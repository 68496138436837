import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT } from '@icp/form-renderer-core';
import { TextField } from '@mui/material';
import I18nTextareaSingle from './I18nTextareaSingle';
import I18nTextareaMulti from './I18nTextareaMulti';
import { useValueLanguageOptions } from '../../../hooks';

const TextareaSupportI18n = forwardRef(function TextareaSupportI18n(props, ref) {
  const { simpleMode, id, value, allowI18nValue, i18nInputLayout, helperText, readonly, ...other } =
    props;

  const languageOptions = useValueLanguageOptions();

  // i18n value textarea 暂时没有支持 simpleMode
  if (simpleMode || !allowI18nValue) {
    const innerValue = simpleMode ? value : value?.longText;
    return (
      <TextField
        {...other}
        multiline={true}
        value={innerValue ?? ''}
        helperText={helperText}
        InputProps={{ readOnly: readonly }}
        ref={ref}
      />
    );
  }

  if (i18nInputLayout === I18N_VALUE_COLLAPSED) {
    return (
      <I18nTextareaMulti
        {...other}
        id={id}
        value={value}
        helperText={helperText}
        readonly={readonly}
        ref={ref}
      />
    );
  }

  if (i18nInputLayout === I18N_VALUE_FLAT) {
    return languageOptions.map((op, index) => (
      <I18nTextareaSingle
        {...other}
        key={op.value}
        id={id}
        languageOp={op}
        value={value}
        helperText={index === languageOptions.length - 1 ? helperText : undefined}
        readonly={readonly}
      />
    ));
  }

  return 'Invalid i18nInputLayout config';
});

TextareaSupportI18n.propTypes = {
  simpleMode: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  allowI18nValue: PropTypes.bool,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  helperText: PropTypes.node,
  readonly: PropTypes.bool,
};

export default TextareaSupportI18n;
