import './Steps.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Children, cloneElement, forwardRef } from 'react';
import Step from './Step';

const Steps = forwardRef(function Steps(props, ref) {
  const {
    children,
    className,
    direction = 'horizontal',
    labelPlacement = 'horizontal',
    current = 0,
    status = 'process',
    waitIcon,
    processIcon,
    finishIcon,
    errorIcon,
    ...other
  } = props;

  return (
    <div
      className={clsx('icp-steps', className, direction, {
        'icp-step-label-horizontal': labelPlacement === 'horizontal',
        'icp-step-label-vertical': labelPlacement === 'vertical',
      })}
      {...other}
      ref={ref}
    >
      {Children.toArray(children)
        .filter((child) => child?.type === Step)
        .map((child, index) => {
          const stepStatus =
            (index === current && status) || (index < current && 'finish') || 'wait';
          const stepIcon =
            child.props.icon ||
            (stepStatus === 'wait' && waitIcon) ||
            (stepStatus === 'process' && processIcon) ||
            (stepStatus === 'finish' && finishIcon) ||
            (stepStatus === 'error' && errorIcon);
          return cloneElement(child, {
            labelPlacement: direction === 'horizontal' ? labelPlacement : 'horizontal',
            index,
            active: index === current,
            status: stepStatus,
            icon: stepIcon,
          });
        })}
    </div>
  );
});

Steps.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  current: PropTypes.number,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  labelPlacement: PropTypes.oneOf(['horizontal', 'vertical']),
  status: PropTypes.oneOf(['wait', 'process', 'error', 'finish']),
  waitIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  processIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  finishIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errorIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Steps;
