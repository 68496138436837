import PropTypes from 'prop-types';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { message } from '@icp/settings';
import { useEventCallback } from '@icp/hooks';
import clsx from 'clsx';
import { debounce, randomNumber } from '@icp/utils';
import {
  uploadFile,
  filterAcceptedFiles,
  selectContext,
  UPLOAD_TYPE_MAP,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
} from '@icp/form-renderer-core';
import { AsyncWangEditor, loadWangEditor } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { getResourceBundle } from '@icp/i18n';
import ErrorBoundary from '../ErrorBoundary';
import { useElementDecorator, useFormApi } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useDispatch, useSelector } from '../store';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const { Editor, Toolbar } = AsyncWangEditor;

const RichTextElement = forwardRef(function RichTextElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    componentProps,
    fieldTitleProps,
    validation,
    readonly,
    helpers,
    onChange,
    onTouchChanged,
    setValidatorDelegate,
  } = props;

  const { toolBarProps, editorProps, acceptProps = {}, sizeLimitProps } = componentProps || {};
  const { style: editorStyle, ...otherEditorProps } = editorProps || {};
  const { style: toolBarStyle, ...otherToolBarProps } = toolBarProps || {};

  const { i18n } = useTranslation();
  const formApi = useFormApi();

  const jaJPLocaleText = useMemo(() => getResourceBundle('icp-vendor-wangeditor'), []);

  useEffect(() => {
    const wangEditorLng = i18n.language === 'en-US' ? 'en' : i18n.language;
    loadWangEditor().then((module) => {
      if (i18n.language === 'ja-JP') {
        if (!jaJPLocaleText) {
          return;
        }
        module.i18nAddResources(i18n.language, jaJPLocaleText);
      }
      module.i18nChangeLanguage(wangEditorLng);
    });
  }, [i18n.language, jaJPLocaleText]);

  const { t } = useTranslation(['icp-form-renderer']);
  const ElementDecorator = useElementDecorator();
  const context = useSelector(selectContext);
  const dispatch = useDispatch();

  const [editor, setEditor] = useState(null); // 存储 editor 实例

  const className = useClassName(classNameProp);

  const valueRef = useRef(null);
  valueRef.current = value || {};

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      editor,
    }),
    [editor],
  );

  // 及时销毁 editor
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  const isEditorReadOnly = readonly || disabled;
  useEffect(() => {
    if (!editor) return;
    if (isEditorReadOnly) {
      editor.disable();
    } else {
      editor.enable();
    }
  }, [editor, isEditorReadOnly]);

  const longTextDisplay = useMemo(() => {
    return (value?.resources || []).reduce(
      (text, resource) => text.replaceAll(resource.token, resource.url),
      value?.longText ?? '',
    );
  }, [value]);

  // 代理表单验证方法
  useMemo(() => {
    if (editor) {
      setValidatorDelegate?.((base) => ({
        ...base,
        isEmpty: (_val) => {
          // https://www.wangeditor.com/v5/API.html#isempty
          // 该方法只能识别只有一个空段落情况，其他情况（如有一个空标题、空表格）请使用 editor.getText() 来判断。
          if (editor.isEmpty()) return true;
          // 只传图片不写文本时getText也返回''
          if (!editor.getText() && !_val?.resources?.length) return true;
          return false;
        },
      }));
    }
  }, [editor, setValidatorDelegate]);

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-rich-text-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreated = (e) => {
    setEditor(e);
    setTimeout(() => {
      formApi.asyncComponentManager.setReady(idToRegister);
    }, 16);
  };

  const handleChange = useEventCallback(
    debounce((e) => {
      // 上传图片过后 onChange 会被连续触发两次导致第二次的 resource 不是最新的
      onChange({
        longText: e.getHtml(),
        resources: valueRef.current.resources,
        children: e.children,
      });
    }),
  );

  const customUpload = useEventCallback((files, callback, type) => {
    dispatch(uploadFile(files)).then(
      (newResources) => {
        switch (type) {
          case 'file':
            newResources.forEach((item) => {
              callback(item.fileName, item.url);
            });
            break;
          case 'img':
            newResources.forEach((item) => {
              callback(item.url, item.fileName, item.url);
            });
            break;
          default:
            newResources.forEach((item) => {
              callback(item.url);
            });
            break;
        }
        // 上传图片过后 onChange 会被连续触发两次导致第二次的 resource 不是最新的
        onChange({
          longText: valueRef.current.longText,
          resources: (valueRef.current?.resources || []).concat(newResources),
        });
      },
      () => {
        message.error(t('error.upload'));
      },
    );
  });

  const customBrowseAndUpload = (insertFn, type) => {
    const input = document.createElement('input');
    input.multiple = true;
    const accept = acceptProps[type] ?? UPLOAD_TYPE_MAP[type];
    const sizeLimit = sizeLimitProps?.[type];
    input.type = 'file';
    input.accept = accept;
    input.click();
    input.onchange = (event) => {
      const [acceptedFiles, notMatchedMsg] = filterAcceptedFiles(
        event.target.files,
        accept,
        sizeLimit,
      );

      if (acceptedFiles.length) {
        customUpload(acceptedFiles, insertFn, type);
      }

      if (notMatchedMsg.length) {
        notMatchedMsg.forEach((msg) => message.warning(msg));
      }
    };
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'rich-text-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          <div className="form-rich-text-editor">
            {!readonly && (
              <Toolbar
                editor={editor}
                defaultConfig={{
                  insertKeys: {
                    index: 0,
                    keys: ['uploadAttachment'],
                  },
                  ...otherToolBarProps,
                }}
                mode="default"
                style={{
                  border: '1px solid var(--border-color)',
                  borderBottom: 0,
                  ...toolBarStyle,
                }}
              />
            )}
            <ErrorBoundary>
              <Editor
                name={id}
                defaultConfig={{
                  hoverbarKeys: {
                    attachment: {
                      menuKeys: ['downloadAttachment'],
                    },
                  },
                  MENU_CONF: {
                    fontFamily: context.RICH_TEXT_FONT_FAMILY_LIST
                      ? {
                          fontFamilyList: context.RICH_TEXT_FONT_FAMILY_LIST,
                        }
                      : undefined,
                    uploadAttachment: {
                      customBrowseAndUpload(insertFn) {
                        customBrowseAndUpload(insertFn, 'file');
                      },
                    },
                    uploadImage: {
                      customBrowseAndUpload(insertFn) {
                        customBrowseAndUpload(insertFn, 'img');
                      },
                    },
                    uploadVideo: {
                      customBrowseAndUpload(insertFn) {
                        customBrowseAndUpload(insertFn, 'video');
                      },
                    },
                  },
                  onBlur: onTouchChanged,
                  autoFocus: false,
                  ...otherEditorProps,
                }}
                value={longTextDisplay}
                onCreated={handleCreated}
                onChange={handleChange}
                mode="default"
                style={{
                  border: !readonly ? '1px solid var(--border-color)' : 0,
                  height: 320,
                  ...editorStyle,
                }}
              />
            </ErrorBoundary>
          </div>
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

RichTextElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.shape({
    longText: PropTypes.string,
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        storageId: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
        // authorization: PropTypes.string,
      }),
    ),
  }),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * wangeditor toolbar 的属性
     */
    toolBarProps: PropTypes.shape({
      style: PropTypes.shape({}),
    }),
    /**
     * wangeditor editor 的属性
     */
    editorProps: PropTypes.shape({
      style: PropTypes.shape({}),
    }),
    /**
     * 上传文件接受的文件类型
     */
    acceptProps: PropTypes.shape({
      img: PropTypes.string,
      video: PropTypes.string,
      file: PropTypes.string,
    }),
    /**
     * 上传文件的大小限制
     */
    sizeLimitProps: PropTypes.shape({
      img: PropTypes.number,
      video: PropTypes.number,
      file: PropTypes.number,
    }),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  style: PropTypes.shape({}),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  setValidatorDelegate: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
RichTextElement.displayName = 'RichText';

export default memo(withFieldWrapper(RichTextElement, { ns: 'icp-vendor-wangeditor' }));
