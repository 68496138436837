import { debounce, throttle } from 'lodash-es';
import { parseJSON, getJwtExp, isJwtNotExpired, isMobile } from '@icp/utils';
import { getCascadedUserIdleTime, setUserProfile } from '@icp/settings';

const AUTH_BC_NAME = '703C78DC-FCD4-403B-AC67-E74A6BA01A9A';
const USER_ACTIVE_KEY = 'LAST_USER_ACTIVE_DATE_TIME';
const STOP_REFRESH_TASK_KEY = 'STOP_REFRESH_TASK';
const ACCESS_TOKEN_STORAGE_KEY = 'ACCESS_TOKEN';
const REFRESH_TOKEN_STORAGE_KEY = 'REFRESH_TOKEN';
// const ACCESS_TOKEN_COOKIE_KEY = 'access_token';
// const REFRESH_TOKEN_COOKIE_KEY = 'refresh_token';

const logger = new Proxy(console, {
  get(target, prop /* , receiver */) {
    if (typeof target[prop] !== 'function') return target[prop];

    // if (process.env.NODE_ENV === 'development') {
    return target[prop].bind(target, '[auth]');
    // }

    // return () => {};
  },
});

class Auth {
  #restClient;

  #bc = window.BroadcastChannel ? new BroadcastChannel(AUTH_BC_NAME) : null;

  #refreshJobTimer;

  #isUserIdle = false;

  #idleTimeoutActual;

  #initIdleTimeoutOnDemand = throttle(
    () => {
      let delay = Math.min(Math.max(+getCascadedUserIdleTime() || 0, 0) * 1000, 2_147_483_647);
      if (delay <= 0) delay = 3600 * 1000;

      if (!this.#idleTimeoutActual || this.#idleTimeoutActual[Symbol.for('wait')] !== delay) {
        this.#idleTimeoutActual?.cancel();
        this.#idleTimeoutActual = debounce(() => {
          this.#isUserIdle = true;
          this.logout({ reason: 'user idle' });
        }, delay);
        this.#idleTimeoutActual[Symbol.for('wait')] = delay;
        logger.log('#initIdleTimeoutOnDemand', 'delay:', delay);
      }
    },
    1000,
    { leading: true, trailing: true },
  );

  get #idleTimeout() {
    this.#initIdleTimeoutOnDemand();
    return this.#idleTimeoutActual;
  }

  #notifyActive = throttle(
    () => {
      // 通知其它tab用户活跃
      if (this.#bc) {
        this.#bc.postMessage({
          key: USER_ACTIVE_KEY,
        });
      } else {
        localStorage.setItem(USER_ACTIVE_KEY, JSON.stringify(new Date()));
      }
    },
    500,
    { leading: true, trailing: true },
  );

  constructor({ restClient }) {
    this.#restClient = restClient;

    this.setToken = Auth.setToken;
    this.clearToken = Auth.clearToken;
    this.getAccessToken = Auth.getAccessToken;
    this.getRefreshToken = Auth.getRefreshToken;
    this.hasActiveAccessToken = Auth.hasActiveAccessToken;

    // 监听token的storage事件, 其它tab存入新token时本tab重新注册"刷新任务", 其它tab登出时本tab也登出
    this.#startTokenStorageListener();
  }

  static setToken({ refresh, access } = {}) {
    if (refresh) {
      localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, JSON.stringify(refresh));
    }
    if (access) {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, JSON.stringify(access));
    }
  }

  static clearToken() {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  static clearRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  static getAccessToken() {
    return parseJSON(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY));
  }

  static getRefreshToken() {
    return parseJSON(localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY));
  }

  static hasActiveAccessToken() {
    const accessToken = this.getAccessToken();
    if (!accessToken) return false;
    return isJwtNotExpired(accessToken) ?? false;
  }

  beginRefreshTokenTask = () => {
    logger.log('beginRefreshTokenTask');
    this.#startStopRefreshTaskListener();
    this.#registerNextRefreshJob();
  };

  stopRefreshTokenTask = () => {
    logger.log('stopRefreshTokenTask');
    this.#stopStopRefreshTaskListener();
    this.#cancelNextRefreshJob();
  };

  restartRefreshTokenTask = () => {
    logger.log('restartRefreshTokenTask');
    this.stopRefreshTokenTask();
    this.beginRefreshTokenTask();
  };

  restartIdleListener = () => {
    logger.log('restartIdleListener');
    this.#stopIdleListener();
    this.#startIdleListener();
  };

  logout = ({ lostCurrentHref = false, reason } = {}) => {
    console.log('logout, reason:', reason);
    setUserProfile(null);
    const refreshToken = this.getRefreshToken();

    this.#stopIdleListener();
    this.#stopTokenStorageListener();
    this.stopRefreshTokenTask();

    (refreshToken
      ? this.#restClient.post(
          `/user-management/api/user/logout`,
          {},
          { timeout: 500, params: { refreshToken } },
        )
      : Promise.resolve()
    ).finally(() => {
      this.clearToken();
      // 直接刷新页面重新触发 authSlice 验证跳转登录
      if (!lostCurrentHref) {
        window.location.reload();
      } else if (isMobile()) {
        window.location = `${window.ICP_PUBLIC_PATH}mobile`;
      } else {
        window.location = window.ICP_PUBLIC_PATH;
      }
    });
  };

  #startTokenStorageListener = () => {
    logger.log('#startTokenStorageListener');
    window.addEventListener('storage', this.#onAccessTokenChanged);
  };

  #stopTokenStorageListener = () => {
    logger.log('#stopTokenStorageListener');
    window.removeEventListener('storage', this.#onAccessTokenChanged);
  };

  #onAccessTokenChanged = (e) => {
    if (e.key === ACCESS_TOKEN_STORAGE_KEY) {
      logger.log('#onAccessTokenChanged');
      if (e.newValue) {
        this.beginRefreshTokenTask();
      } else {
        this.logout({ reason: 'access token in storage changed, new value is null' });
      }
    }
  };

  #startStopRefreshTaskListener = () => {
    logger.log('#startStopRefreshTaskListener');
    if (this.#bc) {
      this.#bc.addEventListener('message', this.#stopRefreshTaskBCMessage);
    } else {
      window.addEventListener('storage', this.#stopRefreshTaskMessage);
    }
  };

  #stopStopRefreshTaskListener = () => {
    logger.log('#stopStopRefreshTaskListener');
    if (this.#bc) {
      this.#bc.removeEventListener('message', this.#stopRefreshTaskBCMessage);
    } else {
      window.removeEventListener('storage', this.#stopRefreshTaskMessage);
    }
  };

  #stopRefreshTaskMessage = (e) => {
    if (e.key === STOP_REFRESH_TASK_KEY) {
      logger.log('#stopRefreshTaskMessage');
      this.stopRefreshTokenTask();
    }
  };

  #stopRefreshTaskBCMessage = (e) => {
    if (e.data.key === STOP_REFRESH_TASK_KEY) {
      logger.log('#stopRefreshTaskBCMessage');
      this.stopRefreshTokenTask();
    }
  };

  #startIdleListener = () => {
    logger.log('#startIdleListener');
    this.#idleTimeout();

    const addEventListeners = (win) => {
      win.document.addEventListener('mousemove', this.#onActive);
      win.document.addEventListener('mousedown', this.#onActive);
      win.document.addEventListener('keydown', this.#onActive);
      win.document.addEventListener('touchstart', this.#onActive);
      Array.from(win.frames).forEach(addEventListeners);
    };
    addEventListeners(window);

    if (this.#bc) {
      this.#bc.addEventListener('message', this.#onUserActiveBCMessage);
    } else {
      window.addEventListener('storage', this.#onUserActiveMessage);
    }
  };

  #stopIdleListener = () => {
    logger.log('#stopIdleListener');
    this.#idleTimeout.cancel();

    const removeEventListeners = (win) => {
      win.document.removeEventListener('mousemove', this.#onActive);
      win.document.removeEventListener('mousedown', this.#onActive);
      win.document.removeEventListener('keydown', this.#onActive);
      win.document.removeEventListener('touchstart', this.#onActive);
      Array.from(win.frames).forEach(removeEventListeners);
    };
    removeEventListeners(window);

    if (this.#bc) {
      this.#bc.removeEventListener('message', this.#onUserActiveBCMessage);
    } else {
      window.removeEventListener('storage', this.#onUserActiveMessage);
    }
  };

  #onUserActiveMessage = (e) => {
    if (e.key === USER_ACTIVE_KEY) {
      // logger.log('#onUserActiveMessage');
      this.#onActiveWithoutNotify();
    }
  };

  #onUserActiveBCMessage = (e) => {
    if (e.data.key === USER_ACTIVE_KEY) {
      // logger.log('#onUserActiveBCMessage');
      this.#onActiveWithoutNotify();
    }
  };

  #onActive = () => {
    // logger.log('#onActive');
    this.#onActiveWithoutNotify();
    this.#notifyActive();
  };

  #onActiveWithoutNotify = () => {
    // logger.log('#onActiveWithoutNotify');
    this.#isUserIdle = false;
    this.#idleTimeout();
  };

  #cancelNextRefreshJob = () => {
    logger.log('#cancelNextRefreshJob');
    clearTimeout(this.#refreshJobTimer);
  };

  #registerNextRefreshJob = () => {
    logger.log('#registerNextRefreshJob');
    this.#cancelNextRefreshJob();
    if (this.#isUserIdle) return;

    const accessToken = this.getAccessToken();
    if (!accessToken) {
      this.logout({ reason: 'registerNextRefreshJob but no access token' });
      return;
    }

    const exp = getJwtExp(accessToken);
    if (!exp) {
      this.logout({ reason: 'registerNextRefreshJob but no exp in access token' });
      return;
    }

    const now = new Date().getTime() / 1000;
    if (now >= exp) {
      this.logout({ reason: 'registerNextRefreshJob but access token expired' });
      return;
    }

    // 到期前5秒刷新，剩余时间小于5秒立即刷新
    // delay > 2^31-1 resulting in the timeout being executed immediately
    const delay = Math.min(Math.max(exp - now - 5, 0) * 1000, 2_147_483_647);

    logger.log(
      '#registerNextRefreshJob, registered delay:',
      delay,
      ', next:',
      new Date(+new Date() + delay),
    );
    this.#refreshJobTimer = setTimeout(() => this.#doRefreshToken(), delay);
  };

  #doRefreshToken = () => {
    logger.log('#doRefreshToken');
    const refreshToken = this.getRefreshToken();

    this.#notifyCancelRefreshTask();

    this.stopRefreshTokenTask();

    this.#restClient
      .get('/user-management/api/user/refresh-token', { params: { refreshToken } })
      .then((result) => {
        this.setToken({ access: result.accessToken, refresh: result.refreshToken });
        this.beginRefreshTokenTask();
      })
      .catch((err) => this.logout({ reason: err.message }));
  };

  // 通知其它tab取消刷新任务避免多tab同时call api
  #notifyCancelRefreshTask = () => {
    logger.log('#notifyCancelRefreshTask');
    if (this.#bc) {
      this.#bc.postMessage({
        key: STOP_REFRESH_TASK_KEY,
      });
    } else {
      localStorage.setItem(STOP_REFRESH_TASK_KEY, JSON.stringify(new Date()));
    }
  };
}

export default Auth;

// // PC端sso回调从cookie初始化token
export function initTokenFromCookie() {
  // TODO: case by case
  // if (isWechat()) return;
  // // 防止刷新页面误把cookie里旧token覆盖localStorage里刷新后的新token
  // if (Auth.hasActiveAccessToken()) return;
  // Auth.setToken({
  //   refresh: getCookie(REFRESH_TOKEN_COOKIE_KEY),
  //   access: getCookie(ACCESS_TOKEN_COOKIE_KEY),
  // });
}
