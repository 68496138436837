import { createAsyncThunk, createSelector, createSlice, current, isAnyOf } from '@reduxjs/toolkit';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual, pick, get, set, unset } from 'lodash-es';
import { EvalWorker, getSearchParams } from '@icp/utils';
import { restApi } from '@icp/settings';
// import { shouldTranslateByDefault } from '@icp/settings';
import {
  resolveConditionalPropertyValue,
  runValidationSchema,
  setNestedObjectValues,
  // submissionPreprocessorLazyValue,
  // submissionPreprocessorFileStorage,
  computeValueExpressions,
  mergeDefaultValues,
  isValueEqual,
  // translateEntireObj,
} from '../../utils';
import { formDataInitialState } from '../initialState';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, {
  resultEqualityCheck: isEqual,
});

/// --- export selectors ---

const selectThis = (state) => state.formData;

export const selectValues = createSelector(selectThis, (state) => state.values);
export const selectInitialValues = createSelector(selectThis, (state) => state.initialValues);
export const selectIsFetching = createSelector(selectThis, (state) => state.isFetching);
export const selectIsSubmitting = createSelector(selectThis, (state) => state.isSubmitting);
export const selectIsFormDataDirty = (state) => {
  const { disableDirty, values, initialValues, uiChangedData } = state.formData;

  // 没 touched 过不 dirty，dirty 目前的应用场景是判断是否有人为修改而去阻止里面离开，通过 js 脚本等操作等修改 value 不判断。
  if (disableDirty || !uiChangedData) {
    return false;
  }

  return !isValueEqual(values, initialValues);
};

export const selectCurrentFieldId = createSelector(selectThis, (state) => state.currentFieldId);

export const selectErrors = (state) => state.formData.errors;

export const selectFieldValue = (keyPath) =>
  createSelector(selectValues, (values) => get(values, keyPath));
export const selectFieldValueI18n = (keyPath, language) => {
  return (state) => get(state.formData.values, `${keyPath}_i18n_${language}`);
};

export const selectFieldInitialValue = (keyPath) =>
  createSelector(selectInitialValues, (values) => get(values, keyPath));

export const selectIsFormDataValid = createSelector(
  selectThis,
  (state) => Object.keys(state.errors || {}).length === 0,
);

const dependenciesSymbol = Symbol('dependencies');

export const createSelectDependencyValues = () => {
  const selectDependencyValuesImpl = createDeepEqualSelector(
    selectThis,
    (input) => input[dependenciesSymbol],
    (state, dependencies) => pick(state.values, dependencies),
  );

  // dependencies: an array of field's keypath
  const selectDependencyValues = (dependencies) => (state) =>
    selectDependencyValuesImpl({
      ...state,
      [dependenciesSymbol]: dependencies,
    });

  return selectDependencyValues;
};

/* export const selectValuesWithPreprocessor = createSelector(
  selectThis,
  (state) => state.fileStorage,
  (formData, fileStorage) => {
    const { values, initialValues } = formData;

    // Handler fields with resources (Upload, RichText, etc)
    return submissionPreprocessorFileStorage({ values, initialValues, fileStorage });
  },
); */

/// --- export async thunks ---

export const submitRequest =
  ({ method, url, data }) =>
  () => {
    return restApi[method](url, data);
  };

export const downloadUrl =
  ({ method, url, data }) =>
  () => {
    const requestData = method !== 'get' ? data : undefined;
    // header config  todo
    return restApi[method](url, requestData, { responseType: 'blob' });
  };

export const uploadRequest =
  ({ url, data }) =>
  () => {
    return restApi.post(url, data);
  };

export const validateForm = createAsyncThunk(
  'formData/validateForm',
  async (payload, { getState, extra: { yupSchema, params } }) => {
    const values = selectValues(getState());

    const conditionalPropertyResolver = (value) =>
      resolveConditionalPropertyValue({
        value,
        // 不支持ListRender当前row取值
        currentData: values,
        store: { getState },
        params,
      });

    const errors = await runValidationSchema(values, yupSchema, conditionalPropertyResolver, {
      store: getState(),
    });
    return errors;
  },
);

export const validateField = createAsyncThunk(
  'formData/validateField',
  async (payload, { getState, extra: { yupSchema, params } }) => {
    const keyPath = payload;
    const values = selectValues(getState());

    const conditionalPropertyResolver = (value) =>
      resolveConditionalPropertyValue({
        value,
        // 不支持ListRender当前row取值
        currentData: values,
        store: { getState },
        params,
      });

    const errors = await runValidationSchema(
      values,
      yupSchema,
      conditionalPropertyResolver,
      { store: getState() },
      keyPath,
    );

    return { field: keyPath, error: get(errors, keyPath) };
  },
);

export const fetchFormData = createAsyncThunk(
  'formData/fetchFormData',
  async (payload, { getState, signal }) => {
    const {
      retrieveUrl,
      transformRetrievedData,
      // translateRetrievedData = shouldTranslateByDefault(),
    } = payload ?? {};
    const searchParams = getSearchParams();
    let data = await restApi.get(retrieveUrl, {
      params: { include_deleted: searchParams.include_deleted },
      signal,
    });
    if (transformRetrievedData) {
      data = await EvalWorker.shared().execEval(data, transformRetrievedData, {
        signal,
        params: { context: getState().context },
      });
    }
    /* if (translateRetrievedData) {
      translateEntireObj(data);
    } */
    return data;
  },
  {
    condition: (payload) => {
      const { retrieveUrl } = payload ?? {};
      return !!retrieveUrl;
    },
  },
);

export const setFieldValue = createAsyncThunk(
  'formData/setFieldValue',
  (payload, { dispatch }) => dispatch(internalSetFieldValue(payload)),
  {
    condition: (payload) => {
      const { keyPath, source } = payload;
      // 防止联动风暴
      if (source?.includes(keyPath)) return false;
      return true;
    },
  },
);

export const processValueExpression = createAsyncThunk(
  'formData/processValueExpression',
  async (payload, { dispatch, getState, extra: { valueExpressions } }) => {
    const { keyPath, source } = payload;

    for (const valueExpression of valueExpressions) {
      const values = selectValues(getState());
      const calculated = computeValueExpressions({
        keyPath,
        values,
        valueExpressions: [valueExpression],
      });
      if (!calculated.length) continue;
      const [{ id, newValue, i18nValues = {} }] = calculated;
      // 上游字段值变动不再重复计算
      if (source?.includes(id)) continue;
      dispatch(
        setFieldValue({ keyPath: id, value: newValue, source: [...(source || []), keyPath] }),
      );
      // 当 valueExpress 依赖的值是个多语言字段，将多语言资源一口气全部带过去
      for (const [suffix, i18nValue] of Object.entries(i18nValues)) {
        dispatch(
          setFieldValue({
            keyPath: `${id}${suffix}`,
            value: i18nValue,
            source: [...(source || []), keyPath],
          }),
        );
      }
    }
  },
);

export const submitFormData =
  ({ method, url, data }) =>
  () => {
    return restApi[method](url, data);
  };

/// --- export async thunks end ---

const EMPTY_OBJ = {};
const slice = createSlice({
  name: 'formData',
  initialState: { ...formDataInitialState },
  reducers: {
    resetForm: (state, action) => {
      const initialValues = action.payload?.initialValues ?? state.initialValues;

      state.initialErrors = EMPTY_OBJ;
      state.initialWarnings = EMPTY_OBJ;
      state.initialTouched = EMPTY_OBJ;
      state.initialValues = initialValues;

      state.errors = EMPTY_OBJ;
      state.warnings = EMPTY_OBJ;
      state.touched = EMPTY_OBJ;
      state.values = initialValues;
    },
    setValidating: (state, action) => {
      state.isValidating = action.payload;
    },
    handleSubmitSuccess: (state, action) => {
      const { initialValues, newData } = action.payload || {};

      // form entity 每保存一次会更新一次 objectVersion 版本信息
      const newValues = initialValues ?? state.values;
      // 只有当前后都有 objectVersion 时才更新，因为 FormRenderer 调用地方很多有可能 retrieveUrl 返回的数据和
      // submit promise 返回的数据不一致
      if (
        newData &&
        state.values.objectVersion !== undefined &&
        state.values.objectVersion !== null &&
        newData.objectVersion !== undefined &&
        newData.objectVersion !== null
      ) {
        newValues.objectVersion = newData.objectVersion;
      }

      state.initialErrors = EMPTY_OBJ;
      state.initialWarnings = EMPTY_OBJ;
      state.initialTouched = EMPTY_OBJ;
      state.initialValues = newValues;

      state.errors = EMPTY_OBJ;
      state.warnings = EMPTY_OBJ;
      state.touched = EMPTY_OBJ;
      state.values = newValues;
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setWarnings: (state, action) => {
      state.warnings = action.payload;
    },
    setValues: (state, action) => {
      state.values = action.payload;
      // TODO listener监听触发全量表单数据校验
    },
    setFieldError: (state, action) => {
      const { keyPath, error } = action.payload;
      set(state.errors, keyPath, error);
    },
    setFieldWarning: (state, action) => {
      const { keyPath, warning } = action.payload;
      set(state.warnings, keyPath, warning);
    },
    setFieldTouched: (state, action) => {
      const { keyPath, touched } = action.payload;
      set(state.touched, keyPath, touched);
    },
    setAllTouched: (state) => {
      state.touched = setNestedObjectValues(state.values, true);
    },
    internalSetFieldValue: (state, action) => {
      const { keyPath, value, language, fromUI } = action.payload;
      const oldValue = get(state.values, keyPath);

      // 不管有没有 language 都赋值给不带 _i18n_ 后缀的 value，否则在字段 required 验证的时候会出错
      // 这样的结果是在切换语言的时候没有当前语言的值会显示上一次修改的值
      set(state.values, keyPath, value);
      if (language && (typeof value === 'string' || typeof oldValue === 'string')) {
        set(state.values, `${keyPath}_i18n_${language}`, value);
      }
      // 清空多语言值，不应该有只修改某个值而不修改其 i18n value 的操作。
      // 此逻辑目前处理发生 processValueExpression 的时候，例如原始 name 有多个 name_i18n_en-US 和 name_i18n_zh-CN，
      // 但是 valueExpress 依赖的值只有 en-US 的值，那 name 原始多出来的 zh-CN i18n 值就是脏数据需要清楚掉。
      if (!language && (typeof value === 'string' || typeof oldValue === 'string')) {
        for (const key of Object.keys(state.values)) {
          if (key.startsWith(`${keyPath}_i18n_`)) {
            unset(state.values, key);
          }
        }
      }

      // 标注表单数据通过 ui 界面进行过修改
      if (fromUI) {
        state.uiChangedData = true;
      }
    },
    setFieldValidatorDelegate: (state, action) => {
      const { keyPath, validator } = action.payload;
      if (typeof validator === 'function') {
        const curr = get(current(state.validatorDelegates), keyPath);
        const next = validator(curr);
        set(state.validatorDelegates, keyPath, next);
      } else if (!validator) {
        unset(state.validatorDelegates, keyPath);
      } else {
        set(state.validatorDelegates, keyPath, validator);
      }
    },
    asyncFieldSetInitialValue: (state, action) => {
      const { keyPath, value } = action.payload;

      // 异步组件自己加载初始值的，例如 Editable Table 通过自己的 dataUrl 获取值
      set(state.initialValues, keyPath, value);
      set(state.values, keyPath, value);
    },
    setCurrentFieldId: (state, action) => {
      state.currentFieldId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormData.pending, (state) => {
        state.isFetching = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchFormData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.error = null;

        // merge values between field defaultValue and api returned values
        state.initialValues = mergeDefaultValues(state.initialValues, action.payload);
        state.values = mergeDefaultValues(state.initialValues, action.payload);
      })
      .addCase(validateForm.fulfilled, (state, action) => {
        state.isValidating = false;
        state.errors = action.payload || EMPTY_OBJ;
      })
      .addCase(validateField.fulfilled, (state, action) => {
        state.isValidating = false;
        const { field, error } = action.payload;
        if (error) {
          set(state.errors, field, error);
        } else {
          unset(state.errors, field);
        }
      })
      .addMatcher(isAnyOf(validateForm.pending, validateField.pending), (state) => {
        state.isValidating = true;
      });

    /* .addMatcher(
        isAnyOf(
          submissionPreprocessor.pending,
        ),
        (state) => {
          state.isSubmitting = true;
          state.isError = false;
          state.error = null;
        },
      ) */
    /* .addMatcher(
        isAnyOf(submissionPreprocessor.rejected, fetchFormData.rejected),
        (state, action) => {
          state.isFetching = false;
          state.isSubmitting = false;
          state.isError = true;
          state.error = action.error;
        },
      ); */
  },
});

/// --- export actions ---

export const {
  resetForm,
  handleSubmitSuccess,
  setValidating,
  setSubmitting,
  setErrors,
  setWarnings,
  setValues,
  setFieldError,
  setFieldWarning,
  setFieldTouched,
  setAllTouched,
  internalSetFieldValue,
  setFieldValidatorDelegate,
  asyncFieldSetInitialValue,
  setCurrentFieldId,
} = slice.actions;

/// --- export reducer ---

export default slice.reducer;
