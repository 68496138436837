import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import DatePickerElement from '../../DatePickerElement';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';

const DateCellEditor = forwardRef(function DateCellEditor(props, ref) {
  const {
    value: valueProp,
    cellStartedEdit,
    colDef,
    data,
    context,
    componentProps,
    node: nodeProp,
  } = props;

  const [value, setValue] = useState(valueProp);
  const [openState, setOpenState] = useState(cellStartedEdit);
  const refInput = useRef(null);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        refInput.current.focus();
        setOpenState(true);
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <DatePickerElement
        className="table-date-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          size: context.tableSize,
          ref: refInput,
          autoFocus: cellStartedEdit,
          open: openState,
          onOpenChange: setOpenState,
          ...componentProps,
        }}
      />
    </CurrentDataProvider>
  );
});

DateCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cellStartedEdit: PropTypes.bool,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({}),
};

export default DateCellEditor;
