import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const CurrentDataContext = createContext(null);

export function CurrentDataProvider({ children, value }) {
  return <CurrentDataContext.Provider value={value}>{children}</CurrentDataContext.Provider>;
}

CurrentDataProvider.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

export function useCurrentData() {
  return useContext(CurrentDataContext);
}
