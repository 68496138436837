import PropTypes from 'prop-types';
import { cloneElement, isValidElement, useEffect, useMemo, useRef } from 'react';
import { pick } from 'lodash-es';
import { getComponentDisplayName } from '@icp/utils';
import { useChangeLanguage } from '@icp/i18n';
import { useConditionalProperty, useField, useStatus } from '../hooks';
import { useFormDisabled, useFormReadonly } from '../FormRenderCtx';

function FormControl(props) {
  const { children: childrenProp, ...other } = props;

  const [, , languageOptions] = useChangeLanguage();

  const children = isValidElement(childrenProp) ? childrenProp : { props: {} };

  const formDisabled = useFormDisabled();
  const formReadonly = useFormReadonly();

  const allowI18nValue = children.props.allowI18nValue && languageOptions?.length > 1;

  const { value, setValue, setTouched, setValidatorDelegate, setFocus } = useField({
    id: children.props.id,
  });
  const { status, helpers } = useStatus({
    id: children.props.id,
    helperProp: children.props.helper,
  });

  const disabled = useConditionalProperty(children.props.disabled);
  const readonly = useConditionalProperty(children.props.readonly);

  const validatorRegisterRef = useRef();
  validatorRegisterRef.current = pick(children.props, [
    'id',
    'title',
    'componentProps',
    'validation',
    'readonly',
    'disabled',
  ]);

  useMemo(() => {
    setValidatorDelegate({
      [Symbol.for('validation')]: {
        ...validatorRegisterRef.current,
        component: getComponentDisplayName(children.type),
      },
    });
  }, [children.type, setValidatorDelegate]);

  useEffect(() => {
    return () => {
      setValidatorDelegate(undefined);
    };
  }, [setValidatorDelegate]);

  if (!isValidElement(children)) {
    console.error('Children is not a valid react element', children);
    return null;
  }

  return cloneElement(children, {
    ...other,
    value,
    allowI18nValue,
    status,
    helpers,
    disabled: formDisabled || disabled,
    readonly: formReadonly || readonly,
    onChange: (newValue, language, fromUI) => {
      setValue(newValue, language, fromUI);
      if (children.props.onChange) {
        children.props.onChange(newValue, language);
      }
    },
    onTouchChanged: setTouched,
    setValidatorDelegate,
    // fieldApi: (api) => setFieldApi(children.props.id, api),
    // todo: The "onFocus: setFocus" should be only applied to the components that need it.
    componentProps: {
      ...children.props.componentProps,
      onFocus: setFocus,
    },
  });
}

FormControl.propTypes = {
  children: PropTypes.element,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
FormControl.displayName = 'FormControl';

export default FormControl;
