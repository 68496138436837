/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DatePicker,
  Empty,
  Switch,
  Input,
  Popconfirm,
  Popover,
  Space,
  message,
} from 'antd';
import { Icon } from '@icp/components';
import { selectIsAdminUser } from '@icp/form-renderer-core';
import { restApi } from '@icp/settings';
import { useEffect, useState, Suspense } from 'react';
import { getAuthorizedConnectorPbcList } from '@icp/app-core';
import ConnectorList from './ConnectorList';
import ConnectorListV2 from './ConnectorListV2';
import { useAppContext } from '../appCtx';

function GenerateToken() {
  const { t } = useTranslation(['icp-app', 'icp-form-renderer']);

  const [accessTokenExp, setAccessTokenExp] = useState('');
  const [generatingAccessToken, setGeneratingAccessToken] = useState(false);
  const [generatedAccessToken, setGeneratedAccessToken] = useState('');

  const handleClickGenerateAccessToken = () => {
    setGeneratingAccessToken(true);

    restApi
      .post(
        `/user-management/api/user/generate-personal-api-token`,
        {},
        {
          params: {
            expirationDate: accessTokenExp,
          },
        },
      )
      .then(setGeneratedAccessToken)
      .finally(() => setGeneratingAccessToken(false));
  };

  const handleClickCopyAccessToken = () => {
    window.navigator.clipboard?.writeText(generatedAccessToken).then(() => {
      message.success(t('success.copy', { ns: 'icp-form-renderer' }));
    });
  };

  return (
    <Space direction="vertical">
      <DatePicker
        showTime={true}
        placeholder={t('expiration-time')}
        onChange={(d) => setAccessTokenExp(d.toISOString())}
      />
      <Popconfirm
        title={t('confirm', { ns: 'icp-common' })}
        description={t('access-token-confirm')}
        placement="right"
        onConfirm={handleClickGenerateAccessToken}
      >
        <Button disabled={!accessTokenExp} loading={generatingAccessToken}>
          {t('generate', { ns: 'icp-common' })}
        </Button>
      </Popconfirm>
      {generatedAccessToken && (
        <Space.Compact block={true}>
          <Input.Password
            value={generatedAccessToken}
            readonly={true}
            onClick={(e) => e.stopPropagation()}
          />
          <Button icon={<Icon name="oct:copy" />} onClick={handleClickCopyAccessToken} />
        </Space.Compact>
      )}
    </Space>
  );
}

function PageConnector(props) {
  const { pbcList: allPbcList, context } = props;

  const { t } = useTranslation(['icp-app']);

  const isAdminUser = selectIsAdminUser({ context });
  const { userPermissionMap } = context;

  const { setPageTitle } = useAppContext();

  const [newConnector, setNewConnector] = useState(true);

  const connectorPbcList = getAuthorizedConnectorPbcList(allPbcList, context);

  useEffect(() => {
    setPageTitle(t('connector.page-title'));

    return () => {
      setPageTitle('');
    };
  }, [setPageTitle, t]);

  const canEdit =
    isAdminUser ||
    (userPermissionMap && Object.keys(userPermissionMap).find((key) => key === 'CONNECTOR_EDIT'));

  return (
    <div className="icp-connector-list">
      <Space>
        <Switch
          checked={newConnector}
          checkedChildren="New"
          unCheckedChildren="Legacy"
          onChange={setNewConnector}
        />
        <Popover
          placement="bottom"
          trigger="click"
          title={t('access-token')}
          content={
            <Suspense fallback={null}>
              <GenerateToken />
            </Suspense>
          }
        >
          <Button
            title={t('access-token')}
            size="small"
            shape="circle"
            icon={<Icon name="oct:passkey-fill" />}
          />
        </Popover>
      </Space>
      {connectorPbcList?.length ? (
        newConnector ? (
          <ConnectorListV2 connectorPbcList={connectorPbcList} canEdit={canEdit} />
        ) : (
          <ConnectorList connectorPbcList={connectorPbcList} canEdit={canEdit} />
        )
      ) : (
        <Empty />
      )}
    </div>
  );
}

PageConnector.propTypes = {
  pbcList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  context: PropTypes.shape({
    userPermissionMap: PropTypes.shape({}),
  }),
};

export default PageConnector;
