const allPbcComponent = {};

(function importS() {
  const r = require.context('@site/src/pbc', true, /(index\.js$)/);
  r.keys()
    // filter outer duplicate key caused by webpack.config.js resolve.context
    .filter((key) => key.startsWith('./'))
    .forEach((key) => {
      const content = r(key);
      const paths = key.split('/');
      const [, pbcToken] = paths;

      if (!allPbcComponent[pbcToken]) {
        allPbcComponent[pbcToken] = {};
      }

      if (paths[2] === 'components' && paths.length === 5) {
        const componentName = paths[paths.length - 2];
        allPbcComponent[pbcToken][componentName] = content.default;
      }
    });
})();

export default allPbcComponent;
