import { restApi } from '@icp/settings';
import { parseJSON } from '@icp/utils';
import appConfig from '@site/appConfig/app.config.json';
import appRoutes from '@site/appConfig/routers.json';

function injectAppConfigOverrides(appConf) {
  return {
    ...appConf,
    i18n: {
      ...appConf?.i18n,
      // 允许免构建修改i18n配置
      // eslint-disable-next-line no-underscore-dangle
      ...window.__icp_i18n__,
    },
  };
}

// eslint-disable-next-line consistent-return
export default function loadFrontendConfig() {
  if (process.env.SAAS_MODE) {
    return restApi.get('/form/api/front-end-config').then((res) => {
      // 刚创建的项目 front-end-config 是 null
      const frontEndConfig = parseJSON(res?.config) || { appConfig, routers: appRoutes };
      return {
        appConfig: injectAppConfigOverrides(frontEndConfig.appConfig),
        appRoutes: frontEndConfig.routers,
      };
    });
  }

  // 使用 if 包起来 webpack 会在 false 的时候 tree shaking 舍弃顶部 import 的 appConfig 和 appRoutes
  // 用 dynamic import 会单独拆一个文件出去
  if (!process.env.SAAS_MODE) {
    return Promise.resolve({
      appConfig: injectAppConfigOverrides(appConfig),
      appRoutes,
    });
  }
}
