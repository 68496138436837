import PropTypes from 'prop-types';
import IcpTable from './IcpTable';
import IcpChart from './IcpChart';

function Code(props) {
  const { className, children } = props;
  if (className === 'language-icp-table') {
    return <IcpTable data={children} />;
  }

  if (className === 'language-icp-chart') {
    return <IcpChart data={children} />;
  }

  return <code className={className}>{children}</code>;
}

Code.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Code;
