import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import RadioMaterial from './RadioMaterial';
import RadioAnt from './RadioAnt';

const RadioUI = forwardRef(function CheckboxUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <RadioMaterial {...props} ref={ref} />;
  }

  return <RadioAnt {...props} ref={ref} />;
});

export default RadioUI;
