import loadable from '@loadable/component';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "saas-ui-chat" */
      /* webpackPrefetch: true */
      './Chat'
    ),
);
