import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import FormRendererSelector from './FormRendererSelector';

const DefaultFormRenderer = forwardRef(function DefaultFormRenderer(props, ref) {
  const params = useParams();

  const {
    schemaId = params.schemaId,
    retrieveUrl,
    createUrl,
    updateUrl,
    submitMethod,
    context,
    FormRendererProps,
  } = props;

  return (
    <FormRendererSelector
      {...FormRendererProps}
      schemaId={schemaId}
      retrieveUrl={retrieveUrl}
      createUrl={createUrl}
      updateUrl={updateUrl}
      submitMethod={submitMethod}
      context={context}
      ref={ref}
    />
  );
});

DefaultFormRenderer.propTypes = {
  schemaId: PropTypes.string,
  retrieveUrl: PropTypes.string,
  createUrl: PropTypes.string,
  updateUrl: PropTypes.string,
  submitMethod: PropTypes.oneOf(['post', 'put']),
  context: PropTypes.shape({}),
  FormRendererProps: PropTypes.shape({}),
};

export default DefaultFormRenderer;
