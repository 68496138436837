import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { ConditionalPropertyPropType } from '../../propTypes';
import { FormHelperTextMaterial } from '../../FormHelperText';

function CheckboxMaterial(props) {
  const {
    title,
    value,
    validation,
    disabled,
    readonly,
    // componentProps = {},
    status,
    helpers,
    onChange,

    options,
    // content,
    direction,
    gap,
  } = props;

  const handleChange = (event) => {
    const { name, checked } = event.target;

    const newValue = [...(value || [])];
    if (checked && !newValue.includes(name)) {
      newValue.push(name);
    } else if (!checked && newValue.includes(name)) {
      newValue.splice(newValue.indexOf(name), 1);
    }

    onChange(newValue);
  };

  return Array.isArray(options) ? (
    <FormControl
      required={validation?.required}
      error={status === 'error'}
      disabled={disabled || readonly}
    >
      <FormLabel>{title}</FormLabel>
      <FormGroup
        className="row-element"
        row={direction !== 'vertical'}
        sx={{ gap: (typeof gap === 'number' && gap / 8) || (direction === 'horizontal' && 4) || 0 }}
      >
        {options.map((op) => {
          return (
            <FormControlLabel
              key={op.value}
              control={
                <Checkbox
                  checked={Boolean(value && value.includes(op.value))}
                  name={op.value}
                  onChange={handleChange}
                />
              }
              label={op.label}
            />
          );
        })}
      </FormGroup>
      {!readonly && !helpersIsEmpty(helpers) ? (
        <FormHelperText>
          <FormHelperTextMaterial helpers={helpers} />
        </FormHelperText>
      ) : null}
    </FormControl>
  ) : (
    <Checkbox />
  );
}

CheckboxMaterial.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  ]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  gap: PropTypes.oneOfType([PropTypes.oneOf(['small', 'middle', 'large']), PropTypes.number]),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  status: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckboxMaterial;
