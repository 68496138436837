import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import UploadElement from '../../UploadElement';
import { runCellValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';

const UploadCellEditor = forwardRef(function UploadCellEditor(props, ref) {
  const { value: valueProp, colDef, data, context, componentProps = {} } = props;

  const [value, setValue] = useState(valueProp);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        // TODO: focus input element
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });

        if (!passed) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <UploadElement
        className="table-upload-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          ...componentProps,
          buttonProps: { ...componentProps.buttonProps, size: context.tableSize },
        }}
      />
    </CurrentDataProvider>
  );
});

UploadCellEditor.propTypes = {
  value: PropTypes.shape({
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        storageId: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({
    buttonProps: PropTypes.shape({}),
  }),
};

export default UploadCellEditor;
