import './cellRenderers.css';
import PropTypes from 'prop-types';
import UploadElement from '../../UploadElement';
import withCurrentData from './withCurrentData';

function UploadCellRenderer(props) {
  const { value, componentProps } = props;

  return (
    <UploadElement
      className="table-upload-cell"
      value={value}
      readonly={true}
      componentProps={componentProps}
    />
  );
}

UploadCellRenderer.propTypes = {
  value: PropTypes.shape({
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        storageId: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  data: PropTypes.shape({}),
  componentProps: PropTypes.shape({}),
};

export default withCurrentData(UploadCellRenderer);
