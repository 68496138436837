/**
 * Convert an array to a hash map
 * @param  {} keyMapper defaults to `(item) => item.id`
 * @param  {} valueMapper defaults to `(obj) => obj`
 * @param  {} mergeMapper defaults to `(prevObj, currObj, prevValue, currValue) => currValue`
 * @example toMap()([{ id: 'a' }, { id: 'b' }])
 * // => { a: { id: 'a' }, b: { id: b }}
 * // more usage see unit test in `arrayUtils.test.js`
 */
export function toMap(
  keyMapper = (item) => item.id,
  valueMapper = (obj) => obj,
  mergeMapper = (prevObj, currObj, prevValue, currValue) => currValue,
) {
  return (arr, ...extraArgs) => {
    const map = {};
    const itemMap = {};

    for (const item of arr) {
      const key = keyMapper(item, ...extraArgs);
      const value = valueMapper(item, ...extraArgs);

      if (map[key]) {
        const prevItem = itemMap[key];
        const prevValue = map[key];
        const currValue = valueMapper(item, ...extraArgs);
        map[key] = mergeMapper(prevItem, item, prevValue, currValue, ...extraArgs);
        itemMap[key] = item;
      } else {
        map[key] = value;
        itemMap[key] = item;
      }
    }

    return map;
  };
}

/**
 * distinct a primitive type array
 * @param  {} arr a primitive type array, i.e. element type is `string`, `number`, `bool` ...
 * @param  {} keepOrder defaults to `false`
 * @example distinct([1, 1, 2, 2], true)
 * // => [1, 2]
 */
export function distinct(arr, keepOrder = false) {
  if (!keepOrder) return [...new Set(arr)];
  const uniqueSet = new Set();
  const result = [];
  for (const item of arr) {
    // eslint-disable-next-line no-continue
    if (uniqueSet.has(item)) continue;
    result.push(item);
    uniqueSet.add(item);
  }
  return result;
}

export function toGroup(keyMapper, compareFn) {
  return (arr) => {
    const map = toMap(
      keyMapper,
      (x) => [x],
      (_, __, a, b) => [...a, ...b],
    )(arr);
    const list = Object.entries(map);
    if (!compareFn) return list;
    return list.sort(compareFn);
  };
}
