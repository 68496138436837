import { createBrowserRouter } from 'react-router-dom';
import makeReactRoutes from './makeReactRoutes';

export default function makeIcpRouter({
  AuthWrapper,
  appConfig,
  appRoutes,
  otherRoutes: otherRoutesProp,
  pbcList,
  context,
  onLocationChanged,
  appChildren,
}) {
  const otherRoutes = typeof otherRoutesProp === 'function' ? otherRoutesProp() : otherRoutesProp;

  const routes = makeReactRoutes({
    AuthWrapper,
    context,
    appConfig,
    appRoutes,
    otherRoutes,
    pbcList,
    onLocationChanged,
    appChildren,
  });

  // saas mode 是 try run 的环境在 aip/saas-ui 里，已经打包好了 /ui/ 了无法重新覆盖
  const siteBaseUrl = window.ICP_PUBLIC_PATH;

  // replace /ui/ to /ui
  // 原因：升级到 react-router 6.12.0 过后，如果 basename 是 /ui/，浏览器地址栏访问 /ui 页面会空白，react-router 要求必须带斜杠。
  // 同时，服务器在 try run 的配置是即使手动输入 /ui/ 也会 302 自动重定向到 /ui，就永远打不开首页了。
  // 改成 /ui 过后 react-router 可以同时支持访问 /ui 以及 /ui/。
  // 此外，@icp/scripts 已经验证了 baseUrl 必须以 / 结尾，不用再判断了。
  const basename =
    siteBaseUrl.length > 1 ? siteBaseUrl.slice(0, siteBaseUrl.length - 1) : siteBaseUrl;

  return createBrowserRouter(routes, { basename });
}
