import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Loading, Icon } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { useDataSource } from '../hooks';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { withFieldWrapper } from '../fieldWrapper';

const NODE_TYPE_APPROVAL = 'APPROVAL';

const TodoListElement = forwardRef(function TodoListElement(props, ref) {
  const { t } = useTranslation(['icp-form-renderer']);

  const { keyPath, id, componentProps, className } = props;

  const {
    dataUrl = '/form/api/form-entity-data-eligible-action/list-by-current-user-info?displayInTodoList=true',
    defaultValues,
    style,
    title,
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const { dataFetched, loading } = useDataSource({ dataUrl });
  const value = dataFetched || defaultValues || [];
  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div className={clsx('todo-list-section form-element', className)} ref={ref} style={style}>
        <div className="todo-list-section-title">{title}</div>
        <div className="todo-list-items-wrapper">
          {loading ? <Loading /> : null}
          {!loading && value?.length === 0 && (
            <div className="todo-list-empty-message">{t('todo-list.empty')}</div>
          )}
          {!loading
            ? (value || []).map((itemValue, index) => {
                const { flowNodeName, createdTime, flowInstanceId, flowThreadId, flowNodeType } =
                  itemValue;
                return (
                  <Link
                    to={
                      flowNodeType === NODE_TYPE_APPROVAL
                        ? `/todo-list/form/${flowInstanceId}/${flowThreadId}/approval`
                        : `/todo-list/form/${flowInstanceId}/${flowThreadId}/confirm`
                    }
                    key={flowInstanceId ?? index}
                  >
                    <div className="todo-list-item">
                      <div className="todo-list-icon" />
                      <div className="todo-list-msg">
                        <Tooltip
                          title={
                            <span>
                              {t('todo-list.message-prefix')} {flowNodeName}{' '}
                              {t('todo-list.message-suffix')}
                            </span>
                          }
                        >
                          {t('todo-list.message-prefix')} <b>{flowNodeName}</b>{' '}
                          {t('todo-list.message-suffix')}
                        </Tooltip>
                      </div>
                      <div>
                        {t('todo-list.create-time')}
                        {createdTime}
                      </div>
                    </div>
                  </Link>
                );
              })
            : null}
        </div>
      </div>
    </ElementDecorator>
  );
});

TodoListElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    title: PropTypes.string,
    dataUrl: PropTypes.string,
    defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
    style: PropTypes.shape({}),
  }),
};

const mockData = [
  { flowNodeName: 'XXXX', createdTime: 'YYYY/MM/DD hh:mm:ss' },
  { flowNodeName: 'XXXX', createdTime: 'YYYY/MM/DD hh:mm:ss' },
];

const TodoListWrapperDesigner = forwardRef(function TodoListWrapperDesigner(props, ref) {
  const { componentProps } = props;
  const { defaultValues: defaultValuesProps, dataUrl: dataUrlProps } = componentProps || {};

  const { t } = useTranslation(['icp-form-renderer']);
  const isInDesign = useIsInDesign();

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const defaultValues = isInDesign ? mockData : defaultValuesProps;
  const dataUrl = isInDesign ? null : dataUrlProps;

  return (
    <div
      className={clsx('todo-list-wrapper', { 'designer-todo-list-wrapper': isInDesign })}
      ref={nodeRef}
    >
      <TodoListElement
        {...props}
        ref={ref}
        componentProps={{ ...componentProps, defaultValues, dataUrl }}
      />
      {isInDesign && (
        <div className="designer-todo-list-annotation">
          <Icon name="ant:question-circle-outlined" />
          <div>
            <div>{t('todo-list.designer-question-tips1')}</div>
            <div>{t('todo-list.designer-question-tips2')}</div>
          </div>
        </div>
      )}
    </div>
  );
});

TodoListWrapperDesigner.propTypes = {
  componentProps: PropTypes.shape({
    dataUrl: PropTypes.string,
    defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TodoListWrapperDesigner.displayName = 'TodoList';

export default withFieldWrapper(TodoListWrapperDesigner);
