import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Icon } from '@icp/components';
import { useState } from 'react';
import { FormHelperTextMaterial } from '../../FormHelperText';

function PasswordMaterial(props) {
  const {
    title,
    value,
    disabled,
    readonly,
    // componentProps = {},
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant="outlined"
      required={validation?.required}
      fullWidth={true}
      disabled={disabled}
      error={status === 'error'}
    >
      <InputLabel htmlFor="password">{title}</InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        label={title}
        autoComplete="new-password"
        defaultValue={value}
        onChange={onChange}
        onBlur={onTouchChanged}
        readOnly={readonly}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <Icon name="solar:eye-bold" size={24} />
              ) : (
                <Icon name="solar:eye-closed-bold" size={20} style={{ margin: 2 }} />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
      {!readonly && !helpersIsEmpty(helpers) ? (
        <FormHelperText>
          <FormHelperTextMaterial helpers={helpers} />
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

PasswordMaterial.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  componentProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

export default PasswordMaterial;
