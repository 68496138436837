import PropTypes from 'prop-types';
import { cloneElement, isValidElement } from 'react';
import { DataPredicatePropType, PermissionPredicatePropType } from '../propTypes';
import { useConditionalProperty } from '../hooks';

// TODO, 这个组件内部已经没有使用了，只有以前的 code generator 可能在用，并且传给 useConditionalProperty 的值也没有支持最新的数组 matched 形式
function ConditionalHidden(props) {
  const {
    children,
    permissionPredicate,
    dataPredicate,
    valueIfPositive,
    valueIfNegative,
    ...other
  } = props;

  const hidden = useConditionalProperty({
    permissionPredicate,
    dataPredicate,
    valueIfPositive,
    valueIfNegative,
  });

  if (hidden) {
    return null;
  }

  // ConditionalHidden requires children to be only on react element
  if (!isValidElement(children)) {
    // TODO，其实应该判断是否是我们自己的 element，是就 cloneElement，否则返回 children，以支持自定义 hard code div 作为 children
    return null;
  }

  return cloneElement(children, { ...other });
}

ConditionalHidden.propTypes = {
  children: PropTypes.element,
  permissionPredicate: PermissionPredicatePropType,
  dataPredicate: DataPredicatePropType,
  valueIfPositive: PropTypes.bool,
  valueIfNegative: PropTypes.bool,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ConditionalHidden.displayName = 'ConditionalHidden';

export default ConditionalHidden;
