import clsx from 'clsx';
import PropTypes from 'prop-types';
import Icon from '../Icon';

function parseIcon(icon) {
  if (!icon) {
    return null;
  }

  if (typeof icon === 'string') {
    return <Icon name={icon} size={24} />;
  }

  return icon;
}

function Step(props) {
  const { className, labelPlacement, index, active, status, icon, title, description, ...other } =
    props;

  return (
    <>
      {labelPlacement === 'horizontal' && index > 0 ? (
        <div className={clsx('icp-step-connector', status)} />
      ) : null}
      <div
        className={clsx('icp-step', className, `label-${labelPlacement}`, status, {
          active,
        })}
        {...other}
      >
        {labelPlacement === 'vertical' && index > 0 ? (
          <div className={clsx('icp-step-connector', status)} />
        ) : null}
        <div className="icp-step-icon">
          {parseIcon(icon) ||
            (status === 'error' && <Icon name="oct:x-circle-fill" size={24} />) ||
            (status === 'finish' && <Icon name="oct:check-circle-fill" size={24} />) || (
              <span className="icp-step-index-icon icp-center">
                <span>{index + 1}</span>
              </span>
            )}
        </div>
        <div className="icp-step-label">
          <div className="icp-step-title">{title}</div>
          <div className="icp-step-description">{description}</div>
        </div>
      </div>
    </>
  );
}

Step.propTypes = {
  className: PropTypes.string,
  labelPlacement: PropTypes.string,
  index: PropTypes.number,
  active: PropTypes.bool,
  status: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Step;
