import { Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';

function GenerateButton(props) {
  const { disabled, prompt, onGenerate } = props;
  const { t } = useTranslation(['icp-components']);
  const [isNarrow, setIsNarrow] = useState(false);

  useEffect(() => {
    const aiAgentRootElement = document.querySelector('.ai-agent');
    const checkWidth = () => {
      if (aiAgentRootElement) {
        setIsNarrow(aiAgentRootElement.offsetWidth < 400);
      }
    };
    const observer = new ResizeObserver(() => checkWidth());
    observer.observe(aiAgentRootElement);
    checkWidth();
    return () => {
      observer.disconnect();
    };
  }, []);

  return isNarrow ? (
    <Tooltip title={t('ai-agent.generate')}>
      <Button
        className="ai-agent-input-button"
        style={{ padding: '8px' }}
        disabled={disabled}
        type="primary"
        onClick={() => onGenerate(prompt)}
        icon={<Icon name="ant:thunderbolt-outlined" />}
      />
    </Tooltip>
  ) : (
    <Button
      className="ai-agent-input-button"
      disabled={disabled}
      type="primary"
      onClick={() => onGenerate(prompt)}
      icon={<Icon name="ant:thunderbolt-outlined" />}
    >
      {t('ai-agent.generate')}
    </Button>
  );
}

GenerateButton.propTypes = {
  disabled: PropTypes.bool,
  prompt: PropTypes.string,
  onGenerate: PropTypes.func,
};

export default GenerateButton;
