import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, Provider } from 'react-redux';
import { makeStore } from '@icp/form-renderer-core';
import { useAppContext } from '@icp/app';

const StoreContext = createContext(null);
export const useStore = createStoreHook(StoreContext);
export const useDispatch = createDispatchHook(StoreContext);
export const useSelector = createSelectorHook(StoreContext);

const cpfCtx = createContext();

export function useCurrentPageForm() {
  return useContext(cpfCtx);
}

const EMPTY_STORE = makeStore({ context: {} });

function CurrentPageFormStoreProvider({ children }) {
  const formService = useAppContext()?.formService;
  const [currentPageForm, setCurrentPageForm] = useState(null);

  useEffect(() => {
    if (!formService) return () => {};
    const listener = () => {
      // 取第一个非dialog的form
      const form = formService.list.find(({ formApi }) => formApi.getContext().isMainFormRenderer);
      setCurrentPageForm(form);
    };
    formService.on('register', listener);
    return () => {
      formService.off('register', listener);
      setCurrentPageForm(null);
    };
  }, [formService]);

  return (
    <cpfCtx.Provider value={currentPageForm}>
      <Provider store={currentPageForm?.store || EMPTY_STORE} context={StoreContext}>
        {children}
      </Provider>
    </cpfCtx.Provider>
  );
}

CurrentPageFormStoreProvider.propTypes = {
  children: PropTypes.node,
};

export default CurrentPageFormStoreProvider;
