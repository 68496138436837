import PropTypes from 'prop-types';
import { Tree } from 'antd';
import { useLoadTreeData } from '../FieldTreeSelect/utils';
import Loading from '../Loading';

export function FieldTree({
  projectToken,
  pbcToken,
  formEntityToken,
  multiple,
  value,
  onChange,
  ...other
}) {
  if (!value) {
    value = [];
  }

  const [data, loading] = useLoadTreeData({
    initialValue: value,
    projectToken,
    pbcToken,
    formEntityToken,
  });

  if (projectToken == null || pbcToken == null || formEntityToken == null) {
    return null;
  }

  const handleChange = (newValue) => {
    if (!newValue) {
      onChange([]);
      return;
    }
    if (multiple) {
      onChange(newValue);
      return;
    }
    onChange([newValue]);
  };

  if (loading) {
    return <Loading delayed={false} />;
  }

  return (
    <Tree
      multiple={multiple}
      autoExpandParent={true}
      defaultExpandAll={data?.loadedKeys?.length < 50}
      checkable={multiple}
      checkStrictly={multiple}
      fieldNames={{
        title: 'label',
        key: 'value',
        children: 'children',
      }}
      treeData={data?.treeData || []}
      loadData={data?.loadData}
      loadedKeys={data?.loadedKeys}
      checkedKeys={value}
      selectedKeys={value}
      onCheck={(e) => handleChange(e.checked)}
      onSelect={handleChange}
      {...other}
    />
  );
}

FieldTree.propTypes = {
  projectToken: PropTypes.string,
  pbcToken: PropTypes.string,
  formEntityToken: PropTypes.string,
  multiple: PropTypes.bool,
  // 无论多选单选统一传入value数组
  value: PropTypes.arrayOf(PropTypes.string),
  // 无论多选单选统一返回value数组
  onChange: PropTypes.func,
};

export default FieldTree;
