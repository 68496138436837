import { useRef } from 'react';
import PropTypes from 'prop-types';
import { HTMLElementType, isMacOs } from '@icp/utils';
import clsx from 'clsx';
import { Icon } from '../index';
import Menu from './Menu';
import { getShortCutLabel, isInTriangle } from './utils';

const LOCK_SUB_MENU_TIME = 400;

function MenuItem(props) {
  const {
    itemKey,
    label,
    icon,
    danger,
    disabled,
    selected,
    shortcut,
    children,
    subAnchorEl,
    onClick,
    onOpenSubMenu,
    onCloseSubMenu,
    onLockSubMenu,
    onReleaseSubMenu,
  } = props;

  const itemRef = useRef(null);
  const subMenuRef = useRef(null);
  const lockTimer = useRef(null);
  // 上一次鼠标位置用过比较鼠标是否在往 sub menu 相反的方向移动
  const prevMouseX = useRef(0);
  // 记录鼠标移动三角形区域的起始坐标点，从鼠标在 menu item 内第一次往 sub menu 方向移动的点开始
  const triangleP1 = useRef({ x: 0, y: 0 });

  const hasSubMenu = Array.isArray(children) && children.length > 0;
  const isSubMenuOpen = hasSubMenu && subAnchorEl && subAnchorEl === itemRef.current;

  const handleClick = (event) => {
    event.stopPropagation();

    if (!disabled && !children) {
      onClick({ key: itemKey });
    }
  };

  const handleEnter = () => {
    if (disabled) {
      return;
    }

    if (isSubMenuOpen) {
      clearTimeout(lockTimer.current);
      onReleaseSubMenu();
      return;
    }

    if (hasSubMenu) {
      onOpenSubMenu(itemRef.current);
    } else {
      onCloseSubMenu();
    }
  };

  const handleMove = (event) => {
    if (disabled || !isSubMenuOpen) {
      return;
    }

    const subMenuRect = subMenuRef.current.getBoundingClientRect();
    const subMenuInRight = subMenuRect.x > event.clientX;

    // 鼠标往 sub menu 的反方向移动
    const isReverse = subMenuInRight
      ? event.clientX < prevMouseX.current
      : event.clientX > prevMouseX.current;

    prevMouseX.current = event.clientX;
    if (isReverse) {
      triangleP1.current = { x: event.clientX, y: event.clientY };
    }
  };

  const handleLeave = (event) => {
    if (disabled || !hasSubMenu) {
      return;
    }

    if (!subMenuRef.current) {
      onCloseSubMenu();
      return;
    }

    const subMenuRect = subMenuRef.current.getBoundingClientRect();
    const mousePoint = { x: event.clientX, y: event.clientY };
    const menuInRight = subMenuRect.x > mousePoint.x;
    // 子菜单顶部点屏幕位置
    const triangleP2 = { x: subMenuRect[menuInRight ? 'left' : 'right'], y: subMenuRect.top };
    // 子菜单底部点屏幕定位
    const triangleP3 = { x: subMenuRect[menuInRight ? 'left' : 'right'], y: subMenuRect.bottom };

    // 以鼠标移动的起始位置（鼠标第一次往 sub menu 方向移动的点），sub menu 取靠近 menu item 的两个点构成三角形区域，
    // 如果鼠标移出 menu item 的位置是三角形区域内，表示是有往 sub menu 的方向移动的趋势，则即使 leave 也暂时不关闭 sub menu
    const isIntentSubMenu = isInTriangle(triangleP1.current, triangleP2, triangleP3, mousePoint);

    if (isIntentSubMenu) {
      onLockSubMenu();
      lockTimer.current = setTimeout(() => {
        // 400 ms 内如果没有进入 sub menu 则关闭 sub menu
        onReleaseSubMenu();
        onCloseSubMenu();
      }, LOCK_SUB_MENU_TIME);
    } else {
      onCloseSubMenu();
    }
  };

  const handleSubMenuEnter = () => {
    clearTimeout(lockTimer.current);
    onReleaseSubMenu();
  };

  return (
    <>
      <div
        className={clsx('icp-menu-item icp-center icp-clickable', {
          'icp-danger': danger,
          selected: isSubMenuOpen,
          disabled,
        })}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        onMouseMove={handleMove}
        onClick={handleClick}
        ref={itemRef}
      >
        {selected ? (
          <span className="icp-menu-item-selected-icon">
            <Icon name="oct:check" size={14} />
          </span>
        ) : null}

        {icon ? (
          <span className="icp-menu-icon">
            <Icon name={icon} size={14} />
          </span>
        ) : null}

        <span className="icp-menu-label">{label}</span>

        {shortcut ? (
          <span className={isMacOs() ? 'keyboard-shortcut-spaced' : null}>
            {getShortCutLabel(shortcut)}
          </span>
        ) : null}

        {hasSubMenu ? <Icon name="oct:chevron-right" size={14} style={{ marginLeft: 8 }} /> : null}
      </div>

      {hasSubMenu ? (
        <Menu
          placement="right-start"
          open={isSubMenuOpen}
          offset={0}
          hideBackdrop={true}
          anchorEl={subAnchorEl}
          items={children}
          onClick={onClick}
          onMouseEnter={handleSubMenuEnter}
          ref={subMenuRef}
        />
      ) : null}
    </>
  );
}

MenuItem.propTypes = {
  itemKey: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  shortcut: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      default: PropTypes.string,
    }),
  ]),
  children: PropTypes.arrayOf(PropTypes.shape({})),
  subAnchorEl: HTMLElementType,
  onClick: PropTypes.func,
  onOpenSubMenu: PropTypes.func,
  onCloseSubMenu: PropTypes.func,
  onLockSubMenu: PropTypes.func,
  onReleaseSubMenu: PropTypes.func,
};

export default MenuItem;
