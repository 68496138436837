import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@mui/material';
import { Icon } from '@icp/components';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { selectContext } from '@icp/form-renderer-core';
import { useAppContext } from '@icp/app';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '@icp/i18n';
import dayjs from 'dayjs';
import { useElementDecorator, useFormApi, useIsInDesign } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { useSelector } from '../../store';
import { withFieldWrapper } from '../../fieldWrapper';
import RecursionRenderer from '../../RecursionRenderer/RecursionRenderer';
import ButtonUI from '../ButtonElement/ButtonUI';
import useVariablePattern from '../../hooks/useVariablePattern';

const PageHeaderElement = forwardRef(function PageHeaderElement(props, ref) {
  const {
    children,
    keyPath,
    id,
    className: classNameProp,
    style,
    fields = [],
    componentProps = {},
  } = props;

  // 暂时强制显示 schema.form.title，不允许这个组件覆盖，这样保证在统一的地方设置 title
  const {
    // pageTitle: pageTitleProp,
    // subTitle,
    headerStyle = 'style1',
    backTo = -1,
  } = componentProps;

  const { t } = useTranslation(['icp-common']);
  const isInDesign = useIsInDesign();
  const navigate = useNavigate();
  const appContext = useAppContext();
  const formContext = useSelector(selectContext);
  const formApi = useFormApi();

  // 在 designer 里 appContext.pageTitle 永远无值
  const pageTitle =
    formApi.getTitle() || formContext.layoutToken || formContext.schemaId || appContext.pageTitle;

  const breadcrumb = useMemo(() => {
    // formContext.pbc 是在 PanelCanvas 里放进去的，只在 designer 里才有
    // appContext.pbc 是咋 IcpApp 里放进去的，在 saas-ui 里有（designer 里其实会是 platform 那个 IcpApp，不会读到没事）
    const pbc = formContext.pbc || appContext.pbc || {};
    return [
      {
        path: '/',
        isHome: true,
      },
      {
        title: pbc.name,
        path: `/${pbc.token}`,
      },
      { title: pageTitle !== pbc.name ? pageTitle : null },
    ].filter((item) => item.title || item.isHome);
  }, [appContext.pbc, formContext.pbc, pageTitle]);

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const noChildren = !children && !fields?.length;

  const handleBack = () => {
    navigate(backTo);
  };

  const subTitle = useVariablePattern(':createdTime');
  const subTitleFormatted = useMemo(() => {
    if (isInDesign) {
      return subTitle;
    }
    return formatDateTime(dayjs(subTitle).toDate(), { dateStyle: 'long', timeStyle: 'short' });
  }, [isInDesign, subTitle]);

  if (formContext.isInDialog) {
    return null;
  }

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('form-element page-header-element', className, classNameComp)}
        style={style}
        ref={nodeRef}
      >
        <div className={clsx('page-header-left', headerStyle)}>
          {headerStyle === 'style1' ? (
            <>
              <h1 className="page-title">{pageTitle}</h1>
              {/* TODO, 省事暂时用 material 的组件，这个组件应该是和 template 自带的 PageHeader 显示效果一直的，不应该随便 FormRenderer 的 componentLibrary 变 */}
              <Breadcrumbs separator={<Icon name="oct:dot-fill" size={8} />}>
                {breadcrumb.map((item, index) => {
                  if (item.path) {
                    return (
                      <Link key={`${item.path}${item.title}${index}`} to={item.path}>
                        {item.isHome ? 'Home' : item.title}
                      </Link>
                    );
                  }
                  return <span key={`${item.path}${item.title}${index}`}>{item.title}</span>;
                })}
              </Breadcrumbs>
            </>
          ) : null}

          {headerStyle === 'style2' ? <h1 className="page-title">{pageTitle}</h1> : null}

          {headerStyle === 'style3' ? (
            <>
              <ButtonUI type="text" icon="oct:chevron-left" onClick={handleBack}>
                {t('back', { ns: 'icp-common' })}
              </ButtonUI>
              <div style={{ flex: 1 }} />
            </>
          ) : null}

          {headerStyle === 'style4' ? (
            <>
              <ButtonUI type="text" icon="oct:chevron-left" onClick={handleBack} />
              <div>
                <h1 className="page-title">{pageTitle}</h1>
                <span className="page-sub-title">{subTitleFormatted}</span>
              </div>
            </>
          ) : null}
        </div>
        <div
          className={clsx('page-header-actions', {
            'no-children': noChildren,
          })}
        >
          {noChildren ? (
            <div
              className="page-header-actions-empty form-element"
              data-key-path={(keyPath || []).concat('fields')}
            />
          ) : (
            children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />
          )}
        </div>
      </div>
    </ElementDecorator>
  );
});

PageHeaderElement.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  componentProps: PropTypes.shape({
    pageTitle: PropTypes.string,
    headerStyle: PropTypes.oneOf(['style1', 'style2', 'style3', 'style4']),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
PageHeaderElement.displayName = 'PageHeader';

export default withFieldWrapper(PageHeaderElement);
