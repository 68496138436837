export function isOverflowElement(el) {
  const { overflowX, overflowY } = getComputedStyle(el);

  return ['auto', 'scroll', 'overlay'].find((item) => overflowX === item || overflowY === item);
}

export function getOverflowAncestors(el) {
  const parent = el?.parentNode;

  if (!parent) {
    return [];
  }

  if (parent === document.documentElement) {
    return [];
  }

  return [isOverflowElement(parent) ? parent : null, ...getOverflowAncestors(parent)].filter(
    Boolean,
  );
}
