import { dataSourceToColumnDefs } from '@icp/form-designer';
import { parseJSON } from '@icp/utils';
import { loadFormEntity } from '../formEntityLoader';

export function makeTableElement(formEntity) {
  const columnDefs = dataSourceToColumnDefs({
    fields: formEntity.fields || [],
    dataSourceFormEntity: formEntity,
  });

  columnDefs.push({
    type: 'ACTION_COLUMN',
    cellRendererParams: {
      displayLikeButton: true,
      showIcon: true,
      actions: [
        {
          type: 'VIEW',
          href: `/${formEntity.pbcToken}/form/:id/view`,
        },
      ],
    },
  });

  const element = {
    style: {
      height: '80vh',
    },
    component: 'Table',
    componentProps: {
      suppressAddButton: true,
      dataSource: {
        pbcToken: formEntity.pbcToken,
        token: formEntity.token,
      },
      columnDefs,
    },
  };

  return element;
}

export async function transformToTableElement(input) {
  try {
    input = parseJSON(input, { silent: true, autocomplete: true });
    if (!input) return null;
    const query = input.queryPayload || input.query;
    if (!query) return null;
    if (!input.formEntity && (!input.formEntityToken || !input.pbcToken)) return null;

    let formEntity = input.formEntity;
    if (!formEntity) {
      formEntity = await loadFormEntity(input.pbcToken, input.formEntityToken);
    }

    // 优先使用指定的显示列
    const knownFieldTokenList = formEntity.fields?.map((f) => f.token) ?? [];
    const specifiedFieldTokenList =
      input.columns
        ?.split(',')
        .map((x) => x.trim())
        .filter((fieldToken) => knownFieldTokenList.includes(fieldToken)) ?? [];
    const fields =
      specifiedFieldTokenList.length > 0
        ? specifiedFieldTokenList.map((fieldToken) =>
            formEntity.fields.find((f) => f.token === fieldToken),
          )
        : formEntity.fields ?? [];

    const columnDefs = dataSourceToColumnDefs({
      fields,
      dataSourceFormEntity: formEntity,
    });

    columnDefs.push({
      type: 'ACTION_COLUMN',
      cellRendererParams: {
        displayLikeButton: true,
        showIcon: true,
        actions: [
          {
            type: 'VIEW',
            href: `/${input.pbcToken}/form/:id/view`,
          },
        ],
      },
    });

    const queryPayloadEscaped = query?.trim()?.replaceAll(':', '\\:') || '';

    const element = {
      component: 'Table',
      componentProps: {
        suppressToolbar: true,
        rowModelType: 'clientSide',
        dataUrl: `/form/api/v2/form-entity-data/${formEntity.pbcToken}/${formEntity.token}/list?payload=${queryPayloadEscaped}`,
        columnDefs,
      },
    };
    return [element, input.title, formEntity];
  } catch {
    return null;
  }
}
