import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { UploadIcon } from '@primer/octicons-react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const IconUploadElement = forwardRef(function IconUploadElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    componentProps = {},
    fieldTitleProps,
    validation,
    readonly,
    // TODO
    // status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const { t } = useTranslation(['icp-form-renderer', 'icp-common']);
  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const [modal, contextHolder] = Modal.useModal();

  const { size = 44, limit = 100 } = componentProps;

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > limit * 1024) {
      // 100KB
      modal.error({
        content: t('error.icon-size-out-limit'),
      });
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onChange(reader.result);
  };

  const Comp = readonly ? 'div' : 'label';

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          'icon-upload-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          <Comp
            className={clsx('icon-upload-wrapper', readonly && 'view-only')}
            style={{ height: size, width: size }}
          >
            {value ? (
              <img src={value} alt="Flow Icon" />
            ) : (
              <div className="icon-upload-default-icon">{t('upload', { ns: 'icp-common' })}</div>
            )}
            {!readonly && (
              <>
                <span className="icon-upload-indicator">
                  <UploadIcon size={size / 2} />
                </span>
                <input
                  className={classNameComp}
                  disabled={disabled}
                  type="file"
                  onChange={handleChange}
                  accept="image/*"
                  style={{ display: 'none' }}
                  onBlur={onTouchChanged}
                />
              </>
            )}
          </Comp>
          <FormHelperText helpers={helpers} />
        </div>
        {contextHolder}
      </div>
    </ElementDecorator>
  );
});

IconUploadElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * Icon 的尺寸
     */
    size: PropTypes.number,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
IconUploadElement.displayName = 'IconUpload';

export default withFieldWrapper(IconUploadElement);
