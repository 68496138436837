import { dataSourceToDataUrl, selectContext } from '@icp/form-renderer-core';
import { useConditionalProperty } from './useConditionalProperty';
import useVariablePattern from './useVariablePattern';
import { useStore } from '../store';

export default function useDataUrl({ dataUrlProp, dataSource }) {
  const store = useStore();
  const context = selectContext(store.getState());

  const dataUrlConfig = dataUrlProp || dataSourceToDataUrl(dataSource, context);
  const dataUrlString = useConditionalProperty(dataUrlConfig);

  return useVariablePattern(dataUrlString, true);
}
