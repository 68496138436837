import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { restApi } from '@icp/settings';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import SearchBox from '../SearchBox';
import Tabs, { Tab } from '../Tabs';
import IconSection from './IconSection';
import Menu from '../Menu';

function getInitTab(name) {
  let tab;
  if (typeof name === 'string') {
    if (name.includes(':')) {
      tab = name.split(':')[0];
    } else {
      tab = 'icp';
    }
  }
  return ['icp', 'oct', 'ant', 'material', 'solar', 'emoji'].includes(tab) ? tab : 'material';
}

function IconList(props) {
  const { name, onChange } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);
  const [icons, setIcons] = useState(new Map());
  const [currentTab, setCurrentTab] = useState(() => getInitTab(name));
  const [searchText, setSearchText] = useState('');
  const [actionsOpen, setActionsOpen] = useState(null);

  const searchRef = useRef(null);
  const scrollDiv = useRef(null);

  useEffect(() => {
    // icp
    restApi
      .getStatic(`${window.ICP_PUBLIC_PATH}icons/icp/data.json`, {
        params: { v: process.env.BUILD_NUMBER || 'DEV' },
      })
      .then((names) => {
        icons.set('icp', [
          {
            key: '',
            theme: '',
            list: names.filter((n) => n !== 'three-dot-loading'),
          },
        ]);
        setIcons(new Map(icons));
      });
    // oct
    restApi
      .getStatic(`${window.ICP_PUBLIC_PATH}icons/oct/data.json`, {
        params: { v: process.env.BUILD_NUMBER || 'DEV' },
      })
      .then((res) => {
        icons.set('oct', [
          {
            key: '',
            theme: '',
            list: res.map((item) => {
              return {
                name: `oct:${item.name}`,
                search: item.search,
              };
            }),
          },
        ]);
        setIcons(new Map(icons));
      });
    // ant
    restApi
      .getStatic(`${window.ICP_PUBLIC_PATH}icons/ant/data.json`, {
        params: { v: process.env.BUILD_NUMBER || 'DEV' },
      })
      .then((res) => {
        icons.set(
          'ant',
          res.map((item) => {
            return {
              ...item,
              list: item.list.map((iconName) => `ant:${iconName}`),
            };
          }),
        );
        setIcons(new Map(icons));
      });
    // material
    restApi
      .getStatic(`${window.ICP_PUBLIC_PATH}icons/material/data.json`, {
        params: { v: process.env.BUILD_NUMBER || 'DEV' },
      })
      .then((res) => {
        icons.set(
          'material',
          res.data
            .map((item) => {
              return {
                ...item,
                list: item.list.map((iconName) => {
                  const nameNoTheme = iconName.replace(/-(outlined|two-tone|rounded|sharp)$/, '');
                  return {
                    name: `material:${iconName}`,
                    search: res.search[nameNoTheme],
                  };
                }),
              };
            })
            .sort((a, b) => {
              // 把 rounded 放到最前面作为推荐使用。紧跟着 outlined 比较合理，因为 filled 和 sharpe 和 rounded 基本相同
              if (a.key === 'rounded' && b.key === 'outlined') return -1;
              if (a.key === 'rounded') return -1;
              if (a.key === 'outlined') return -1;
              return 0;
            }),
        );
        setIcons(new Map(icons));
      });
    // solar
    restApi
      .getStatic(`${window.ICP_PUBLIC_PATH}icons/solar/data.json`, {
        params: { v: process.env.BUILD_NUMBER || 'DEV' },
      })
      .then((res) => {
        icons.set(
          'solar',
          res
            .map((item) => {
              return {
                ...item,
                list: item.list.map((iconName) => `solar:${iconName}`),
              };
            })
            .sort((a) => {
              // 把 bold-duotone 放到最前面作为推荐使用。
              if (a.key === 'bold-duotone') return -1;
              return 0;
            }),
        );
        setIcons(new Map(icons));
      });
    // emoji
    restApi
      .getStatic(`${window.ICP_PUBLIC_PATH}/emojis.json`, {
        params: { v: process.env.BUILD_NUMBER || 'DEV' },
      })
      .then((emojis) => {
        icons.set('emoji', emojis);
        setIcons(new Map(icons));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentIcons = icons.get(currentTab);

  useEffect(() => {
    if (!currentIcons) {
      return;
    }

    setTimeout(() => {
      searchRef.current.focus();
    }, 16);
  }, [currentIcons]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setActionsOpen({
      name: event.currentTarget.title,
      anchorEl: {
        getBoundingClientRect: () => ({
          width: 0,
          height: 0,
          left: event.clientX,
          right: event.clientX,
          top: event.clientY,
          bottom: event.clientY,
        }),
      },
    });
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'copy') {
      navigator.clipboard.writeText(actionsOpen.name);
    }
  };

  return (
    <>
      <div className="icp-icon-picker-list-header">
        <Tabs value={currentTab} onChange={setCurrentTab} style={{ padding: 0, borderBottom: 0 }}>
          <Tab value="material">Material</Tab>
          <Tab value="solar">Solar</Tab>
          <Tab value="oct">Octicons</Tab>
          <Tab value="ant">Ant Design</Tab>
          <Tab value="icp">Icp</Tab>
          <Tab value="emoji">Emoji</Tab>
        </Tabs>
        {name ? (
          <button className="icp-action-button" onClick={() => onChange()}>
            {t('remove', { ns: 'icp-common' })}
          </button>
        ) : null}
      </div>
      <SearchBox
        className="icp-icon-picker-list-search"
        open={true}
        suppressSpeech={true}
        debounce={false}
        onSearch={setSearchText}
        ref={searchRef}
      />
      <div
        className="icp-icon-picker-list-content icp-thin-scrollbar"
        ref={scrollDiv}
        onContextMenu={(event) => event.preventDefault()}
      >
        {!currentIcons ? <Loading /> : null}
        {currentIcons
          ? currentIcons.map((section) => {
              return (
                <IconSection
                  key={`${currentTab}${section.theme || section.category}`}
                  currentTab={currentTab}
                  currentIcon={name}
                  section={section}
                  searchText={searchText}
                  scrollDiv={scrollDiv.current}
                  onChange={onChange}
                  onContextMenu={handleContextMenu}
                />
              );
            })
          : null}
      </div>
      <Menu
        placement="right-start"
        open={!!actionsOpen}
        anchorEl={actionsOpen?.anchorEl}
        onClose={() => setActionsOpen(null)}
        items={[
          {
            key: 'copy',
            label: 'Copy Icon Name',
          },
        ]}
        onClick={handleMenuClick}
      />
    </>
  );
}

IconList.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default IconList;
