/* eslint-disable import/no-dynamic-require */
import loadRequire from './loadRequire';
import formatJSCode from '../formatJSCode';

let promise;

function loadEditor(require) {
  return new Promise((resolve) => {
    require(['vs/editor/editor.main'], (res) => resolve({ monaco: res, require }));
  });
}

function loadGroovyLang({ monaco, require }) {
  const loader = () => {
    return new Promise((resolve, reject) => {
      require(['monaco-groovy'], resolve, reject);
    });
  };

  monaco.languages.register({
    id: 'groovy',
    extensions: ['.groovy'],
    aliases: ['Groovy'],
    loader,
  });

  monaco.languages.onLanguage('groovy', () => {
    loader().then(
      (mod) => {
        monaco.languages.setLanguageConfiguration('groovy', mod.conf);
      },
      (e) => console.log(e),
    );
  });

  monaco.languages.setMonarchTokensProvider(
    'groovy',
    loader().then((mod) => mod.language),
  );

  return monaco;
}

function defineTheme(monaco) {
  monaco.editor.defineTheme('icp-light', {
    base: 'vs',
    inherit: true,
    rules: [],
    // https://code.visualstudio.com/api/references/theme-color#editor-colors
    colors: {
      'editorLineNumber.foreground': '#aaa',
      'editorIndentGuide.background1': '#efefef',
      'editorIndentGuide.activeBackground1': '#bbb',
      'editor.lineHighlightBackground': '#f8f8f8',
    },
  });
  monaco.editor.setTheme('icp-light');
  return monaco;
}

function defineFormatter(monaco) {
  const jsFormatProvider = {
    provideDocumentFormattingEdits: (model) => {
      return formatJSCode(model.getValue()).then((formattedString) => {
        return [
          {
            range: model.getFullModelRange(),
            text: formattedString,
          },
        ];
      });
    },
  };
  monaco.languages.registerDocumentFormattingEditProvider('javascript', jsFormatProvider);
  return monaco;
}

export default function loadMonaco() {
  if (promise) {
    return promise;
  }
  promise = loadRequire()
    .then(loadEditor)
    .then(loadGroovyLang)
    .then(defineTheme)
    .then(defineFormatter);
  return promise;
}
