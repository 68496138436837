import PropTypes from 'prop-types';
import { cloneElement, useEffect, useRef } from 'react';
import { useEventCallback } from '@icp/hooks';
import { HTMLElementType } from '@icp/utils';

// 全局只注册一个keydown监听
const listeners = new Set();
let initialized = false;
const init = () => {
  if (initialized) return;
  initialized = true;
  document.addEventListener('keydown', (event) => {
    listeners.forEach((listener) => {
      try {
        listener(event);
      } catch {
        // ignore
      }
    });
  });
};

// 来自父组件的hack函数继续向下hack
function mergeChildActions(props, childProps) {
  const entries = Object.entries(childProps)
    .filter(([key, value]) => typeof value === 'function' && typeof props[key] === 'function')
    .map(([key, func]) => [
      key,
      (...args) => {
        props[key](...args);
        func(...args);
      },
    ]);
  return Object.fromEntries(entries);
}

function KeyboardShortcut({
  children,
  target,
  keybindings,
  // 属性向里穿透，否则外层是antd/Tooltip内层是button时将无法工作
  ...otherProps
}) {
  init();

  const ignoreRef = useRef({});

  const handleKeyDown = useEventCallback((event) => {
    keybindings
      .filter(({ when }) => Object.entries(when).every(([k, v]) => event[k] === v))
      .forEach(
        ({
          triggerEventHandler,
          disabled = children.props.disabled,
          ignoreTailgating = true,
          //
        }) => {
          event.preventDefault();
          if (disabled) return;

          if (ignoreTailgating) {
            const prev = ignoreRef.current[triggerEventHandler];
            ignoreRef.current[triggerEventHandler] = setTimeout(() => {
              ignoreRef.current[triggerEventHandler] = null;
            }, 500);

            if (prev != null) {
              clearTimeout(prev);
              return;
            }
          }

          children.props[triggerEventHandler]?.(event);
        },
      );
  });

  useEffect(() => {
    if (target) {
      target.addEventListener('keydown', handleKeyDown);

      return () => {
        target.removeEventListener('keydown', handleKeyDown);
      };
    }

    listeners.add(handleKeyDown);

    return () => {
      listeners.delete(handleKeyDown);
    };
  }, [handleKeyDown, target]);

  return cloneElement(children, {
    ...otherProps,
    ...mergeChildActions(otherProps, children.props),
  });
}

KeyboardShortcut.propTypes = {
  // keydown监听对象，不传则监听document
  target: HTMLElementType,
  keybindings: PropTypes.arrayOf(
    PropTypes.shape({
      // keyof KeyboardEvent
      when: PropTypes.shape({}),
      // e.g. onClick
      triggerEventHandler: PropTypes.string,
      // defaults to children.props.disabled
      disabled: PropTypes.bool,
      // true: 立即执行并忽略过于紧随的触发, 用于防止高频触发
      ignoreTailgating: PropTypes.bool,
    }),
  ),
};

export default KeyboardShortcut;
