import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import InputAnt from './InputAnt';
import InputMaterial from './InputMaterial';

const InputUI = forwardRef(function InputUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <InputMaterial {...props} ref={ref} />;
  }

  return <InputAnt {...props} ref={ref} />;
});

export default InputUI;
