import { restApi, shouldTranslateByDefault } from '@icp/settings';
import { EvalWorker } from '@icp/utils';
import { get } from 'lodash-es';
import { translateEntireObj } from '@icp/i18n';

export default class EnumLoader {
  #reqCache = new Map();

  static #buildRequest = (config) => {
    const {
      dataUrl,
      dataResponseKeyPath,
      translateDataResponse = shouldTranslateByDefault(),
      transformDataResponse,
    } = config;

    return restApi
      .get(dataUrl)
      .then((res) => {
        if (!dataResponseKeyPath) return res;
        return get(res, dataResponseKeyPath);
      })
      .then((res) => {
        if (!transformDataResponse) return res;
        return EvalWorker.shared().execEval(res, transformDataResponse);
      })
      .then((res) => {
        if (!translateDataResponse) return res;
        translateEntireObj(res);
        return res;
      });
  };

  clearCache() {
    this.#reqCache.clear();
  }

  getCache(config) {
    const cacheKey = JSON.stringify(config);
    if (!this.#reqCache.has(cacheKey)) return null;
    const { result } = this.#reqCache.get(cacheKey);
    return result;
  }

  load(config) {
    const cacheKey = JSON.stringify(config);
    if (this.#reqCache.has(cacheKey)) {
      const { pending, result, error, request } = this.#reqCache.get(cacheKey);
      if (pending) throw request;
      if (error) throw error;
      return result;
    }

    const request = EnumLoader.#buildRequest(config);

    const newCache = {
      pending: true,
      result: null,
      error: null,
      request,
    };
    this.#reqCache.set(cacheKey, newCache);

    request.then(
      (res) => {
        newCache.result = res;
        newCache.pending = false;
      },
      (err) => {
        newCache.error = err;
        newCache.pending = false;
      },
    );

    throw request;
  }
}
