import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { selectUserPermissionMap, selectUserProfile } from 'store/features/authSlice';
import { setup } from '@icp/settings';
import restApi from 'services/restApi';
import IcpApp, { useAppContext } from '@icp/app';
import store from './store';
import AuthWrapper from './components/PageRouter/AuthWrapper';
import SSOCallback from './components/PageRouter/SSOCallback';
import OpAuthorize from './components/PageRouter/OpAuthorize';
import CurrentPageFormStoreProvider from './CurrentPageFormStoreProvider';
import SaleConsultationAI from './customerFeatures/jcfw/SaleConsultationAI';
import JobSuggestionAIAgent from './customerFeatures/jobsuggestion/JobSuggestionAIAgent';
import PDFChatAI from './customerFeatures/pdfchat';
import GeneralAIAgent from './GeneralAIAgent';
import DemoChat from './components/DemoChat';
import Chat from './components/Chat';
import PersonalDashboard from './components/Dashboard/PersonalDashboard';
import * as extensionComponents from './extensionComponents';

setup({ restApi, extensionComponents });

const otherRoutes = [
  {
    noNeedAuth: true,
    path: '/login/sso-callback/:loginSourceToken',
    element: <SSOCallback />,
  },
  {
    noNeedAuth: true,
    path: '/login/op/authorize',
    element: <OpAuthorize />,
  },
  {
    path: '/chat',
    element: <DemoChat />,
  },
  {
    path: '/ai-chat',
    element: <Chat />,
  },
  {
    path: '/sale-consultation-ai',
    element: <SaleConsultationAI />,
  },
  {
    path: '/personal-dashboard',
    element: <PersonalDashboard />,
  },
  {
    noNeedAuth: true,
    path: '/pdf-chat',
    element: <PDFChatAI />,
  },
];

function AppChildren() {
  const userProfile = useSelector(selectUserProfile);
  const context = useAppContext();

  if (!userProfile) return null;

  const useAgents = !!context?.pbcList?.find((pbc) => pbc.token === 'system-agent');

  if (useAgents) {
    return <GeneralAIAgent userProfile={userProfile} />;
  }

  const aiAgentConfPbc = context?.pbcList?.find((pbc) => pbc.token.startsWith('ai-agent-config-'));
  const aiAgentConfName = aiAgentConfPbc?.token?.replace('ai-agent-config-', '');
  if (aiAgentConfName === JobSuggestionAIAgent.token) {
    return <JobSuggestionAIAgent userProfile={userProfile} />;
  }

  return null;
}

function App() {
  const userPermissionMap = useSelector(selectUserPermissionMap);
  const userProfile = useSelector(selectUserProfile);

  return (
    <IcpApp
      context={{ userPermissionMap, userProfile }}
      AuthWrapper={AuthWrapper}
      // otherRoutes 里除了 noNeedAuth 都是 react router 的属性,
      // https://reactrouter.com/en/main/route/route
      otherRoutes={otherRoutes}
    >
      <CurrentPageFormStoreProvider>
        <AppChildren />
      </CurrentPageFormStoreProvider>
    </IcpApp>
  );
}

function AppWrap() {
  return (
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  );
}

export default AppWrap;
