const isIDNumber = (value) => {
  if (typeof value !== 'string') return false;
  const reg =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  const passedBasicTest = reg.test(value);
  if (!passedBasicTest) return false;
  // last letter check
  // 身份证号码前面的17位数分别乘以不同的系数。从第一位到第十七位的系数分别为：7－9－10－5－8－4－2－1－6－3－7－9－10－5－8－4－2。
  const eachNoCoefficient = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  // 余数只可能有0－1－2－3－4－5－6－7－8－9－10这11个数字。其分别对应的最后一位身份证的号码为1－0－X－9－8－7－6－5－4－3－2。
  const remaindersArray = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
  let sum = 0;
  for (let i = 0; i < value.length - 1; i++) {
    sum += value[i] * eachNoCoefficient[i];
  }
  const remainder = sum % 11;
  const lastNo = value.slice(-1).toUpperCase();
  return lastNo === remaindersArray[remainder];
};

const isEmail = (value) => {
  const reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+.)+[A-Za-z]{2,6}$/;
  return reg.test(value);
};

const isMobilePhoneNum = (value) => {
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(value);
};

const isStrongPassword = (value) => {
  const p1 = /[a-z]/.test(value);
  const p2 = /[A-Z]/.test(value);
  const p3 = /[0-9]/.test(value);
  const p4 = /[^a-zA-Z0-9]/.test(value);
  return [p1, p2, p3, p4].filter(Boolean).length >= 3;
};

const isVeryStrongPassword = (value) => {
  const p1 = /[a-z]/.test(value);
  const p2 = /[A-Z]/.test(value);
  const p3 = /[0-9]/.test(value);
  const p4 = /[^a-zA-Z0-9]/.test(value);
  return p1 && p2 && p3 && p4;
};

export { isIDNumber, isEmail, isMobilePhoneNum, isStrongPassword };

const validationRulesObj = {
  isIDNumber: { validator: isIDNumber },
  isEmail: { validator: isEmail },
  isMobilePhoneNum: { validator: isMobilePhoneNum },
  isStrongPassword: { validator: isStrongPassword },
  isVeryStrongPassword: { validator: isVeryStrongPassword },
};

export { validationRulesObj };
