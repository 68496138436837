import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName } from '../hooks';
import { useElementDecorator } from '../FormRenderCtx';
import { withFieldWrapper } from '../fieldWrapper';

const StackLayout = forwardRef(function StackLayout(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const {
    flexDirection,
    alignItems,
    justifyContent,
    gap,
    flexWrap,
    style: flexStyle,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        {...otherComponentProps}
        className={clsx(
          'stack-layout form-layout',
          { 'no-children': noChildren },
          className,
          classNameComp,
        )}
        style={{
          flexDirection,
          alignItems,
          justifyContent,
          gap,
          flexWrap,
          ...style,
          ...flexStyle,
        }}
        ref={nodeRef}
      >
        {children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />}
      </div>
    </ElementDecorator>
  );
});

StackLayout.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * Root div 的 className
     */
    className: PropTypes.string,
    /**
     * Root div 的 style
     */
    style: PropTypes.shape({}),
    /**
     * 等同 css `flex` 的属性
     */
    flexDirection: PropTypes.string,
    /**
     * 等同 css `flex` 的属性
     */
    alignItems: PropTypes.string,
    /**
     * 等同 css `flex` 的属性
     */
    justifyContent: PropTypes.string,
    /**
     * 等同 css `flex` 的属性
     * @default 8
     */
    gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 等同 css `flex` 的属性
     */
    flexWrap: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
StackLayout.displayName = 'Stack';

export default withFieldWrapper(StackLayout);
