import { findParentNode, getDataKeyPath } from '@icp/utils';
import {
  FIELD_TYPE_COLLAPSE_ITEM,
  FIELD_TYPE_FORM,
  FIELD_TYPE_STEP,
  FIELD_TYPE_TAB,
  FIELD_TYPE_TABLE_COLUMN,
} from '../constant';

export default function getMouseFieldState(event, keyPath) {
  const { target } = event;

  if (target.classList.contains('fd-canvas') || target.classList.contains('form-renderer')) {
    return { type: FIELD_TYPE_FORM, keyPath: [] };
  }

  if (target.classList.contains('ag-header-cell')) {
    const colId = target.getAttribute('col-id');
    const isEditableTable = !!findParentNode(target, 'editable-table-element');
    const isAutoGroupColColumn = target.getAttribute('col-id') === 'ag-Grid-AutoColumn';
    return {
      type: FIELD_TYPE_TABLE_COLUMN,
      keyPath,
      colId,
      isEditableTable,
      isAutoGroupColColumn,
    };
  }

  if (target.classList.contains('icp-step')) {
    const itemIndex = Number(
      Array.from(target.parentNode.querySelectorAll('.icp-step')).indexOf(target),
    );
    return {
      type: FIELD_TYPE_STEP,
      keyPath,
      itemIndex,
    };
  }

  if (target.classList.contains('ant-collapse-item')) {
    const itemIndex = Number(
      Array.from(target.parentNode.querySelectorAll('.ant-collapse-item')).indexOf(target),
    );
    return {
      type: FIELD_TYPE_COLLAPSE_ITEM,
      keyPath,
      itemIndex,
    };
  }

  if (target.classList.contains('ant-tabs-tab')) {
    const itemIndex = Number(
      Array.from(target.parentNode.querySelectorAll('.ant-tabs-tab')).indexOf(target),
    );
    return {
      type: FIELD_TYPE_TAB,
      keyPath,
      itemIndex,
    };
  }

  if (target.classList.contains('table-element-toolbar-fields-empty')) {
    return {
      keyPath: getDataKeyPath(target),
    };
  }

  if (target.classList.contains('page-header-actions-empty')) {
    return {
      keyPath: getDataKeyPath(target),
    };
  }

  return { keyPath };
}
