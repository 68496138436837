import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import clsx from 'clsx';
import { Icon } from '@icp/components';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName, useConditionalPropertyForItemOfArray } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const CollapseElement = forwardRef(function CollapseElement(props, ref) {
  const {
    keyPath,
    style,
    fields: fieldsProp,
    componentProps = {},
    className: classNameProp,
  } = props;
  const {
    panelHeaders,
    panelProps,
    items: itemsProp,
    itemSplit = false,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  // legacy support
  const itemsMerged = useMemo(() => {
    if (Array.isArray(itemsProp)) {
      return itemsProp;
    }

    if (panelHeaders) {
      return Object.keys(panelHeaders || {}).map((key) => {
        return {
          ...panelProps,
          key,
          label: panelHeaders[key],
          fields: [fieldsProp.find((info) => info.id === key)].filter(Boolean),
        };
      });
    }

    return [];
  }, [panelHeaders, itemsProp, panelProps, fieldsProp]);

  const items = useConditionalPropertyForItemOfArray(itemsMerged, 'hidden');

  return (
    <ElementDecorator keyPath={keyPath}>
      {/* antd 的 collapse 无法传多余的属性 data-key-path 都传不到真实的 div 上，所以再包一层 div */}
      <div
        className={clsx('collapse-element form-element', { 'item-split': itemSplit }, className)}
        style={style}
        ref={nodeRef}
      >
        <Collapse
          key={isInDesign ? String(componentProps.defaultActiveKey) : undefined}
          // 保证 collapse 过后 field 都渲染了，否则 input 组件的 不会验证 hidden 的字段
          forceRender={true}
          bordered={false}
          expandIcon={({ isActive }) => (
            <span>
              <Icon
                name="ant:caret-right-outlined"
                size={12}
                style={{ transform: `rotate(${isActive ? 90 : 0}deg)` }}
              />
            </span>
          )}
          items={items
            .filter((item) => !item.hidden)
            .map((item, itemIndex) => {
              const { key, label, fields, children, ...other } = item;
              return {
                ...other,
                key,
                label,
                children: children || (
                  <RecursionRenderer
                    keyPath={keyPath.concat('componentProps', 'items', itemIndex, 'fields')}
                    fields={fields}
                  />
                ),
              };
            })}
          className={classNameComp}
          {...otherComponentProps}
        />
      </div>
    </ElementDecorator>
  );
});

CollapseElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * @deprecated
   */
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * @deprecated
     * { key: value }  的形式，value 是 antd Collapse 的 Panel 的 header 属性
     */
    panelHeaders: PropTypes.shape({}),
    /**
     * @deprecated
     * antd Collapse Panel 的属性
     */
    panelProps: PropTypes.shape({}),
    /**
     * Collapse 的内容
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        fields: PropTypes.arrayOf(PropTypes.shape({})),
        // code generator 会将 fields 转换为 children
        children: PropTypes.node,
        hidden: ConditionalPropertyPropType(PropTypes.bool),
      }),
    ),
    /**
     * 当值为 true 的时候多个折叠面板直接分开布局，否则面板将会贴合到一起
     */
    itemSplit: PropTypes.bool,
    /**
     * 默认展开的面板
     */
    defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CollapseElement.displayName = 'Collapse';

export default withFieldWrapper(CollapseElement);
