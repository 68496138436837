import { AutoCellRenderer } from './cellRenders';

export const DEFAULT_TABLE_HEIGHT = 300; // designer need a default height
export const DEFAULT_PAGE_SIZE = 30; // 30 rows
export const DEFAULT_INTERVAL_TIME = 10; // 10 second
export const DEFAULT_COL_DEF = {
  flex: 1,
  minWidth: 100,
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
  enableRowGroup: false,
  cellRenderer: AutoCellRenderer,
  filterParams: {
    maxNumConditions: 1,
  },
};

// column types
export const ACL_COLUMN = 'ACL_COLUMN';
export const ACTION_COLUMN = 'ACTION_COLUMN';
export const AUTO_COLUMN = 'AUTO_COLUMN';
export const AUTO_INDEX_COLUMN = 'AUTO_INDEX_COLUMN';
export const CHECKBOX_COLUMN = 'CHECKBOX_COLUMN';
export const DATE_COLUMN = 'DATE_COLUMN';
export const ENUM_COLUMN = 'ENUM_COLUMN';
export const MULTI_VALUE_COLUMN = 'MULTI_VALUE_COLUMN'; /* @deprecated */
export const NUMBER_COLUMN = 'NUMBER_COLUMN';
export const PROGRESS_COLUMN = 'PROGRESS_COLUMN';
export const RADIO_COLUMN = 'RADIO_COLUMN';
export const SELECT_COLUMN = 'SELECT_COLUMN';
export const STATUS_COLUMN = 'STATUS_COLUMN';
export const TEXTAREA_COLUMN = 'TEXTAREA_COLUMN';
export const TEXT_COLUMN = 'TEXT_COLUMN';
export const TIME_COLUMN = 'TIME_COLUMN';
export const UPLOAD_COLUMN = 'UPLOAD_COLUMN';

// column actions
export const ACTION_COLUMN_VIEW = 'VIEW';
export const ACTION_COLUMN_EDIT = 'EDIT';
export const ACTION_COLUMN_DELETE = 'DELETE';
export const ACTION_COLUMN_TREE_DELETE = 'TREE_DELETE'; // 删除当前节点的同时级联删除所有子孙节点
export const ACTION_COLUMN_APPROVAL = 'APPROVAL';
export const ACTION_COLUMN_CALL_GLOBAL_METHOD = 'CALL_GLOBAL_METHOD';
export const ACTION_COLUMN_ACTIONS = [
  ACTION_COLUMN_VIEW,
  ACTION_COLUMN_EDIT,
  ACTION_COLUMN_DELETE,
  ACTION_COLUMN_TREE_DELETE,
  ACTION_COLUMN_APPROVAL,
  ACTION_COLUMN_CALL_GLOBAL_METHOD,
];
