import PropTypes from 'prop-types';
import { Input } from 'antd';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ValueLanguageSelect from '../../../ValueLanguageSelect';

const I18nTextareaMulti = forwardRef(function I18nTextareaMulti(props, ref) {
  const { id, value: valueProp, onChange, ...other } = props;

  const { i18n } = useTranslation();

  const [valueLanguage, setValueLanguage] = useState(i18n.language);

  const valueI18nKey = `longText_i18n_${valueLanguage}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const handleChange = (event) => {
    onChange(event, valueLanguage);
  };

  return (
    <>
      <ValueLanguageSelect value={valueLanguage ?? ''} onChange={setValueLanguage} />
      <Input.TextArea {...other} value={valueI18n} onChange={handleChange} ref={ref} />
    </>
  );
});

I18nTextareaMulti.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default I18nTextareaMulti;
