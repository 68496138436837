import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LNG_STORAGE_KEY, changeLanguage } from './i18nForBrowser';
import { getLanguageOptions } from './otherUtils';

export function useChangeLanguage({
  displayCountryFlagEmoji = false,
  countryEmojiPosition = 'right',
  reloadOnChange = false,
} = {}) {
  const { i18n } = useTranslation();

  const [loader, setLoader] = useState(null);
  if (loader) throw loader;

  const onChangeLanguage = (newLng) => {
    if (reloadOnChange) {
      // utils 里用了 getTFunc 的不会实时刷新
      localStorage.setItem(LNG_STORAGE_KEY, newLng);
      window.location.reload();
    } else {
      setLoader(changeLanguage(newLng).then(() => setLoader(null)));
    }
  };

  const languageOptions = useMemo(() => {
    return getLanguageOptions({
      displayCountryFlagEmoji,
      countryEmojiPosition,
    });
  }, [displayCountryFlagEmoji, countryEmojiPosition]);

  return [i18n.language, onChangeLanguage, languageOptions];
}
