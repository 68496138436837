import PropTypes from 'prop-types';
import { Input } from 'antd';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import { renderReadonlyText } from '../../utils';

function PasswordAnt(props) {
  const {
    title,
    value,
    disabled,
    readonly,
    componentProps = {},
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          renderReadonlyText(componentProps, value?.replace?.(/./g, '·'))
        ) : (
          <Input.Password
            autoComplete="new-password"
            {...componentProps}
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onTouchChanged}
            status={status}
          />
        )}

        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

PasswordAnt.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

export default PasswordAnt;
