import { JS_API_EVENT } from '../constant';

function getArgs(event) {
  const { type, payload } = event;

  if (type === 'fieldValueChange') {
    return [payload.keyPath, payload.value];
  }

  return [payload];
}

export default class EventService {
  constructor(events) {
    this.listeners = new Map();
    this.propsEvents = events;
  }

  on(eventType, listener) {
    if (!JS_API_EVENT.has(eventType)) {
      console.warn('Unknown event type: ', eventType);
      return;
    }

    if (!this.listeners.has(eventType)) {
      this.listeners.set(eventType, new Set());
    }

    this.listeners.get(eventType).add(listener);
  }

  off(eventType, listener) {
    this.listeners.get(eventType)?.delete(listener);
  }

  dispatch(event) {
    const { type } = event;

    const propsEvent = this.propsEvents[type];

    if (typeof propsEvent === 'function') {
      propsEvent(...getArgs(event));
    }

    for (const callback of this.listeners.get(type) || new Set()) {
      callback(...getArgs(event));
    }
  }
}
