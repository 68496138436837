import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Divider } from 'antd';
import { useElementDecorator } from '../FormRenderCtx';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

const DividerElement = forwardRef(function DividerElement(props, ref) {
  const {
    children,
    keyPath,
    id,
    className: classNameProp,
    style = {},
    componentProps = {},
  } = props;

  const { content, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  useImperativeHandle(
    ref,
    () => ({
      node: null, // TODO antd 没暴露
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Divider
        {...otherComponentProps}
        className={clsx('divider-element form-element', className, classNameComp)}
        style={{ ...style, ...componentProps.style }}
        ref={console.log}
      >
        {children || content}
      </Divider>
    </ElementDecorator>
  );
});

DividerElement.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * Divider 中显示的文字
     */
    content: PropTypes.string,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
DividerElement.displayName = 'Divider';

export default withFieldWrapper(DividerElement);
