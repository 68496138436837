import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import TextareaAnt from './TextareaAnt';
import TextareaMaterial from './TextareaMaterial';

const TextareaUI = forwardRef(function TextareaUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <TextareaMaterial {...props} ref={ref} />;
  }

  return <TextareaAnt {...props} ref={ref} />;
});

export default TextareaUI;
