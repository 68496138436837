import { computeValueExpressions } from '@icp/form-renderer-core';

export function processValueExpression(event, valueExpressions) {
  const { data: rowData, node: rowNode } = event;

  // Only manually edit cell data can re-calculate valueExpression, for the case of prevent dead cycle.
  if (event.source === 'valueExpressionChanged') {
    return;
  }

  const calculated = computeValueExpressions({
    keyPath: event.colDef.colId,
    values: rowData,
    valueExpressions,
  });

  for (const { id, newValue, i18nValues = {} } of calculated) {
    rowNode.setDataValue(id, newValue, 'valueExpressionChanged');

    // 清空旧数据多语言值
    for (const key of Object.keys(rowData)) {
      if (key.startsWith(`${id}_i18n_`)) {
        rowNode.setDataValue(key, undefined, 'valueExpressionChanged');
      }
    }

    // 当 valueExpress 依赖的值是个多语言字段，将多语言资源一口气全部带过去
    for (const [suffix, i18nValue] of Object.entries(i18nValues)) {
      rowNode.setDataValue(`${id}${suffix}`, i18nValue, 'valueExpressionChanged');
    }
  }
}
