import { useEffect, useMemo, useRef } from 'react';
import { execFormScripts } from '@icp/form-renderer-core';
import { restApi, message as messageApi } from '@icp/settings';
import { useTranslation } from 'react-i18next';

export default function useFormScript(script, formApi, isInDesign, isFetching) {
  const { t, i18n } = useTranslation();

  const i18nApi = useMemo(() => {
    return { language: i18n.language, t };
  }, [i18n.language, t]);

  const done = useRef(false);

  useEffect(() => {
    if (done.current || isInDesign || !script || isFetching) {
      return;
    }

    // 表单拿到数据渲染过后再执行脚本
    // js 代码只执行一次
    done.current = true;

    execFormScripts(script, { formApi, restApi, messageApi, i18nApi });
  }, [formApi, i18nApi, isInDesign, isFetching, script]);
}
