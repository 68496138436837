export const FORM_TYPE_ENTITY = 'ENTITY';
export const FORM_TYPE_ENTITY_VIEW = 'ENTITY-VIEW';
export const FORM_TYPE_EXTERNAL = 'EXTERNAL';
export const FORM_TYPE_FLOW_APPROVAL = 'FLOW-APPROVAL';
export const FORM_TYPE_FLOW_FORM = 'FLOW-FORM';
export const FORM_TYPE_IFRAME = 'IFRAME';
export const FORM_TYPES = [
  '',
  FORM_TYPE_ENTITY,
  FORM_TYPE_ENTITY_VIEW,
  FORM_TYPE_EXTERNAL,
  FORM_TYPE_FLOW_APPROVAL,
  FORM_TYPE_FLOW_FORM,
  FORM_TYPE_IFRAME,
];

export const PAGE_TYPE = {
  formCreate: 'form-create',
  formEdit: 'form-edit',
  formView: 'form-view',
  flowCreate: 'flow-create',
  flowEdit: 'flow-edit',
  flowApproval: 'flow-approval',
};
