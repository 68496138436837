const DEFAULT_COLUMN_WIDTH = 200;

function quarterLabel(date) {
  const month = date.getMonth();
  let quarterNumber;

  if (month >= 9) {
    quarterNumber = 4;
  } else if (month >= 6) {
    quarterNumber = 3;
  } else if (month >= 3) {
    quarterNumber = 2;
  } else {
    quarterNumber = 1;
  }
  return `Q${quarterNumber}`;
}

export function getGanttConfigByZoomValue(value) {
  const type = value.type || value;
  switch (type) {
    case 'YearQuarter':
      return {
        scale_unit: 'year',
        date_scale: '%Y',
        subscales: [
          {
            unit: 'quarter',
            step: 1,
            format: quarterLabel,
          },
        ],
      };
    case 'YearsMonth':
      return {
        scale_unit: 'year',
        scale_height: 50,
        date_scale: '%Y',
        subscales: [
          {
            unit: 'month',
            step: 1,
            date: '%M',
          },
        ],
      };
    case 'MonthsWeek':
      return {
        scale_unit: 'month',
        date_scale: '%M %Y',
        subscales: [
          {
            unit: 'week',
            step: 1,
            date: 'W%W',
          },
        ],
      };
    case 'MonthDays':
      return {
        scale_unit: 'month',
        date_scale: '%M %Y',
        subscales: [
          {
            unit: 'day',
            step: 1,
            date: '%d',
          },
        ],
      };
    case 'MonthDaysMin':
      return {
        scale_unit: 'month',
        date_scale: '%M %Y',
        subscales: [
          {
            unit: 'day',
            step: 1,
            date: '%l %j',
          },
        ],
      };

    case 'changeableYears':
      return {
        scale_unit: 'year',
        date_scale: '%Y',
        step: value.step * 10,
        subscales: [
          {
            unit: 'year',
            step: value.step,
            date: '%Y',
          },
        ],
      };
    default:
      return {};
  }
}

export function fitToZoom(start, end, width = 500) {
  const minWidth = 25;
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();
  const monthCount = Math.abs((startDate - endDate) / 1000 / 3600 / 24 / 30);
  const weekCount = Math.abs((startDate - endDate) / 1000 / 3600 / 24 / 7);
  const count = (width - DEFAULT_COLUMN_WIDTH) / minWidth;

  if (monthCount / 4 + 2 >= count) {
    const step = Math.ceil(monthCount / 12 / count);
    return { type: 'changeableYears', step };
  }
  if (monthCount >= count) {
    return 'YearQuarter';
  }
  if (weekCount * 7 <= (width - DEFAULT_COLUMN_WIDTH) / 100) {
    // in this scale the string is logger than others
    return 'MonthDaysMin';
  }
  if (monthCount * 30 <= count) {
    return 'MonthDays';
  }
  if (monthCount * 4 <= count) {
    return 'MonthsWeek';
  }
  return 'YearsMonth';
}

export function getGanttScale(ganttData) {
  const tasks = ganttData?.data || [];
  // 算出数组中最小的开始时间和最大的结束时间
  let maxEnd = 0;
  let minStart = 0;
  tasks.forEach((task) => {
    const { start_date: startDate, end_date: endDate } = task || {};
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    if (start < minStart || minStart === 0) {
      minStart = start;
    }
    if (end > maxEnd) {
      maxEnd = end;
    }
  });
  return { ganttStartDate: minStart, ganttEndDate: maxEnd };
}
