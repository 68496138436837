import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import SelectElement from '../../SelectElement';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';
import { DEFAULT_MAPPING, DEFAULT_VALUE_TYPE, toComponentValue } from '../../SelectElement/utils';

const ParentCellEditor = forwardRef(function ParentCellEditor(props, ref) {
  const {
    value: valueProp,
    cellStartedEdit,
    colDef,
    data,
    context,
    componentProps,
    node: nodeProp,
  } = props;

  const {
    allowLoop,
    mode: legacyMode, // @deprecated
    multiple: multipleProp = false,
    mapping = DEFAULT_MAPPING,
    valueType = DEFAULT_VALUE_TYPE,
    useOriginValue,
    ...otherComponentProps
  } = componentProps || {};

  const multiple = multipleProp || legacyMode === 'multiple' || legacyMode === 'tags';

  const [value, setValue] = useState(valueProp);
  const refInput = useRef(null);

  const options = useMemo(() => {
    const list = [];

    if (allowLoop) {
      props.api.forEachNode((node) => {
        // 禁止选自己
        if (node === nodeProp) return;
        list.push(node.data);
      });
      return list;
    }

    const getId = (v) => {
      const compVal = toComponentValue({ valueProp: v, multiple, mapping, useOriginValue });
      if (!compVal) return undefined;
      // eslint-disable-next-line no-nested-ternary
      return Array.isArray(compVal)
        ? compVal[0]?.value
        : typeof compVal === 'object'
          ? compVal.value
          : compVal;
    };

    const allDataMap = {};
    props.api.forEachNode((node) => {
      allDataMap[getId(node.data[mapping.value])] = node.data;
    });

    const getParent = (row) => {
      return allDataMap[getId(row[colDef.colId])];
    };

    const selfId = getId(data[mapping.value]);

    props.api.forEachNode((node) => {
      // 禁止选自己
      if (node === nodeProp) return;

      // 禁止循环
      let parent = getParent(node.data);
      while (parent) {
        if (getId(parent[mapping.value]) === selfId) return;
        parent = getParent(parent);
      }
      list.push(node.data);
    });
    return list;
  }, [props.api, data, nodeProp, colDef.colId, allowLoop, useOriginValue, mapping, multiple]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        refInput.current.focus();
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <SelectElement
        className="table-select-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          size: context.tableSize,
          ref: refInput,
          defaultOpen: cellStartedEdit,
          autoFocus: cellStartedEdit,
          options,
          mapping,
          useOriginValue,
          multiple,
          valueType,
          ...otherComponentProps,
        }}
      />
    </CurrentDataProvider>
  );
});

ParentCellEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  cellStartedEdit: PropTypes.bool,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  api: PropTypes.shape({
    forEachNode: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({
    /**
     * 是否允许循环引用父节点
     */
    allowLoop: PropTypes.bool,
  }),
};

export default ParentCellEditor;
