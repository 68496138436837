import {
  ActionCellRenderer,
  AutoCellRenderer,
  AutoIndexCellRenderer,
  DateCellRenderer,
  EnumCellRenderer,
  MultiValueCellRenderer,
  NumberCellRenderer,
  ProgressCellRenderer,
  StatusCellRenderer,
  TextCellRenderer,
  UploadCellRenderer,
} from './cellRenders';

import {
  ACL_COLUMN,
  ACTION_COLUMN,
  AUTO_COLUMN,
  AUTO_INDEX_COLUMN,
  CHECKBOX_COLUMN,
  DATE_COLUMN,
  ENUM_COLUMN,
  MULTI_VALUE_COLUMN, // @deprecated
  NUMBER_COLUMN,
  PROGRESS_COLUMN,
  RADIO_COLUMN,
  SELECT_COLUMN,
  STATUS_COLUMN,
  TEXTAREA_COLUMN,
  TEXT_COLUMN,
  TIME_COLUMN,
  UPLOAD_COLUMN,
} from './constant';

// !important, cellEditor need use string, yupSchema use string to switch validation function
const COLUMN_TYPES = {
  [ACL_COLUMN]: {
    cellRenderer: MultiValueCellRenderer,
    cellEditor: 'ACLCellEditor',
  },
  [ACTION_COLUMN]: {
    cellRenderer: ActionCellRenderer,
    flex: null,
    filter: false,
    sortable: false,
  },
  [AUTO_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'TextCellEditor',
  },
  [AUTO_INDEX_COLUMN]: {
    cellRenderer: AutoIndexCellRenderer,
    width: 70,
    minWidth: 70,
    flex: null,
    filter: false,
    sortable: false,
    lockVisible: true,
  },
  [CHECKBOX_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'SelectCellEditor',
    // cellDataType: 'object', // TODO, 后端会把 checkbox 全部转换存成 string
    cellEditorParams: {
      componentProps: {
        valueType: 'valueAlwaysArray',
      },
    },
  },
  [DATE_COLUMN]: {
    cellRenderer: DateCellRenderer,
    cellEditor: 'DateCellEditor',
    filter: 'agDateColumnFilter',
  },
  [ENUM_COLUMN]: {
    cellRenderer: EnumCellRenderer,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: EnumCellRenderer.values,
      valueFormatter: EnumCellRenderer.valueFormatter,
    },
  },
  // @deprecated
  [MULTI_VALUE_COLUMN]: {
    cellRenderer: MultiValueCellRenderer,
    cellEditor: 'SelectCellEditor',
  },
  [NUMBER_COLUMN]: {
    cellRenderer: NumberCellRenderer,
    cellEditor: 'NumberCellEditor',
    filter: 'agNumberColumnFilter',
    comparator: (valueA, valueB) => valueA - valueB,
  },
  [PROGRESS_COLUMN]: {
    cellRenderer: ProgressCellRenderer,
    filter: 'agNumberColumnFilter',
    comparator: (valueA, valueB) => valueA - valueB,
  },
  [RADIO_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'SelectCellEditor',
    cellEditorParams: {
      componentProps: {
        valueType: 'value',
      },
    },
  },
  [SELECT_COLUMN]: {
    cellRenderer: MultiValueCellRenderer,
    cellEditor: 'SelectCellEditor',
  },
  // TODO，不推荐用，使用 ENUM_COLUMN 替代功能更丰富
  [STATUS_COLUMN]: {
    cellRenderer: StatusCellRenderer,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: StatusCellRenderer.values,
      valueFormatter: StatusCellRenderer.valueFormatter,
    },
  },
  [TEXT_COLUMN]: {
    cellRenderer: TextCellRenderer,
    cellEditor: 'TextCellEditor',
  },
  [TEXTAREA_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'TextareaCellEditor',
  },
  [TIME_COLUMN]: {
    cellRenderer: AutoCellRenderer,
    cellEditor: 'TimeCellEditor',
  },
  [UPLOAD_COLUMN]: {
    cellRenderer: UploadCellRenderer,
    cellEditor: 'UploadCellEditor',
    sortable: false,
    filter: false,
  },
};

export default COLUMN_TYPES;
