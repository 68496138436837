import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { resolveDataFilters } from '@icp/form-renderer-core';
import { isEqual } from 'lodash-es';
import { useStore } from '../store';
import useDependencies from './useDependencies';
import { useCurrentData } from '../currentDataCtx';

export default function useDataFilters(dataFilters) {
  const store = useStore();
  const params = useParams();
  const currentData = useCurrentData();

  const memorized = useRef([]);

  const [dataFiltersResolved, depIds] = resolveDataFilters({
    dataFilters,
    store,
    currentData,
    params,
  });

  useDependencies(depIds);

  if (!isEqual(memorized.current, dataFiltersResolved)) {
    memorized.current = dataFiltersResolved;
  }

  return memorized.current;
}
