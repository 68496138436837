import loadable from '@loadable/component';

export function loadWangEditor() {
  return import(
    /* webpackChunkName: "@wangeditor" */
    /* webpackPrefetch: true */
    './WangEditor'
  );
}

// Editor 和 Toolbar 名字太普遍了，包成 WantEditor 在 index.js 里进行导出
const AsyncWangEditor = {
  Editor: loadable(() => loadWangEditor().then((module) => ({ default: module.Editor }))),
  Toolbar: loadable(() => loadWangEditor().then((module) => ({ default: module.Toolbar }))),
};

export default AsyncWangEditor;
