import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

function ACLDialogAnt(props) {
  const { children, className, title, multiple, onOk, onClose, ...other } = props;

  const { t } = useTranslation(['icp-common']);

  // 如果 Button 的祖先有 Link 的话点击 Modal 空白地方就会跳转。
  // and Model 没有提供方法传 props 到真正的跟 div 去阻止 click 的冒泡。
  // 如果加在 wrapProps 上会直接和 maskClosable 冲突，点击外面无法关闭 Modal 了，所以只能加一个幽灵 span。
  return (
    <span onClick={(event) => event.stopPropagation()} style={{ display: 'none' }}>
      <Modal
        open={true}
        centered={true}
        wrapClassName={clsx('icp-acl-dialog', { multiple }, className)}
        // 与 ButtonElement getDialogWidth 一致
        width={multiple ? 1200 : 900}
        closable={false}
        keyboard={false}
        {...other}
        onCancel={onClose}
        title={title}
        // 如果在 modal 里再打开一个 modal，antd 会给后面的 modal 的 style 里设置 210，再打开一个会变成 310，
        // 导致类似 acl table 上面的 filter 被遮盖的 bug。（filter 用的是 icp-overlay）。
        // 测试下来设置 null 可以阻止此行为，让 modal 继续使用 themeUtil 里设置的 zIndexPopup。
        // 我们系统的 z-index 规则是全局统一设置 10 （vars.css 里的 --max-z-index 以及 themeUtil 里的zIndexPopup），
        // 统一为 10 过后通过 dom 元素出现的优先级来实现后打开的 modal div 覆盖前打开的 modal div 的效果更方便维护。
        zIndex={null}
        footer={[
          <Button key="cancel" onClick={onClose}>
            {t('cancel', { ns: 'icp-common' })}
          </Button>,
          <Button key="ok" type="primary" onClick={onOk}>
            {t('ok', { ns: 'icp-common' })}
          </Button>,
        ]}
      >
        {children}
      </Modal>
    </span>
  );
}

ACLDialogAnt.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
};

export default ACLDialogAnt;
