import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Input } from 'antd';
import { I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT } from '@icp/form-renderer-core';
import I18nTextareaSingle from './I18nTextareaSingle';
import I18nTextareaMulti from './I18nTextareaMulti';
import { useValueLanguageOptions } from '../../../hooks';

const TextareaSupportI18n = forwardRef(function TextareaSupportI18n(props, ref) {
  const { simpleMode, id, value, allowI18nValue, i18nInputLayout, ...other } = props;

  const languageOptions = useValueLanguageOptions();

  // i18n value textarea 暂时没有支持 simpleMode
  if (simpleMode || !allowI18nValue) {
    const innerValue = simpleMode ? value : value?.longText;
    return <Input.TextArea {...other} value={innerValue ?? ''} ref={ref} />;
  }

  if (i18nInputLayout === I18N_VALUE_COLLAPSED) {
    return <I18nTextareaMulti {...other} id={id} value={value} ref={ref} />;
  }

  if (i18nInputLayout === I18N_VALUE_FLAT) {
    return languageOptions.map((op) => (
      <I18nTextareaSingle {...other} key={op.value} id={id} languageOp={op} value={value} />
    ));
  }

  return 'Invalid i18nInputLayout config';
});

TextareaSupportI18n.propTypes = {
  simpleMode: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  allowI18nValue: PropTypes.bool,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
};

export default TextareaSupportI18n;
