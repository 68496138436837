import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import SwitchAnt from './SwitchAnt';
import SwitchMaterial from './SwitchMaterial';

const SwitchUI = forwardRef(function SelectUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <SwitchMaterial {...props} ref={ref} />;
  }

  return <SwitchAnt {...props} ref={ref} />;
});

export default SwitchUI;
