import PropTypes from 'prop-types';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { InputAdornment, TextField } from '@mui/material';
import { useSelector } from '../../../store';
import { ValueLanguageSelectMaterial } from '../../../ValueLanguageSelect';

function I18nInputSingle(props) {
  const { id, languageOp, onChange, readonly, ...other } = props;

  const valueI18n = useSelector(selectFieldValueI18n(id, languageOp.value));

  const handleChange = (event) => {
    onChange(event, languageOp.value);
  };

  return (
    <TextField
      {...other}
      fullWidth={true}
      value={valueI18n ?? ''}
      onChange={handleChange}
      InputProps={{
        readOnly: readonly,
        startAdornment: (
          <InputAdornment position="start">
            <ValueLanguageSelectMaterial value={languageOp.value} readonly={true} />
          </InputAdornment>
        ),
      }}
    />
  );
}

I18nInputSingle.propTypes = {
  id: PropTypes.string,
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};

export default I18nInputSingle;
