import PropTypes from 'prop-types';
import { helpersIsEmpty } from '@icp/form-renderer-core';

export default function FormHelperTextMaterial({ helpers }) {
  const isEmpty = helpersIsEmpty(helpers);

  if (isEmpty) {
    return null;
  }

  const newHelpers = Array.isArray(helpers) ? helpers : [helpers];

  return (
    <span>
      {newHelpers.map((helper) => {
        const { text } = helper || {};
        const content = Array.isArray(text) ? text : [text];
        return (
          <span key={`${content}`} style={{ display: 'block' }}>
            {content.map((item, i) => {
              // 转一下 string 保证页面不会奔溃。如果字段 id 是纯 number 组成的 string 例如 '123'，yup 验证的时候会无法识别到正确的字段导致 helpers 里的 text 是个 object。
              return <span key={i}>{String(item)}</span>;
            })}
          </span>
        );
      })}
    </span>
  );
}

FormHelperTextMaterial.propTypes = {
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    }),
  ),
};
