import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

// 给 位于 TableElement 下的 ButtonElement 等元素操作 table 用，例如刷新数据
const TableContextCtx = createContext(null);

export function TableContextProvider({ children, value }) {
  return <TableContextCtx.Provider value={value}>{children}</TableContextCtx.Provider>;
}

TableContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape({}),
};

export function useTableContext() {
  return useContext(TableContextCtx);
}
