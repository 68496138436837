export default function debounce(func, time = 200) {
  let timeout;

  function debounced(...args) {
    const callback = () => {
      func.apply(this, args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(callback, time);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}
