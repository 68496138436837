const allOldExternalForm = {};

(function importS() {
  const r = require.context('externalForms', true, /index\.js$/);
  r.keys()
    // filter outer duplicate key caused by webpack.config.js resolve.context
    .filter((key) => key.startsWith('./'))
    .forEach((key) => {
      const lastSlashesIndex = key.lastIndexOf('/index.js');
      const name = key.substring(2, lastSlashesIndex); // 2 === './'.length
      allOldExternalForm[name] = r(key).default;
    });
})();

export default allOldExternalForm;
