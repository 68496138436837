import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { getCascadedTimeZone } from '@icp/settings';
import { convertDateTimeFromTimeZone, toDateValue } from '@icp/utils';
import dayjs from 'dayjs';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import DatePickerUI from './DatePickerUI';
import { getDisabledDate } from './utils';
import { useStore } from '../../store';
import { useCurrentData } from '../../currentDataCtx';

const DatePickerElement = forwardRef(function DatePickerElement(props, ref) {
  const {
    keyPath,
    id,
    value: valueProp,
    className: classNameProp,
    style,
    componentProps = {},
    helpers,
    onChange,
  } = props;

  const {
    noTimeZone = false,
    timeZone: timeZoneProp,
    showTime,
    formatOptions,
    disabledDate: disabledDateProp,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const store = useStore();
  const currentData = useCurrentData();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const value = (() => {
    if (!valueProp) {
      return null;
    }
    return showTime && timeZone
      ? convertDateTimeFromTimeZone(valueProp, timeZone)
      : dayjs(valueProp);
  })();

  const timeZone = timeZoneProp || getCascadedTimeZone();

  const shouldDisableDate = disabledDateProp
    ? (current) => getDisabledDate(disabledDateProp, current, currentData, store)
    : undefined;

  const handleChange = (newDate) => {
    onChange(toDateValue(newDate, showTime, noTimeZone, timeZone));
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'date-picker-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <DatePickerUI
          {...props}
          componentProps={{
            ...otherComponentProps,
            className: classNameComp,
          }}
          value={value}
          timeZone={timeZone}
          showTime={showTime}
          formatOptions={formatOptions}
          shouldDisableDate={shouldDisableDate}
          onChange={handleChange}
        />
      </div>
    </ElementDecorator>
  );
});

const DisabledValueType = PropTypes.oneOfType([
  PropTypes.oneOf(['today', 'tomorrow', 'yesterday']),
  // 0 is today, -1 is tomorrow, and so on
  PropTypes.number,
  PropTypes.string,
]);

DatePickerElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * onChange 的时候是否去除日期的 time zone 信息
     * @default false
     */
    noTimeZone: PropTypes.bool,
    /**
     * 日历的时区，仅在选择DateTime时有效
     */
    timeZone: PropTypes.string,
    /**
     * antd DatePicker 的 `showTime` 属性
     */
    showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    /**
     * Intl.DateTimeFormat的所有属性
     */
    formatOptions: PropTypes.shape({}),
    /**
     * 在选择日期的时候禁止选择某些日期
     */
    disabledDate: PropTypes.shape({
      /**
       * 禁止的类型
       */
      type: PropTypes.oneOf(['before', 'after', 'inRange', 'outRange']),
      /**
       * If `true`, the day of value(valueFrom, valueTo) itself is disabled
       * @default false
       */
      includeValue: PropTypes.bool,
      /**
       * 当 type 等于 before or after 时候的禁止日期,
       * string 表示具体的日期，number 表示基于当天日期的偏移量。
       */
      value: DisabledValueType,
      /**
       * 可以指定 valueOffset 作为偏移量来往前或者往后移动 value
       */
      valueOffset: PropTypes.number,
      /**
       * 当 type 等于 inRange or outRange 时的起始日期，
       * string 表示具体的日期，number 表示基于当天日期的偏移量。
       */
      valueFrom: DisabledValueType,
      /**
       * 可以指定 valueFromOffset 作为偏移量来往前或者往后移动 valueFrom
       */
      valueFromOffset: PropTypes.number,
      /**
       * 当 type 等于 inRange or outRange 时的终止日期，
       * string 表示具体的日期，number 表示基于当天日期的偏移量。
       */
      valueTo: DisabledValueType,
      /**
       * 可以指定 valueToOffset 作为偏移量来往前或者往后移动 valueFrom
       */
      valueToOffset: PropTypes.number,
    }),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
DatePickerElement.displayName = 'DatePicker';

export default withFieldWrapper(DatePickerElement);
