/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useEventCallback } from '@icp/hooks';
import computePosition from './computePosition';
import { getOverflowAncestors } from './utils';

function usePosition({ anchorEl, element, placement, offset, gap, observable }) {
  const [position, setPosition] = useState(null);
  const [isInvisible, setIsInvisible] = useState(false);

  const update = useEventCallback(() => {
    if (!anchorEl || !element) {
      return;
    }
    const computed = computePosition(anchorEl, element, { placement, offset, gap });
    if (!position || computed.left !== position.left || computed.top !== position.top) {
      setPosition(computed);
    }
  });

  // Hide element when anchorEl moved on the screen to invisible
  useEffect(() => {
    if (!anchorEl || !element || anchorEl.nodeType !== 1) {
      return;
    }

    let isFirst = true;
    let prevIsInvisible = false;
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        let value = prevIsInvisible;
        if (entry.intersectionRatio === 0) {
          // intersectionRatio 0 可能同时由可见到不可见、不可见到可见触发，所以需要根据上一次的 inVisible 来判断
          value = !prevIsInvisible;
        } else if (entry.intersectionRatio > 0) {
          value = false;
        }
        prevIsInvisible = value;
        setIsInvisible(value);

        if (isFirst) {
          isFirst = false;
        } else {
          update();
        }
      });
    });

    if (observable) {
      if (anchorEl.nodeType === 1) {
        intersectionObserver.observe(anchorEl);
      }
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [anchorEl, element, observable, update]);

  // Update when ancestors scroll or resize
  useEffect(() => {
    if (!anchorEl || !element) {
      setPosition(null);
      return;
    }

    // ResizeObserver will trigger once when element is rendered when observable is true
    if (!observable) {
      update();
    }

    const resizeObserver = new ResizeObserver(() => {
      update();
    });
    const ancestors = observable ? getOverflowAncestors(anchorEl) : [];

    if (observable) {
      if (anchorEl.nodeType === 1) {
        resizeObserver.observe(anchorEl);
      }
      resizeObserver.observe(element);
      ancestors.forEach((el) => {
        el.addEventListener('scroll', update);
      });
      window.addEventListener('resize', update);
      window.addEventListener('scroll', update);
    }

    return () => {
      resizeObserver.disconnect();
      ancestors.forEach((el) => {
        el.removeEventListener('scroll', update);
      });
      window.removeEventListener('resize', update);
      window.removeEventListener('scroll', update);
    };
  }, [anchorEl, placement, observable, element, update]);

  return { position: isInvisible ? null : position, updatePosition: update };
}

export default usePosition;
