import './IconPicker.css';
import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';
import Icon from '../Icon';
import IconList from './IconList';
import Popover from '../Popover';

function IconPicker(props) {
  const { className, name, size = 16, onChange, ...other } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (newName) => {
    setOpen(false);
    onChange(newName);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        className={clsx('icp-icon-picker icp-clickable', className)}
        style={{ width: size, height: size }}
        onClick={() => setOpen(true)}
        ref={setAnchorEl}
      >
        {name ? (
          <Icon name={name} size={size} {...other} />
        ) : (
          <div className="icp-icon-picker-empty" />
        )}
      </button>
      <Popover
        className="icp-icon-picker-list"
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        resizable={true}
        onClose={handleClose}
      >
        <IconList name={name} onChange={handleChange} />
      </Popover>
    </>
  );
}

IconPicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  onChange: PropTypes.func,
};

export default IconPicker;
