import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { getTFunc } from '@icp/i18n';
import { useElementDecorator } from '../FormRenderCtx';
import { useClassName, useDisplayValue, useVariablePattern } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

function getDefaultOptions() {
  const t = getTFunc('icp-form-renderer');
  return [
    {
      value: 'SUCCESS',
      label: t('status.success'),
      color: 'var(--success-color)',
    },
    {
      value: 'FAILURE',
      label: t('status.failure'),
      color: 'var(--error-color)',
    },
  ];
}

const StatusElement = forwardRef(function StatusElement(props, ref) {
  const { keyPath, id, valueField, className: classNameProp, style, componentProps = {} } = props;

  const { statusColor: statusColorPattern, enums = getDefaultOptions() } = componentProps;

  const ElementDecorator = useElementDecorator();

  const value = useDisplayValue(id, valueField);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const statusColor =
    useVariablePattern(statusColorPattern) || enums.find((item) => item.value === value)?.color;

  const displayText = enums.find((item) => item.value === value)?.label;

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div className={clsx('status-element', className, classNameComp)} style={style} ref={nodeRef}>
        {statusColor ? <i style={{ backgroundColor: statusColor }} /> : null}
        <span>{displayText}</span>
      </div>
    </ElementDecorator>
  );
});

StatusElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 当前状态的颜色，如果不指定则会从 `enums` 属性里通过 `value` 查找
     */
    statusColor: PropTypes.string,
    /**
     * 状态列表
     * // TODO, refactor to options
     */
    enums: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        color: PropTypes.string,
      }),
    ),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
StatusElement.displayName = 'Status';

export default withFieldWrapper(StatusElement);
