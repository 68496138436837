import './Dialog.css';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { composeEvent } from '@icp/utils';
import FocusTrap from '../FocusTrap';

const Dialog = forwardRef(function Dialog(props, ref) {
  const {
    children,
    className,
    open,
    title,
    footer = true,
    loading = false,
    hideBackdrop = false,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableRestoreFocus = false,
    disableEscapeKeyDown = false,
    disableBackdropClose = false,
    okText,
    cancelText,
    rootProps,
    backdropProps,
    titleProps,
    contentProps,
    footerProps,
    onOk,
    onClose,
    ...other
  } = props;

  const { t } = useTranslation(['icp-common']);

  if (!open) {
    return null;
  }

  const handleKeyDown = (event) => {
    if (disableEscapeKeyDown) {
      return;
    }

    if (event.key === 'Escape' && onClose) {
      event.stopPropagation();
      onClose(event);
    }
  };

  const handleBackdropClick = (event) => {
    if (disableBackdropClose || event.target !== event.currentTarget) {
      return;
    }

    if (onClose) {
      onClose(event);
    }
  };

  return createPortal(
    <div
      {...rootProps}
      className={clsx('icp-dialog-root icp-overlay icp-center', rootProps?.className)}
      onKeyDown={composeEvent(handleKeyDown, rootProps?.onKeyDown)}
    >
      {!hideBackdrop ? (
        <div
          {...backdropProps}
          className={clsx('icp-backdrop', backdropProps?.className)}
          onClick={composeEvent(handleBackdropClick, backdropProps?.onClick)}
        />
      ) : null}
      <FocusTrap
        disableAutoFocus={disableAutoFocus}
        disableEnforceFocus={disableEnforceFocus}
        disableRestoreFocus={disableRestoreFocus}
      >
        <div
          className={clsx('icp-dialog', className)}
          role="dialog"
          aria-modal={true}
          ref={ref}
          {...other}
        >
          {title ? (
            <div {...titleProps} className={clsx('icp-dialog-title', titleProps?.className)}>
              {title}
            </div>
          ) : null}
          <div {...contentProps} className={clsx('icp-dialog-content', contentProps?.className)}>
            {children}
          </div>
          {footer ? (
            <div {...footerProps} className={clsx('icp-dialog-footer', footerProps?.className)}>
              <Button onClick={onClose}>{cancelText || t('cancel')}</Button>
              <Button type="primary" onClick={onOk} loading={loading}>
                {okText || t('ok')}
              </Button>
            </div>
          ) : null}
        </div>
      </FocusTrap>
    </div>,
    document.body,
  );
});

Dialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.node,
  footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  loading: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disableBackdropClose: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  rootProps: PropTypes.shape({
    className: PropTypes.string,
    onKeyDown: PropTypes.func,
  }),
  backdropProps: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
  }),
  titleProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  contentProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  footerProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  onOk: PropTypes.func,
  onClose: PropTypes.func,
};

export default Dialog;
