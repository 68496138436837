import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill';

export async function loadIntlDisplayNames(locale) {
  const unsupportedLocale = shouldPolyfill(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import(
    /* webpackChunkName: "polyfill/intl-displaynames/polyfill-force" */
    '@formatjs/intl-displaynames/polyfill-force'
  );
  await import(
    /* webpackChunkName: "polyfill/intl-displaynames/locale-data" */
    `@formatjs/intl-displaynames/locale-data/${unsupportedLocale}.js`
  );
}
