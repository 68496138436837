import { useBlocker } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function blockResult(block) {
  return typeof block === 'function' ? block() : block;
}

export default function useLeaveConfirm({ block, message }) {
  const { t } = useTranslation(['icp-components']);

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return currentLocation.pathname !== nextLocation.pathname && blockResult(block);
    },
    [block],
  );
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked' && !blockResult(block)) {
      blocker.reset();
    } else if (blocker.state === 'blocked') {
      // eslint-disable-next-line no-alert
      if (window.confirm(message || t('confirm.leave.message'))) {
        blocker.proceed?.();
      } else {
        blocker.reset?.();
      }
    }
  }, [blocker, block, t, message]);

  useEffect(() => {
    const leaveConfirm = (event) => {
      if (blockResult(block)) {
        event.preventDefault();
        // eslint-disable-next-line no-return-assign
        return (event.returnValue = message || t('confirm.leave.message'));
      }
      return false;
    };

    window.addEventListener('beforeunload', leaveConfirm, { capture: true });

    return () => {
      window.removeEventListener('beforeunload', leaveConfirm, { capture: true });
    };
  }, [block, message, t]);
}
