import './SaleConsultationAI.css';
import PropTypes from 'prop-types';
import { Button, Tag } from 'antd';
import { useState } from 'react';
import { consultLawyer, updateResultMapCache } from './utils';

export const AnalysisMessageType = 'customized[AnalysisMessage]';

function AnalysisMessage(props) {
  const { title, resultMap } = props;
  const { analysis } = resultMap;

  const advice = analysis.output.adviceForAllRules;
  const tags = analysis.tagList.map((tag) => (
    <Tag className="analysis-message-tag" key={tag}>
      {tag}
    </Tag>
  ));

  // Temporarily hide the footer button since the action of the button creates incorrect data.
  const showFooterButton = false;
  const [consultLayerResult, setConsultLawyerResult] = useState(resultMap.consultLawyer);

  const handleConsultLawyer = () => {
    consultLawyer(resultMap).then((response) => {
      setConsultLawyerResult(response.result);
      resultMap.consultLawyer = response.result;
      updateResultMapCache(resultMap);
    });
  };

  return (
    <div className="analysis-message">
      <div className="chat-message-content-box">
        <div className="analysis-message-title">{title}</div>
        {tags && tags.length > 0 && <div className="analysis-message-tags">{tags}</div>}
        {/* eslint-disable-next-line react/no-danger */}
        <div className="analysis-message-content" dangerouslySetInnerHTML={{ __html: advice }} />
      </div>
      {showFooterButton && (
        <div className="analysis-message-footer">
          {consultLayerResult ? (
            <div className="message-footer-text">{consultLayerResult}</div>
          ) : (
            <>
              <Button
                type="primary"
                className="message-footer-button"
                onClick={handleConsultLawyer}
              >
                咨询专业律师
              </Button>
              <div className="message-footer-text">
                以上内容为AI自动判断，详情请咨询专业律师服务。
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

AnalysisMessage.propTypes = {
  title: PropTypes.string,
  resultMap: PropTypes.shape({
    analysis: PropTypes.shape({
      output: PropTypes.shape({
        adviceForAllRules: PropTypes.string,
      }),
      tagList: PropTypes.arrayOf(PropTypes.string),
    }),
    consultLawyer: PropTypes.string,
  }),
};

export default AnalysisMessage;
