import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName } from '../hooks';
import { useElementDecorator } from '../FormRenderCtx';
import { withFieldWrapper } from '../fieldWrapper';

const CardLayout = forwardRef(function CardLayout(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const {
    variant = 'pc',
    size = 'default',
    title,
    titleDesc,
    style: cardStyle,
    titleStyle,
    titleTextStyle,
    titleDescStyle,
    contentStyle,
    suppressMargin = false,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        {...otherComponentProps}
        className={clsx(
          'card-layout form-layout',
          `variant-${variant}`,
          `size-${size}`,
          {
            'suppress-margin': suppressMargin,
            'no-children': noChildren,
          },
          className,
          classNameComp,
        )}
        style={{
          ...style,
          ...cardStyle,
        }}
        ref={nodeRef}
      >
        {title || titleDesc ? (
          <div className="card-title" style={titleStyle}>
            <div className="title-text" style={titleTextStyle}>
              {title}
            </div>
            <div className="title-desc" style={titleDescStyle}>
              {titleDesc}
            </div>
          </div>
        ) : null}
        <div className="card-content" style={contentStyle}>
          {children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />}
        </div>
      </div>
    </ElementDecorator>
  );
});

CardLayout.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * Root div 的 className
     */
    className: PropTypes.string,
    /**
     * Root div 的 style
     */
    style: PropTypes.shape({}),
    // TODO, 要不要做成自动判断？或者 css 写在 MobileLayout 下面？
    /**
     * 桌面端样式 `pc`, 移动端样式 `mobile`
     * @default 'pc'
     */
    variant: PropTypes.oneOf(['pc', 'mobile']),
    /**
     * Card 的尺寸
     */
    size: PropTypes.oneOf(['small', 'default']),
    /**
     * Card 的标题
     */
    title: PropTypes.string,
    /**
     * Card 的描述
     */
    titleDesc: PropTypes.string,
    /**
     * Card 标题 `div` 的 style
     */
    titleStyle: PropTypes.shape({}),
    /**
     * Card 内容 `div` 的 style
     */
    contentStyle: PropTypes.shape({}),
    /**
     * 是否禁止 Card 的外边距
     * @default false
     */
    suppressMargin: PropTypes.bool,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CardLayout.displayName = 'Card';

export default withFieldWrapper(CardLayout);
