import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import NumberPickerElement from '../../NumberPickerElement';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';

const NumberCellEditor = forwardRef(function NumberCellEditor(props, ref) {
  const {
    value: valueProp,
    cellStartedEdit,
    eventKey,
    charPress,
    colDef,
    data,
    context,
    componentProps,
    node: nodeProp,
  } = props;

  const refInput = useRef(null);
  const [value, setValue] = useState(() => {
    if (eventKey === 'Backspace' || eventKey === 'Delete') {
      return null;
    }

    if (charPress) {
      return charPress;
    }

    return valueProp;
  });

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        if (
          typeof value === 'number' &&
          Number.isInteger(componentProps?.precision) &&
          !Number.isInteger(value)
        ) {
          return Number(value.toFixed(componentProps.precision));
        }
        return value;
      },

      focusIn() {
        refInput.current.focus();
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <NumberPickerElement
        className="table-number-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          size: context.tableSize,
          ref: refInput,
          autoFocus: cellStartedEdit,
          ...componentProps,
        }}
      />
    </CurrentDataProvider>
  );
});

NumberCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cellStartedEdit: PropTypes.bool,
  eventKey: PropTypes.string,
  charPress: PropTypes.string,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
  }),
  componentProps: PropTypes.shape({
    precision: PropTypes.number,
  }),
};

export default NumberCellEditor;
