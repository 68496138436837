import { Tooltip } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEventCallback } from '@icp/hooks';
import { useChangeLanguage } from '@icp/i18n';
import Popover from '../Popover';
import Menu from '../Menu';
import Icon from '../Icon';

function SpeechButton(props) {
  const {
    className,
    disabled,
    allowSwitchLanguage,
    onChange,
    tooltip,
    speechStartTip,
    speechErrorTip,
    speechListeningTip,
  } = props;

  const { i18n } = useTranslation(['icp-components']);
  const [, , languageOptions] = useChangeLanguage();
  const [lng, setLng] = useState(i18n.language);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [speechTip, setSpeechTip] = useState(null);

  const btnRef = useRef();
  const disabledRef = useRef();
  const popoverOpenRef = useRef(false);
  disabledRef.current = disabled;

  const onResult = useEventCallback((event) => {
    const text = event.results[0][0].transcript;
    if (disabledRef.current) return;
    onChange(text);
  });

  const onEnd = useEventCallback(() => {
    if (popoverOpenRef.current) {
      // 重新开启，等待用户手动关闭
      recognition?.start();
    }
  });

  const recognition = useMemo(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) return null;
    const instance = new SpeechRecognition();

    instance.lang = i18n.language;

    instance.onresult = onResult;
    instance.onspeechstart = () => {
      setSpeechTip(speechListeningTip);
    };
    instance.onspeechend = () => {
      setSpeechTip(speechStartTip);
    };
    instance.onend = onEnd;
    instance.onerror = (error) => {
      console.error(error);
      setSpeechTip(speechErrorTip);
    };
    return instance;
  }, [i18n.language, speechStartTip, speechListeningTip, speechErrorTip, onResult, onEnd]);

  const startSpeech = () => {
    if (disabled) return;
    recognition?.start();
    popoverOpenRef.current = true;
    setPopoverOpen(true);
    setSpeechTip(speechStartTip);
  };

  const stopSpeech = useEventCallback(() => {
    setSpeechTip(null);
    setPopoverOpen(false);
    popoverOpenRef.current = false;
    recognition?.stop();
  });

  useEffect(() => {
    const listener = () => {
      if (document.hidden) {
        stopSpeech();
      }
    };
    document.addEventListener('visibilitychange', listener);
    window.addEventListener('blur', stopSpeech);
    return () => {
      stopSpeech();
      document.removeEventListener('visibilitychange', listener);
      window.removeEventListener('blur', stopSpeech);
    };
  }, [stopSpeech]);

  if (!recognition) {
    return null;
  }

  return (
    <>
      <Tooltip title={tooltip} disabled={disabled}>
        <button
          ref={btnRef}
          className={className}
          style={{ position: 'relative' }}
          type="text"
          disabled={disabled}
          onClick={() => {
            startSpeech();
          }}
          onContextMenu={
            allowSwitchLanguage
              ? (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setMenuOpen(true);
                }
              : null
          }
        >
          <Icon name="microphone" size={16} />
          {allowSwitchLanguage && (
            <Icon
              name="oct:triangle-down"
              size={12}
              style={{
                position: 'absolute',
                right: 1,
                bottom: 1,
                transform: 'rotate(-45deg)',
              }}
            />
          )}
        </button>
      </Tooltip>
      <Popover
        anchorEl={btnRef.current}
        placement="top"
        open={popoverOpen}
        onClose={() => {
          stopSpeech();
        }}
      >
        <div style={{ opacity: 0.8, padding: '12px 20px', textAlign: 'center' }}>
          <Icon name="microphone" size={42} />
          <div>{speechTip}</div>
        </div>
      </Popover>
      {allowSwitchLanguage && (
        <Menu
          anchorEl={btnRef.current}
          placement="right-start"
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          items={languageOptions.map((x) => ({
            key: x.value,
            label: x.label,
            selected: x.value === lng,
          }))}
          onClick={(event) => {
            setLng(event.key);
            if (recognition) {
              recognition.lang = event.key;
            }
          }}
        />
      )}
    </>
  );
}

SpeechButton.propTypes = {
  allowSwitchLanguage: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  speechStartTip: PropTypes.string,
  speechErrorTip: PropTypes.string,
  speechListeningTip: PropTypes.string,
};

export default SpeechButton;
