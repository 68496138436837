import { loadPrettier } from './loadVendors';

export default function formatJSCode(codeString) {
  return loadPrettier().then(({ prettier, plugins }) => {
    return prettier.format(codeString, {
      printWidth: 100,
      singleQuote: true,
      trailingComma: 'all',
      parser: 'babel',
      plugins,
    });
  });
}
