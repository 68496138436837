import { createContext, useEffect, useMemo } from 'react';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import { clearAllListeners } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import makeStore from './makeStore';

const ctx = createContext();

export const useStore = createStoreHook(ctx);
export const useDispatch = createDispatchHook(ctx);
export const useSelector = createSelectorHook(ctx);
export const hooks = {
  useStore,
  useDispatch,
  useSelector,
};

export default function StoreProvider({
  children,
  typeName,
  restApi,
  pbcId,
  formEntityId,
  isInnerEntity,
  singleLayout,
  preloadedState,
  readonly,
  userProfile,
  userPermissionMap,
}) {
  const store = useMemo(
    () =>
      makeStore({
        typeName,
        restApi,
        pbcId,
        formEntityId,
        isInnerEntity,
        singleLayout,
        preloadedState,
        readonly,
        userProfile,
        userPermissionMap,
      }),
    [
      typeName,
      restApi,
      pbcId,
      formEntityId,
      isInnerEntity,
      singleLayout,
      preloadedState,
      readonly,
      userProfile,
      userPermissionMap,
    ],
  );

  return (
    <Provider store={store} context={ctx}>
      <CleanUpListeners />
      {children}
    </Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node,
  typeName: PropTypes.string,
  pbcId: PropTypes.number,
  formEntityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isInnerEntity: PropTypes.bool,
  singleLayout: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  preloadedState: PropTypes.object,
  userProfile: PropTypes.shape({}),
  userPermissionMap: PropTypes.shape({}),
  readonly: PropTypes.bool,
  restApi: PropTypes.shape({
    get: PropTypes.func,
    put: PropTypes.func,
    post: PropTypes.func,
    delete: PropTypes.func,
  }),
};

function CleanUpListeners() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllListeners());
    };
  }, [dispatch]);

  return null;
}
