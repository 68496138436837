import PropTypes from 'prop-types';
import { AgTable } from '@icp/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEventCallback } from '@icp/hooks';
import { restApi } from '@icp/settings';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ConnectorDialog from './ConnectorDialog';

function ConnectorList(props) {
  const { pbcId, connectorPbcList, canAdd = false, canEdit = false, canDelete = false } = props;

  const { t } = useTranslation(['icp-app', 'icp-form-renderer', 'icp-common']);

  const [connectorTypeList, setConnectorTypeList] = useState(null);
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const gridRef = useRef(null);

  const fetchData = useEventCallback(() => {
    const fetchConnectorTypes = restApi
      .get('/connector/api/connector-type/list')
      .then(setConnectorTypeList);

    // 本地部署没有pbcId，platform中有pbcId
    const fetchConnectors = (
      pbcId
        ? Promise.all(
            connectorPbcList.map((pbc) => {
              return restApi.get(`/connector/api/connector/list-by-pbcId/${pbc.id}`);
            }),
          ).then((aoa) => aoa.flatMap((x) => x))
        : restApi.get('/connector/api/connector/list')
    ).then(setData);

    return Promise.all([fetchConnectorTypes, fetchConnectors]);
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = useEventCallback((row) => {
    setOpenDialog(row);
  });

  const handleSubmitSuccess = (res) => {
    if (openDialog?.id) {
      gridRef.current.api.applyTransaction({ update: [res] });
    } else {
      gridRef.current.api.applyTransaction({ add: [res] });
    }
    setOpenDialog(false);
  };

  const handleDelete = (ids) => {
    const selectedData = gridRef.current.api.getSelectedRows();
    const promises = [];
    for (const id of ids) {
      promises.push(restApi.delete(`/connector/api/connector/${id}`));
    }
    return Promise.all(promises).then(() => {
      gridRef.current.api.applyTransaction({ remove: selectedData });
    });
  };

  const columnDefs = useMemo(() => {
    return [
      {
        colId: 'name',
        field: 'name',
        headerName: t('connector.name'),
      },
      {
        colId: 'connectorTypeUuid',
        field: 'connectorTypeUuid',
        headerName: t('connector.connector-type'),
        valueFormatter: (params) => {
          if (!params.value) return params.value;
          return connectorTypeList.find((item) => item.uuid === params.value)?.name;
        },
      },
      {
        colId: 'description',
        field: 'description',
        headerName: t('description', { ns: 'icp-common' }),
      },
      canEdit
        ? {
            headerName: t('action.header-name', { ns: 'icp-form-renderer' }),
            cellRenderer: (params) => {
              return (
                <Button type="link" size="small" onClick={() => handleEdit(params.data)}>
                  {t('edit', { ns: 'icp-common' })}
                </Button>
              );
            },
          }
        : null,
    ].filter(Boolean);
  }, [canEdit, connectorTypeList, handleEdit, t]);

  return (
    <>
      <AgTable
        title={t('connector.list')}
        rowModelType="clientSide"
        rowSelection={canDelete ? 'multiple' : null}
        suppressAddButton={!canAdd}
        suppressDeleteButton={!canDelete}
        columnDefs={columnDefs}
        rowData={connectorTypeList ? data : null}
        getRowId={(params) => params.data.id}
        onRefresh={() => fetchData()}
        onAddRow={() => setOpenDialog(true)}
        onDeleteRow={handleDelete}
        ref={gridRef}
      />
      {openDialog ? (
        <ConnectorDialog
          pbcId={openDialog.pbcId || pbcId}
          connector={typeof openDialog === 'object' ? openDialog : null}
          connectorTypeList={connectorTypeList}
          onSuccess={handleSubmitSuccess}
          onClose={() => {
            setOpenDialog(false);
          }}
        />
      ) : null}
    </>
  );
}

ConnectorList.propTypes = {
  pbcId: PropTypes.number,
  connectorPbcList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  canAdd: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default ConnectorList;
