export const DEFAULT_THEME = 'blue';

export const blueTheme = {
  name: 'blue',
  token: {
    colorPrimary: '#1890ff',
    colorPrimaryBg: '#e6f4ff',
    colorPrimaryHover: '#40a9ff',
    colorInfo: '#1677ff',
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#ff4d4f',
    colorBorder: '#dcdedf',
    colorBorderSecondary: '#f0f0f0',
    colorLink: '#1890ff',
    colorLinkHover: '#69c0ff',
    colorLinkActive: '#096dd9',
    colorTextBase: '#000',
    colorText: '#333',
    colorTextTertiary: 'rgba(0 0 0 / 45%)',
    controlHeight: 32,
    borderRadius: 6,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 14,
    lineHeight: 1.5,
    zIndexPopupBase: 10,
  },
  components: {},
  cssVars: {
    '--page-background-color': '#fff',
    '--card-shadow':
      'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  },
};

export const darkBlueTheme = {
  token: {
    colorPrimary: '#4540DF',
    colorPrimaryBg: '#f3f0ff',
    colorPrimaryBgHover: '#f0edff',
    colorPrimaryBorder: '#ccc4ff',
    colorPrimaryBorderHover: '#a197f7',
    colorPrimaryHover: '#726aeb',
    colorPrimaryActive: '#2c2cb8',
    colorPrimaryTextHover: '#726aeb',
    colorPrimaryText: '#4540df',
    colorPrimaryTextActive: '#2c2cb8',
    colorInfo: '#4540DF',
    colorLink: '#4540DF',
    colorLinkHover: '#a197f7',
    colorLinkActive: '#2c2cb8',
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F8F8FE',
    '--card-shadow': null,
  },
};

export const greenTheme = {
  token: {
    colorPrimary: '#29A875',
    colorPrimaryBg: '#EFF8F4',
    colorPrimaryBgHover: '#cff1df',
    colorPrimaryBorder: '#95cfb2',
    colorPrimaryBorderHover: '#6dc29a',
    colorPrimaryHover: '#48b586',
    colorPrimaryActive: '#19825b',
    colorPrimaryTextHover: '#48b586',
    colorPrimaryText: '#29a875',
    colorPrimaryTextActive: '#19825b',
    colorInfo: '#29A875',
    colorLink: '#29A875',
    colorLinkHover: '#6dc29a',
    colorLinkActive: '#19825b',
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F4F6FA',
    '--card-shadow': null,
  },
};

export const greenWithBlackButtonTheme = {
  token: {
    colorPrimary: '#6bb665',
    colorPrimaryBg: '#eaf5e6',
    colorPrimaryBgHover: '#dde8da',
    colorPrimaryBorder: '#d0dbce',
    colorPrimaryBorderHover: '#b7cfb4',
    colorPrimaryHover: '#8fc28a',
    colorPrimaryActive: '#4a8f47',
    colorPrimaryTextHover: '#8fc28a',
    colorPrimaryText: '#6bb665',
    colorPrimaryTextActive: '#4a8f47',
    colorInfo: '#202E3F',
    colorLink: '#202E3F',
    colorLinkHover: '#91999E',
    colorLinkActive: '#090F17',
    borderRadius: 4,
  },
  components: {
    Button: {
      colorPrimary: 'rgb(32, 46, 63)',
      colorPrimaryHover: 'rgb(69, 79, 91)',
      controlOutline: 'rgba(32, 46, 63, 0.1)',
      controlTmpOutline: 'rgba(32, 46, 63, 0.1)',
      colorPrimaryActive: 'rgb(9, 15, 23)',
      colorLink: 'rgb(32, 46, 63)',
      colorLinkActive: 'rgb(9, 15, 23)',
      colorLinkHover: 'rgb(145, 153, 158)',
    },
    Tabs: {
      colorPrimary: 'rgb(32, 46, 63)',
      colorPrimaryActive: 'rgb(9, 15, 23)',
      colorPrimaryHover: 'rgb(145, 153, 158)',
    },
  },
  cssVars: {
    '--page-background-color': '#F8F8F8',
    '--card-shadow': null,
  },
};

export const orangeTheme = {
  token: {
    colorPrimary: '#f68443',
    colorPrimaryBg: '#fff8f0',
    colorPrimaryBgHover: '#fff4e8',
    colorPrimaryBorder: '#ffddbf',
    colorPrimaryBorderHover: '#ffc496',
    colorPrimaryHover: '#ffa86e',
    colorPrimaryActive: '#cf632d',
    colorPrimaryTextHover: '#ffa86e',
    colorPrimaryText: '#f68443',
    colorPrimaryTextActive: '#cf632d',
    colorInfo: '#f68443',
    colorLink: '#f68443',
    colorLinkHover: '#fbab6d',
    colorLinkActive: '#cf632d',
    borderRadius: 16,
    borderRadiusLG: 20,
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F8F8F8',
    '--card-shadow': null,
  },
};

export const grayTheme = {
  token: {
    colorPrimary: '#4c5f69',
    colorPrimaryBg: '#dce4e6',
    colorPrimaryBgHover: '#929a9c',
    colorPrimaryBorder: '#868d8f',
    colorPrimaryBorderHover: '#7a8082',
    colorPrimaryHover: '#7b919a',
    colorPrimaryActive: '#2c3a42',
    colorPrimaryTextHover: '#677175',
    colorPrimaryText: '#4c5f69',
    colorPrimaryTextActive: '#2c3a42',
    colorInfo: '#4c5f69',
    colorLink: '#4c5f69',
    colorLinkHover: '#8c979b',
    colorLinkActive: '#2c3a42',
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F5F8F9',
    '--card-shadow': null,
  },
};

const themeMap = {
  blue: blueTheme,
  green: greenTheme,
  greenWithBlackButton: greenWithBlackButtonTheme,
  darkBlue: darkBlueTheme,
  orange: orangeTheme,
  gray: grayTheme,
};

// TODO, 临时 material 解决方案，后续需要整理
const tempMaterialTheme = {
  name: 'temp-material-ui',
  token: {
    // 这个是设给 ant 的。因为 material dialog 是 1300，TreeSelect 目前还没有适配 material，在 dialog 里用 TreeSelect 的话 dropdown 会被 material dialog 挡住看不见
    zIndexPopupBase: 1300,
  },
  cssVars: {
    '--card-padding': '24px',
    '--card-radius': '16px',
  },
  // TODO, vars 里的 css 变量机制无法实现在 form designer 里 canvas 的组件使用 theme 的 vars，因为 material 组件大部分的字体设置都是用的 0.875rem 这种
  css: `
html:not(.fd-app-context),
.form-renderer {
  -webkit-font-smoothing: antialiased;
}

body {
  color: rgb(28, 37, 46);
}

.card-layout.variant-pc .card-title {
  padding-bottom: var(--card-padding);
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
}

.card-layout.variant-pc .card-title .title-desc {
  margin-top: 4px;
  color: rgb(99, 115, 129);
}
  `,
};

// theme2 覆盖 theme1
function mergeTwoTheme(theme1, theme2) {
  // Properties in obj2 cover properties in obj1
  const mergeObj = (obj1, obj2) => {
    if (!obj2 || !Object.keys(obj2).length) return obj1;

    const merged = { ...obj1 };

    for (const [k, v] of Object.entries(obj2)) {
      if (
        typeof v === 'object' &&
        v !== null &&
        typeof merged[k] === 'object' &&
        merged[k] !== null
      ) {
        merged[k] = mergeObj(merged[k], v);
      } else {
        merged[k] = v;
      }
    }

    return merged;
  };

  const mergeCss = (css1, css2) => {
    if (!css1 && typeof css2 === 'string') return css2;
    if (!css2 && typeof css1 === 'string') return css1;

    if (typeof css1 === 'string' && typeof css2 === 'string') {
      return `${css1}${css2}}`;
    }

    return '';
  };

  return {
    base: theme2.base,
    token: mergeObj(theme1.token, theme2.token),
    components: mergeObj(theme1.components, theme2.components),
    cssVars: mergeObj(theme1.cssVars, theme2.cssVars),
    agGridCssVars: mergeObj(theme1.agGridCssVars, theme2.agGridCssVars),
    css: mergeCss(theme1.css, theme2.css),
  };
}

export function extendsTheme(themeConfig, componentLibrary) {
  const { base: baseProp, cssVars = themeConfig?.varsCss } = themeConfig;
  const base = themeMap[baseProp] ? baseProp : DEFAULT_THEME;

  // 默认都 base blueTheme
  const usedConfig = themeMap[base] || themeMap[DEFAULT_THEME];

  // themeConfig 使用的 base theme 也需要递归的去 extends theme，
  let baseTheme = usedConfig !== blueTheme ? extendsTheme(usedConfig) : blueTheme;

  // TODO, 临时解决方案
  if (componentLibrary === 'material-ui') {
    baseTheme = mergeTwoTheme(baseTheme, tempMaterialTheme);
  }

  return mergeTwoTheme(baseTheme, { ...themeConfig, base, cssVars });
}

function antThemeToCssVar(token) {
  if (!token || !Object.keys(token)) {
    return null;
  }

  const entries = Object.keys(token).map((key) => [`--${key}`, token[key]]);

  return Object.fromEntries(entries);
}

function mergeThemeCssVars(mergedTheme) {
  const { token, cssVars } = mergedTheme;
  const antCssVars = antThemeToCssVar(token);
  return { ...antCssVars, ...cssVars };
}

function insertStyleElement(cssText, id) {
  let style = document.querySelector(`[data-id="${id}"]`);
  if (!style) {
    style = document.createElement('style');
    style.setAttribute('data-id', id);
    document.head.appendChild(style);
  }
  style.textContent = cssText;
}

function removeStyleElement(id) {
  const style = document.querySelector(`[data-id="${id}"]`);
  if (style) {
    document.head.removeChild(style);
  }
}

function insertCssVarsToDocument(cssVars, id, selector) {
  if (!cssVars || !Object.keys(cssVars)) {
    return;
  }
  let cssText = `${selector}{`;
  for (const [key, value] of Object.entries(cssVars)) {
    cssText += `${key}:${value};`;
  }
  cssText += '}';

  insertStyleElement(cssText, id);
}

function insertCssToDocument(css, id) {
  if (!css) return;
  insertStyleElement(css, id);
}

export function insertThemeConfigToPage(mergedTheme, oldCss, scope) {
  const { base, agGridCssVars, css: themeCss } = mergedTheme;

  // 显示页面的主题到 html 根节点方便 debug
  if (scope === ':root') {
    document.documentElement.setAttribute('data-theme', base);
  }

  // css 变量写入页面给 vars.css 以及组件使用实现主题切换
  // 将 ant theme config 的 token 转换为 css 变量放到页面给 vars.css 配置，其余组件使用可以使用 vars.css 中的值来达到主题一致的效果
  const themeCssVars = mergeThemeCssVars(mergedTheme);
  const themeVarsStyleId = `${scope}-css-vars`;
  insertCssVarsToDocument(themeCssVars, themeVarsStyleId, scope);

  // ag-grid 的 css 变量写入页面给 vars.css 以及组件使用实现主题切换, 增加 selector 数量以优先级来覆盖 AgTable.css 里定义的变量
  const agVarsStyleId = `${scope}-ag-grid-css-vars`;
  insertCssVarsToDocument(agGridCssVars, agVarsStyleId, `${scope} div[class*='ag-theme-']`);

  // 自定义的全局 css 写入页面
  // theme css 包含 3 块内容:
  // 1. base theme 里预定义的 css;
  // 2. 在平台 themeConfig 里定义的 themeConfig.css;
  // 3. 以及旧的全局 css 设置 appConfig.css.
  const cssStyleId = `${scope}-theme-css`;
  insertCssToDocument(`${themeCss || ''}${oldCss || ''}`, cssStyleId);
}

export function removeThemeConfigFromPage(scope) {
  const themeVarsStyleId = `${scope}-css-vars`;
  const agVarsStyleId = `${scope}-ag-grid-css-vars`;
  const cssStyleId = `${scope}-theme-css`;

  document.documentElement.removeAttribute('data-theme');
  removeStyleElement(themeVarsStyleId);
  removeStyleElement(agVarsStyleId);
  removeStyleElement(cssStyleId);
}
