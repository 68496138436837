import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const { RangePicker } = DatePicker;

const DateRangePickerElement = forwardRef(function DateRangePickerElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    disabled,
    readonly,
    style,
    componentProps = {},
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const { showTime, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const momentValue = useMemo(() => {
    if (!Array.isArray(value)) {
      return [];
    }
    if (
      !(typeof value[0] === 'string' && value[0].length) ||
      !(typeof value[1] === 'string' && value[1].length)
    ) {
      return ['', ''];
    }
    return [dayjs(value[0]), dayjs(value[1])];
  }, [value]);

  const setReadOnlyValue = useMemo(() => {
    if (!Array.isArray(value)) return '';
    if (
      !(typeof value[0] === 'string' && value[0].length) ||
      !(typeof value[1] === 'string' && value[1].length)
    ) {
      return '';
    }
    return `${value[0]}-${value[1]}`;
  }, [value]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'data-range-picker-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          {readonly ? (
            <span className="readonly-text">{setReadOnlyValue || ''}</span>
          ) : (
            <RangePicker
              {...otherComponentProps}
              className={classNameComp}
              name={id}
              value={momentValue}
              disabled={disabled}
              showTime={showTime}
              onChange={(date, dateString) => onChange(dateString)}
              onBlur={onTouchChanged}
              status={status}
            />
          )}
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

DateRangePickerElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 是否显示时间
     */
    showTime: PropTypes.bool,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
DateRangePickerElement.displayName = 'DateRangePicker';

export default withFieldWrapper(DateRangePickerElement);
