import { forwardRef } from 'react';
import TextElement from './TextElement';
import { withFieldWrapper } from '../fieldWrapper';

const FormulaElement = forwardRef(function FormulaElement(props, ref) {
  return <TextElement {...props} ref={ref} />;
});

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
FormulaElement.displayName = 'Formula';

export default withFieldWrapper(FormulaElement);
