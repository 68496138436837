function Test(props) {
  return (
    <p>
      <pre>
        <code>{`ExtensionId: _Test\nExtensionParams:\n${JSON.stringify(props, null, 2)}`}</code>
      </pre>
    </p>
  );
}

export default Test;
