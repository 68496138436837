export const INPUT_ELEMENT_ENUM = [
  'ACL',
  'Cascader',
  'Checkbox',
  'CodeEditor',
  'DatePicker',
  'DateRangePicker',
  'EditableTable',
  'FormDesigner',
  'Formula',
  'IconUpload',
  'Input',
  'NumberPicker',
  'Ocr',
  'Password',
  'Permissions',
  'Radio',
  'Rate',
  'RichText',
  'Select',
  'Slider',
  'Switch',
  'Textarea',
  'TimePicker',
  'TimeRangePicker',
  'TreeSelect',
  'Upload',
];

export const DISPLAY_ELEMENT_ENUM = [
  'Audit',
  'Button',
  'Carousel',
  'Collapse',
  'Divider',
  'DropDown',
  'EChart',
  'Gantt',
  'EventCalendar',
  'Icon',
  'Image',
  'List',
  'Mining',
  'NavTab', // legacy support, NavTab already renamed to NavTabs
  'NavTabs',
  'Progress',
  'QRCode',
  'Status',
  'Steps',
  'Success',
  'Tab', // legacy support, Tab already renamed to Tabs
  'Table',
  'Tabs',
  'Text',
  'TodoList',
  'Tooltip',
  'Tree',
  'Video',
  'PageHeader',
  // 'Flow',
];

export const LAYOUTS_ENUMS = [
  // compatible with old usage
  'NormalLayout',
  'FlowLayout',
  'GridLayout',
  'CardLayout',
  'MobileLayout',
  //
  'Box', // Box, NormalLayout mean same things
  'Stack', // Stack, FlowLayout means same things
  'Grid',
  'Card',
  'Mobile',
];

export const WRAPPERS_ENUMS = ['Data', 'LinkWrapper', 'Link', 'Timer', 'Extension'];

export const ELEMENT_ENUMS = [...INPUT_ELEMENT_ENUM, ...DISPLAY_ELEMENT_ENUM];
export const FIELD_ENUMS = [...ELEMENT_ENUMS, ...LAYOUTS_ENUMS, WRAPPERS_ENUMS];
