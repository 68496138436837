import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { QRCode } from '@icp/components';
import { useElementDecorator } from '../FormRenderCtx';
import { useClassName, useDisplayValue, useVariablePattern } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

const QRCodeElement = forwardRef(function QRCodeElement(props, ref) {
  const { keyPath, id, valueField, className: classNameProp, style, componentProps = {} } = props;

  const {
    text: textProp,
    type = 'text',
    title: titleProp,
    description: descriptionProp,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const value = useDisplayValue(id, valueField);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const textResolved = useVariablePattern(textProp);
  const title = useVariablePattern(titleProp);
  const description = useVariablePattern(descriptionProp);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const formatValue = (text) => {
    if (type === 'internalUrl') {
      return `${window.location.origin}${text}`;
    }
    return text;
  };
  const text = formatValue(value || textResolved);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <QRCode
        {...otherComponentProps}
        className={clsx('qr-code-element form-element', className, classNameComp)}
        text={typeof text === 'string' ? text : JSON.stringify(text)}
        title={title}
        description={description}
        style={style}
        ref={nodeRef}
      />
    </ElementDecorator>
  );
});

QRCodeElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 转换成二维码的文本信息
     */
    text: PropTypes.string,
    /**
     * 二维码的类型
     */
    type: PropTypes.oneOf(['text', 'internalUrl']),
    /**
     * 二维码的标题
     */
    title: PropTypes.string,
    /**
     * 二维码的描述信息
     */
    description: PropTypes.string,
    /**
     * 二维码的宽度
     */
    width: PropTypes.number,
    /**
     * 二维码的高度
     */
    height: PropTypes.number,
    /**
     * qrcode `toDataURL` 方法的的属性
     */
    QRProps: PropTypes.shape({
      margin: PropTypes.number,
      width: PropTypes.number,
    }),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
QRCodeElement.displayName = 'QRCode';

export default withFieldWrapper(QRCodeElement);
