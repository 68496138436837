import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tooltip } from 'antd';
import { Icon } from '@icp/components';
import FieldTitle from '../FieldTitle';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName, useDisplayValue, useVariablePattern } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

const ImageElement = forwardRef(function ImageElement(props, ref) {
  const {
    keyPath,
    id,
    valueField,
    className: classNameProp,
    title,
    style,
    componentProps = {},
  } = props;

  const {
    width,
    height,
    src: srcProp,
    alt,
    style: imgStyle,
    tooltipByValue,
    fieldTitleProps,
    ...otherImgProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const value = useDisplayValue(id, valueField);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const src = useVariablePattern(srcProp) || value;

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  // TODO, 提取 tooltip 组件
  const tooltipValue = tooltipByValue && value ? tooltipByValue[value] : null;

  const img =
    isInDesign && !/^http/i.test(src) ? (
      <Icon
        name="oct:image"
        size={24}
        className={classNameComp}
        style={{ width, height, ...imgStyle }}
      />
    ) : (
      <img
        {...otherImgProps}
        className={classNameComp}
        src={src}
        alt={alt}
        style={{ width, height, ...imgStyle }}
      />
    );

  // TODO, 为什么 image 会有 FieldTitle？
  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div className={clsx('image-element form-element', className)} style={style} ref={nodeRef}>
        {title && <FieldTitle {...fieldTitleProps}>{title}</FieldTitle>}
        {tooltipByValue ? (
          <Tooltip title={<span style={{ color: '#000' }}>{tooltipValue}</span>} color="#fff">
            {img}
          </Tooltip>
        ) : (
          img
        )}
      </div>
    </ElementDecorator>
  );
});

ImageElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 图片的宽度
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 图片的高度
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * 图片的地址
     */
    src: PropTypes.string,
    /**
     * 图片的 alt
     */
    alt: PropTypes.string,
    /**
     * @deprecated
     */
    tooltipByValue: PropTypes.shape({}),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ImageElement.displayName = 'Image';

export default withFieldWrapper(ImageElement);
