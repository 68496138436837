import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useValueLanguageOptions } from '../hooks';

function ValueLanguageSelect(props) {
  const { value, onChange } = props;

  const languageOptions = useValueLanguageOptions();

  return (
    <Select
      className="element-value-i18n-select"
      popupMatchSelectWidth={false}
      value={value}
      options={languageOptions}
      onChange={onChange}
    />
  );
}

ValueLanguageSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ValueLanguageSelect;
