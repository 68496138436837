import './cellRenderers.css';
import PropTypes from 'prop-types';
import { ensureDisplayText } from '@icp/utils';
import { ConditionalPropertyPropType } from '../../../propTypes';
import LinkWrapper from '../../../wrappers/LinkWrapper';
import withCurrentData from './withCurrentData';
import { useVariablePattern } from '../../../hooks';
import TextElement from '../../TextElement';

function TextCellRenderer(props) {
  const {
    value,
    valueFormatted,
    href,
    hrefSelector,
    hrefIsSiteBased,
    suppressBasePath,
    suppressInheritIncludeDeleted,
    formatter: formatterProp,
  } = props;

  const currency = useVariablePattern(formatterProp?.props?.currency, false);
  const formatter = TextElement.resolveFormatter(formatterProp, currency);

  // '[object Object]' 是因为 ag-grid 在 valueFormatted 做了多余的事情，最后判断如果 value 是 array 会强制调用 value.join(', '),
  // 导致即使 colDef 没有提供 valueFormatter 的时候 [{}, {}] 会变成 '[object Object], [object Object]'
  const displayText =
    typeof valueFormatted === 'number' ||
    (typeof valueFormatted === 'string' && !valueFormatted.startsWith('[object Object]'))
      ? valueFormatted
      : formatter(value);

  if (href || hrefSelector) {
    return (
      <LinkWrapper
        className="table-text-cell"
        componentProps={{
          href,
          hrefSelector,
          hrefIsSiteBased,
          suppressBasePath,
          suppressInheritIncludeDeleted,
          suppressLinkColor: false,
        }}
      >
        {ensureDisplayText(displayText)}
      </LinkWrapper>
    );
  }

  return ensureDisplayText(displayText);
}

TextCellRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.shape({}),
  // href must like '/path/{id}' or '/path/:Id'
  // TODO, support pattern '/path/{id}'
  href: ConditionalPropertyPropType(PropTypes.string),
  hrefSelector: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      matched: ConditionalPropertyPropType(PropTypes.bool),
    }),
  ),
  /**
   * 如果值为 `false` 并且此链接位于 pbc 下，href 将自动拼接上 pbc 的 `token` 作为 `basename`，移动端将自动拼接上 `mobile` 的前缀
   */
  hrefIsSiteBased: PropTypes.bool,
  /**
   * 是否直接链接不通过 react router 的 base path
   */
  suppressBasePath: PropTypes.bool,
  suppressInheritIncludeDeleted: PropTypes.bool,
  formatter: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf(TextElement.formatterTypes),
      props: PropTypes.shape({}),
    }),
    PropTypes.oneOf(TextElement.formatterTypes),
  ]),
};

export default withCurrentData(TextCellRenderer);
