export const makeLineOption = () => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      type: 'scroll',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
    },
    series: [],
  };
};

export const makeLineSeries = () => {
  return {
    type: 'line',
    stack: 'stack',
  };
};
