/**
 * 注册/注销IcpApp内渲染的所有FormRenderer的formApi和redux store
 */
export default class FormService extends EventTarget {
  #list = []; // { formApi, store }

  get list() {
    return [...this.#list];
  }

  on(eventType, listener, options) {
    this.addEventListener(eventType, listener, options);
  }

  off(eventType, listener, options) {
    this.removeEventListener(eventType, listener, options);
  }

  register(form) {
    Object.freeze(form);
    this.#list.push(form);
    this.dispatchEvent(new CustomEvent('register', { detail: form }));
    return () => this.unregister(form);
  }

  unregister(form) {
    this.#list = this.#list.filter((item) => item !== form);
    this.dispatchEvent(new CustomEvent('unregister', { detail: form }));
  }
}
