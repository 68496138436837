import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  AsyncManager,
  fetchFormData,
  FORM_API_STAGE,
  handleSubmitSuccess,
  injectFormApi,
  processSubmitData,
  resetFileStorage,
  selectContext,
  selectIsFetching,
  setErrors,
  setSubmitting,
  submitFormData,
} from '@icp/form-renderer-core';
import { Loading } from '@icp/components';
import { getAppConfig } from '@icp/settings';
import { useDispatch, useSelector, useStore } from './store';
import RecursionRenderer from './RecursionRenderer';
import ButtonElement from './elements/ButtonElement';
import { FormRenderCtxProvider } from './FormRenderCtx';
import {
  useConditionalProperty,
  // useFormRendererPageTitle,
  useFormScript,
  useRegisterFormApi,
  useVariablePattern,
} from './hooks';
import { FormRendererProps } from './propTypes';
import { useDialogContext } from './dialogCtx';

const FormRenderer = forwardRef(function FormRender(props, ref) {
  const {
    ElementDecorator,
    children,
    schema,
    title: titlePattern,
    id,
    className,
    style: formStyle,
    transformRetrievedData,
    translateRetrievedData,
    retrieveUrl: retrieveUrlPattern,
    createUrl,
    updateUrl,
    submitMethod,
    transformDataSubmit,
    labelLayout = 'horizontal',
    showColon = false,
    defaultSubmitButton = false,
    defaultCancelButton = false,
    defaultButtonPosition = 'bottom',
    disabled: formLevelDisabledConfig = false,
    readonly: formLevelReadonlyConfig = false,
    suppressValidation: suppressValidationProp = false,
    disableHideDefaultButtonsInDialog = false,
    script,
    allowedLanguages, // 同时支持 Designer 里设置项目的 allowedLanguages 使用
    componentLibrary = getAppConfig()?.componentLibrary || 'ant-design', // 同时支持 Designer 里设置项目的 componentLibrary 使用
    formApi: formApiEmpty,
    events,
    // 只能通过 jsx 写代码调用的 events
    onSubmit,
    onSave,
    onCancel,
    onRefreshData,
  } = props;

  const { t } = useTranslation(['icp-form-renderer', 'icp-common']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();
  const isFetching = useSelector(selectIsFetching);
  const isInDesign = !!ElementDecorator;
  const context = selectContext(store.getState());
  const dialogContext = useDialogContext();
  const [modifiedSchema, setModifiedSchema] = useState();

  const title = useVariablePattern(titlePattern, false, isInDesign);
  const retrieveUrl = useVariablePattern(retrieveUrlPattern);
  const submitUrl = useVariablePattern(
    (submitMethod === 'post' && createUrl) || (submitMethod === 'put' && updateUrl) || '',
  );
  const formDisabled = useConditionalProperty(formLevelDisabledConfig);
  const formReadonly = useConditionalProperty(formLevelReadonlyConfig);
  const suppressValidation = useConditionalProperty(suppressValidationProp);

  const fieldApis = useRef({});
  const setFieldApi = useCallback((fieldId, fieldApi) => {
    if (!fieldId) {
      return;
    }
    fieldApis.current[fieldId] = fieldApi;
  }, []);

  const formApi = useMemo(() => {
    return injectFormApi({
      formApiEmpty,
      store,
      dispatch,
      fieldApis: fieldApis.current,
      events,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const readyManager = useMemo(() => {
    const manager = new AsyncManager();
    manager.register(['formData', 'asyncComponent']);
    manager.onReady(() => {
      formApi.stage = FORM_API_STAGE.ready;
      formApi.eventService.dispatch({ type: 'ready', payload: formApi });
    });
    return manager;
  }, [formApi]);

  useRegisterFormApi(formApi, store);
  useImperativeHandle(ref, () => formApi, [formApi]);
  // useFormRendererPageTitle(title, isFetching);
  useFormScript(script, formApi, isInDesign, isFetching);

  useEffect(() => {
    // 设计模式下不发请求取数据
    if (isInDesign) return;
    if (retrieveUrl) {
      dispatch(fetchFormData({ retrieveUrl, transformRetrievedData, translateRetrievedData }));
    }
  }, [isInDesign, dispatch, retrieveUrl, transformRetrievedData, translateRetrievedData]);

  useEffect(() => {
    if (!isFetching) {
      formApi.stage = FORM_API_STAGE.rendered;
      formApi.eventService.dispatch({ type: 'firstRendered', payload: formApi });
      readyManager.setReady('formData');
    } else {
      formApi.stage = FORM_API_STAGE.fetchData;
    }
  }, [formApi, isFetching, readyManager]);

  // 必须放在 useFormScript 之后。当没有异步组件的时候 ready 会立即触发，所以要用 useFormScript 先绑定 ready 事件。
  useEffect(() => {
    formApi.asyncComponentManager.onReady(() => {
      readyManager.setReady('asyncComponent');
    });
  }, [formApi, readyManager]);

  useEffect(() => {
    // 在 isFetching 的时候会出 loading 禁止组件渲染，必须等 loading 结束过后异步组件注册完成过后才能 checkReady
    if (!isFetching) {
      // 表单渲染过后立马 check 一下，如果没有异步组件，直接设置 asyncComponentManager ready
      // 延迟一下支持在 PageRenderer 里 useEffect 再去调用 formApi.on('ready')
      setTimeout(() => {
        formApi.asyncComponentManager.checkReady();
      }, 16);
    }
  }, [formApi.asyncComponentManager, isFetching]);

  useEffect(() => {
    return () => {
      formApi.eventService.dispatch({ type: 'unmount' });
    };
  }, [formApi]);

  const handleSubmit = async ({ saveOnly, handler } = {}) => {
    // If there is onSave or onSubmit callback, call it.
    // Otherwise, call FormRenderer default submit function.
    let submitCallback;
    if (handler) {
      submitCallback = handler;
    } else if (saveOnly && onSave) {
      submitCallback = onSave;
    } else if (onSubmit) {
      submitCallback = onSubmit;
    } else if (submitUrl) {
      submitCallback = (data) => {
        return dispatch(submitFormData({ method: submitMethod, url: submitUrl, data }));
      };
    }

    if (!submitCallback) {
      return Promise.reject(
        new Error(
          `No ${
            (submitMethod === 'post' && 'createUrl') ||
            (submitMethod === 'put' && 'updateUrl') ||
            'submitMethod'
          } and no callback`,
        ),
      );
    }

    dispatch(setSubmitting(true));

    const dataToSubmit = await processSubmitData({
      store,
      formApi,
      suppressValidation,
      saveOnly,
      transformDataSubmit,
    });

    return submitCallback(dataToSubmit)
      .then((resData) => {
        dispatch(handleSubmitSuccess({ newData: resData }));
        // file storage upload permission is temporary, after submit form, the file storage upload
        // permission is out of date.
        dispatch(resetFileStorage());
        setTimeout(() => {
          formApi.eventService.dispatch({ type: 'submitted', payload: resData });
        }, 0);
        return resData;
      })
      .catch((error) => {
        if (error.validationError) {
          dispatch(setErrors(error.validationError));
        }
        return Promise.reject(error);
      })
      .finally(() => dispatch(setSubmitting(false)));
  };

  const handleRefresh = () => {
    const refreshFields = Object.values(fieldApis.current)
      .map((x) => x?.refresh)
      .filter(Boolean)
      .map((refreshFn) => Promise.resolve(refreshFn()));

    if (retrieveUrl) {
      return dispatch(
        fetchFormData({ retrieveUrl, transformRetrievedData, translateRetrievedData }),
      )
        .unwrap()
        .then((res) => Promise.all(refreshFields).then(() => res));
    }

    if (onRefreshData) {
      return Promise.resolve(onRefreshData()).then((res) =>
        Promise.all(refreshFields).then(() => res),
      );
    }

    return Promise.resolve();
  };

  const handleCancel = (event) => {
    if (onCancel) {
      onCancel();
    } else if (dialogContext) {
      dialogContext.close({ state: event?.state || 'cancel', response: event?.response });
    } else {
      navigate(-1);
    }
  };

  formApi.submit = handleSubmit;
  formApi.refresh = handleRefresh;
  formApi.cancel = handleCancel;
  formApi.getProps = () => props;
  formApi.getSchema = () => modifiedSchema || schema;
  // for POC usage
  formApi.changeSchema = (cb) => {
    const nextSchema = cb(modifiedSchema || schema);
    setModifiedSchema(nextSchema);
    formApi.eventService.dispatch({ type: 'schemaChanged', payload: nextSchema });
  };
  formApi.getTitle = () => title;

  const defaultButtons =
    (defaultSubmitButton || defaultCancelButton) &&
    !(context.isInDialog && !disableHideDefaultButtonsInDialog) ? (
      <div
        className={clsx('form-renderer-default-button', {
          'form-renderer-default-button-top': defaultButtonPosition === 'top',
          'form-renderer-default-button-bottom': defaultButtonPosition === 'bottom',
        })}
      >
        {defaultCancelButton ? (
          <ButtonElement
            componentProps={{
              content: t('cancel', { ns: 'icp-common' }),
              action: {
                type: 'cancel',
              },
              ...(typeof defaultCancelButton === 'object' ? defaultCancelButton : {}),
            }}
          />
        ) : null}
        {defaultSubmitButton && !formReadonly && !formDisabled ? (
          <ButtonElement
            componentProps={{
              type: 'primary',
              content: t('submit', { ns: 'icp-common' }),
              action: {
                type: 'submit',
                successAction: {
                  type: 'cancel',
                  state: dialogContext ? 'success' : undefined,
                },
              },
              ...(typeof defaultSubmitButton === 'object' ? defaultSubmitButton : {}),
            }}
          />
        ) : null}
      </div>
    ) : null;

  if (isFetching) {
    return <Loading className="form-renderer-block-loading" />;
  }

  return (
    <FormRenderCtxProvider
      ElementDecorator={ElementDecorator}
      isInDesign={isInDesign}
      formDisabled={formDisabled}
      formReadonly={formReadonly}
      formApi={formApi}
      fieldApis={fieldApis}
      setFieldApi={setFieldApi}
      allowedLanguages={allowedLanguages}
      componentLibrary={componentLibrary}
    >
      <div
        id={id}
        className={clsx(
          'form-renderer',
          {
            'label-layout-horizontal': labelLayout === 'horizontal',
            'label-layout-vertical': labelLayout === 'vertical',
            'label-layout-colon': showColon,
            fetching: isFetching,
            'in-design': !!ElementDecorator,
          },
          className,
          `library-${componentLibrary}`,
        )}
        style={formStyle}
        ref={(node) => {
          formApi.node = node;
        }}
      >
        {defaultButtonPosition === 'top' && defaultButtons}
        {children || (
          <RecursionRenderer fields={(modifiedSchema || schema)?.fields} keyPath={['fields']} />
        )}
        {defaultButtonPosition === 'bottom' && defaultButtons}
        {/* {isFetching ? <Loading className="form-renderer-data-loading" /> : null} */}
      </div>
    </FormRenderCtxProvider>
  );
});

FormRenderer.propTypes = FormRendererProps;

export default FormRenderer;
