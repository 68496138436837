import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const Load = ({ ns, children }) => {
  useTranslation(ns);
  return children;
};

// load i18n namespace first before rendering children
function LoadI18nResource({ children, ns, fallback = null }) {
  return (
    <Suspense fallback={fallback}>
      <Load ns={ns}>{children}</Load>
    </Suspense>
  );
}

LoadI18nResource.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node,
  ns: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default LoadI18nResource;
