/* eslint-disable react/prop-types */
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchBox from '../../SearchBox';
import Icon from '../../Icon';
import { getSelectableColumns } from '../utils';
import Popover from '../../Popover';

function ColumnSelect(props) {
  const { gridApi } = props;

  const { t } = useTranslation(['icp-components']);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [, setFresh] = useState(Date.now());

  const anchorEl = useRef(null);

  const handleClick = (colId) => {
    const oldVisible = gridApi.getColumn(colId)?.visible;
    gridApi.setColumnVisible(colId, !oldVisible);
    setFresh(Date.now());
  };

  return (
    <>
      <Tooltip title={t('table.show-hide-column')}>
        <Button
          className="icp-table-toolbar-column-button"
          type="text"
          icon={<Icon name="oct:columns" size={16} />}
          onClick={() => setOpen(true)}
          ref={anchorEl}
        />
      </Tooltip>
      <Popover
        className="icp-table-column-select"
        anchorEl={anchorEl.current}
        open={open}
        placement="bottom-end"
        onClose={() => setOpen(false)}
      >
        <SearchBox
          open={true}
          suppressSpeech={true}
          autoFocus={true}
          debounce={false}
          onSearch={setSearchText}
        />
        <div className="icp-table-menu icp-thin-scrollbar">
          {open &&
            getSelectableColumns(gridApi, searchText)
              .filter((column) => column.colId !== 'ag-Grid-AutoColumn')
              .map((column) => {
                const { colDef } = column;
                return (
                  <div
                    className="item icp-clickable"
                    key={column.colId}
                    onClick={!colDef.lockVisible ? () => handleClick(column.colId) : null}
                  >
                    <span
                      style={{
                        opacity: (colDef.lockVisible && 0.2) || (column.visible && 1) || 0,
                      }}
                    >
                      <Icon name="oct:check" size={16} />
                    </span>
                    {colDef.headerName || column.colId}
                  </div>
                );
              })}
        </div>
      </Popover>
    </>
  );
}

ColumnSelect.propTypes = {
  gridApi: PropTypes.shape({}),
};

export default ColumnSelect;
