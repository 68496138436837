import { useEffect, useMemo, useRef, useState } from 'react';
import useEventCallback from './useEventCallback';

const hotKeys = ['Control', 'b', 'o', 't'];

/**
 * 按顺序依次按下 hotKeys 键设置 enabled 为 true 以打开 json 模式
 * @return {{stage: number|*, enabled: boolean}}
 */
export default function useJSONMode() {
  const [enabled, setEnabled] = useState(false);
  const [prevKey, setPrevKey] = useState(null);
  const [inExit, setInExit] = useState(false);

  const mouseDownKey = useRef(null);
  // mousedown hotKeys[0] 的时候设置为 true，当 mouseup 的时候还是 true 则进入 startListen 后续几个键
  // 目的是为了防止按住 hotKey[0] 一直不动再放开的误操作，或者 control+tab 切换浏览器 tab 再切换回来再放手会触发 mouseup。
  // 总的目的都是为了实现按下 hotKey[0] 马上放开再按下一个键进行热键组合
  const waitForListen = useRef(false);
  const timeout = useRef(null);

  const startListen = useEventCallback((event) => {
    clearTimeout(timeout.current);

    setInExit(false);
    setPrevKey(event.key);

    // listen 开始过后 2s 没输入退出 listen
    timeout.current = setTimeout(() => {
      stopListen();
    }, 2000);
  });

  const stopListen = useEventCallback(() => {
    clearTimeout(timeout.current);
    mouseDownKey.current = null;

    setInExit(true);
    timeout.current = setTimeout(() => {
      setInExit(false);
    }, 2000);

    setPrevKey(null);
  });

  const handleKeyDown = useEventCallback((event) => {
    mouseDownKey.current = event.key;

    if (event.key === hotKeys[0]) {
      // 第一次 hotKeys[0] 进入 listen 过后再次按 hotKey[0] 退出
      if (prevKey) {
        stopListen();
        return;
      }
      waitForListen.current = true;
      setTimeout(() => {
        waitForListen.current = false;
      }, 300);
      // keydown Control key and keyup Control key to start listen
      return;
    }

    if (!prevKey) {
      return;
    }

    const prevKeyIndex = hotKeys.indexOf(prevKey);

    if (event.key !== hotKeys[0] && hotKeys[prevKeyIndex + 1] === event.key) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        stopListen();
      }, 1000);
      setPrevKey(event.key);
    } else {
      stopListen();
      return;
    }

    if (event.key === hotKeys[hotKeys.length - 1]) {
      setEnabled(true);
    }
  });

  const handleKeyUp = useEventCallback((event) => {
    // 第一次按 hotKey[0] 进入 listen
    if (waitForListen.current && event.key === hotKeys[0] && event.key === mouseDownKey.current) {
      startListen(event);
    }
  });

  useEffect(() => {
    if (!enabled) {
      // 一旦 enabled 过后就不再监听
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [enabled, handleKeyDown, handleKeyUp]);

  return useMemo(() => {
    const prevKeyIndex = hotKeys.indexOf(prevKey);
    // 只按了 hotKeys[0] 的时候不增加 stage 执行动画，因为无法解决按 Control + Space 切换输入法的情况，这种情况
    // 操作系统会强制先触发 keydown Control 再立即触发 keyup control，js 代码监听不到 Space 键。
    const stage = prevKeyIndex === 0 ? 0 : (prevKeyIndex + 1) / hotKeys.length;
    // flag exit 为 true 先设置 stage 0 通知外部组件执行退出动画操作等
    return { enabled, stage: !prevKey && inExit ? 0 : stage };
  }, [enabled, inExit, prevKey]);
}
