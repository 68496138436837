import PropTypes from 'prop-types';
import { Button, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { getDisplayLabel } from './utils';
import Icon from '../Icon';

function ACLInputAnt(props) {
  const { id, value, size, disabled, placeholder, status, multiple, unit, openButtonProps } = props;

  const { t } = useTranslation(['icp-components']);

  return (
    <Space.Compact block={true}>
      <Input
        className="icp-acl-input"
        size={size}
        name={id}
        value={getDisplayLabel(value)}
        disabled={disabled}
        readOnly={true}
        placeholder={placeholder}
        status={status}
        suffix={
          value?.length && multiple ? t('acl.selected', { length: value.length, unit }) : undefined
        }
      />
      <Button
        {...openButtonProps}
        size={size}
        icon={<Icon name="ACL-select" size={16} />}
        disabled={disabled}
      >
        {t('acl.selection')}
      </Button>
    </Space.Compact>
  );
}

ACLInputAnt.propTypes = {
  id: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  status: PropTypes.string,
  multiple: PropTypes.bool,
  unit: PropTypes.string,
  openButtonProps: PropTypes.shape({}),
};

export default ACLInputAnt;
