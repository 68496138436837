import './cellRenderers.css';
import PropTypes from 'prop-types';

function AutoIndexCellRenderer(props) {
  const { node } = props;

  return node.rowIndex + 1;
}

AutoIndexCellRenderer.propTypes = {
  node: PropTypes.shape({
    rowIndex: PropTypes.number,
  }),
};

export default AutoIndexCellRenderer;
