// 明确指定名字分开 import 会把 template 所有文件都打包进一个文件里，dynamic 的话会拆除几十个 1kb 的小文件
// TODO，暂时写死，等做动态 template 配置的时候 template 是不是就得单独发包了，单独发包的话可能很难满足项目的自定义需求
export default {
  Template2: () =>
    import(
      /* webpackChunkName: "Template2" */
      `@site/src/components/Templates/Template2`
    ),
  Template3: () =>
    import(
      /* webpackChunkName: "Template3" */
      `@site/src/components/Templates/Template3`
    ),
  MobileTemplate1: () =>
    import(
      /* webpackChunkName: "MobileTemplate1" */
      `@site/src/components/Templates/MobileTemplate1`
    ),
};
