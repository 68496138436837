import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { ValueLanguageSelectMaterial } from '../../../ValueLanguageSelect';

function I18nTextareaSingle(props) {
  const { id, value: valueProp, languageOp, onChange, readonly, ...other } = props;

  const valueI18nKey = `longText_i18n_${languageOp.value}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const handleChange = (event) => {
    onChange(event, languageOp.value);
  };

  return (
    <TextField
      {...other}
      multiline={true}
      fullWidth={true}
      value={valueI18n ?? ''}
      onChange={handleChange}
      InputProps={{
        readOnly: readonly,
        startAdornment: (
          <InputAdornment position="start">
            <ValueLanguageSelectMaterial value={languageOp.value} readonly={true} />
          </InputAdornment>
        ),
      }}
    />
  );
}

I18nTextareaSingle.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};

export default I18nTextareaSingle;
