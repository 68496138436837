import { forwardRef } from 'react';
import ButtonMaterial from './ButtonMaterial';
import ButtonAnt from './ButtonAnt';
import { useComponentLibrary } from '../../FormRenderCtx';

const ButtonUI = forwardRef(function ButtonUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <ButtonMaterial {...props} ref={ref} />;
  }

  return <ButtonAnt {...props} ref={ref} />;
});

export default ButtonUI;
