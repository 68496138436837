import { selectIsAdminUser } from '@icp/form-renderer-core';

export default function getAuthorizedConnectorPbcList(pbcList, context) {
  const isAdminUser = selectIsAdminUser({ context });
  const { userPermissionMap } = context;

  const connectorPbcList = (pbcList || []).filter((pbc) => pbc.type === 'CONNECTOR');

  if (isAdminUser) return connectorPbcList;
  if (
    userPermissionMap &&
    Object.keys(userPermissionMap).find((key) => {
      return ['CONNECTOR_EDIT', 'CONNECTOR_VIEW'].includes(key);
    })
  ) {
    return connectorPbcList;
  }
  return [];
}
