import { getTFunc } from '@icp/i18n';

export function filterAcceptedFiles(files, accept, sizeLimit) {
  const t = getTFunc('icp-form-renderer');
  // http://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
  const matchers = !accept
    ? []
    : accept
        .split(',')
        .map((x) => x.trim().toLowerCase())
        .map((x) => {
          if (x.startsWith('.')) {
            return (file) => file.name.toLowerCase().endsWith(x);
          }
          if (x.endsWith('/*')) {
            return (file) => file.type.toLowerCase().startsWith(x.substring(0, x.length - 1));
          }
          return (file) => file.type.toLowerCase() === x;
        });

  const sizeLimitMatcher = sizeLimit > 0 ? (file) => file.size <= sizeLimit : () => true;

  const acceptedFiles = [];
  const notMatchedMsg = [];

  for (const file of files) {
    // 如果有 storageId 表示已经上传成功的图片，不进行校验了。
    if (file.storageId) {
      acceptedFiles.push(file);
      continue;
    }

    const acceptPass = matchers.length > 0 ? matchers.some((fn) => fn(file)) : true;
    const sizeLimitPass = sizeLimitMatcher(file);
    if (!acceptPass) {
      notMatchedMsg.push(`${t('upload-not-supported')}：${file.name}`);
    } else if (!sizeLimitPass) {
      notMatchedMsg.push(`${t('upload-size-out-limit')}：${file.name}`);
    } else {
      acceptedFiles.push(file);
    }
  }

  return [acceptedFiles, notMatchedMsg];
}
