/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';

export default function useControlled({ controlled, default: defaultProp }) {
  const [valueState, setValueState] = useState(controlled ?? defaultProp);

  // isControlled should never change
  const { current: isControlled } = useRef(controlled !== undefined);
  const { current: defaultValue } = useRef(defaultProp);

  const value = isControlled ? controlled : valueState;

  useEffect(() => {
    if (!isControlled && defaultProp !== defaultValue) {
      console.error(
        'Warning: A component is changing an uncontrolled input to be controlled.' +
          'This is likely caused by the value changing from undefined to a defined value, which should not happen.' +
          'Decide between using a controlled or uncontrolled input element for the lifetime of the component.' +
          'More info: https://reactjs.org/link/controlled-components',
      );
    }
  }, []);

  const setValueIfControlled = useCallback((newValue) => {
    if (!isControlled) {
      setValueState(newValue);
    }
  }, []);

  return [value, setValueIfControlled];
}
