import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { allLocalIcons, getIconUrl, isEmoji, isImg } from '@icp/components-core';
import clsx from 'clsx';

// See assets/icons/icons.md for how to use svg icon.
const Icon = forwardRef(function Icon(props, ref) {
  const { className, name, size = 16, color, ...other } = props;

  if (typeof name !== 'string') {
    return null;
  }

  const Comp = allLocalIcons[name];

  // local icon in @site/src/assets/icons directory
  if (Comp) {
    return <Comp color={color} {...other} size={size} ref={ref} />;
  }

  // emoji
  if (isEmoji(name)) {
    return (
      <span
        className={clsx('icp-emoji', className)}
        {...other}
        style={{ fontSize: size, ...other.style }}
        ref={ref}
      >
        {name}
      </span>
    );
  }

  // image
  if (isImg(name)) {
    return (
      <img
        className={clsx('icp-icon', className)}
        src={name}
        alt="icon"
        {...other}
        style={{
          width: size,
          height: size,
          ...other.style,
        }}
        ref={ref}
      />
    );
  }

  const iconUrl = getIconUrl(name, size);

  return (
    <svg
      className={clsx('icp-icon', className)}
      fill="currentColor"
      width={size}
      height={size}
      focusable={false}
      aria-hidden={true}
      {...other}
      style={{
        color,
        ...other.style,
      }}
      ref={ref}
    >
      <use
        href={iconUrl}
        // xlink:href is deprecated, href is standard.
        // use xlink:href as a fallback in addition to support old browser like ios 12.1
        xlinkHref={iconUrl}
      />
    </svg>
  );
});

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Icon;
