import { v4 as uuidv4 } from 'uuid';
import { findFieldBy } from '@icp/form-schema';
import { findQuestionFromMessages } from '@icp/components';
import { transformToTableElement, makeTableElement } from './IcpTable/utils';
import { transformToEChartElement } from './IcpChart/utils';

export function makeTitleElement(title) {
  return {
    component: 'Text',
    componentProps: {
      content: title,
      style: {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  };
}

const GRID_ID = 'grid';

function makeGridElement(fields) {
  return {
    id: GRID_ID,
    component: 'Grid',
    componentProps: {
      colNumber: 3,
    },
    fields,
  };
}

function findCodeBlock(str) {
  const results = [];
  const regex = /```(icp-table|icp-chart)([\s\S]*?)```/g;
  for (let match = regex.exec(str); match !== null; match = regex.exec(str)) {
    results.push([match[1], match[2]]);
  }
  return results;
}

export async function handleSave(answerMessageContent, formApi, { messages, messageId }) {
  if (!answerMessageContent) return;
  if (!formApi) return;

  const prompt = findQuestionFromMessages(messageId, messages);

  const elements = await Promise.all(
    findCodeBlock(answerMessageContent).map(([type, content]) => {
      return {
        'icp-table': transformToTableElement,
        'icp-chart': transformToEChartElement,
      }[type]?.(content);
    }),
  ).then((list) =>
    list
      .filter(Boolean)
      .map(([element, title, formEntity]) => wrapElement(element, title, formEntity, prompt)),
  );

  if (elements.length === 0) return;

  formApi.changeSchema((prev) => {
    const prevCopy = JSON.parse(JSON.stringify(prev));
    const foundGrid = findFieldBy(prevCopy, (field) => field.id === GRID_ID)?.field;
    if (foundGrid) {
      foundGrid.fields = [...foundGrid.fields, ...elements];
      return prevCopy;
    }

    return {
      ...prev,
      fields: [makeGridElement([...prev.fields, ...elements])],
    };
  });
}

export function wrapElement(element, title, formEntity /* , prompt */) {
  const portletId = `portlet-${uuidv4()}`;

  return {
    id: portletId,
    component: 'Card',
    componentProps: {
      'data-portlet-id': portletId,
    },
    fields: [
      {
        component: 'Stack',
        componentProps: {
          flexDirection: 'column',
        },
        fields: [
          {
            component: 'Stack',
            componentProps: {
              flexDirection: 'row',
            },
            fields: [
              makeTitleElement(title),
              {
                component: 'Box',
                style: {
                  flex: 1,
                },
              },
              {
                component: 'Button',
                componentProps: {
                  icon: 'oct:arrow-both',
                  type: 'text',
                  action: {
                    type: 'formMethod',
                    methodName: 'toggleWidth',
                  },
                },
              },
              {
                component: 'Button',
                componentProps: {
                  icon: 'material:arrow-back',
                  type: 'text',
                  action: {
                    type: 'formMethod',
                    methodName: 'portletMoveBack',
                  },
                },
              },
              {
                component: 'Button',
                componentProps: {
                  icon: 'material:arrow-forward',
                  type: 'text',
                  action: {
                    type: 'formMethod',
                    methodName: 'portletMoveForward',
                  },
                },
              },
              {
                component: 'Button',
                componentProps: {
                  icon: 'material:clear-rounded',
                  type: 'text',
                  action: {
                    type: 'confirm',
                    content: 'Are you sure you want to delete this portlet?',
                    successAction: {
                      type: 'request',
                      method: 'put',
                      url: '/form/api/dashboard/personal',
                      transformDataRequest: `function findById(fields, targetId) { if (!(fields !== null && fields !== void 0 && fields.length)) return; var idx = fields.findIndex(function (f) { return f.id === targetId; }); if (idx >= 0) { fields.splice(idx, 1); return; } fields.forEach(function (f) { return findById(f.fields, targetId); });}(function transform() { findById(formSchema.fields, '${portletId}'); return { id: context.dashboardId, schemaJson: JSON.stringify(formSchema) };})(this);`,
                      successAction: {
                        type: 'refresh',
                      },
                    },
                  },
                },
              },
            ],
          },
          // {
          //   component: 'Text',
          //   componentProps: {
          //     content: prompt || '',
          //     style: {
          //       color: 'var(--helper-color, #979797)',
          //     },
          //   },
          // },
          element,
          {
            component: 'Button',
            style: {
              alignSelf: 'start',
            },
            componentProps: {
              content: `View Report (${formEntity.name})`,
              type: 'link',
              action: {
                type: 'dialog',
                openDialogProps: {
                  title: `View Report (${formEntity.name})`,
                  hideFooter: true,
                  centered: true,
                  width: '90vw',
                },
                openFormProps: {
                  FormRendererProps: {
                    schema: {
                      fields: [makeTableElement(formEntity)],
                    },
                  },
                },
              },
            },
          },
        ],
      },
    ],
  };
}
