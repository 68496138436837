/* eslint-disable import/no-dynamic-require */
import loadRequire from './loadRequire';

let promise;

export default function loadECharts() {
  if (promise) {
    return promise;
  }
  promise = loadRequire().then((require) => {
    return new Promise((resolve) => {
      require(['echarts'], resolve);
    });
  });
  return promise;
}
