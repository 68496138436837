import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);

/**
  将当前时区的时间以修改时间的方式切换到指定时区
  用于在antd/DatePicker(无法切换时区)中使用指定时区选择时间

  @param {string} date ISO 18601 格式日期时间原始值
  @param {string} timeZone e.g. `Asia/Tokyo`, `Europe/Berlin`
  @returns dayjs object
  @example
  input:
    date: 2022-02-02T02:02:02Z
    timeZone: Asia/Tokyo
  output:
    2022-02-02T01:02:02.000Z
*/
export function convertDateTimeToTimeZone(date, timeZone) {
  return dayjs(date).tz(timeZone, true);
}

/**
  将指定时区的时间以修改时间的方式切换到当前时区
  用于在antd/DatePicker(无法切换时区)中使用指定时区选择时间

  @param {string} date ISO 18601 格式日期时间原始值
  @param {string} timeZone e.g. `Asia/Tokyo`, `Europe/Berlin`
  @returns dayjs object
  @example
  input:
    date: 2022-02-02T02:02:02Z
    timeZone: Asia/Tokyo
  output:
    2022-02-02T03:02:02.000Z
 */
export function convertDateTimeFromTimeZone(date, timeZone) {
  return dayjs(
    dayjs(date)
      //
      .tz(timeZone)
      .utc(true)
      .toISOString()
      .replace(/z$/i, ''),
  );
}

const isThisXXX = (comparisonTemplate) => (date) =>
  dayjs(date).format(comparisonTemplate) === dayjs().format(comparisonTemplate);

export const isToday = isThisXXX('YYYY-MM-DD');
export const isThisWeek = isThisXXX('w');
export const isThisMonth = isThisXXX('M');
export const isThisQuarter = isThisXXX('Q');
export const isThisYear = isThisXXX('YYYY');
