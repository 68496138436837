import { useEffect, useState } from 'react';
import getFilterValueText from './getFilterValueText';
import { getFilterTypeIcon } from './utils';

export default function useFilterItemDisplayText(gridApi, model, column) {
  const icon = getFilterTypeIcon(model.filterType);
  const [valueText, setValueText] = useState('');

  useEffect(() => {
    Promise.resolve(getFilterValueText(gridApi, model, column)).then(setValueText);
  }, [gridApi, column, model]);

  if (!model || !column) {
    return null;
  }

  return (
    <>
      {icon}
      <span>{`${column.colDef.headerName}${valueText ? ': ' : ''}`}</span>
      {valueText}
    </>
  );
}
