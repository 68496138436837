import { get, set, toPath } from 'lodash-es';
import { getLanguage, getTFunc } from './i18nForBrowser';

/**
 * 使用当前语言从props翻译指定字段k
 * 优先使用译文字段, 格式`${k}_i18n_${lng}`, 比如: title的中文译文字段 title_i18n_zh-CN: "标题"
 * 否则使用i18n key字段，格式`${k}_i18n_key` 和可选的namespace `${k}_i18n_ns`, 比如: title_i18n_key: "myForm.myField.title": "标题"
 * 否则使用字段k的值
 *
 * 此方法不会触发加载 `${k}_i18n_ns` 语言包, 请确保使用的语言包已被加载完毕
 *
 * @param k
 * @param props
 * @param t
 * @param lng
 * @returns {*|undefined}
 */
export function translate(k, props, t = getTFunc(), lng = getLanguage()) {
  const untranslatedText = get(props, k);
  const translatedText = get(props, `${k}_i18n_${lng}`);
  const i18nKey = get(props, `${k}_i18n_key`);
  const i18nNamespace = get(props, `${k}_i18n_ns`);

  if (untranslatedText == null && translatedText == null && i18nKey == null) {
    return undefined;
  }

  return (
    translatedText ??
    (i18nKey
      ? t(i18nKey, { ns: i18nNamespace, defaultValue: untranslatedText }) ?? untranslatedText
      : //
        untranslatedText)
  );
}

function findKeyToTranslate(obj, kp, cb, skip, objMap = new WeakMap()) {
  if (!obj) return;
  // 识别循环引用退出递归
  if (objMap.has(obj)) return;
  objMap.set(obj, true);

  for (const [k, v] of Object.entries(obj)) {
    if (skip?.(k, v, obj, kp)) continue;
    if (k.startsWith('_')) {
      // ignore internal fields
    } else if (Array.isArray(v)) {
      v.filter((subO) => typeof subO === 'object').forEach((subO, i) => {
        findKeyToTranslate(subO, kp ? `${kp}.${k}[${i}]` : `${k}[${i}]`, cb, skip, objMap);
      });
    } else if (typeof v === 'object') {
      findKeyToTranslate(v, kp ? `${kp}.${k}` : k, cb, skip, objMap);
    } else if (k.includes('_i18n_')) {
      const keyToTranslate = k.replace(/_i18n_.*$/, '');
      cb(kp ? `${kp}.${keyToTranslate}` : keyToTranslate);
    }
  }
}

// This method mutates props
function deleteTranslationProps(props, keyPath) {
  const lastKey = keyPath[keyPath.length - 1];
  const keyPathPre = keyPath.slice(0, keyPath.length - 1);

  const obj = keyPathPre.length ? get(props, keyPathPre) : props;

  if (Object.isSealed(obj)) return;

  Object.keys(obj || {})
    .filter((k) => k.startsWith(`${lastKey}_i18n_`))
    .forEach((k) => {
      delete obj[k];
    });
}

const hasTranslatedSymbol = Symbol('hasTranslatedSymbol');

function markTranslated(obj) {
  if (Object.isExtensible(obj)) {
    obj[hasTranslatedSymbol] = true;
  }
}

const TRANSLATE_ENTIRE_OBJ_DEFAULT_OPTIONS = () => ({
  t: getTFunc(),
  lng: getLanguage(),
  skip: undefined,
  deleteI18nVariantProps: false,
});

/**
 * 此方法修改传入的obj
 * 深度遍历obj翻译所有标记_i18n_的字段
 * _i18n_配置字段将被删除
 */
export function translateEntireObj(obj, options) {
  const { t, lng, skip, deleteI18nVariantProps } = {
    ...TRANSLATE_ENTIRE_OBJ_DEFAULT_OPTIONS(),
    ...options,
  };

  if (Array.isArray(obj)) {
    for (const item of obj) {
      translateEntireObj(item, options);
    }
    markTranslated(obj);
    return;
  }

  if (!obj) return;
  if (typeof obj !== 'object') return;
  if (obj.skipTranslation) return;
  if (obj[hasTranslatedSymbol]) return;
  // console.time('translateEntireObj');
  const kpToTranslateSet = new Set();

  findKeyToTranslate(
    obj,
    null,
    (kp) => {
      kpToTranslateSet.add(kp);
    },
    skip,
  );

  for (const k of kpToTranslateSet) {
    const translated = translate(k, obj, t, lng);

    if (translated != null) {
      const kp = toPath(k);
      if (get(obj, kp) !== translated) {
        try {
          const parent = kp.length > 1 ? get(obj, kp.slice(0, kp.length - 1)) : obj;
          if (!Object.isFrozen(parent)) {
            set(obj, kp, translated);
          }
        } catch (e) {
          console.error(e);
        }
      }
      if (deleteI18nVariantProps) {
        deleteTranslationProps(obj, kp);
      }
    }
  }

  markTranslated(obj);
  // console.timeEnd('translateEntireObj');
}

export const CUSTOM_I18N_KEY_SEPARATOR = '|>';

// key中不能使用 .
export function escapeI18nKey(originalKey) {
  return originalKey.replace(/\./g, CUSTOM_I18N_KEY_SEPARATOR);
}

export function escapeI18nKeyForEntireObj(obj) {
  if (typeof obj !== 'object' || obj == null) return obj;
  if (Array.isArray(obj)) throw Error('Unexpected data type array');
  return Object.fromEntries(
    Object.entries(obj || {}).map(([k, v]) => [escapeI18nKey(k), escapeI18nKeyForEntireObj(v)]),
  );
}

// convert from { "a.b.c": "xxx" } to { "a": { "b": { "c": "xxx" } } }
export function convertI18nResourceFormat(obj) {
  const result = {};
  for (const [k, v] of Object.entries(obj)) {
    set(result, k.split('.'), v);
  }
  return result;
}

export function localeCompare(a, b) {
  if (!a || !b) return 0;
  if (a === b) return 0;
  try {
    return a.localeCompare(b, getLanguage());
  } catch {
    return a.localeCompare(b);
  }
}

export function findI18nValues(data, path) {
  if (typeof path !== 'string') {
    return {};
  }

  const arrayPath = toPath(path);
  const parentPath = arrayPath.slice(0, -1);
  const propertyKey = arrayPath[arrayPath.length - 1];
  const parentObj = parentPath.length ? get(data, parentPath) : data;

  const i18nValues = {};

  // 如果未配置相关的i18n资源 则直接返回
  if (!parentObj || typeof parentObj !== 'object') return i18nValues;

  for (const [k, v] of Object.entries(parentObj)) {
    if (k.startsWith(`${propertyKey}_i18n_`)) {
      const suffix = k.split('_i18n_')[1];
      i18nValues[`_i18n_${suffix}`] = v;
    }
  }

  return i18nValues;
}
