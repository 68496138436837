import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isMobile } from '@icp/utils';
import { AUTH_SUCCESS_REDIRECT_URL_STORAGE_KEY, ssoLogin } from 'store/features/authSlice';
import ErrorPage from './ErrorPage';

export default function SSOCallback() {
  const navigate = useNavigate();
  const { loginSourceToken } = useParams();
  const dispatch = useDispatch();

  const [err, setErr] = useState(null);

  useEffect(() => {
    const payload = Object.fromEntries(new URLSearchParams(window.location.search));
    dispatch(ssoLogin({ loginSourceToken, payload }))
      .unwrap()
      .then(() => {
        const from = sessionStorage.getItem(AUTH_SUCCESS_REDIRECT_URL_STORAGE_KEY);
        sessionStorage.removeItem(AUTH_SUCCESS_REDIRECT_URL_STORAGE_KEY);

        if (from) {
          window.location.replace(from);
        } else if (isMobile()) {
          navigate('/mobile', { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      })
      .catch(setErr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (err) return <ErrorPage status="error" subTitle={err.message} />;

  return null;
}
