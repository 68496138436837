import { useRef } from 'react';
import { get, isEqual, toPath } from 'lodash-es';
import {
  resolveConditionalListProperty,
  resolveConditionalProperty,
} from '@icp/form-renderer-core';
import { useParams } from 'react-router-dom';
import { immutableSet } from '@icp/utils';
import { useStore } from '../store';
import useDependencies from './useDependencies';
import { useCurrentData } from '../currentDataCtx';

export function useConditionalProperty(value) {
  const store = useStore();
  const params = useParams();
  const currentData = useCurrentData();

  const [resolvedValue, depIds] = resolveConditionalProperty({
    value,
    currentData,
    store,
    params,
  });

  useDependencies(depIds);

  return resolvedValue;
}

// TODO，有性能问题，每次刷新都会全部泡一遍对 array 里所有数据调用 immutableSet
export function useConditionalPropertyForItemOfArray(array, ...valueKeyPath) {
  const store = useStore();
  const params = useParams();
  const currentData = useCurrentData();

  const memorized = useRef(null);

  const { arr: resolvedArray, dep: rawDepIds } = (Array.isArray(array) ? array : [])
    .map((item) => {
      let resolvedItem = item;
      const depIdList = [];
      for (const kp of valueKeyPath) {
        const [resolvedValue, depIds] = resolveConditionalProperty({
          value: get(item, kp),
          currentData,
          store,
          params,
        });
        resolvedItem = immutableSet(resolvedItem, toPath(kp), resolvedValue);
        depIdList.push(...depIds);
      }
      return [resolvedItem, depIdList];
    })
    .reduce(
      (accu, [resolvedValue, depIds]) => {
        accu.arr.push(resolvedValue);
        accu.dep.push(...depIds);
        return accu;
      },
      { arr: [], dep: [] },
    );

  useDependencies([...new Set(rawDepIds)]);

  if (!isEqual(memorized.current, resolvedArray)) {
    memorized.current = resolvedArray;
  }

  return memorized.current;
}

export function useConditionalListProperty(value) {
  const store = useStore();
  const params = useParams();
  const currentData = useCurrentData();

  const memorized = useRef(null);

  const [resolvedValue, depIds] = resolveConditionalListProperty({
    value,
    currentData,
    store,
    params,
  });

  useDependencies(depIds);

  if (!isEqual(memorized.current, resolvedValue)) {
    memorized.current = resolvedValue;
  }

  return memorized.current;
}
