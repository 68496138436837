import { useEffect } from 'react';
import { Loading } from '@icp/components';
import { getSearchParams } from '@icp/utils';
import restApi from 'services/restApi';
import AuthWrapper from './AuthWrapper';

function Authorize() {
  useEffect(() => {
    const searchParams = getSearchParams();
    restApi.post(`/user-management/api/op/redirect_uri`, searchParams).then((redirectUri) => {
      window.location.href = redirectUri;
    });
  }, []);

  return <Loading />;
}

export default function OpAuthorize() {
  return (
    <AuthWrapper>
      <Authorize />
    </AuthWrapper>
  );
}
