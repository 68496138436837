import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Button } from 'antd';
import { HELPER_TEXT_TYPES, I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT } from '@icp/form-renderer-core';
import { Icon } from '@icp/components';
import FieldTitle from '../../../FieldTitle';
import TextareaReadonlyText from './TextareaReadonlyText';
import TextareaSupportI18n from './TextareaSupportI18n';
import FormHelperText from '../../../FormHelperText';
import { useIsInDesign } from '../../../FormRenderCtx';

const TextareaAnt = forwardRef(function TextareaAnt(props, ref) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    allowI18nValue,
    componentProps,
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,

    simpleMode,
    i18nInputLayout,
    onToggleI18nInputLayout,
  } = props;

  const isInDesign = useIsInDesign();

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          <TextareaReadonlyText {...props} />
        ) : (
          <>
            <TextareaSupportI18n
              {...componentProps}
              simpleMode={simpleMode}
              allowI18nValue={allowI18nValue}
              i18nInputLayout={i18nInputLayout}
              id={id}
              name={id}
              value={value}
              disabled={disabled}
              status={status}
              maxLength={validation?.maxLength}
              onChange={onChange}
              onBlur={onTouchChanged}
              ref={ref}
            />
            <FormHelperText helpers={helpers} />
          </>
        )}
        {!isInDesign && !readonly && allowI18nValue ? (
          <Button
            className="i18n-input-layout-switch-button"
            size="small"
            type="text"
            icon={
              i18nInputLayout === I18N_VALUE_COLLAPSED ? (
                <Icon name="oct:chevron-right" size={16} />
              ) : (
                <Icon name="oct:chevron-down" size={16} />
              )
            }
            onClick={onToggleI18nInputLayout}
          />
        ) : null}
      </div>
    </>
  );
});

TextareaAnt.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  allowI18nValue: PropTypes.bool,
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  simpleMode: PropTypes.bool,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  onToggleI18nInputLayout: PropTypes.func,
};

export default TextareaAnt;
