import { get } from 'lodash-es';
import {
  HELPER_TEXT_ERROR,
  HELPER_TEXT_NORMAL,
  HELPER_TEXT_WARNING,
} from '@icp/form-renderer-core';
import { useSelector } from '../store';

export default function useStatus({ id, helperProp }) {
  const error = useSelector((state) => get(state.formData?.errors, id));
  const warning = useSelector((state) => get(state.formData?.warnings, id));

  const helpers = [];
  let status = null;

  if (helperProp) {
    helpers.push({ status: HELPER_TEXT_NORMAL, text: helperProp });
  }

  if (warning) {
    helpers.push({ status: HELPER_TEXT_WARNING, text: warning });
    status = HELPER_TEXT_WARNING;
  }

  if (error) {
    helpers.push({ status: HELPER_TEXT_ERROR, text: error });
    status = HELPER_TEXT_ERROR;
  }

  return { status, helpers: helpers.length ? helpers : null };
}
