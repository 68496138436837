import { getLanguage, getTFunc } from '@icp/i18n';
import { isField, isLayout, isWrapper } from '@icp/form-schema';
import { compressSchema, deCompressSchema, parseJSON } from '@icp/utils';
import { get } from 'lodash-es';
import { ACTION_COLUMN } from '@icp/form-renderer';
import { restApi } from '@icp/settings';
import {
  FIELD_TYPE_COLLAPSE_ITEM,
  FIELD_TYPE_FORM,
  FIELD_TYPE_STEP,
  FIELD_TYPE_TAB,
  FIELD_TYPE_TABLE_COLUMN,
} from '../constant';
import getMouseFieldState from './getMouseFieldState';
import { dataSourceToColumnDefs } from '../DesignerSettings/fieldSettings/TableSettings/utils';
import { composeGeneralHref } from '../DesignerSettings/fieldSettings/common/LinkSetting/utils';
import initFrontEndConfig from './initFrontEndConfig';

export function validateId(id) {
  const t = getTFunc('icp-form-designer');

  if (!id) {
    return t('error.required', { name: 'ID' });
  }

  if (/^\d+$/.test(id)) {
    return `${t('error.cannot-not-be-number', { name: 'ID' })}: ${id}`;
  }

  return null;
}

export function filterToolWindow(typeName, name) {
  if (
    typeName !== 'form' &&
    ['hidden-fields', 'data-fields', 'data-dict', 'ai-agent', 'explanation'].includes(name)
  ) {
    return null;
  }

  return name;
}

export function getComponentNameToDisplay(fieldInfo) {
  const { component, componentProps = {}, style } = fieldInfo;
  if (component === 'Stack') {
    if (
      componentProps.flexDirection === 'column' ||
      componentProps.style?.flexDirection === 'column' ||
      style?.flexDirection === 'column'
    ) {
      return 'VStack';
    }
    return 'HStack';
  }

  if (component === 'EChart') {
    const { series } = componentProps;

    if (!series) {
      return component;
    }

    if (series.type === 'pie' && Array.isArray(series.radius) && series.radius.length === 2) {
      return 'DoughnutChart';
    }

    if (series.type === 'pie') {
      return 'PieChart';
    }

    if (series.type === 'line' && series.areaStyle) {
      return 'AreaChart';
    }

    if (series.type === 'line') {
      return 'LineChart';
    }

    if (series.type === 'bar') {
      return 'BarChart';
    }
  }
  return component;
}

export function getFieldDisplayShortName({ type, fieldInfo, typeName }) {
  const t = getTFunc(['icp-form-designer', 'icp-common']);

  const name = getComponentNameToDisplay(fieldInfo);

  if (type === FIELD_TYPE_FORM) {
    if (typeName === 'form') {
      return t('form', { ns: 'icp-common' });
    }
    if (typeName === 'page') {
      return t('page', { ns: 'icp-common' });
    }
    return '';
  }

  if (type === FIELD_TYPE_TABLE_COLUMN) {
    return t('selection.table-column');
  }

  if (type === FIELD_TYPE_STEP) {
    return `${t('steps.step.step')}`;
  }

  if (type === FIELD_TYPE_COLLAPSE_ITEM) {
    return `${t('collapse.panel.panel')}`;
  }

  if (type === FIELD_TYPE_TAB) {
    return `${t('tabs.tab.tab')}`;
  }

  if (!name) return null;

  return t(`component.${name}`);
}

export function getFieldDisplayLongName({ type, fieldInfo, typeName }) {
  const { id, title, component, componentProps } = fieldInfo;

  const t = getTFunc(['icp-form-designer', 'icp-common']);

  if (type === FIELD_TYPE_FORM) {
    if (typeName === 'form') {
      return t('form', { ns: 'icp-common' });
    }
    if (typeName === 'page') {
      return t('page', { ns: 'icp-common' });
    }
    return '';
  }

  const name = getComponentNameToDisplay(fieldInfo);

  let displayText;
  if (component === 'Grid') {
    displayText = `${t(`component.${name}`)} (${componentProps?.colNumber ?? 12})`;
  } else if (component === 'Link') {
    displayText = componentProps?.href || t(`component.${name}`);
  } else {
    displayText =
      componentProps?.content || // Button, etc
      title || // input fields
      fieldInfo.label || // item of Collapse / Steps / Tabs, etc
      t(`component.${name}`);
  }

  if (id) {
    displayText += ` (${id})`;
  }

  return displayText;
}

export function canAppend(fieldInfo, event) {
  return (
    isLayout(fieldInfo) ||
    isWrapper(fieldInfo) ||
    fieldInfo?.component === 'List' ||
    event.target.classList.contains('fd-canvas') ||
    event.target.classList.contains('form-renderer') ||
    event.target.classList.contains('ant-collapse-item') ||
    event.target.classList.contains('ant-tabs-tab') ||
    event.target.classList.contains('table-element-toolbar-fields-empty') ||
    event.target.classList.contains('page-header-actions-empty')
  );
}

export function selectionIsItem(type) {
  return [FIELD_TYPE_STEP, FIELD_TYPE_COLLAPSE_ITEM, FIELD_TYPE_TAB].includes(type);
}

export function getAppendState(event, keyPath) {
  const mouseFieldState = getMouseFieldState(event, keyPath);

  const { type } = mouseFieldState;

  if (type === FIELD_TYPE_FORM || type === FIELD_TYPE_COLLAPSE_ITEM || type === FIELD_TYPE_TAB) {
    return mouseFieldState;
  }

  return { keyPath };
}

export function fieldToClipboard(fieldInfo) {
  const copy = JSON.parse(JSON.stringify(fieldInfo));
  navigator.clipboard.writeText(JSON.stringify(compressSchema(copy)));
}

export function fieldFromClipboard() {
  return navigator.clipboard.readText().then((text) => {
    const fieldInfo = deCompressSchema(parseJSON(text, { silent: true }));

    if (isField(fieldInfo) || fieldInfo?.form) {
      return fieldInfo;
    }

    return Promise.reject(new Error('Paste content not valid'));
  });
}

export function ensureTableColumnVisible(container, keyPath, colId) {
  if (!container) {
    return;
  }
  const fieldEl = container.parentNode.querySelector(`[data-key-path="${keyPath}"]`);
  if (!fieldEl) {
    return;
  }

  const scrollEl = fieldEl.querySelector('.ag-body-horizontal-scroll-viewport');
  const cellEl = fieldEl.querySelector(`.ag-header-cell[col-id="${colId}"]`);
  if (!scrollEl || !cellEl) {
    return;
  }

  if (
    cellEl.parentNode.parentNode.classList.contains('ag-pinned-left-header') ||
    cellEl.parentNode.parentNode.classList.contains('ag-pinned-right-header')
  ) {
    return;
  }

  const cellBox = cellEl.getBoundingClientRect();
  const scrollBox = scrollEl.getBoundingClientRect();

  const leftDistance = cellBox.left - scrollBox.left;
  const rightDistance = cellBox.right - scrollBox.right;
  if (leftDistance < 0) {
    scrollEl.scrollLeft += leftDistance;
  } else if (rightDistance > 0) {
    scrollEl.scrollLeft += Math.min(rightDistance, leftDistance);
  }
}

export function ensureItemChildVisible(container, keyPath, schema) {
  if (!container || !keyPath) {
    return;
  }

  const index = keyPath.indexOf('componentProps');

  if (index === -1 || keyPath[index + 1] !== 'items' || typeof keyPath[index + 2] !== 'number') {
    return;
  }

  const parentKeyPath = keyPath.slice(0, index);
  const itemIndex = keyPath[index + 2];

  const parentConfig = get(schema, parentKeyPath);
  const itemConfig = get(schema, parentKeyPath.concat('componentProps', 'items', itemIndex));
  // console.log(parentConfig, itemConfig);

  // Tabs
  if (parentConfig.component === 'Tabs') {
    const tabEl = container
      .querySelector(`[data-key-path="${parentKeyPath}"]`)
      ?.querySelector(`[data-node-key="${itemConfig.key}"]`);

    if (tabEl && !tabEl.classList.contains('ant-collapse-item-active')) {
      tabEl.querySelector('.ant-tabs-tab-btn')?.click();
    }
  }

  // Collapse
  if (parentConfig.component === 'Collapse') {
    const collapseEl = container
      .querySelector(`[data-key-path="${parentKeyPath}"]`)
      ?.querySelectorAll('.ant-collapse-item')[itemIndex];

    if (collapseEl && !collapseEl.classList.contains('ant-collapse-item-active')) {
      collapseEl.querySelector('.ant-collapse-header').click();
    }
  }
}

export function needAutoPageMenu(originalFormEntity) {
  return (
    !originalFormEntity.fields.length &&
    originalFormEntity.layouts.length <= 1 &&
    (!originalFormEntity.layouts[0]?.schema ||
      (originalFormEntity.layouts[0].schema?.fields || []).length === 0) // material 会自动生成 schema.form.title 属性
  );
}

export function getAutoPageMenu(formEntity, pbcToken) {
  const t = getTFunc('icp-form-designer');

  const schemaId = `${formEntity.token}-list`;
  const pageTitle = t('auto-page-menu.page-title', { name: formEntity.name });
  const pageSchema = {
    form: {
      title: pageTitle,
    },
    fields: [
      {
        component: 'Card',
        fields: [
          {
            component: 'Table',
            id: `${formEntity.token}-table`,
            componentProps: {
              dataSource: {
                token: formEntity.token,
              },
              columnDefs: dataSourceToColumnDefs({
                fields: formEntity.fields,
                dataSourceFormEntity: formEntity,
              }).concat({
                type: ACTION_COLUMN,
                width: 240,
                cellRendererParams: {
                  displayLikeButton: true,
                  showIcon: true,
                  actions: [
                    {
                      type: 'EDIT',
                      href: composeGeneralHref('form-edit', {
                        formEntityToken: formEntity.token,
                        layoutToken: formEntity.layouts[0].token,
                        formEntityDataId: ':id',
                      }),
                    },
                    {
                      type: 'VIEW',
                      href: composeGeneralHref('form-view', { formEntityDataId: ':id' }),
                    },
                    {
                      type: 'DELETE',
                    },
                  ],
                },
              }),
              addButtonHref: composeGeneralHref('form-create', {
                formEntityToken: formEntity.token,
                layoutToken: formEntity.layouts[0].token,
              }),
              pinnedFilter: formEntity.fields.slice(0, 3).map((item) => item.token),
              pagination: true,
            },
          },
        ],
      },
    ],
  };
  const menuItem = {
    to: `/${pbcToken}/page/${schemaId}`,
    label: pageTitle,
    icon: 'oct:home',
  };

  return { schemaId, pageSchema, menuItem };
}

// 这个函数直接从 platform copy 过来的，为了不在 form designer 里去 import platform 的内容
export async function modifyFrontendConfig(projectId, projectName, modifier) {
  const frontEnd = await restApi.get(`/form/api/front-end-config/get-by-project-id/${projectId}`);
  const frontEndConfig =
    parseJSON(frontEnd.config, { silent: true }) || initFrontEndConfig(getLanguage(), projectName);

  modifier(frontEndConfig);

  return restApi.put(`/form/api/front-end-config/${frontEnd.id}`, {
    ...frontEnd,
    config: JSON.stringify(frontEndConfig),
  });
}
