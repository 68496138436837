import NodeQRCode from 'qrcode';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

const QRCode = forwardRef(function QRCode(props, ref) {
  const { text, title, description, width = 90, height = 90, QRProps = {}, ...other } = props;
  const canvasRef = useRef(null);

  useImperativeHandle(ref, () => canvasRef.current);

  useEffect(() => {
    // If no text, qrcode will throw error
    if (!text) {
      return;
    }
    NodeQRCode.toDataURL(text, { width: 90, margin: 2, ...QRProps }).then((data) => {
      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');
      let dy = 0;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, width, height);
      // title
      if (title) {
        ctx.font = '400 24px serif';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000';
        ctx.fillText(title, width / 2, 60, width);
        dy += 94; // 94 = 60 + 24 + 10 margin bottom
      }
      // description
      if (description) {
        ctx.font = '14px serif';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0,0,0,0.45)';
        ctx.fillText(description, width / 2, dy, width);
        dy += 24; // 24 = 14 + 10 margin bottom
      }
      // 二维码
      const img = new Image();
      img.src = data;
      img.onload = () => {
        ctx.drawImage(img, 0, dy, width, height - dy);
      };
    });
  }, [text, width, height, title, description, QRProps]);

  return <canvas ref={canvasRef} width={width} height={height} {...other} />;
});

QRCode.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  QRProps: PropTypes.shape({
    margin: PropTypes.number,
    width: PropTypes.number,
  }),
};

export default QRCode;
