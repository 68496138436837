import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  dataSourceToDataUrl,
  selectContext,
  resolveUrl,
  selectValues,
} from '@icp/form-renderer-core';
import { Loading } from '@icp/components';
import { getResourceBundle } from '@icp/i18n';
import { EvalWorker, loadDHTMLXEventCalendar, randomNumber } from '@icp/utils';
import { restApi, shouldTranslateByDefault } from '@icp/settings';
import { useClassName, useDataSource, useDisplayValue } from '../../hooks';
import { useElementDecorator, useFormApi, useIsInDesign } from '../../FormRenderCtx';
import { withFieldWrapper } from '../../fieldWrapper';
import { useStore } from '../../store';

const EventCalendarElement = forwardRef(function EventCalendarElement(
  {
    keyPath,
    id,
    className: classNameProp,
    style: styleSetByParent,
    valueField,
    componentProps = {},
  },
  ref,
) {
  const {
    style,
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    defaultValues,
    calendarConfig = {},
    addEventAction,
    updateEventAction,
    deleteEventAction,
    readonly,
  } = componentProps;

  const params = useParams();
  const formApi = useFormApi();

  const store = useStore();

  const { i18n } = useTranslation('icp-vendor-dhtmlx-event-calendar');
  const lng = i18n.language;
  const localeObj = useMemo(() => getResourceBundle('icp-vendor-dhtmlx-event-calendar'), []);

  const ElementDecorator = useElementDecorator();
  const context = selectContext(store.getState());
  const isInDesign = useIsInDesign();
  const dataUrl = dataUrlProp || dataSourceToDataUrl(dataSource, context, true, isInDesign);

  const nodeRef = useRef(null);
  const eleRef = useRef(null);
  const calendarRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      get calendar() {
        return calendarRef.current;
      },
    }),
    [],
  );

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-calendar-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueResolved = useDisplayValue(id, valueField);

  const { loading, error, dataFetched } = useDataSource({
    skip: valueResolved,
    defaultValues, // deprecated
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod: httpMethod || (!dataUrlProp && dataSource ? 'post' : undefined),
  });
  const data = dataFetched || valueResolved;
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  useEffect(() => {
    const { date: dateString, mode = 'month' } = calendarConfig;

    const executeAction = (currentAction, item) => {
      if (!currentAction) return undefined;

      const {
        type,
        method = 'post',
        url: urlPattern,
        transformDataRequest,
        successAction,
      } = currentAction;

      if (type === 'refresh') {
        return formApi.refresh();
      }

      const url = resolveUrl({ url: urlPattern, store, currentData: item, params })[0];

      return (
        Promise.resolve(item)
          // remove private properties
          .then((requestPayload) => {
            return Object.fromEntries(
              Object.entries(requestPayload).filter(([k]) => !k.startsWith('$')),
            );
          })
          // transform data
          .then((requestPayload) => {
            if (transformDataRequest) {
              return EvalWorker.shared().execEval(requestPayload, transformDataRequest, {
                params: {
                  context: store.getState().context,
                  formData: selectValues(store.getState()),
                },
              });
            }
            return requestPayload;
          })
          .then((requestPayload) => {
            if (method === 'get') {
              return restApi.get(url);
            }
            return restApi[method](url, requestPayload);
          })
          .then(() => {
            return executeAction(successAction, item);
          })
      );
    };

    let calendar;
    loadDHTMLXEventCalendar().then((eventCalendar) => {
      const calendarReadonly = readonly || calendarConfig?.config?.readonly;

      // eslint-disable-next-line no-new
      calendar = new eventCalendar.EventCalendar(eleRef.current, {
        ...calendarConfig,
        config: { ...(calendarConfig.config || {}), readonly: calendarReadonly },
        mode,
        date: dateString ? new Date(dateString) : new Date(),
      });

      if (localeObj) {
        calendar.setLocale(localeObj);
      }

      calendar.api.on('add-event', (obj) => {
        return executeAction(addEventAction, obj);
      });
      calendar.api.on('delete-event', (obj) => {
        return executeAction(deleteEventAction, obj);
      });
      calendar.api.on('update-event', (obj) => {
        return executeAction(updateEventAction, obj);
      });

      if (data) {
        calendar.parse({ events: data });
      }

      formApi.asyncComponentManager.setReady(idToRegister);
    });

    return () => {
      calendar?.destructor();
    };
  }, [
    lng,
    localeObj,
    calendarConfig,
    addEventAction,
    updateEventAction,
    deleteEventAction,
    params,
    store,
    formApi,
    data,
    readonly,
    idToRegister,
  ]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('event-calendar-element form-element', className)}
        style={styleSetByParent}
        ref={nodeRef}
      >
        {loading && <Loading />}
        {error && <div>{error.message ?? `${error.error}`}</div>}
        <div
          ref={eleRef}
          className={classNameComp}
          style={{
            ...style,
            ...(loading || error ? { visibility: 'hidden' } : null),
          }}
        />
      </div>
    </ElementDecorator>
  );
});

EventCalendarElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    calendarConfig: PropTypes.shape({
      calendars: PropTypes.arrayOf(PropTypes.shape({})),
      colors: PropTypes.arrayOf(PropTypes.string),
      config: PropTypes.shape({}),
      date: PropTypes.string,
      editorShape: PropTypes.arrayOf(PropTypes.shape({})),
      events: PropTypes.arrayOf(PropTypes.shape({})),
      mode: PropTypes.string,
      sidebar: PropTypes.bool,
      templates: PropTypes.shape({}),
      theme: PropTypes.oneOf(['material', 'willow', 'willowDark']),
    }),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
EventCalendarElement.displayName = 'EventCalendar';

export default withFieldWrapper(EventCalendarElement, { ns: 'icp-vendor-dhtmlx-event-calendar' });
