import { useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { selectContext, selectValues } from '@icp/form-renderer-core';
import { extractDataDependencyIds, resolveVariablePattern } from '@icp/utils';
import { useStore } from '../store';
import useDependencies from './useDependencies';
import { useCurrentData } from '../currentDataCtx';

export default function useVariablePatternArray(patternArray, failWhenNoVariableValue) {
  const store = useStore();
  const formData = selectValues(store.getState());
  const currentData = useCurrentData();
  const context = selectContext(store.getState());
  const params = useParams();

  const memorized = useRef([]);

  const ids = useMemo(() => {
    return (Array.isArray(patternArray) ? patternArray : [])
      .map((pattern) => extractDataDependencyIds(pattern, currentData))
      .reduce((prev, current) => prev.concat(current), []);
  }, [patternArray, currentData]);

  useDependencies(ids);

  if (patternArray && !Array.isArray(patternArray)) {
    console.error(new Error(`Error: Need an array but received: ${patternArray}`));
    return null;
  }

  const resolved = (patternArray || [])
    .map((pattern) => {
      return resolveVariablePattern({
        currentData: currentData || formData,
        formData,
        context,
        params,
        pattern,
        failWhenNoVariableValue,
      });
    })
    .filter(Boolean);

  if (!isEqual(memorized.current, resolved)) {
    memorized.current = resolved;
  }

  return memorized.current;
}
