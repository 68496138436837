import { forwardRef, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Popover from '../Popover';
import MenuItem from './MenuItem';

const Menu = forwardRef(function Menu(props, ref) {
  const { items, onClick, onClose, ...other } = props;

  const [subAnchorEl, setSubAnchorElPlain] = useState(null);

  const subMenuLocked = useRef(null);
  const listRef = useRef(null);
  const openSubTimer = useRef(null);

  const hasSelected = useMemo(() => {
    return items.some((item) => item.selected);
  }, [items]);

  const handleClick = ({ key }) => {
    if (onClick) {
      onClick({ key });
    }

    if (onClose) {
      onClose();
    }
  };

  const setSubAnchorEl = (anchorEl) => {
    if (subMenuLocked.current) {
      return;
    }

    clearTimeout(openSubTimer.current);
    if (anchorEl) {
      // open 则延迟一点，优化鼠标快速划过多个 submenu
      openSubTimer.current = setTimeout(() => {
        setSubAnchorElPlain(anchorEl);
      }, 100);
    } else {
      // close submenu 则立即 close
      setSubAnchorElPlain(anchorEl);
    }
  };

  const toggleLock = (isLocked) => {
    if (subMenuLocked.current === isLocked) {
      return;
    }

    subMenuLocked.current = isLocked;

    // Popover 已经 closed 过后调用 toggleLock
    if (!listRef.current) {
      return;
    }

    // 这里手动用 dom 同步的修改样式，因为 react 的 setState 是异步的，class 还没加上去就会先处罚 menu item 的
    // css hover，背景效果会先出现再消失
    if (isLocked) {
      listRef.current.classList.add('submenu-locked');
    } else {
      listRef.current.classList.remove('submenu-locked');
    }
  };

  const handleClose = () => {
    toggleLock(false);
    onClose();
  };

  return (
    <Popover className="icp-menu" onClose={handleClose} offset={0} {...other} ref={ref}>
      <div className={clsx('icp-menu-list', { 'has-selected': hasSelected })} ref={listRef}>
        {(items || []).map((item, index) => {
          return (
            <MenuItem
              key={item.key || index}
              itemKey={item.key}
              {...item}
              subAnchorEl={subAnchorEl}
              onClick={handleClick}
              onOpenSubMenu={setSubAnchorEl}
              onCloseSubMenu={() => setSubAnchorEl(null)}
              onLockSubMenu={() => toggleLock(true)}
              onReleaseSubMenu={() => toggleLock(false)}
            />
          );
        })}
      </div>
    </Popover>
  );
});

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default Menu;
