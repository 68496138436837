import PropTypes from 'prop-types';
import { getOctHeight, isEmoji } from '@icp/components-core';

function getIconUrl(name, size, themeKey) {
  if (name.startsWith('oct')) {
    const iconName = name.slice('oct:'.length);
    // octicons 提供了针对有些 icon 提供了不同尺寸版本
    const fileName = `${iconName}-${getOctHeight(iconName, size)}`;
    return `${window.ICP_PUBLIC_PATH}icons/oct/_large.svg#${fileName}`;
  }

  if (name.includes(':')) {
    const [library, iconName] = name.split(':');
    return `${window.ICP_PUBLIC_PATH}icons/${library}/_large_${themeKey}.svg#${iconName}`;
  }

  // default load icp icon
  return `${window.ICP_PUBLIC_PATH}icons/icp/_large.svg#${name}`;
}

// 此组件类似 icp-components-react/src/Icon
function IconRenderer(props) {
  const { name, themeKey } = props;

  const size = 24;

  // emoji
  if (isEmoji(name)) {
    return (
      <span className="icp-emoji" style={{ fontSize: size }}>
        {name}
      </span>
    );
  }

  const iconUrl = getIconUrl(name, size, themeKey);

  return (
    <svg
      className="icp-icon"
      fill="currentColor"
      width={size}
      height={size}
      focusable={false}
      aria-hidden={true}
    >
      <use href={iconUrl} />
    </svg>
  );
}

IconRenderer.propTypes = {
  name: PropTypes.string,
  themeKey: PropTypes.string,
};

export default IconRenderer;
