import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import Img from '../../assets/img/img.png';
import Pdf from '../../assets/img/pdf.png';
import Txt from '../../assets/img/txt.png';
import { FileStatus } from '../utils';
import Icon from '../../Icon';

function FileList(props) {
  const { files = [], onRemoveFile, readOnly = false } = props;
  const filesSnapshot = useRef();
  const listRef = useRef();

  useEffect(() => {
    const lastFile = files.slice(-1)[0];
    const lastFileSnapshot = filesSnapshot.current?.slice(-1)[0];
    if (lastFile && (!lastFileSnapshot || lastFile.uid !== lastFileSnapshot.uid)) {
      const lastFileNode = listRef.current?.lastChild;
      lastFileNode?.scrollIntoView({ behavior: 'smooth' });
    }
    filesSnapshot.current = files;
  }, [files]);

  const fileNodes = files.map((file) => {
    const { uid, name, status, type } = file;
    return (
      <div key={uid} className="ai-agent-file">
        <div className="ai-agent-file-info">
          <FileIcon status={status} type={type} />
          <div className="ai-agent-file-name">{name}</div>
        </div>
        {!readOnly && (
          <Button className="ai-agent-file-remove" type="link" onClick={() => onRemoveFile(uid)}>
            <Icon name="oct:x" size={12} />
          </Button>
        )}
      </div>
    );
  });

  return (
    fileNodes.length > 0 && (
      <div className="ai-agent-file-list" ref={listRef}>
        {fileNodes}
      </div>
    )
  );
}

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
  onRemoveFile: PropTypes.func,
  readOnly: PropTypes.bool,
};

const getIcon = (type) => {
  if (type === 'application/pdf' || type === 'application/x-pdf') {
    return Pdf;
  }
  if (type.startsWith('image/')) {
    return Img;
  }
  return Txt;
};

function FileIcon(props) {
  const { type, status } = props;
  return (
    <div className="ai-agent-file-icon">
      {status !== FileStatus.Done && <Spin size="small" />}
      {status === FileStatus.Done && <img src={getIcon(type)} alt={type} />}
    </div>
  );
}

FileIcon.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
};

export default FileList;
