/**
 * react render 直接用 object 给 react 会让页面奔溃, 而平台里字段类型会频繁修改会有导致脏数据，
 * 这个方法主要是保证脏数据不会导致页面奔溃。
 * @param value
 * @return {*|string|null}
 */
export default function ensureDisplayText(value) {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value !== 'object') {
    return value;
  }

  return String(value);
}
