import React from 'react';
import PropTypes from 'prop-types';
import { getTFunc } from '@icp/i18n';
import { withResourceBundle } from '../fieldWrapper';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { children, showActualError = true } = this.props;
    const { hasError, error } = this.state;
    const t = getTFunc('icp-form-renderer');

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div style={{ color: 'red' }}>
            {showActualError && error?.message ? error?.message : t('error.unknown')}
          </div>
          <button onClick={() => this.setState({ hasError: false, error: null })}>Retry</button>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  showActualError: PropTypes.bool,
  children: PropTypes.node,
};

export default withResourceBundle('icp-form-renderer')(ErrorBoundary);
