import { restApi } from '@icp/settings';
import { findFieldBy } from '@icp/form-schema';
import { get } from 'lodash-es';

export function fetchPersonalDashboard(signal) {
  return restApi.get('/form/api/dashboard/personal', { signal });
}

export function updatePersonalDashboard(data) {
  return restApi.put('/form/api/dashboard/personal', data);
}

function findPortletId(element) {
  while (element.parentElement) {
    element = element.parentElement;
    const portletId = element.getAttribute('data-portlet-id');
    if (portletId) {
      return portletId;
    }
  }
  return null;
}

export function registerFormMethods(formApi, dashboardId) {
  formApi.registerMethod('portletMoveForward', (params) => {
    const portletId = findPortletId(params.buttonApi.node);
    const schema = JSON.parse(JSON.stringify(formApi.getSchema()));
    const { field, keyPath } = findFieldBy(schema, (f) => f.id === portletId);
    const parent = get(schema, keyPath.slice(0, -1));
    const currentIdx = parent.findIndex((f) => f === field);
    if (currentIdx < parent.length - 1) {
      const next = parent[currentIdx + 1];
      parent[currentIdx] = next;
      parent[currentIdx + 1] = field;
      formApi.changeSchema((/* prev */) => schema);
      updatePersonalDashboard({
        id: dashboardId,
        schemaJson: JSON.stringify(schema),
      });
    }
  });

  formApi.registerMethod('portletMoveBack', (params) => {
    const portletId = findPortletId(params.buttonApi.node);
    const schema = JSON.parse(JSON.stringify(formApi.getSchema()));
    const { field, keyPath } = findFieldBy(schema, (f) => f.id === portletId);
    const parent = get(schema, keyPath.slice(0, -1));
    const currentIdx = parent.findIndex((f) => f === field);
    if (currentIdx > 0) {
      const prev = parent[currentIdx - 1];
      parent[currentIdx] = prev;
      parent[currentIdx - 1] = field;
      formApi.changeSchema((/* prev */) => schema);
      updatePersonalDashboard({
        id: dashboardId,
        schemaJson: JSON.stringify(schema),
      });
    }
  });

  formApi.registerMethod('toggleWidth', (params) => {
    const portletId = findPortletId(params.buttonApi.node);
    const schema = JSON.parse(JSON.stringify(formApi.getSchema()));
    const { field } = findFieldBy(schema, (f) => f.id === portletId);
    field.span = ((field.span ?? 1) % 3) + 1;
    formApi.changeSchema((/* prev */) => schema);
    updatePersonalDashboard({
      id: dashboardId,
      schemaJson: JSON.stringify(schema),
    });
  });
}
