import {
  resetForm,
  selectContext,
  selectErrors,
  selectFieldValue,
  selectIsFormDataDirty,
  selectValues,
  setErrors,
  setFieldError,
  setFieldValue,
  setValues,
  validateForm,
} from '../store';
import { AsyncManager, EventService, FORM_API_STAGE, getDataWithPreprocessor } from '../index';

export default function injectFormApi({
  formApiEmpty: formApi,
  store,
  dispatch,
  fieldApis,
  events,
}) {
  // 给 ButtonElement formMethod action 调用的注册函数
  const methods = {};

  formApi.stage = FORM_API_STAGE.init;
  formApi.node = null;
  formApi.eventService = new EventService(events);
  formApi.asyncComponentManager = new AsyncManager();
  formApi.on = (type, listener) => {
    formApi.eventService.on(type, listener);
  };
  formApi.off = (type, listener) => {
    formApi.eventService.off(type, listener);
  };
  formApi.getData = () => {
    return selectValues(store.getState());
  };
  formApi.getDataWithPreprocessor = () => {
    return getDataWithPreprocessor({ store, formApi });
  };
  formApi.setData = (newData) => {
    dispatch(setValues(newData));
  };
  formApi.getContext = () => {
    return selectContext(store.getState());
  };
  // @deprecated
  // formApi.setFieldValue = ({ keyPath, value }) => dispatch(setFieldValue({ keyPath, value }));
  formApi.getValue = (keyPath) => {
    return selectFieldValue(keyPath)(store.getState());
  };
  formApi.setValue = (keyPath, value) => {
    dispatch(setFieldValue({ keyPath, value }));
  };
  formApi.getErrors = () => {
    return selectErrors(store.getState());
  };
  formApi.setErrors = (errors) => {
    dispatch(setErrors(errors));
  };
  formApi.setError = (keyPath, error) => {
    dispatch(setFieldError({ keyPath, error }));
  };
  formApi.getFieldApis = () => {
    return fieldApis;
  };
  formApi.getFieldApi = (fieldId) => {
    return fieldApis[fieldId];
  };
  formApi.validateForm = () => {
    return dispatch(validateForm()).unwrap();
  };
  formApi.isDataValid = () => {
    return !Object.keys(selectErrors(store.getState())).length;
  };
  formApi.isDirty = () => {
    return selectIsFormDataDirty(store.getState());
  };
  formApi.registerMethod = (name, func) => {
    if (typeof func === 'function') {
      methods[name] = func;
    } else {
      console.error(`registerMethod: ${func} is not a function`);
    }
  };
  formApi.getMethods = () => {
    return methods;
  };
  formApi.submit = () => {}; // will be reset below
  formApi.refresh = () => {}; // will be reset below
  formApi.cancel = () => {}; // will be reset below
  formApi.reset = ({ initialValues } = {}) => {
    dispatch(resetForm({ initialValues }));
  };
  formApi.getProps = () => {}; // will be reset below

  return formApi;
}
