import { Button, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Icon } from '@icp/components';

function toMaterialProps(props) {
  const { type, danger, icon, ...other } = props;

  const newProps = {
    variant:
      (type === 'primary' && 'contained') ||
      (type === 'default' && 'outlined') ||
      (type === 'text' && 'text') ||
      'outlined',
    ...other,
  };

  if (icon) {
    const iconSize = (other.size === 'small' && 16) || (other.size === 'large' && 24) || 20;
    newProps.startIcon = <Icon name={icon} size={iconSize} />;
  }

  if (danger) {
    newProps.color = 'error';
  }

  return newProps;
}

const ButtonMaterial = forwardRef(function ButtonMaterial(props, ref) {
  const { children, icon, loading, ...other } = props;

  if (!children && icon) {
    const iconSize = (other.size === 'small' && 16) || (other.size === 'large' && 24) || 20;

    return (
      <IconButton {...toMaterialProps(other)} ref={ref}>
        <Icon name={icon} size={iconSize} />
      </IconButton>
    );
  }

  if (loading) {
    return (
      <LoadingButton {...toMaterialProps({ ...other, icon })} loading={true} ref={ref}>
        {children}
      </LoadingButton>
    );
  }

  return (
    <Button {...toMaterialProps({ ...other, icon })} ref={ref}>
      {children}
    </Button>
  );
});

ButtonMaterial.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  loading: PropTypes.bool,
};

export default ButtonMaterial;
