import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import ACLTableServerSideTwoUrl from './ACLTableServerSideTwoUrl';
import ACLTableClientSide from './ACLTableClientSide';
import { getIdsInValue } from './utils';
import ACLTableServerSideOneUrl from './ACLTableServerSideOneUrl';

const ACLTable = forwardRef(function ACLTable(props, ref) {
  const {
    value,
    columnDefs,
    multiple,
    selectedKeys: selectedKeysProp,
    stringEqual,
    defaultColDef: defaultColDefProp,
    idListUrl,
    rowModelType,
    ...other
  } = props;

  // If not multiple, need to compute the default selectedKeys
  const selectedKeys = selectedKeysProp || getIdsInValue(value, stringEqual);

  const defaultColDef = useMemo(() => {
    return {
      ...defaultColDefProp,
      flex: multiple ? undefined : 1,
      minWidth: 100,
      width: 100,
      resizable: true,
    };
  }, [defaultColDefProp, multiple]);

  const common = {
    ...other,
    className: 'acl-table',
    headerHeight: 40,
    rowHeight: 40,
    animateRows: false,
    columnDefs,
    multiple,
    defaultColDef,
    selectedKeys,
    suppressAddButton: true,
    suppressDeleteButton: true,
    fuzzySearchOpen: true,
    suppressColumnSelect: true,
    suppressExcelExport: true,
    suppressCsvExport: true,
    suppressToolbarRefresh: true,
    suppressTableSetting: true,
    suppressFullscreen: true,
    suppressStatusBarTotalRows: multiple,
  };

  // 如果提供了 idListUrl，一定使用先 fetch 所有 ids 再拿详细信息的 lazy load 模式，此时可以支持多选所有 ids。
  if (idListUrl) {
    return <ACLTableServerSideTwoUrl {...common} ref={ref} />;
  }

  // 使用 ag-grid 的普通 serverSide 模式。此时，因为只有一个 dataUrl，没有根据选中的 ids 去拿详细数据的 url，
  // 所以当 acl 是多选的时候 ACLTransfer 右边的 table 无法显示选中数据的详细信息，只能显示 label。
  // 并且 header 的全选只能选中当前已加载的 rows。
  // TODO, 产品 api 暂时不支持使用 filter 去排除某一些 ids, 所以 multiple 的 lazy-loading 无法做
  if (rowModelType === 'serverSide' && !multiple) {
    return <ACLTableServerSideOneUrl {...common} ref={ref} />;
  }

  // 一次加载完所有数据，不适合数据量大的情况。
  return <ACLTableClientSide {...common} ref={ref} />;
});

ACLTable.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  multiple: PropTypes.bool,
  stringEqual: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onSelectionChanged: PropTypes.func,
  defaultColDef: PropTypes.shape({}),
  idListUrl: PropTypes.string,
  rowModelType: PropTypes.string,
};

export default ACLTable;
