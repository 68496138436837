// import PropTypes from 'prop-types';
// import clsx from 'clsx';
// import { forwardRef, useState } from 'react';
// import { Button, Modal } from 'antd';
// import loadable from '@loadable/component';
// import { useTranslation } from 'react-i18next';
// import { useElementDecorator } from '../FormRenderCtx';
// import FieldTitle from '../FieldTitle';
// import { useClassName } from '../hooks';
// import { withConditionalHidden, withFormControl, withTranslatedProps } from '../fieldWrapper';
// import { ConditionalPropertyPropType } from '../PredicateTypes';

// const FormDesigner = loadable(() =>
//   import(
//     // eslint-disable-next-line import/no-unresolved
//     '@icp/form-designer'
//   ).catch(() => ({
//     default: function ErrBoundary() {
//       console.error(`"@icp/form-designer" not installed`);
//       return `"@icp/form-designer" not installed`;
//     },
//   })),
// );

// function FDDialog({ open, value, ...rest }) {
//   const formEntityId =
//     value && typeof value === 'object' ? value.snapshot.context.formEntityId : value;

//   const preloadedState = value && typeof value === 'object' ? value.snapshot : undefined;

//   return (
//     <Modal
//       open={open}
//       centered={true}
//       title={null}
//       footer={null}
//       closable={false}
//       destroyOnClose={true}
//       className="fullscreen"
//     >
//       <FormDesigner
//         formEntityId={formEntityId}
//         isInnerEntity={true}
//         preloadedState={preloadedState}
//         {...rest}
//       />
//     </Modal>
//   );
// }

// FDDialog.propTypes = {
//   open: PropTypes.bool,
//   value: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.number,
//     PropTypes.shape({
//       submit: PropTypes.func,
//       snapshot: PropTypes.shape({}),
//     }),
//   ]),
// };

// const FormDesignerElement = forwardRef(function FormDesignerElement(props, ref) {
//   const {
//     keyPath,
//     id,
//     className: classNameProp,
//     title,
//     value,
//     style,
//     disabled,
//     componentProps = {},
//     fieldTitleProps,
//     validation,
//     onChange,
//     onTouchChanged,
//   } = props;

//   const { t } = useTranslation(['icp-form-renderer', 'icp-common']);
//   const ElementDecorator = useElementDecorator();

//   const [isDialogOpen, setDialogOpen] = useState(false);

//   const className = useClassName(classNameProp);
//   const classNameComp = useClassName(componentProps.className);

//   return (
//     <ElementDecorator keyPath={keyPath} id={id}>
//       <div className={clsx('input-element form-element', className)} style={style} ref={ref}>
//         <FieldTitle required={validation?.required} {...fieldTitleProps}>
//           {title}
//         </FieldTitle>
//         <Button
//           {...componentProps}
//           className={classNameComp}
//           name={id}
//           disabled={disabled}
//           onClick={() => setDialogOpen(true)}
//         >
//           {value == null ? t('create', { ns: 'icp-common' }) : t('edit', { ns: 'icp-common' })}
//         </Button>
//         <FDDialog
//           open={isDialogOpen}
//           value={value}
//           onConfirm={(res) => {
//             onChange?.({
//               snapshot: res.snapshot,
//               resolveValue: () => res.submit().then((formEntity) => formEntity.id),
//             });
//             setDialogOpen(false);
//             onTouchChanged();
//           }}
//           onExit={() => setDialogOpen(false)}
//         />
//       </div>
//     </ElementDecorator>
//   );
// });

// FormDesignerElement.propTypes = {
//   keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
//   id: PropTypes.string,
//   className: PropTypes.string,
//   title: PropTypes.string,
//   value: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.number,
//     PropTypes.shape({
//       submit: PropTypes.func,
//       snapshot: PropTypes.shape({}),
//     }),
//   ]),
//   disabled: ConditionalPropertyPropType(PropTypes.bool),
//   componentProps: PropTypes.shape({}),
//   fieldTitleProps: PropTypes.shape({
//     showColon: PropTypes.bool,
//   }),
//   validation: PropTypes.shape({
//     required: PropTypes.bool,
//   }),
//   onChange: PropTypes.func,
//   onTouchChanged: PropTypes.func,
// };

// const Wrapper = withConditionalHidden(withTranslatedProps()(withFormControl(FormDesignerElement)));

function Wrapper() {
  return null;
}

// FormControl 里去调用了 getComponentDisplayName 获取组件名字
// FormDesignerElement.displayName = 'FormDesigner';

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
// Wrapper.displayName = FormDesignerElement.displayName;

export default Wrapper;
