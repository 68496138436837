import { forwardRef } from 'react';
import { Progress } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const ProgressAnt = forwardRef(function ProgressAnt(props, ref) {
  const { className, percent, status: statusProp, progressStyle = 'style1', ...other } = props;

  const type = progressStyle === 'style4' ? 'circle' : 'line';
  const percentPosition = progressStyle === 'style3' ? { type: 'inner', align: 'end' } : undefined;
  const size = {
    style1: ['100%', 6],
    style2: ['100%', 8],
    style3: ['100%', 20],
    style4: 80,
  }[progressStyle];
  // success 和 error antd 会分别显示两个 icon 并且配上红色和绿色
  const status = { success: 'success', error: 'exception', warning: 'active' }[statusProp];

  return (
    <Progress
      // designer 里修改 style ant 组件不刷新
      key={progressStyle}
      // 允许 componentProps 覆盖这些默认属性
      type={type}
      strokeColor="var(--status-color)"
      percentPosition={percentPosition}
      size={size}
      {...other}
      className={clsx('progress-element form-element', className, statusProp)}
      percent={percent}
      status={status}
      ref={ref}
    />
  );
});

ProgressAnt.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
  status: PropTypes.string,
  progressStyle: PropTypes.string,
};

export default ProgressAnt;
