export const entityDataUrl = {
  list: () => null,
  create: ({
    pbcToken,
    formEntityToken,
    formEntityLayoutToken,
    formEntityId,
    formEntityLayoutId,
  }) => {
    if (pbcToken && formEntityToken && formEntityLayoutToken) {
      return `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/${formEntityLayoutToken}`;
    }

    return `/form/api/form-entity-data/${formEntityId}/${formEntityLayoutId}`;
  },
  retrieve: ({ formEntityDataId }) => {
    return `/form/api/form-entity-data/${formEntityDataId}`;
  },
  edit: ({
    pbcToken,
    formEntityToken,
    formEntityLayoutToken,
    formEntityLayoutId,
    formEntityDataId,
  }) => {
    if (pbcToken && formEntityToken && formEntityLayoutToken) {
      return `/form/api/v2/form-entity-data/${formEntityDataId}/${pbcToken}/${formEntityToken}/${formEntityLayoutToken}`;
    }

    return `/form/api/form-entity-data/${formEntityDataId}/${formEntityLayoutId}`;
  },
  view: ({ formEntityDataId }) => {
    return `/form/api/form-entity-data/${formEntityDataId}/view`;
  },
};

export const flowDataUrl = {
  get: ({ pbcToken, flowToken, flowDefinitionId, flowInstanceId }) => {
    if (flowInstanceId) {
      return `/flow/api/flow-instance/${flowInstanceId}/get-form`;
    }

    if (pbcToken && flowToken) {
      // token use v2 api
      return `/flow/api/v2/flow-definition/${pbcToken}/${flowToken}/get-form`;
    }

    // old api
    return `/flow/api/flow-definition/${flowDefinitionId}/get-form`;
  },
  update: ({
    pbcToken,
    flowToken,
    formEntityToken,
    layoutToken,
    flowDefinitionId,
    flowInstanceId,
    layoutId,
  }) => {
    if (pbcToken && (flowToken || formEntityToken)) {
      // token use v2 api
      return flowInstanceId
        ? `/flow/api/v2/flow-instance/${flowInstanceId}/${pbcToken}/${formEntityToken}/${layoutToken}/update-form`
        : `/flow/api/v2/flow-definition/${pbcToken}/${flowToken}/${formEntityToken}/${layoutToken}/update-form`;
    }
    // old api
    return flowInstanceId
      ? `/flow/api/flow-instance/${flowInstanceId}/${layoutId}/update-form`
      : `/flow/api/flow-definition/${flowDefinitionId}/${layoutId}/update-form`;
  },
  approve: ({ flowInstanceId }) => {
    return `/flow/api/flow-instance/${flowInstanceId}/approval`;
  },
};
