import PropTypes from 'prop-types';
import { Button as ButtonAnt } from 'antd';
import { Button as ButtonMaterial, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function toMaterialProps(props) {
  const { type, danger, icon, ...other } = props;

  const newProps = {
    variant:
      (type === 'primary' && 'contained') ||
      (type === 'default' && 'outlined') ||
      (type === 'text' && 'text') ||
      'outlined',
    ...other,
  };

  if (icon) {
    newProps.startIcon = icon;
  }

  if (danger) {
    newProps.color = 'error';
  }

  return newProps;
}

// TODO, 临时代码，material 考虑重新设计 toolbar，icon 的 size 也不对外面写死 16 了。
function Button(props) {
  const { componentLibrary, ...otherProps } = props;

  if (componentLibrary === 'material-ui') {
    const { children, icon, loading, ...other } = otherProps;

    if (!children && icon) {
      return <IconButton {...toMaterialProps(other)}>{icon}</IconButton>;
    }

    if (loading) {
      return (
        <LoadingButton {...toMaterialProps({ ...other, icon })} loading={true}>
          {children}
        </LoadingButton>
      );
    }

    return <ButtonMaterial {...toMaterialProps({ ...other, icon })}>{children}</ButtonMaterial>;
  }

  return <ButtonAnt {...otherProps} />;
}

Button.propTypes = {
  componentLibrary: PropTypes.string,
};

export default Button;
