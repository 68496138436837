import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, message } from 'antd';
import { restApi } from '@icp/settings';
import FormRenderer, { InputElement, SelectElement, StackLayout } from '@icp/form-renderer';
import { CodeEditor } from '@icp/components';
import Field from './Field';

function ConnectorDialog(props) {
  const { pbcId, connector, connectorTypeList, onSuccess, onClose } = props;

  const { t } = useTranslation(['icp-app', 'icp-common']);

  const [selectedConnectorType, setSelectedConnectorType] = useState(() =>
    connector ? connectorTypeList.find((x) => x.uuid === connector.connectorTypeUuid) : null,
  );
  const [submitting, setSubmitting] = useState(false);

  const formRef = useRef(null);

  const handleOk = () => {
    formRef.current.submit();
  };

  const handleSubmit = (formData) => {
    setSubmitting(true);

    if (connector) {
      return restApi
        .put(`/connector/api/connector/${formData.id}`, formData)
        .then((res) => {
          onSuccess(res);
        })
        .finally(() => setSubmitting(false));
    }

    return restApi
      .post('/connector/api/connector', formData)
      .then((res) => {
        onSuccess(res);
      })
      .finally(() => setSubmitting(false));
  };

  const listenersRef = useRef([]);

  const handleFieldValueChange = (keyPath, value) => {
    if (keyPath === 'connectorTypeUuid') {
      setSelectedConnectorType(connectorTypeList.find((x) => x.uuid === value));
    }
    listenersRef.current.forEach((listener) => listener(keyPath, value));
  };

  const handleClickTestConnection = () => {
    const actionUuid = selectedConnectorType.testConnectionActionUuid;
    return restApi
      .post(`/connector/api/action/exe-action`, {
        connectorUuid: connector.uuid,
        actionUuid,
      })
      .then((res) => {
        if (res.success) {
          message.success(t('connect-success', { ns: 'icp-common' }));
        } else {
          message.warning(t('connect-fail', { ns: 'icp-common' }));
        }
        Modal.info({
          title: t(`connect-success`, { ns: 'icp-common' }),
          centered: true,
          content: (
            <div>
              <CodeEditor
                language="json"
                readOnly={true}
                bordered={true}
                style={{ height: 200 }}
                //
              >
                {JSON.stringify(res, null, 2)}
              </CodeEditor>
            </div>
          ),
        });
      });
  };

  return (
    <Modal
      open={true}
      destroyOnClose={true}
      centered={true}
      title={connector ? t('connector.edit') : t('connector.add')}
      width={600}
      onOk={handleOk}
      onCancel={onClose}
      confirmLoading={submitting}
    >
      <FormRenderer
        ref={formRef}
        className="icp-dialog-form"
        defaultData={{ pbcId, parameters: {}, ...connector }}
        onSubmit={handleSubmit}
        onFieldValueChange={handleFieldValueChange}
      >
        <StackLayout componentProps={{ gap: 12, flexDirection: 'column' }}>
          <InputElement id="name" title={t('connector.name')} validation={{ required: true }} />
          <SelectElement
            id="connectorTypeUuid"
            title={t('connector.connector-type')}
            componentProps={{
              valueType: 'value',
              mapping: {
                value: 'uuid',
                label: 'name',
              },
              options: connectorTypeList,
            }}
          />
          <InputElement id="description" title={t('description', { ns: 'icp-common' })} />
          {selectedConnectorType?.parameters?.map((parameter) => (
            <Field
              key={parameter.name}
              info={parameter}
              formRef={formRef}
              listenersRef={listenersRef}
            />
          ))}
          {connector && selectedConnectorType?.testConnectionActionUuid && (
            <div style={{ marginBottom: 10 }}>
              <Button size="small" onClick={handleClickTestConnection}>
                {t('connector.test')}
              </Button>
            </div>
          )}
        </StackLayout>
      </FormRenderer>
    </Modal>
  );
}

ConnectorDialog.propTypes = {
  pbcId: PropTypes.number,
  connector: PropTypes.shape({
    uuid: PropTypes.string,
    connectorTypeUuid: PropTypes.string,
  }),
  connectorTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConnectorDialog;
