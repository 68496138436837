import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { parseJSON, toGroup, toMap } from '@icp/utils';
// eslint-disable-next-line import/no-extraneous-dependencies
import initAppConfig from 'create-icp-app/template/appConfig/app.config.json';

export const fetchPbc = createAsyncThunk('context/fetchPbc', async (payload, { signal, extra }) => {
  return extra.restApi.get(`/form/api/pbc/${payload}`, { signal });
});

export const fetchProject = createAsyncThunk(
  'context/fetchProject',
  async (payload, { signal, extra }) => {
    return extra.restApi.get(`/form/api/project/get-by-pbc-id/${payload}`, { signal });
  },
);

export const fetchI18nResources = createAsyncThunk(
  'context/fetchI18nResources',
  async (payload, { signal, extra }) => {
    const projectId = payload;

    return Promise.all([
      extra.restApi.get(`/form/api/translation/getByProjectId/${projectId}`, { signal }),
      extra.restApi.get('/form/api/translation/standard', { signal }),
    ])
      .then((aoa) => aoa.flat())
      .then((list) => {
        return list.flatMap(({ locale, translation }) => {
          const i18nResourceGroupedByNs = parseJSON(translation);
          if (!i18nResourceGroupedByNs) return [];
          return Object.entries(i18nResourceGroupedByNs)
            .filter(([, resource]) => resource != null)
            .map(([ns, resource]) => {
              return {
                locale,
                ns,
                resource,
              };
            });
        });
      })
      .then((list) => {
        return Object.fromEntries(
          toGroup((x) => x.locale)(list).map(([locale, subList]) => [
            locale,
            toMap(
              (x) => x.ns,
              (x) => x.resource,
            )(subList),
          ]),
        );
      });
  },
);

export const fetchAppConfig = createAsyncThunk(
  'context/fetchFrontendConfig',
  async (payload, { signal, extra, getState }) => {
    const project = selectProject(getState());
    const frontendConfig = await extra.restApi.get(
      `/form/api/front-end-config/get-by-project-id/${project.id}`,
      { signal },
    );
    return (
      parseJSON(frontendConfig?.config)?.appConfig || JSON.parse(JSON.stringify(initAppConfig))
    );
  },
);

const slice = createSlice({
  name: 'context',
  initialState: {
    typeName: '',
    userProfile: null,
    userPermissionMap: null,
    readonly: false,
    pbcId: null,
    pbc: null,
    project: null,
    appConfig: null,
    formEntityId: null,
    isInnerEntity: null,
    singleLayout: false,
    i18nResources: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPbc.fulfilled, (state, action) => {
        state.pbc = action.payload;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.project = action.payload;
      })
      .addCase(fetchI18nResources.fulfilled, (state, action) => {
        state.i18nResources = action.payload;
      })
      .addCase(fetchAppConfig.fulfilled, (state, action) => {
        state.appConfig = action.payload;
      });
  },
});

const selectThis = (state) => state.context;

export const selectTypeName = (state) => state.context.typeName;
export const selectReadonly = (state) => state.context.readonly;
export const selectPbc = createSelector(selectThis, (state) => state.pbc);
export const selectPbcToken = createSelector(selectPbc, (pbc) => pbc?.token);
export const selectProject = createSelector(selectThis, (state) => state.project);
export const selectProjectToken = createSelector(selectProject, (project) => project?.token);
export const selectI18nResources = createSelector(selectThis, (state) => state.i18nResources);
export const selectAppConfig = (state) => state.context.appConfig;
export const selectFormEntityId = createSelector(selectThis, (state) => state.formEntityId);
export const selectIsSingleLayout = createSelector(selectThis, (state) => state.singleLayout);
export const selectUserProfile = createSelector(selectThis, (state) => state.userProfile);
export const selectUserPermissionMap = createSelector(
  selectThis,
  (state) => state.userPermissionMap,
);

export default slice.reducer;
