export function saveSetting(key, obj) {
  if (!key) return;
  const settings = loadSetting(key);
  const newSettings = { ...settings, ...obj };
  localStorage.setItem(key, JSON.stringify(newSettings));
}

export function loadSetting(key) {
  if (!key) return {};
  return JSON.parse(localStorage.getItem(key)) || {};
}

export function clearSetting(key) {
  if (!key) return;
  const { favoriteView } = loadSetting(key);
  if (favoriteView) {
    localStorage.setItem(key, JSON.stringify({ favoriteView }));
  } else {
    localStorage.removeItem(key);
  }
}
