import PropTypes from 'prop-types';
import { Empty, Select } from 'antd';
import { Loading } from '@icp/components';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import { useMemo } from 'react';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import ItemField from './ItemField';

const loadingIndicator = 'lazy-loading-indicator';

function SelectAnt(props) {
  const {
    keyPath,
    id,
    title,
    value,
    componentProps,
    fieldTitleProps,
    validation,
    disabled,
    readonly,
    status,
    helpers,
    onChange,
    onTouchChanged,

    readonlyText,
    showSearch,
    loading,
    lazyLoading,
    lazyFetching,
    useClientSideFilter,
    options,
    legacyMode,
    multiple,
    mapping,
    itemField,
    onSearch,
    onScroll,
    onClose,
  } = props;

  const optionsWithLoading = useMemo(() => {
    if (lazyLoading && options?.length && lazyFetching) {
      return [...options, { type: loadingIndicator, disabled: true }];
    }
    return options;
  }, [lazyFetching, lazyLoading, options]);

  const handleOpenChange = (open) => {
    if (!open) {
      onClose();
    }
  };

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          <span className="readonly-text">{readonlyText}</span>
        ) : (
          <>
            <Select
              {...componentProps}
              name={id}
              value={value}
              disabled={disabled}
              mode={legacyMode || (multiple ? 'multiple' : 'single')}
              status={status}
              loading={loading}
              notFoundContent={
                loading || lazyFetching ? (
                  <Loading size={16} delayed={false} style={{ height: 56 }} />
                ) : (
                  <Empty className="ant-empty-small" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              showSearch={showSearch}
              options={optionsWithLoading}
              filterOption={useClientSideFilter}
              // antd Select search 的时候没有自动读区 fieldNames 识别 options 中的 label，需要用这个属性
              // 单独配置一下。也奇怪， Cascader 就自动识别了，也没有这个属性。 TreeSelect 更狠搭配 fieldNames 直接就搜索不了
              optionFilterProp={useClientSideFilter ? mapping.label : undefined}
              fieldNames={mapping}
              optionRender={(option) => {
                if (option.data.type === loadingIndicator) {
                  return <Loading size={16} centered={false} delayed={false} />;
                }
                if (itemField) {
                  return <ItemField keyPath={keyPath} itemField={itemField} option={option.data} />;
                }
                return option.label;
              }}
              onChange={onChange}
              onSearch={showSearch && !useClientSideFilter ? onSearch : undefined}
              onBlur={onTouchChanged}
              onPopupScroll={lazyLoading ? onScroll : undefined}
              onDropdownVisibleChange={handleOpenChange}
            />
            <FormHelperText helpers={helpers} />
          </>
        )}
      </div>
    </>
  );
}

SelectAnt.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
  ]),
  componentProps: PropTypes.shape({}),
  legacyMode: PropTypes.string,
  multiple: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    // Other key map to save
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  showSearch: PropTypes.bool,
  lazyLoading: PropTypes.bool,
  itemField: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  readonlyText: PropTypes.string,
  loading: PropTypes.bool,
  lazyFetching: PropTypes.bool,
  useClientSideFilter: PropTypes.bool,
  onSearch: PropTypes.func,
  onScroll: PropTypes.func,
  onClose: PropTypes.func,
};

export default SelectAnt;
