import { pick } from 'lodash-es';

function findBracketsContent(str) {
  const results = [];
  const regex = /\[\[(.*?)\]\]/g;
  for (let match = regex.exec(str); match !== null; match = regex.exec(str)) {
    results.push({
      text: match[1],
      start: match.index,
      end: match.index + match[0].length,
    });
  }
  return results;
}

/**
 * @returns
    {
      all: { text, start, end, fields?, form? }[],
      forms: { text, start, end, fields }[],
      fields: { text, start, end, form }[],
    }
 */
function constructReferenceData(str) {
  const contents = findBracketsContent(str);
  const results = { all: [], forms: [], fields: [], formByEnd: new Map() };

  const endSet = new Set(contents.map((x) => x.end));

  contents.forEach(({ text, start, end }) => {
    // 如果start存在于endSet，说明是紧挨的括号，是字段，否则是表单
    const isField = endSet.has(start);

    if (!isField) {
      const item = { text, start, end, fields: [] };
      results.all.push(item);
      results.forms.push(item);
      results.formByEnd.set(end, item);
    } else {
      const item = { text, start, end, form: null };
      results.all.push(item);
      results.fields.push(item);
      if (results.formByEnd.has(start)) {
        const form = results.formByEnd.get(start);
        results.formByEnd.set(end, form);
        item.form = form;
        form.fields.push(item);
      }
    }
  });

  return results;
}

export default class ReferenceDataController {
  #referenceData = null;

  #text = '';

  #positionOnOpenPopover = 0;

  #beforeTextChange = null;

  get positionOnOpenPopover() {
    return this.#positionOnOpenPopover;
  }

  constructor(options) {
    /*
      mirrorEl
      getFormList
      getFieldList
      getFormByName
      getFieldByName
      onOpenFormSelect
      onOpenFieldSelect
      onCloseSelect
    */
    this.options = options || {};
  }

  beforeTextChange(text, selectionStart, selectionEnd) {
    if (selectionStart > selectionEnd) {
      [selectionStart, selectionEnd] = [selectionEnd, selectionStart];
    }
    this.#beforeTextChange = { text, selectionStart, selectionEnd };
  }

  setText(text) {
    this.#text = text;
    this.#referenceData = constructReferenceData(text);
    setTimeout(() => this.#highlight(), 0);
  }

  #highlight() {
    const mirrorEl = this.options.mirrorEl;
    const referenceData = this.#referenceData;
    if (!mirrorEl || !referenceData || !window.CSS.highlights || !window.Highlight || !window.Range)
      return;

    const rangesForm = referenceData.forms.map((item) => {
      const range = new window.Range();
      range.setStart(mirrorEl.firstChild, item.start);
      range.setEnd(mirrorEl.firstChild, item.end);
      return range;
    });
    const highlightForm = new window.Highlight(...rangesForm);
    window.CSS.highlights.set('ai-agent-ref-form', highlightForm);

    const rangesField = referenceData.fields.map((item) => {
      const range = new window.Range();
      range.setStart(mirrorEl.firstChild, item.start);
      range.setEnd(mirrorEl.firstChild, item.end);
      return range;
    });
    const highlightField = new window.Highlight(...rangesField);
    window.CSS.highlights.set('ai-agent-ref-field', highlightField);
  }

  decideIfOpenSelect(char, pos) {
    const position = pos;
    const leftText = this.#text.substring(0, position);
    const rightText = this.#text.substring(position);

    // 右边不是[, 左边]][[时, 打开field popover
    if (rightText.substr(0, 1) !== '[' && leftText.slice(-4) === ']][[') {
      const referenceData = this.#referenceData;

      // 找到对应的form
      const formName = referenceData.formByEnd.get(position - 2)?.text;

      if (!formName) return;

      Promise.resolve(formName)
        .then(this.options?.getFormByName)
        .then((form) => {
          this.#positionOnOpenPopover = position;
          this.options.onOpenFieldSelect(form);
        });
    }
    // 右边不是[, 左边[[时, 打开form popover
    // TODO 处理 \[[[
    // TODO 右边1个[, 左边1个[时, 也打开popover
    else if (rightText.substr(0, 1) !== '[' && /^[^[]*\[{2}$/.test(leftText.slice(-3))) {
      this.#positionOnOpenPopover = position;
      this.options.onOpenFormSelect();
    }
  }

  onSelectItem = (item) => {
    if (!item?.name) {
      this.options.onCloseSelect();
      return;
    }
    const position = this.#positionOnOpenPopover;
    this.#positionOnOpenPopover = null;
    const leftText = this.#text.substring(0, position);
    const rightText = this.#text.substring(position);
    const itemName = item.name;
    const newText = `${leftText}${itemName}]]${rightText}`;
    this.#onProduceNewText(newText);
    this.options.onChangeCursorPosition(position + itemName.length + 2);
  };

  #onProduceNewText(newText) {
    this.setText(newText);
    this.options.onProduceNewText(newText);
  }

  checkIfInRefContent(position) {
    const referenceData = this.#referenceData;
    if (!referenceData) return false;
    return referenceData.all.some(
      (item) =>
        // content内部
        (item.start < position && item.end > position) ||
        // 连接点
        (item.form && item.start === position),
    );
  }

  processCustomDelete() {
    const referenceData = this.#referenceData;
    if (!referenceData) return false;
    const { text, selectionStart, selectionEnd } = this.#beforeTextChange;
    // 删field 直接删该field
    // 删form 删form同时连带删后续fields
    const itemsToDelete = referenceData.all
      .filter(
        (item) =>
          (selectionStart > item.start && selectionStart <= item.end) ||
          (selectionEnd > item.start && selectionEnd <= item.end) ||
          (item.start >= selectionStart && item.end <= selectionEnd),
      )
      .flatMap((item) => [item, ...(item.fields || [])]);

    if (!itemsToDelete.length) return false;

    const maxPos = Math.max(selectionEnd, ...itemsToDelete.map((item) => item.end));
    const minPos = Math.min(selectionStart, ...itemsToDelete.map((item) => item.start));
    const newText = text.slice(0, minPos) + text.slice(maxPos);

    this.#onProduceNewText(newText);
    this.options.onChangeCursorPosition(minPos);
    return true;
  }

  processPrompt = async () => {
    const prompt = this.#text;
    const referenceData = this.#referenceData;
    if (!referenceData) return prompt;

    const forms = await Promise.all(
      [...new Set(referenceData.forms.map((item) => item.text))].map((formName) =>
        Promise.resolve(formName)
          .then(this.options.getFormByName)
          .then(async (form) => {
            // 给表单拉字段
            const fields = await this.options.getFieldList(form);
            return { ...form, fields };
          })
          .then((form) =>
            pick(form, ['id', 'token', 'name', 'description', 'pbcToken', 'fields', 'layouts']),
          )
          .then((form) => {
            form.layouts = form.layouts.map((layout) => pick(layout, ['name', 'token']));
            form.fields = form.fields.map((field) => pick(field, ['name', 'token', 'type']));
            return form;
          }),
      ),
    );

    return `${prompt.replace(/([^\]])\[\[/g, '$1FORM[[').replace(/\]\]\[\[/g, ']]FIELD[[')}

${forms
  .map(
    (form) =>
      `# Form: ${form.name}
\`\`\`json
${JSON.stringify(form, null, 2)}
\`\`\`
`,
  )
  .join('\n\n')}
`;
  };
}
