import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator } from '../FormRenderCtx';
import { useVariablePattern } from '../hooks';

const VideoElement = forwardRef(function VideoElement(props, ref) {
  const { keyPath, id, style, componentProps = {} } = props;

  const {
    src: srcProp = null,
    sources = [],
    style: componentStyle,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const src = useVariablePattern(srcProp);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id} ref={nodeRef}>
      <video src={src} {...otherComponentProps} style={{ ...style, ...componentStyle }} ref={ref}>
        {sources.map((item) => (
          <source key={item.src} src={item.src} type={item.type} />
        ))}
      </video>
    </ElementDecorator>
  );
});
VideoElement.propTypes = {
  id: PropTypes.string,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  componentProps: PropTypes.shape({
    /**
     * 视频的播放源
     */
    src: PropTypes.string,
    /**
     * 兼容不同的浏览器中使用
     */
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
  }),
};
// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
VideoElement.displayName = 'Video';

export default withFieldWrapper(VideoElement);
