import { forwardRef, useImperativeHandle, useRef } from 'react';
import { forOwn } from 'lodash-es';
import PropTypes from 'prop-types';
import { Tooltip, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileStatus, uploadFile } from '../utils';
import Icon from '../../Icon';

const UploadWidget = forwardRef((props, ref) => {
  const { fileList = [], onChange, disabled } = props;
  const uploadFiles = useRef(fileList);
  const { t } = useTranslation(['icp-common']);

  useImperativeHandle(ref, () => ({
    setUploadFiles: (list) => {
      uploadFiles.current = list;
    },
  }));

  const uploadProps = {
    fileList,
    multiple: true,
    showUploadList: false,
    customRequest: (parameters) => {
      const { file } = parameters;
      updateFile(file, { status: FileStatus.Uploading });
      uploadFile(file)
        .then((data) => {
          updateFile(file, { fileId: data, status: FileStatus.Done });
        })
        .catch((error) => {
          console.error(error);
          updateFile(file, { status: FileStatus.Error });
        });
    },
  };

  function updateFile(file, properties) {
    forOwn(properties, (value, key) => {
      file[key] = value;
    });
    const index = uploadFiles.current.findIndex((item) => item.uid === file.uid);
    if (index < 0) {
      uploadFiles.current = uploadFiles.current.concat(file);
    } else {
      uploadFiles.current.splice(index, 1, file);
      uploadFiles.current = uploadFiles.current.slice();
    }
    onChange(uploadFiles.current);
  }

  return (
    <Upload {...uploadProps}>
      <Tooltip title={t('attachment')}>
        <button disabled={disabled}>
          <Icon name="oct:paperclip" size={16} />
        </button>
      </Tooltip>
    </Upload>
  );
});

UploadWidget.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default UploadWidget;
