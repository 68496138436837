import './AIAgentFloatButton.css';
import PropTypes from 'prop-types';
import { cloneElement, useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import Resizable from '../Resizable';
import AIAgentIcon from '../AIAgentIcon';

function AIAgentFloatButton(props) {
  const { isInDesign, defaultOpen = false, children } = props;
  // To ensure the AI Agent input area height is correct when defaultOpen is false,
  // need "open" to avoid the AI Agent panel added to the DOM at the beginning.
  const [open, setOpen] = useState(defaultOpen);

  const floatButton = (
    <FloatButton
      style={{ display: open ? 'none' : 'block' }}
      className="ai-agent-float-button ai-agent-icon-hover-wrapper"
      tooltip="AI Agent"
      icon={<AIAgentIcon size="100%" />}
      onClick={() => {
        if (!isInDesign) {
          setOpen(true);
        }
      }}
    />
  );

  useEffect(() => {
    const parentElm = document.body.querySelector('.ai-agent-float-button')?.parentElement;
    parentElm?.classList?.add('ai-agent-float-button-parent');
    return () => {
      parentElm?.classList?.remove('ai-agent-float-button-parent');
    };
  }, []);

  return (
    <>
      <Resizable placement="left" min={366} max={window.screen.availWidth / 2}>
        <div className="ai-agent-right-side-panel" style={{ display: open ? 'flex' : 'none' }}>
          {cloneElement(children, {
            eventHandlers: {
              ...children.props.eventHandlers,
              onClose: () => {
                setOpen(false);
                return children.props.eventHandlers?.onClose?.();
              },
            },
          })}
        </div>
      </Resizable>
      {floatButton}
    </>
  );
}

AIAgentFloatButton.propTypes = {
  isInDesign: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node,
};

export default AIAgentFloatButton;
