import { useTranslation } from 'react-i18next';
import { useAllowedLanguages } from '../FormRenderCtx';

export default function useValueLanguageOptions() {
  const { i18n } = useTranslation();

  const allowLanguages = useAllowedLanguages();

  return allowLanguages.map((lng) => {
    return {
      value: lng,
      label: new Intl.DisplayNames([i18n.language], {
        type: 'language',
        languageDisplay: 'standard',
      }).of(lng),
    };
  });
}
