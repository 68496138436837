import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import Axios from 'axios';
import restApi, { auth } from 'services/restApi';
import { getLanguage } from '@icp/i18n';
import { toMap, getSearchParams } from '@icp/utils';
import { setUserProfile } from '@icp/settings';
import { isEqual } from 'lodash-es';

export const selectIsAuthing = (state) => state.auth.isAuthing;
export const selectUserPermissionMap = (state) => state.auth.userPermissionMap;
export const selectAuthError = (state) => state.auth.error;
export const selectUserProfile = (state) => state.auth.userProfile;
export const selectIsAdminUser = (state) => state.auth.userProfile?.isAdmin === 1;
export const selectLoginConfig = (state) => state.auth.loginConfig;
export const selectLoginSources = (state) => state.auth.loginSources;

export const AUTH_SUCCESS_REDIRECT_URL_STORAGE_KEY = 'AUTH_SUCCESS_REDIRECT_URL_C5793E6F47DF';

const restClient = Axios.create({
  headers: {
    'Accept-Language': getLanguage(),
  },
});
restClient.interceptors.response.use((response) => response.data);

const fetchUserProfile = () =>
  restApi
    .get(`/user-management/api/user/get-user-info`)
    .then((userProfile) => {
      setUserProfile(userProfile);
      return userProfile;
    })
    .catch(() => null);

const GONNA_REDIRECT = (() => {
  const err = Error('未登录');
  err.code = 'GONNA_REDIRECT';
  return err;
})();

export const fetchLoginConfig = createAsyncThunk(
  'auth/fetchLoginConfig',
  //
  async () => {
    const loginConfig = await restClient.get('/user-management/api/login/config');
    const loginSources = (loginConfig || []).sort((a, b) => a.order - b.order);
    return {
      loginConfig,
      loginSources,
    };
  },
);

export const loginViaSource = createAsyncThunk(
  'auth/loginViaSource',
  //
  async ({ token: loginSourceToken, fromLogin, navigate }, { dispatch, getState }) => {
    if (!selectLoginSources(getState())) {
      await dispatch(fetchLoginConfig());
    }

    const loginSources = selectLoginSources(getState());
    const loginSource = loginSources.find((x) => x.token === loginSourceToken);

    if (!loginSource) {
      throw Error(`Login source not found: ${loginSourceToken}`);
    }

    if (loginSource.type?.toLowerCase() === 'auth') {
      let newSP;

      if (fromLogin) {
        newSP = new URLSearchParams(window.location.search);
        newSP.set('auth', loginSourceToken);
      } else {
        newSP = new URLSearchParams();
        newSP.set('auth', loginSourceToken);
        newSP.set('from', window.location.href);
      }

      if (navigate) {
        navigate(`/login/basic?${newSP.toString()}`, { replace: true });
      } else {
        window.location.replace(`${window.ICP_PUBLIC_PATH}login/basic?${newSP.toString()}`);
      }

      throw GONNA_REDIRECT;
    }

    if (loginSource.type?.toLowerCase() === 'redirect-sso') {
      const { from } = getSearchParams();
      const successRedirect = fromLogin ? from : window.location.href;

      const url = await restClient.get(`/user-management/api/login/redirect-url`, {
        params: { auth: loginSource.token },
      });

      sessionStorage.setItem(AUTH_SUCCESS_REDIRECT_URL_STORAGE_KEY, successRedirect || '');

      window.location.href = url;

      throw GONNA_REDIRECT;
    }

    throw Error(`Unknown login source type: ${loginSource.type}`);
  },
);

export const checkAuthState = createAsyncThunk(
  'auth/checkAuthState',
  //
  async (payload, { dispatch, getState }) => {
    // 若localStorage中有token不重走授权流程
    const accessToken = auth.getAccessToken();
    const refreshToken = auth.getRefreshToken();
    if (auth.hasActiveAccessToken()) {
      const userProfile = selectUserProfile(getState()) || (await fetchUserProfile());
      // 已登录 (有token且没过期) 注册刷新任务
      auth.beginRefreshTokenTask();
      auth.restartIdleListener();
      return { accessToken, refreshToken, userProfile };
    }

    if (!selectLoginSources(getState())) {
      await dispatch(fetchLoginConfig());
    }
    const loginSources = selectLoginSources(getState());

    if (loginSources.length === 1) {
      const loginSource = loginSources[0];
      await dispatch(loginViaSource({ token: loginSource.token })).unwrap();
    }

    window.location.replace(
      `${window.ICP_PUBLIC_PATH}login?from=${encodeURIComponent(window.location.href)}`,
    );

    throw GONNA_REDIRECT;
  },
);

export const ssoLogin = createAsyncThunk(
  'auth/ssoLogin',
  //
  async (payload) => {
    const { payload: body, loginSourceToken } = payload;
    const data = await restApi.post('/user-management/api/login/ssoLogin', body, {
      params: { auth: loginSourceToken },
    });
    auth.setToken({
      access: data.accessToken,
      refresh: data.refreshToken,
    });
    auth.beginRefreshTokenTask();
    auth.restartIdleListener();
    const userProfile = await fetchUserProfile();
    return { ...data, userProfile };
  },
);

export const classicLogin = createAsyncThunk(
  'auth/classicLogin',
  async (payload, { rejectWithValue }) => {
    const { loginSourceToken, data: body } = payload;

    try {
      const data = await restApi.post(`/user-management/api/user/login`, body, {
        params: { auth: loginSourceToken },
      });
      auth.setToken({
        access: data.accessToken,
        refresh: data.refreshToken,
      });
      auth.beginRefreshTokenTask();
      auth.restartIdleListener();
      const userProfile = await fetchUserProfile();
      return { ...data, userProfile };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loginConfig: null,
    loginSources: null,

    isAuthing: true,
    error: null,

    userProfile: null,
    userPermissions: [],
    userPermissionMap: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginConfig.rejected, (state) => {
        state.loginConfig = null;
        state.loginSources = [];
      })
      .addCase(fetchLoginConfig.fulfilled, (state, action) => {
        const { loginConfig, loginSources } = action.payload;
        state.loginConfig = loginConfig;
        state.loginSources = loginSources;
      })
      .addCase(checkAuthState.pending, (state) => {
        state.isAuthing = true;
        state.error = null;
      })
      .addCase(checkAuthState.rejected, (state, action) => {
        state.isAuthing = false;
        state.error = action.error;
        state.accessToken = null;
        state.refreshToken = null;
        state.userProfile = null;
        state.userPermissions = [];
        state.userPermissionMap = {};
      })
      .addCase(loginViaSource.rejected, (state, action) => {
        if (action.error !== GONNA_REDIRECT) {
          console.error(action.error);
        }
      })
      .addMatcher(
        isAnyOf(checkAuthState.fulfilled, classicLogin.fulfilled, ssoLogin.fulfilled),
        (state, action) => {
          const { userProfile } = action.payload;
          const userPermissions = userProfile?.permissions ?? [];

          state.isAuthing = false;
          state.error = null;

          if (!isEqual(state.userProfile, userProfile)) {
            const userPermissionMap = toMap(
              (x) => x.token,
              () => true,
            )(userPermissions);

            state.userProfile = userProfile;
            state.userPermissions = userPermissions;
            state.userPermissionMap = userPermissionMap;
          }
        },
      );
  },
});

// export const {} = authSlice.actions;

export default authSlice.reducer;
