import { set } from 'lodash-es';
import { restApi } from '@icp/settings';

// Map<String, Promise<Field[]>> key: <project-token>#@#<pbc-token>#@#<form-entity-token>
const cache = new Map();

async function fetchFieldList(projectToken, pbcToken, formEntityToken, signal) {
  const cacheKey = [projectToken, pbcToken, formEntityToken].join('#@#');
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const loader = restApi
    .get(`/form/api/v2/form-entity-field/list/${pbcToken}/${formEntityToken}/${projectToken}`, {
      signal,
    })
    .catch((error) => {
      cache.delete(cacheKey);
      throw error;
    });

  cache.set(cacheKey, loader);

  return loader;
}

export async function fetchFields(projectToken, pbcToken, formEntityToken, signal, map = {}) {
  const fields = await fetchFieldList(projectToken, pbcToken, formEntityToken, signal);
  set(map, [pbcToken, formEntityToken], fields);

  const results = await Promise.all(
    fields
      .filter(
        (field) =>
          ['ACL', 'SELECT'].includes(field.type) && field.referencePbc && field.referenceEntity,
      )
      .filter((field) => !map[field.referencePbc]?.[field.referenceEntity])
      .map((field) =>
        fetchFields(projectToken, field.referencePbc, field.referenceEntity, signal, map),
      ),
  );

  const maxDepth = Math.max(0, ...results.map(([, , depth]) => depth));

  return [fields, map, maxDepth + 1];
}

export function convertToTreeData(
  fields,
  fieldMap,
  maxDepth,
  currentDepth = 1,
  valuePrefix = '',
  resolver = {},
) {
  const treeData = fields.map((field) => {
    const hasChildren = !!fieldMap[field.referencePbc]?.[field.referenceEntity];

    const subResolver = {};

    resolver[field.token] = {
      label: field.name,
      resolver: subResolver,
    };

    const node = {
      value: `${valuePrefix}${field.token}`,
      label: field.name,
      isLeaf: !hasChildren,
      field,
    };

    const loadChildren = () => {
      node.children = convertToTreeData(
        fieldMap[field.referencePbc]?.[field.referenceEntity] || [],
        fieldMap,
        maxDepth,
        currentDepth + 1,
        `${valuePrefix}${field.token}.`,
        subResolver,
      )[0];
    };

    if (hasChildren) {
      if (currentDepth <= maxDepth) {
        loadChildren();
      } else {
        node.loadChildren = loadChildren;
      }
    }

    return node;
  });

  return [treeData, resolver];
}

export function extractTreeDataKeys(treeData) {
  return (
    treeData
      ?.filter((x) => !x.isLeaf && x.children)
      ?.flatMap((x) => [x.value, ...extractTreeDataKeys(x.children)]) || []
  );
}
