import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../Icon';

function ExplainButton(props) {
  const { disabled, generating, explained, onStop, onGenerate } = props;
  const { t } = useTranslation(['icp-common']);
  const buttonTitle = explained ? t('re-explain') : t('explain');

  return (
    <div className="explain-button-container">
      <Button
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        disabled={disabled}
        size="large"
        shape={generating ? 'circle' : null}
        onClick={generating ? onStop : onGenerate}
      >
        {generating ? <Icon name="ant:pause-outlined" size={20} /> : buttonTitle}
      </Button>
    </div>
  );
}

ExplainButton.propTypes = {
  disabled: PropTypes.bool,
  generating: PropTypes.bool,
  explained: PropTypes.bool,
  onStop: PropTypes.func,
  onGenerate: PropTypes.func,
};

export default ExplainButton;
