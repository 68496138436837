import PropTypes from 'prop-types';
import { Result } from 'antd';

export default function ErrorPage({ title, subTitle, status }) {
  return <Result status={status} title={title} subTitle={subTitle} />;
}

ErrorPage.propTypes = {
  title: PropTypes.node,
  subTitle: PropTypes.node,
  status: PropTypes.oneOf(['success', 'error', 'info', 'warning', 404, 403, 500]),
};
