import PropTypes from 'prop-types';
import { cloneElement, isValidElement, useEffect, useRef } from 'react';
import { useForkRef } from '@icp/hooks';
import { composeEvent } from '@icp/utils';

function findMayBeTapable(rootEl) {
  const elements = Array.from(
    rootEl.querySelectorAll(['input', 'select', 'button', 'a[href]', '[tabindex]'].join(',')),
  );
  const zeroElements = elements.filter((node) => node.tabIndex === 0);
  const sortedElement = elements
    .filter((node) => node.tabIndex > 0)
    .map((node, i) => {
      return {
        documentOrder: i,
        node,
      };
    })
    .sort((a, b) => {
      return a.tabIndex === b.tabIndex
        ? a.documentOrder - b.documentOrder
        : a.tabIndex - b.tabIndex;
    })
    .map((item) => item.node);
  return sortedElement.concat(zeroElements);
}

function FocusTrap(props) {
  const { children, disableAutoFocus, disableEnforceFocus, disableRestoreFocus } = props;

  const startRef = useRef(null);
  const endRef = useRef(null);
  const rootRef = useRef(null);
  const handleRef = useForkRef(children.ref, rootRef);
  const nodeToRestore = useRef(null);

  // Auto focus
  useEffect(() => {
    if (!rootRef.current) {
      return;
    }

    // 如果 root 没有 tableIndex，那在 mouse click root 下空白处的时候焦点会自动跑到 body 去，有 tabIndex root 就会自动捕获焦点。
    if (!rootRef.current.getAttribute('tabindex')) {
      rootRef.current.setAttribute('tabindex', -1);
    }

    if (disableAutoFocus) {
      return;
    }

    if (!rootRef.current.contains(document.activeElement)) {
      rootRef.current.focus();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (!disableRestoreFocus && nodeToRestore.current) {
        nodeToRestore.current.focus();
        nodeToRestore.current = null;
      }
    };
    // 不响应 disableAutoFocus disableRestoreFocus 的变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isValidElement(children)) {
    console.error('FocusTrap children is not an valid react element', children);
    return null;
  }

  const attemptFocus = (el) => {
    el.focus();
    return document.activeElement === el;
  };

  // Trap focus
  const enforce = (event, reverse) => {
    if (disableEnforceFocus) {
      return;
    }

    const mayBeTapable = findMayBeTapable(rootRef.current);

    if (reverse) {
      mayBeTapable.reverse();
    }

    if (!mayBeTapable.length) {
      rootRef.current.focus();
      return;
    }

    for (const el of mayBeTapable) {
      if (attemptFocus(el)) {
        return;
      }
    }
  };

  const handleFocusStart = (event) => {
    if (nodeToRestore.current === null) {
      nodeToRestore.current = event.relatedTarget;
    }
    enforce(event, true);
  };

  const handleFocusEnd = (event) => {
    enforce(event);
  };

  const handleFocusChildren = (event) => {
    if (nodeToRestore.current === null) {
      nodeToRestore.current = event.relatedTarget;
    }
  };

  return (
    <>
      <div className="start" tabIndex={0} onFocus={handleFocusStart} ref={startRef} />
      {cloneElement(children, {
        ref: handleRef,
        onFocus: composeEvent(handleFocusChildren, children.props.onFocus),
      })}
      <div className="end" tabIndex={0} onFocus={handleFocusEnd} ref={endRef} />
    </>
  );
}

FocusTrap.propTypes = {
  children: PropTypes.element,
  disableAutoFocus: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
};

export default FocusTrap;
