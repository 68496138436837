import './DiffView.css';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';
import { loadMonaco } from '@icp/utils';
import Loading from '../Loading';

const DiffView = forwardRef(function DiffView(props, ref) {
  const { language = 'json', readOnly, original, modified } = props;

  const codeContainer = useRef(null);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!original && !modified) {
      editorRef.current = null;
      return;
    }

    if (editorRef.current) {
      editorRef.current.getModel().original.setValue(JSON.stringify(original.code, null, 2));
      editorRef.current.getModel().modified.setValue(JSON.stringify(modified.code, null, 2));
      return;
    }

    loadMonaco().then((monaco) => {
      const originalModel = monaco.editor.createModel(
        JSON.stringify(original.code, null, 2),
        language,
      );
      const modifiedModel = monaco.editor.createModel(
        JSON.stringify(modified.code, null, 2),
        language,
      );

      editorRef.current = monaco.editor.createDiffEditor(codeContainer.current, {
        readOnly,
        automaticLayout: true,
        lineNumbersMinChars: 1,
        glyphMargin: true,
        renderLineHighlight: 'all',
        scrollBeyondLastLine: false,
        padding: {
          bottom: 18,
        },
      });
      editorRef.current.setModel({
        original: originalModel,
        modified: modifiedModel,
      });

      if (typeof ref === 'function') {
        ref(editorRef.current);
      } else if (ref) {
        ref.current = editorRef.current;
      }
    });
  }, [original, modified, language, readOnly, ref]);

  if (!original && !modified) {
    return <Loading />;
  }

  return (
    <div className="icp-diff-view">
      <div className="icp-diff-view-title">
        <div>{original.name}</div>
        <div>{modified.name}</div>
      </div>
      <div className="icp-diff-view-code" ref={codeContainer} />
    </div>
  );
});

DiffView.propTypes = {
  language: PropTypes.string,
  readOnly: PropTypes.bool,
  original: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.shape({}),
  }),
  modified: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.shape({}),
  }),
};

export default DiffView;
