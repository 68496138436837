export const makeDonutOption = () => {
  return {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      type: 'scroll',
    },
    series: [],
  };
};

export const makeDonutSeries = () => {
  return {
    type: 'pie',
    radius: ['40%', '70%'],
    itemStyle: {
      borderRadius: 4,
      borderColor: '#fff',
      borderWidth: 2,
    },
  };
};
