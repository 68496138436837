// 相对路径视为同源
export function isSameOrigin(url1, url2) {
  try {
    const l = new URL(url1);
    const r = new URL(url2);
    return l.origin === r.origin;
  } catch (err) {
    // console.log(err);
    return true;
  }
}

function normalizePathname(pathname) {
  return pathname.replace(/\/+$/, '').replace(/^\/*/, '/');
}

export function joinPaths(paths) {
  return normalizePathname(paths.join('/').replace(/\/\/+/g, '/'));
}

export function getSearchParam(key) {
  if (!key) return undefined;
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}

export function updateSearchParam(key, value, falseAsUndefined = true) {
  if (!key) return;
  const searchParams = new URLSearchParams(window.location.search);
  if (
    value !== undefined &&
    value !== null &&
    value !== '' &&
    !(falseAsUndefined && value === false)
  ) {
    searchParams.set(key, value);
  } else {
    searchParams.delete(key);
  }

  const suffix = `${searchParams.toString()}${window.location.hash}`;

  window.history.replaceState({}, '', suffix ? `?${suffix}` : window.location.pathname);
}

export function getSearchParams() {
  const searchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(searchParams.entries());
}
