import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import PasswordAnt from './PasswordAnt';
import PasswordMaterial from './PasswordMaterial';

const PasswordUI = forwardRef(function InputUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <PasswordMaterial {...props} ref={ref} />;
  }

  return <PasswordAnt {...props} ref={ref} />;
});

export default PasswordUI;
