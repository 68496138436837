import { shouldPolyfill } from '@formatjs/intl-getcanonicallocales/should-polyfill';

export async function loadIntlGetCanonicalLocales() {
  // This platform already supports Intl.getCanonicalLocales
  if (shouldPolyfill()) {
    await import(
      /* webpackChunkName: "polyfill/intl-getcanonicallocales/polyfill" */
      '@formatjs/intl-getcanonicallocales/polyfill'
    );
  }
}
