import {
  FIELD_TYPE_COLLAPSE_ITEM,
  FIELD_TYPE_FORM,
  FIELD_TYPE_STEP,
  FIELD_TYPE_TAB,
  FIELD_TYPE_TABLE_COLUMN,
} from '../constant';

function highlightContainer({ container }) {
  const containerRect = container.getBoundingClientRect();
  // designer 里是把 .fd-canvas 当成 form 设置用的，.fd-canvas 可能会出现滚动条
  const scrollbarWidth = container.offsetWidth - container.clientWidth;
  return {
    width: containerRect.width - scrollbarWidth,
    height: container.scrollHeight,
    left: 0,
    top: 0,
  };
}

function highlightField({ container, keyPath }) {
  const fieldTarget = container.parentNode.querySelector(`[data-key-path="${keyPath}"]`);
  const fieldRect = fieldTarget.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const { width, height, left, top } = fieldRect;

  // GridLayout 为了解决 scrollbar 问题，会有 margin-left 以及 margin-top
  const leftOffset = fieldTarget.classList.contains('grid-layout')
    ? parseInt(getComputedStyle(fieldTarget).getPropertyValue('margin-left'), 10) || 0
    : 0;
  const topOffset = fieldTarget.classList.contains('grid-layout')
    ? parseInt(getComputedStyle(fieldTarget).getPropertyValue('margin-top'), 10) || 0
    : 0;

  return {
    width: width + leftOffset,
    height: height + topOffset,
    left: left - containerRect.left + container.scrollLeft - leftOffset,
    top: top - containerRect.top + container.scrollTop - topOffset,
  };
}

function highlightTableColumn({ container, keyPath, colId }) {
  const fieldTarget = container.parentNode.querySelector(`[data-key-path="${keyPath}"]`);
  const fieldRect = fieldTarget.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const cellTarget = fieldTarget.querySelector(`.ag-header-cell[col-id="${colId}"]`);

  if (!cellTarget) return {};

  const cellRect = cellTarget.getBoundingClientRect();

  const agGrid = fieldTarget.querySelector('.ag-root-wrapper');
  const statusBar = agGrid.querySelector('.ag-status-bar');
  const scrollbar = agGrid.querySelector('.ag-body-horizontal-scroll');

  const visibleWidth = Math.max(
    0,
    Math.min(cellRect.right, fieldRect.right) - Math.max(cellRect.left, fieldRect.left),
  );
  const { height: tableHeight } = agGrid.getBoundingClientRect();
  const statusBarHeight = statusBar?.offsetHeight || 0;
  const scrollbarHeight = scrollbar?.offsetHeight || 0;

  return {
    width: visibleWidth,
    height: tableHeight - statusBarHeight - scrollbarHeight,
    left: Math.max(cellRect.left, fieldRect.left) - containerRect.left + container.scrollLeft,
    top: cellRect.top - containerRect.top + container.scrollTop - 1, // 1px grid header border
  };
}

function highlightItem({ container, keyPath, itemIndex, selector }) {
  const fieldTarget = container.parentNode.querySelector(`[data-key-path="${keyPath}"]`);
  const containerRect = container.getBoundingClientRect();
  const stepTarget = fieldTarget.querySelectorAll(selector)[itemIndex];

  if (!stepTarget) return {};

  const stepRect = stepTarget.getBoundingClientRect();

  const { width, height, left, top } = stepRect;

  return {
    width,
    height,
    left: left - containerRect.left + container.scrollLeft,
    top: top - containerRect.top + container.scrollTop,
  };
}

export default function computeBoxStyle(container, params) {
  if (!container) {
    return {};
  }

  const { type, keyPath, colId, itemIndex } = params;

  if (type === FIELD_TYPE_FORM) {
    return highlightContainer({ container });
  }

  const fieldTarget = container.parentNode?.querySelector(`[data-key-path="${keyPath}"]`);

  if (!fieldTarget) return {};

  if (type === FIELD_TYPE_TABLE_COLUMN) {
    return highlightTableColumn({ container, keyPath, colId });
  }

  if (type === FIELD_TYPE_STEP) {
    return highlightItem({ container, keyPath, itemIndex, selector: '.icp-step' });
  }

  if (type === FIELD_TYPE_COLLAPSE_ITEM) {
    return highlightItem({ container, keyPath, itemIndex, selector: '.ant-collapse-item' });
  }

  if (type === FIELD_TYPE_TAB) {
    return highlightItem({ container, keyPath, itemIndex, selector: '.ant-tabs-tab' });
  }

  return highlightField({ container, keyPath });
}
