import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator } from '../FormRenderCtx';

const BoxLayout = forwardRef(function BoxLayout(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const { style: layoutStyle, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        {...otherComponentProps}
        className={clsx(
          'box-layout',
          'form-layout',
          {
            'no-children': noChildren,
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...layoutStyle }}
        ref={nodeRef}
      >
        {children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />}
      </div>
    </ElementDecorator>
  );
});

BoxLayout.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * Root div 的 className
     */
    className: PropTypes.string,
    /**
     * Root div 的 style
     */
    style: PropTypes.shape({}),
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
BoxLayout.displayName = 'Box';

export default withFieldWrapper(BoxLayout);
