export function hookFunc(fn, { callBefore, callAfter }) {
  return function inner(...args) {
    callBefore?.apply(this);
    const output = fn.apply(this, args);
    callAfter?.apply(this);
    return output;
  };
}

export function pipe(...funcs) {
  return (x) => funcs.reduce((res, fn) => fn(res), x);
}

export function compose(...funcs) {
  return (x) => funcs.reduceRight((res, fn) => fn(res), x);
}
