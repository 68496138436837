import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { composeEvent } from '@icp/utils';
import { HELPER_TEXT_TYPES, helpersIsEmpty, selectContext } from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { shouldTranslateByDefault } from '@icp/settings';
import { useElementDecorator } from '../../FormRenderCtx';
import { ConditionalPropertyPropType, DataFiltersType } from '../../propTypes';
import {
  useClassName,
  useDataFilters,
  useVariablePattern,
  useVariablePatternArray,
} from '../../hooks';
import { getACLUrl, toComponentValue, toInterfaceValue, legacyColumnDefs } from './utils';
import COLUMN_TYPES from '../TableElement/columnTypes';
import { cellRenderMapping } from '../TableElement/cellRenders';
import { cellEditorMapping } from '../TableElement/cellEditors';
import { useStore } from '../../store';
import { DEFAULT_COL_DEF } from '../TableElement';
import { withFieldWrapper } from '../../fieldWrapper';
import ACLUI from './ACLUI';

const ACLElement = forwardRef(function ACLElement(props, ref) {
  const { t } = useTranslation(['icp-form-renderer', 'icp-common']);

  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value: valueProp,
    disabled,
    readonly,
    validation,
    status,
    helpers,
    style,
    componentProps = {},
    fieldTitleProps,
    unit: deprecatedUnit,
    onChange,
    onTouchChanged,
  } = props;

  const {
    idListUrl: idListUrlProp,
    dataUrl: dataUrlProp,
    dataResponseKeyPath = 'results',
    translateDataResponse = shouldTranslateByDefault(),
    dataSource,
    dataFilters,
    dataExclusion: dataExclusionProp,
    unit: unitProp,
    mapping = { value: 'value', label: 'label' },
    useOriginValue = false,
    columnDefs: columnDefsProp,
    columns,
    multiple,
    placeholder = t('acl.placeholder'),
    supportImport = false,
    supportExport = false,
    stringEqual = true,
    exportColumns,
    transformKey,
    transformUrl,
    maxSizePerReq = 1000,
    AgTableProps,
    ...otherComponentProps
  } = componentProps;

  const store = useStore();

  const ElementDecorator = useElementDecorator();
  const context = selectContext(store.getState());

  const nodeRef = useRef(null);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const outerFilterModel = useDataFilters(dataFilters);
  const dataUrlPropResolved = useVariablePattern(dataUrlProp);
  const dataExclusion = useVariablePatternArray(dataExclusionProp);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (deprecatedUnit) {
        console.warn(`'unit' is deprecated, use 'componentProps.unit' instead`);
      }
      if (columns) {
        console.warn(`'columns' is deprecated, use 'componentProps.columnDefs' instead`);
      }
    }
  }, [columns, deprecatedUnit]);

  const { idListUrl, dataUrl } = getACLUrl(
    context,
    mapping,
    dataSource,
    idListUrlProp,
    dataUrlPropResolved,
  );

  const columnDefs = useMemo(() => {
    return legacyColumnDefs(columnDefsProp, columns);
  }, [columnDefsProp, columns]);

  const unit = unitProp || deprecatedUnit || t('acl.unit');

  const value = useMemo(
    () => toComponentValue(valueProp, mapping, useOriginValue),
    [mapping, useOriginValue, valueProp],
  );

  const handleChange = (newValue) => {
    onChange(toInterfaceValue(newValue, mapping, useOriginValue));
    if (onTouchChanged) {
      onTouchChanged();
    }
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'acl-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <ACLUI
          componentProps={{
            ...otherComponentProps,
            className: classNameComp,
          }}
          id={id}
          title={title}
          value={value}
          unit={unit}
          disabled={disabled}
          readonly={readonly}
          fieldTitleProps={fieldTitleProps}
          validation={validation}
          status={status}
          helpers={helpers}
          idListUrl={idListUrl}
          dataUrl={dataUrl}
          isStandardDataSource={!!dataSource}
          dataResponseKeyPath={dataResponseKeyPath}
          translateDataResponse={translateDataResponse}
          outerFilterModel={outerFilterModel}
          dataExclusion={dataExclusion}
          mapping={mapping}
          columnDefs={columnDefs}
          multiple={multiple}
          placeholder={placeholder}
          supportImport={supportImport}
          supportExport={supportExport}
          stringEqual={stringEqual}
          exportColumns={exportColumns}
          transformKey={transformKey}
          transformUrl={transformUrl}
          maxSizePerReq={maxSizePerReq}
          AgTableProps={{
            ...AgTableProps,
            defaultColDef: DEFAULT_COL_DEF,
            columnTypes: COLUMN_TYPES,
            components: { ...cellRenderMapping, ...cellEditorMapping },
            context: { store: store.getState() },
          }}
          onChange={composeEvent(handleChange, otherComponentProps.onChange)}
        />
      </div>
    </ElementDecorator>
  );
});

ACLElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  /**
   * @deprecated unit now in componentProps
   */
  unit: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 选择器的单位
     */
    unit: PropTypes.string,
    /**
     * value 的映射关系，默认是 { value, label }，如果提供了其他 key，会一并进行保存
     * @default { value: 'value', label: 'label' }
     */
    mapping: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      // Other key map to save
    }),
    /**
     * Given mapping: `{ value: 'id', label: 'name' }`,
     * When useOriginValue is `true`, value would be `[{ id: 'xxx',name: 'xxx' }]`,
     * When useOriginValue is `false`, value would be `[{ value: 'xxx',label: xxx' }]`,
     * @default false
     */
    useOriginValue: PropTypes.bool,
    /**
     * 如果提供了 `idListUrl`，则表示此 ACL 使用 server side lazy loading 的形式加载数据，
     * 先通过 `idListUrl` 获取所有数据的 id，然后当滚动条往下滚动的时候，再根据显示窗口的 id 通过 `dataUrl` 属性发请求获取详细数据
     */
    idListUrl: PropTypes.string,
    /**
     * 当不提供 `idListUrl` 的时候，ACL 通过 `dataUrl` 一次性获取所有数据；
     * 当提供 `idListUrl` 的时候，ACL 通过 `dataUrl` 根据 id 获取特定某些行的详细数据
     */
    dataUrl: PropTypes.string,
    /**
     * 也可以指定 formEntity 的数据源作为 ACL 的 data source
     */
    dataSource: PropTypes.shape({
      listUrl: PropTypes.string,
      token: PropTypes.string,
    }),
    dataResponseKeyPath: PropTypes.string,
    translateDataResponse: PropTypes.bool,
    /**
     * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变
     */
    dataFilters: DataFiltersType,
    /**
     * 在 api 返回的数据中排除某些数据
     */
    dataExclusion: PropTypes.arrayOf(PropTypes.string),
    /**
     * 是否多选
     * @default fasle
     */
    multiple: PropTypes.bool,
    /**
     * ACL table 中列的定义，支持 ag-grid 的 `columnDefs`
     */
    columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * @deprecated
     */
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string,
      }),
    ),
    /**
     * input 元素的 placeholder
     */
    placeholder: PropTypes.string,
    /**
     * 是否支持导入
     * @default false
     */
    supportImport: PropTypes.bool,
    /**
     * 导入的时候每个请求的最大行数
     * @default 1000
     */
    maxSizePerReq: PropTypes.number,
    /**
     * 是否支持导出
     */
    supportExport: PropTypes.bool,
    /**
     * export 的时候导出的列
     */
    exportColumns: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    /**
     * 转换字段, import时excel里没主键`mapping.value`, 但是有转换字段, 通过转换字段call转换请求地址拿主键
     */
    transformKey: PropTypes.string,
    /**
     * 转换请求地址，固定post，body格式固定
     * {
     *   key: <转换字段>,
     *   values: Array<转换字段值>
     * }
     */
    transformUrl: PropTypes.string,
    /**
     * 在比较选项的时候，是否转换成字符串进行比较
     * @default true
     */
    stringEqual: PropTypes.bool,
    /**
     * 传给 AgTable 的属性
     */
    AgTableProps: PropTypes.shape({}),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ACLElement.displayName = 'ACL';

export default withFieldWrapper(ACLElement);
