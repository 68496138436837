import { useAppContext } from '@icp/app';
import { useEffect } from 'react';

export default function useRegisterFormApi(formApi, store) {
  const { formService } = useAppContext();

  useEffect(() => {
    const unregister = formService.register({ formApi, store });

    return () => {
      unregister();
    };
  }, [formService, formApi, store]);
}
