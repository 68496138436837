import './Draggable.css';
import PropTypes from 'prop-types';
import { cloneElement, isValidElement } from 'react';
import { useEventCallback } from '@icp/hooks';
import { composeEvent } from '@icp/utils';

function Draggable(props) {
  const { children, threshold = 1, onDragStart, onDrag, onDragEnd, disableDrag = false } = props;

  const disableTextSelect = () => {
    document.body.classList.add('icp-disable-text-select');
  };

  const enableTextSelect = () => {
    document.body.classList.remove('icp-disable-text-select');
  };

  const handleMouseDown = useEventCallback((downEvent) => {
    if (downEvent.button !== 0) {
      return;
    }

    const mouseStart = {
      x: downEvent.clientX,
      y: downEvent.clientY,
    };
    let mouseMove = { x: 0, y: 0 };
    let isMoved = false;

    const handleMouseMove = (event) => {
      mouseMove = {
        x: event.clientX - mouseStart.x,
        y: event.clientY - mouseStart.y,
      };

      if (!isMoved) {
        if (Math.abs(mouseMove.x) < threshold && Math.abs(mouseMove.y) < threshold) {
          return;
        }
        isMoved = true;

        if (onDragStart) {
          // 传 mousedown 的 event 让外面在获取 event.clientX 拿到的是鼠标按下时的位置
          onDragStart(downEvent);
        }

        disableTextSelect();
      }

      if (onDrag) {
        onDrag(event, mouseMove);
      }
    };

    const handleMouseUp = (event, isCancel) => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleKeyDown, true);

      if (!isMoved) {
        return;
      }

      isMoved = false;

      // esc 调用的 handleMouseUp 没有 clientX
      mouseMove =
        event.clientX !== undefined
          ? {
              x: event.clientX - mouseStart.x,
              y: event.clientY - mouseStart.y,
            }
          : mouseMove;

      if (onDragEnd) {
        onDragEnd(event, mouseMove, !!isCancel);
      }

      enableTextSelect();
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        handleMouseUp(event, true);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keydown', handleKeyDown, true); // true 才能禁止掉 react 的事件冒泡
  });

  if (disableDrag) {
    return children;
  }

  if (!isValidElement(children)) {
    console.error('Draggable children is not an valid react element', children);
    return null;
  }

  return cloneElement(children, {
    onMouseDown: composeEvent(handleMouseDown, children.props.onMouseDown),
  });
}

Draggable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  disableDrag: PropTypes.bool,
};

export default Draggable;
