import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import CheckboxMaterial from './CheckboxMaterial';
import CheckboxAnt from './CheckboxAnt';

const CheckboxUI = forwardRef(function CheckboxUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <CheckboxMaterial {...props} ref={ref} />;
  }

  return <CheckboxAnt {...props} ref={ref} />;
});

export default CheckboxUI;
