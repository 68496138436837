import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { useSelector } from '../../../store';
import { renderReadonlyText } from '../../../utils';

function InputReadonlyText(props) {
  const { id, value: valueProp, allowI18nValue, ...other } = props;

  const { i18n } = useTranslation();

  const valueI18n = useSelector(selectFieldValueI18n(id, i18n.language));

  // 当 valueI18n 里没有对应语言的值的时候，fallback 取 valueProp 的值 (兼容老数据)
  const value = (!allowI18nValue ? valueProp : valueI18n ?? valueProp) ?? '';

  return renderReadonlyText(other, value);
}

InputReadonlyText.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  allowI18nValue: PropTypes.bool,
};

export default InputReadonlyText;
