import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  analyze,
  buildAnalysisMessage,
  buildMultipleSelectMessage,
  buildSingleSelectMessage,
  handleGetContentForContext,
  loadPageSchema,
  messageFactory,
  resetUIData,
  storageKey,
} from './utils';
import configuration from './configuration.json';
import {
  cacheData,
  createMessage,
  MessageType,
  pendingMessageTypes,
  restoreData,
} from '../../components/Chat/utils';
import Chat from '../../components/Chat';

const Phase = {
  Conversational: -2,
  Initial: -1,
  Triggered: 0,
};

function findLastPendingMessage(restoredItem) {
  const restoredLastMessage = restoredItem.messages?.slice(-1)[0];
  return pendingMessageTypes.includes(restoredLastMessage?.type) && restoredLastMessage;
}

function SaleConsultationAI() {
  const restoredItem = useMemo(() => {
    return restoreData(storageKey);
  }, []);

  const { trigger: originalTrigger, definition } = configuration;
  const trigger = restoredItem.trigger || originalTrigger;
  const [index, setIndex] = useState(restoredItem.index ?? Phase.Initial);
  const lastPendingMessage = useRef(findLastPendingMessage(restoredItem));
  const resultMap = useRef(restoredItem.resultMap || {});
  const { t } = useTranslation();
  const analyzing = t('chat.analyzing');
  const chatRef = useRef();

  const handleStartNewChat = () => {
    chatRef.current.startNewChat();
    setIndex(Phase.Initial);
    trigger.triggerred = false;
    lastPendingMessage.current = null;
    resultMap.current = {};
  };

  const handleSelection = (id, result) => {
    const currentMessage = lastPendingMessage.current;
    const findItem = (option) => currentMessage.data.find((item) => item.id === option.value);
    const data = Array.isArray(result) ? result.map(findItem) : findItem(result);
    resultMap.current[id] = { data, result };
    lastPendingMessage.current.isHidden = true;
    lastPendingMessage.current = null;
    const question = createMessage(MessageType.PlainText, currentMessage.content.title);
    const answer = createMessage(
      MessageType.PlainText,
      Array.isArray(result) ? result.map((item) => item.label).join('\n') : result.label,
      true,
    );
    appendMessages(question, answer);
    setIndex(index + 1);
  };

  const handleSendMessage = (textMessage, files) => {
    if (textMessage.trim() === '') {
      return;
    }
    const { type, value } = trigger;
    if (type === 'contains' && textMessage.includes(value) && !trigger.triggerred) {
      const message = createMessage(MessageType.PlainText, textMessage, true);
      appendMessages(message);
      trigger.triggerred = true;
      setIndex(Phase.Triggered);
    } else {
      chatRef.current.sendMessage(textMessage, files);
      setIndex(Phase.Conversational);
    }
  };

  function setInputDisabled(disabled) {
    chatRef.current.setInputDisabled(disabled);
  }

  function appendMessages(...messages) {
    chatRef.current.appendMessages(...messages);
  }

  useEffect(() => {
    if (index < Phase.Triggered) {
      return;
    }
    const { pbcToken, pageToken } = definition;
    loadPageSchema(pbcToken, pageToken).then((schema) => {
      if (index >= schema.fields.length) {
        setInputDisabled(false);
        return;
      }
      setInputDisabled(true);
      if (index >= Phase.Triggered && lastPendingMessage.current) {
        return;
      }
      const currentField = schema.fields[index];
      const componentProps = currentField.componentProps;
      if (currentField.component === 'Select' && componentProps.mode !== 'multiple') {
        buildSingleSelectMessage(currentField, resultMap.current).then((message) => {
          lastPendingMessage.current = message;
          appendMessages(message);
        });
      } else if (currentField.component === 'Table' && componentProps.rowSelection === 'multiple') {
        buildMultipleSelectMessage(currentField, resultMap.current).then((message) => {
          lastPendingMessage.current = message;
          appendMessages(message);
        });
      } else if (currentField.id === 'analyze' && componentProps.href) {
        lastPendingMessage.current = createMessage(MessageType.Pending, analyzing);
        appendMessages(lastPendingMessage.current);
        const { href: link, payload: payloadConfig, useMockResponse } = componentProps;
        analyze(link, payloadConfig, useMockResponse, resultMap.current).then((analysis) => {
          resultMap.current.analysis = analysis;
          const message = buildAnalysisMessage(resultMap.current);
          lastPendingMessage.current.isHidden = true;
          lastPendingMessage.current = null;
          appendMessages(message);
          setIndex(index + 1);
        });
      }
    });
  }, [analyzing, definition, index]);

  const handleCacheData = (cacheKey, content) => {
    const newMessages = content.messages
      .map((message) => {
        if (message.type.startsWith('customized')) {
          message.content = { props: message.content.props };
        }
        return message;
      })
      .filter((message) => !message.isHidden);
    const item = {
      ...content,
      trigger,
      index,
      messages: newMessages,
      resultMap: resultMap.current,
    };
    cacheData(cacheKey, item);
  };

  return (
    <div className="sale-consultation-ai">
      <Chat
        cacheKey={storageKey}
        initialData={restoredItem}
        onCacheData={handleCacheData}
        onClearCache={resetUIData}
        onSendMessage={handleSendMessage}
        onStartNewChat={handleStartNewChat}
        onGetContentForContext={handleGetContentForContext}
        messageEventHandlers={{ onSelect: handleSelection }}
        messageFactory={messageFactory}
        ref={chatRef}
      />
    </div>
  );
}

export default SaleConsultationAI;
