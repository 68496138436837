import FormRenderer, {
  InputElement,
  PasswordElement,
  SelectElement,
  StackLayout,
} from '@icp/form-renderer';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { restApi, message } from '@icp/settings';
import { CodeEditor } from '@icp/components';
import { findParentNode } from '@icp/utils';
import { toApiData, toCompData } from './utils';

function ConnectorDialog(props) {
  const { pbcId, connector, connectorTypeList, onSuccess, onClose } = props;

  const { t } = useTranslation(['icp-app']);

  const [submitting, setSubmitting] = useState(false);
  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState(null);

  const formRef = useRef(null);
  const editorRef = useRef(null);

  const resultStr = useMemo(() => JSON.stringify(testResult, null, 2), [testResult]);

  useEffect(() => {
    editorRef.current?.setValue(resultStr);
  }, [resultStr]);

  const createConnector = (data) => {
    return restApi.post('/flow/api/connector', data);
  };

  const updateConnector = (data) => {
    return restApi.put(`/flow/api/connector/${data.id}`, data);
  };

  const handleOk = () => {
    formRef.current.submit();
  };

  const handleSubmit = (formData) => {
    const data = toApiData(formData);
    setSubmitting(true);

    if (connector) {
      return updateConnector(data)
        .then((res) => {
          onSuccess(res);
        })
        .finally(() => setSubmitting(false));
    }

    return createConnector(data)
      .then((res) => {
        onSuccess(res);
      })
      .finally(() => setSubmitting(false));
  };

  const handleTest = () => {
    const formData = formRef.current.getData();
    const data = toApiData(formData);
    setTesting(true);

    const scrollToBottom = () => {
      const scrollDiv = findParentNode(formRef.current.node, 'ant-modal-body');
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    };

    restApi
      .post('/flow/api/connector/do-ping', data)
      .then(
        (res) => {
          setTestResult(res);
          message.success(t('connector.test-success'));
        },
        (err) => {
          setTestResult(err);
        },
      )
      .finally(() => {
        setTimeout(() => {
          scrollToBottom();
        }, 16);
        setTesting(false);
      });
  };

  const defaultData = useMemo(() => {
    return {
      pbcId,
      ...toCompData(connector || {}),
    };
  }, [connector, pbcId]);

  return (
    <Modal
      open={true}
      destroyOnClose={true}
      centered={true}
      title={connector ? t('connector.edit') : t('connector.add')}
      width={600}
      onOk={handleOk}
      onCancel={onClose}
      confirmLoading={submitting}
    >
      <FormRenderer
        className="icp-dialog-form"
        defaultData={defaultData}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <StackLayout componentProps={{ gap: 12, flexDirection: 'column' }}>
          <InputElement id="name" title={t('connector.name')} validation={{ required: true }} />
          <SelectElement
            id="connectorTypeUuid"
            title={t('connector.connector-type')}
            componentProps={{
              valueType: 'value',
              options: connectorTypeList?.map((item) => ({
                value: item.uuid,
                label: item.name,
              })),
            }}
          />
          <InputElement id="baseUrl" title="baseUrl" />
          <SelectElement
            id="authType"
            title="Authorization Type"
            defaultValue="BASIC_AUTH"
            componentProps={{
              valueType: 'value',
              options: [
                {
                  value: 'BASIC_AUTH',
                  label: 'Basic Auth',
                },
                {
                  value: 'API_KEY',
                  label: 'API Key',
                },
                {
                  value: 'OAUTH_2',
                  label: 'OAuth 2.0',
                },
              ],
            }}
          />
          <SelectElement
            id="addTo"
            title="Add authorization data to"
            defaultValue="HEADER"
            componentProps={{
              valueType: 'value',
              options: [
                {
                  value: 'HEADER',
                  label: 'Request Headers',
                },
                {
                  value: 'URL',
                  label: 'Request URL',
                },
              ],
            }}
            hidden={{
              dataPredicate: {
                operator: 'AND',
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'API_KEY',
                  },
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="headerPrefix"
            title="Header Prefix"
            defaultValue="Bearer"
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <SelectElement
            id="grantType"
            title="Grant Type"
            defaultValue="PASSWORD"
            componentProps={{
              valueType: 'value',
              options: [
                {
                  value: 'PASSWORD',
                  label: 'Password Credentials',
                },
                {
                  value: 'CLIENT_CREDENTIALS',
                  label: 'Client Credentials',
                },
              ],
            }}
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="accessTokenURL"
            title="Access Token URL"
            fieldTitleProps={{
              tooltip:
                'The endpoint for authentication server. This is used to exchange the authorization code for an access token.',
            }}
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="clientID"
            title="Client ID"
            fieldTitleProps={{
              tooltip:
                'The client identifier issued to the client during the Application registration process.',
            }}
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="clientSecret"
            title="Client Secret"
            fieldTitleProps={{
              tooltip:
                'The client secret issued to the client during the Application registration process.',
            }}
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="username"
            title="Username"
            hidden={[
              {
                value: false,
                matched: {
                  dataPredicate: {
                    operator: 'AND',
                    conditions: [
                      {
                        field: ':authType',
                        condition: 'equals',
                        value: 'BASIC_AUTH',
                      },
                    ],
                  },
                },
              },
              {
                value: false,
                matched: {
                  dataPredicate: {
                    operator: 'AND',
                    conditions: [
                      {
                        field: ':authType',
                        condition: 'equals',
                        value: 'OAUTH_2',
                      },
                      {
                        field: ':grantType',
                        condition: 'equals',
                        value: 'PASSWORD',
                      },
                    ],
                  },
                },
              },
              true,
            ]}
          />
          <PasswordElement
            id="password"
            title="Password"
            hidden={[
              {
                value: false,
                matched: {
                  dataPredicate: {
                    operator: 'AND',
                    conditions: [
                      {
                        field: ':authType',
                        condition: 'equals',
                        value: 'BASIC_AUTH',
                      },
                    ],
                  },
                },
              },
              {
                value: false,
                matched: {
                  dataPredicate: {
                    operator: 'AND',
                    conditions: [
                      {
                        field: ':authType',
                        condition: 'equals',
                        value: 'OAUTH_2',
                      },
                      {
                        field: ':grantType',
                        condition: 'equals',
                        value: 'PASSWORD',
                      },
                    ],
                  },
                },
              },
              true,
            ]}
          />
          <InputElement
            id="scope"
            title="Scope"
            fieldTitleProps={{
              tooltip:
                'The scope of the access request. It may have multiple space-delimited values.',
            }}
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <SelectElement
            id="clientAuthentication"
            title="Client Authentication"
            defaultValue="SEND_AS_BASIC_AUTH_HEADER"
            fieldTitleProps={{
              tooltip:
                'This client authentication method will be ignored if you ve added client_id or client_secret in the advanced options below.',
            }}
            componentProps={{
              valueType: 'value',
              options: [
                {
                  value: 'SEND_AS_BASIC_AUTH_HEADER',
                  label: 'Send as Basic Auth header',
                },
                {
                  value: 'SEND_CLIENT_CREDENTIALS_IN_BODY',
                  label: 'Send client credentials in body',
                },
              ],
            }}
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'OAUTH_2',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="key"
            title="Key"
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'API_KEY',
                  },
                ],
              },
            }}
          />
          <InputElement
            id="value"
            title="Value"
            hidden={{
              dataPredicate: {
                conditions: [
                  {
                    field: ':authType',
                    condition: 'notEqual',
                    value: 'API_KEY',
                  },
                ],
              },
            }}
          />
          <InputElement id="testPath" title="Test Path" />
          <div>
            <Button type="primary" onClick={handleTest} loading={testing}>
              {t('connector.test')}
            </Button>
          </div>
          {testResult ? (
            <CodeEditor
              language="json"
              readOnly={true}
              bordered={true}
              style={{ height: 200 }}
              ref={editorRef}
            >
              {resultStr}
            </CodeEditor>
          ) : null}
        </StackLayout>
      </FormRenderer>
    </Modal>
  );
}

ConnectorDialog.propTypes = {
  pbcId: PropTypes.number,
  connector: PropTypes.shape({
    authConfigJson: PropTypes.string,
  }),
  connectorTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConnectorDialog;
