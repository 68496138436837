import {
  calc,
  extractDataDependencyIds,
  isValidCalcExpression,
  isVariable,
  resolveVariablePattern,
} from '@icp/utils';
import { findI18nValues } from '@icp/i18n';

function extractDependencyIds(valueExpression) {
  const keyPathArray = extractDataDependencyIds(valueExpression);
  return keyPathArray.map((keyPath) => {
    // math keyPath managers[0].name => managers
    return keyPath.match(/[a-zA-Z0-9_]+/)[0];
  });
}

/**
 * keyPath 发生变化，重新计算 valueExpression 中依赖 keyPath 的表达式
 *
 * @param keyPath, 发生变化的值
 * @param values, 作为 currentData，目前的实现只支持 currentData
 * @param valueExpressions，表达式数组
 * @return {*}
 */
export default function computeValueExpressions({ keyPath, values, valueExpressions }) {
  return valueExpressions
    .filter(({ id, valueExpression }) => {
      if (id === keyPath) return false;
      if (typeof valueExpression !== 'string') return false;

      const dependencyIds = extractDependencyIds(valueExpression);
      return dependencyIds.includes(keyPath);
    })
    .map(({ id, valueExpression }) => {
      // Use arithmetic function, valueExpression: 'calc(:count * 10)'
      if (valueExpression.startsWith('calc')) {
        const valueResolved = resolveVariablePattern({
          currentData: values,
          // formData,
          // context,
          // params,
          pattern: valueExpression,
          converter: (v) => v || 0, // 四则运算 calc 的时候将 undefined 和 null 和 '' 转换为 0 计算
        });

        // calc 要求严格，calc 里必须是 number
        if (isValidCalcExpression(valueResolved)) {
          return { id, newValue: calc(valueResolved) };
        }

        console.error(`Invalid calc valueExpression: ${valueResolved}`);
        return null;
      }

      // Directly use variable pattern, valueExpression: ':year - :period[0].label'
      const valueResolved = resolveVariablePattern({
        currentData: values,
        // formData,
        // context,
        // params,
        pattern: valueExpression,
      });

      // 处理多语言的情况，
      // eg: valueExpression 等于 :select[0].label, isVariable() 会返回 true ，
      // 同时select[0] 是一个对象，里面的 label 属性有多语言字段 { label: 'abc', label_i18n_en-US: 'abc-en', label_i18n_zh-CN: 'abc-cn' }，
      // 则 valueExpress 自动把 label_i18n_ 的所有多语言 值都带出去
      let i18nValues = {};
      if (isVariable(valueExpression)) {
        const path = valueExpression.split(':')[1];
        i18nValues = findI18nValues(values, path);
      }

      return { id, newValue: valueResolved, i18nValues };
    })
    .filter(Boolean);
}
