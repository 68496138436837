import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import ACLElement from '../../ACLElement';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';

const ACLCellEditor = forwardRef(function ACLCellEditor(props, ref) {
  const { value: valueProp, colDef, data, context, componentProps, node: nodeProp } = props;

  const [value, setValue] = useState(valueProp);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        // TODO: focus ACL element
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    // set currentData as row data for resolveDataFilters
    <CurrentDataProvider value={data}>
      <ACLElement
        className="table-acl-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{ size: context.tableSize, ...componentProps, clickUseCapture: true }}
      />
    </CurrentDataProvider>
  );
});

ACLCellEditor.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({}),
};

// 验证unique列时使用
ACLCellEditor.getUniqueKey = ({ colDef, value }) => {
  if (!value || !Array.isArray(value)) return JSON.stringify(value);
  const mapping = colDef.cellEditorParams?.componentProps?.mapping;
  const valueKey = colDef.cellEditorParams?.componentProps?.useOriginValue
    ? mapping?.value || 'value'
    : 'value';
  return JSON.stringify(value.map((item) => String(item[valueKey])));
};

export default ACLCellEditor;
