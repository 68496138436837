import { Button } from 'antd';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Icon } from '@icp/components';

const ButtonAnt = forwardRef(function ButtonAnt(props, ref) {
  const { children, icon, size, ...other } = props;

  const iconSize = (size === 'small' && 14) || (size === 'large' && 20) || 16;

  return (
    <Button
      {...other}
      icon={icon ? <Icon name={icon} size={iconSize} /> : null}
      size={size}
      ref={ref}
    >
      {children}
    </Button>
  );
});

ButtonAnt.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  size: PropTypes.string,
};

export default ButtonAnt;
