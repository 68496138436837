import './SuggestionPopover.css';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { HTMLElementType } from '@icp/utils';
import Popover from '../../Popover';
import Loading from '../../Loading';

function SuggestionPopover({ anchorEl, open, onClose, onSelect, getList }) {
  const [list, setList] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const containerRef = useRef();
  const selectedIndexRef = useRef(0);
  selectedIndexRef.current = selectedIndex;

  useEffect(() => {
    if (!open) {
      return () => {
        setList(null);
      };
    }
    const controller = new AbortController();
    const { signal } = controller;

    Promise.resolve(signal)
      .then(getList)
      .then((x) => x || [])
      .then(setList);

    return () => {
      controller.abort();
    };
  }, [getList, open]);

  useEffect(() => {
    if (!open || !list?.length) return () => {};

    const keyDownListener = (event) => {
      event.preventDefault();

      switch (event.key) {
        case 'ArrowDown': {
          setSelectedIndex((prev) => (prev + 1) % list.length);
          break;
        }
        case 'ArrowUp': {
          setSelectedIndex((prev) => (prev - 1 + list.length) % list.length);
          break;
        }
        case 'Enter': {
          containerRef.current
            ?.querySelector(`*:nth-child(${selectedIndexRef.current + 1})`)
            ?.click();
          break;
        }
        default:
          break;
      }
    };
    document.addEventListener('keydown', keyDownListener);
    return () => {
      document.removeEventListener('keydown', keyDownListener);
    };
  }, [open, list?.length]);

  useEffect(() => {
    if (!open) return;
    if (!containerRef.current) return;
    const scrollContainer = containerRef.current;
    const selectedElement = containerRef.current.querySelector(`*:nth-child(${selectedIndex + 1})`);
    const containerRect = scrollContainer.getBoundingClientRect();
    const itemRect = selectedElement.getBoundingClientRect();
    if (itemRect.top < containerRect.top || itemRect.bottom > containerRect.bottom) {
      selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [open, selectedIndex]);

  const handleClick = (form) => {
    onSelect(form);
    onClose();
    setSelectedIndex(0);
  };

  return (
    <Popover
      ref={containerRef}
      className="icp-ai-agent-suggestion-popover-content"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      placement="top"
      disableAutoFocus={true}
    >
      {!list ? (
        <Loading delayed={false} />
      ) : (
        list.map((item, i) => (
          <div
            key={item.token}
            className={clsx('icp-clickable suggestion-item', { selected: i === selectedIndex })}
            onClick={() => handleClick(item)}
            onMouseEnter={() => setSelectedIndex(i)}
          >
            {item.name}
          </div>
        ))
      )}
    </Popover>
  );
}

SuggestionPopover.propTypes = {
  anchorEl: HTMLElementType,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  getList: PropTypes.func,
};

export default SuggestionPopover;
