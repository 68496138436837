import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import { ConditionalPropertyPropType } from '../../propTypes';

function SwitchAnt(props) {
  const {
    id,
    title,
    value,
    disabled,
    componentProps = {},
    fieldTitleProps,
    validation,
    readonly,
    helpers,
    onChange,

    size,
    loading,
  } = props;

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        <div className="row-element">
          <Switch
            {...componentProps}
            size={size}
            name={id}
            checked={value}
            disabled={disabled || readonly}
            loading={loading}
            onChange={onChange}
          />
        </div>
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

SwitchAnt.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({}),
  size: PropTypes.oneOf(['default', 'small']),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  value: PropTypes.bool,
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default SwitchAnt;
