function isStrictNumber(value) {
  return typeof value === 'number' && !Number.isNaN(value);
}

/**
 * Clamp value to within min and max.
 * @param value
 * @param min
 * @param max
 * @returns {number|*}
 * @example
 *
 * clamp(5, 1, 4)
 *
 * // => 4
 */
export default function clamp(value, min, max) {
  if (!isStrictNumber(value) || !isStrictNumber(min) || !isStrictNumber(max)) {
    return value;
  }

  return Math.min(Math.max(min, value), max);
}
