import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@icp/components';
import { isMobile } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import { useElementDecorator } from '../FormRenderCtx';
import { useClassName, useHref, useVariablePattern } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const SuccessElement = forwardRef(function SuccessElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, componentProps = {} } = props;

  const {
    style: componentStyle,
    title: titleProp,
    description: descriptionProp,
    redirectHref: redirectHrefProp,
    buttonContent,
    ...otherComponentProps
  } = componentProps;

  const { t } = useTranslation(['icp-form-renderer']);

  const ElementDecorator = useElementDecorator();
  const navigate = useNavigate();
  const isMobileEnv = isMobile();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const title = useVariablePattern(titleProp);
  const description = useVariablePattern(descriptionProp);
  const redirectHref = useHref({ href: redirectHrefProp });

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const handleOk = () => {
    navigate(redirectHref || -1, { replace: true });
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        {...otherComponentProps}
        className={clsx('success-element form-element', className, classNameComp, {
          'icp-center': isMobileEnv,
          'is-mobile': isMobileEnv,
        })}
        style={{ ...style, ...componentStyle }}
        ref={nodeRef}
      >
        <Icon name="success" size={72} />
        <div>{title}</div>
        <div>{description}</div>
        <Button type="primary" onClick={handleOk}>
          {buttonContent || t('button.complete')}
        </Button>
      </div>
    </ElementDecorator>
  );
});

SuccessElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 成功提示的标题
     */
    title: PropTypes.string,
    /**
     * 成功提示的描述
     */
    description: PropTypes.string,
    /**
     * 点击按钮过后的链接地址
     * @default -1
     */
    redirectHref: ConditionalPropertyPropType(PropTypes.string),
    /**
     * 按钮的内容
     * @default '完成'
     */
    buttonContent: PropTypes.string,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
SuccessElement.displayName = 'Success';

export default withFieldWrapper(SuccessElement);
