import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';

const TimerWrapper = forwardRef(function TimerWrapper(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const {
    style: componentStyle,
    interval,
    ...otherComponentProps
    //
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [times, setTimes] = useState(0);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  useEffect(() => {
    if (isInDesign || interval == null) return () => {};
    const handle = setInterval(() => {
      setTimes((prev) => prev + 1);
    }, interval);

    return () => {
      clearInterval(handle);
    };
  }, [interval, isInDesign]);

  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        {...otherComponentProps}
        key={`timer-${times}`}
        className={clsx(
          'timer-wrapper form-wrapper',
          {
            'in-design': isInDesign,
            'no-children': noChildren,
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...componentStyle }}
        ref={nodeRef}
      >
        {children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />}
      </div>
    </ElementDecorator>
  );
});

TimerWrapper.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    interval: PropTypes.number,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TimerWrapper.displayName = 'Timer';

export default withFieldWrapper(TimerWrapper);
