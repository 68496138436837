import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import TreeSelectMaterial from './TreeSelectMaterial';
import TreeSelectAnt from './TreeSelectAnt';

const TreeSelectUI = forwardRef(function SelectUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <TreeSelectMaterial {...props} ref={ref} />;
  }

  return <TreeSelectAnt {...props} ref={ref} />;
});

export default TreeSelectUI;
