const allFormCode = {};

// code after code generator
(function importS() {
  const r = require.context('@site/src/forms', true, /\.js$/);
  r.keys()
    // filter outer duplicate key caused by webpack.config.js resolve.context
    .filter((key) => key.startsWith('./'))
    .forEach((key) => {
      const lastSlashesIndex = key.lastIndexOf('/');
      const name = key.substring(lastSlashesIndex + 1, key.length - 3); // 3 === '.js'.length
      allFormCode[name] = r(key).default;
    });
})();

export default allFormCode;
