import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ButtonMaterial from './ButtonMaterial';

function DialogMaterial(props) {
  const {
    children,
    className,
    open,
    title,
    size,
    hideFooter,
    okText,
    cancelText,
    okProps,
    cancelProps,
    submitting,
    onOk,
    onClose,

    // antd config
    keyboard,
    maskClosable,
    ...other
  } = props;

  const { t } = useTranslation(['icp-common']);

  const handleClose = (event, reason) => {
    if (reason === 'escapeKeyDown' && keyboard === false) {
      return;
    }
    if (reason === 'backdropClick' && maskClosable === false) {
      return;
    }
    if (onClose) {
      onClose(event);
    }
  };

  return (
    <Dialog
      {...other}
      className={className}
      open={open}
      fullWidth={true}
      maxWidth={size !== 'fullscreen' ? size : 'sm'}
      fullScreen={size === 'fullscreen'}
      onClose={handleClose}
    >
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>{children}</DialogContent>
      {hideFooter ? null : (
        <DialogActions>
          <ButtonMaterial {...cancelProps} onClick={onClose}>
            {cancelText || t('cancel', { ns: 'icp-common' })}
          </ButtonMaterial>
          <ButtonMaterial loading={submitting} variant="contained" {...okProps} onClick={onOk}>
            {okText || t('ok', { ns: 'icp-common' })}
          </ButtonMaterial>
        </DialogActions>
      )}
    </Dialog>
  );
}

DialogMaterial.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'fullscreen']),
  hideFooter: PropTypes.bool,
  okProps: PropTypes.shape({
    icon: PropTypes.string,
  }),
  okText: PropTypes.string,
  cancelProps: PropTypes.shape({
    icon: PropTypes.string,
  }),
  cancelText: PropTypes.string,
  submitting: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  keyboard: PropTypes.bool,
  maskClosable: PropTypes.bool,
};

export default DialogMaterial;
