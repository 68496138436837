import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import SelectMaterial from './SelectMaterial';
import SelectAnt from './SelectAnt';

const SelectUI = forwardRef(function SelectUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <SelectMaterial {...props} ref={ref} />;
  }

  return <SelectAnt {...props} ref={ref} />;
});

export default SelectUI;
