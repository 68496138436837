import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getDisplayLabel } from './utils';

function FluentSelectAllOn24Filled() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M3 6.25A3.25 3.25 0 0 1 6.25 3h9.5A3.25 3.25 0 0 1 19 6.25v9.5A3.25 3.25 0 0 1 15.75 19h-9.5A3.25 3.25 0 0 1 3 15.75zm12.28 2.78a.75.75 0 0 0-1.06-1.06L10 12.19l-1.97-1.97a.75.75 0 1 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0zM6.5 20a3.25 3.25 0 0 0 2.741 1.5h7.005a5.254 5.254 0 0 0 5.254-5.254V9.241A3.25 3.25 0 0 0 19.999 6.5v9.746A3.753 3.753 0 0 1 16.246 20z"
      />
    </svg>
  );
}

function ACLInputMaterial(props) {
  const {
    title,
    readonly,
    required,
    helperText,
    value,
    size,
    disabled,
    placeholder,
    status,
    multiple,
    unit,
    openButtonProps,
  } = props;

  const { t } = useTranslation(['icp-components']);

  return (
    <TextField
      label={title}
      value={getDisplayLabel(value)}
      helperText={helperText}
      disabled={disabled}
      placeholder={placeholder}
      size={size}
      error={status === 'error'}
      required={required}
      InputProps={{
        readOnly: readonly,
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position="end">
            {value?.length && multiple
              ? t('acl.selected', {
                  length: value.length,
                  unit,
                })
              : undefined}
            <IconButton {...openButtonProps} edge="end">
              <FluentSelectAllOn24Filled />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{ width: '100%' }}
    />
  );
}

ACLInputMaterial.propTypes = {
  title: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  status: PropTypes.string,
  multiple: PropTypes.bool,
  unit: PropTypes.string,
  openButtonProps: PropTypes.shape({}),
};

export default ACLInputMaterial;
