import { omit } from 'lodash-es';
import { getLabelOfObjectValue, getValueItem, originToMapping } from '../SelectElement/utils';
import {
  buildTree,
  excludeTreeData,
  formatTreeDataByStringEqual,
} from '../TreeSelectElement/utils';

export const DEFAULT_MAPPING = { value: 'value', label: 'label', children: 'children' };

export function constructOptionsByValue(value, mapping) {
  return [
    [...value].reverse().reduce((prev, curr) => ({
      ...curr,
      [mapping.children]: [prev],
    })),
  ];
}

export function convertToOptionKeyPath(value, options, mapping) {
  if (!options) return [];

  const kp = [];

  function accessItemOf(list, depth = 0) {
    for (const item of list) {
      kp[depth] = item[mapping.value];
      if (item[mapping.value] === value) {
        return 1;
      }
      if (item[mapping.children] && accessItemOf(item[mapping.children], depth + 1)) {
        return 1;
      }
    }
    kp.pop();
    return 0;
  }

  if (accessItemOf(options)) {
    return kp;
  }
  return [];
}

export function formatOptions({
  optionsProp,
  dataFetched,
  valueProp,
  multiple,
  mapping,
  stringEqual,
  buildTreeBy,
  dataExclusion,
}) {
  let treeData = optionsProp || dataFetched;

  // options 没有时, 单选时使用value自身推断出最简options, 多选时无法推断
  if (!Array.isArray(treeData)) {
    if (!multiple && Array.isArray(valueProp) && valueProp.length) {
      return constructOptionsByValue(valueProp, mapping);
    }
    return [];
  }

  if (buildTreeBy) {
    treeData = buildTree(treeData, buildTreeBy);
  }

  if (stringEqual) {
    treeData = formatTreeDataByStringEqual({ treeData, mapping });
  }

  // 树状结构移除节点的同时就会移除掉其所有子孙节点
  if (Array.isArray(dataExclusion) && dataExclusion.length) {
    return excludeTreeData(treeData, dataExclusion, mapping.value);
  }

  return treeData;
}

export function toComponentValue({
  valueProp,
  options,
  multiple,
  mapping,
  useOriginValue,
  stringEqual,
}) {
  // 目前 Cascader 的 value 只能是数组
  if (!Array.isArray(valueProp)) {
    return valueProp;
  }

  const labeledValue = useOriginValue
    ? valueProp.map((item) => originToMapping(item, mapping))
    : valueProp;

  let innerValue;

  // 单选时value是选中项路径数组, 多选时value是选中项路径数组的尾节点
  if (multiple) {
    innerValue = labeledValue.map((v) => convertToOptionKeyPath(v, options, mapping));
  } else {
    innerValue = labeledValue.map((item) => item.value);
  }

  // 转换一下 saved value 为 string，为了保证以后后端修复 stringEqual 的问题后也能显示正确
  if (stringEqual) {
    innerValue = innerValue.map((v) => String(v));
  }

  return innerValue;
}

export function toInterfaceValue({ selectedOptions, multiple, mapping, useOriginValue }) {
  if (!selectedOptions) {
    return selectedOptions;
  }

  // 目前多选是只保存叶子节点，因为产品 form entity data api 不支持 array 嵌套 array
  const optionsToSave = multiple
    ? selectedOptions?.map((arr) => arr[arr.length - 1])
    : selectedOptions;

  return optionsToSave
    .map((option) => getValueItem(option, mapping, useOriginValue))
    .map((op) => omit(op, 'children'));
}

export function getDisplayText({ valueProp, mapping, useOriginValue }) {
  if (!Array.isArray(valueProp)) {
    return '';
  }

  // 目前 Cascader 的 value 只能是数组嵌套对象的 [{ value, label }] 格式，不支持像 Select 和 TreeSelect 一样的多种 valueType
  return valueProp.map((item) => getLabelOfObjectValue(item, mapping, useOriginValue)).join(' / ');
}
