import { message } from '@icp/settings';
import { differenceBy, get, intersectionBy } from 'lodash-es';
import { findI18nValues, getTFunc } from '@icp/i18n';
import { loadXlsx } from '@icp/utils';

export function formatColumnDefs(columnDefs) {
  return (columnDefs || []).map((col) => {
    // 统一规范，suppressMenu 禁止使用 ag-grid column 的 filter，并且其余 menuTabs 对 acl 来说也无意义
    return { ...col, suppressMenu: true };
  });
}

export function getDisplayLabel(value) {
  return (value || [])
    .slice(0, 20)
    .map((item) => item.label)
    .join('，');
}

export function getIdsInValue(value, stringEqual) {
  return (value || []).map((item) => (stringEqual ? String(item.value) : item.value));
}

function toValueItem(rowDetail, mapping, isStandardDataSource) {
  const item = !isStandardDataSource
    ? {}
    : {
        // 表单引擎标准数据源后端dot walking功能，约定hardcode mapping: id -> id
        id: rowDetail.id != null ? +rowDetail.id : undefined,
      };
  for (const [k, v] of Object.entries(mapping)) {
    item[k] = k === 'value' ? rowDetail[v] : get(rowDetail, v);

    // eg：mapping 是 { value: 'id', label: 'name' }，
    // 当 options 里存在 { id: 12345, name: 'abc', name_i18n_en-US: 'abc-en' } 等 name 的多语言资源时，
    // 给 item 设置上 item.label_en-US = 'abc-en'。
    const i18nValues = findI18nValues(rowDetail, v);
    for (const [suffix, i18nValue] of Object.entries(i18nValues)) {
      item[`${k}${suffix}`] = i18nValue;
    }
  }
  return item;
}

export function composeServerSideValue(cachedData, selectedKeys, mapping, isStandardDataSource) {
  return selectedKeys.map((key) => {
    const rowDetail = cachedData.get(key);
    // If already load row detail data, save all properties figured out in mapping
    if (rowDetail) {
      return toValueItem(rowDetail, mapping, isStandardDataSource);
    }
    // If no load more detail data, only save value
    return { value: key };
  });
}

export function composeClientSideValue(
  cachedAllRowsData,
  selectedKeys,
  mapping,
  isStandardDataSource,
) {
  return selectedKeys
    .map((key) => {
      // stringEqual already handled by fetchAllRows.then
      const rowDetail = cachedAllRowsData.find((item) => item[mapping.value] === key);
      if (!rowDetail) return null;
      // client side mode always maintain all rows data.
      return toValueItem(rowDetail, mapping, isStandardDataSource);
    }) // filter ids not existing in options, for the case of option has been deleted after saved its id when editing.
    .filter(Boolean);
}

export function resolveIdsFromRequest(request, ids) {
  const { startRow, endRow } = request;

  return ids.slice(startRow, endRow);
}

export function resolveIdsToFetch(cachedData, ids) {
  return ids.filter((id) => !cachedData.get(id));
}

export function setRowsToCachedData(cachedData, rows, stringEqual, mapping) {
  rows.forEach((rowData) => {
    const key = stringEqual ? String(rowData[mapping.value]) : rowData[mapping.value];
    cachedData.set(key, rowData);
  });
}

export function getRowsFromCachedData(cachedData, ids) {
  return ids.map((id) => cachedData.get(id));
}

export function importExcel(file, successCallback) {
  const fileReader = new FileReader();
  fileReader.onload = (event) => {
    try {
      loadXlsx().then((XLSX) => {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = []; // 存储获取到的数据
        // 遍历每张工作表进行读取（这里默认只读取第一张表）
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets[sheet]) {
            // 利用 sheet_to_json 方法将 excel 转成 json 数据
            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          }
        }
        if (successCallback) {
          successCallback(data);
        }
      });
    } catch (e) {
      const t = getTFunc('icp-components');
      message.error(t('acl.import-error'));
    }
  };
  // 以二进制方式打开文件
  fileReader.readAsBinaryString(file);
}

export function exportExcel(headers, data, fileName, callback = () => {}) {
  const excelHeader = headers
    .map((item, i) => ({
      key: item.key,
      title: item.title,
      label: item.label,
      position: String.fromCharCode(65 + i) + 1,
    }))
    .reduce((prev, next) => {
      // Using Threaded property will result in empty cell comments in office
      // So here is not used Threaded property
      const c = [{ t: next.label, a: 'system' }];
      // In order to solve the problem that comments are always displayed when opening the file
      // https://docs.sheetjs.com/docs/csf/features/
      c.hidden = true;
      return {
        ...prev,
        [next.position]: {
          key: next.key,
          v: next.title,
          c,
        },
      };
    }, {});

  const xlsxData = data
    .map((item, i) =>
      headers.map((key, j) => ({
        content: item[key.key],
        position: String.fromCharCode(65 + j) + (i + 2),
      })),
    )
    //  对刚才的结果进行降维处理（二维数组变成一维数组）
    .reduce((prev, next) => prev.concat(next), [])
    // 转换成 worksheet 需要的结构
    .reduce(
      (prev, next) => ({
        ...prev,
        [next.position]: { v: next.content, t: typeof next.content === 'number' ? 'n' : 's' },
      }),
      {},
    );

  // 合并 headers 和 data
  const output = { ...excelHeader, ...xlsxData };
  // 获取所有单元格的位置
  const outputPos = Object.keys(output);
  // 计算出范围 ,["A1",..., "H2"]
  const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;

  // 构建 workbook 对象
  const wb = {
    SheetNames: ['mySheet'],
    Sheets: {
      mySheet: {
        ...output,
        '!ref': ref,
      },
    },
  };
  // 导出 Excel
  loadXlsx().then((XLSX) => {
    XLSX.writeFile(wb, fileName);
    setTimeout(() => callback());
  });
}

export function getValue(v, key = 'value') {
  // v may be null
  if (Object.prototype.toString.call(v) === '[object Object]') {
    return v[key];
  }
  return v;
}

/**
 * Filter out some data in results, for example,
 * dataExclusion = [':context.userProfile.id', ':issuers'] means filter out current user and
 * issuers (issuers can be id of another ACL, or other Input, etc)
 * @param results
 * @param dataExclusion
 * @param key
 * @returns {*}
 */
export function excludeData(results, dataExclusion, key) {
  if (!Array.isArray(dataExclusion)) {
    return results;
  }

  return results.filter((item) => {
    // Currently, only support string equal, 0 and '0' is equal
    // item may be null
    const value = String(getValue(item, key));

    return !dataExclusion.find((targetValue) => {
      if (Array.isArray(targetValue)) {
        return targetValue.some((v) => value === String(getValue(v)));
      }

      return value === String(getValue(targetValue));
    });
  });
}

export function differenceByString(originArray, excludeArray, stringEqual, iteratee) {
  return differenceBy(originArray, excludeArray, (val) => {
    // If iteratee exists, execute iteratee first
    const convertedValue = iteratee ? iteratee(val) : val;
    // stringEqual to determine whether the final result is converted into a string for comparison
    return stringEqual ? String(convertedValue) : convertedValue;
  });
}

export function intersectionByString(originArray, intersectArray, stringEqual, iteratee) {
  return intersectionBy(originArray, intersectArray, (val) => {
    // If iteratee exists, execute iteratee first
    const convertedValue = iteratee ? iteratee(val) : val;
    // stringEqual to determine whether the final result is converted into a string for comparison
    return stringEqual ? String(convertedValue) : convertedValue;
  });
}

export function setSelectedIfNeed(multiple, selectedKeys, gridApi) {
  if (!multiple && selectedKeys.length) {
    setTimeout(() => {
      const rowNode = gridApi.getRowNode(selectedKeys[0]);
      if (rowNode) {
        rowNode.setSelected(true);
      }
    }, 16);
  }
}

export function logDuplicate(data, mapping) {
  const map = new Map();
  for (const item of data) {
    const key = item[mapping.value];
    if (map.get(key)) {
      console.warn(
        `ACL 发现多条数据的 mapping.value 值 ${key} 重复: `,
        mapping,
        map.get(key),
        item,
      );
    }
    if (key === undefined || key === null) {
      console.warn(`ACL 发现行数据 mapping.value 值为 ${key}，无法进行选择。`);
    }
    map.set(key, item);
  }
}
