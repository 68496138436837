import { createContext, forwardRef, useEffect, useMemo } from 'react';
import { Provider, createDispatchHook, createSelectorHook, createStoreHook } from 'react-redux';
import PropTypes from 'prop-types';
import makeStore from './makeStore';

const ctx = createContext();

export const useStore = createStoreHook(ctx);
export const useDispatch = createDispatchHook(ctx);
export const useSelector = createSelectorHook(ctx);

function StoreProvider({ children }) {
  const [store, clearListeners] = useMemo(() => makeStore(), []);

  useEffect(() => {
    return () => {
      clearListeners();
    };
  }, [clearListeners]);

  return (
    <Provider store={store} context={ctx}>
      {children}
    </Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node,
};

export function withStoreProvider(Component) {
  return forwardRef((props, ref) => {
    return (
      <StoreProvider>
        <Component ref={ref} {...props} />
      </StoreProvider>
    );
  });
}
