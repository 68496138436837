import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import clsx from 'clsx';
import { HELPER_TEXT_TYPES, helpersIsEmpty, OOTB_VALIDATION } from '@icp/form-renderer-core';
import { restApi } from '@icp/settings';
import { DeviceCameraIcon } from '@primer/octicons-react';
import { Loading } from '@icp/components';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useClassName } from '../hooks';
import { renderReadonlyText } from '../utils';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const OcrElement = forwardRef(function OcrElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    componentProps = {},
    fieldTitleProps,
    validation,
    readonly,
    status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const { url, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [loading, setLoading] = useState(false);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleImgChange = (event) => {
    const file = event.target.files[0];

    const data = new FormData();
    data.append('file', file);

    setLoading(true);

    restApi
      .post(url, data)
      .then(onChange)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'ocr-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          {readonly ? (
            renderReadonlyText(otherComponentProps, value)
          ) : (
            <Input
              {...otherComponentProps}
              className={classNameComp}
              maxLength={validation?.maxLength}
              name={id}
              value={value}
              disabled={disabled}
              onChange={handleChange}
              onBlur={onTouchChanged}
              status={status}
            />
          )}
          <FormHelperText helpers={helpers} />
          <span className="ocr-element-camera-btn icp-center">
            <DeviceCameraIcon size={20} />
            <input type="file" accept="image/*" onChange={handleImgChange} />
          </span>
          {loading ? (
            <div className="ocr-element-loading-overlay icp-center">
              <Loading delayed={false} />
            </div>
          ) : null}
        </div>
      </div>
    </ElementDecorator>
  );
});

OcrElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    /**
     * 是否必填
     */
    required: PropTypes.bool,
    /**
     * 最小长度
     */
    minLength: PropTypes.number,
    /**
     * 小于最小长度的错误提示信息
     */
    minLengthErrorMessage: PropTypes.string,
    /**
     * 最大长度
     */
    maxLength: PropTypes.number,
    /**
     * 大于最大长度的错误提示信息
     */
    maxLengthErrorMessage: PropTypes.string,
    /**
     * 系统提供的验证格式
     */
    ootb: PropTypes.oneOf(OOTB_VALIDATION),
    /**
     * 正则验证
     */
    regex: PropTypes.string,
    /**
     * 正则验证错误时候的提示信息
     */
    regexErrorMessage: PropTypes.string,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
OcrElement.displayName = 'Ocr';

export default withFieldWrapper(OcrElement);
