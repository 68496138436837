import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import DialogAnt from './DialogAnt';
import DialogMaterial from './DialogMaterial';

const DialogUI = forwardRef(function ButtonUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <DialogMaterial {...props} ref={ref} />;
  }

  return <DialogAnt {...props} ref={ref} />;
});

export default DialogUI;
