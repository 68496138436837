import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  DEFAULT_I18N_VALUE,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
  I18N_VALUE_COLLAPSED,
  I18N_VALUE_FLAT,
  OOTB_VALIDATION,
  selectValues,
} from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { findI18nValues } from '@icp/i18n';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import { useStore } from '../../store';
import InputUI from './InputUI';

const InputElement = forwardRef(function InputElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    value,
    allowI18nValue,
    componentProps = {},
    helpers,
    onChange,
  } = props;

  const { i18nInputLayout: i18nInputLayoutProp = DEFAULT_I18N_VALUE, ...otherComponentProps } =
    componentProps;

  const ElementDecorator = useElementDecorator();
  const { i18n } = useTranslation();
  const store = useStore();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [i18nInputLayout, setI18nInputLayout] = useState(i18nInputLayoutProp);

  const nodeRef = useRef(null);
  const inputRef = useRef(null);

  // 响应 designer 里的改动
  useEffect(() => {
    setI18nInputLayout(i18nInputLayoutProp);
  }, [i18nInputLayoutProp]);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      get input() {
        // antd is inputRef.current?.input, material is inputRef.current
        return inputRef.current?.input || inputRef.current;
      },
    }),
    [],
  );

  // 处理脏数据，case 是 Input 最开始没有打开 allowI18nValue，保存的数据就是普通数据，例如 { name: 'Name' }。
  // 后续打开 allowI18nValue，这时候组件会去读相应语言的 i18n value 会找不到不显示。
  // 所以这里发现这种情况过后，将老的普通不带多语言数据的 value 设置给当前登陆语言的 i18n value，例如 { name_i18n_zh-CN: 'Name' }。
  useEffect(() => {
    if (!allowI18nValue || !value) {
      return;
    }
    const values = selectValues(store.getState());
    const i18nValues = findI18nValues(values, id);

    if (!Object.keys(i18nValues).length) {
      console.warn(
        `${id} has the allowI18nValue turned on, the field has the value "${value}" but there is no` +
          ` any i18n value. For the component to work properly, set "${value}" to the i18n ` +
          `value name_i18n_${i18n.language} of the current login language`,
      );
      onChange(value, i18n.language, false); // false 表示是代码自动触发的 change，不是用户通过 ui 组件修改的
    }
  }, [allowI18nValue, id, value, store, onChange, i18n.language]);

  const handleChange = (event, language) => {
    onChange(event.target.value, language);
  };

  const handleToggleI18nInputLayout = () => {
    if (i18nInputLayout === I18N_VALUE_COLLAPSED) {
      setI18nInputLayout(I18N_VALUE_FLAT);
    } else {
      setI18nInputLayout(I18N_VALUE_COLLAPSED);
    }
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
            'i18n-value-allowed': allowI18nValue,
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <InputUI
          {...props}
          componentProps={{
            ...otherComponentProps,
            className: classNameComp,
          }}
          i18nInputLayout={i18nInputLayout}
          onToggleI18nInputLayout={handleToggleI18nInputLayout}
          onChange={handleChange}
          ref={inputRef}
        />
      </div>
    </ElementDecorator>
  );
});

InputElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  allowI18nValue: PropTypes.bool,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 多语言输入的布局方式
     */
    i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    /**
     * 是否必填
     */
    required: PropTypes.bool,
    /**
     * 最小长度
     */
    minLength: PropTypes.number,
    /**
     * 小于最小长度的错误提示信息
     */
    minLengthErrorMessage: PropTypes.string,
    /**
     * 最大长度
     */
    maxLength: PropTypes.number,
    /**
     * 大于最大长度的错误提示信息
     */
    maxLengthErrorMessage: PropTypes.string,
    /**
     * 系统提供的验证格式
     */
    ootb: PropTypes.oneOf(OOTB_VALIDATION),
    /**
     * 正则验证
     */
    regex: PropTypes.string,
    /**
     * 正则验证错误时候的提示信息
     */
    regexErrorMessage: PropTypes.string,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
InputElement.displayName = 'Input';

export default withFieldWrapper(InputElement);
