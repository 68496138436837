import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withFieldWrapper } from '../fieldWrapper';
// import RecipeSteps from 'components/RecipeSteps';
// import { useMemo } from 'react';
// import { parseJSON } from '@icp/utils';

const FlowElement = forwardRef(function FlowElement() {
  return null;
  /* const { value, style = {} } = props;

  const parsedDefinition = useMemo(() => parseJSON(value?.definition), [value]);

  return (
    <div className="flow-element form-element" style={{ ...style }} ref=ref>
      Flow
      <RecipeSteps definition={parsedDefinition} viewOnly={true} />
    </div>
  ); */
});

FlowElement.propTypes = {
  value: PropTypes.shape({
    definition: PropTypes.string,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
FlowElement.displayName = 'Flow';

export default withFieldWrapper(FlowElement);
