export default class AsyncManager {
  constructor() {
    this.allReady = false;
    this.ids = {};
    this.readyCallback = () => {};
  }

  checkReady() {
    if (this.allReady) {
      return;
    }

    if (Object.values(this.ids).some((x) => !x)) {
      return;
    }

    // all async component is ready
    this.allReady = true;
    this.readyCallback();
  }

  isAllReady() {
    return this.allReady;
  }

  onReady(callback) {
    this.readyCallback = callback;
  }

  register(id) {
    if (this.allReady) {
      // console.warn('所有异步事件都已经 ready，再注册无效', id);
      return;
    }

    if (!Array.isArray(id)) {
      id = [id];
    }

    for (const i of id) {
      if (!this.ids[i]) {
        this.ids[i] = false;
      }
    }
  }

  setReady(id) {
    if (this.allReady) {
      return;
    }

    this.ids[id] = true;
    this.checkReady();
  }
}
