import { createTheme } from '@mui/material/styles';

export default function createMaterialTheme(mergedTheme) {
  return createTheme({
    palette: {
      primary: {
        main: mergedTheme.token.colorPrimary,
      },
      secondary: {
        main: 'rgb(28, 37, 46)',
      },
      error: {
        main: mergedTheme.token.colorError,
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      // Tell Material UI what the font-size on the html element is.
      htmlFontSize: 14,
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 14,
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          color: 'secondary',
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 600,
            lineHeight: 1.71429,
            whiteSpace: 'nowrap',
          },
          sizeLarge: {
            paddingLeft: 16,
            paddingRight: 16,
            height: 48,
          },
          sizeMedium: {
            paddingLeft: 12,
            paddingRight: 12,
          },
          sizeSmall: {
            paddingLeft: 8,
            paddingRight: 8,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'rgb(99, 115, 129)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          elevation8: {
            boxShadow:
              'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px',
          },
          elevation16: {
            boxShadow: '-40px 40px 80px -8px rgba(145, 158, 171, 0.24)',
          },
          elevation24: {
            boxShadow: 'rgba(0, 0, 0, 0.24) -40px 40px 80px -8px',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: 10,
          },
        },
      },
      MuiPickersPopper: {
        styleOverrides: {
          paper: {
            borderRadius: 12,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 16,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: 'rgb(28, 37, 46)',
            fontWeight: 600,
            padding: 24,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: 24,
            paddingBottom: 0,
            '&:last-child': {
              paddingBottom: 24,
            },
          },
        },
      },
      MuiDialogActions: {
        defaultProps: {
          disableSpacing: true,
        },
        styleOverrides: {
          root: {
            padding: 24,
            '& > :not(:first-of-type)': {
              marginLeft: 12,
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            color: 'inherit',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            padding: 4,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            lineHeight: 1.57143,
            color: 'rgb(28, 37, 46)',
            borderRadius: 6,
            padding: '6px 8px',
            '&:not(:last-of-type)': {
              marginBottom: 4,
            },
            '&:hover': {
              backgroundColor: 'rgba(145 158 171 / 0.08)',
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(145 158 171 / 0.16)',
              '&:hover': {
                backgroundColor: 'rgba(145 158 171 / 0.08)',
              },
              '&.Mui-focusVisible:not(:hover)': {
                backgroundColor: 'rgba(145 158 171 / 0.24)',
              },
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'rgba(145 158 171 / 0.24)',
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'currentcolor',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            right: 9,
            top: 'calc(50% - 9px)',
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          slotProps: {
            paper: {
              elevation: 8,
            },
          },
        },
        styleOverrides: {
          popupIndicator: {
            color: 'inherit',
          },
          clearIndicator: {
            color: 'inherit',
          },
          endAdornment: {
            color: 'rgb(99, 115, 129)',
          },
          paper: {
            borderRadius: 10,
          },
          listbox: {
            padding: 4,
          },
          option: {
            fontSize: '1rem',
            lineHeight: 1.57143,
            borderRadius: 6,
            '&:not(:last-of-type)': {
              marginBottom: 4,
            },
            // 加 class 增加优先级覆盖默认样式
            '&.MuiAutocomplete-option': {
              padding: '6px 8px',
              '&[aria-selected="true"]': {
                backgroundColor: 'rgba(145 158 171 / 0.16)',
              },
              '&.Mui-focused.Mui-focused': {
                backgroundColor: 'rgba(145 158 171 / 0.08)',
              },
              '&.Mui-focusVisible.Mui-focusVisible': {
                backgroundColor: 'rgba(145 158 171 / 0.24)',
              },
            },
            '&.Mui-focused.Mui-focused': {
              backgroundColor: 'rgba(145 158 171 / 0.08)',
            },
            '&.Mui-focusVisible.Mui-focusVisible': {
              backgroundColor: 'rgba(145 158 171 / 0.24)',
            },
          },
        },
      },
      MuiRichTreeView: {
        styleOverrides: {
          root: {
            padding: 4,
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          root: {
            '&:not(:last-of-type)': {
              marginBottom: 4,
            },
            '& .MuiCollapse-wrapperInner > .MuiTreeItem-root': {
              marginTop: 4,
            },
          },
          content: {
            color: 'rgb(28, 37, 46)',
            borderRadius: 6,
            padding: '4px 8px',
            '&:hover': {
              backgroundColor: 'rgba(145 158 171 / 0.08)',
            },
            '&.Mui-selected': {
              backgroundColor: 'rgba(145 158 171 / 0.16)',
              '&:hover': {
                backgroundColor: 'rgba(145 158 171 / 0.08)',
              },
              '&.Mui-focused': {
                backgroundColor: 'rgba(145 158 171 / 0.24)',
              },
            },
            '&.Mui-focused': {
              backgroundColor: 'rgba(145 158 171 / 0.24)',
            },
          },
          label: {
            fontSize: '1rem',
            lineHeight: 1.57143,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(145, 158, 171, 0.16)',
            transition:
              'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:hover': {
              backgroundColor: 'rgba(145, 158, 171, 0.32)',
            },
          },
          deleteIcon: {
            color: 'currentcolor',
            opacity: 0.48,
            '&:hover': {
              color: 'currentcolor',
              opacity: 1,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'rgb(145, 158, 171)',
            fontSize: '1rem',
            lineHeight: '23px',
          },
          shrink: {
            color: 'rgb(99, 115, 129)',
            fontSize: '16px',
            fontWeight: 600,
            '&.Mui-focused': {
              color: 'var(--font-color)',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '15px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'rgba(145, 158, 171, 0.2)',
            transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--font-color)',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'rgb(99, 115, 129)',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: 'rgb(99, 115, 129)',
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            lineHeight: 1.57143,
            color: 'rgb(99, 115, 129)',
            '& a': {
              color: 'rgb(28, 37, 46)',
            },
            '& a:hover': {
              textDecoration: 'underline',
            },
          },
          separator: {
            margin: '0 14px',
            color: 'rgb(145, 158, 171)',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderStyle: 'dashed',
            borderColor: 'rgba(145, 158, 171, 0.2)',
          },
          withChildren: {
            fontSize: '1rem',
            color: 'rgb(145, 158, 171)',
            '&::before,&::after': {
              borderTopStyle: 'dashed',
              borderTopColor: 'rgba(145, 158, 171, 0.2)',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: 'rgb(28, 37, 46)',
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            color: 'rgb(99, 115, 129)',
            '&.Mui-selected': {
              color: 'rgb(28, 37, 46)',
              fontWeight: 600,
            },
          },
        },
      },
    },
  });
}
