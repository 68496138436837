import parseJSON from './parseJSON';

// https://en.wikipedia.org/wiki/Base64
// convert "RFC 4648 §5" to "RFC 4648 §4"
function decodeUrlSafeBase64(str) {
  return str?.replace(/-/g, '+').replace(/_/g, '/');
}

export function getJwtHeader(jwt) {
  if (!jwt) return null;

  const [jwtHeader] = jwt.split('.');
  try {
    return parseJSON(atob(decodeUrlSafeBase64(jwtHeader)));
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getJwtPayload(jwt) {
  if (!jwt) return null;

  const [, jwtPayload] = jwt.split('.');
  if (!jwtPayload) return null;

  try {
    return parseJSON(atob(decodeUrlSafeBase64(jwtPayload)));
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function isJwtNotExpired(jwt) {
  const exp = getJwtExp(jwt);
  if (exp == null) {
    console.log(`jwt [${jwt}] expired: no exp in it`);
    return null;
  }
  const now = +new Date();
  const pass = exp * 1000 > now;
  if (!pass) {
    console.log(`jwt [${jwt}] expired: exp [${exp}] * 1000 <= now [${now}]`);
  }
  return pass;
}

export function getJwtExp(jwt) {
  if (!jwt) return null;

  const payload = getJwtPayload(jwt);
  if (!payload) return null;
  return payload.exp;
}
