/* eslint-disable react/prop-types */
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

function TotalRowsStatusBar(props) {
  const { api } = props;

  const { t } = useTranslation(['icp-components']);

  const [, setRefreshFlag] = useState(Date.now());

  const total = api.paginationGetRowCount() || api.getDisplayedRowCount();

  useEffect(() => {
    const updateStatusBar = () => {
      setRefreshFlag(Date.now());
    };

    api.addEventListener('paginationChanged', updateStatusBar);

    return () => {
      if (!api.isDestroyed()) {
        api.removeEventListener('paginationChanged', updateStatusBar);
      }
    };
  }, [api]);

  return (
    <span className="icp-table-total-rows icp-center">
      <Trans i18nKey="table.total" t={t} values={{ total }} components={[<b />]} />
    </span>
  );
}

export default TotalRowsStatusBar;
