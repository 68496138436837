import {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { ConditionalPropertyPropType } from '../../propTypes';
import { FormHelperTextMaterial } from '../../FormHelperText';

function RadioMaterial(props) {
  const {
    id,
    title,
    value,
    disabled,
    // componentProps = {},
    validation,
    readonly,
    helpers,
    status,
    onChange,

    options,
    direction,
    gap,
  } = props;

  return (
    <FormControl
      required={validation?.required}
      error={status === 'error'}
      disabled={disabled || readonly}
    >
      <FormLabel>{title}</FormLabel>
      <RadioGroup
        row={direction !== 'vertical'}
        sx={{ gap: (typeof gap === 'number' && gap / 8) || (direction === 'horizontal' && 4) || 0 }}
        name={id}
        value={value ?? ''}
        onChange={onChange}
      >
        {(options || []).map((op) => {
          return (
            <FormControlLabel
              key={op.value}
              value={op.value}
              control={<Radio />}
              label={op.label}
            />
          );
        })}
      </RadioGroup>
      {!readonly && !helpersIsEmpty(helpers) ? (
        <FormHelperText>
          <FormHelperTextMaterial helpers={helpers} />
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

RadioMaterial.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  status: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  gap: PropTypes.oneOfType([PropTypes.oneOf(['small', 'middle', 'large']), PropTypes.number]),
  onChange: PropTypes.func,
};

export default RadioMaterial;
