import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const CustomDateComponent = forwardRef(function CustomDateComponent(props, ref) {
  const { filterParams } = props;

  const [date, setDate] = useState(null);

  // we use a ref as well as state, as state is async,
  // and after the grid calls setDate() (eg when setting filter model)
  // it then can call getDate() immediately (eg to execute the filter)
  // and we need to pass back the most recent value, not the old 'current state'.
  const dateRef = useRef(null);

  const { showTime, format, readOnly } = filterParams || {};

  const handleChange = (newValue) => {
    // startOf('day') set time to 00:00:00
    let newDate = null; // = newValue ? newValue.startOf('day').toDate() : null;

    if (newValue) {
      newDate = showTime ? newValue.toDate() : newValue.startOf('day').toDate();
    }

    setDate(newDate);
    dateRef.current = newDate;
    props.onDateChanged();
  };

  useImperativeHandle(ref, () => ({
    // *********************************************************************************
    //          METHODS REQUIRED BY AG-GRID
    //* ********************************************************************************
    getDate() {
      // ag-grid will call us here when in need to check what the current date value is hold by this
      // component.
      return dateRef.current;
    },

    setDate(newDate) {
      // ag-grid will call us here when it needs this component to update the date that it holds.
      dateRef.current = newDate;
      setDate(newDate);
    },
  }));

  return (
    <DatePicker
      className="ag-input-wrapper icp-custom-date-component"
      // ag-custom-component-popup 是 ag-grid 提供的阻止 popup 点击提前关闭的方法
      popupClassName="ag-custom-component-popup"
      value={date ? dayjs(date) : undefined}
      format={format}
      showTime={showTime}
      onChange={handleChange}
      style={{ height: 24, fontSize: 14 }}
      disabled={readOnly}
    />
  );
});

CustomDateComponent.propTypes = {
  filterParams: PropTypes.shape({
    format: PropTypes.string,
    /**
     * antd DatePicker 的 `showTime` 属性
     * @default false
     */
    showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    /**
     * onChange 的时候是否去除日期的 time zone 信息
     * @default false
     */
    noTimeZone: PropTypes.bool,
    readOnly: PropTypes.bool,
  }),
  onDateChanged: PropTypes.func,
};

export default CustomDateComponent;
