import PropTypes from 'prop-types';
import { withErrorBoundary } from '../fieldWrapper';
import componentMap from './componentMap';

function RecursionRenderer(props) {
  const { fields, keyPath: keyPathProp, Wrapper } = props;

  if (!Array.isArray(fields)) {
    return null;
  }

  return fields.map((fieldInfo, index) => {
    // 加上 index 防止同级别有两个一样的 id
    const reactKey = `${fieldInfo.id} ${fieldInfo.component} ${index}`;
    const keyPath = keyPathProp.concat([index]);

    const Comp = componentMap[fieldInfo.component];

    if (!Comp) {
      // ai 生成过程中对象不完整暂时还没有 fieldInfo.component，不需要抛错
      if (!fieldInfo.component) {
        return null;
      }
      return (
        <div key={reactKey} className="form-renderer-unknown-component">
          Unknown component: {fieldInfo.component}
        </div>
      );
    }

    if (Wrapper) {
      return (
        <Wrapper key={reactKey} {...fieldInfo}>
          <Comp {...fieldInfo} keyPath={keyPath} />
        </Wrapper>
      );
    }
    return <Comp key={reactKey} {...fieldInfo} keyPath={keyPath} />;
  });
}

RecursionRenderer.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  Wrapper: PropTypes.func,
  WrapperProps: PropTypes.shape({}),
};

export default withErrorBoundary()(RecursionRenderer);
