import loadCss from './loadCSS';
import loadJS from './loadJS';

let promise;

export default function loadDHTMLXEventCalendar() {
  if (promise) {
    return promise;
  }
  promise = new Promise((resolve) => {
    if (window.eventCalendar) {
      resolve(window.eventCalendar);
    } else {
      const cssUrl = `${process.env.VENDOR_PATHS['event-calendar']}/event-calendar.css`;
      const jsUrl = `${process.env.VENDOR_PATHS['event-calendar']}/event-calendar.js`;
      Promise.all([loadCss('event-calendar', cssUrl), loadJS(jsUrl)]).then(() => {
        resolve(window.eventCalendar);
      });
    }
  });

  return promise;
}
