import './cellRenderers.css';
import PropTypes from 'prop-types';
import { getTFunc } from '@icp/i18n';
import { ensureDisplayText } from '@icp/utils';

function getDefaultOptions() {
  const t = getTFunc('icp-form-renderer');
  return [
    {
      value: 'SUCCESS',
      label: t('status.success'),
      color: 'var(--success-color)',
    },
    {
      value: 'FAILURE',
      label: t('status.failure'),
      color: 'var(--error-color)',
    },
  ];
}

function StatusCellRenderer(props) {
  const { value, enums: enumsProp } = props;

  const enums = Array.isArray(enumsProp) ? enumsProp : getDefaultOptions();

  const statusColor = enums.find((item) => item.value === value)?.color;
  const displayText = StatusCellRenderer.toDisplayText(value, { enums });

  return (
    <div className="table-status-cell">
      <i style={statusColor ? { backgroundColor: statusColor } : {}} />
      <span className="text-ellipsis">{ensureDisplayText(displayText)}</span>
    </div>
  );
}

/* StatusCellRenderer.keyCreator = (params) => {
  const { cellRendererParams = {} } = params.colDef;
  const enumsProp = cellRendererParams.enums;
  const enums = Array.isArray(enumsProp) ? enumsProp : defaultEnum;
  return enums.find((item) => item.value === params.value)?.label;
}; */

StatusCellRenderer.toDisplayText = (value, cellRendererParams) => {
  const { enums } = cellRendererParams || {};
  return enums.find((item) => item.value === value)?.label;
};

StatusCellRenderer.valueFormatter = (params) => {
  const { enums: enumsProp } = params.colDef.cellRendererParams || {};
  const enums = Array.isArray(enumsProp) ? enumsProp : getDefaultOptions();
  return enums.find((item) => item.value === params.value)?.label;
};

StatusCellRenderer.values = (params) => {
  const { enums: enumsProp } = params.colDef.cellRendererParams || {};
  const enums = Array.isArray(enumsProp) ? enumsProp : getDefaultOptions();
  const values = enums.map((item) => item.value);
  params.success(values);
};

StatusCellRenderer.propTypes = {
  value: PropTypes.string,
  enums: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

export default StatusCellRenderer;
