import './PDFChatAI.css';
import { AIAgent, AIAgentIcon, Markdown } from '@icp/components';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, List, Modal } from 'antd';
import { restApi } from '@icp/settings';
import { parseJSON } from '@icp/utils';
import { selectUserProfile } from '../../store/features/authSlice';
import useNonScalableViewport from '../../hooks/useNonScalableViewport';
import configuration from './configuration.json';
import ExpertIcon from '../../assets/icons/expert.png';

const SMALL_SCREEN_THRESHOLD = 512;

function addFeedback(isLike, parameters) {
  const payload = { ...parameters, time: new Date().toISOString(), isLike };
  return restApi.post(configuration.apis.feedback.url, payload);
}

function PDFChatAI() {
  const { t } = useTranslation();
  const userProfile = useSelector(selectUserProfile);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userFeedback, setUserFeedback] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useNonScalableViewport(SMALL_SCREEN_THRESHOLD);

  useEffect(() => {
    document.getElementById('root').classList.add('pdf-chat-embedded');
    return () => {
      document.getElementById('root').classList.remove('pdf-chat-embedded');
    };
  }, []);

  const feedbackSettings = {
    enabled: true,
    forms: {
      dislike: {
        title: t('contact-info.title'),
        body: (
          <div className="pdf-chat-feedback-form">
            <div className="pdf-chat-feedback-form-message">
              {t('pdf-chat.feedback-form-message')}
            </div>
            <Input
              placeholder={t('contact-info.name')}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <Input
              placeholder={t('contact-info.email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder={t('contact-info.phone')}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input.TextArea
              style={{ height: 80, resize: 'none' }}
              placeholder={t('pdf-chat.user-feedback-placeholder')}
              value={userFeedback}
              onChange={(e) => setUserFeedback(e.target.value)}
            />
          </div>
        ),
      },
    },
    handlers: {
      like: ({ question, answer }) => {
        return addFeedback(true, { question, answer });
      },
      dislike: ({ question, answer }) => {
        return addFeedback(false, { question, answer, userName, email, phone, userFeedback }).then(
          () => setUserFeedback(''),
        );
      },
    },
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent(null);
    setModalTitle('');
    setUserFeedback('');
  };

  const handleClickSourceType = ({ title, content }) => {
    setModalTitle(title);
    setModalContent(content);
    setModalOpen(true);
  };

  const buildDocumentSourceButton = (documentSources) => (
    <button
      className="pdf-chat-source-button"
      onClick={() => {
        handleClickSourceType({
          title: `${t('pdf-chat.source-prefix')} ${t('pdf-chat.source-ai')}`,
          content: (
            <List
              dataSource={documentSources}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${t('pdf-chat.source-ai-item-title', { file: item.fileName, page: item.page })}`}
                    description={<Markdown>{item.pageContent}</Markdown>}
                  />
                </List.Item>
              )}
            />
          ),
        });
      }}
    >
      <AIAgentIcon size="16px" />
    </button>
  );

  const buildHistorySourceButton = (historySources) => (
    <button
      className="pdf-chat-source-button"
      onClick={() => {
        handleClickSourceType({
          title: `${t('pdf-chat.source-prefix')} ${t('pdf-chat.source-expert')}`,
          content: (
            <List
              dataSource={historySources}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta description={<Markdown>{item}</Markdown>} />
                </List.Item>
              )}
            />
          ),
        });
      }}
    >
      <img src={ExpertIcon} alt="expert" width={16} height={16} />
    </button>
  );

  const convertKbItemsToSource = (kbItems) => {
    const sourceTypes = {
      document: [],
      history: [],
    };
    kbItems?.forEach((item) => {
      if (item.source === 'history') {
        sourceTypes.history.push(item.content);
      } else {
        const { file_name: fileName, content } = item;
        const { page, page_content: pageContent } = parseJSON(content, {
          silent: true,
          autoComplete: true,
        });
        sourceTypes.document.push({ fileName, page, pageContent });
      }
    });
    const sourceExisted = sourceTypes.document.length > 0 || sourceTypes.history.length > 0;
    return sourceExisted ? (
      <div className="pdf-chat-source-container">
        <div className="pdf-chat-source-prefix">{t('pdf-chat.source-prefix')}</div>
        {sourceTypes.document.length > 0 && buildDocumentSourceButton(sourceTypes.document)}
        {sourceTypes.history.length > 0 && buildHistorySourceButton(sourceTypes.history)}
      </div>
    ) : null;
  };

  const chatApi = {
    name: 'pdf-chat',
    url: configuration.apis.chat.url,
    stream: false,
    sendMessage({ prompt }, signal) {
      return restApi.post(configuration.apis.chat.url, { prompt }, { signal }).then((response) => {
        const { status, content, kb_items: kbItems } = response.result;
        if (status !== 0) {
          throw Error(content);
        }
        return { answer: content, source: { renderer: 'convertKbItemsToSource', data: kbItems } };
      });
    },
  };

  return (
    <div className="pdf-chat-ai">
      <AIAgent
        userProfile={userProfile}
        chatConfiguration={{ chatApi }}
        uiConfiguration={{
          inputToolbar: {
            hideModelPicker: true,
            hideSpeech: true,
          },
        }}
        feedbackSettings={feedbackSettings}
        eventHandlers={{ convertKbItemsToSource }}
      />
      <Modal
        wrapClassName="pdf-chat-source-modal"
        title={modalTitle}
        open={modalOpen}
        closable={true}
        footer={null}
        onCancel={handleCloseModal}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

export default PDFChatAI;
