import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import NumberPickerMaterial from './NumberPickerMaterial';
import NumberPickerAnt from './NumberPickerAnt';

const NumberPickerUI = forwardRef(function NumberPickerUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <NumberPickerMaterial {...props} ref={ref} />;
  }

  return <NumberPickerAnt {...props} ref={ref} />;
});

export default NumberPickerUI;
