// 按permission.target分组, 并按permission.id排序
export function categorizePermissions(permissions) {
  const categoryMap = {};

  for (const perm of permissions) {
    if (!categoryMap[perm.target]) categoryMap[perm.target] = [];
    categoryMap[perm.target].push(perm);
  }

  return Object.entries(categoryMap)
    .map(([categoryName, permList]) => ({
      categoryName,
      sortNumber: permList[0].targetSortNumber,
      permissions: permList.sort((l, r) => {
        const result = l.sortNumber - r.sortNumber;
        if (result) return result;
        return l.name.localeCompare(r.name, navigator.language);
      }),
    }))
    .sort((l, r) => {
      const result = l.sortNumber - r.sortNumber;
      if (result) return result;
      return l.categoryName.localeCompare(r.categoryName, navigator.language);
    });
}
