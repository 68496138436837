import { resolveConditionalProperty } from '@icp/form-renderer-core';
import { get } from 'lodash-es';
import { getTFunc } from '@icp/i18n';
import {
  ACL_COLUMN,
  ACTION_COLUMN,
  AUTO_COLUMN,
  AUTO_INDEX_COLUMN,
  DATE_COLUMN,
  ENUM_COLUMN,
  MULTI_VALUE_COLUMN,
  NUMBER_COLUMN,
  SELECT_COLUMN,
  STATUS_COLUMN,
  TEXTAREA_COLUMN,
} from './constant';
import {
  AutoCellRenderer,
  DateCellRenderer,
  EnumCellRenderer,
  MultiValueCellRenderer,
  NumberCellRenderer,
  StatusCellRenderer,
} from './cellRenders';

export function forEachColumnDefs(columnDefs, cb) {
  const dfs = (colDefArray) => {
    if (!Array.isArray(colDefArray)) {
      return;
    }

    for (const colDef of colDefArray) {
      cb(colDef);
      dfs(colDef.children);
    }
  };

  dfs(columnDefs);
}

export function flattenColumnDefs(columnDefs) {
  return columnDefs.flatMap((x) => (x.children ? flattenColumnDefs(x.children) : x));
}

// TODO, 处理有 column group children 的情况
export function formatColumnDefs(columnDefs) {
  if (!Array.isArray(columnDefs)) {
    return [];
  }
  return columnDefs.map((item) => {
    const colDef = { ...item };
    // 针对特定 type 固定 colId 和 field。不能在 columnTypes.js 里写，ag-grid 的 columnTypes 里是缺省值的意思，会被 props 里 columnDefs 的属性覆盖掉。
    if (colDef.type === AUTO_INDEX_COLUMN) {
      colDef.colId = AUTO_INDEX_COLUMN;
      colDef.field = AUTO_INDEX_COLUMN; // field 在 export 的时候用
    }
    if (colDef.type === ACTION_COLUMN) {
      const t = getTFunc('icp-form-renderer');
      colDef.colId = ACTION_COLUMN;
      colDef.field = null;
      colDef.headerName = colDef.headerName || t('action.header-name');
    }
    return colDef;
  });
}

export function resolveDefaultColumnDefs(
  columnDefs = {},
  currentData = {},
  store = {},
  params = {},
) {
  const newColumnDefs = { ...columnDefs };
  Object.entries(newColumnDefs).forEach(([key, value]) => {
    const [resolvedValue] = resolveConditionalProperty({
      value,
      currentData,
      store,
      params,
    });

    newColumnDefs[key] = resolvedValue;
  });
  return newColumnDefs;
}

const longTextColumns = [AUTO_COLUMN, TEXTAREA_COLUMN];
export function getDisplayTextFunc(colDef, gridContext) {
  if (colDef.type === NUMBER_COLUMN || colDef.cellRenderer === 'NumberCellRenderer') {
    return (v) => NumberCellRenderer.parseValue(v, colDef.cellRendererParams);
  }
  if (colDef.type === DATE_COLUMN || colDef.cellRenderer === 'DateCellRenderer') {
    return (v) => DateCellRenderer.formatValue(v, colDef.cellRendererParams);
  }
  if (colDef.type === STATUS_COLUMN || colDef.cellRenderer === 'StatusCellRenderer') {
    return (v) => StatusCellRenderer.toDisplayText(v, colDef.cellRendererParams);
  }
  if (colDef.type === ENUM_COLUMN || colDef.cellRenderer === 'EnumCellRenderer') {
    // known issue: RemoteEnum没加载完就导出的话，会导出空值
    return (v) => EnumCellRenderer.toDisplayText(v, colDef.cellRendererParams, gridContext);
  }
  if (
    colDef.type === MULTI_VALUE_COLUMN ||
    colDef.type === SELECT_COLUMN ||
    colDef.type === ACL_COLUMN ||
    colDef.cellRenderer === 'MultiValueCellRenderer'
  ) {
    return (v) => MultiValueCellRenderer.toDisplayText(v, colDef.cellRendererParams);
  }
  if (
    longTextColumns.includes(colDef.type) ||
    colDef.cellRenderer === 'AutoCellRenderer' ||
    !colDef.type
  ) {
    return (v) => AutoCellRenderer.toDisplayText(v);
  }
  return (v, o) => {
    if (o) {
      return get(o, colDef.field);
    }
    return v;
  };
}

export function makeSettingKey(id, context) {
  const { pbcToken, formEntityToken, layoutToken, schemaId, userProfile } = context;

  if (!id) {
    return null;
  }

  let key = `table.${id}`;

  if (pbcToken) {
    key += `.${pbcToken}`;
  }

  if (schemaId) {
    key += `.${schemaId}`;
  } else if (formEntityToken && layoutToken) {
    key += `.${formEntityToken}.${layoutToken}`;
  }

  if (userProfile?.username) {
    key += `.${userProfile.username}`;
  } else {
    key += '.all';
  }

  return key;
}

export function extractSortModel(columnState) {
  return columnState
    .filter((cs) => cs.sort)
    .sort((cs) => cs.sortIndex)
    .map((cs) => {
      return {
        colId: cs.colId,
        sort: cs.sort,
      };
    });
}

// 暂时不想从 AgTable/utils 里 import，单独写一份
export function gridFilterModelToApi(gridFilterModel) {
  return Object.keys(gridFilterModel).map((colId) => {
    return {
      colId,
      ...gridFilterModel[colId],
    };
  });
}

export function getExportableColumns(gridRef) {
  return gridRef.current.api
    .getAllGridColumns()
    .filter((column) => {
      const { colDef } = column;
      return (
        column.visible &&
        !(colDef.colId === undefined || colDef.colId === null) &&
        colDef.colId !== 'auto-checkbox-col' &&
        colDef.type !== ACTION_COLUMN
      );
    })
    .map((column) => column.colDef);
}

export function getSelectedIds(gridApi) {
  const selectedRows = gridApi.getSelectedRows();
  return selectedRows.map((row) => row.id);
}
