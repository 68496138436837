import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventCallback } from '@icp/hooks';
import clsx from 'clsx';
import { Popover, Tooltip } from 'antd';
import { Icon } from '@icp/components';

function DemoChatSearchBox(props) {
  const { onSearch } = props;

  const { i18n, t } = useTranslation(['icp-components']);

  const [value, setValue] = useState('');
  const [speeching, setSpeeching] = useState(false);
  const [speechTip, setSpeechTip] = useState(null);

  const containerRef = useRef(null);
  const searchInputRef = useRef(null);
  const tipTimeout = useRef(null); // wait to change speechTip

  const canSpeech = window.SpeechRecognition || window.webkitSpeechRecognition;

  const recognition = useMemo(() => {
    if (!canSpeech) return null;

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const instance = new SpeechRecognition();

    instance.lang = i18n.language;
    instance.onresult = (event) => {
      const text = event.results[0][0].transcript;
      setValue(text);
      onSearch(text);
    };
    instance.onspeechend = () => {
      instance.stop();
      setSpeeching(false);
      clearTimeout(tipTimeout.current);
    };
    instance.onerror = () => {
      setSpeechTip(t('search.speech-error'));
      clearTimeout(tipTimeout.current);
      setTimeout(() => {
        setSpeeching(false);
      }, 2000);
    };
    return instance;
  }, [canSpeech, i18n.language, onSearch, t]);

  const startSpeech = () => {
    recognition?.start();
    setSpeeching(true);
    setSpeechTip(t('search.speech-start'));
    clearTimeout(tipTimeout.current);
    tipTimeout.current = setTimeout(() => {
      setSpeechTip(t('search.speech-listening'));
    }, 2000);
    searchInputRef.current.focus();
  };

  const stopSpeech = () => {
    recognition?.stop();
    setSpeeching(false);
    clearTimeout(tipTimeout.current);
  };

  const closeSearch = () => {
    stopSpeech();
  };

  const clearSearch = () => {
    if (value !== '') {
      setValue('');
      onSearch('');
    }
    closeSearch();
  };

  const clickToClose = useEventCallback(() => {
    stopSpeech();
  });

  useEffect(() => {
    document.addEventListener('click', clickToClose, true);
    return () => {
      document.removeEventListener('click', clickToClose, true);
    };
  }, [clickToClose]);

  const handleChange = (event) => {
    const newText = event.target.value;
    setValue(newText);
    event.target.style.height = '22px';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const handleConfirmSearch = () => {
    onSearch(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      clearSearch();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      handleConfirmSearch();
    }
  };

  const handleFocus = (event) => {
    event.target.style.height = '22px';
    event.target.style.height = `${event.target.scrollHeight}px`;
  };

  const handleBlur = (event) => {
    event.target.style = null;
  };

  return (
    <div className={clsx('demo-chat-search')} ref={containerRef}>
      <textarea
        className="demo-chat-search-input"
        placeholder={`${t('search.search')}......`}
        rows={1}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={searchInputRef}
      />
      <div style={{ height: '100%', visibility: !value ? 'hidden' : 'initial' }}>
        <Tooltip title={t('search.search-clear')}>
          <button onClick={clearSearch}>
            <Icon name="oct:x" size={20} />
          </button>
        </Tooltip>
        <div className="demo-chat-search-split" />
      </div>

      {canSpeech && !speeching ? (
        <Tooltip title={t('search.speech-click')}>
          <button className="demo-chat-speech-button" type="text" onClick={startSpeech}>
            <Icon name="microphone" size={20} />
          </button>
        </Tooltip>
      ) : null}
      {canSpeech && speeching ? (
        <Popover
          open={true}
          placement="top"
          content={
            <div style={{ opacity: 0.8, padding: '0 8px', textAlign: 'center' }}>
              <Icon name="microphone" size={42} />
              <div>{speechTip}</div>
            </div>
          }
        >
          <button className="icp-table-fuzzy-speech-button" type="text" onClick={stopSpeech}>
            <Icon name="microphone" size={20} />
          </button>
        </Popover>
      ) : null}

      <Tooltip title={t('search.search')}>
        <button onClick={handleConfirmSearch}>
          <Icon name="oct:search" size={20} />
        </button>
      </Tooltip>
    </div>
  );
}

DemoChatSearchBox.propTypes = {
  onSearch: PropTypes.func,
};

export default DemoChatSearchBox;
