import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router-dom';
import { resolveVariablePattern } from '@icp/utils';

function Redirect({ to, replace, context }) {
  const params = useParams();
  const redirectTo = resolveVariablePattern({ pattern: to, params, context });
  return <Navigate replace={replace} to={redirectTo} />;
}

Redirect.propTypes = {
  to: PropTypes.string,
  replace: PropTypes.bool,
  context: PropTypes.shape({}),
};

export default Redirect;
