import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { formatTime } from '@icp/i18n';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useClassName } from '../hooks';
import { renderReadonlyText } from '../utils';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const TimePickerElement = forwardRef(function TimePickerElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    disabled,
    readonly,
    style,
    componentProps = {},
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const { format = 'HH:mm:ss', formatOptions, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'time-picker-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          {readonly ? (
            renderReadonlyText(
              componentProps,
              value ? formatTime(dayjs(value, format).toDate(), formatOptions) : undefined,
            )
          ) : (
            <TimePicker
              {...otherComponentProps}
              className={classNameComp}
              name={id}
              format={format}
              value={value ? dayjs(value, format) : undefined}
              disabled={disabled}
              onChange={(time, timeStr) => onChange(timeStr)}
              onBlur={onTouchChanged}
              status={status}
            />
          )}
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

TimePickerElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /** Set the time format
     * @defualt HH:mm:ss
     */
    format: PropTypes.string,
    /**
     * Intl.DateTimeFormat的所有属性
     */
    formatOptions: PropTypes.shape({}),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TimePickerElement.displayName = 'TimePicker';

export default withFieldWrapper(TimePickerElement);
