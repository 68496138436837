import './Popover.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { HTMLElementType } from '@icp/utils';
import { createPortal } from 'react-dom';
import Resizable from '../Resizable';
import usePosition from './usePosition';
import FocusTrap from '../FocusTrap';

const Popover = forwardRef(function Popover(props, ref) {
  const {
    children,
    className,
    style,
    open,
    anchorEl,
    placement = 'bottom-start',
    offset = 4, // offset between anchor element
    gap = 8, // gap between screen top/right/bottom/left
    resizable = false,
    hideBackdrop = false,
    disableAutoFocus = false,
    disableEnforceFocus = false,
    disableRestoreFocus = false,
    onClose,
    ...other
  } = props;

  const [resized, setResized] = useState(false);
  const [element, setElement] = useState(null);

  const { position } = usePosition({
    anchorEl,
    element,
    placement,
    offset,
    gap,
    observable: !resized,
  });

  useImperativeHandle(ref, () => element);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape' && onClose) {
      event.stopPropagation();
      onClose(event);
    }
  };

  if (!open) {
    return null;
  }

  const paper = (
    <div
      className={clsx('icp-popover icp-thin-scrollbar', className)}
      style={{
        opacity: !position ? 0 : undefined,
        ...style,
        ...position,
        '--screen-gap': `${gap}px`,
      }}
      {...other}
      ref={setElement}
    >
      {children}
    </div>
  );

  return createPortal(
    <div className="icp-overlay" onKeyDown={handleKeyDown}>
      {!hideBackdrop ? <div className="icp-backdrop" onMouseDown={onClose} /> : null}
      <FocusTrap
        disableAutoFocus={disableAutoFocus}
        disableEnforceFocus={disableEnforceFocus}
        disableRestoreFocus={disableRestoreFocus}
      >
        {resizable ? (
          <Resizable placement="all" min={24} maxGap={12} onResizeStart={() => setResized(true)}>
            {paper}
          </Resizable>
        ) : (
          paper
        )}
      </FocusTrap>
    </div>,
    document.body,
  );
});

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  container: HTMLElementType,
  open: PropTypes.bool,
  anchorEl: PropTypes.oneOfType([
    HTMLElementType,
    PropTypes.shape({
      getBoundingClientRect: PropTypes.func,
    }),
  ]),
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'left-start',
    'left',
    'left-end',
  ]),
  offset: PropTypes.number,
  gap: PropTypes.number,
  resizable: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Popover;
