function getXY(anchorRect, elRect, { placement, offset }) {
  const horizontalMargin = anchorRect.width / 2 - elRect.width / 2;
  const verticalMargin = anchorRect.height / 2 - elRect.height / 2;

  switch (placement) {
    case 'top-start':
      return [anchorRect.left, anchorRect.top - elRect.height - offset];
    case 'top':
      return [anchorRect.left + horizontalMargin, anchorRect.top - elRect.height - offset];
    case 'top-end':
      return [anchorRect.right - elRect.width, anchorRect.top - elRect.height - offset];
    case 'right-start':
      return [anchorRect.right + offset, anchorRect.top];
    case 'right':
      return [anchorRect.right + offset, anchorRect.top + verticalMargin];
    case 'right-end':
      return [anchorRect.right + offset, anchorRect.bottom - elRect.height];
    case 'bottom-start':
      return [anchorRect.left, anchorRect.bottom + offset];
    case 'bottom':
      return [anchorRect.left + horizontalMargin, anchorRect.bottom + offset];
    case 'bottom-end':
      return [anchorRect.right - elRect.width, anchorRect.bottom + offset];
    case 'left-start':
      return [anchorRect.left - elRect.width - offset, anchorRect.top];
    case 'left':
      return [anchorRect.left - elRect.width - offset, anchorRect.top + verticalMargin];
    case 'left-end':
      return [anchorRect.left - elRect.width - offset, anchorRect.bottom - elRect.height];
    default:
      return [0, 0];
  }
}

// shift the x/y to ensure that the element is displayed within the container
function clampTo(xy, elRect, containerRect, { gap }) {
  const [x, y] = xy;
  return [
    Math.max(x + Math.min(containerRect.width - gap - (x + elRect.width), 0), gap),
    Math.max(y + Math.min(containerRect.height - gap - (y + elRect.height), 0), gap),
  ];
}

// flip mainAxis to another side
function flip(mainAxis) {
  const map = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  };
  return map[mainAxis];
}

function needFlip(xy, elRect, containerRect, { mainAxis, gap }) {
  const [x, y] = xy;

  if (mainAxis === 'top') {
    return y < gap;
  }
  if (mainAxis === 'bottom') {
    return y + elRect.height > containerRect.height - gap;
  }
  if (mainAxis === 'right') {
    return x + elRect.width > containerRect.width - gap;
  }
  if (mainAxis === 'left') {
    return x < gap;
  }
  return false;
}

function addScroll(xy, container) {
  return [xy[0] + container.scrollLeft, xy[1] + container.scrollTop];
}

export default function computePosition(anchorEl, element, { placement, offset = 0, gap = 0 }) {
  const container = element.parentNode;
  const containerRect = element.parentNode.getBoundingClientRect();
  const anchorRectOrigin = anchorEl.getBoundingClientRect();
  // 减去 containerRect 的屏幕距离，这样后续在用 anchorRect 的 top right bottom left 的时候就已经是相对于 container 的了
  const anchorRect = {
    width: anchorRectOrigin.width,
    height: anchorRectOrigin.height,
    top: anchorRectOrigin.top - containerRect.top,
    right: anchorRectOrigin.right - containerRect.left,
    bottom: anchorRectOrigin.bottom - containerRect.top,
    left: anchorRectOrigin.left - containerRect.left,
  };
  const elRect = element.getBoundingClientRect();
  const mainAxis = placement.split('-')[0];
  const crossAxis = placement.split('-')[1];

  let xy = getXY(anchorRect, elRect, { placement, offset });

  if (needFlip(xy, elRect, containerRect, { mainAxis, gap })) {
    const flippedMainAxis = flip(mainAxis);
    const flippedPlacement = [flippedMainAxis, crossAxis].filter(Boolean).join('-');
    const tryFlip = getXY(anchorRect, elRect, { placement: flippedPlacement, offset });

    // 如果 flip 到另一边也超出了 edge，则 fail，不进行 flip，恢复到指定的 mainAxis
    if (!needFlip(tryFlip, elRect, containerRect, { mainAxis: flippedMainAxis, gap })) {
      xy = tryFlip;
    }
  }

  xy = addScroll(clampTo(xy, elRect, containerRect, { gap }), container);

  return {
    left: xy[0],
    top: xy[1],
  };
}
