import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Button } from 'antd';
import { HELPER_TEXT_TYPES, I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT } from '@icp/form-renderer-core';
import { Icon } from '@icp/components';
import { composeEvent } from '@icp/utils';
import FieldTitle from '../../../FieldTitle';
import InputReadonlyText from './InputReadonlyText';
import InputSupportI18n from './InputSupportI18n';
import FormHelperText from '../../../FormHelperText';
import { useIsInDesign } from '../../../FormRenderCtx';

const InputAnt = forwardRef(function InputUIAnt(props, ref) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    allowI18nValue,
    componentProps = {},
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,

    i18nInputLayout,
    onToggleI18nInputLayout,
  } = props;

  const isInDesign = useIsInDesign();

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          <InputReadonlyText {...props} />
        ) : (
          <>
            <InputSupportI18n
              {...componentProps}
              allowI18nValue={allowI18nValue}
              i18nInputLayout={i18nInputLayout}
              id={id}
              name={id}
              value={value}
              status={status}
              maxLength={validation?.maxLength}
              disabled={disabled}
              onChange={onChange}
              onBlur={composeEvent(onTouchChanged, componentProps.onBlur)}
              ref={ref}
            />
            <FormHelperText helpers={helpers} />
          </>
        )}
        {!isInDesign && !readonly && allowI18nValue ? (
          <Button
            className="i18n-input-layout-switch-button"
            size="small"
            type="text"
            icon={
              i18nInputLayout === I18N_VALUE_COLLAPSED ? (
                <Icon name="oct:chevron-right" size={16} />
              ) : (
                <Icon name="oct:chevron-down" size={16} />
              )
            }
            onClick={onToggleI18nInputLayout}
          />
        ) : null}
      </div>
    </>
  );
});

InputAnt.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  allowI18nValue: PropTypes.bool,
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({}),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  onToggleI18nInputLayout: PropTypes.func,
};

export default InputAnt;
