import { restApi } from '@icp/settings';
import { parseJSON } from '@icp/utils';

const localPbcList = [];
(function importS() {
  const r = require.context('@site/src/pbc', true, /(basic\.json$)/);
  r.keys()
    // filter outer duplicate key caused by webpack.config.js resolve.context
    .filter((key) => key.startsWith('./'))
    .forEach((key) => {
      const content = r(key);
      const paths = key.split('/');
      if (paths.length === 3) {
        localPbcList.push(content);
      }
    });
})();

const allLocalPbcRoutes = {};
(function importS() {
  const r = require.context('@site/src/pbc', true, /(routes\.json$)/);
  r.keys()
    // filter outer duplicate key caused by webpack.config.js resolve.context
    .filter((key) => key.startsWith('./'))
    .forEach((key) => {
      const content = r(key);
      const paths = key.split('/');
      const [, pbcToken] = paths;
      if (paths.length === 3) {
        allLocalPbcRoutes[pbcToken] = content;
      }
    });
})();

function parseRemotePbcRoutes(remotePbcList) {
  if (!Array.isArray(remotePbcList)) {
    return remotePbcList;
  }

  const tokenSet = new Set();

  return remotePbcList.map((pbc) => {
    if (pbc.category !== 'BUSINESS') {
      return pbc;
    }

    if (tokenSet.has(pbc.token)) {
      throw new Error(`Two pbc has same token ${pbc.token}`);
    }

    tokenSet.add(pbc.token);

    const { routesJson, ...other } = pbc;

    const parsedRoutes = parseJSON(routesJson, { silent: true }) || {
      desktop: {},
      mobile: {},
    };

    return {
      ...other,
      routes: parsedRoutes,
    };
  });
}

function loadLocalPbcWithRoutes() {
  return localPbcList.map((pbc) => {
    if (pbc.category !== 'BUSINESS') {
      return pbc;
    }
    const routes = allLocalPbcRoutes[pbc.token] || {
      desktop: {},
      mobile: {},
    };
    return {
      ...pbc,
      routes,
    };
  });
}

export default function loadPbcList() {
  if (process.env.SAAS_MODE) {
    return restApi.get(`/form/api/pbc/list`).then(parseRemotePbcRoutes);
  }

  return loadLocalPbcWithRoutes();
}
