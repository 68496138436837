import { EvalWorker } from '@icp/utils';
import { setAllTouched, validateForm } from '../store';
import { getDataWithPreprocessor } from './formDataUtils';

export default async function processSubmitData({
  store,
  formApi,
  suppressValidation,
  saveOnly,
  transformDataSubmit,
}) {
  const { dispatch } = store;

  await dispatch(setAllTouched());

  // If save only, do not validate form data
  if (!saveOnly && !suppressValidation) {
    try {
      const errors = await dispatch(validateForm()).unwrap();
      if (errors) throw errors;
    } finally {
      // dispatch(setSubmitting(false));
    }
  }

  const dataWithPreprocessor = getDataWithPreprocessor({ store, formApi });

  if (transformDataSubmit) {
    return EvalWorker.shared().execEval(dataWithPreprocessor, transformDataSubmit, {
      params: { context: store.getState().context },
    });
  }

  return dataWithPreprocessor;
}
