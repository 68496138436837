import './ACL.css';
import { forwardRef, useImperativeHandle, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { chunk, pick } from 'lodash-es';
import { restApi, message } from '@icp/settings';
import { useTranslation } from 'react-i18next';
import { chooseFile } from '@icp/utils';
import ACLDialog from './ACLDialog';
import {
  differenceByString,
  excludeData,
  exportExcel,
  getIdsInValue,
  importExcel,
  intersectionByString,
  getValue,
} from './utils';
import ACLInputUI from './ACLInputUI';
import ACLImportExport from './ACLImportExport';

const ACL = forwardRef(function ACL(props, ref) {
  const {
    componentLibrary,
    id,
    className,
    value,
    mapping,
    idListUrl,
    dataUrl,
    isStandardDataSource,
    dataResponseKeyPath,
    translateDataResponse,
    transformDataResponse,
    title,
    placeholder,
    status,
    size = 'default',
    multiple,
    stringEqual,
    unit,
    columnDefs,
    outerFilterModel,
    dataExclusion,
    supportImport,
    supportExport,
    exportColumns,
    maxSizePerReq,
    transformKey,
    transformUrl,
    disabled,
    readonly,
    required,
    helperText,
    clickUseCapture = false,
    InputProps,
    ButtonProps,
    DialogProps,
    AgTableProps,
    onChange,
    ...other
  } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);

  const [open, setOpen] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    import: handleImport,
    export: handleExport,
  }));

  const [modal, contextHolder] = Modal.useModal();

  const handleChange = (newValue) => {
    onChange(newValue);
    setOpen(false);
  };
  // 有主键，走原流程
  // 没主键，call转换地址用数据换主键
  const setImportValue = (xlsxData) => {
    if (xlsxData.length === 0) {
      message.warning(t('acl.import-no-data'));
      return Promise.resolve();
    }

    const hasPK = mapping.value in xlsxData[0];
    const hasLabel = mapping.label in xlsxData[0];
    const hasTransformKey = transformKey in xlsxData[0];

    if ((!hasPK && !hasTransformKey) || !hasLabel) {
      message.warning(
        t('acl.import-missing-column.prefix', { value1: mapping.value, label1: mapping.label }) +
          (transformKey
            ? t('acl.import-missing-column.middle', {
                transformKey,
                label2: mapping.label,
              })
            : '') +
          t('acl.import-missing-column.suffix'),
      );
      return Promise.resolve();
    }

    function execute(_xlsxData) {
      return restApi.get(idListUrl).then((results) => {
        results = excludeData(results, dataExclusion);
        // 是否有无效的数据 跟allIds进行比较
        const invalidList = differenceByString(_xlsxData, results, stringEqual, (comparedData) => {
          return getValue(comparedData, mapping.value);
        });
        const validList = intersectionByString(_xlsxData, results, stringEqual, (comparedData) => {
          return getValue(comparedData, mapping.value);
        });
        if (invalidList.length > 0) {
          const columnsKeys = Object.keys(invalidList[0]);
          const invalidHeader = columnsKeys.map((key) => <th>{key}</th>);
          const invalidMessage = invalidList.map((invalidVal, index) => {
            return (
              <tr key={index}>
                {columnsKeys.map((key, columnsIndex) => (
                  <td key={columnsIndex}>{invalidVal[key]} </td>
                ))}
              </tr>
            );
          });
          modal.error({
            title: t('acl.import-not-valid'),
            content: (
              <table className="acl-import-message-table">
                <thead>
                  <tr>{invalidHeader}</tr>
                </thead>
                <tbody>{invalidMessage}</tbody>
              </table>
            ),
          });
        }
        if (validList.length > 0) {
          const formattedXlsxData = validList.map((item) => ({
            value: item[mapping.value],
            label: item[mapping.label],
            ...item,
          }));
          onChange(formattedXlsxData);
        }
      });
    }

    if (!hasPK) {
      const transformKeyValues = xlsxData.map((x) => x[transformKey]);
      const transformKeyValuesList = chunk(transformKeyValues, maxSizePerReq);

      return Promise.all(
        transformKeyValuesList.map((tkvList) =>
          restApi.post(transformUrl, {
            key: transformKey,
            values: tkvList,
          }),
        ),
      )
        .then((results) => results.flatMap((x) => x))
        .then((pkList) =>
          xlsxData.map((x, i) => ({
            ...x,
            [mapping.value]: pkList[i],
          })),
        )
        .then(execute);
    }
    // 如果正常情况 useOriginValue为false value的格式应该是 {value:xxx,lable:xxx}

    return execute(xlsxData);
  };

  const handleImport = () => {
    chooseFile({
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }).then((file) => {
      setImportLoading(true);
      importExcel(file, (xlsxData) => {
        setImportValue(xlsxData).finally(() => setImportLoading(false));
      });
    });
  };

  const handleExport = () => {
    setExportLoading(true);

    // TODO, when isLazy is false
    const fetchListByIds = (ids) => {
      const idsList = chunk(ids, maxSizePerReq);
      return Promise.all(
        idsList.map((_ids) => restApi.get(dataUrl, { params: { ids: String(_ids) } })),
      ).then((results) => results.flatMap((x) => x));
    };

    const ids = getIdsInValue(value, stringEqual);
    return fetchListByIds(ids)
      .then((results) => {
        const exportData = results.map((item) => ({
          ...pick(
            item,
            exportColumns && exportColumns.length > 0
              ? exportColumns.map((column) => column.value)
              : [mapping.value, mapping.label],
          ),
        }));

        const column = exportColumns.map((exportColumn) => ({
          title: exportColumn.value,
          key: exportColumn.value,
          label: exportColumn.label,
        }));
        exportExcel(column, exportData, `${title}.xlsx`);
      })
      .finally(() => setExportLoading(false));
  };

  const clickPropName = clickUseCapture ? 'onClickCapture' : 'onClick';

  return (
    <div className={clsx('icp-acl', className)} {...other}>
      <ACLInputUI
        componentLibrary={componentLibrary}
        title={title}
        readonly={readonly}
        required={required}
        helperText={helperText}
        id={id}
        value={value}
        size={size}
        disabled={disabled}
        placeholder={placeholder}
        status={status}
        multiple={multiple}
        unit={unit}
        openButtonProps={{
          [clickPropName]: (event) => {
            event.stopPropagation();
            if (!readonly && !disabled) {
              setOpen(true);
            }
          },
        }}
        importLoading={importLoading}
      />
      {supportImport || supportExport ? (
        <ACLImportExport
          componentLibrary={componentLibrary}
          supportImport={supportImport}
          supportExport={supportExport}
          size={size}
          disabled={disabled}
          importLoading={importLoading}
          exportLoading={exportLoading}
          onImport={handleImport}
          onExport={handleExport}
        />
      ) : null}
      {/* ACLDialog 依赖这里 open 直接不渲染来刷新 dataSource，如果 open 挪到 ACLDialog 里面注意 dataSource 的刷新 */}
      {open ? (
        <ACLDialog
          {...DialogProps}
          componentLibrary={componentLibrary}
          AgTableProps={AgTableProps}
          value={value}
          mapping={mapping}
          title={title}
          unit={unit}
          multiple={multiple}
          stringEqual={stringEqual}
          idListUrl={idListUrl}
          dataUrl={dataUrl}
          isStandardDataSource={isStandardDataSource}
          dataResponseKeyPath={dataResponseKeyPath}
          translateDataResponse={translateDataResponse}
          transformDataResponse={transformDataResponse}
          columnDefs={columnDefs}
          outerFilterModel={outerFilterModel}
          dataExclusion={dataExclusion}
          onClose={() => setOpen(false)}
          onChange={handleChange}
        />
      ) : null}
      {contextHolder}
    </div>
  );
});

ACL.propTypes = {
  componentLibrary: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      // Other key map saved
    }),
  ),
  // Mapping value keys to option data keys
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    // Other key map to save
  }),
  idListUrl: PropTypes.string,
  dataUrl: PropTypes.string,
  isStandardDataSource: PropTypes.bool,
  dataResponseKeyPath: PropTypes.string,
  translateDataResponse: PropTypes.bool,
  transformDataResponse: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  status: PropTypes.oneOf(['error', 'warning', 'normal']),
  size: PropTypes.oneOf(['default', 'small']),
  multiple: PropTypes.bool,
  stringEqual: PropTypes.bool,
  unit: PropTypes.string,
  outerFilterModel: PropTypes.arrayOf(PropTypes.shape({})),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  // eslint-disable-next-line react/forbid-prop-types
  dataExclusion: PropTypes.array,
  supportImport: PropTypes.bool,
  supportExport: PropTypes.bool,
  exportColumns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  maxSizePerReq: PropTypes.number,
  transformKey: PropTypes.string,
  transformUrl: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * 是否在捕获阶段触发点击事件
   */
  clickUseCapture: PropTypes.bool,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.bool,
  InputProps: PropTypes.shape({}),
  ButtonProps: PropTypes.shape({}),
  DialogProps: PropTypes.shape({}),
  AgTableProps: PropTypes.shape({}),
  onChange: PropTypes.func,
};

export default ACL;
