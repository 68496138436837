import './cellRenderers.css';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { ensureDisplayText } from '@icp/utils';
import { ConditionalPropertyPropType } from '../../../propTypes';
import LinkWrapper from '../../../wrappers/LinkWrapper';
import withCurrentData from './withCurrentData';

function MultiValueCellRenderer(props) {
  const {
    value,
    valueFormatted,
    separator,
    displayTextKeyPath,
    href,
    hrefSelector,
    hrefIsSiteBased,
    suppressBasePath,
    suppressInheritIncludeDeleted,
  } = props;

  // '[object Object]' 是因为 ag-grid 在 valueFormatted 做了多余的事情，最后判断如果 value 是 array 会强制调用 value.join(', '),
  // 导致即使 colDef 没有提供 valueFormatter 的时候 [{}, {}] 会变成 '[object Object], [object Object]'
  const displayText =
    typeof valueFormatted === 'number' ||
    (typeof valueFormatted === 'string' && !valueFormatted.startsWith('[object Object]'))
      ? valueFormatted
      : MultiValueCellRenderer.toDisplayText(value, {
          separator,
          displayTextKeyPath,
        });

  if (href || hrefSelector) {
    return (
      <LinkWrapper
        className="table-multi-value-cell"
        componentProps={{
          href,
          hrefSelector,
          hrefIsSiteBased,
          suppressBasePath,
          suppressInheritIncludeDeleted,
          suppressLinkColor: false,
        }}
      >
        {ensureDisplayText(displayText)}
      </LinkWrapper>
    );
  }

  return <span className="table-multi-value-cell">{ensureDisplayText(displayText)}</span>;
}

MultiValueCellRenderer.toDisplayText = (value, cellRendererParams) => {
  const { separator = '、', displayTextKeyPath = 'label' } = cellRendererParams || {};

  if (!Array.isArray(value)) {
    return null;
  }

  return value.map((x) => (displayTextKeyPath ? get(x, displayTextKeyPath) : x)).join(separator);
};

MultiValueCellRenderer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.shape({}),
  separator: PropTypes.string,
  displayTextKeyPath: PropTypes.string,
  // TODO, support pattern '/path/{id}'
  href: ConditionalPropertyPropType(PropTypes.string),
  hrefSelector: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      matched: ConditionalPropertyPropType(PropTypes.bool),
    }),
  ),
  /**
   * 如果值为 `false` 并且此链接位于 pbc 下，href 将自动拼接上 pbc 的 `token` 作为 `basename`，移动端将自动拼接上 `mobile` 的前缀
   */
  hrefIsSiteBased: PropTypes.bool,
  /**
   * 是否直接链接不通过 react router 的 base path
   */
  suppressBasePath: PropTypes.bool,
  suppressInheritIncludeDeleted: PropTypes.bool,
};

export default withCurrentData(MultiValueCellRenderer);
