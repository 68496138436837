import { forwardRef } from 'react';
import ACLMaterial from './ACLMaterial';
import ACLAnt from './ACLAnt';
import { useComponentLibrary } from '../../FormRenderCtx';

const ACLUI = forwardRef(function ButtonUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <ACLMaterial {...props} componentLibrary={componentLibrary} ref={ref} />;
  }

  return <ACLAnt {...props} componentLibrary={componentLibrary} ref={ref} />;
});

export default ACLUI;
