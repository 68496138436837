import { useState } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogUI } from '@icp/form-renderer';
import { getAppConfig } from '@icp/settings';
import { TextField } from '@mui/material';

function CommentDialog(props) {
  const { open, commentRequired, submitting, onOk, onClose, dialogProps = {} } = props;

  const { t } = useTranslation(['icp-common', 'icp-form-renderer']);
  const { componentLibrary } = getAppConfig() || {};

  const [comment, setComment] = useState('');

  const handleOk = () => {
    onOk(comment);
  };

  return (
    <DialogUI
      open={open}
      title={dialogProps.title ?? t('action.approval-comment', { ns: 'icp-form-renderer' })}
      submitting={submitting}
      okProps={{
        disabled: commentRequired && !comment,
      }}
      onOk={handleOk}
      onClose={onClose}
    >
      {componentLibrary === 'material-ui' ? (
        <TextField
          multiline={true}
          autoFocus={true}
          fullWidth={true}
          rows={3}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      ) : (
        <Input.TextArea
          autoFocus={true}
          rows={3}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      )}
    </DialogUI>
  );
}

CommentDialog.propTypes = {
  open: PropTypes.bool,
  commentRequired: PropTypes.bool,
  submitting: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  dialogProps: PropTypes.shape({ title: PropTypes.string }),
};

export default CommentDialog;
