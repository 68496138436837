import './Tabs.css';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Tab = forwardRef(function Tab(props, ref) {
  const { children, className, value, ...other } = props;

  return (
    <button
      className={clsx('icp-action-button icp-tab', className)}
      role="tab"
      ref={ref}
      {...other}
    >
      {children}
    </button>
  );
});

Tab.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
};

export default Tab;
