import PropTypes from 'prop-types';
import { I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT } from '@icp/form-renderer-core';
import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import I18nInputSingle from './I18nInputSingle';
import I18nInputMulti from './I18nInputMulti';
import { useValueLanguageOptions } from '../../../hooks';

const InputSupportI18n = forwardRef(function InputSupportI18n(props, ref) {
  const { id, value, allowI18nValue, i18nInputLayout, helperText, readonly, ...other } = props;

  const languageOptions = useValueLanguageOptions();

  if (!allowI18nValue) {
    return (
      <TextField
        {...other}
        value={value ?? ''}
        helperText={helperText}
        InputProps={{ readOnly: readonly }}
        ref={ref}
      />
    );
  }

  if (i18nInputLayout === I18N_VALUE_COLLAPSED) {
    return (
      <I18nInputMulti {...other} id={id} helperText={helperText} readonly={readonly} ref={ref} />
    );
  }

  if (i18nInputLayout === I18N_VALUE_FLAT) {
    return languageOptions.map((op, index) => (
      <I18nInputSingle
        {...other}
        key={op.value}
        id={id}
        languageOp={op}
        helperText={index === languageOptions.length - 1 ? helperText : undefined}
        readonly={readonly}
      />
    ));
  }

  return 'Invalid i18nInputLayout config';
});

InputSupportI18n.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allowI18nValue: PropTypes.bool,
  i18nInputLayout: PropTypes.oneOf([I18N_VALUE_COLLAPSED, I18N_VALUE_FLAT]),
  helperText: PropTypes.node,
  readonly: PropTypes.bool,
};

export default InputSupportI18n;
