import { joinPaths } from '@icp/utils';

// join with pbc token and merge with appRoutes
export default function flattenRoutes(appRoutes = {}, pbcList = []) {
  return pbcList
    .filter((pbc) => pbc.routes)
    .reduce((prev, current) => {
      const { token: pbcToken } = current;

      const currentDesktop = {};
      if (pbcToken) {
        for (const [key, value] of Object.entries(current.routes.desktop || {})) {
          currentDesktop[joinPaths([pbcToken, key])] = value;

          if (value.parent) {
            currentDesktop[joinPaths([pbcToken, key])] = {
              ...value,
              parent: joinPaths([pbcToken, value.parent]),
            };
          }
        }
      }

      const currentMobile = {};
      if (pbcToken) {
        for (const [key, value] of Object.entries(current.routes.mobile || {})) {
          currentMobile[joinPaths(['/mobile', pbcToken, key])] = value;

          if (value.parent) {
            currentDesktop[joinPaths([pbcToken, key])] = {
              ...value,
              parent: joinPaths([pbcToken, value.parent]),
            };
          }
        }
      }

      return {
        desktop: {
          ...prev.desktop,
          ...currentDesktop,
        },
        mobile: {
          ...prev.mobile,
          ...currentMobile,
        },
      };
    }, appRoutes);
}
