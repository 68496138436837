import { useDispatch, useSelector } from 'react-redux';
import { checkAuthState, selectAuthError, selectIsAuthing } from 'store/features/authSlice';
import { useEffect } from 'react';

export default function useAuthingState() {
  const dispatch = useDispatch();
  const isAuthing = useSelector(selectIsAuthing);
  const authError = useSelector(selectAuthError);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  return { isAuthing, authError };
}
