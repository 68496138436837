import { AIAgentFloatButton, AIAgent } from '@icp/components';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { findFieldBy } from '@icp/form-schema';
import { restApi } from '@icp/settings';
import { useCurrentPageForm } from '../../CurrentPageFormStoreProvider';

const JOB_SUGGESTION = 'jobsuggestion';
const CHAT_REQUEST_URL = '/aip/api/qf/hint';

function JobSuggestionAIAgent(props) {
  const { userProfile } = props;
  const [currentField, setCurrentField] = useState(null);
  const currentPageForm = useCurrentPageForm();

  useEffect(() => {
    if (!currentPageForm?.store) {
      return () => {};
    }
    const unsubscribe = currentPageForm.store?.subscribe(() => {
      const storeState = currentPageForm.store.getState();
      const fieldId = storeState.formData.currentFieldId;
      if (fieldId && currentField?.id !== fieldId) {
        const schema = currentPageForm.formApi.getProps().schema;
        const foundField = findFieldBy(schema, (field) => field.id === fieldId)?.field;
        setCurrentField(foundField);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [currentField, currentPageForm]);

  const chatApi = useMemo(
    () => ({
      name: JOB_SUGGESTION,
      url: CHAT_REQUEST_URL,
      stream: false,
      promptUpdater: {
        getValue: () => currentField?.title || '',
      },
      sendMessage({ prompt }, signal) {
        return restApi
          .post(CHAT_REQUEST_URL, { query: prompt, field: currentField }, { signal })
          .then((response) => {
            const references = response.content.flatMap((item) => item?.outputs?.references || []);
            return { answer: response.answer, references };
          });
      },
    }),
    [currentField],
  );

  return (
    <AIAgentFloatButton defaultOpen={true}>
      <AIAgent
        userProfile={userProfile}
        chatConfiguration={{ chatApi }}
        uiConfiguration={{
          inputToolbar: {
            hideModelPicker: true,
          },
        }}
      />
    </AIAgentFloatButton>
  );
}

JobSuggestionAIAgent.propTypes = {
  userProfile: PropTypes.shape({}),
};

JobSuggestionAIAgent.token = JOB_SUGGESTION;

export default JobSuggestionAIAgent;
