import './CodeEditor.css';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { formatJSCode, loadMonaco } from '@icp/utils';
import Loading from '../Loading';

// Currently, not support code update with react prop flow, it will cause some unexpected result.
// Currently, not support code change language, if you want change language, you can mount a new CodeEditor instance.
const CodeEditor = forwardRef(function CodeEditor(props, ref) {
  const {
    className,
    language,
    readOnly,
    bordered,
    simpleMode,
    autoFocus = false,
    formatJSCodeOnLoad = true,
    children = '',
    editorOptions,
    onEditorReady,
    ...other
  } = props;

  const [loading, setLoading] = useState(true);
  const container = useRef(null);
  const editorRef = useRef(null);

  useImperativeHandle(ref, () => editorRef.current);

  useEffect(() => {
    if (!container.current || editorRef.current) {
      return;
    }

    loadMonaco()
      .then((monaco) => {
        if (formatJSCodeOnLoad && language === 'javascript') {
          return formatJSCode(children).then((code) => ({ monaco, code }));
        }
        return { monaco, code: children };
      })
      .then(({ monaco, code }) => {
        const model = monaco.editor.createModel(code, language);

        editorRef.current = monaco.editor.create(container.current, {
          model,
          readOnly,
          automaticLayout: true,
          // fontSize: 13,
          // lineHeight: 20,
          lineNumbers: simpleMode ? 'off' : 'on',
          lineNumbersMinChars: 1,
          glyphMargin: true,
          renderLineHighlight: 'all',
          renderLineHighlightOnlyWhenFocus: true,
          folding: !simpleMode,
          scrollbar: {
            alwaysConsumeMouseWheel: false,
            useShadows: false,
          },
          scrollBeyondLastLine: false,
          padding: {
            top: 8,
            bottom: 18,
          },
          minimap: {
            enabled: false,
          },
          tabSize: 2,
          formatOnPaste: true,
          formatOnType: true,
          hideCursorInOverviewRuler: true,
          overviewRulerBorder: false,
          ...editorOptions,
        });

        setLoading(false);

        if (autoFocus) {
          editorRef.current.focus();
        }

        // 异步调用 onEditorReady，保证在 onEditorReady 过后 CodeEditor.ref 是可用给的
        setTimeout(() => {
          if (onEditorReady) {
            onEditorReady(editorRef.current);
          }
        }, 16);
      });
    // 不相应更新属性
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx('icp-code-editor', { bordered, 'simple-mode': simpleMode }, className)}
      {...other}
      ref={container}
    >
      {loading ? (
        <Loading style={{ position: 'absolute', left: 0, top: 0, width: '100%' }} />
      ) : null}
    </div>
  );
});

CodeEditor.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  bordered: PropTypes.bool,
  simpleMode: PropTypes.bool,
  autoFocus: PropTypes.bool,
  formatJSCodeOnLoad: PropTypes.bool,
  children: PropTypes.string,
  editorOptions: PropTypes.shape({}),
  onEditorReady: PropTypes.func,
};

export default CodeEditor;
