import { restApi } from '@icp/settings';
import AnalysisMessage, { AnalysisMessageType } from './AnalysisMessage';
import configuration from './configuration.json';
import {
  cacheData,
  clearCache,
  createMessage,
  getContentForContext,
  MessageType,
  restoreData,
} from '../../components/Chat/utils';

let cachedSchemas = {};
const storageKey = 'sale-consultation-ai';

function loadPageSchema(pbcToken, pageToken) {
  const path = `${pbcToken}/${pageToken}`;
  return new Promise((resolve, reject) => {
    if (cachedSchemas[path]) {
      resolve(cachedSchemas[path]);
      return;
    }
    const url = `/form/api/form-entity-page/get-by-schema-id/${path}`;
    restApi.get(url).then((response) => {
      const schema = response.schemaJson && JSON.parse(response.schemaJson);
      if (!schema) {
        reject(Error(`Something's wrong when getting schema of ${path}.`));
        return;
      }
      cachedSchemas[path] = schema;
      resolve(schema);
    });
  });
}

function loadFormData(path, params) {
  const url = `/form/api/v2/form-entity-data/${path}/list`;
  return restApi.get(url, { params }).then((response) => {
    return response.results;
  });
}

function buildSelectMessage(field, type, optionsMapping, resultMap) {
  const componentProps = field.componentProps;
  const { pbcToken, token } = componentProps.dataSource;
  const payload = buildFilterModel(resultMap, componentProps.dataFilters);
  return loadFormData(`${pbcToken}/${token}`, { payload }).then((data) => {
    return configureData(field, data, resultMap).then((results) => {
      const options = optionsMapping(results);
      const content = { identifier: field.id, title: field.title, options, handlerKey: 'onSelect' };
      const message = createMessage(type, content);
      message.data = results;
      return message;
    });
  });
}

function buildSingleSelectMessage(field, resultMap) {
  const { label: labelKey, value: valueKey } = field.componentProps.mapping;
  const optionsMapping = (results) =>
    results.map((item) => {
      return { label: item[labelKey], value: item[valueKey] };
    });
  return buildSelectMessage(field, MessageType.SingleSelect, optionsMapping, resultMap);
}

function buildMultipleSelectMessage(field, resultMap) {
  const columnDefs = field.componentProps.columnDefs;
  const optionsMapping = (results) =>
    results.map((item) => {
      const label = columnDefs.map((def) => transformValue(item, def)).join(' ');
      return { label, value: item.id };
    });
  return buildSelectMessage(field, MessageType.MultipleSelect, optionsMapping, resultMap);
}

function transformValue(item, columnDef) {
  const key = columnDef.field;
  const rawValue = item[key];
  const isSingleElementArray = Array.isArray(rawValue) && rawValue.length === 1;
  const value = isSingleElementArray ? rawValue[0] : rawValue;
  if (typeof value === 'object' && value.label) {
    return value.label || value.value;
  }
  if (typeof value === 'string' && key.toLowerCase().includes('ratio') && !value.includes('%')) {
    return `${value}%`;
  }
  return value;
}

function buildFilterModel(valueSource, dataFilters) {
  if (!dataFilters || dataFilters.length === 0) {
    return null;
  }
  const filters = dataFilters.map((filter) => {
    const { id, type, valueFrom } = filter;
    const { sourceKey, keyPaths, type: valueType } = valueFrom;
    const value = getValue(valueSource[sourceKey], keyPaths, 0, valueType);
    return { colId: id, filterType: valueType, type, filter: value };
  });
  return { filterModel: filters };
}

function getValue(source, paths, depth, type) {
  if (depth >= paths.length) {
    return type === 'text' ? source.toString() : source;
  }
  const key = paths[depth];
  if (key !== null && key !== undefined && source[key]) {
    return getValue(source[key], paths, depth + 1, type);
  }
  return source;
}

function triggerFlow(link, payloadConfig, resultMap) {
  const payload = {};
  payloadConfig.forEach((item) => {
    const { key, valueFrom } = item;
    const { sourceKey, keyPaths } = valueFrom;
    payload[key] = getValue(resultMap[sourceKey], keyPaths, 0);
  });
  return restApi.post(link, payload);
}

function updateResultMapCache(resultMap) {
  const item = restoreData(storageKey);
  item.resultMap = resultMap;
  cacheData(storageKey, item);
}

function resetUIData() {
  cachedSchemas = {};
  clearCache(storageKey);
}

function messageFactory(type, props) {
  const matchTypeResult = /customized\[(\w+)]/.exec(type);
  if (matchTypeResult && matchTypeResult.length >= 2) {
    const componentType = matchTypeResult[1];
    if (componentType === 'AnalysisMessage') {
      return <AnalysisMessage {...props} />;
    }
  }
  return null;
}

function configureData(field, data, resultMap) {
  return new Promise((resolve) => {
    // Special logic for business data
    if (field.id === 'select-shareholders' && Array.isArray(data)) {
      const componentProps = field.componentProps;
      const { pbcToken, token, key } = componentProps.extraDataSource;
      const payload = buildFilterModel(resultMap, componentProps.dataFilters);
      loadFormData(`${pbcToken}/${token}`, { payload }).then((results) => {
        const shareholdersInConcertMap = {};
        results.forEach((item) => {
          item[key]?.forEach((shareholder) => {
            shareholdersInConcertMap[shareholder.value.toString()] = item[key];
          });
        });
        data.forEach((item) => {
          item.shareholdersSelection = shareholdersInConcertMap[item.id.toString()] || [];
        });
        resolve(data);
      });
    } else {
      resolve(data);
    }
  });
}

function getMockAnalysis() {
  const { mockAnalysis } = configuration;
  const { pbcToken, pageToken } = mockAnalysis;
  return loadPageSchema(pbcToken, pageToken).then((result) => {
    const adviceField = result.fields.find((field) => field.id === 'adviceForAllRules');
    const output = { adviceForAllRules: adviceField?.defaultValue?.longText || '' };
    const tagListField = result.fields.find((field) => field.id === 'tagList');
    if (!adviceField || !tagListField) {
      return { output, tagList: [] };
    }
    const tagList = tagListField.componentProps.options
      .filter((option) => tagListField.defaultValue.includes(option.value))
      .map((option) => option.label);
    return { output, tagList };
  });
}

function buildAnalysisMessage(resultMap) {
  const title = resultMap['select-shareholders'].data.map((item) => item.holdingSubject).join(', ');
  const props = { title, resultMap };
  return createMessage(AnalysisMessageType, { props });
}

function handleGetContentForContext(message) {
  if (message.type === AnalysisMessageType) {
    const resultMap = message.content.props.resultMap;
    return resultMap.analysis.output.adviceForAllRules;
  }
  return getContentForContext(message);
}

function consultLawyer(resultMap) {
  const url = '/flow/api/flow-rest/consult-lawyer';
  const payload = {
    company: resultMap['select-company'].data,
    shareholders: resultMap['select-shareholders'].data,
    rules: resultMap.analysis.output.adviceForAllRules,
  };
  return restApi.post(url, payload);
}

function analyze(link, payloadConfig, useMockResponse, resultMap) {
  if (useMockResponse) {
    return getMockAnalysis();
  }
  return triggerFlow(link, payloadConfig, resultMap);
}

export {
  analyze,
  buildAnalysisMessage,
  buildSingleSelectMessage,
  buildMultipleSelectMessage,
  consultLawyer,
  handleGetContentForContext,
  loadPageSchema,
  messageFactory,
  resetUIData,
  storageKey,
  updateResultMapCache,
};
