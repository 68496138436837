import 'abort-controller/polyfill';

// abort-controller/polyfill 没有实现throwIfAborted方法
if (!AbortSignal.prototype.throwIfAborted) {
  AbortSignal.prototype.throwIfAborted = function throwIfAborted() {
    if (this.aborted) {
      throw Error(this.reason || 'AbortError');
    }
  };
}

if (!AbortSignal.abort && Object.isExtensible(AbortSignal)) {
  AbortSignal.abort = (reason) => {
    const controller = new AbortController();
    controller.abort(reason);
    return controller.signal;
  };
}

if (!AbortSignal.timeout && Object.isExtensible(AbortSignal)) {
  AbortSignal.timeout = (time) => {
    const controller = new AbortController();
    setTimeout(() => controller.abort('Timeout'), time);
    return controller.signal;
  };
}
