import isIndex from './isIndex';

export function findParentNode(target, clsOrCb) {
  const find = (node) => {
    if (!node || node.nodeType !== 1) {
      return null;
    }

    // string means find by class name
    if (typeof clsOrCb === 'string' && node.classList.contains(clsOrCb)) {
      return node;
    }

    // function callback
    if (typeof clsOrCb === 'function' && clsOrCb(node)) {
      return node;
    }

    return find(node.parentNode);
  };

  return find(target);
}

export function ensureVisible(scrollDiv, selector, offset = 0) {
  if (!scrollDiv) return;
  const item = scrollDiv.querySelector(selector);
  if (!item) return;

  const scrollBox = scrollDiv.getBoundingClientRect();
  const itemBox = item.getBoundingClientRect();

  // 元素自身或者父亲 display none 时，没有 width 和 height，此时去定位无意义
  if (!itemBox.width || !itemBox.height) {
    return;
  }

  // horizontal scroll
  const leftDistance = itemBox.left - scrollBox.left;
  const rightDistance = itemBox.right - scrollBox.right;
  if (leftDistance < 0) {
    scrollDiv.scrollLeft += leftDistance - offset;
  } else if (rightDistance > 0 && rightDistance < leftDistance) {
    // 最大只移动 leftDistance - offset，否则会让左侧不可见
    scrollDiv.scrollLeft += Math.min(rightDistance + offset, leftDistance - offset);
  }

  // vertical scroll
  const topDistance = itemBox.top - scrollBox.top;
  const bottomDistance = itemBox.bottom - scrollBox.bottom;
  if (topDistance < 0) {
    scrollDiv.scrollTop += topDistance - offset;
  } else if (bottomDistance > 0) {
    // 最大只移动 topDistance - offset，否则会让顶部不可见
    scrollDiv.scrollTop += Math.min(bottomDistance + offset, topDistance - offset);
  }
}

export function getDataKeyPath(target) {
  return (target?.getAttribute('data-key-path') || '')
    .split(',')
    .filter(Boolean)
    .map((key) => {
      if (isIndex(key)) {
        return Number(key);
      }
      return key;
    });
}
