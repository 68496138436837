import { forwardRef } from 'react';
// import { useComponentLibrary } from '../../FormRenderCtx';
import ProgressAnt from './ProgressAnt';
// import ProgressMaterial from './ProgressMaterial';

const ProgressUI = forwardRef(function InputUI(props, ref) {
  // const componentLibrary = useComponentLibrary();

  /* if (componentLibrary === 'material-ui') {
    return <ProgressMaterial {...props} ref={ref} />;
  } */

  return <ProgressAnt {...props} ref={ref} />;
});

export default ProgressUI;
