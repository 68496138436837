function* IdGenerator() {
  let i = 1;
  while (true) {
    yield i;
    i += 1;
    if (i === Number.MAX_SAFE_INTEGER) {
      i = 1;
    }
  }
}

export default class EvalWorker {
  static #shared = null;

  #worker = null;

  #jobIdGenerator = IdGenerator();

  constructor() {
    if (!window.Worker) {
      console.error(Error('Upgrade your browser to execute js expression'));
      return;
    }
    this.#worker = new Worker(`${window.ICP_PUBLIC_PATH}eval-worker.js`);
    this.#worker.onmessageerror = console.error;
    this.#worker.onerror = console.error;
  }

  static shared() {
    if (!this.#shared) {
      this.#shared = new EvalWorker();
    }
    return this.#shared;
  }

  #newJobId() {
    return this.#jobIdGenerator.next().value;
  }

  terminate() {
    if (this === EvalWorker.shared()) {
      console.warn('Cannot terminate the shared EvalWorker.');
      return;
    }
    this.#worker.terminate();
  }

  execEval(context = {}, expression = '', config = {}) {
    if (!this.#worker) {
      return Promise.reject(Error('Upgrade your browser to execute js expression'));
    }

    const { signal, params, terminateOnFinish = false } = config || {};

    const controller = new AbortController();
    const jobId = this.#newJobId();

    let onmessage;

    const p = new Promise((resolve, reject) => {
      onmessage = (e) => {
        if (e.data.jobId === jobId) {
          const { error, result } = e.data;
          if (error) {
            reject(error.message);
          } else {
            resolve(result);
          }
        }
      };

      this.#worker.addEventListener('message', onmessage);

      this.#worker.postMessage({
        jobId,
        context,
        expression,
        params,
        PUBLIC_PATH: window.ICP_PUBLIC_PATH,
      });
    })
      .then((result) => {
        signal?.throwIfAborted();
        controller.signal.throwIfAborted();
        return result;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.#worker.removeEventListener('message', onmessage);
      });

    if (terminateOnFinish) {
      p.finally(() => this.terminate());
    }

    p.abort = (reason) => controller.abort(reason);

    return p;
  }
}
