import { set } from 'lodash-es';
import { restApi } from '@icp/settings';
import { useEffect, useRef, useState } from 'react';

// Map<String, Promise<Field[]>> key: <project-token>#@#<pbc-token>#@#<form-entity-token>
const cache = new Map();

async function fetchFieldList(projectToken, pbcToken, formEntityToken, signal) {
  const cacheKey = [projectToken, pbcToken, formEntityToken].join('#@#');
  if (cache.has(cacheKey)) {
    return cache.get(cacheKey);
  }

  const loader = restApi
    .get(`/form/api/v2/form-entity-field/list/${pbcToken}/${formEntityToken}/${projectToken}`, {
      signal,
    })
    .catch((error) => {
      cache.delete(cacheKey);
      throw error;
    });

  cache.set(cacheKey, loader);

  return loader;
}

async function fetchFields(projectToken, pbcToken, formEntityToken, signal, map = {}) {
  const fields = await fetchFieldList(projectToken, pbcToken, formEntityToken, signal);
  set(map, [pbcToken, formEntityToken], fields);

  const results = await Promise.all(
    fields
      .filter(
        (field) =>
          [
            'ACL',
            // 'SELECT'
          ].includes(field.type) &&
          // TODO: 目前只支持单选ACL
          !field.multiple &&
          field.referencePbc &&
          field.referenceEntity,
      )
      .filter((field) => !map[field.referencePbc]?.[field.referenceEntity])
      .map((field) =>
        fetchFields(projectToken, field.referencePbc, field.referenceEntity, signal, map),
      ),
  );

  const maxDepth = Math.max(0, ...results.map(([, , depth]) => depth));

  return [fields, map, maxDepth + 1];
}

function convertToTreeData(
  fields,
  fieldMap,
  maxDepth,
  currentDepth = 1,
  valuePrefix = '',
  resolver = {},
) {
  const treeData = fields.map((field) => {
    const hasChildren = !!fieldMap[field.referencePbc]?.[field.referenceEntity];

    const subResolver = {};

    resolver[field.token] = {
      label: field.name,
      field,
      resolver: subResolver,
    };

    const node = {
      value: `${valuePrefix}${field.token}`,
      label: field.name,
      isLeaf: !hasChildren,
      field,
    };

    const loadChildren = () => {
      node.children = convertToTreeData(
        fieldMap[field.referencePbc]?.[field.referenceEntity] || [],
        fieldMap,
        maxDepth,
        currentDepth + 1,
        `${valuePrefix}${field.token}.`,
        subResolver,
      )[0];
    };

    if (hasChildren) {
      if (currentDepth <= maxDepth) {
        loadChildren();
      } else {
        node.loadChildren = loadChildren;
      }
    }

    return node;
  });

  return [treeData, resolver];
}

function extractTreeDataKeys(treeData) {
  return (
    treeData
      ?.filter((x) => !x.isLeaf && x.children)
      ?.flatMap((x) => [x.value, ...extractTreeDataKeys(x.children)]) || []
  );
}

export function useLoadTreeData({ initialValue, projectToken, pbcToken, formEntityToken }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const initialValueRef = useRef(initialValue || []);

  useEffect(() => {
    if (!projectToken || !pbcToken || !formEntityToken) {
      return () => {};
    }
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);

    fetchFields(projectToken, pbcToken, formEntityToken, signal)
      .then(([rootFields, fieldMap, maxDepth]) => {
        const [initialTreeData, labelResolver] = convertToTreeData(
          rootFields,
          fieldMap,
          Math.max(0, maxDepth, ...initialValueRef.current.map((x) => x.split('.').length - 1)),
        );

        setData({
          resolveFieldInValue: (valueInStringArray) =>
            valueInStringArray.map((singleStringValue) => {
              return singleStringValue
                .split('.')
                .reduce(
                  ([result, resolver], fieldToken) => [
                    [...result, resolver?.[fieldToken]?.field],
                    resolver?.[fieldToken]?.resolver,
                  ],
                  [[], labelResolver],
                )[0];
            }),
          resolveLabelInValue: (valueInStringArray) =>
            valueInStringArray.map((singleStringValue) => {
              const label = singleStringValue
                .split('.')
                .reduce(
                  ([result, resolver], fieldToken) => [
                    [...result, `${resolver?.[fieldToken]?.label ?? fieldToken}`],
                    resolver?.[fieldToken]?.resolver,
                  ],
                  [[], labelResolver],
                )[0]
                .join(' > ');
              return {
                value: singleStringValue,
                label,
              };
            }),
          treeData: initialTreeData,
          loadedKeys: extractTreeDataKeys(initialTreeData),
          loadData: async (node) => {
            node.loadChildren();
            setData((prev) => {
              return {
                ...prev,
                treeData: [...prev.treeData],
                loadedKeys: extractTreeDataKeys(prev.treeData),
              };
            });
          },
        });
      })
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, [projectToken, pbcToken, formEntityToken]);

  return [data, loading];
}
