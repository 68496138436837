import './SearchBox.css';
import { Button, Input, Tooltip } from 'antd';
import { SearchIcon, XCircleFillIcon } from '@primer/octicons-react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useControlled, useEventCallback } from '@icp/hooks';
import SpeechButton from '../SpeechButton';

const animationTime = 200; // ms

const SearchBox = forwardRef(function SearchBox(props, ref) {
  const { t } = useTranslation(['icp-components']);

  const {
    className,
    open: openProp,
    placeholder = t('search.search'),
    suppressSpeech,
    autoFocus,
    defaultValue,
    debounce = true,
    onSearch,
    ...other
  } = props;

  const [value, setValue] = useControlled({ default: defaultValue });
  // opening, closing, open, close
  const [openState, setOpenState] = useState(openProp || defaultValue ? 'open' : 'close');

  const containerRef = useRef(null);
  const searchInputRef = useRef(null);
  const openTimeout = useRef(null); // open css animation time
  const changeTimeout = useRef(null); // debounce to trigger props.onSearch

  useImperativeHandle(ref, () => ({
    open: openSearch,
    focus: () => searchInputRef.current.focus(),
    blur: () => searchInputRef.current.blur(),
  }));

  const isOpening = openState === 'opening';
  const isOpened = openState === 'open';
  const isClosed = openState === 'close';
  const isClosing = openState === 'closing';

  const openSearch = () => {
    if (!isClosed) {
      searchInputRef.current.focus();
      return;
    }
    setOpenState('opening');

    clearTimeout(openTimeout.current);
    openTimeout.current = setTimeout(() => {
      setOpenState('open');
      searchInputRef.current.focus();
    }, animationTime);
  };

  const closeSearch = () => {
    if (openProp) return;
    setOpenState('closing');
    clearTimeout(openTimeout.current);
    clearTimeout(changeTimeout.current);
    openTimeout.current = setTimeout(() => {
      setOpenState('close');
    }, animationTime);
  };

  const clearSearch = () => {
    if (value !== '') {
      setValue('');
      onSearch('');
    }
    closeSearch();
  };

  const clickToClose = useEventCallback((event) => {
    if ((isOpened || isOpening) && !value && !containerRef.current.contains(event.target)) {
      closeSearch();
    }
  });

  useEffect(() => {
    document.addEventListener('click', clickToClose, true);
    return () => {
      document.removeEventListener('click', clickToClose, true);
    };
  }, [clickToClose]);

  const handleChange = (event) => {
    const newText = event.target.value;
    setValue(newText);

    if (debounce) {
      clearTimeout(changeTimeout.current);
      changeTimeout.current = setTimeout(() => {
        onSearch(newText);
      }, 400);
    } else {
      onSearch(newText);
    }
  };

  const handleSpeech = (newText) => {
    setValue(newText);
    onSearch(newText);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      if (!openProp) {
        event.preventDefault();
        event.stopPropagation();
      }
      clearSearch();
    }
  };

  return (
    <div
      className={clsx(
        'icp-search',
        {
          'icp-opened': isOpened,
          'icp-opening': isOpening,
          'icp-closed': isClosed,
          'icp-closing': isClosing,
        },
        className,
      )}
      ref={containerRef}
      {...other}
    >
      {isOpened || isOpening ? (
        <Input
          className="icp-search-input"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          prefix={<SearchIcon size={16} />}
          suffix={
            (value && (
              <Tooltip title={t('search.search-clear')}>
                <button className="icp-center" onClick={clearSearch} style={{ color: '#70757a' }}>
                  <XCircleFillIcon size={16} />
                </button>
              </Tooltip>
            )) ||
            (isOpened && !suppressSpeech && (
              <SpeechButton
                onChange={handleSpeech}
                tooltip={t('search.speech-click')}
                speechStartTip={t('search.speech-start')}
                speechErrorTip={t('search.speech-error')}
                speechListeningTip={t('search.speech-listening')}
              />
            ))
          }
          autoFocus={autoFocus}
          style={isClosed ? { width: 0, opacity: 0, padding: 0 } : undefined}
          ref={searchInputRef}
        />
      ) : null}
      {isClosed ? (
        <Tooltip title={t('search.search')}>
          <Button
            className="icp-search-button"
            type="text"
            icon={<SearchIcon size={16} />}
            onClick={openSearch}
          />
        </Tooltip>
      ) : null}
      {/* 用 2 个一样的 button 来实现点击过后立马隐藏 antd 的 tooltip */}
      {isOpening || isClosing ? (
        <Button
          className="icp-search-button"
          type="text"
          icon={<SearchIcon size={16} />}
          onClick={openSearch}
        />
      ) : null}
    </div>
  );
});

SearchBox.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  suppressSpeech: PropTypes.bool,
  autoFocus: PropTypes.bool,
  debounce: PropTypes.bool,
  defaultValue: PropTypes.string,
  onSearch: PropTypes.func,
};

export default SearchBox;
