import PropTypes from 'prop-types';
import { Checkbox, Space } from 'antd';
import clsx from 'clsx';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FormHelperText from '../../FormHelperText';
import { ConditionalPropertyPropType } from '../../propTypes';
import FieldTitle from '../../FieldTitle';

function CheckboxAnt(props) {
  const {
    id,
    title,
    value,
    validation,
    disabled,
    readonly,
    componentProps = {},
    fieldTitleProps,
    helpers,
    onChange,
    onTouchChanged,

    options,
    content,
    direction,
    gap,
  } = props;

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {Array.isArray(options) ? (
          <Checkbox.Group
            {...componentProps}
            className={clsx('row-element', componentProps.className)}
            name={id}
            value={value}
            disabled={disabled || readonly}
            onChange={onChange}
            onBlur={onTouchChanged}
          >
            <Space direction={direction} size={gap}>
              {options.map((item) => {
                return (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        ) : (
          <Checkbox
            {...componentProps}
            className={clsx('row-element', componentProps.className)}
            name={id}
            checked={value}
            disabled={disabled || readonly}
            onChange={(e) => onChange(e.target.checked)}
            onBlur={onTouchChanged}
          >
            {content}
          </Checkbox>
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

CheckboxAnt.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  ]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  content: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  gap: PropTypes.oneOfType([PropTypes.oneOf(['small', 'middle', 'large']), PropTypes.number]),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

export default CheckboxAnt;
