import './Reorderable.css';
import PropTypes from 'prop-types';
import { cloneElement, isValidElement, useRef } from 'react';
import { useForkRef } from '@icp/hooks';
import clsx from 'clsx';
import Draggable from '../Draggable';
import Reorder from './Reorder';

function Reorderable(props) {
  const { children, direction, onDragStart, onDrag, onDragEnd, onChange } = props;

  const dragRef = useRef(null);
  const elRef = useRef(null);
  const handleRef = useForkRef(children?.ref, elRef);
  const noTriggerClick = useRef(false);

  if (!isValidElement(children)) {
    console.error('Reorderable children is not an valid react element', children);
    return null;
  }

  const handleClick = (event) => {
    if (!noTriggerClick.current && children.props.onClick) {
      children.props.onClick(event);
    }
  };

  const handleDragStart = (event) => {
    dragRef.current = new Reorder({
      item: elRef.current,
      direction,
      callback: onChange,
    });
    dragRef.current.dragStart(event);
    noTriggerClick.current = true;

    if (onDragStart) {
      onDragStart(event);
    }
  };

  const handleDrag = (event, mouseMove) => {
    dragRef.current.drag(event, mouseMove);

    if (onDrag) {
      onDrag(event, mouseMove);
    }
  };

  const handleDragEnd = (event, mouseMove, isCancel) => {
    dragRef.current.dragEnd(event, mouseMove, isCancel);

    setTimeout(() => {
      noTriggerClick.current = false;
    }, 16);

    if (onDragEnd) {
      onDragEnd(event, mouseMove, isCancel);
    }
  };

  return (
    <Draggable onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd}>
      {cloneElement(children, {
        className: clsx(children.props.className, 'icp-reorderable'),
        onClick: handleClick,
        ref: handleRef,
      })}
    </Draggable>
  );
}

Reorderable.propTypes = {
  children: PropTypes.element,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  onDragStart: PropTypes.func,
  onDrag: PropTypes.func,
  onDragEnd: PropTypes.func,
  onChange: PropTypes.func,
};

export default Reorderable;
