/* eslint-disable react/destructuring-assignment */
export default function HTMLElementType(props, propName, componentName) {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }

  if (!props[propName]) {
    return null;
  }

  if (props[propName].nodeType !== 1) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. ` +
        `Expected an HTMLElement.`,
    );
  }
}
