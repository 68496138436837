import { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@icp/components';
import { templateLoader } from '@icp/app-core';

function TemplatePageRenderer(props) {
  const { name, pageName, ...other } = props;

  const TemplateLogin = useMemo(() => {
    return lazy(() => templateLoader[name]().then((module) => ({ default: module[pageName] })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense
      fallback={
        <div className="app-loading-wrapper">
          <Loading />
        </div>
      }
    >
      <TemplateLogin {...other} />
    </Suspense>
  );
}

TemplatePageRenderer.propTypes = {
  name: PropTypes.string,
  pageName: PropTypes.string,
};

function TemplateRender(props) {
  return <TemplatePageRenderer pageName="default" {...props} />;
}

function HomePage(props) {
  return <TemplatePageRenderer pageName="HomePage" {...props} />;
}

function Page404(props) {
  return <TemplatePageRenderer pageName="Page404" {...props} />;
}

function Login(props) {
  return <TemplatePageRenderer pageName="Login" {...props} />;
}

function BasicLogin(props) {
  return <TemplatePageRenderer pageName="BasicLogin" {...props} />;
}

function ResetPassword(props) {
  return <TemplatePageRenderer pageName="ResetPassword" {...props} />;
}

export default TemplateRender;
export { HomePage, Page404, Login, BasicLogin, ResetPassword };
