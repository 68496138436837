import PropTypes from 'prop-types';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Icon from '../Icon';

function SolarImportBold() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.53 10.47a.75.75 0 0 0-1.06 0l-1.72 1.72V4a.75.75 0 0 0-1.5 0v8.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0 0-1.06"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M17.748 12c-.448 0-.84.274-1.157.591l-3 3a2.25 2.25 0 0 1-3.182 0l-3-3C7.092 12.274 6.7 12 6.252 12H4a8 8 0 1 0 16 0z"
      />
    </svg>
  );
}

function SolarExportBold() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.845 7.905a.75.75 0 0 0 1.06 0l1.72-1.72v8.19a.75.75 0 0 0 1.5 0v-8.19l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.375 20.375a8 8 0 0 0 8-8h-3.75c-.943 0-1.414 0-1.707.293c-.293.293-.293.764-.293 1.707a2.25 2.25 0 0 1-4.5 0c0-.943 0-1.414-.293-1.707c-.293-.293-.764-.293-1.707-.293h-3.75a8 8 0 0 0 8 8"
      />
    </svg>
  );
}

function ACLImportExport(props) {
  const {
    componentLibrary,
    supportImport,
    supportExport,
    size,
    disabled,
    importLoading,
    exportLoading,
    onImport,
    onExport,
  } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);

  const [anchorEl, setAnchorEl] = useState(false);

  if (componentLibrary === 'material-ui') {
    const open = !!anchorEl;

    return (
      <>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Icon name="material:more-vert-rounded" size={20} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          MenuListProps={{
            sx: { minWidth: 140 },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {supportImport ? (
            <MenuItem onClick={onImport}>
              <ListItemIcon>
                <SolarImportBold />
              </ListItemIcon>
              {t('import', { ns: 'icp-common' })}
            </MenuItem>
          ) : null}
          {supportExport ? (
            <MenuItem onClick={onExport}>
              <ListItemIcon>
                <SolarExportBold />
              </ListItemIcon>
              {t('export', { ns: 'icp-common' })}
            </MenuItem>
          ) : null}
        </Menu>
      </>
    );
  }

  return (
    <Space>
      {supportImport && (
        <Button
          size={size}
          icon={<Icon name="excel" size={16} />}
          disabled={disabled}
          loading={importLoading}
          onClick={onImport}
        >
          {t('import', { ns: 'icp-common' })}
        </Button>
      )}
      {supportExport && (
        <Button
          size={size}
          icon={<Icon name="excel" size={16} />}
          onClick={onExport}
          disabled={disabled}
          loading={exportLoading}
        >
          {t('export', { ns: 'icp-common' })}
        </Button>
      )}
    </Space>
  );
}

ACLImportExport.propTypes = {
  componentLibrary: PropTypes.string,
  supportImport: PropTypes.bool,
  supportExport: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  importLoading: PropTypes.bool,
  exportLoading: PropTypes.bool,
  onImport: PropTypes.func,
  onExport: PropTypes.func,
};

export default ACLImportExport;
