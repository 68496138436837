import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getAppConfig } from '@icp/settings';
import DefaultElementDecorator from './DefaultElementDecorator';

const ctx = createContext();

export function FormRenderCtxProvider(props) {
  const {
    children,
    ElementDecorator,
    isInDesign,
    formDisabled,
    formReadonly,
    formApi,
    fieldApis,
    setFieldApi,
    allowedLanguages,
    componentLibrary,
  } = props;

  // FormRender 的 context 里放的几乎都是不会变化的 prop，
  // 所以也就无所谓一个变化导致整个 value 变化所有依赖的组件重新渲染的问题
  const value = useMemo(() => {
    return {
      ElementDecorator,
      isInDesign,
      formDisabled,
      formReadonly,
      formApi,
      fieldApis,
      setFieldApi,
      allowedLanguages,
      componentLibrary,
    };
  }, [
    ElementDecorator,
    isInDesign,
    formDisabled,
    formReadonly,
    formApi,
    fieldApis,
    setFieldApi,
    allowedLanguages,
    componentLibrary,
  ]);

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
}

FormRenderCtxProvider.propTypes = {
  children: PropTypes.node,
  ElementDecorator: PropTypes.func,
  isInDesign: PropTypes.bool,
  formDisabled: PropTypes.bool,
  formReadonly: PropTypes.bool,
  formApi: PropTypes.shape({}),
  fieldApis: PropTypes.shape({ current: PropTypes.shape({}) }),
  setFieldApi: PropTypes.func,
  allowedLanguages: PropTypes.arrayOf(PropTypes.string),
  componentLibrary: PropTypes.string,
};

export function useElementDecorator(noElementDecorator) {
  const { ElementDecorator } = useContext(ctx) || {};
  if (noElementDecorator || !ElementDecorator) {
    return DefaultElementDecorator;
  }
  return ElementDecorator;
}

export function useIsInDesign() {
  return useContext(ctx)?.isInDesign;
}

export function useFormDisabled() {
  return useContext(ctx)?.formDisabled;
}

export function useFormReadonly() {
  return useContext(ctx)?.formReadonly;
}

export function useFormApi() {
  return useContext(ctx)?.formApi;
}

export function useSetFieldApi() {
  return useContext(ctx)?.setFieldApi;
}

export function useAllowedLanguages() {
  const value = useContext(ctx);
  return (
    (value?.isInDesign ? value.allowedLanguages : getAppConfig()?.i18n?.allowedLanguages) || []
  );
}

export function useComponentLibrary() {
  const value = useContext(ctx);
  return value?.componentLibrary || getAppConfig()?.componentLibrary || 'ant-design';
}
