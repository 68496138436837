import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { forwardRef, useState } from 'react';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { Input } from 'antd';
import { useSelector } from '../../../store';
import ValueLanguageSelect from '../../../ValueLanguageSelect';

const I18nInputMulti = forwardRef(function I18nInputMulti(props, ref) {
  const { id, onChange, ...other } = props;

  const { i18n } = useTranslation();

  const [valueLanguage, setValueLanguage] = useState(i18n.language);
  const valueI18n = useSelector(selectFieldValueI18n(id, valueLanguage));

  const handleChange = (event) => {
    onChange(event, valueLanguage);
  };

  return (
    <Input
      {...other}
      value={valueI18n ?? ''}
      onChange={handleChange}
      addonBefore={<ValueLanguageSelect value={valueLanguage} onChange={setValueLanguage} />}
      ref={ref}
    />
  );
});

I18nInputMulti.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
};

export default I18nInputMulti;
