import PropTypes from 'prop-types';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space } from 'antd';
import { message } from '@icp/settings';
import clsx from 'clsx';
import { delay, isMobile, resolveVariablePattern } from '@icp/utils';
import { joinPbcToken } from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { useForkRef } from '@icp/hooks';
import { PAGE_TYPE, postApproveData } from '@icp/page-renderer-core';
import { ButtonUI, useDialogContext } from '@icp/form-renderer';
import FormRendererSelector from '../FormRendererSelector';
import CommentDialog from './CommentDialog';

const APPROVAL = 'APPROVAL';
const DISAPPROVAL = 'DISAPPROVAL';

const ApproveNodeFormRenderer = forwardRef(function ApproveNodeFormRender(props, ref) {
  const {
    pbcToken,
    flowInstanceId,
    threadId,
    instanceData,
    buttonsPosition,
    context: contextProp,
    agreeSuccessRedirect,
    refuseSuccessRedirect,
    hrefIsSiteBased,
    suppressSuccessLinkDelay = false,
    successLinkDelayTime = 2000,
    agreeText,
    refuseText,
    dialogProps,
    FormRendererProps,
  } = props;

  const {
    data: defaultData,
    formEntityDataId,
    formEntityId,
    layoutId,
    pbcToken: formPbcToken,
    formEntityToken,
    layoutToken,
    isCommentMandatoryForApproval,
    isCommentMandatoryForDisapproval,
  } = instanceData;

  const { t } = useTranslation(['icp-common', 'icp-form-renderer']);
  const params = useParams();
  const navigate = useNavigate();
  const isMobileEnv = isMobile();
  const dialogContext = useDialogContext();

  const [hasSchema, setHasSchema] = useState(false);
  const [approvalResult, setApprovalResult] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef(null);
  const handleRef = useForkRef(ref, formRef);

  // context must memo, every context change will trigger FormRender make new store
  const context = useMemo(() => {
    return {
      ...contextProp,
      pbcToken: formPbcToken,
      formEntityId,
      formEntityDataId,
      approvalResult,
      pageType: PAGE_TYPE.flowApproval,
    };
  }, [contextProp, formPbcToken, formEntityDataId, formEntityId, approvalResult]);

  const handleSubmit = (approvalComment) => {
    if (!formRef.current) return;
    setSubmitting(true);

    const handler = (formData) =>
      Promise.resolve({
        pbcToken,
        formPbcToken,
        flowInstanceId,
        threadId,
        data: { formData, approvalResult, approvalComment },
      })
        .then(postApproveData)
        .then((response) => {
          let successRedirect;
          if (approvalResult === APPROVAL && agreeSuccessRedirect) {
            successRedirect = resolveVariablePattern({
              currentData: defaultData,
              context,
              response,
              params,
              pattern: agreeSuccessRedirect,
            });
          } else if (approvalResult === DISAPPROVAL && refuseSuccessRedirect) {
            successRedirect = resolveVariablePattern({
              currentData: defaultData,
              context,
              response,
              params,
              pattern: refuseSuccessRedirect,
            });
          } else {
            successRedirect = -1;
          }

          if (!hrefIsSiteBased) {
            successRedirect = joinPbcToken({ pbcToken }, successRedirect);
          }

          return delay(suppressSuccessLinkDelay ? 0 : successLinkDelayTime).then(() => {
            message.success(
              response?.successMessage || t('success.submit', { ns: 'icp-form-renderer' }),
            );
            if (dialogContext) {
              dialogContext.close({ state: 'success' });
            } else {
              navigate(successRedirect, { replace: true });
            }
          });
        });

    formRef.current.submit({ handler }).finally(() => {
      setSubmitting(false);
      setApprovalResult(null);
      setApprovalResult(null);
    });
  };

  const handleApproveSubmit = (comment) => {
    handleSubmit(comment || t('agree', { ns: 'icp-common' }));
  };

  const handleDisapproveSubmit = (comment) => {
    handleSubmit(comment);
  };

  const handleRouterBack = () => {
    if (dialogContext) {
      dialogContext.close({ status: 'cancel' });
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className={clsx('flow-form-renderer', 'approval-form', {
        'buttons-top': buttonsPosition === 'top',
      })}
    >
      <div className="approval-content">
        <FormRendererSelector
          {...FormRendererProps}
          formEntityToken={formEntityToken}
          layoutToken={layoutToken}
          layoutId={layoutId}
          defaultData={defaultData}
          defaultSubmitButton={false}
          defaultCancelButton={false}
          context={context}
          onSchemaFetchSuccess={() => setHasSchema(true)}
          onValidationFail={() =>
            message.error({ content: t('validation.fail', { ns: 'icp-form-renderer' }) })
          }
          ref={handleRef}
        />
      </div>
      {hasSchema ? (
        <div className="approval-buttons">
          <Space>
            {!isMobileEnv && (
              <ButtonUI onClick={handleRouterBack}>{t('back', { ns: 'icp-common' })}</ButtonUI>
            )}
            <ButtonUI onClick={() => setApprovalResult(DISAPPROVAL)}>
              {refuseText || t('reject', { ns: 'icp-common' })}
            </ButtonUI>
            <ButtonUI type="primary" onClick={() => setApprovalResult(APPROVAL)}>
              {agreeText || t('agree', { ns: 'icp-common' })}
            </ButtonUI>
          </Space>
        </div>
      ) : null}
      <CommentDialog
        open={approvalResult === APPROVAL}
        submitting={submitting}
        commentRequired={isCommentMandatoryForApproval}
        dialogProps={dialogProps}
        onOk={handleApproveSubmit}
        onClose={() => setApprovalResult(null)}
      />
      <CommentDialog
        open={approvalResult === DISAPPROVAL}
        submitting={submitting}
        commentRequired={isCommentMandatoryForDisapproval}
        dialogProps={dialogProps}
        onOk={handleDisapproveSubmit}
        onClose={() => setApprovalResult(null)}
      />
    </div>
  );
});

ApproveNodeFormRenderer.propTypes = {
  pbcToken: PropTypes.string,
  flowInstanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  instanceData: PropTypes.shape({
    data: PropTypes.shape({}),
    formEntityDataId: PropTypes.number,
    formEntityId: PropTypes.number,
    layoutId: PropTypes.number,
    pbcToken: PropTypes.string,
    formEntityToken: PropTypes.string,
    layoutToken: PropTypes.string,
    isCommentMandatoryForApproval: PropTypes.bool,
    isCommentMandatoryForDisapproval: PropTypes.bool,
  }),
  buttonsPosition: PropTypes.oneOf(['top', 'bottom']),
  context: PropTypes.shape({}),
  agreeSuccessRedirect: PropTypes.string,
  refuseSuccessRedirect: PropTypes.string,
  hrefIsSiteBased: PropTypes.bool,
  // By default, link in successAction will have 2s delay
  suppressSuccessLinkDelay: PropTypes.bool,
  successLinkDelayTime: PropTypes.number,
  dialogProps: PropTypes.shape({ title: PropTypes.string, centered: PropTypes.bool }),
  agreeText: PropTypes.string,
  refuseText: PropTypes.string,
  FormRendererProps: PropTypes.shape({}),
};

export default ApproveNodeFormRenderer;
