import PropTypes from 'prop-types';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import FormRenderer from '@icp/form-renderer';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { Loading } from '@icp/components';
import { fetchSchema, getLocalCode } from '@icp/page-renderer-core';
import { FORM_API_STAGE } from '@icp/form-renderer-core';
import { setRef } from '@icp/utils';

// 底层组件，根据 id 或者 token 信息选择一个 schema 去渲染，或者使用 code generator 生成好的 jsx 代码渲染
const FormRendererSelector = forwardRef(function FormRendererSelector(props, ref) {
  const {
    formEntityToken,
    layoutToken,
    layoutId, // layoutId 兼容老的项目后端代码
    schemaId,
    schema: schemaProp,
    notAllowedFields,
    retrieveUrl,
    createUrl,
    updateUrl,
    submitMethod,
    context: contextProp,
    onSchemaFetchSuccess,
    ...other
  } = props;

  const { t } = useTranslation(['icp-common']);

  const needFetch = !schemaProp;
  const [fetching, setFetching] = useState(needFetch);
  const [schemaFetched, setSchemaFetched] = useState(null);
  const [error, setError] = useState();

  useEffect(() => {
    if (fetching || error) {
      setRef(ref, { stage: FORM_API_STAGE.fetchSchema, error });
    }
  }, [error, fetching, ref]);

  // pbcToken 是由生成 routes 的时候决定的，不能通过 props 覆盖
  const { pbcToken } = contextProp;
  // Token 放在 context 里方便给 FormRenderer 里的上下文用
  const context = useMemo(() => {
    return { ...contextProp, formEntityToken, layoutToken, schemaId };
  }, [contextProp, formEntityToken, layoutToken, schemaId]);

  // 用 useMemo 不用 useEffect 是为了在 render 前 log
  useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log({ pbcToken, formEntityToken, layoutToken, schemaId });
    }
  }, [formEntityToken, layoutToken, pbcToken, schemaId]);

  useEffect(() => {
    if (schemaFetched && onSchemaFetchSuccess) {
      onSchemaFetchSuccess();
    }
    // 不响应 onSchemaFetchSuccess 的变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaFetched]);

  // Render json schema
  if (!process.env.CODE_MODE) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!needFetch) {
        return;
      }
      setFetching(true);
      setSchemaFetched(null);
      fetchSchema({ pbcToken, formEntityToken, layoutToken, layoutId, schemaId })
        .then(setSchemaFetched)
        .catch(setError)
        .finally(() => setFetching(false));
    }, [formEntityToken, layoutToken, pbcToken, schemaId, needFetch, layoutId]);

    if (fetching) {
      return <Loading className="form-renderer-block-loading" />;
    }

    if (!schemaProp && !schemaFetched) {
      console.error(
        `Error: No schema found for schemaId: ${schemaId} or layoutToken: ${layoutToken}`,
      );
    }

    if (error) {
      return (
        <Result
          title={t('error-occurred')}
          subTitle={error.message}
          status={[404, 403, 500].includes(error.status) ? error.status : 'error'}
        />
      );
    }

    return (
      <FormRenderer
        // key={`${pbcToken}, ${formEntityToken}, ${layoutToken}, ${layoutId}, ${schemaId}`}
        schema={schemaProp || schemaFetched}
        retrieveUrl={retrieveUrl}
        createUrl={createUrl}
        updateUrl={updateUrl}
        submitMethod={submitMethod}
        notAllowedFields={notAllowedFields}
        context={context}
        ref={ref}
        {...other}
      />
    );
  }

  // Render jsx code after code generator, 暂时还没有项目真是在用
  const Comp = getLocalCode({ schemaId, pbcToken, formEntityToken, layoutToken });

  if (!Comp) {
    console.error(`Error: No code found for schemaId: ${schemaId} or layoutToken: ${layoutToken}`);
    return null;
  }

  return (
    <Comp
      retrieveUrl={retrieveUrl}
      createUrl={createUrl}
      updateUrl={updateUrl}
      submitMethod={submitMethod}
      notAllowedFields={notAllowedFields}
      context={context}
      ref={ref}
      {...other}
    />
  );
});

FormRendererSelector.propTypes = {
  formEntityToken: PropTypes.string,
  layoutToken: PropTypes.string,
  layoutId: PropTypes.number,
  schemaId: PropTypes.string,
  schema: PropTypes.shape({}),
  notAllowedFields: PropTypes.arrayOf(PropTypes.string),
  retrieveUrl: PropTypes.string,
  createUrl: PropTypes.string,
  updateUrl: PropTypes.string,
  submitMethod: PropTypes.oneOf(['post', 'put']),
  context: PropTypes.shape({
    pbcToken: PropTypes.string,
  }),
  onSchemaFetchSuccess: PropTypes.func,
};

export default FormRendererSelector;
