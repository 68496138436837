import './NewChatButton.css';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

function NewChatButton(props) {
  const { t } = useTranslation(['icp-common']);
  const { disabled, suppressConfirm, onStartNewChat } = props;

  const button = (
    <Button
      className="ai-agent-new-chat"
      shape="round"
      size="small"
      disabled={disabled}
      icon={<Icon name="ant:plus-circle-outlined" />}
      onClick={suppressConfirm ? () => onStartNewChat() : undefined}
    >
      {t('new-conversation', { ns: 'icp-common' })}
    </Button>
  );

  return disabled || suppressConfirm ? (
    button
  ) : (
    <Popconfirm
      placement="right"
      title={t('clear-confirm', { ns: 'icp-common' })}
      onConfirm={() => onStartNewChat()}
    >
      {button}
    </Popconfirm>
  );
}

NewChatButton.propTypes = {
  disabled: PropTypes.bool,
  suppressConfirm: PropTypes.bool,
  onStartNewChat: PropTypes.func,
};

export default NewChatButton;
