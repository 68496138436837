import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  dataSourceToDataUrl,
  selectContext,
  resolveUrl,
  selectValues,
} from '@icp/form-renderer-core';
import { AG_SORT_TYPES, Loading } from '@icp/components';
import { getResourceBundle } from '@icp/i18n';
import { EvalWorker, loadDHTMLXGantt, randomNumber } from '@icp/utils';
import { restApi, shouldTranslateByDefault } from '@icp/settings';
import { useClassName, useDataSource, useDisplayValue } from '../../hooks';
import { ConditionalListOrSinglePropertyPropType, DataFiltersType } from '../../propTypes';
import { useElementDecorator, useFormApi, useIsInDesign } from '../../FormRenderCtx';
import { withFieldWrapper } from '../../fieldWrapper';
import { useStore } from '../../store';
import { getGanttScale } from './utils';

// const tasks = {
//   data: [
//     {
//       id: 11,
//       text: 'Project #1',
//       type: 'project',
//       open: true,
//     },
//     {
//       id: 12,
//       parent: 11,
//       text: 'Task #1',
//       start_date: '2023-04-03',
//       duration: 5,
//       open: true,
//     },
//     {
//       id: 13,
//       parent: 11,
//       text: 'Task #2',
//       render: 'split',
//       open: true,
//     },
//     {
//       id: 17,
//       parent: 13,
//       text: 'Stage #1',
//       start_date: '2023-04-03',
//       duration: 1,
//       open: true,
//     },
//     {
//       id: 18,
//       parent: 13,
//       text: 'Stage #2',
//       start_date: '2023-04-05',
//       duration: 2,
//       open: true,
//     },
//     {
//       id: 19,
//       parent: 13,
//       text: 'Stage #3',
//       start_date: '2023-04-08',
//       duration: 1,
//       open: true,
//     },
//     {
//       id: 20,
//       parent: 13,
//       text: 'Stage #4',
//       start_date: '2023-04-10',
//       duration: 2,
//       open: true,
//     },
//     {
//       id: 14,
//       parent: 11,
//       text: 'Task #3',
//       start_date: '2023-04-02',
//       duration: 6,
//       open: true,
//     },
//     {
//       id: 15,
//       parent: 11,
//       text: 'Task #4',
//       render: 'split',
//       open: true,
//     },
//     {
//       id: 21,
//       parent: 15,
//       text: 'Stage #1',
//       start_date: '2023-04-03',
//       duration: 4,
//       open: true,
//     },
//     {
//       id: 22,
//       parent: 15,
//       text: 'Stage #2',
//       start_date: '2023-04-08',
//       duration: 3,
//       open: true,
//     },
//   ],
//   links: [],
// };
const GanttElement = forwardRef(function GanttElement(
  {
    keyPath,
    id,
    className: classNameProp,
    style: styleSetByParent,
    valueField,
    componentProps = {},
    //
  },
  ref,
) {
  const {
    style,
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    defaultValues, // deprecated
    //
    ganttConfig,
    ganttDataProcessor,
    showToday,
  } = componentProps;

  const params = useParams();
  const store = useStore();
  const formApi = useFormApi();

  const { i18n } = useTranslation('icp-vendor-dhtmlx-gantt');
  const lng = i18n.language;
  const localeObj = useMemo(() => getResourceBundle('icp-vendor-dhtmlx-gantt'), []);

  const ElementDecorator = useElementDecorator();
  const context = selectContext(store.getState());
  const isInDesign = useIsInDesign();
  const dataUrl = dataUrlProp || dataSourceToDataUrl(dataSource, context, true, isInDesign);

  const nodeRef = useRef(null);
  const eleRef = useRef(null);
  const ganttRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      get gantt() {
        return ganttRef.current;
      },
    }),
    [],
  );

  const valueResolved = useDisplayValue(id, valueField);

  const { loading, error, dataFetched } = useDataSource({
    skip: valueResolved,
    defaultValues, // deprecated
    // single data source config
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod: httpMethod || (!dataUrlProp && dataSource ? 'post' : undefined), // 使用dataSource推断的内部聚合接口是post
  });
  const data = dataFetched || valueResolved;

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const { ganttStartDate, ganttEndDate } = getGanttScale(data);
  /* const scale = fitToZoom(ganttStartDate, ganttEndDate, eleRef.current?.offsetWidth);
  const zoom = getGanttConfigByZoomValue(scale); */

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-gantt-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // init gantt
  useEffect(() => {
    let gantt;

    loadDHTMLXGantt().then((res) => {
      gantt = res.Gantt.getGanttInstance();

      // custom default config over gantt default config
      gantt.config.autofit = true;
      gantt.config.readonly = true;
      gantt.config.open_split_tasks = false;
      gantt.config.date_format = '%Y-%m-%d';
      gantt.config.bar_height = 23;
      gantt.config.columns = [
        { name: 'text', label: 'Name', tree: true, resize: true, width: '*' },
        { name: 'duration', label: 'Duration', width: 80, align: 'center', resize: true },
        { name: 'start_date', label: 'Start', width: 80, align: 'center', resize: true },
        { name: 'end_date', label: 'Finish', width: 80, align: 'center', resize: true },
      ];
      Object.assign(gantt.config, ganttConfig);

      // 这里配置了ganttConfig，所以这里要覆盖掉
      if (!gantt.config.readonly && !gantt.config.columns.find((e) => e.name === 'add')) {
        gantt.config.columns.push({ name: 'add', width: 40 });
      }

      const zoomConfig = {
        levels: [
          {
            name: 'day',
            scale_height: 50,
            min_column_width: 80,
            scales: [
              { unit: 'month', format: '%M %Y' },
              { unit: 'day', format: '%d %M' },
            ],
          },
          {
            name: 'week',
            scale_height: 50,
            min_column_width: 120,
            max_column_width: 200,
            scales: [
              { unit: 'month', format: '%M %Y', step: 1 },
              {
                unit: 'week',
                step: 1,
                format(date) {
                  const dateToStr = gantt.date.date_to_str('%d %M');
                  const endDate = gantt.date.add(date, 6, 'day');
                  const weekNum = gantt.date.date_to_str('%W')(date);
                  return `#${weekNum}, ${dateToStr(date)} - ${dateToStr(endDate)}`;
                },
              },
            ],
          },
          {
            name: 'month',
            scale_height: 50,
            min_column_width: 80,
            scales: [
              { unit: 'year', format: '%Y' },
              { unit: 'month', format: '%M' },
            ],
          },
          {
            name: 'year',
            scale_height: 50,
            min_column_width: 80,
            scales: [{ unit: 'year', step: 1, format: '%Y' }],
          },
        ],
        startDate: ganttStartDate,
        endDate: ganttEndDate,
        useKey: 'ctrlKey',
        trigger: 'wheel',
        element() {
          return gantt.$root.querySelector('.gantt_task');
        },
      };
      const handleRefreshAction = () => {
        return formApi.refresh();
      };
      const executeAction = async (
        currentAction,
        { item, dataId },
        actionType,
        requestSuccessCallback = null,
      ) => {
        if (!currentAction) return undefined;

        const handleRequestAction = () => {
          const { method, url: urlPattern, transformDataRequest, successAction } = currentAction;

          const url = resolveUrl({
            url: urlPattern,
            store,
            currentData: { dataId, ...item },
            params,
          })[0];

          return (
            Promise.resolve(item)
              // remove private properties
              .then((requestPayload) => {
                return Object.fromEntries(
                  Object.entries(requestPayload).filter(([k]) => !k.startsWith('$')),
                );
              })
              // transform data
              .then((requestPayload) => {
                if (transformDataRequest) {
                  return EvalWorker.shared().execEval(requestPayload, transformDataRequest, {
                    params: {
                      context: store.getState().context,
                      formData: selectValues(store.getState()),
                    },
                  });
                }
                return requestPayload;
              })
              .then((requestPayload) => {
                if (method === 'get') {
                  return restApi.get(url);
                }
                return restApi[method](url, requestPayload);
              })
              .then((rsp) => {
                requestSuccessCallback?.(rsp);
                return executeAction(successAction);
              })
          );
        };

        switch (currentAction.type || 'request') {
          case 'refresh':
            return handleRefreshAction();
          case 'request':
            return handleRequestAction();
          default:
            return undefined;
        }
      };
      gantt.ext.zoom.init(zoomConfig);

      gantt.createDataProcessor({
        task: {
          async create(item) {
            if (!ganttDataProcessor?.task?.create) return undefined;
            const taskParentData =
              data?.data.find((e) => e.id === item.parent || e.id === Number(item.parent)) || {};

            const newData = {
              ...(item.originData || taskParentData.originData),
              startDate: item.start_date,
              endDate: item.end_date,
              task: item.text,
              parent: Number(item.parent) < 100 ? undefined : Number(item.parent),
              parentDataId: store.getState().context?.formEntityDataId,

              ganttData: item,
            };

            return executeAction(
              ganttDataProcessor?.task?.create,
              { item: newData },
              'create',
              (rsp) => {
                gantt.getTask(item.id).originData = {
                  ...rsp,
                  dataFormEntityToken: newData.dataFormEntityToken,
                };
                gantt.changeTaskId(item.id, rsp.id); // renders the updated link
              },
            );
          },
          async update(item, dataId) {
            window.dispatchEvent(
              new CustomEvent('taskChange', {
                detail: {
                  item: gantt.getTask(item.id),
                  gantt,
                  ganttData: data,
                  type: 'task.update',
                },
              }),
            );

            if (!ganttDataProcessor?.task?.update) return undefined;
            const newData = {
              ...item.originData,
              startDate: item.start_date,
              endDate: item.end_date,
              task: item.text,
              parent: Number(item.parent) < 100 ? undefined : Number(item.parent),

              ganttData: item,
            };

            return executeAction(
              ganttDataProcessor?.task?.update,
              { dataId, item: newData },
              'update',
            );
          },
          async delete(taskId) {
            if (!ganttDataProcessor?.task?.delete) return undefined;

            return executeAction(
              ganttDataProcessor?.task?.delete,
              { dataId: taskId, item: {} },
              'delete',
            );
          },
        },
        link: {
          async create(item) {
            if (!ganttDataProcessor?.link?.create) return undefined;

            const newData = {
              ...item,
              parentDataId: store.getState().context?.formEntityDataId,
            };

            return executeAction(
              ganttDataProcessor?.link?.create,
              { item: newData },
              'create',
              (rsp) => {
                gantt.changeLinkId(item.id, rsp.id); // renders the updated link
              },
            );
          },
          async update(item) {
            if (!ganttDataProcessor?.link?.update) return undefined;

            return executeAction(ganttDataProcessor?.link?.update, { item }, 'update');
          },
          async delete(linkId) {
            if (!ganttDataProcessor?.link?.delete) return undefined;

            return executeAction(
              ganttDataProcessor?.link?.delete,
              { dataId: linkId, item: {} },
              'delete',
            );
          },
        },
      });

      if (localeObj) gantt.i18n.setLocale(localeObj);

      gantt.templates = {
        timeline_cell_class: (task, date) => {
          if (!gantt.isWorkTime(date)) return 'week_end';
          return '';
        },
        task_class: (start, end, task) => {
          const barClass = `gantt-bar ${task.barClass || ''}`;
          return barClass;
        },

        link_class: (link) => {
          const types = gantt.config.links;
          switch (link.type) {
            case types.finish_to_start:
              return 'finish_to_start';
            case types.start_to_start:
              return 'start_to_start';
            case types.finish_to_finish:
              return 'finish_to_finish';
            default:
              return '';
          }
        },
      };

      gantt.plugins({
        marker: true,
        critical_path: true,
      });

      gantt.addMarker({
        start_date: new Date(),
        css: 'today_line',
        text: '今天',
        title: '今天',
      });

      gantt.init(eleRef.current);

      if (data) {
        gantt.parse(data);
      }

      ganttRef.current = gantt;
      formApi.asyncComponentManager.setReady(idToRegister);
    });

    return () => {
      gantt?.destructor();
    };
  }, [
    lng,
    localeObj,
    ganttConfig,
    ganttDataProcessor,
    data,
    store,
    params,
    formApi,
    showToday,
    ganttStartDate,
    ganttEndDate,
    idToRegister,
  ]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('gantt-element form-element', className)}
        style={styleSetByParent}
        ref={nodeRef}
      >
        {loading && <Loading />}
        {error && <div>{error.message ?? `${error.error}`}</div>}
        <div
          ref={eleRef}
          className={classNameComp}
          style={{
            ...style,
            ...(loading || error ? { visibility: 'hidden' } : null),
          }}
        />
      </div>
    </ElementDecorator>
  );
});

GanttElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 通过 `dataUrl` 获取数据
     */
    dataUrl: ConditionalListOrSinglePropertyPropType(PropTypes.string),
    /**
     * 数据请求的http方法
     * @default 'get'
     */
    httpMethod: PropTypes.oneOf(['get', 'post', 'put']),
    /**
     * 也可以指定 formEntity 的数据源作为 data source
     */
    dataSource: PropTypes.shape({
      listUrl: PropTypes.string,
      token: PropTypes.string,
    }),
    /**
     * 数据存在于 response data 里的 key path
     * @default 'results'
     */
    dataResponseKeyPath: PropTypes.string,
    /**
     * 转换从 `dataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 是否需要翻译请求结果
     * @default false
     */
    translateDataResponse: PropTypes.bool,
    /**
     * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变
     */
    dataFilters: DataFiltersType,
    /**
     * 请求数据的 debounce time
     */
    debounceTime: PropTypes.number,
    /**
     *
     */
    defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * 数据的排序方式，前端排序
     */
    sortModel: PropTypes.shape({
      /**
       * 排序字段 id
       */
      id: PropTypes.string,
      /**
       * 生序或者降序排序
       * @default 'asc'
       */
      sort: PropTypes.oneOf(AG_SORT_TYPES),
      /**
       * 值按照什么类型进行排序
       * @default 'text'
       */
      type: PropTypes.oneOf(['text', 'number', 'date']),
    }),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
GanttElement.displayName = 'Gantt';

export default withFieldWrapper(GanttElement, { ns: 'icp-vendor-dhtmlx-gantt' });
