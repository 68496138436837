import { restApi } from '@icp/settings';
import { deCompressSchema, getSearchParams } from '@icp/utils';

const allLocalOldSchema = process.env.ALL_LOCAL_OLD_SCHEMA;

export function fetchLocalPbcEntityLayoutSchema({ pbcToken, formEntityToken, layoutToken }) {
  const url = `${window.ICP_PUBLIC_PATH}pbc/${pbcToken}/forms/${formEntityToken}/layouts/${layoutToken}.json`;
  return restApi
    .getStatic(url, { params: { v: process.env.BUILD_NUMBER || 'DEV' } })
    .then((schema) => {
      if (process.env.NODE_ENV === 'production') {
        return deCompressSchema(schema);
      }
      return schema;
    });
}

export function fetchLocalPbcPageSchema({ pbcToken, schemaId }) {
  const url = `${window.ICP_PUBLIC_PATH}pbc/${pbcToken}/pages/${schemaId}.json`;
  return restApi
    .getStatic(url, { params: { v: process.env.BUILD_NUMBER || 'DEV' } })
    .then((schema) => {
      if (process.env.NODE_ENV === 'production') {
        return deCompressSchema(schema);
      }
      return schema;
    });
}

export function fetchLocalSchemaFromOldSchemaDir(schemaId) {
  const url = `${window.ICP_PUBLIC_PATH}schema/${allLocalOldSchema[schemaId]}`;
  return restApi
    .getStatic(url, { params: { v: process.env.BUILD_NUMBER || 'DEV' } })
    .then((schema) => {
      if (process.env.NODE_ENV === 'production') {
        return deCompressSchema(schema);
      }
      return schema;
    });
}

export function fetchRemotePbcEntityLayoutSchema({ pbcToken, formEntityToken, layoutToken }) {
  const searchParams = getSearchParams();
  const url = `/form/api/v2/form-entity-layout/${pbcToken}/${formEntityToken}/${layoutToken}/schema-json`;
  return restApi.getStatic(url, { params: searchParams });
}

export function fetchRemotePbcPageSchema({ pbcToken, schemaId }) {
  const url = `/form/api/form-entity-page/get-by-schema-id/${pbcToken}/${schemaId}`;
  return restApi.getStatic(url).then((res) => JSON.parse(res.schemaJson));
}

export function fetchRemoteSchemaFromLayoutId({ layoutId }) {
  const searchParams = getSearchParams();
  const url = `/form/api/form-entity-layout/${layoutId}/schema-json`;
  return restApi.getStatic(url, { params: searchParams });
}
