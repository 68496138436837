import { parseJSON } from '@icp/utils';
import { makeNewFormSchema } from './makeNew';
import { ensureFields, ensureFieldsI18n, ensureLayoutsI18n } from './formEntityHelper';

export const Converter = {
  FormEntity: {
    fromDTO: (data) => {
      if (!data) return data;

      const layouts = (data.layouts || []).map(({ schemaJson, ...layout }) => ({
        ...layout,
        schema: parseJSON(schemaJson) ?? makeNewFormSchema(),
      }));

      const { dataSecurityJson, ...other } = data;

      return {
        ...other,
        isInnerEntity: !!+data.isInnerEntity,
        isMasterData: !!+data.isMasterData,
        layouts,
        dataSecurity: parseJSON(dataSecurityJson),
      };
    },
    toDTO: (data) => {
      if (!data) return data;

      data = JSON.parse(JSON.stringify(data));

      ensureLayoutsI18n(data.layouts || [], {
        pbcToken: data.pbcToken,
        formEntityToken: data.token,
      });

      const layouts = (data.layouts || []).map(({ schema, ...layout }, index) => ({
        ...layout,
        sortIndex: index,
        schemaJson: JSON.stringify(schema),
      }));

      const { dataSecurity, ...other } = data;
      return {
        ...other,
        isInnerEntity: data.isInnerEntity ? 1 : 0,
        isMasterData: data.isMasterData ? 1 : 0, // ?? 没看懂为什么 api 是 0 和 1
        layouts,
        fields: ensureFieldsI18n(ensureFields(data.fields, data.layouts, data.pbcToken), {
          pbcToken: data.pbcToken,
          formEntityToken: data.token,
        }),
        dataSecurityJson: JSON.stringify(dataSecurity),
      };
    },
  },
  PageLayout: {
    fromDTO: (data) => {
      if (!data) return data;

      const { schemaJson, ...other } = data;

      return {
        ...other,
        schema: parseJSON(schemaJson),
      };
    },
    toDTO: (data) => {
      if (!data) return data;

      const { schema, ...other } = data;

      return {
        ...other,
        schemaJson: JSON.stringify(schema),
      };
    },
  },
};
