import TextCellEditor from './TextCellEditor';
import SelectCellEditor from './SelectCellEditor';
import DateCellEditor from './DateCellEditor';
import TimeCellEditor from './TimeCellEditor';
import ACLCellEditor from './ACLCellEditor';
import UploadCellEditor from './UploadCellEditor';
import TextareaCellEditor from './TextareaCellEditor';
import NumberCellEditor from './NumberCellEditor';
import ParentCellEditor from './ParentCellEditor';

export {
  TextCellEditor,
  SelectCellEditor,
  DateCellEditor,
  TimeCellEditor,
  ACLCellEditor,
  UploadCellEditor,
  TextareaCellEditor,
  NumberCellEditor,
  ParentCellEditor,
};

export const cellEditorMapping = {
  TextCellEditor,
  SelectCellEditor,
  DateCellEditor,
  TimeCellEditor,
  ACLCellEditor,
  UploadCellEditor,
  TextareaCellEditor,
  NumberCellEditor,
  ParentCellEditor,
};
