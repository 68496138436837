/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Divider, Switch, Tooltip } from 'antd';
import { GearIcon, TrashIcon } from '@primer/octicons-react';
import { useTranslation } from 'react-i18next';
import { getSearchParam, updateSearchParam } from '@icp/utils';
import SizeDefaultSvg from './size-default.svg';
import SizeDefaultSvgSelected from './size-default-selected.svg';
import SizeSmallSvg from './size-small.svg';
import SizeSmallSvgSelected from './size-small-selected.svg';

function TableSetting(props) {
  const { gridApi, supportShowDeleted, tableSize, onTableSizeChange, onResetSetting } = props;

  const { t } = useTranslation(['icp-components']);

  const [open, setOpen] = useState(false);

  const showDeleted = getSearchParam('include_deleted') === 'true';

  const handleShowDeletedChanged = (checked) => {
    updateSearchParam('include_deleted', checked);
    gridApi.refreshServerSide({ purge: true });
  };

  return (
    <>
      <Tooltip title={t('table.setting')}>
        <Button
          className="icp-table-toolbar-setting-button"
          type="text"
          icon={<GearIcon size={16} />}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      {open ? (
        <div
          className="icp-backdrop"
          onClick={() => setOpen(false)}
          style={{ zIndex: 'var(--max-z-index)' }}
        />
      ) : null}
      <div
        className="icp-table-setting-hidden-wrapper"
        style={!open ? { visibility: 'hidden' } : undefined}
      >
        <div
          className="icp-table-setting"
          style={
            !open
              ? {
                  transform: 'translate(50%)',
                  opacity: 0,
                  visibility: 'hidden',
                }
              : undefined
          }
        >
          <h2>{t('table.setting')}</h2>
          {supportShowDeleted ? (
            <div className="icp-table-setting-row">
              <span className="icp-table-setting-label">{t('table.show-deleted')}</span>
              <Switch defaultChecked={showDeleted} onChange={handleShowDeletedChanged} />
            </div>
          ) : null}
          <div className="icp-table-setting-column">
            <div className="icp-table-setting-label">{t('table.size')}</div>
            <div className="icp-center" style={{ gap: 18, justifyContent: 'flex-start' }}>
              <div
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => onTableSizeChange('default')}
              >
                <img
                  src={tableSize === 'default' ? SizeDefaultSvgSelected : SizeDefaultSvg}
                  style={{ width: 80 }}
                  alt={t('table.size-default')}
                />
                <div style={{ marginTop: 4 }}>{t('table.size-default')}</div>
              </div>
              <div
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={() => onTableSizeChange('small')}
              >
                <img
                  src={tableSize === 'small' ? SizeSmallSvgSelected : SizeSmallSvg}
                  style={{ width: 80 }}
                  alt={t('table.size-small')}
                />
                <div style={{ marginTop: 4 }}>{t('table.size-small')}</div>
              </div>
            </div>
          </div>
          <Divider style={{ margin: 0 }} />
          <div className="icp-table-setting-actions" onClick={onResetSetting}>
            <div className="icp-table-setting-action">
              {t('table.clear-setting')}
              <span>
                <TrashIcon size={16} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TableSetting.propTypes = {
  gridApi: PropTypes.shape({}),
  supportShowDeleted: PropTypes.bool,
  tableSize: PropTypes.oneOf(['default', 'small']),
  onTableSizeChange: PropTypes.func,
  onResetSetting: PropTypes.func,
};

export default TableSetting;
