/* eslint-disable react/prop-types */
import { CurrentDataProvider } from '../../../currentDataCtx';

export default function withCurrentData(Comp) {
  function WithCurrentData(props) {
    const { data } = props;

    return (
      <CurrentDataProvider value={data}>
        <Comp {...props} />
      </CurrentDataProvider>
    );
  }

  Object.assign(WithCurrentData, Comp);

  return WithCurrentData;
}
