import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Select, message } from 'antd';
import { Loading } from '@icp/components';
import { loadFlameChartJs, toGroup } from '@icp/utils';
import { restApi } from '@icp/settings';

function convertDataStructure(list) {
  return list.map((item) => ({
    name: item.identifier,
    info: item.info,
    type: item.type,
    start: item.unitBegin,
    duration: item.duration,
    children: convertDataStructure(item.executeUnits || []),
  }));
}

function FlowFlame() {
  const ref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  const [flowList, setFlowList] = useState();
  const [flame, setFlame] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    loadFlameChartJs().then((flameChartJs) => {
      const { FlameChart } = flameChartJs;
      const flameChart = new FlameChart({
        canvas: ref.current,
        data: [],
        settings: {
          options: {
            tooltip: (hoveredRegion, renderEngine, globalMouse) => {
              if (!hoveredRegion) return;
              const {
                data: {
                  source: { start, duration, name, info, children },
                },
              } = hoveredRegion;
              const timeUnits = renderEngine.getTimeUnits();

              const selfTime =
                duration -
                (children ? children.reduce((acc, { duration: curr }) => acc + curr, 0) : 0);

              const nodeAccuracy = renderEngine.getAccuracy() + 2;

              const dur = `duration: ${duration.toFixed(nodeAccuracy)} ${timeUnits} ${
                children?.length ? `(self ${selfTime.toFixed(nodeAccuracy)} ${timeUnits})` : ''
              }`;
              const st = `start: ${start.toFixed(nodeAccuracy)} ${timeUnits}`;

              renderEngine.renderTooltipFromData(
                [
                  { text: `${name}`, color: '#333' },
                  { text: `${info}`, color: '#333' },
                  { text: dur },
                  { text: st },
                ],
                globalMouse,
              );
            },
            timeUnits: 'ms',
          },
        },
      });
      setFlame(flameChart);
    });
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    restApi
      .get(`/flow/api/flow-definition/list-current-project-flow-definitions`, { signal })
      .then((list) =>
        toGroup((x) => x.pbcToken)(list).map(([pbcToken, items]) => ({
          label: pbcToken,
          options: items.map((x) => ({ value: x.id, label: x.name })),
        })),
      )
      .then(setFlowList);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (id == null) return () => {};
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);
    restApi
      .get(`/flow/api/flow-definition/execute-flame/${id}`, { signal })
      .then((x) => {
        if (!x) {
          message.error(`No flame data for ${id}`);
          throw Error('Empty response');
        }
        return x;
      })
      .then((x) => convertDataStructure(Array.isArray(x) ? x : [x]))
      .then(setData)
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    if (!flame) return () => {};

    const handleResize = () => flame.resize(window.innerWidth, window.innerHeight - 40);
    requestAnimationFrame(handleResize);
    window.addEventListener('resize', handleResize);

    flame.on('select', console.log);

    return () => {
      window.removeEventListener('resize', handleResize);
      flame.off('select', console.log);
    };
  }, [flame]);

  useEffect(() => {
    if (!flame || !data) return;
    flame.setNodes(data);
  }, [flame, data]);

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {flowList && (
        <div style={{ height: 40, display: 'flex', alignItems: 'center', padding: '0 10px' }}>
          <span style={{ marginRight: 10 }}>Flow: </span>
          <Select
            popupMatchSelectWidth={false}
            style={{ minWidth: 100 }}
            allowClear={true}
            onChange={(x) => {
              setSearchParams(x ? { id: x } : {});
            }}
            value={+id || null}
            options={flowList}
          />
        </div>
      )}
      <canvas ref={ref} />
      {loading && <Loading style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }} />}
    </div>
  );
}

export default FlowFlame;
