import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRef, useState, useImperativeHandle, forwardRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventCallback } from '@icp/hooks';
import SuggestionPopover from './SuggestionPopover';
import ReferenceDataController from './ReferenceDataController';

const InputBox = forwardRef((props, ref) => {
  const { value, onChange, disabled, allowSuggestion } = props;
  const { t } = useTranslation(['icp-components']);
  const containerRef = useRef();
  const textareaRef = useRef();
  const mirrorRef = useRef();
  const popoverAnchorRef = useRef();

  const [formPopoverOpen, setFormPopoverOpen] = useState(false);
  const [fieldPopoverOpen, setFieldPopoverOpen] = useState(false);

  const onCloseSelect = () => {
    setFormPopoverOpen(false);
    setFieldPopoverOpen(false);
  };

  const refDataCtrl = useMemo(() => new ReferenceDataController(), []);
  if (allowSuggestion) {
    refDataCtrl.options.mirrorEl = mirrorRef.current;
    refDataCtrl.options.getFormList = allowSuggestion.getFormList;
    refDataCtrl.options.getFieldList = allowSuggestion.getFieldList;
    refDataCtrl.options.getFormByName = allowSuggestion.getFormByName;
    refDataCtrl.options.getFieldByName = allowSuggestion.getFieldByName;
    refDataCtrl.options.onOpenFormSelect = () => setFormPopoverOpen(true);
    refDataCtrl.options.onOpenFieldSelect = (form) => setFieldPopoverOpen(form);
    refDataCtrl.options.onCloseSelect = () => onCloseSelect();
    refDataCtrl.options.onProduceNewText = (newText) => {
      setFormPopoverOpen(false);
      setFieldPopoverOpen(false);
      onChange(newText);
    };
    refDataCtrl.options.onChangeCursorPosition = (newPos) => {
      textareaRef.current?.focus();
      setTimeout(() => {
        textareaRef.current?.setSelectionRange(newPos, newPos);
      }, 0);
    };
  }

  useImperativeHandle(ref, () => ({
    focus: () => textareaRef.current?.focus(),
    get textarea() {
      return textareaRef.current;
    },
    get referenceDataController() {
      return refDataCtrl;
    },
  }));

  // 监听键盘触发退出
  useEffect(() => {
    const listener = (event) => event.key === 'Escape' && onCloseSelect();
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const handleScroll = useEventCallback((event) => {
    if (!mirrorRef.current) return;
    mirrorRef.current.style.transform = `translateY(-${event.target.scrollTop}px)`;
  });

  const handleKeyDown = useEventCallback((event) => {
    const { selectionStart, selectionEnd } = event.target;
    refDataCtrl.beforeTextChange(event.target.value, selectionStart, selectionEnd);
  });

  // [[引用]]禁止直接编辑
  const handleBeforeInput = useEventCallback((event) => {
    const { selectionStart, selectionEnd } = event.target;
    if (
      refDataCtrl.checkIfInRefContent(selectionStart) ||
      refDataCtrl.checkIfInRefContent(selectionEnd)
    ) {
      event.preventDefault();
    }
  });

  const resize = () => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 1}px`;

    if (mirrorRef.current) {
      mirrorRef.current.style.height = `${textareaRef.current.height}px`;
      mirrorRef.current.style.width = `${textareaRef.current.clientWidth}px`;
    }
  };

  useEffect(() => {
    resize();
  }, []);

  // 监听输入决定是否打开popover选东西
  const handleInput = useEventCallback((event) => {
    resize();
    if (allowSuggestion) {
      // 整体一次性删除[[引用]]
      if (/^delete/i.test(event.nativeEvent.inputType) && refDataCtrl.processCustomDelete()) {
        return;
      }

      refDataCtrl.setText(event.target.value);
      refDataCtrl.decideIfOpenSelect(event.nativeEvent.data, event.target.selectionStart);
    }

    onChange(event.target.value);
  });

  const valuePrevRef = useRef(value);
  useEffect(() => {
    const prevValue = valuePrevRef.current;
    valuePrevRef.current = value;
    // resize when change from non-empty to empty
    if (!!prevValue && !value) {
      resize();
    }
  });

  return (
    <div ref={containerRef} className="ai-agent-input-box-inner">
      <textarea
        ref={textareaRef}
        className={clsx('icp-thin-scrollbar', allowSuggestion && 'allow-suggestion')}
        disabled={disabled}
        value={value}
        onInput={handleInput}
        {...(allowSuggestion && {
          onKeyDown: handleKeyDown,
          onBeforeInput: handleBeforeInput,
          onScroll: handleScroll,
          placeholder: t('ai-agent.inputbox-placeholder'),
        })}
      />
      {allowSuggestion ? (
        <div ref={mirrorRef} className="ai-agent-input-box-mirror">
          {value}
          <div style={{ position: 'absolute', inset: 8, visibility: 'hidden' }}>
            {value?.slice(0, refDataCtrl.positionOnOpenPopover)}
            <span ref={popoverAnchorRef} />
          </div>
        </div>
      ) : null}
      {allowSuggestion?.getFormList && (
        <SuggestionPopover
          anchorEl={popoverAnchorRef.current}
          open={!!formPopoverOpen}
          onClose={onCloseSelect}
          getList={allowSuggestion.getFormList}
          onSelect={refDataCtrl.onSelectItem}
        />
      )}
      {allowSuggestion?.getFieldList && (
        <SuggestionPopover
          anchorEl={popoverAnchorRef.current}
          open={!!fieldPopoverOpen}
          onClose={onCloseSelect}
          getList={(signal) => allowSuggestion.getFieldList(fieldPopoverOpen, signal)}
          onSelect={refDataCtrl.onSelectItem}
        />
      )}
    </div>
  );
});

export const AllowSuggestionPropType = PropTypes.shape({
  getFormList: PropTypes.func,
  getFieldList: PropTypes.func,
  getFormByName: PropTypes.func,
  getFieldByName: PropTypes.func,
});

InputBox.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  getFormList: PropTypes.func,
  allowSuggestion: AllowSuggestionPropType,
};

export default InputBox;
