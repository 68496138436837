import { camelCase } from 'case-anything';
import { restApi } from '@icp/settings';
import { chooseFile, parseJSON, readFileAsDataURL } from '@icp/utils';
import { fetchJSONStream } from '@icp/components';
import { forEachFieldInSchema, isField } from '@icp/form-schema';
import { get, set } from 'lodash-es';
import { translateEntireObj } from '@icp/i18n';

export function generateFormByText({ payload, model, onUpdateSchema, onDone, signal }) {
  const { chatMsg } = payload;
  if (chatMsg.startsWith('>')) {
    return fetchJSONdiffData({ payload, model, onUpdateSchema, signal, onDone });
  }
  const params = model ? { model, stream: true, type: 'form' } : { stream: true, type: 'form' };
  const queryString = new URLSearchParams(params).toString();
  const request = { url: `/aip/api/gpt/s/create-chat-message?${queryString}`, payload };
  const handlers = {
    processPartialResult: (result) => onUpdateSchema(result, true),
    processFinalResult: (result) => {
      const finalSchema = parseJSON(result?.generatedJson?.longText);
      if (finalSchema) {
        onUpdateSchema(finalSchema);
      }
    },
    doneCallback: onDone,
  };
  return fetchJSONStream(request, handlers, signal);
}

function fetchJSONdiffData({ payload, model, onUpdateSchema, signal, onDone }) {
  const params = model ? { model, stream: false, type: 'form' } : { stream: false, type: 'form' };
  const newPayload = {
    ...payload,
    chatMsg: `${payload.chatMsg},Please locate and identify the "specified element" in the JSON data, and record its full path, which should start from the root node of the JSON:
If you need to add new fields before or after the "specified element", please add them to the 'insert' array in the following format: {"insert":[{'should be the full path of the 'specified element' separated by commas': {"id":"unique identifier generated", "title":"title of the field", "component":"type of the field component", "isBefore":"boolean value, indicating whether to insert before the 'specified element'"}}]}. eg: {insert: [{'fields,0,fields,0,fields,2': {"id": "6C5F319C","field name 1": "title name","component": "Input", isBefore: true}, {"id": "6C5F3212C","title": "field name 2","component": "Input", isBefore: true}}]}
If you need to change or modify the field of the "specified element", please add them to the 'update' array in the following format: {"update":[{'should be the full path of the 'specified element' separated by commas': {"id":"keep the specified id unchanged", "title":"updated title of the field", "component":"updated type of the field component"}}]}。 eg: {insert: [{'fields,0,fields,0,fields,2': {"id": "6C5F319C","field name 1": "title name","component": "Input"}, {"id": "6C319C","title": "field name 2","component": "Input"}}]} Please note that the 'id' field in the 'update' array must remain unchanged to identify the original field. Note that the "specified element" is an existing element in the original form JSON; the path for the inserted element is based on the path of the "specified element". The final returned JSON format should be: {"form":{},"fields":[],"insert":[],"update":[]}`,
  };
  const queryString = new URLSearchParams(params).toString();
  const request = { url: `/aip/api/gpt/create-chat-message?${queryString}`, newPayload };
  return restApi.post(request.url, newPayload, { signal }).then((result) => {
    // update form layout
    const responseJson = parseJSON(result?.generatedJson?.longText);
    const { insert = [], update = [] } = responseJson;
    insert.forEach((diff) => {
      const key = Object.keys(diff)[0];
      const keyPath = key.split(',');
      const { isBefore, ...field } = diff[key];
      // snippet 支持传入完成的field信息
      const source = { type: 'snippet', value: JSON.stringify(field) };
      const target = {
        isBefore,
        keyPath,
        operation: 'insert',
      };
      if (isField(field)) {
        onUpdateSchema(null, false, { incremental: true, source, target });
      }
    });

    update.forEach((diff) => {
      const key = Object.keys(diff)[0];
      const keyPath = key.split(',');
      const field = diff[key];
      if (isField(field)) {
        onUpdateSchema(null, false, { incremental: true, keyPath, updateValue: field });
      }
    });

    // finish ai chat
    onDone();
  });
}

export function chooseImageFile() {
  return chooseFile({ accept: 'image/png, image/jpeg, image/gif, image/webp' }).then(
    readFileAsDataURL,
  );
}

export function generateFormByImage(base64Image, model, signal) {
  const params = model ? { model, stream: false } : { stream: false };
  const queryString = new URLSearchParams(params).toString();
  const formData = new FormData();
  formData.set('base64Image', base64Image);
  return restApi.post(`/aip/api/gpt/image2form?${queryString}`, formData, { signal });
}

// ensure field id case camel
// ensure there are submit and cancel buttons
// ensure there is a status field
export function ensureSchemaSatisfies(schema) {
  let hasSubmitButton = get(schema, 'form.defaultSubmitButton');
  let hasCancelButton = get(schema, 'form.defaultCancelButton');
  let hasStatusField = false;

  forEachFieldInSchema(schema, (field) => {
    if (field.id && typeof field.id === 'string') {
      field.id = camelCase(field.id);
    }

    if (field.component === 'Button') {
      const actionType = field.componentProps?.action?.type;
      if (actionType === 'submit') {
        hasSubmitButton = true;
      } else if (actionType === 'cancel') {
        hasCancelButton = true;
      }
    }

    if (/(status|状态|状態)/i.test(field.title)) {
      hasStatusField = true;
    }
  });

  if (!hasSubmitButton) {
    set(schema, 'form.defaultSubmitButton', true);
  }
  if (!hasCancelButton) {
    set(schema, 'form.defaultCancelButton', true);
  }

  if (!hasStatusField) {
    const statusField = {
      id: 'status',
      component: 'Input',
      title: 'Status',
      'title_i18n_en-US': 'Status',
      'title_i18n_zh-CN': '状态',
      'title_i18n_ja-JP': '状態',
      hidden: true,
    };
    translateEntireObj(statusField, { deleteI18nVariantProps: true });
    if (!Array.isArray(schema.fields)) {
      schema.fields = [];
    }
    schema.fields.push(statusField);
  }
}
