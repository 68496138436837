import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import SelectElement from '../../SelectElement';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';

const SelectCellEditor = forwardRef(function SelectCellEditor(props, ref) {
  const {
    value: valueProp,
    cellStartedEdit,
    colDef,
    data,
    context,
    componentProps,
    node: nodeProp,
  } = props;

  const [value, setValue] = useState(valueProp);
  const refInput = useRef(null);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        refInput.current.focus();
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <SelectElement
        className="table-select-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          size: context.tableSize,
          ref: refInput,
          defaultOpen: cellStartedEdit,
          autoFocus: cellStartedEdit,
          popupMatchSelectWidth: false,
          ...componentProps,
        }}
      />
    </CurrentDataProvider>
  );
});

SelectCellEditor.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
  ]),
  cellStartedEdit: PropTypes.bool,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    minLengthErrorMessage: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthErrorMessage: PropTypes.string,
  }),
  componentProps: PropTypes.shape({}),
};

// 验证unique列时使用
SelectCellEditor.getUniqueKey = ({ colDef, value }) => {
  if (!value) return value;
  const mapping = colDef.cellEditorParams?.componentProps?.mapping;
  const valueKey = colDef.cellEditorParams?.componentProps?.useOriginValue
    ? mapping?.value || 'value'
    : 'value';
  if (Array.isArray(value)) {
    return JSON.stringify(
      value.map((item) => (typeof item === 'object' ? String(item[valueKey]) : String(item))),
    );
  }
  return typeof value === 'object' ? String(value[valueKey]) : String(value);
};

export default SelectCellEditor;
