const allLocalIcons = {};

(function importAllIcons() {
  const r = require.context('@site/src/assets/icons/', false, /\.svg$/);
  r.keys()
    // filter outer duplicate key caused by webpack.config.js resolve.context
    .filter((key) => key.startsWith('./'))
    .forEach((key) => {
      const lastSlashesIndex = key.lastIndexOf('/');
      const name = key.substring(lastSlashesIndex + 1, key.length - 4); // 4 === '.svg'.length
      allLocalIcons[name] = r(key).default;
    });
})();

export default allLocalIcons;
