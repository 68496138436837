import PropTypes from 'prop-types';
import { Result } from 'antd';
import { checkUserHasPermission, selectIsAdminUser } from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import { PermissionPredicatePropType } from './propTypes';

function RequirePermission(props) {
  const { children, accessPermissionPredicate, context } = props;

  const { userPermissionMap } = context;

  const { t } = useTranslation(['icp-app']);
  const isAdminUser = selectIsAdminUser({ context });

  const canAccess =
    checkUserHasPermission(userPermissionMap, accessPermissionPredicate, isAdminUser) ?? true;

  if (!canAccess) {
    return <Result status={403} title={t('title.access-permission')} />;
  }

  return children;
}

RequirePermission.propTypes = {
  children: PropTypes.node,
  accessPermissionPredicate: PermissionPredicatePropType,
  context: PropTypes.shape({
    userPermissionMap: PropTypes.shape({}),
  }),
};

export default RequirePermission;
