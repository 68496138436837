import { restApi } from '@icp/settings';

const cache = new Map(); // Map<String, Promise>

export async function loadFormEntity(pbcToken, formEntityToken) {
  const cacheKey = `${pbcToken}#@#${formEntityToken}`;

  const loader =
    cache.get(cacheKey) ||
    restApi
      .get(`/form/api/v2/form-entity/${pbcToken}/${formEntityToken}`, {
        skipResponseInterceptors: true,
      })
      .catch((err) => {
        cache.delete(cacheKey);
        throw Error(err.response?.data?.message ?? err.message ?? 'Unknown error');
      });

  cache.set(cacheKey, loader);

  const formEntity = await loader;
  return formEntity;
}
