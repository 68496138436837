import PropTypes from 'prop-types';
import { Input } from 'antd';

function I18nTextareaSingle(props) {
  const { id, value: valueProp, languageOp, onChange, ...other } = props;

  const valueI18nKey = `longText_i18n_${languageOp.value}`;

  const valueI18n = valueProp?.[valueI18nKey];

  const handleChange = (event) => {
    onChange(event, languageOp.value);
  };

  return (
    <div className="textarea-element-i18n-single">
      <div className="textarea-element-addon">{languageOp.label}</div>
      <Input.TextArea {...other} value={valueI18n ?? ''} onChange={handleChange} />
    </div>
  );
}

I18nTextareaSingle.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shape({
    longText: PropTypes.string,
  }),
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default I18nTextareaSingle;
