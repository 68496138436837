import loadable from '@loadable/component';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "markdown" */
      /* webpackPrefetch: true */
      './Markdown'
    ),
);
