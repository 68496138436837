import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  CheckboxElement,
  DatePickerElement,
  InputElement,
  NumberPickerElement,
  PasswordElement,
  SelectElement,
  TextareaElement,
} from '@icp/form-renderer';
import { restApi } from '@icp/settings';
import { delay } from '@icp/utils';

export default function Field({ info, formRef, listenersRef }) {
  const {
    name,
    label,
    description,
    controlType,
    visibleScriptCode,
    visibleScriptCodeDependency,
    optionalScriptCode,
    optionalScriptCodeDependency,
    pickListScriptCode,
    pickListScriptCodeDependency,
  } = info;

  const Comp = {
    text: InputElement,
    text_area: TextareaElement,
    password: PasswordElement,
    number: NumberPickerElement,
    checkbox: CheckboxElement,
    select: SelectElement,
    multiselect: SelectElement,
    date: DatePickerElement,
    date_time: DatePickerElement,
  }[controlType];

  const [visible, setVisible] = useState(!visibleScriptCode);
  const [optional, setOptional] = useState(!!optionalScriptCode);
  const [pickList, setPickList] = useState(pickListScriptCode ? [] : undefined);
  const [shouldEvaluateVisible, setShouldEvaluateVisible] = useState({});
  const [shouldEvaluateOptional, setShouldEvaluateOptional] = useState({});
  const [shouldEvaluatePickList, setShouldEvaluatePickList] = useState({});

  useEffect(() => {
    const listener = (keyPath) => {
      keyPath = keyPath.replace(/^parameters\./, '');

      if (visibleScriptCodeDependency?.includes(keyPath)) {
        setShouldEvaluateVisible({});
      }

      if (optionalScriptCodeDependency?.includes(keyPath)) {
        setShouldEvaluateOptional({});
      }

      if (pickListScriptCodeDependency?.includes(keyPath)) {
        setShouldEvaluatePickList({});
      }
    };

    const list = listenersRef.current;
    list.push(listener);

    return () => {
      list.splice(
        list.findIndex((x) => x === listener),
        1,
      );
    };
  }, [
    listenersRef,
    visibleScriptCodeDependency,
    optionalScriptCodeDependency,
    pickListScriptCodeDependency,
  ]);

  useEffect(() => {
    if (!visibleScriptCode) return () => {};

    const controller = new AbortController();
    const { signal } = controller;

    delay(0, { signal })
      .then(() => formRef.current.getData())
      .then((formData) =>
        restApi.post(
          `/connector/api/connector/evaluate-script`,
          { evaluateParameter: name, parameterAttribute: 'visible', connectorDraft: formData },
          { signal },
        ),
      )
      .then((x) => x.value)
      .then(setVisible);

    return () => {
      controller.abort();
    };
  }, [name, visibleScriptCode, formRef, shouldEvaluateVisible]);

  useEffect(() => {
    if (!optionalScriptCode) return () => {};

    const controller = new AbortController();
    const { signal } = controller;

    delay(0, { signal })
      .then(() => formRef.current.getData())
      .then((formData) =>
        restApi.post(
          `/connector/api/connector/evaluate-script`,
          { evaluateParameter: name, parameterAttribute: 'optional', connectorDraft: formData },
          { signal },
        ),
      )
      .then((x) => x.value)
      .then(setOptional);

    return () => {
      controller.abort();
    };
  }, [name, optionalScriptCode, formRef, shouldEvaluateOptional]);

  useEffect(() => {
    if (!pickListScriptCode) return () => {};

    const controller = new AbortController();
    const { signal } = controller;

    delay(0, { signal })
      .then(() => formRef.current.getData())
      .then((formData) =>
        restApi.post(
          `/connector/api/connector/evaluate-script`,
          { evaluateParameter: name, parameterAttribute: 'pickList', connectorDraft: formData },
          { signal },
        ),
      )
      .then((x) =>
        x.value?.filter((item) => {
          if (typeof item === 'string') return true;
          if (typeof item === 'number') return true;
          if (typeof item === 'boolean') return true;
          if (typeof item === 'object' && 'value' in item && 'label' in item) return true;
          return false;
        }),
      )
      .then(setPickList);

    return () => {
      controller.abort();
    };
  }, [name, pickListScriptCode, formRef, shouldEvaluatePickList]);

  if (!Comp) return null;

  return (
    <Comp
      id={`parameters.${name}`}
      title={label}
      helper={description}
      validation={{
        required: !optional,
      }}
      hidden={!visible}
      componentProps={{
        ...(controlType === 'select' && {
          valueType: 'value',
          options: pickList?.map((x) => {
            if (typeof x === 'string' || typeof x === 'number' || typeof x === 'boolean') {
              return { value: x, label: x };
            }
            return x;
          }),
        }),
        ...(controlType === 'multiselect' && {
          multiple: true,
          valueType: 'value',
          options: pickList?.map((x) => {
            if (typeof x === 'string' || typeof x === 'number' || typeof x === 'boolean') {
              return { value: x, label: x };
            }
            return x;
          }),
        }),
        ...(controlType === 'date_time' && {
          showTime: true,
        }),
        ...(controlType === 'text-area' && {
          simpleMode: true,
        }),
      }}
    />
  );
}

Field.propTypes = {
  info: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    controlType: PropTypes.string,
    dataType: PropTypes.string,
    visibleScriptCode: PropTypes.string,
    visibleScriptCodeDependency: PropTypes.arrayOf(PropTypes.string),
    optionalScriptCode: PropTypes.string,
    optionalScriptCodeDependency: PropTypes.arrayOf(PropTypes.string),
    pickListScriptCode: PropTypes.string,
    pickListScriptCodeDependency: PropTypes.arrayOf(PropTypes.string),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  listenersRef: PropTypes.object,
};
