import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import ACLDialogAnt from './ACLDialogAnt';
import ACLDialogMaterial from './ACLDialogMaterial';

const ACLDialogUI = forwardRef(function ButtonUI(props, ref) {
  const { componentLibrary, ...other } = props;

  if (componentLibrary === 'material-ui') {
    return <ACLDialogMaterial {...other} ref={ref} />;
  }

  return <ACLDialogAnt {...other} ref={ref} />;
});

ACLDialogUI.propTypes = {
  componentLibrary: PropTypes.string,
};

export default ACLDialogUI;
