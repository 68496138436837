// 判断 O 是否位于 ACB 构成的三角形内部
import { isMacOs } from '@icp/utils';

export function isInTriangle(A, B, C, O) {
  // 向量 p1p2 * p1p3 的乘积大于 0 表示 p3 位于 p1p2 的左侧
  const isLeftSide = (p1, p2, p3) => {
    // 向量 p1p2: (p2.x - p1.x, p2.y - p1.y);
    // 向量 p1p3: (p3.x - p1.x, p3.y - p1.y)
    // 向量 A (m, n) 和  向量 B (p, q) 的乘积为 m * q - n * p;
    return (p2.x - p1.x) * (p3.y - p1.y) - (p2.y - p1.y) * (p3.x - p1.x) > 0;
  };

  // 保证 ABC 为逆序
  if (!isLeftSide(A, B, C)) {
    return isInTriangle(A, C, B, O);
  }

  // O 同时位于三角形 3 边的左侧则表示在三角形内部
  return isLeftSide(A, B, O) && isLeftSide(B, C, O) && isLeftSide(C, A, O);
}

export function getShortCutLabel(shortcut) {
  if (typeof shortcut === 'object' && shortcut !== null) {
    const key = isMacOs() ? 'macOs' : 'default';
    return shortcut[key];
  }

  return shortcut;
}
