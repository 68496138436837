import PropTypes from 'prop-types';
import { formatDate, formatDateTime } from '@icp/i18n';
import { DatePicker } from 'antd';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import { useTranslation } from 'react-i18next';
import FieldTitle from '../../FieldTitle';
import { renderReadonlyText } from '../../utils';
import FormHelperText from '../../FormHelperText';
import { ConditionalPropertyPropType } from '../../propTypes';

function DatePickerAnt(props) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    componentProps = {},
    fieldTitleProps,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,

    timeZone,
    showTime,
    formatOptions,
    shouldDisableDate,
  } = props;

  const { t } = useTranslation();

  const getReadonlyText = () => {
    if (!value) return undefined;

    return showTime
      ? formatDateTime(value.toDate(), { timeZone, ...formatOptions })
      : formatDate(value.toDate(), formatOptions);
  };

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        {readonly ? (
          renderReadonlyText(componentProps, getReadonlyText())
        ) : (
          <DatePicker
            renderExtraFooter={
              timeZone
                ? () => (
                    <div className="date-picker-element-time-zone-info">{`${t('time-zone', {
                      ns: 'icp-common',
                    })}: ${timeZone}`}</div>
                  )
                : undefined
            }
            {...componentProps}
            name={id}
            value={value}
            disabled={disabled}
            disabledDate={shouldDisableDate}
            showTime={showTime}
            onChange={onChange}
            onBlur={onTouchChanged}
            status={status}
            // 无法做到点击此刻转换到目标时区的当前时间，因为无法区分点击此刻还是点击面板时间
            {...(timeZone && { showToday: false, showNow: false })}
          />
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

DatePickerAnt.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.shape({
    toDate: PropTypes.func,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({}),
  timeZone: PropTypes.string,
  showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  formatOptions: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  shouldDisableDate: PropTypes.func,
};

export default DatePickerAnt;
