import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { getExtensionComponents } from '@icp/settings';
import { useParams } from 'react-router-dom';
import { resolveNestedValue } from '@icp/form-renderer-core';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useFormApi } from '../FormRenderCtx';
import { useCurrentData } from '../currentDataCtx';

const ExtensionWrapper = forwardRef(function ExtensionWrapper(props, ref) {
  const { keyPath, className: classNameProp, style: styleProp, componentProps = {} } = props;

  const {
    className: componentClassName,
    style: componentStyle,
    extensionId,
    extensionParams,
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = clsx(useClassName(classNameProp), useClassName(componentClassName));
  const style = { ...styleProp, ...componentStyle };

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const ExtensionComp = getExtensionComponents()?.[extensionId];

  const formApi = useFormApi();
  const params = useParams();
  const currentData = useCurrentData();

  const extensionProps = useMemo(() => {
    const formData = formApi.getData();
    return resolveNestedValue({
      obj: extensionParams,
      currentData: currentData || formData,
      formData,
      context: formApi.getContext(),
      params,
    });
  }, [formApi, extensionParams, currentData, params]);

  return (
    <ElementDecorator keyPath={keyPath}>
      <div className={className} style={style} ref={nodeRef}>
        {!ExtensionComp ? (
          <pre>
            <code>
              {JSON.stringify(
                {
                  extensionId: extensionId ?? null,
                  extensionParams: extensionParams ?? null,
                },
                null,
                2,
              )}
            </code>
          </pre>
        ) : (
          <ExtensionComp {...extensionProps} />
        )}
      </div>
    </ElementDecorator>
  );
});

ExtensionWrapper.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  componentProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
    extensionId: PropTypes.string,
    extensionParams: PropTypes.shape({}),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ExtensionWrapper.displayName = 'Extension';

export default withFieldWrapper(ExtensionWrapper);
