import PropTypes from 'prop-types';
import { Loading } from '@icp/components';
import useAuthingState from 'hooks/useAuthingState';
import ErrorPage from './ErrorPage';

function AuthWrapper(props) {
  const { children } = props;

  const { isAuthing, authError } = useAuthingState();

  if (isAuthing) {
    return (
      <div className="app-loading-wrapper">
        <Loading />
      </div>
    );
  }

  if (authError) {
    // render nothing while redirecting to sso page
    if (authError.code === 'GONNA_REDIRECT') {
      return null;
    }

    return <ErrorPage status="error" title="无法访问" subTitle={authError.message} />;
  }

  return children;
}

AuthWrapper.propTypes = {
  children: PropTypes.node,
};

export default AuthWrapper;
