import { useEffect, useState } from 'react';
import { Loading } from '@icp/components';
import { parseJSON } from '@icp/utils';
import FormRenderer from '@icp/form-renderer';
import { fetchPersonalDashboard, registerFormMethods, updatePersonalDashboard } from './action';

export default function PersonalDashboard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [forceRefresh, setForceRefresh] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);
    fetchPersonalDashboard(signal)
      .then((res) => ({
        ...res,
        schema: parseJSON(res.schemaJson) || { fields: [] },
      }))
      .then(setData)
      .finally(() => setLoading(false));

    return () => {
      controller.abort();
    };
  }, [forceRefresh]);

  if (loading) return <Loading />;
  if (!data) return null;

  return (
    <FormRenderer
      context={{ isMainFormRenderer: true, dashboardId: data.id }}
      schema={data.schema}
      onRefreshData={() => setForceRefresh({})}
      onFirstRendered={(formApi) => registerFormMethods(formApi, data.id)}
      onSchemaChanged={(newSchema) =>
        updatePersonalDashboard({
          id: data.id,
          schemaJson: JSON.stringify(newSchema),
        })
      }
    />
  );
}
