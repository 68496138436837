import dayjs from 'dayjs';
import TimeAgo from 'javascript-time-ago';

export default function loadFrameWorkLocale(lng) {
  return Promise.all([
    // ant design
    import(
      /* webpackChunkName: "antd-locales/locale" */
      `antd/es/locale/${lng.replace('-', '_')}.js`
    ).then((m) => m.default),

    // dayjs
    // https://day.js.org/docs/en/i18n/changing-locale
    import(
      /* webpackChunkName: "dayjs-locales/locale" */
      `dayjs/locale/${lng.toLowerCase().split('-')[0]}.js`
    ).then(() => dayjs.locale(lng.toLowerCase())),

    // javascript-time-ago
    // 动态 import 所有 locale 会提示 package.json 没有 export locale 目录报错，先只用最常用的 3 种，不匹配会默认显示 en
    import(
      /* webpackChunkName: "javascript-time-ago/locale" */
      `javascript-time-ago/locale/en`
    ).then((m) => {
      TimeAgo.addDefaultLocale(m.default);
      TimeAgo.addLocale(m.default);
    }),
    import(
      /* webpackChunkName: "javascript-time-ago/locale" */
      `javascript-time-ago/locale/zh`
    ).then((m) => TimeAgo.addLocale(m.default)),
    import(
      /* webpackChunkName: "javascript-time-ago/locale" */
      `javascript-time-ago/locale/ja`
    ).then((m) => TimeAgo.addLocale(m.default)),
  ]).then(([antdLocale]) => antdLocale);
}
