const regexp = /^calc\(([-+*/0-9.() ]+)((, ([0-9]+))?\))$/;

export function isValidCalcExpression(str) {
  if (!regexp.test(str)) {
    return false;
  }

  // The + - * / operators must be surrounded by whitespace
  for (let index = 0; index < str.length; index++) {
    const char = str[index];

    // Support minus number
    if (char === '-') {
      if (!/[0-9]+/.test(str[index + 1]) && (str[index - 1] !== ' ' || str[index + 1] !== ' ')) {
        return false;
      }
    }

    if (['+', '*', '/'].includes(char)) {
      if (str[index - 1] !== ' ' || str[index + 1] !== ' ') {
        return false;
      }
    }
  }

  return true;
}

function doCalc(expression) {
  // TODO, 如果发现 eval 不好的话实现一个简单的类 css calc 的四则运算 +-*/
  let calculated = null;
  try {
    // eslint-disable-next-line no-eval
    calculated = eval(expression);
  } catch (e) {
    console.error(`Invalid calc expression: ${expression}`);
  }

  return calculated;
}

function doPrecision(value, precision) {
  if (typeof value !== 'number' || Number.isInteger(value) || !Number.isInteger(precision)) {
    return value;
  }

  return Number(value.toFixed(precision));
}

/**
 * A simple arithmetic like css calc function
 *
 * @param str
 * @returns {null|any}
 * @example
 *
 * calc('calc(1 + (4 / 2) * 3 - 4, 2)')
 * // => 3.00
 */
export function calc(str) {
  if (!isValidCalcExpression(str)) {
    console.error(`Invalid calc expression: ${str}`);
    return null;
  }

  const match = str.match(regexp);
  const expression = match[1];
  const precision = Number(match[4]);

  const value = doCalc(expression);

  return doPrecision(value, precision);
}
