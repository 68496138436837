import './HistoryView.css';
import dayjs from 'dayjs';
import { Modal, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import DiffView from '../DiffView';
import Resizable from '../Resizable';
import Icon from '../Icon';

function HistoryView(props) {
  const { name, histories, suppressRollbackTo, onLoadData, onCompareWithLocal, onRollbackTo } =
    props;

  const { t } = useTranslation(['icp-components']);
  const [contentVersion, setContentVersion] = useState(histories[0]?.contentVersion);
  const [diffData, setDiffData] = useState(null);

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (!contentVersion) return;

    setDiffData(null);

    const index = histories.findIndex((item) => item.contentVersion === contentVersion);
    const prevVersion = histories[index + 1]?.contentVersion;

    Promise.all([
      prevVersion ? onLoadData(prevVersion) : Promise.resolve({}),
      onLoadData(contentVersion),
    ]).then((res) => {
      const originalData = { ...res[0] };
      const modifiedData = { ...res[1] };

      // 频繁切换
      if (modifiedData.contentVersion !== contentVersion) return;

      // version 不应该显示在 diff 里
      delete originalData?.contentVersion;
      delete modifiedData.contentVersion;

      setDiffData({
        original: {
          name: prevVersion,
          code: originalData,
        },
        modified: {
          name: contentVersion,
          code: modifiedData,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentVersion]);

  const compareWithLocal = (event, version) => {
    event.stopPropagation();
    onCompareWithLocal(version);
  };

  const rollBackTo = (event, version) => {
    event.stopPropagation();
    const info = histories.find((item) => item.contentVersion === version);
    modal.confirm({
      title: t('history.rollback-confirm-title'),
      content: (
        <div>
          {t('history.rollback-content-info')}：
          <div>
            {info.submitter} {dayjs(info.modifyTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>
          <div>{version}</div>
        </div>
      ),
      onOk: () => {
        onRollbackTo(version);
      },
    });
  };

  return (
    <div className="icp-history-view">
      <Resizable placement="right">
        <div className="icp-history-view-sidebar">
          <div style={{ padding: '8px 12px 20px', fontWeight: 500 }}>
            {t('history.title', { name })}
          </div>
          <div className="icp-history-view-list">
            {histories.map((item) => {
              return (
                <div
                  key={item.contentVersion}
                  className={clsx('icp-history-view-item', {
                    active: item.contentVersion === contentVersion,
                  })}
                  onClick={() => setContentVersion(item.contentVersion)}
                >
                  <div>
                    <div>
                      <span style={{ marginRight: 8 }}>{item.submitter}</span>
                      {dayjs(item.modifyTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    <div>
                      <Tooltip title={t('history.compare-with-local')}>
                        <button
                          className="icp-action-button"
                          onClick={(e) => compareWithLocal(e, item.contentVersion)}
                        >
                          <Icon name="diff" size={16} />
                        </button>
                      </Tooltip>
                      {!suppressRollbackTo ? (
                        <Tooltip title={t('history.rollback-to')}>
                          <button
                            className="icp-action-button"
                            onClick={(e) => rollBackTo(e, item.contentVersion)}
                          >
                            <Icon name="oct:reply" size={16} />
                          </button>
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                  <div>{`${item.contentVersion}`}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Resizable>
      {contentVersion ? <DiffView readOnly={true} {...diffData} /> : null}
      {contextHolder}
    </div>
  );
}

HistoryView.propTypes = {
  name: PropTypes.string,
  histories: PropTypes.arrayOf(
    PropTypes.shape({
      contentVersion: PropTypes.string,
      submitter: PropTypes.string,
      modifyTime: PropTypes.string,
    }),
  ),
  suppressRollbackTo: PropTypes.bool,
  current: PropTypes.shape({}),
  onLoadData: PropTypes.func,
  onCompareWithLocal: PropTypes.func,
  onRollbackTo: PropTypes.func,
};

export default HistoryView;
