import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import { Tree } from 'antd';
import { get } from 'lodash-es';
import { restApi } from '@icp/settings';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import { useClassName, useVariablePattern } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

const TreeElement = forwardRef(function TreeElement(props, ref) {
  const { keyPath, id, className: classNameProp, title, style, componentProps = {} } = props;

  const { dataUrl, dataKey = 'results' } = componentProps;

  const ElementDecorator = useElementDecorator();

  const [treeData, setTreeData] = useState('');

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const url = useVariablePattern(dataUrl);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    restApi.get(url, { signal }).then((res) => {
      const data = get(res, dataKey);
      setTreeData(data);
    });

    return () => {
      controller.abort();
    };
  }, [url, dataKey]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div className={clsx('tree-element form-element', className)} style={style} ref={nodeRef}>
        <FieldTitle>{title}</FieldTitle>
        <div>
          <Tree {...componentProps} className={classNameComp} name={id} treeData={treeData} />
        </div>
      </div>
    </ElementDecorator>
  );
});

TreeElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 请求数据的地址
     */
    dataUrl: PropTypes.string,
    /**
     * 数据存在于 response data 里的 key path
     * TODO, refactor to dataResponseKeyPath
     */
    dataKey: PropTypes.string,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TreeElement.displayName = 'Tree';

export default withFieldWrapper(TreeElement);
