import { useParams } from 'react-router-dom';
import { resolveHref } from '@icp/form-renderer-core';
import { useStore } from '../store';
import useDependencies from './useDependencies';
import { useCurrentData } from '../currentDataCtx';

export default function useHref({
  href,
  hrefSelector,
  hrefIsSiteBased,
  suppressInheritIncludeDeleted,
}) {
  const store = useStore();
  const params = useParams();
  const currentData = useCurrentData();

  const [hrefResolved, depIds] = resolveHref({
    href,
    hrefSelector,
    hrefIsSiteBased,
    suppressInheritIncludeDeleted,
    store,
    currentData,
    params,
  });

  useDependencies(depIds);

  return hrefResolved;
}
