import PropTypes from 'prop-types';
import { CodeEditor } from '@icp/components';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { composeEvent, randomNumber } from '@icp/utils';
import { useElementDecorator, useFormApi } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const CodeEditorElement = forwardRef(function CodeEditorElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    componentProps = {},
    fieldTitleProps,
    validation,
    readonly,
    // TODO
    // status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const ElementDecorator = useElementDecorator();
  const formApi = useFormApi();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);
  const editorRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      get editor() {
        return editorRef.current;
      },
    }),
    [],
  );

  const { style: styleComp, language = 'json', ...otherComponentProps } = componentProps;

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-chart-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditorReady = () => {
    formApi.asyncComponentManager.setReady(idToRegister);
  };

  const handleBlur = () => {
    const newValue = editorRef.current.getModel().getValue();
    onChange(newValue);
    if (onTouchChanged) {
      onTouchChanged();
    }
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          'code-editor-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          <CodeEditor
            id={id}
            {...otherComponentProps}
            className={clsx('code-editor-element-inner', classNameComp)}
            style={styleComp}
            readOnly={disabled || readonly}
            language={language}
            bordered={true}
            ref={editorRef}
            onEditorReady={composeEvent(handleEditorReady, otherComponentProps.onEditorReady)}
            onBlur={handleBlur}
          >
            {value}
          </CodeEditor>
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

CodeEditorElement.propTypes = {
  fieldApi: PropTypes.func,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 编辑器的语言，支持 monaco editor 的 language
     */
    language: PropTypes.string,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CodeEditorElement.displayName = 'CodeEditor';

export default withFieldWrapper(CodeEditorElement);
