import { forwardRef, isValidElement, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'antd';
import clsx from 'clsx';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useDataSource } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import RecursionRenderer from '../RecursionRenderer';
import { CurrentDataProvider } from '../currentDataCtx';

const CarouselElement = forwardRef(function CarouselElement(props, ref) {
  const { keyPath, id, className, componentProps = {}, style } = props;
  const {
    itemField,
    preField,
    preFieldStyle,
    nextField,
    nextFieldStyle,
    itemStyle,
    dataUrl,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse,
    defaultValue,
    dataFilters,
    debounceTime,
    sortModel,
    values,
    ...otherProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const carouselRef = useRef(null);
  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const { dataFetched, loading } = useDataSource({
    // TODO, Carousel de  useDataSource 没有跟着最新的改，不清楚用法
    skip: isInDesign || Array.isArray(values),
    dataUrl,
    dataFilters,
    dataResponseKeyPath,
    defaultValue,
    sortModel,
    debounceTime,
    transformDataResponse,
    translateDataResponse,
  });

  const onPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  const onNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  if (!itemField || loading) {
    return null;
  }
  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div className={clsx('form-element carousel-element', className)} ref={nodeRef}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={40}
          height={40}
          fill="none"
          className="arrow left"
          onClick={onPrev}
        >
          <rect width={40} height={40} fill="#fff" rx={20} />
          <path
            fill="#000"
            fillOpacity={0.88}
            stroke="#2E80B4"
            d="m16.518 18.897-.854.853H25.5v.5h-9.836l.854.854 3.67 3.67-.354.353L14.707 20l5.127-5.126.353.353-3.67 3.67Z"
          />
        </svg>
        <Carousel ref={carouselRef} style={style} {...otherProps}>
          {(values || dataFetched || []).map((itemValue, index) => {
            return (
              <CurrentDataProvider key={itemValue.id || index} value={itemValue}>
                <div style={itemStyle}>
                  {isValidElement(itemField) ? (
                    itemField
                  ) : (
                    <RecursionRenderer
                      fields={[itemField]}
                      keyPath={keyPath.concat(['itemField', index])}
                    />
                  )}
                </div>
              </CurrentDataProvider>
            );
          })}
        </Carousel>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={41}
          height={40}
          fill="none"
          onClick={onNext}
          className="arrow right"
        >
          <rect width={40} height={40} x={0.148} fill="#fff" rx={20} />
          <path
            fill="#000"
            fillOpacity={0.88}
            stroke="#2E80B4"
            d="M19.514 25.758h-.51l5.429-4.712 1.011-.878H14.18v-.336h11.266l-1.011-.878-5.429-4.712h.511l6.579 5.71a.063.063 0 0 1 .022.048.063.063 0 0 1-.022.048l-6.582 5.71Z"
          />
        </svg>
      </div>
    </ElementDecorator>
  );
});

CarouselElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    itemStyle: PropTypes.shape({}),
    /**
     * 组件的数据源
     */
    dataUrl: PropTypes.string,
    /**
     * 组件的元素
     */
    itemField: PropTypes.shape({
      component: PropTypes.string,
    }),
    /**
     * 组件默认值
     */
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CarouselElement.displayName = 'Carousel';

export default withFieldWrapper(CarouselElement);
