import { restApi } from '@icp/settings';
import { v4 as uuidv4 } from 'uuid';
import { parseJSON } from '@icp/utils';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { auth } from '../../services/restApi';

export const MessageType = {
  PromptAndFiles: 'prompt-and-files',
  PlainText: 'plain-text',
  Markdown: 'markdown',
  Stream: 'stream',
  Error: 'Error',
  Pending: 'pending',
  SingleSelect: 'single-select',
  MultipleSelect: 'multiple-select',
  Customized: 'customized',
};

export const pendingMessageTypes = [
  MessageType.Pending,
  MessageType.SingleSelect,
  MessageType.MultipleSelect,
  MessageType.Stream,
];

export const EngineType = {
  Kimi: 'kimi',
};

export const FileStatus = {
  Uploading: 'uploading',
  Done: 'done',
  Error: 'error',
};

const Role = {
  User: 'user',
  Assistant: 'assistant',
};

function createMessage(type, content, isSelf) {
  return {
    type,
    content,
    isSelf: isSelf || false,
    id: uuidv4(),
  };
}

function getContentForContext(message) {
  const { content } = message;
  switch (message.type) {
    case MessageType.PromptAndFiles:
      return content.prompt;
    case MessageType.Error:
      return content.errorType;
    default:
      return typeof content === 'string' ? content : '';
  }
}

function preparePayload(content, getContextContent) {
  const { contextMap, promptMessage, fileInfoList } = content;
  const { messages, cache } = contextMap;
  cache[promptMessage.id] = { content: promptMessage.content.prompt, role: Role.User };
  const context = messages
    .filter((msg) => !pendingMessageTypes.includes(msg.type) && msg.type !== MessageType.Error)
    .map((msg) => {
      cache[msg.id] = cache[msg.id] || {
        content: getContextContent?.(msg) || getContentForContext(msg),
        role: msg.isSelf ? Role.User : Role.Assistant,
      };
      return cache[msg.id];
    })
    .concat(cache[promptMessage.id]);
  return JSON.stringify({ context, fileList: fileInfoList.map((file) => file.fileId) });
}

function submit(content, engine = EngineType.Kimi) {
  if (engine === EngineType.Kimi) {
    const payload = preparePayload(content);
    const url = `/aip/api/kimi/chat${content.fileIds && content.fileIds.length > 0 ? '/files' : ''}`;
    return restApi.post(url, payload);
  }
  return Promise.reject(Error(`${engine} is not supported.`));
}

function ask(payload, onReceiveData, abortController, engine = EngineType.Kimi) {
  if (engine === EngineType.Kimi) {
    return fetchEventSource('/aip/api/kimi/chat/stream', {
      method: 'POST',
      signal: abortController?.signal,
      headers: {
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
      body: payload,
      onmessage: (e) => {
        const data = e.data && parseJSON(e.data);
        onReceiveData(data);
      },
      onerror: (error) => {
        console.error(error);
        abortController?.abort();
        throw error;
      },
    });
  }
  return Promise.reject(Error(`${engine} is not supported.`));
}

function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  return restApi.post('/aip/api/kimi/files', formData);
}

function convert(files) {
  // for keeping file meta information during JSON serialization
  return files.map((file) => {
    return {
      uid: file.uid,
      fileId: file.fileId,
      status: file.status,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
    };
  });
}

function cacheData(storageKey, content) {
  let item = content;
  if (typeof content === 'object') {
    item = JSON.stringify(content);
  }
  sessionStorage.setItem(storageKey, item);
}

function restoreData(storageKey) {
  const item = sessionStorage.getItem(storageKey);
  try {
    return item ? JSON.parse(item) : {};
  } catch {
    return item;
  }
}

function clearCache(storageKey) {
  sessionStorage.removeItem(storageKey);
}

export {
  ask,
  createMessage,
  getContentForContext,
  uploadFile,
  convert,
  preparePayload,
  submit,
  cacheData,
  restoreData,
  clearCache,
};
