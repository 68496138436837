export const SESSION_DATE_CATEGORY = {
  TODAY: 'today',
  THIS_WEEK: 'this-week',
  THIS_YEAR: 'this-year',
  OTHER: 'other',
};

export const VIEW_ENUM = {
  AGENT: 'agent',
  SESSION: 'session',
  MESSAGE: 'message',
};
