import PropTypes from 'prop-types';

function Loading16(props) {
  const { size = 16 } = props;

  return (
    <svg
      className="icp-loading-16"
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="currentColor"
    >
      <rect x="90" y="10" width="20" height="58" rx="10" ry="10" fillOpacity={0.2} />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(45) translate(-100, -100)"
        fillOpacity={0.2}
      />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(90) translate(-100, -100)"
        fillOpacity={0.3}
      />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(135) translate(-100, -100)"
        fillOpacity={0.4}
      />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(180) translate(-100, -100)"
        fillOpacity={0.5}
      />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(225) translate(-100, -100)"
        fillOpacity={0.6}
      />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(270) translate(-100, -100)"
        fillOpacity={0.7}
      />
      <rect
        x="90"
        y="10"
        width="20"
        height="58"
        rx="10"
        ry="10"
        transform="translate(100, 100) rotate(315) translate(-100, -100)"
        fillOpacity={0.8}
      />
    </svg>
  );
}

Loading16.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Loading16;
