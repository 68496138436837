import { useTranslation } from 'react-i18next';
import Loading from '../Loading';

function CustomLoadingCellRenderer() {
  const { t } = useTranslation(['icp-vendor-aggrid']);

  return (
    <div className="icp-center" style={{ paddingLeft: 8, gap: 8 }}>
      <Loading delayed={false} centered={false} size={16} />
      {t('loadingOoo')}
    </div>
  );
}

export default CustomLoadingCellRenderer;
