export default function parseJSON(str, options = {}) {
  const { autocomplete = false, silent = false } = options;
  if (str == null) return str;
  try {
    return JSON.parse(str);
  } catch (err) {
    if (!silent) {
      console.error(err);
    }
    if (autocomplete) {
      return parseJSON(completeJson(str), { autocomplete: false, silent });
    }
    return null;
  }
}

/*
  把不完整的json字符串（只缺失后面内容）补成合法json
*/
function completeJson(str) {
  str = str.trim();
  if (!str.length) return null;
  if (!str.startsWith('{') && !str.startsWith('[')) return null;

  if (str.endsWith(':')) {
    str += 'null';
  }

  const stack = [];
  let inString = false;
  let lastKeyPos = 0;

  for (let i = 0; i < str.length; i++) {
    const c = str[i];

    if (inString) {
      if (c === '\\') {
        i++;
        continue;
      }
      if (c === '"') {
        inString = false;
        // 现有部份不是合法json字符串，不做补全
        if (stack.pop() !== '"') return null;
      }
    } else {
      switch (c) {
        case '"':
          inString = true;
          stack.push(c);
          break;
        case '{':
        case '[':
          lastKeyPos = i + 1;
          stack.push(c);
          break;
        case '}':
        case ']': {
          lastKeyPos = i + 1;
          const opposite = { '}': '{', ']': '[' }[c];
          // 现有部份不是合法json字符串，不做补全
          if (stack.pop() !== opposite) return null;
          break;
        }
        case ',':
          lastKeyPos = i;
          break;
        default:
          break;
      }
    }
  }

  return (
    str.slice(0, lastKeyPos) +
    stack
      .reverse()
      .map((c) => ({ '{': '}', '[': ']' }[c]))
      .filter(Boolean)
      .join('')
  );
}
