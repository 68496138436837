import { Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useEventCallback } from '@icp/hooks';
import { useChangeLanguage } from '@icp/i18n';
import { restApi } from '@icp/settings';
import { debounce } from '@icp/utils';
import Loading from '../Loading';
import Popover from '../Popover';
import Menu from '../Menu';
import Icon from '../Icon';

function SpeechButton(props) {
  const {
    className,
    disabled,
    allowSwitchLanguage,
    onChange,
    tooltip,
    speechErrorTip,
    speechListeningTip,
  } = props;

  const { i18n, t } = useTranslation(['icp-components', 'icp-common']);
  const [, , languageOptions] = useChangeLanguage();
  const [lng, setLng] = useState(i18n.language);
  const lngRef = useRef(lng);
  lngRef.current = lng;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [speechTip, setSpeechTip] = useState(null);
  const [recorder, setRecorder] = useState(null);

  const abortControllerRef = useRef();
  const btnRef = useRef();

  const popoverOpenRef = useRef(popoverOpen);
  popoverOpenRef.current = popoverOpen;

  const startSpeech = async () => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    setPopoverOpen(true);
    setSpeechTip(speechListeningTip);

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    if (!popoverOpenRef.current) {
      // 用户快速关闭
      stream.getTracks().forEach((track) => track.stop());
      return;
    }

    // safari只支持mp4格式，不支持webm。chrome两种都支持
    const mediaRecorder = new window.MediaRecorder(stream, { mimeType: 'audio/mp4' });

    const chunks = [];
    mediaRecorder.ondataavailable = (event) => {
      // safari会出现空blob
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    // safari会触发2次onstop, 因此debounce一下
    mediaRecorder.onstop = debounce(() => {
      const formData = new FormData();
      formData.append('file', new File(chunks, 'record.mp4', { type: 'audio/mp4' }));
      formData.append('language', lngRef.current?.split('-')[0]);
      setLoading(true);
      restApi
        .post('/aip/api/gpt/audio2text', formData, { signal })
        .then((res) => res.text || '')
        .then(onChange)
        .finally(() => setLoading(false));
    }, 100);

    mediaRecorder.onerror = (error) => {
      console.error(error);
      setSpeechTip(speechErrorTip);
    };
    // start 1000 为了解决safari的mp4在whisper模型幻觉问题
    // https://community.openai.com/t/whisper-api-completely-wrong-for-mp4/289256/12
    mediaRecorder.start(1000);
    setRecorder({
      stop: () => {
        stream.getTracks().forEach((track) => track.stop());
        mediaRecorder.stop();
      },
    });
  };

  const stopSpeech = useEventCallback(() => {
    recorder?.stop();
    setSpeechTip(null);
    setPopoverOpen(false);
  });

  useEffect(() => {
    const listener = () => {
      if (document.hidden) {
        stopSpeech();
      }
    };
    document.addEventListener('visibilitychange', listener);
    window.addEventListener('blur', stopSpeech);
    return () => {
      stopSpeech();
      document.removeEventListener('visibilitychange', listener);
      window.removeEventListener('blur', stopSpeech);
    };
  }, [stopSpeech]);

  if (
    !window.MediaRecorder ||
    !navigator.mediaDevices ||
    !navigator.mediaDevices.getUserMedia
    //
  ) {
    return null;
  }

  return (
    <>
      <Tooltip title={tooltip} disabled={disabled}>
        <button
          ref={btnRef}
          className={className}
          style={{ position: 'relative' }}
          type="text"
          title={t('speech', { ns: 'icp-common' })}
          disabled={disabled || loading}
          onClick={() => {
            startSpeech();
          }}
          onContextMenu={
            allowSwitchLanguage
              ? (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setMenuOpen(true);
                }
              : null
          }
        >
          <Icon
            name="microphone-outlined"
            size={16}
            style={{
              opacity: loading ? 0.6 : 1,
            }}
          />
          {loading && (
            <Loading
              delayed={false}
              style={{
                position: 'absolute',
                inset: 0,
              }}
            />
          )}
          {allowSwitchLanguage && (
            <Icon
              name="oct:triangle-down"
              size={12}
              style={{
                position: 'absolute',
                right: 1,
                bottom: 1,
                transform: 'rotate(-45deg)',
                opacity: loading ? 0.6 : 1,
              }}
            />
          )}
        </button>
      </Tooltip>
      <Popover
        anchorEl={btnRef.current}
        placement="top"
        open={popoverOpen}
        onClose={() => {
          stopSpeech();
        }}
      >
        <div style={{ opacity: 0.8, padding: '12px 20px', textAlign: 'center' }}>
          <Icon name="microphone" size={42} />
          <div>{speechTip}</div>
        </div>
      </Popover>
      {allowSwitchLanguage && (
        <Menu
          anchorEl={btnRef.current}
          placement="right-start"
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          items={languageOptions.map((x) => ({
            key: x.value,
            label: x.label,
            selected: x.value === lng,
          }))}
          onClick={(event) => {
            setLng(event.key);
          }}
        />
      )}
    </>
  );
}

SpeechButton.propTypes = {
  allowSwitchLanguage: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  speechErrorTip: PropTypes.string,
  speechListeningTip: PropTypes.string,
};

export default SpeechButton;
