import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

// 给 位于 TableElement 下的 ButtonElement 等元素操作 table 用，例如刷新数据
const DialogContextCtx = createContext(null);

export function DialogContextProvider({ children, value }) {
  return <DialogContextCtx.Provider value={value}>{children}</DialogContextCtx.Provider>;
}

DialogContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape({}),
};

export function useDialogContext() {
  return useContext(DialogContextCtx);
}
