import './EditableText.css';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Tooltip } from 'antd';
import clsx from 'clsx';

function EditableText(props) {
  const { className, placeholder, value, tip, regex, onOk, required, disabled, style, ...other } =
    props;

  const [editing, setEditing] = useState(required && !value);
  const inputRef = useRef(null);

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (value) {
      setEditing(false);
    }
  }, [value]);

  const handleClick = () => {
    if (!disabled) {
      setEditing(true);
      setTimeout(() => {
        inputRef.current.input.focus();
      }, 16);
    }
  };

  const handleBlur = (event) => {
    const newValue = event.target.value;
    if (!newValue && required) {
      if (value) {
        setEditing(false);
      }
      return;
    }

    if (!regex || regex.test(newValue)) {
      setEditing(false);
      if (newValue !== value) {
        onOk(newValue);
      }
    } else {
      if (value) {
        setEditing(false);
      }
      modal.error({
        content: tip,
      });
    }
  };

  if (!editing) {
    return (
      <span
        {...other}
        className={clsx('icp-edit-hover', { 'icp-disabled': disabled }, className)}
        onClick={handleClick}
      >
        {value}
      </span>
    );
  }

  return (
    <Tooltip placement="bottomLeft" title={tip}>
      <Input
        {...other}
        className={className}
        defaultValue={value}
        placeholder={placeholder}
        onBlur={handleBlur}
        style={{ width: 180, ...style }}
        ref={inputRef}
      />
      {contextHolder}
    </Tooltip>
  );
}

EditableText.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  tip: PropTypes.string,
  regex: PropTypes.instanceOf(RegExp),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  onOk: PropTypes.func,
};

export default EditableText;
