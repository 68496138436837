// mapping 的 value 不应该重复
const reversedKeyMapping = {
  // tree structure props
  f: 'form',
  fi: 'fields',
  ifi: 'itemField',
  tfi: 'toolbarFields',

  // FormRenderer props
  dsb: 'defaultSubmitButton',
  dcb: 'defaultCancelButton',
  dcp: 'defaultButtonPosition',
  ll: 'labelLayout',
  ru: 'retrieveUrl',
  cr: 'createUrl',
  uu: 'updateUrl',
  sm: 'submitMethod',
  sc: 'showColon',
  sct: 'script',

  // container props
  i: 'id',
  cn: 'className',
  s: 'style',
  t: 'title',
  v: 'value',
  ve: 'valueExpression',
  vf: 'valueField',
  dv: 'defaultValue',
  hi: 'hidden',
  d: 'disabled',
  r: 'readonly',
  c: 'component',
  cp: 'componentProps',
  fietp: 'fieldTitleProps',
  hp: 'helper',
  va: 'validation',
  re: 'required',
  ml: 'maxLength',
  reg: 'regex',
  rgm: 'regexErrorMessage',
  aiv: 'allowI18nValue',
  // fieldConfig
  fc: 'fieldConfig',
  pb: 'pbcToken',
  fet: 'formEntityToken',
  rl: 'relations',

  // conditional
  pp: 'permissionPredicate',
  dp: 'dataPredicate',
  halo: 'hasAllOf',
  hano: 'hasAnyOf',
  op: 'operator',
  cds: 'conditions',
  dfd: 'dataField',
  cond: 'condition',
  vaip: 'valueIfPositive',
  vain: 'valueIfNegative',

  // datasource
  ds: 'dataSource',
  lu: 'listUrl',
  tok: 'token',
  du: 'dataUrl',
  tldr: 'translateDataResponse',
  tfdr: 'transformDataResponse',
  df: 'dataFilters',
  de: 'dataExclusion',
  som: 'sortModel',
  dbt: 'debounceTime',
  ft: 'filterType',

  // style
  w: 'width',
  h: 'height',
  fs: 'fontSize',
  fw: 'fontWeight',
  mg: 'margin',
  pd: 'padding',
  di: 'display',
  p: 'position',
  le: 'left',
  tp: 'top',
  ri: 'right',
  bo: 'bottom',

  // componentProps
  // Grid
  con: 'colNumber',
  g: 'gap',
  rg: 'rowGap',
  cg: 'columnGap',
  ji: 'justifyItems',
  ai: 'alignItems',
  jc: 'justifyContent',
  ac: 'alignContent',

  // Stack
  fd: 'flexDirection',
  // card
  cs: 'contentStyle',

  // Link
  hr: 'href',
  hisb: 'hrefIsSiteBased',
  sbp: 'suppressBasePath',
  siid: 'suppressInheritIncludeDeleted',
  slc: 'suppressLinkColor',
  ta: 'target',
  rep: 'replace',

  // Table
  cd: 'columnDefs',
  dcd: 'defaultColDef',
  rd: 'rowData',
  rmt: 'rowModelType',
  st: 'suppressToolbar',
  spab: 'suppressAddButton',
  spfs: 'suppressFuzzySearch',
  spfss: 'suppressFuzzySearchSpeech',
  spcs: 'suppressColumnSelect',
  spee: 'suppressExcelExport',
  sptr: 'suppressToolbarRefresh',
  spts: 'suppressTableSetting',
  spf: 'suppressFullscreen',
  spss: 'suppressSaveSetting',
  spsd: 'supportShowDeleted',
  sk: 'settingKey',
  tas: 'tableSize',
  adh: 'addButtonHref',
  abc: 'addButtonContent',
  fsp: 'fuzzySearchPlaceholder',
  fso: 'fuzzySearchOpen',
  pn: 'pagination',
  pps: 'paginationPageSize',
  eemspr: 'excelExportMaxSizePerReq',
  eef: 'excelExportFilename',
  ty: 'type',
  ci: 'colId',
  fie: 'field',
  hn: 'headerName',
  fil: 'filter',
  soa: 'sortable',
  crr: 'cellRenderer',
  cer: 'cellEditor',
  crp: 'cellRendererParams',
  cep: 'cellEditorParams',
  fp: 'filterParams',
  ed: 'editable',

  // EditableTable
  car: 'canAddRow',
  nrd: 'newRowDefaultValues',
  mrc: 'maxRowCount',
  cdr: 'canDeleteRow',

  // Button
  si: 'size',
  co: 'content',
  a: 'action',
  icn: 'iconName',
  m: 'method',
  u: 'url',
  reqd: 'requestData',
  ofp: 'openFormProps',
  odp: 'openDialogProps',
  um: 'uploadingMsg',
  ufk: 'uploadFileKey',
  sa: 'successAction',
  gm: 'globalMethod',

  // DatePicker
  ntz: 'noTimeZone',
  sht: 'showTime',
  dd: 'disabledDate',
  iv: 'includeValue',
  vaf: 'valueFrom',
  vat: 'valueTo',

  // ACL
  ilu: 'idListUrl',
  un: 'unit',
  ma: 'mapping',
  uov: 'useOriginValue',
  ph: 'placeholder',
  sui: 'supportImport',
  sue: 'supportExport',
  se: 'stringEqual',
  ec: 'exportColumns',
  tk: 'transformKey',
  tu: 'transformUrl',
  mspr: 'maxSizePerReq',

  // Select
  mul: 'multiple',
  o: 'options',
  l: 'label',
  vt: 'valueType',
  // EChart
  cate: 'category',
  chs: 'chartSettings',
  eco: 'echartOption',
  sers: 'series',

  // Icon
  n: 'name',
  cor: 'color',

  // Upload
  ut: 'uploadTitle',
  sbt: 'subTitle',
  lt: 'listType',
  mc: 'maxCount',
  act: 'accept',
  sl: 'sizeLimit',

  // Input
  inl: 'i18nInputLayout',
};

// value 压缩暂时只放组件名称，否则容易误修改用户输入的属性值
const reversedValueMapping = {
  // elements
  au: 'Audit',
  ch: 'Checkbox',
  sw: 'Switch',
  ra: 'Radio',
  sl: 'Slider',
  in: 'Input',
  np: 'NumberPicker',
  dp: 'DatePicker',
  trp: 'TimeRangePicker',
  se: 'Select',
  up: 'Upload',
  pa: 'Password',
  tt: 'Textarea',
  te: 'Text',
  bu: 'Button',
  li: 'List',
  tb: 'Tab', // legacy support, Tab already renamed to Tabs
  tbs: 'Tabs',
  ta: 'Table',
  im: 'Image',
  ic: 'Icon',
  iu: 'IconUpload',
  st: 'Status',
  ste: 'Steps',
  pr: 'Progress',
  ac: 'ACL',
  rt: 'RichText',
  rat: 'Rate',
  fd: 'FormDesigner',
  pe: 'Permissions',
  drg: 'DateRangePicker',
  dd: 'DropDown',
  di: 'Divider',
  qr: 'QRCode',
  tr: 'Tree',
  et: 'EditableTable',
  ec: 'EChart',
  ca: 'Cascader',
  ts: 'TreeSelect',
  mi: 'Mining',
  ce: 'CodeEditor',
  co: 'Collapse',
  ss: 'Success',
  nt: 'NavTab', // legacy support, NavTab already renamed to NavTabs
  nts: 'NavTabs',
  oc: 'Ocr',
  tl: 'TodoList',
  // layouts
  // compatible with old usage
  nl: 'NormalLayout',
  fl: 'FlowLayout',
  gl: 'GridLayout',
  cl: 'CardLayout',
  ml: 'MobileLayout',
  //
  bo: 'Box', // Box, NormalLayout mean same things
  sk: 'Stack', // Stack, FlowLayout means same things
  gr: 'Grid',
  cd: 'Card',
  mo: 'Mobile',
  // wrappers
  lw: 'LinkWrapper',
  lk: 'Link',
  da: 'Data',
  tm: 'Timer',
  ex: 'Extension',
};

// { key: value } => { value: key }
function reverseMapping(obj) {
  const reversed = {};

  for (const [k, v] of Object.entries(obj)) {
    if (Object.hasOwnProperty.call(reversed, v)) {
      throw new Error(`mapping value is duplicated ${k}: ${v}`);
    }
    reversed[v] = k;
  }

  return reversed;
}

function forEachSchema(schema, callback) {
  const dfs = (obj) => {
    if (typeof obj !== 'object' || obj === null) return;

    for (const [k, v] of Object.entries(obj)) {
      if (typeof k === 'string') {
        callback(obj, k, v);
      }
      if (typeof v === 'object') {
        dfs(v);
      }
      if (Array.isArray(v)) {
        for (const item of v) {
          dfs(item);
        }
      }
    }
  };

  dfs(schema);
}

function compressSchema(schema) {
  const keyMapping = reverseMapping(reversedKeyMapping);
  const valueMapping = reverseMapping(reversedValueMapping);

  forEachSchema(schema, (obj, k, v) => {
    const i18nSuffix = k.endsWith('_i18n_key') ? '_i_k' : '';
    const key = k.slice(0, k.length - (i18nSuffix ? '_i18n_key'.length : 0));
    const compressedKey = keyMapping[key];
    const compressedValue = k === 'component' ? valueMapping[v] : null;

    if (compressedKey) {
      obj[compressedKey + i18nSuffix] = compressedValue || v;
      delete obj[k];
    }
  });

  return schema;
}

function deCompressSchema(compressedSchema) {
  forEachSchema(compressedSchema, (obj, k, v) => {
    const i18nSuffix = k.endsWith('_i_k') ? '_i18n_key' : '';
    const key = k.slice(0, k.length - (i18nSuffix ? '_i_k'.length : 0));
    const deCompressedKey = reversedKeyMapping[key];
    const deCompressedValue = deCompressedKey === 'component' ? reversedValueMapping[v] : null;

    if (deCompressedKey) {
      obj[deCompressedKey + i18nSuffix] = deCompressedValue || v;
      delete obj[k];
    }
  });

  return compressedSchema;
}

export { compressSchema, deCompressSchema };
