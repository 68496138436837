import { get, set } from 'lodash-es';
import { forEachFieldInSchema, isInputField } from '@icp/form-schema';
import { ensureFields } from './formEntityHelper';

// 移除掉由于意外原因或者编辑 json 时不小心产生的无效字段
function removeInvalidField(schema, keyPath) {
  const lastKey = keyPath[keyPath.length - 1];
  const parentKeyPath = keyPath.slice(0, -1);

  const parent = get(schema, parentKeyPath);

  if (Array.isArray(parent) && typeof lastKey === 'number') {
    parent.splice(lastKey, 1);
  }
}

// 以前 designer 里有一些属性，移除掉有助于减少 schema 的 size
// TODO, save 的时候 remove 整个 object 所有的 empty object 不影响性能和使用体验
function removeEmptyObject(field) {
  const { componentProps = {} } = field;

  for (const [key, value] of Object.entries(field)) {
    if (typeof value === 'object' && value !== null && !Object.keys(value).length) {
      delete field[key];
    }
  }

  for (const [key, value] of Object.entries(componentProps)) {
    if (typeof value === 'object' && value !== null && !Object.keys(value).length) {
      delete componentProps[key];
    }
  }
}

// 删除之前由于代码 bug 导致的给所有 field 存的 domain 和 field。这个 bug 其实只存在了几周，在后面合适的时间脏数据去的差不多了可以删掉这段代码。
function removeUselessDomain(field) {
  if (field.domain !== undefined) {
    delete field.domain;
  }
  if (field.module !== undefined) {
    delete field.module;
  }
}

// itemField is deprecated, use fields instead
function migrateListItemField(field) {
  const { itemField, itemProps, itemStyle } = field.componentProps;
  delete field.componentProps.itemField;
  delete field.componentProps.itemStyle;
  if (!field.fields) {
    field.fields = [];
  }
  field.fields.push(itemField);

  if (itemStyle) {
    field.componentProps.itemProps = {
      ...itemProps,
      style: { ...itemStyle, ...itemProps?.style },
    };
  }
}

// stepItems -> items
function migrateStepsItems(field) {
  const { componentProps = {} } = field;
  const { stepItems, items: itemsProp } = componentProps;

  // delete old and props
  delete componentProps.stepItems;
  delete componentProps.progressDot;
  delete componentProps.size;
  delete componentProps.type;

  if (!itemsProp) {
    componentProps.items = stepItems;
  }
}

// panelHeaders -> items
function migrateCollapseItems(field) {
  const { fields, componentProps = {} } = field;
  const { panelHeaders, panelProps, items } = componentProps;

  delete field.fields;
  delete field.componentProps.panelHeaders;
  delete field.componentProps.panelProps;

  if (!items) {
    componentProps.items = Object.keys(panelHeaders).map((key) => {
      const theField = fields.find((info) => info.id === key);
      if (theField) {
        delete theField.id;
      }
      return {
        ...panelProps,
        key,
        label: panelHeaders[key],
        fields: [theField].filter(Boolean),
      };
    });
  }
}

// tabNames -> items
function migrateTabsItems(field) {
  const { fields, componentProps = {} } = field;
  const { tabNames, tabItemProps, items } = componentProps;

  delete field.fields;
  delete field.componentProps.tabNames;
  delete field.componentProps.tabItemProps;

  if (!items) {
    componentProps.items = Object.keys(tabNames).map((key) => {
      const theField = fields.find((info) => info.id === key);
      if (theField) {
        delete theField.id;
      }
      return {
        ...tabItemProps?.[key],
        key,
        label: tabNames[key],
        fields: [theField].filter(Boolean),
      };
    });
  }
}

// Tab -> Tabs; NavTab -> NavTabs
function renameComponent(field) {
  if (field.component === 'Tab') {
    field.component = 'Tabs';
  }
  if (field.component === 'NavTab') {
    field.component = 'NavTabs';
  }
}

// acl componentProps.column is old antd table config, we now use ag-grid
// field.unit -> field.componnetProps.unit
function migrateACLProps(field) {
  const { unit } = field;
  const { columns, columnDefs } = field.componentProps;

  delete field.unit;
  delete field.componentProps.columns;

  if (unit && !field.componentProps.unit) {
    field.componentProps.unit = unit;
  }

  if (!columnDefs && Array.isArray(columns)) {
    field.componentProps.columnDefs = columns.map((col) => {
      const { key, title } = col;
      return {
        colId: key,
        field: key,
        headerName: title,
      };
    });
  }
}

// function migrateTableDeleteUrl(field) {
//   const actionColumns =
//     field.componentProps?.columnDefs?.filter((colDef) => colDef?.type === 'ACTION_COLUMN') ?? [];
//   if (!actionColumns.length) return;

//   const deleteActions = actionColumns.flatMap(
//     (colDef) =>
//       colDef.cellRendererParams?.actions?.filter(
//         (action) => action?.type === 'DELETE' && action?.url === '/form/api/form-entity-data/:id',
//       ) ?? [],
//   );
//   if (!deleteActions.length) return;

//   // 标准的deleteUrl将作为缺省值，不用配在json里，便于以后改地址。
//   deleteActions.forEach((action) => {
//     action.url = undefined;
//   });
// }

function refineDataSourceScope(field) {
  const { scope } = field;
  const { dataUrl } = field.componentProps || {};

  // 如果 scope 等于 project 的话，dataSource 里肯定有 pbcToken，FormDesigner 里已经改成判断是否有 pbcToken
  // 来判断 dataSource 是 pbc 内部还是 pbc 外部了
  delete field.scope;
  // 更早的配置 scopeUnderProject 同样如此
  delete field.scopeUnderProject;

  if (scope === 'api' && dataUrl) {
    if (!field.componentProps) {
      field.componentProps = {};
    }
    field.componentProps.dataSource = {
      listUrl: dataUrl,
      scope: 'api',
    };
  }
}

function supportButton(field) {
  function forEachButtonAction(cb) {
    const forEach = (action) => {
      if (!action || typeof action !== 'object') {
        return;
      }
      cb(action);
      forEach(action.successAction);
    };

    forEach(field.componentProps?.action);
  }

  forEachButtonAction((action) => {
    if (action.type === 'open') {
      // open rename 成了 dialog，并且 deprecated 了 suppressCurrentDataAsDefaultData
      action.type = 'dialog';
      if (action.suppressCurrentDataAsDefaultData) {
        // 默认 dialog 已经不传递 currentData 到打开的 dialog
        delete action.suppressCurrentDataAsDefaultData;
      } else if (!action.openFormProps?.FormRendererProps?.defaultData) {
        // 否则则兼容老代码，使用 currentData 作为 defaultData 传给 打开的 dialog
        set(action, 'openFormProps.FormRendererProps.defaultData', ':currentData');
      }

      // 删除由于 designer 代码 bug 误在 json 里放置的 contentType 属性，这个 bug 存在时间不超过一个月，脏数据应该很少，
      // 后续什么时候合适了都可以删掉这段代码。
      if (action.openFormProps?.contentType) {
        delete action.openFormProps.contentType;
      }
    }
  });
}

// SelectElement 并不支持 ant design 的 mode tags，所有组件多选统一使用 multiple 属性接口
function modeToMultiple(field) {
  if (field.componentProps?.mode) {
    if (['multiple', 'tags'].includes(field.componentProps?.mode)) {
      field.componentProps.multiple = true;
    }
    delete field.componentProps.mode;
  }
}

export function legacySupportSchema(schema) {
  if (!schema) {
    return schema;
  }
  // blockRenderWhenFetching 参数删掉了，FormRenderer 改为一定获取了数据再渲染
  if (schema.form?.blockRenderWhenFetching) {
    delete schema.form.blockRenderWhenFetching;
  }
  forEachFieldInSchema(schema, (field, keyPath) => {
    if (String(field) !== '[object Object]') {
      removeInvalidField(schema, keyPath);
      return;
    }

    if (!isInputField(field)) {
      removeUselessDomain(field);
    }
    const { component, componentProps = {}, scope, scopeUnderProject } = field;

    removeEmptyObject(field);

    if (component === 'List' && componentProps.itemField) {
      migrateListItemField(field);
    }
    if (component === 'Steps' && componentProps.stepItems) {
      migrateStepsItems(field);
    }
    if (component === 'Collapse' && componentProps.panelHeaders) {
      migrateCollapseItems(field);
    }
    if (component === 'Tab') {
      migrateTabsItems(field);
    }
    if (['Tab', 'NavTab'].includes(component)) {
      renameComponent(field);
    }
    if (component === 'ACL' && (componentProps.columns || field.unit)) {
      migrateACLProps(field);
    }
    if (scope || scopeUnderProject) {
      refineDataSourceScope(field);
    }
    if (component === 'Button') {
      supportButton(field);
    }
    if (component === 'Select') {
      modeToMultiple(field);
    }
    if (component === 'Table' && componentProps.columnDefs?.length > 0) {
      // 海油MES仍在ceta上配，但已经固定了前端版本不准备升级，清除删除地址会导致它删除时找不到url报错。
      // migrateTableDeleteUrl(field);
    }
  });
  return schema;
}

export function legacySupportEntity(formEntity, needEnsureFields = true) {
  formEntity.layouts.forEach((layout) => {
    legacySupportSchema(layout.schema);
  });

  if (needEnsureFields) {
    formEntity.fields = ensureFields(
      formEntity.fields || [],
      formEntity.layouts,
      formEntity.pbcToken,
    );
  } else {
    formEntity.fields = formEntity.fields || [];
  }

  return formEntity;
}
