import { validationRulesObj } from './utils/validationUtils';

export const OOTB_VALIDATION = Object.keys(validationRulesObj);

export const GROUPABLE_CHART_DIMENSION = [
  'categoryAxis',
  'legend',
  //
];

export const AGGREGATABLE_CHART_DIMENSION = [
  'valueAxis',
  'value',
  //
];

export const CHART_DIMENSION = [...GROUPABLE_CHART_DIMENSION, ...AGGREGATABLE_CHART_DIMENSION];

// 老的 condition，不推荐使用
export const DEPRECATED_CONDITIONS = [
  'equals',
  'string_equals',
  'does_not_equal',
  'string_does_not_equal',
  'greater_than',
  'less_than',
  'contains',
  'is_present',
];
// 使用 ag-grid 的 filter types，除了 inRange
// 再加上转换成 string 在相等的两个 stringEquals stringNotEqual
export const CONDITIONS = [
  'equals',
  'notEqual',
  'stringEquals',
  'stringNotEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'contains',
  'notContains',
  'startsWith',
  'endsWith',
  'blank',
  'notBlank',
];

// TODO, 为了不去 import @icp/component 的内容这里在放一份。AG_FILTER_TYPES 已经变成了我们组件的通用 dataFilters
// 的格式，后续可以考虑把 constants 都从 AgTable/constant.js 里挪到这里了。
export const FILTER_TYPES_UNARY = [
  'blank',
  'notBlank',
  //
];

// Do not change to uppercase
export const HELPER_TEXT_ERROR = 'error';
export const HELPER_TEXT_WARNING = 'warning';
export const HELPER_TEXT_NORMAL = 'normal';
export const HELPER_TEXT_TYPES = [HELPER_TEXT_ERROR, HELPER_TEXT_WARNING, HELPER_TEXT_NORMAL];
export const UPLOAD_TYPE_MAP = {
  file: '.pdf,.zip,.rar,.7z,.html,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.jpg,.jpeg,.png,.gif,.avi,.mp4,.mov,.wmv',
  img: '.jpg,.jpeg,.png,.gif',
  video: '.mp4,.avi,.mov,.wmv',
};

export const I18N_VALUE_COLLAPSED = 'collapsed';
export const I18N_VALUE_FLAT = 'flat';
export const DEFAULT_I18N_VALUE = I18N_VALUE_COLLAPSED;

export const JS_API_EVENT = new Set([
  'firstRendered',
  'ready',
  'unmount',
  'fieldValueChange',
  'change',
  'validationFail',
  'schemaChanged',
  'submitted',
]);

export const FORM_API_STAGE = {
  getForm: 'flow-get-form',
  getView: 'form-get-view',
  fetchSchema: 'fetch-schema-json',
  fetchRemoteContext: 'fetch-remote-context',
  init: 'init',
  fetchData: 'fetch-data',
  rendered: 'rendered',
  ready: 'ready',
};
