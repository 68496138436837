import PropTypes from 'prop-types';
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useImperativeHandle,
  useRef,
} from 'react';
import clsx from 'clsx';
import RecursionRenderer from '../RecursionRenderer';
import ConditionalHidden from '../ConditionalHidden';
import FormControl from '../FormControl';
import { useClassName } from '../hooks';
import { useElementDecorator } from '../FormRenderCtx';
import { withFieldWrapper } from '../fieldWrapper';

function GridItem(props) {
  const { span, children } = props;
  return (
    <div
      className={clsx('grid-layout-item', {
        'grid-layout-item-span': span !== 'auto',
      })}
      style={{
        '--span': span || 1,
      }}
    >
      {children}
    </div>
  );
}

GridItem.propTypes = {
  children: PropTypes.node,
  span: PropTypes.number,
};

const GridLayout = forwardRef(function GridLayout(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const {
    colNumber = 12,
    gap: gapProp = 8,
    rowGap: rowGapProp,
    columnGap: columnGapProp,
    justifyItems,
    alignItems,
    justifyContent,
    alignContent,
    style: gridStyle,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const rowGap = rowGapProp ?? gapProp;
  const columnGap = columnGapProp ?? gapProp;
  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <div
        {...otherComponentProps}
        className={clsx(
          'grid-layout form-layout',
          { 'no-children': noChildren },
          className,
          classNameComp,
        )}
        style={{
          justifyItems,
          alignItems,
          justifyContent,
          alignContent,
          ...style,
          ...gridStyle,
          '--column-gap': `${columnGap}px`,
          '--row-gap': `${rowGap}px`,
          '--column-number': colNumber,
        }}
        ref={nodeRef}
      >
        {children ? (
          Children.map(children, (child) => {
            if (!child) return child;
            const realChild =
              child.type === ConditionalHidden || child.type === FormControl
                ? child.props?.children
                : child;

            const { span } = isValidElement(realChild) ? realChild.props : {};

            if (child.type === ConditionalHidden) {
              return cloneElement(child, {
                children: <GridItem span={span}>{realChild}</GridItem>,
              });
            }

            return <GridItem span={span}>{child}</GridItem>;
          })
        ) : (
          <RecursionRenderer
            fields={fields}
            keyPath={keyPath.concat(['fields'])}
            // TODO，22 年把 conditional hidden 从 RecursionRenderer 移走的时候好像改出了 bug，hidden 的元素会留一个空白的 grid-item 在那里
            Wrapper={GridItem}
          />
        )}
      </div>
    </ElementDecorator>
  );
});

GridLayout.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * Root div 的 className
     */
    className: PropTypes.string,
    /**
     * Root div 的 style
     */
    style: PropTypes.shape({}),
    /**
     * Grid 的列的数量
     * @default 12
     */
    colNumber: PropTypes.number,
    /**
     * Grid 行和列之间的间距，等同 css `flex` 的属性
     */
    gap: PropTypes.number,
    /**
     * Grid 行之间的间距，等同 css `flex` 的属性
     */
    rowGap: PropTypes.number,
    /**
     * Grid 列之间的间距，等同 css `flex` 的属性
     */
    columnGap: PropTypes.number,
    /**
     * 等同 css `flex` 的属性
     */
    justifyItems: PropTypes.string,
    /**
     * 等同 css `flex` 的属性
     */
    alignItems: PropTypes.string,
    /**
     * 等同 css `flex` 的属性
     */
    justifyContent: PropTypes.string,
    /**
     * 等同 css `flex` 的属性
     */
    alignContent: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      rowIndex: PropTypes.number,
      colIndex: PropTypes.number,
      rowSpan: PropTypes.number,
      colSpan: PropTypes.number,
    }),
  ),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
GridLayout.displayName = 'Grid';

export default withFieldWrapper(GridLayout);
