import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import Popover from '../../../Popover';
import SearchBox from '../../../SearchBox';
import { getFilterModel, getFilterTypeIcon } from './utils';

function AddFilter(props) {
  const { gridApi, allColumns, pinnedFilter, filterModel, onChange } = props;

  const { t } = useTranslation(['icp-components']);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const anchorEl = useRef(null);

  const otherColumns = allColumns.filter((col) => {
    return (
      col.colDef.filter &&
      !filterModel.find((model) => model.colId === col.colId) &&
      !(Array.isArray(pinnedFilter) && pinnedFilter.includes(col.colId))
    );
  });

  if (!otherColumns.length) {
    return null;
  }

  return (
    <>
      <button
        className="icp-table-filter-add-button icp-action-button"
        onClick={() => setOpen(true)}
        ref={anchorEl}
      >
        <Icon name="oct:plus" size={16} />
        {t('table.filter.add-filter')}
      </button>
      <Popover
        className="icp-table-column-select"
        anchorEl={anchorEl.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <SearchBox
          open={true}
          suppressSpeech={true}
          autoFocus={true}
          debounce={false}
          placeholder={t('table.filter.filter-by')}
          onSearch={setSearchText}
        />
        <div className="icp-table-menu icp-thin-scrollbar">
          {open &&
            otherColumns
              .filter((col) => {
                return !searchText || col.colDef.headerName?.toLowerCase().includes(searchText);
              })
              .map((column) => {
                const { colDef } = column;
                const filterType = getFilterModel(column.colId, gridApi);
                const icon = getFilterTypeIcon(filterType);

                return (
                  <div
                    key={column.colId}
                    className="item icp-clickable"
                    onClick={() => {
                      setOpen(false);
                      onChange(column.colId);
                    }}
                  >
                    {icon}
                    {colDef.headerName}
                  </div>
                );
              })}
        </div>
      </Popover>
    </>
  );
}

AddFilter.propTypes = {
  gridApi: PropTypes.shape({}),
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  filterModel: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default AddFilter;
