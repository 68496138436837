import { forwardRef } from 'react';
import { useComponentLibrary } from '../../FormRenderCtx';
import DatePickerAnt from './DatePickerAnt';
import DatePickerMaterial from './DatePickerMaterial';

const DatePickerUI = forwardRef(function DatePickerUI(props, ref) {
  const componentLibrary = useComponentLibrary();

  if (componentLibrary === 'material-ui') {
    return <DatePickerMaterial {...props} ref={ref} />;
  }

  return <DatePickerAnt {...props} ref={ref} />;
});

export default DatePickerUI;
