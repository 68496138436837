import { get } from 'lodash-es';
import {
  extractDataDependencyIds,
  isMobile,
  joinPaths,
  removeUndefined,
  resolveVariablePattern,
} from '@icp/utils';
import { selectContext, selectIsAdminUser, selectUserPermissionMap, selectValues } from '../store';
import { FILTER_TYPES_UNARY } from '../constant';

const distinct = (list) => Array.from(new Set(list));

export function checkUserHasPermission(userPermissionMap, permissionPredicate, isAdminUser) {
  if (isAdminUser) return true;
  if (!permissionPredicate) return undefined;
  if (!userPermissionMap) {
    console.error('Fatal Error: no user permission map');
    return false;
  }

  const { hasAllOf, hasAnyOf } = permissionPredicate;
  if (hasAllOf && hasAllOf.length > 0) {
    return hasAllOf.every((permToken) => userPermissionMap[permToken]);
  }
  if (hasAnyOf && hasAnyOf.length > 0) {
    return hasAnyOf.some((permToken) => userPermissionMap[permToken]);
  }
  return undefined;
}

function getArrayAutoValue(value) {
  const getValue = (item) => {
    if (typeof item === 'object' && item !== null) {
      return item.value;
    }

    return item;
  };

  return value.map(getValue);
}

export function checkMatchDataPredicate({ dataPredicate, currentData, formData, context, params }) {
  if (!dataPredicate) return undefined;

  const { operator = 'AND', conditions } = dataPredicate;

  if (!conditions?.length) return undefined;
  if (!['AND', 'OR'].includes(operator)) return undefined;

  if (!currentData && !formData && !context) return false;

  return conditions[operator === 'AND' ? 'every' : 'some'](
    //
    (item) => {
      const { dataField, field, condition, value } = item;

      const subMatched = checkMatchDataPredicate({
        dataPredicate: item,
        currentData,
        formData,
        context,
        params,
      });

      if (subMatched === false) return false;

      const sourceValue = dataField
        ? get(currentData || formData, dataField)
        : resolveVariablePattern({
            currentData: currentData || formData,
            formData,
            context,
            params,
            pattern: field,
          });

      const targetValue = resolveVariablePattern({
        currentData: currentData || formData,
        formData,
        context,
        params,
        pattern: value,
      });

      // 'equals',
      // 'string_equals',
      // 'does_not_equal',
      // 'string_does_not_equal',
      // 'greater_than',
      // 'less_than',
      // 'contains',
      // 'is_present',
      // 这几个是老的接口，不推荐使用
      if (condition === 'equals') {
        return sourceValue === targetValue;
      }
      if (condition === 'string_equals' || condition === 'stringEquals') {
        return String(sourceValue) === String(targetValue);
      }
      if (condition === 'does_not_equal' || condition === 'notEqual') {
        return sourceValue !== targetValue;
      }
      if (condition === 'string_does_not_equal' || condition === 'stringNotEqual') {
        return String(sourceValue) !== String(targetValue);
      }
      if (condition === 'less_than' || condition === 'lessThan') {
        return sourceValue < targetValue;
      }
      if (condition === 'lessThanOrEqual') {
        return sourceValue <= targetValue;
      }
      if (condition === 'greater_than' || condition === 'greaterThan') {
        return sourceValue > targetValue;
      }
      if (condition === 'greaterThanOrEqual') {
        return sourceValue >= targetValue;
      }
      if (condition === 'contains') {
        if (Array.isArray(sourceValue)) {
          return getArrayAutoValue(sourceValue).includes(targetValue);
        }
        return String(sourceValue).includes(targetValue);
      }
      if (condition === 'notContains') {
        if (Array.isArray(sourceValue)) {
          return !getArrayAutoValue(sourceValue).includes(targetValue);
        }
        return !String(sourceValue).includes(targetValue);
      }
      if (condition === 'startsWith') {
        return String(sourceValue).startsWith(targetValue);
      }
      if (condition === 'endsWith') {
        return String(sourceValue).endsWith(targetValue);
      }
      if (condition === 'is_present' || condition === 'notBlank') {
        return (
          sourceValue !== null && sourceValue !== undefined && String(sourceValue).trim() !== ''
        );
      }
      if (condition === 'blank') {
        return (
          sourceValue === null || sourceValue === undefined || String(sourceValue).trim() === ''
        );
      }

      console.error(`Unknown condition operator: ${condition}`);

      return false;
    },
  );
}

export function isPredicateConfigLike(value) {
  if (typeof value !== 'object' || !value) return false;
  return 'permissionPredicate' in value || 'dataPredicate' in value;
}

export function isMatchableConfigLike(value) {
  if (typeof value !== 'object' || !value) return false;
  return 'value' in value && isPredicateConfigLike(value.matched);
}

/**
 * 按条件取值(单值)
 *
 * @param value 支持传: PredicateConfig<原始值类型>,  Array<MatchableConfig<原始值类型> | PredicateConfig<原始值类型> | 原始值类型>
 * @param currentData
 * @param store
 * @param params
 * @returns {[*, string[]]} 返回元组<匹配命中的值, 依赖字段id列表>。依赖列表仅包含匹配命中的项和之前的项，不包含命中项之后的项。
 */
export function resolveConditionalProperty({ value, currentData, store, params }) {
  if (isPredicateConfigLike(value)) {
    const resolvedValue = resolvePredicateConfig({ value, currentData, store, params });
    const depIds = extractConditionalDependencyIds(value, currentData);
    return [resolvedValue, depIds];
  }
  return resolveMatchable({ value, currentData, store, params, matchFirst: true });
}

/**
 * 按条件取值(列表)
 *
 * @param value 支持传: Array<MatchableConfig<原始值类型> | PredicateConfig<原始值类型> | 原始值类型>
 * @param currentData
 * @param store
 * @param params
 * @returns {[*, string[]]} 返回元组<匹配命中的值列表, 依赖字段id列表>
 */
export function resolveConditionalListProperty({ value, currentData, store, params }) {
  return resolveMatchable({ value, currentData, store, params, matchFirst: false });
}

export function resolveConditionalPropertyValue({ value, currentData, store, params }) {
  const [resolvedValue] = resolveConditionalProperty({ value, currentData, store, params });
  return resolvedValue;
}

function resolvePredicateConfig({ value, currentData, store, params }) {
  if (!isPredicateConfigLike(value)) return value;

  const {
    permissionPredicate,
    dataPredicate,
    valueIfPositive = true,
    valueIfNegative = false,
  } = value;

  const formData = selectValues(store.getState());
  const userPermissionMap = selectUserPermissionMap(store.getState());
  const context = selectContext(store.getState());
  const isAdminUser = selectIsAdminUser(store.getState());

  const userHasPerm = checkUserHasPermission(userPermissionMap, permissionPredicate, isAdminUser);

  const isMatched = checkMatchDataPredicate({
    dataPredicate,
    currentData,
    formData,
    context,
    params,
  });

  if ((userHasPerm ?? true) && (isMatched ?? true)) {
    return valueIfPositive;
  }
  return valueIfNegative;
}

function resolveMatchable({ value, currentData, store, params, matchFirst = false }) {
  if (!Array.isArray(value)) return [value, []];

  const depIdsList = [];
  const resolvedValueList = [];

  for (const v of value) {
    let isMatched = false;
    let resolvedValue;
    let depIds;

    if (isPredicateConfigLike(v)) {
      isMatched = true;
      resolvedValue = resolvePredicateConfig({ value: v, currentData, store, params });
      depIds = extractConditionalDependencyIds(v, currentData);
    } else if (isMatchableConfigLike(v)) {
      isMatched = resolvePredicateConfig({ value: v.matched, currentData, store, params });
      resolvedValue = v.value;
      depIds = extractConditionalDependencyIds(v.matched, currentData);
    } else {
      isMatched = true;
      resolvedValue = v;
      depIds = [];
    }

    depIdsList.push(depIds);
    if (isMatched) {
      resolvedValueList.push(resolvedValue);
      if (matchFirst) break;
    }
  }

  return [
    matchFirst ? resolvedValueList.pop() : resolvedValueList,
    distinct(depIdsList.flat()),
    //
  ];
}

/**
 * @param conditionalValue
 * @param currentData
 * @returns {unknown[]|*[]}
 *
 * const conditionalValue = {
 *     dataPredicate: {
 *       conditions: [
 *         {
 *           dataField: ':status',
 *           condition: 'equals',
 *           value: 'success',
 *         },
 *       ],
 *     },
 *   }
 * extractConditionalDependencyIds(conditionalValue)
 * => ['status']
 */
export function extractConditionalDependencyIds(conditionalValue, currentData) {
  if (!conditionalValue || !conditionalValue.dataPredicate) {
    return [];
  }

  const ids = new Set();

  function extract(conditions) {
    for (const cd of conditions) {
      const { dataField, field, value } = cd;

      if (!currentData && dataField) {
        ids.add(dataField);
      }

      const idsInField = extractDataDependencyIds(field, currentData);
      const idsInValue = extractDataDependencyIds(value, currentData);

      for (const id of [...idsInField, ...idsInValue]) {
        ids.add(id);
      }
      extract(cd.conditions || []);
    }
  }

  extract(conditionalValue.dataPredicate.conditions || []);

  return Array.from(ids);
}

function removeInvalidDataFilters(dataFilters) {
  const isValidSimpleValue = (value) => {
    // 目前来说 '' undefined  null 都是属于没意义的 filter，都过滤掉。如果有需求再修改。
    return typeof value === 'number' || (typeof value === 'string' && value);
  };
  return dataFilters.filter((item) => {
    const { filterType, type, filter, filterTo, dateFrom, dateTo, values } = item;

    if (FILTER_TYPES_UNARY.includes(type)) {
      return true;
    }

    if (filterType === 'text') {
      return isValidSimpleValue(filter);
    }
    if (filterType === 'number') {
      if (type === 'inRange') {
        return isValidSimpleValue(filter) && isValidSimpleValue(filterTo);
      }
      return isValidSimpleValue(filter);
    }
    if (filterType === 'date') {
      if (type === 'inRange') {
        return isValidSimpleValue(dateFrom) && isValidSimpleValue(dateTo);
      }
      return isValidSimpleValue(dateFrom);
    }
    if (filterType === 'set') {
      // TODO, set 没有做完善，不知道 values 数组里支持些什么东西
      return Array.isArray(values);
    }

    return true;
  });
}

function resolveFilterStructWithvalues({
  dataFiltersMatched,
  store,
  context,
  formData,
  currentData,
  params,
}) {
  const [depIdsInValues, filterStructs] = [[], []];
  (dataFiltersMatched || []).forEach((item) => {
    if (Array.isArray(item.conditions) && item.conditions.length > 0) {
      const [loopValues, loopStruct] = resolveFilterStructWithvalues({
        dataFiltersMatched: item.conditions,
        store,
        context,
        formData,
        currentData,
        params,
      });

      // 填充循环值
      depIdsInValues.push(...loopValues);
      filterStructs.push({
        operator: item.operator,
        conditions: loopStruct,
      });
      return;
    }
    const depIdsInValue = extractDataDependencyIds(item.filter ?? item.value, currentData);
    depIdsInValues.push(...depIdsInValue);

    const filterValue = resolveVariablePattern({
      currentData: currentData || formData,
      formData,
      context,
      params,
      pattern: item.filter ?? item.value, // value is deprecated
    });

    const multiValues = resolveVariablePattern({
      currentData: currentData || formData,
      formData,
      context,
      params,
      pattern: item.values,
    });
    const noNeedValue = FILTER_TYPES_UNARY.includes(item.type);

    const filterStruct = removeUndefined({
      colId: item.id,
      filterType: item.filterType || 'text',
      type: item.type || 'contains',
      filter: noNeedValue ? undefined : filterValue,
      filterTo: noNeedValue ? undefined : item.filterTo,
      dateFrom: noNeedValue ? undefined : item.dateFrom,
      dateTo: noNeedValue ? undefined : item.dateTo,
      values: noNeedValue ? undefined : multiValues,
    });
    filterStructs.push(filterStruct);
  });

  return [distinct(depIdsInValues), removeInvalidDataFilters(filterStructs)];
}

export function resolveDataFilters({ dataFilters, store, currentData, params }) {
  const formData = selectValues(store.getState());
  const context = selectContext(store.getState());

  // check deprecated dataFilters
  if (process.env.NODE_ENV === 'development') {
    if (Array.isArray(dataFilters)) {
      for (const item of dataFilters) {
        if (item.id && item.type && item.value && item.matched) {
          console.error('dataFilter matched 的用法错误，将不会生效！');
        }
      }
    }
  }

  const [dataFiltersMatched, depIdsInMatched] = resolveConditionalListProperty({
    value: dataFilters,
    currentData,
    store,
    params,
  });

  // TODO，暂时只 resolve item.filter，其他几个入 filterTo，dateFrom 和 dateTo 和 values 暂时无需求
  const [depIdsInValues, dataFiltersStruces] = resolveFilterStructWithvalues({
    dataFiltersMatched,
    store,
    formData,
    currentData,
    context,
    params,
  });

  return [dataFiltersStruces, distinct([...depIdsInMatched, ...depIdsInValues])];
}

export function joinPbcToken(context, href) {
  // If href is relative path like ../toPath/subPath or toPath/subPath, leave react router Link to handle.
  if (
    context.pbcToken &&
    context.pbcToken !== '/' &&
    typeof href === 'string' &&
    href.startsWith('/')
  ) {
    // TODO, 如果没有配置 /mobile 的 router，就想在 mobile 打开 pc 的 link 怎么办？
    if (isMobile()) {
      return joinPaths(['/mobile', context.pbcToken, href]);
    }
    return joinPaths(['/', context.pbcToken, href]);
  }
  return href;
}

export function resolveHref({
  href,
  hrefSelector,
  hrefIsSiteBased,
  suppressInheritIncludeDeleted,
  store,
  currentData,
  params,
  response,
}) {
  const formData = selectValues(store.getState());
  const context = selectContext(store.getState());

  if (process.env.NODE_ENV === 'development') {
    if (hrefSelector) {
      console.warn(`'hrefSelector' is deprecated, use 'href' instead`);
    }
  }

  // conditional match a href
  const [hrefMatched, depIdsInMatched] = resolveConditionalProperty({
    value: href ?? hrefSelector,
    currentData,
    store,
    params,
  });

  // resolve variable pattern
  const depIdsInValue = extractDataDependencyIds(hrefMatched, currentData);
  let hrefResolved = resolveVariablePattern({
    currentData: currentData || formData,
    formData,
    context,
    params,
    response,
    pattern: hrefMatched,
  });

  // handle basename
  if (!hrefIsSiteBased) {
    hrefResolved = joinPbcToken(context, hrefResolved);
  }

  // 跳转已删除
  if (
    !suppressInheritIncludeDeleted &&
    hrefResolved &&
    window.location.search.includes('include_deleted=true') &&
    !hrefResolved.includes('include_deleted=true')
  ) {
    hrefResolved += `${hrefResolved.includes('?') ? '&' : '?'}include_deleted=true`;
  }

  if (typeof hrefResolved !== 'string') {
    // react route 的 Link 如果接收到 undefined 或者 null 会导致页面奔溃，
    // 产品里目前 href 只支持 string 类型，不支持 Link 的对象形式
    hrefResolved = '';
  }

  return [hrefResolved, distinct([...depIdsInMatched, ...depIdsInValue])];
}

export function resolveUrl({ url, store, context: contextProp, currentData, params, response }) {
  const formData = selectValues(store.getState());
  const context = contextProp || selectContext(store.getState());

  // conditional match a url
  const [urlMatched, depIdsInMatched] = resolveConditionalProperty({
    value: url,
    currentData,
    store,
    params,
  });

  // resolve variable pattern
  const depIdsInValue = extractDataDependencyIds(urlMatched, currentData);
  const urlResolved = resolveVariablePattern({
    currentData: currentData || formData,
    formData,
    context,
    params,
    response,
    pattern: urlMatched,
    failWhenNoVariableValue: true,
  });

  return [urlResolved, distinct([...depIdsInMatched, ...depIdsInValue])];
}
