import loadJS from './loadJS';

let promise;

export default function loadRequire() {
  if (promise) {
    return promise;
  }
  promise = new Promise((resolve) => {
    if (window.require) {
      resolve(window.require);
    } else {
      // TODO, requirejs 不好使，加载 prettier 会报错，monaco editor 的 loader 一切正常
      // const jsUrl = `${window.ICP_PUBLIC_PATH}requirejs/require.min.js`;
      const jsUrl = `${process.env.VENDOR_PATHS.vs}/loader.js`;
      loadJS(jsUrl).then(() => {
        window.require.config({ paths: process.env.VENDOR_PATHS });
        resolve(window.require);
      });
    }
  });
  return promise;
}
