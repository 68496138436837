import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { OOTB_VALIDATION } from '@icp/form-renderer-core';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';
import InputElement from '../../InputElement';

const TextCellEditor = forwardRef(function TextCellEditor(props, ref) {
  const {
    value: valueProp,
    cellStartedEdit,
    eventKey,
    charPress,
    colDef,
    data,
    context,
    validation,
    node: nodeProp,
  } = props;

  const [value, setValue] = useState(() => {
    if (eventKey === 'Backspace' || eventKey === 'Delete') {
      return '';
    }

    if (charPress) {
      return charPress;
    }

    return valueProp;
  });
  const refInput = useRef(null);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        refInput.current.node.querySelector('input').focus();
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <InputElement
        className="table-text-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          size: context.tableSize,
          maxLength: validation?.maxLength,
          autoFocus: cellStartedEdit,
        }}
        ref={refInput}
      />
    </CurrentDataProvider>
  );
});

TextCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cellStartedEdit: PropTypes.bool,
  eventKey: PropTypes.string,
  charPress: PropTypes.string,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minLength: PropTypes.number,
    minLengthErrorMessage: PropTypes.string,
    maxLength: PropTypes.number,
    maxLengthErrorMessage: PropTypes.string,
    ootb: PropTypes.oneOf(OOTB_VALIDATION),
    regex: PropTypes.string,
    regexErrorMessage: PropTypes.string,
  }),
};

export default TextCellEditor;
