import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { useRef } from 'react';

/* server side header need to custom a checkbox, ag-grid not provided */
function ServerSideCheckboxHeader(props) {
  const { displayName, column, context, enableMenu, enableSorting, showColumnMenu, progressSort } =
    props;

  const { allSelected, indeterminate } = context.selectionState || {};
  const { selectAll } = context;

  const sort = column.getSort();

  const menuIconRef = useRef(null);

  const handleMenuIconClick = () => {
    showColumnMenu(menuIconRef.current);
  };

  return (
    <div className="icp-server-side-checkbox-header">
      <Checkbox
        checked={allSelected}
        indeterminate={indeterminate}
        onChange={(event) => {
          selectAll(event.target.checked);
        }}
      />
      <div
        className="ag-header-cell-label"
        onClick={enableSorting ? () => progressSort() : undefined}
      >
        <span className="ag-header-cell-text">{displayName}</span>
        {(sort === 'asc' && (
          <span className="ag-icon ag-icon-asc" unselectable="on" role="presentation" />
        )) ||
          (sort === 'desc' && (
            <span className="ag-icon ag-icon-desc" unselectable="on" role="presentation" />
          )) ||
          null}
      </div>
      {enableMenu ? (
        <span
          className="ag-header-icon ag-header-cell-menu-button"
          aria-hidden="true"
          onClick={handleMenuIconClick}
          ref={menuIconRef}
        >
          <span className="ag-icon ag-icon-menu" unselectable="on" role="presentation" />
        </span>
      ) : null}
    </div>
  );
}

ServerSideCheckboxHeader.propTypes = {
  displayName: PropTypes.string,
  column: PropTypes.shape({
    getSort: PropTypes.func,
  }),
  context: PropTypes.shape({
    selectionState: PropTypes.shape({
      allSelected: PropTypes.bool,
      indeterminate: PropTypes.bool,
    }),
    selectAll: PropTypes.func,
  }),
  enableMenu: PropTypes.bool,
  enableSorting: PropTypes.bool,
  showColumnMenu: PropTypes.func,
  progressSort: PropTypes.func,
};

export default ServerSideCheckboxHeader;
