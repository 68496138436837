import { EvalWorker, resolveVariablePattern } from '@icp/utils';
import { get, set } from 'lodash-es';
import { shouldTranslateByDefault } from '@icp/settings';
import { translateEntireObj } from '@icp/i18n';

export default async function fetchRemoteContext({
  requestConfig,
  resultHolder,
  urlPatternResolverParams,
  restApi,
  signal,
}) {
  if (!Array.isArray(requestConfig)) return;
  if (!resultHolder) return;

  await Promise.allSettled(
    requestConfig.map(
      async ({
        url,
        dataResponseKeyPath,
        transformDataResponse,
        translateDataResponse = shouldTranslateByDefault(),
        alias,
        subsequence,
      }) => {
        const resolvedUrl = resolveVariablePattern({ pattern: url, ...urlPatternResolverParams });

        await restApi.get(resolvedUrl, { signal }).then(async (response) => {
          let result = dataResponseKeyPath ? get(response, dataResponseKeyPath) : response;

          if (transformDataResponse) {
            result = await EvalWorker.shared().execEval(result, transformDataResponse, { signal });
          }

          if (translateDataResponse) {
            translateEntireObj(result);
          }

          if (alias) {
            set(resultHolder, alias, result);
          }

          await fetchRemoteContext({
            requestConfig: subsequence,
            resultHolder,
            urlPatternResolverParams,
            restApi,
            signal,
          });
        });
      },
    ),
  );
}
