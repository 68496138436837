export default function removeUndefined(obj) {
  const newObj = { ...obj };

  for (const [k, v] of Object.entries(newObj)) {
    if (v === undefined) {
      delete newObj[k];
    }
  }

  return newObj;
}
