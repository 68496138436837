import { restApi } from '@icp/settings';
import { v4 as uuidv4 } from 'uuid';
import { parseJSON } from '@icp/utils';
import fetchSSE from './fetchSSE';

export const FileStatus = {
  Uploading: 'uploading',
  Done: 'done',
  Error: 'error',
};

export const MessageType = {
  Prompt: 'prompt',
  Answer: 'answer',
  Pending: 'pending',
  Image: 'image',
  Stream: 'stream',
  Error: 'Error',
};

export const MessageContentType = {
  PlainText: 'plaintext',
  Markdown: 'markdown',
};

export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  return restApi.post('/aip/api/kimi/files', formData);
}

export function makeSerializableFiles(files) {
  // for keeping file meta information during JSON serialization
  return files.map((file) => {
    return {
      uid: file.uid,
      fileId: file.fileId,
      status: file.status,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
    };
  });
}

export function createMessage(type, content, isSelf) {
  return {
    type,
    content,
    isSelf: isSelf || false,
    id: uuidv4(),
  };
}

export function getContentForContext(message) {
  const { content } = message;
  switch (message.type) {
    case MessageType.Prompt:
      return content.prompt;
    case MessageType.Error:
      return content.errorType;
    case MessageType.Image:
      return '[image]';
    case MessageType.Answer:
      return content.text;
    default:
      return typeof content === 'string' ? content : '';
  }
}

export async function fetchJSONStream(request, handlers, signal) {
  const { url, payload } = request;

  let total = '';
  for await (const e of fetchSSE({
    signal,
    url,
    body: JSON.stringify(payload),
  })) {
    if (e.event === '') {
      total += e.data;
      const partialResult = parseJSON(total, { silent: true, autocomplete: true });
      if (partialResult) {
        handlers.processPartialResult(partialResult);
      }
    } else if (e.event === 'done') {
      const finalResult = parseJSON(e.data, { silent: true, autocomplete: true });
      if (finalResult) {
        handlers.processFinalResult(finalResult);
      }
      handlers.doneCallback?.();
    }
  }
}

export function findQuestionFromMessages(answerMessageId, messages) {
  const answerMessageIdx = messages.findIndex((x) => x.id === answerMessageId);
  if (answerMessageIdx <= 0) return null;
  for (let i = answerMessageIdx - 1; i >= 0; i--) {
    if (messages[i].type === 'prompt') {
      return messages[i].content.prompt;
    }
  }
  return null;
}
