import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { Icon } from '@icp/components';
import { ConditionalPropertyPropType } from '../../propTypes';
import { FormHelperTextMaterial } from '../../FormHelperText';

function calendarIcon(props) {
  return <Icon name="solar:calendar-mark-bold-duotone" size={24} {...props} />;
}

function DownIcon(props) {
  return <Icon name="oct:chevron-down" size={18} style={{ margin: 3 }} {...props} />;
}

function LeftIcon(props) {
  return <Icon name="oct:chevron-left" size={18} style={{ margin: 3 }} {...props} />;
}

function RightIcon(props) {
  return <Icon name="oct:chevron-right" size={18} style={{ margin: 3 }} {...props} />;
}

function DatePickerMaterial(props) {
  const {
    id,
    title,
    value,
    disabled,
    readonly,
    componentProps = {},
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,

    shouldDisableDate,
  } = props;

  const { i18n } = useTranslation();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language ? i18n.language.split('-')[0] : undefined}
    >
      <DatePicker
        {...componentProps}
        id={id}
        label={title}
        value={value}
        disabled={disabled}
        readOnly={readonly}
        required={validation?.required}
        onChange={onChange}
        onBlur={onTouchChanged}
        slotProps={{
          textField: {
            error: status === 'error',
            helperText:
              !readonly && !helpersIsEmpty(helpers) ? (
                <FormHelperTextMaterial helpers={helpers} />
              ) : null,
          },
          openPickerButton: {
            style: {
              color: 'rgb(99, 115, 129)',
            },
          },
        }}
        slots={{
          openPickerIcon: calendarIcon,
          switchViewIcon: DownIcon,
          rightArrowIcon: RightIcon,
          leftArrowIcon: LeftIcon,
        }}
        shouldDisableDate={shouldDisableDate}
      />
    </LocalizationProvider>
  );
}

DatePickerMaterial.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.shape({}),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
  shouldDisableDate: PropTypes.func,
};

export default DatePickerMaterial;
