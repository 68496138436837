import './Tabs.css';
import { Children, cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { composeEvent } from '@icp/utils';
import Tab from './Tab';

const Tabs = forwardRef(function Tabs(props, ref) {
  const { children, className, value, onChange, ...other } = props;

  const handleClick = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={clsx('icp-tabs', className)} role="tablist" ref={ref} {...other}>
      {Children.toArray(children)
        .filter((child) => child?.type === Tab)
        .map((child) => {
          return cloneElement(child, {
            className: clsx(child.props.className, { current: value === child.props.value }),
            onClick: composeEvent(() => handleClick(child.props.value), child.props.onClick),
          });
        })}
    </div>
  );
});

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

export default Tabs;
