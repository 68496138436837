import PropTypes from 'prop-types';
import { selectFieldValueI18n } from '@icp/form-renderer-core';
import { Input } from 'antd';
import { useSelector } from '../../../store';

function I18nInputSingle(props) {
  const { id, languageOp, onChange, ...other } = props;

  const valueI18n = useSelector(selectFieldValueI18n(id, languageOp.value));

  const handleChange = (event) => {
    onChange(event, languageOp.value);
  };

  return (
    <Input
      {...other}
      value={valueI18n ?? ''}
      onChange={handleChange}
      addonBefore={languageOp.label}
    />
  );
}

I18nInputSingle.propTypes = {
  id: PropTypes.string,
  languageOp: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default I18nInputSingle;
