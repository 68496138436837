import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { allOldExternalForm, allPbcComponent } from '@icp/page-renderer-core';

function getComponentFromLocalPbc(componentName, pbcToken) {
  const pbc = allPbcComponent[pbcToken];

  if (!pbc) {
    return null;
  }

  return pbc[componentName];
}

const ExternalFormRenderer = forwardRef(function ExternalFormRenderer(props, ref) {
  const { context, externalFormName, FormRendererProps } = props;

  const { pbcToken } = context;

  const Comp = pbcToken
    ? getComponentFromLocalPbc(externalFormName, pbcToken)
    : allOldExternalForm[externalFormName];

  if (process.env.NODE_ENV === 'development') {
    console.log(`externalFormName: ${externalFormName}`);
  }

  if (!Comp) {
    console.error(`Unknown component: ${externalFormName}`);
    return null;
  }

  return <Comp {...FormRendererProps} context={context} ref={ref} />;
});

ExternalFormRenderer.propTypes = {
  context: PropTypes.shape({
    pbcToken: PropTypes.string,
  }),
  externalFormName: PropTypes.string,
  FormRendererProps: PropTypes.shape({}),
};

export default ExternalFormRenderer;
