import {
  resolveHref,
  resolveInitialValues,
  resolveNestedValue,
  resolveUrl,
} from '@icp/form-renderer-core';
import {
  browserDownloadFile,
  chooseFile,
  EvalWorker,
  immutableSet,
  joinPaths,
  removeUndefined,
  resolveVariablePattern,
} from '@icp/utils';
import { message, restApi } from '@icp/settings';
import { set } from 'lodash-es';
import { getTFunc } from '@icp/i18n';
import { emptyOpenPageRendererProps, legacySupportForOpenAction } from './utils';

const needDoSuccessAction = (type) => {
  // successAction of 'dialog' will be down in onOpenDialog
  return !['dialog', 'cancel', 'refreshPage'].includes(type);
};

export default async function executeAction(currentAction, response, options) {
  const {
    store,
    context,
    currentData,
    params,
    formApi,
    tableApi,
    buttonApi,
    modal,
    navigate,
    onOpenDialog,
    onSetFieldValue,
    isUnMount,
  } = options;
  const t = getTFunc(['icp-form-renderer', 'icp-common']);

  if (!currentAction) {
    return null;
  }

  if (currentAction.type === 'open') {
    console.warn('Button open type action is deprecated, use dialog type instead');
    // legacy support for old open action
    currentAction = legacySupportForOpenAction(currentAction);
  }

  const resolveButtonUrl = (url) => {
    return resolveUrl({ url, store, context, currentData, params, response })[0];
  };

  const resoleConfigVar = (pattern, res = response, failWhenNoVariableValue = false) => {
    const formData = formApi.getData();
    return resolveVariablePattern({
      pattern,
      context,
      currentData: currentData || formData,
      formData,
      params,
      response: res,
      failWhenNoVariableValue,
    });
  };

  const handleSubmitAction = () => {
    buttonApi.setLoading(true);

    return formApi.submit().then(
      (res = {}) => {
        if (!isUnMount.current && currentAction.successAction?.type !== 'link') {
          // if successAction type is link, do not close loading, link may has delay
          buttonApi.setLoading(false);
        }
        if (!res.preventSuccessMessage) {
          const msg = resoleConfigVar(currentAction.msg, res, true);
          message.success(msg || t('success.submit'));
        }
        return res;
      },
      (err) => {
        if (!isUnMount.current) {
          buttonApi.setLoading(false);
        }
        return Promise.reject(err);
      },
    );
  };

  const handleSaveAction = () => {
    buttonApi.setLoading(true);

    return formApi.submit({ saveOnly: true }).then(
      (res = {}) => {
        if (!isUnMount.current && currentAction.successAction?.type !== 'link') {
          // if successAction type is link, do not close loading, link may has delay
          buttonApi.setLoading(false);
        }
        if (!res.preventSuccessMessage) {
          const msg = resoleConfigVar(currentAction.msg, res, true);
          message.success(msg || t('success.save'));
        }
        return res;
      },
      (err) => {
        if (!isUnMount.current) {
          buttonApi.setLoading(false);
        }
        return Promise.reject(err);
      },
    );
  };

  const handleCancelAction = () => {
    const res = currentAction.response
      ? resolveNestedValue({
          obj: currentAction.response,
          currentData,
          formData: formApi.getData(),
          context,
          params,
        })
      : response;
    formApi.cancel({ state: currentAction.state, response: res });
  };

  const handleRefreshAction = () => {
    formApi.refresh();
    // refresh form 的同时也刷新一下 table
    if (tableApi) {
      tableApi.refresh();
    }
  };

  const handleRefreshPageAction = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleRefreshTableAction = () => {
    const { tableIds } = currentAction;

    if (Array.isArray(tableIds) && tableIds.length) {
      tableIds.forEach((tableId) => formApi.getFieldApi(tableId).refresh());
    } else if (tableApi) {
      tableApi.refresh();
    } else {
      console.error('This Button not in TableElement context');
    }
  };

  const handleDialogAction = () => {
    let openFormPropsResolved = {
      ...emptyOpenPageRendererProps,
      ...removeUndefined({ ...currentAction.openFormProps }),
    };
    openFormPropsResolved = {
      ...openFormPropsResolved,
      retrieveUrl: resolveButtonUrl(openFormPropsResolved.retrieveUrl),
      createUrl: resolveButtonUrl(openFormPropsResolved.createUrl),
      updateUrl: resolveButtonUrl(openFormPropsResolved.updateUrl),
      formEntityDataId: resoleConfigVar(openFormPropsResolved.formEntityDataId),
      flowInstanceId: resoleConfigVar(openFormPropsResolved.flowInstanceId),
      threadId: resoleConfigVar(openFormPropsResolved.threadId),
    };
    if (openFormPropsResolved.FormRendererProps?.defaultData) {
      const resolvedDefaultData = resolveInitialValues({
        defaultValues: openFormPropsResolved.FormRendererProps.defaultData,
        currentData,
        formData: formApi.getData(),
        context,
        params,
      });
      openFormPropsResolved = immutableSet(
        openFormPropsResolved,
        ['FormRendererProps', 'defaultData'],
        resolvedDefaultData,
      );
      // 当不支持 legacy open 的时候可以删掉如下代码
      if (currentAction.legacyOpenUseDefaultDataId) {
        if (!openFormPropsResolved.formEntityDataId) {
          openFormPropsResolved.formEntityDataId = resolvedDefaultData.id;
        }
        if (!openFormPropsResolved.flowInstanceId) {
          openFormPropsResolved.flowInstanceId = resolvedDefaultData.flowInstanceId;
        }
      }
    }
    onOpenDialog({
      ...currentAction,
      openFormProps: openFormPropsResolved,
    });
  };

  const handleRequestAction = () => {
    buttonApi.setLoading(true);

    const {
      method,
      url: urlPattern,
      requestData,
      transformDataRequest,
      transformDataResponse,
    } = currentAction;

    // request action 需要发送给服务器的数据，默认是发送整个 formData
    const resolveRequestData = () => {
      // 只能是 null 或者 {} 对象，null 表示不传任何数据。
      if (!requestData || typeof requestData !== 'object' || Array.isArray(requestData)) {
        return null;
      }
      const resolvedData = {};
      for (const [k, v] of Object.entries(requestData)) {
        const resolvedValue = resoleConfigVar(v);
        set(resolvedData, k, resolvedValue);
      }
      return resolvedData;
    };

    const url = resolveButtonUrl(urlPattern);

    return Promise.resolve(currentData || formApi.getDataWithPreprocessor())
      .then((data) => {
        if (requestData !== undefined) {
          return resolveRequestData();
        }
        return data;
      })
      .then((data) => {
        if (transformDataRequest) {
          return EvalWorker.shared().execEval(data, transformDataRequest, {
            params: {
              context: formApi.getContext(),
              formData: formApi.getData(),
              formSchema: formApi.getSchema(),
            },
          });
        }
        return data;
      })
      .then((data) => {
        return restApi[method](url, data);
      })
      .then((res) => {
        if (transformDataResponse) {
          return EvalWorker.shared().execEval(res, transformDataResponse, {
            params: {
              context: formApi.getContext(),
              formData: formApi.getData(),
            },
          });
        }
        return res;
      })
      .then(
        (res) => {
          if (!isUnMount.current && currentAction.successAction?.type !== 'link') {
            // if successAction type is link, do not close loading, link may has delay
            buttonApi.setLoading(false);
          }
          if (currentAction.msg) {
            const msg = resoleConfigVar(currentAction.msg, res, true);
            if (msg) {
              message.success(msg);
            }
          }
          return res;
        },
        (err) => {
          if (!isUnMount.current) {
            buttonApi.setLoading(false);
          }
          return Promise.reject(err);
        },
      );
  };

  const handleSetFormDataAction = () => {
    const { formData } = currentAction;

    if (!formData || typeof formData !== 'object') {
      return;
    }

    for (const [k, v] of Object.entries(formData)) {
      const resolvedValue = resoleConfigVar(v);
      onSetFieldValue({ keyPath: k, value: resolvedValue });
    }
  };

  const handleDownloadAction = () => {
    const { url: urlPattern, filename } = currentAction;
    const url = resolveButtonUrl(urlPattern);

    // use browser link download
    browserDownloadFile(url, filename);
  };

  const handleUploadAction = async () => {
    const { accept, url: urlPattern, uploadFileKey, uploadingMsg } = currentAction;

    if (!uploadFileKey) {
      throw new Error(t('button.missing-upload-file-key'));
    }

    const url = resolveButtonUrl(urlPattern);
    const file = await chooseFile({ accept });
    const data = new FormData();
    data.append(uploadFileKey, file);

    buttonApi.setLoading(true);
    message.open({
      key: 'import-button-loading-message',
      type: 'loading',
      content: uploadingMsg || t('button.uploading'),
      duration: 0,
    });
    return restApi.post(url, data).then(
      (res) => {
        if (!isUnMount.current && currentAction.successAction?.type !== 'link') {
          // if successAction type is link, do not close loading, link may has delay
          buttonApi.setLoading(false);
        }
        message.open({
          key: 'import-button-loading-message',
          type: 'success',
          content: currentAction.msg || t('button.uploaded'),
        });
        return res;
      },
      (err) => {
        if (!isUnMount.current) {
          buttonApi.setLoading(false);
        }
        message.destroy('import-button-loading-message');
        return Promise.reject(err);
      },
    );
  };

  const handleLinkAction = () => {
    const {
      url: urlPattern, // TODO, delete it, use href and hrefSelector
      href: hrefPattern,
      hrefSelector,
      hrefIsSiteBased,
      target,
      replace = false,
      suppressBasePath = false,
      suppressSuccessLinkDelay = false,
      suppressInheritIncludeDeleted = false,
      successLinkDelayTime = 2000,
    } = currentAction;

    const url = resolveButtonUrl(urlPattern);
    const [href] = resolveHref({
      href: hrefPattern,
      hrefSelector,
      hrefIsSiteBased,
      suppressInheritIncludeDeleted,
      store,
      currentData,
      params,
      response,
    });

    if (url) {
      console.warn(`'url' is deprecated, use 'href' instead`);
    }

    const doNavigate = () => {
      // logic same as LinkWrapper
      let to = href || url;
      const isLink = !!to;
      const isOuterLink =
        isLink && (suppressBasePath || /^http/i.test(href) || href.startsWith('mailto:'));

      if (target === '_blank' || isOuterLink) {
        if (!isOuterLink && !suppressBasePath) {
          to = joinPaths([window.ICP_PUBLIC_PATH, to]);
        }
        if (target === '_blank') {
          window.open(to);
        } else {
          window.location.href = to;
        }
      } else if (isLink) {
        navigate(to, { replace });
      }

      // Link 的前置 action（例如）可能有 loading，而 link 有可能新标签页打开。
    };

    // If it has response, means is in successAction
    if (response && !suppressSuccessLinkDelay) {
      setTimeout(() => {
        doNavigate();
      }, successLinkDelayTime);
    } else {
      doNavigate();
    }
  };

  const handleGlobalMethodAction = () => {
    const { globalMethod } = currentAction;
    const values = formApi.getDataWithPreprocessor();
    window[globalMethod]?.(values, response);
  };

  const handleFormMethodAction = () => {
    const { methodName } = currentAction;
    const func = formApi.getMethods()[methodName];

    if (typeof func !== 'function') {
      const msg = `Error: Method ${methodName} not found in formApi`;
      message.error(msg);
      throw new Error(msg);
    }

    // 支持在 formMethod 里面返回 promise，等待 promise resolve 后再执行 successAction
    return Promise.resolve(func({ response, buttonApi, currentData }));
  };

  const handleLogoutAction = () => {
    restApi.auth.logout({ reason: 'logout button clicked' });
  };

  const handleConfirmAction = () => {
    return new Promise((resolve) => {
      modal.confirm({
        title: currentAction.title || t('confirm', { ns: 'icp-common' }),
        content: resoleConfigVar(currentAction.content),
        onOk: () => {
          resolve();
        },
      });
    });
  };

  const handleUpdateTableRowAction = () => {
    const { updateType, rowData } = currentAction;
    const rowDataResolved = resolveNestedValue({
      obj: rowData,
      currentData,
      formData: formApi.getData(),
      context,
      params,
      response,
    });
    if (tableApi?.addRow) {
      if (updateType === 'add') {
        return tableApi.addRow(rowDataResolved);
      }
      if (updateType === 'update') {
        return tableApi.updateRow(rowDataResolved);
      }
      if (updateType === 'remove') {
        return tableApi.removeRow(rowDataResolved);
      }
      return Promise.reject(new Error(`updateType not supported: ${updateType}`));
    }
    return Promise.reject(new Error('This Button not in EditTable context'));
  };

  const func = {
    submit: handleSubmitAction,
    save: handleSaveAction,
    cancel: handleCancelAction,
    refresh: handleRefreshAction,
    refreshPage: handleRefreshPageAction,
    refreshTable: handleRefreshTableAction,
    dialog: handleDialogAction,
    request: handleRequestAction,
    setFormData: handleSetFormDataAction,
    download: handleDownloadAction,
    upload: handleUploadAction,
    link: handleLinkAction,
    globalMethod: handleGlobalMethodAction,
    formMethod: handleFormMethodAction,
    logout: handleLogoutAction,
    confirm: handleConfirmAction,
    updateTableRow: handleUpdateTableRowAction,
  }[currentAction.type];

  if (!func) {
    console.error('Unrecognized button action type:', currentAction.type);
    return null;
  }

  const res = await func();

  if (currentAction.successAction && needDoSuccessAction(currentAction.type)) {
    return executeAction(currentAction.successAction, res, options);
  }

  return null;
}
