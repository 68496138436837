function getFunctionComponentName(type) {
  return type.displayName || type.name || '';
}

function getWrappedName(outerType, innerType) {
  return outerType.displayName || innerType.displayName || innerType.name || '';
}

// https://github.com/facebook/react/blob/main/packages/shared/getComponentNameFromType.js
export default function getComponentDisplayName(type) {
  if (typeof type === 'function') {
    return getFunctionComponentName(type);
  }

  if (typeof type === 'object') {
    switch (type.$$typeof) {
      case Symbol.for('react.forward_ref'):
        return getWrappedName(type, type.render);
      case Symbol.for('react.memo'):
        return type.displayName || getComponentDisplayName(type.type);
      default:
        return undefined;
    }
  }

  return undefined;
}
