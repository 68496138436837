import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import listeners from './features/listeners';
import agentSlice from './features/agentSlice';

const reducer = {
  [agentSlice.name]: agentSlice.reducer,
};

export default function makeStore() {
  const listenerMiddleware = createListenerMiddleware();

  listeners.forEach((listener) => listenerMiddleware.startListening(listener));

  const store = configureStore({
    reducer,
    // preloadedState: {},
    devTools: process.env.NODE_ENV !== 'production' ? { name: `Agent` } : false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  });

  const clearListeners = () => {
    listenerMiddleware.clearListeners();
  };

  return [store, clearListeners];
}
