import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseJSON } from '@icp/utils';
import { AIAgentFloatButton, AIAgent } from '@icp/components';
import markdownComponents from './markdownComponents';
import { handleSave } from './markdownComponents/utils';
import { useCurrentPageForm } from './CurrentPageFormStoreProvider';

const ACTION_PROMPTS = {
  OPEN_FORM: '// action:open_form',
};

function GeneralAIAgent({ userProfile }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const aiAgentRef = useRef();
  const aiAgentDelegates = useRef({});
  const currentPageForm = useCurrentPageForm();

  useEffect(() => {
    if (!currentPageForm) return () => {};

    const listener = () => {
      const {
        context: { pbcToken, formEntityToken },
        formData: { values: formEntityData },
      } = currentPageForm.store.getState();

      const newUserContext =
        pbcToken && formEntityToken
          ? {
              pbcToken,
              formEntityToken,
              formEntityData,
            }
          : null;
      aiAgentDelegates.current.getUserContext = () => newUserContext;
      sendUserContextMessage();
    };

    currentPageForm.formApi.on('ready', listener);
    if (currentPageForm.formApi.stage === 'ready') {
      listener();
    }
    return () => {
      currentPageForm.formApi.off('ready', listener);
    };
  }, [currentPageForm]);

  const onCustomSSEMessage = (message) => {
    if (/^FORM_AUTOFILL$/i.test(message.event)) {
      const data = parseJSON(message.data);
      if (data) {
        currentPageForm?.formApi?.setData(data);
      }
    }
  };

  const promptAnswerActions = (msgContent) =>
    [
      pathname === '/personal-dashboard' &&
        /```(icp-table|icp-chart)/.test(msgContent || '') && {
          label: t('save', { ns: 'icp-common' }),
          handlerKey: 'onSave',
        },
    ].filter(Boolean);

  const renderPromptText = (text) => {
    if (text.startsWith(ACTION_PROMPTS.OPEN_FORM)) {
      const userContext = JSON.parse(text.replace(ACTION_PROMPTS.OPEN_FORM, ''));
      return `User is opening form: ${userContext.formEntityToken}`;
    }
    return text;
  };

  const sendUserContextMessage = () => {
    const userContext = aiAgentDelegates.current?.getUserContext?.();
    if (!userContext?.pbcToken || !userContext?.formEntityToken) return;
    const message = `${ACTION_PROMPTS.OPEN_FORM}\n${JSON.stringify(userContext)}`;
    aiAgentRef.current?.sendMessage(message, [], userContext);
  };

  return (
    <AIAgentFloatButton>
      <AIAgent
        ref={aiAgentRef}
        userProfile={userProfile}
        uiConfiguration={{
          newChatSuppressConfirm: true,
          headerTabs: ['session'],
          inputToolbar: {
            hideModelPicker: true,
          },
        }}
        chatConfiguration={{ useAgents: true }}
        markdownComponents={markdownComponents}
        eventHandlers={{
          onSave: (msg, action, options) => handleSave(msg, currentPageForm?.formApi, options),
          onCustomSSEMessage,
          renderPromptText,
        }}
        promptAnswerActions={promptAnswerActions}
        onAgentChanged={(agent) => {
          if (agent) {
            sendUserContextMessage();
          }
        }}
      />
    </AIAgentFloatButton>
  );
}

GeneralAIAgent.propTypes = {
  userProfile: PropTypes.shape({}),
};

export default GeneralAIAgent;
