import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEventCallback } from '@icp/hooks';
import { HTMLElementType } from '@icp/utils';
import IconRenderer from './IconRenderer';

function IconSection(props) {
  const { currentTab, currentIcon, section, searchText, scrollDiv, onChange, onContextMenu } =
    props;

  const { key: themeKey, theme, category, list } = section;

  const filtered = useMemo(() => {
    if (!searchText || !Array.isArray(list)) {
      return list;
    }

    if (currentTab === 'emoji') {
      return list.filter((item) => {
        return item.emoji === searchText || item.shortName.toLowerCase().includes(searchText);
      });
    }

    return list.filter((item) => {
      const str = typeof item === 'string' ? item : `${item.name} ${item.search}`;
      return str.toLowerCase().includes(searchText);
    });
  }, [currentTab, list, searchText]);

  const [viewPoint, setViewPoint] = useState({
    width: scrollDiv.clientWidth,
    height: scrollDiv.clientHeight,
  });
  const [displayRowIndex, setDisplayIndex] = useState({ start: 0, end: 0 });

  const rootDiv = useRef(null);
  const titleDiv = useRef(null);
  const iconsDiv = useRef(null);

  const iconSize = 32;
  const rowHeight = iconSize + 8;
  const columnCount = Math.floor((viewPoint.width - 8 * 2 + 8) / (iconSize + 8)); //  4px icon gap, 8px section left/right margin
  const totalRowCount = Math.ceil(filtered.length / columnCount);
  const displayRowCount = Math.floor((viewPoint.height + 8) / rowHeight); // 最小 4px gap，忽略 titleDiv 的高度，多算一点也无所谓
  const iconsDivHeight = Math.max(0, totalRowCount * (iconSize + 8) - 8 + 8); //  4px icon gap, 8px section bottom margin

  const compute = useEventCallback(() => {
    const topOffset =
      iconsDiv.current.getBoundingClientRect().top - scrollDiv.getBoundingClientRect().top;
    const topOffsetRowCount = Math.ceil(topOffset / rowHeight);

    // 渲染的 icons 个数为当前视口可见的 icon 基础上上下各多加 3 行。
    const startRowIndex = Math.max(0, 0 - topOffsetRowCount - 3);
    const endRowIndex = Math.min(displayRowCount - topOffsetRowCount + 3, totalRowCount);
    if (startRowIndex < totalRowCount && endRowIndex > 0) {
      if (startRowIndex !== displayRowIndex.start || endRowIndex !== displayRowIndex.end) {
        setDisplayIndex({ start: startRowIndex, end: endRowIndex });
      }
    } else {
      setDisplayIndex({ start: 0, end: 0 });
    }
  });

  useEffect(() => {
    compute();
  }, [viewPoint, columnCount, compute, filtered]);

  useEffect(() => {
    const computeRowColumnCount = () => {
      setViewPoint({ width: scrollDiv.clientWidth, height: scrollDiv.clientHeight });
    };

    const resizeObserver = new ResizeObserver(() => {
      computeRowColumnCount();
    });

    resizeObserver.observe(rootDiv.current.parentNode);

    return () => {
      resizeObserver.disconnect();
    };
  }, [scrollDiv]);

  useEffect(() => {
    scrollDiv.addEventListener('scroll', compute);

    return () => {
      scrollDiv.removeEventListener('scroll', compute);
    };
  }, [compute, scrollDiv]);

  const slice = filtered.slice(
    displayRowIndex.start * columnCount,
    displayRowIndex.end * columnCount,
  );

  return (
    <div className="icp-icon-picker-list-section" ref={rootDiv}>
      <div className="icp-icon-section-name" ref={titleDiv}>
        {slice.length ? theme || category : null}
      </div>
      <div
        style={{ height: iconsDivHeight, paddingTop: displayRowIndex.start * rowHeight }}
        ref={iconsDiv}
      >
        <div className="icp-icon-section-icons" style={{}}>
          {slice.map((item) => {
            const icon = item?.emoji || item?.name || item;
            const title = item?.shortName || icon;
            return (
              <button
                key={icon}
                className={clsx('icp-icon-picker-list-icon icp-clickable', {
                  selected: icon === currentIcon,
                })}
                title={title}
                onClick={() => onChange(icon)}
                onContextMenu={(event) => onContextMenu(event)}
              >
                <IconRenderer name={icon} themeKey={themeKey} />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}

IconSection.propTypes = {
  currentTab: PropTypes.string,
  currentIcon: PropTypes.string,
  section: PropTypes.shape({
    key: PropTypes.string,
    theme: PropTypes.string,
    category: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
  }),
  searchText: PropTypes.string,
  scrollDiv: HTMLElementType,
  onChange: PropTypes.func,
  onContextMenu: PropTypes.func,
};

export default IconSection;
