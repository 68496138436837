export const makeAreaOption = () => {
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    legend: {
      type: 'scroll',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [],
  };
};

export const makeAreaSeries = () => {
  return {
    type: 'line',
    areaStyle: {},
    stack: 'stack',
  };
};
