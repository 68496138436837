import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
// TODO optional import
import { initReactI18next } from 'react-i18next';
import TimeAgo from 'javascript-time-ago';
import { loadAllPolyfills } from './loadPolyfill';

const instance = i18next.createInstance();

export const LNG_STORAGE_KEY = 'icp_i18n_lng';

// 清除缓存中不被允许的语言
function cleanDisallowedLng(allowedLanguages) {
  const lng = localStorage.getItem(LNG_STORAGE_KEY);
  if (!allowedLanguages?.includes(lng)) {
    localStorage.removeItem(LNG_STORAGE_KEY);
  }
}

export async function initBrowserI18n(i18nConfig, initialResources) {
  const isProd = process.env.NODE_ENV === 'production';

  const allowedLanguages = i18nConfig?.allowedLanguages || [];
  const fallbackLng = i18nConfig?.fallbackLng || 'zh-CN';
  const debug = i18nConfig?.debug ?? false;
  const cacheExpirationTime =
    i18nConfig?.cacheExpirationTime ?? (!isProd ? 3 * 1000 : 60 * 60 * 1000); // 1 hour

  const extraLoadPathByNamespace = i18nConfig?.extraLoadPathByNamespace || {};

  cleanDisallowedLng(allowedLanguages);

  const languageDetector = new LanguageDetector();
  languageDetector.addDetector({
    // same as built-in 'navigator' detector, plus filtering by allowedLanguages
    name: 'navigatorFiltered',
    lookup(/* options */) {
      const navLngList = [...Array.from(navigator.languages || []), navigator.language]
        .filter(Boolean)
        .map((navLng) => navLng.split('-')[0]);
      return allowedLanguages
        .filter((aLng) => navLngList.includes(aLng.split('-')[0]))
        .sort((a, b) => {
          const idxA = navLngList.indexOf(a.split('-')[0]);
          const idxB = navLngList.indexOf(b.split('-')[0]);
          if (idxA < 0 && idxB < 0) return 0;
          if (idxA < 0) return 1;
          if (idxB < 0) return -1;
          return idxA - idxB;
        })[0];
    },
  });

  await instance
    .use(languageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      debug,

      resources: {},
      ns: [
        'default',
        'icp-common',
        'icp-components',
        'icp-app',
        'icp-vendor-aggrid',
        'icp-vendor-echarts',
        // 预加载外部ns，因为难以预知外部ns使用的地方
        ...Object.keys(extraLoadPathByNamespace),
      ],
      defaultNS: 'default',
      fallbackLng,
      partialBundledLanguages: true,

      detection: {
        order: ['querystring', 'localStorage', 'navigatorFiltered'],
        lookupQuerystring: 'lng',
        lookupLocalStorage: LNG_STORAGE_KEY,
        caches: ['localStorage'],
        excludeCacheFor: ['cimode'],
      },

      interpolation: {
        escapeValue: false,
      },

      load: 'currentOnly',
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            prefix: 'icp_i18n_res_',
            expirationTime: cacheExpirationTime,
            defaultVersion: process.env.BUILD_NUMBER || 'DEV',
          },
          {
            loadPath: (lng, ns) =>
              extraLoadPathByNamespace[ns] ||
              `${window.ICP_PUBLIC_PATH || '/'}locales/{{lng}}/{{ns}}.json`,
            queryStringParams: { v: process.env.BUILD_NUMBER || 'DEV' },
          },
        ],
      },
    });

  initialResources.forEach(({ locale, ns, resource }) => {
    instance.addResourceBundle(locale, ns, resource, true);
  });

  await loadAllPolyfills([instance.language]);

  return instance;
}

export function getLanguage() {
  return instance.language;
}

export function changeLanguage(lng) {
  return instance.changeLanguage(lng);
}

// 不会因切换语言触发重渲染，不会等待语言包加载，确保需要的语言包已加载完毕后再调用
export function getTFunc(ns) {
  return instance.getFixedT(null, ns);
}

// 同i18n.getResourceBundle, 优先取当前语言, 其次取fallbackLng
export function getResourceBundle(ns) {
  const lngList = [instance.language, ...(instance.store?.options?.fallbackLng || [])];

  for (const lng of lngList) {
    const exist = instance.hasResourceBundle(lng, ns);
    if (exist) {
      return instance.getResourceBundle(lng, ns);
    }
  }

  return undefined;
}

export function formatNumber(value, options) {
  if (!value && value !== 0) return value;
  const language = getLanguage();
  try {
    return Number(value).toLocaleString(language, options);
  } catch (err) {
    console.error(err);
    return Number(value).toLocaleString(language);
  }
}

export function formatDateTime(value, options) {
  if (!value) return value;
  const language = getLanguage();
  try {
    return value.toLocaleString(language, options);
  } catch (err) {
    console.error(err);
    return value.toLocaleString(language);
  }
}

export function formatDate(value, options) {
  if (!value) return value;
  const language = getLanguage();
  try {
    return value.toLocaleDateString(language, options);
  } catch (err) {
    console.error(err);
    return value.toLocaleDateString(language);
  }
}

export function formatTime(value, options) {
  if (!value) return value;
  const language = getLanguage();
  try {
    return value.toLocaleTimeString(language, options);
  } catch (err) {
    console.error(err);
    return value.toLocaleTimeString(language);
  }
}

export function formatTimeAgo(value) {
  const timeAgo = new TimeAgo(instance.language);
  return timeAgo.format(value);
}

export function localCompare(a, b) {
  if (!a || !b) return 0;
  try {
    return a.localeCompare(b, getLanguage());
  } catch {
    return a.localeCompare(b);
  }
}
