import { translateEntireObj } from '@icp/i18n';
import { dataSourceToUrlGroup } from '@icp/form-renderer-core';
import { mappingToOrigin, originToMapping } from '../SelectElement/utils';

export function toComponentValue(value, mapping, useOriginValue) {
  if (!value) {
    return value;
  }

  if (!useOriginValue) {
    translateEntireObj(value);
    return value;
  }

  return value.map((item) => originToMapping(item, mapping));
}

export function toInterfaceValue(value, mapping, useOriginValue) {
  if (!value || !useOriginValue) {
    return value;
  }

  return value.map((item) => mappingToOrigin(item, mapping));
}

// TODO, 所有 table select echart 等组件都改成统一使用 datasource。componentProps.dataUrl 作为老配置兼容
// 兼容老代码 listIdsUrl 和 dataUrl 可以从 componentProps 里穿。以后 designer 里选出来都会在 datasource
// 对象里，文档里也推荐手写 listIdsUrl 和 listByIdsUrl 写到 datasource 里
export function getACLUrl(context, mapping, dataSource, idListUrlProp, dataUrlPropResolved) {
  const dataSourceUrlGroup = dataSourceToUrlGroup(dataSource, context);

  let idListUrl = idListUrlProp;
  let dataUrl = dataUrlPropResolved;

  if (dataSource) {
    // form entity 只支持 list-by-ids 接口，当 mapping.value 非 id 的时候没有 list by mapping.value 的接口，只能使用一个 dataUrl
    if (mapping.value === 'id') {
      idListUrl = dataSourceUrlGroup.listIdsUrl;
      dataUrl = dataSourceUrlGroup.listByIdsUrl;
    } else {
      dataUrl = dataSourceUrlGroup.listUrl;
    }
  }

  return { idListUrl, dataUrl };
}

export function legacyColumnDefs(columnDefs, columns) {
  return (
    columnDefs ||
    columns?.map((col) => {
      const { key, title } = col;
      return {
        colId: key,
        field: key,
        headerName: title,
      };
    })
  );
}
