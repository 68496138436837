import { forEachInputFieldInSchema } from '@icp/form-schema';
import { removeUndefined } from '@icp/utils';

export function mergeSchemaToProps(props) {
  // 如果有 children 表示是 jsx 写代码调用 FormRenderer，schema 会无效。
  const schema = props.children ? null : props.schema;
  const schemaForm = schema ? schema.form : {};

  // props 和 schema.form 里共有的属性，schema.form 比 props 优先级高
  return {
    // vue 的 props 定义会把所有定义的属性明确赋值为 undefined，如果放在 schemaForm 后会误覆盖 schema.form 里的。
    ...removeUndefined({ ...props }),
    ...schemaForm,
  };
}

// mutation on param schemaAndData
export function hideNotAllowedFieldsInSchema(schema, notAllowedFields) {
  if (!schema || !(Array.isArray(notAllowedFields) && notAllowedFields.length)) {
    return schema;
  }

  // TODO, immutable
  forEachInputFieldInSchema(schema, (field) => {
    if (notAllowedFields.includes(field.id)) {
      field.hidden = true;
    }
  });

  return schema;
}

export function helpersIsEmpty(helpers) {
  return !helpers || (Array.isArray(helpers) && !helpers.length);
}
