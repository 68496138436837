export const makePieOption = () => {
  return {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      type: 'scroll',
    },
    series: [],
  };
};

export const makePieSeries = () => {
  return {
    type: 'pie',
    radius: '50%',
  };
};
