import { useEffect, useState } from 'react';
import { EvalWorker } from '@icp/utils';

export default function useDataTransformed(data, transformExpression, context) {
  const [dataTransformed, setDataTransformed] = useState(data);
  const [transforming, setTransforming] = useState(!!transformExpression);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    if (transformExpression) {
      EvalWorker.shared()
        .execEval(data, transformExpression, {
          signal,
          params: {
            context,
          },
        })
        .then(setDataTransformed)
        .finally(() => setTransforming(false));
    }

    return () => {
      controller.abort();
    };
  }, [data, transformExpression, context]);

  // 没有 transformExpression 吐出原始 data，因为 data 可能多次发生改变
  return [transforming, !transformExpression ? data : dataTransformed];
}
