import { getLanguage } from '@icp/i18n';

export const JS_CODE_SEPARATOR_START = `// ---------- AI Generation start ----------`;
export const JS_CODE_SEPARATOR_END = `// ---------- AI Generation end ----------`;

const JS_CODE_CONTEXT_PROMPT = `
Leverage the following global api if necessary:
  - formApi: FormApi
  - messageApi: MessageApi

## Definition
\`\`\`typescript
type EventName = 'ready' | 'fieldValueChange';

interface CallbackFunctionMap {
  /**
   * The ready event is triggered when all components have completed initialization, after then other methods of formApi can be called safely.
   */
  ready: () => void;
  /**
   * Callback function to be called when any form field value changed. The caller has to filter the fields that need to be monitored to avoid annoying.
   * @param keyPath - The path to the form field whose value has changed.
   * @param value - The new value of the form field.
   */
  fieldValueChange: (keyPath: string, value: any) => void;
}

// provides methods for interacting with a form
interface FormApi {
  /**
   * Registers an event listener for a specific event.
   * @param event - The name of the event to listen for.
   * @param callback - The function to call when the event occurs. The type of the function depends on the event.
   */
  on<T extends EventName>(event: T, callback: CallbackFunctionMap[T]): void;
  /**
   * Retrieves the value of a form field specified by the key path.
   * Don't call it before form is ready.
   * @param keyPath - The path to the form field whose value is to be retrieved.
   * @returns The value of the form field.
   */
  getValue(keyPath: string): any;
  /**
   * Sets the value of a form field specified by the key path.
   * Don't call it before form is ready.
   * @param keyPath - The path to the form field whose value is to be set.
   * @param value - The value to set for the form field.
   */
  setValue(keyPath: string, value: any): void;
}

// provides methods for displaying messages to the user
interface MessageApi {
  success(message: string): void;
  warning(message: string): void;
  error(message: string): void;
}
\`\`\`
`;

export const formatPromptForJSCode = (userPrompt, knownFields) => `
## Fields in the form
\`\`\`json
${JSON.stringify(knownFields, null, 2)}
\`\`\`

The 'DATE' type field format is ISO-8601.
Make type conversion before using DATE.
Any value can be null, check it before using.

${JS_CODE_CONTEXT_PROMPT}


Any Text displaying to the user should be translated into ${getLanguage()}.


Generate code for:
${userPrompt}
`;
