import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import RecursionRenderer from '../RecursionRenderer';
import { ConditionalPropertyPropType } from '../propTypes';
import { useClassName, useHref } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';

const LinkWrapper = forwardRef(function LinkWrapper(props, ref) {
  const {
    children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const {
    style: wrapperStyle,
    href: hrefPattern,
    hrefSelector,
    hrefIsSiteBased = false,
    target,
    replace,
    suppressBasePath = false,
    suppressInheritIncludeDeleted = false,
    suppressLinkColor = true,
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const href = useHref({
    href: hrefPattern,
    hrefSelector,
    hrefIsSiteBased,
    suppressInheritIncludeDeleted,
  });

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const isLink = !!href;
  const isOuterLink =
    isLink && (suppressBasePath || /^http/i.test(href) || href.startsWith('mailto:'));

  const Comp = (!isLink && 'span') || (isOuterLink && 'a') || Link;
  const hrefProp = (!isLink && {}) || (isOuterLink && { href }) || { to: href, replace };

  const noChildren = !children && !fields?.length;

  return (
    <ElementDecorator keyPath={keyPath}>
      <Comp
        className={clsx(
          'link-wrapper form-wrapper',
          {
            'suppress-link-color': suppressLinkColor,
            'in-design': isInDesign,
            'no-children': noChildren,
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...wrapperStyle }}
        target={target}
        {...hrefProp}
        onClick={(event) => {
          if (isInDesign) {
            event.preventDefault();
          }
        }}
        ref={nodeRef}
      >
        {children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />}
      </Comp>
    </ElementDecorator>
  );
});

LinkWrapper.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  // id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * Root 元素的 className
     */
    className: PropTypes.string,
    /**
     * 链接地址
     */
    href: ConditionalPropertyPropType(PropTypes.string),
    /**
     * @deprecated
     */
    hrefSelector: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        matched: ConditionalPropertyPropType(PropTypes.bool),
      }),
    ),
    /**
     * 如果值为 `false` 并且此链接位于 pbc 下，href 将自动拼接上 pbc 的 `token` 作为 `basename`，移动端将自动拼接上 `mobile` 的前缀
     */
    hrefIsSiteBased: PropTypes.bool,
    /**
     * 是否直接链接不通过 react router 的 base path
     */
    suppressBasePath: PropTypes.bool,
    /**
     * 是否自动继承当前页面的 `include_deleted` 属性，此属性一般在当前页面 url 的 search params 里
     */
    suppressInheritIncludeDeleted: PropTypes.bool,
    /**
     * html a 标签的 target 属性
     */
    target: PropTypes.string,
    /**
     * react-router Link 的 replace 属性
     */
    replace: PropTypes.bool,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
LinkWrapper.displayName = 'Link';

export default withFieldWrapper(LinkWrapper);
