import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AG_FILTER_TYPES_DATE,
  AG_FILTER_TYPES_NUMBER,
  AG_FILTER_TYPES_TEXT,
} from '../../constants';
import Icon from '../../../Icon';
import Popover from '../../../Popover';

function FilterItemCondition(props) {
  const { model, deletable, onChange, onDelete } = props;
  const { filterType, type } = model;

  const { t } = useTranslation(['icp-vendor-aggrid']);

  const [open, setOpen] = useState(false);

  const anchorEl = useRef(null);

  const conditions =
    (filterType === 'text' && AG_FILTER_TYPES_TEXT) ||
    (filterType === 'number' && AG_FILTER_TYPES_NUMBER) ||
    (filterType === 'date' && AG_FILTER_TYPES_DATE) ||
    (filterType === 'set' && [undefined]) ||
    [];

  return (
    <div className="icp-table-filter-item-condition icp-center">
      <button className="icp-action-button" onClick={() => setOpen(true)} ref={anchorEl}>
        {filterType === 'set' ? t('equals') : t(type)}
        <Icon name="oct:chevron-down" size={12} />
      </button>
      {deletable ? (
        <button className="icp-action-button" onClick={onDelete}>
          <Icon name="oct:trash" size={24} style={{ width: 14, height: 14 }} />
        </button>
      ) : null}
      <Popover
        className="icp-table-menu"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        {conditions.map((v, index) => {
          return (
            <div
              key={v || index}
              className={clsx('item icp-clickable', { selected: v === type })}
              onClick={() => {
                setOpen(false);
                onChange('type', v);
              }}
            >
              {filterType === 'set' ? t('equals') : t(v)}
            </div>
          );
        })}
      </Popover>
    </div>
  );
}

FilterItemCondition.propTypes = {
  model: PropTypes.shape({
    filterType: PropTypes.string,
    type: PropTypes.string,
  }),
  deletable: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FilterItemCondition;
