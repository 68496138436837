import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Steps, Step } from '@icp/components';
import { useElementDecorator } from '../FormRenderCtx';
import { useClassName, useDisplayValue, useConditionalPropertyForItemOfArray } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

function minusValueMapCausedByHiddenItems(items, valueMap) {
  if (typeof valueMap !== 'object' || valueMap === null || !Array.isArray(items)) {
    return valueMap;
  }

  const newValueMap = { ...valueMap };

  // delete hidden step map
  for (let stepNumber = 0; stepNumber < items.length; stepNumber++) {
    const item = items[stepNumber];
    if (!item.hidden) {
      continue;
    }

    for (const key of Object.keys(newValueMap)) {
      if (newValueMap[key] === stepNumber) {
        delete newValueMap[key];
      }
    }
  }

  // move step number map after hidden step
  for (let stepNumber = items.length - 2; stepNumber >= 0; stepNumber--) {
    const item = items[stepNumber];
    if (!item.hidden) {
      continue;
    }

    for (const key of Object.keys(newValueMap)) {
      if (newValueMap[key] > stepNumber) {
        newValueMap[key]--;
      }
    }
  }

  return newValueMap;
}

const StepsElement = forwardRef(function StepsElement(props, ref) {
  const { keyPath, id, valueField, className: classNameProp, style, componentProps = {} } = props;

  const {
    direction = 'horizontal',
    labelPlacement = 'vertical',
    status,
    current,
    stepItems,
    items: itemsProp,
    valueMap: valueMapProp,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const value = useDisplayValue(id, valueField);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const itemsMerged = itemsProp || stepItems || [];
  const items = useConditionalPropertyForItemOfArray(itemsMerged, 'hidden');

  // items 有 hidden 元素的话 valueMap 大于 hidden number 的 map 应该相应减 1
  const valueMap = minusValueMapCausedByHiddenItems(items, valueMapProp);

  const currentStep = valueMap?.[value] ?? current ?? value;

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Steps
        className={clsx('steps-element form-element', className, classNameComp)}
        direction={direction}
        labelPlacement={labelPlacement}
        current={typeof currentStep === 'number' ? currentStep : undefined}
        status={status}
        {...otherComponentProps}
        style={{ ...style, ...componentProps.style }}
        ref={nodeRef}
      >
        {items
          .filter((item) => !item.hidden)
          .map((step, index) => {
            const { icon, title, description, ...other } = step;
            return (
              <Step key={index} icon={icon} title={title} description={description} {...other} />
            );
          })}
      </Steps>
    </ElementDecorator>
  );
});

StepsElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 当前步骤的编号，步骤从 0 开始编号
     */
    current: PropTypes.number,
    /**
     * 当前步骤的状态
     */
    status: PropTypes.oneOf(['wait', 'process', 'finish', 'error']),
    /**
     * Step title 和 description 的位置
     */
    labelPlacement: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * @deprecated
     */
    stepItems: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Steps 的具体一个个步骤
     */
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        // node 留给写代码用
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        hidden: ConditionalPropertyPropType(PropTypes.bool),
      }),
    ),
    /**
     * 将 value 映射到 step 编号
     */
    valueMap: PropTypes.objectOf(PropTypes.number),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
StepsElement.displayName = 'Steps';

export default withFieldWrapper(StepsElement);
