import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import PasswordUI from './PasswordUI';

const PasswordElement = forwardRef(function PasswordElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    // title,
    // value,
    // disabled,
    // readonly,
    style,
    componentProps = {},
    // fieldTitleProps,
    // validation,
    // status,
    helpers,
    onChange,
    // onTouchChanged,
  } = props;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'password-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <PasswordUI
          {...props}
          componentProps={{
            ...componentProps,
            className: classNameComp,
          }}
          onChange={handleChange}
        />
      </div>
    </ElementDecorator>
  );
});

PasswordElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
PasswordElement.displayName = 'Password';

export default withFieldWrapper(PasswordElement);
