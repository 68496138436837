import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import ACLInputMaterial from './ACLInputMaterial';
import ACLInputAnt from './ACLInputAnt';

const ACLInputUI = forwardRef(function ButtonUI(props, ref) {
  const { componentLibrary, title, readonly, required, helperText, ...other } = props;

  if (componentLibrary === 'material-ui') {
    return (
      <ACLInputMaterial
        {...other}
        title={title}
        readonly={readonly}
        requierd={required}
        helperText={helperText}
        ref={ref}
      />
    );
  }

  return <ACLInputAnt {...other} ref={ref} />;
});

ACLInputUI.propTypes = {
  componentLibrary: PropTypes.string,
  title: PropTypes.string,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
};

export default ACLInputUI;
