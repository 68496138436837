import { getSearchParams, isMobile, removeUndefined } from '@icp/utils';
import { restApi } from '@icp/settings';
import { entityDataUrl, flowDataUrl } from './dataUrlFactory';

export function fetchFlowForm({
  pbcToken,
  flowToken,
  flowDefinitionId,
  flowInstanceId,
  threadId,
  formEntityDataId,
  forceLayoutEnv,
}) {
  const url = flowDataUrl.get({ pbcToken, flowToken, flowDefinitionId, flowInstanceId });
  const searchParams = getSearchParams();
  // 移除 undefined 防止外部属性误展开了一个 undefined 的属性覆盖了有值的 searchParams 属性
  // searchParams 放前面，可以让其被 null 覆盖，例如 Button open 的 PageRenderer 会传 null 清空 formEntityDataId
  const params = { ...searchParams, ...removeUndefined({ threadId, formEntityDataId }) };

  return restApi.get(url, { params }).then((res) => {
    const {
      pbcToken: formPbcToken,
      formEntityToken,
      formEntityLayoutToken,
      formEntityMobileLayoutToken,
      formLayoutId,
      formMobileLayoutId,
      ...otherRes
    } = res || {};

    let layoutId = isMobile() ? formMobileLayoutId : formLayoutId;
    let layoutToken = isMobile() ? formEntityMobileLayoutToken : formEntityLayoutToken;

    if (forceLayoutEnv === 'MOBILE') {
      layoutId = formMobileLayoutId;
      layoutToken = formEntityMobileLayoutToken;
    }
    if (forceLayoutEnv === 'PC') {
      layoutId = formLayoutId;
      layoutToken = formEntityLayoutToken;
    }

    return {
      // 当接口没有返回pbcToken时，约定该form与当前flow同属一个pbc
      ...otherRes,
      pbcToken: formPbcToken || pbcToken,
      formEntityToken,
      layoutToken,
      layoutId,
    };
  });
}

export function postFlowFormData({
  pbcToken,
  flowToken,
  formPbcToken,
  formEntityToken,
  layoutToken,
  flowDefinitionId,
  flowInstanceId,
  layoutId,
  threadId,
  saveOnly,
  data,
}) {
  const url = flowDataUrl.update({
    pbcToken,
    flowToken,
    formEntityToken,
    layoutToken,
    flowDefinitionId,
    flowInstanceId,
    layoutId,
  });
  const searchParams = getSearchParams();
  const params = { ...searchParams, ...removeUndefined({ threadId, saveOnly, formPbcToken }) };

  return restApi.put(url, data, { params });
}

export function postApproveData({ pbcToken, formPbcToken, flowInstanceId, threadId, data }) {
  const url = flowDataUrl.approve({ pbcToken, flowInstanceId });
  const searchParams = getSearchParams();
  const params = { ...searchParams, ...removeUndefined({ formPbcToken, threadId }) };

  return restApi.put(url, data, { params });
}

export function fetchViewForm({ formEntityDataId, forceLayoutEnv }) {
  const searchParams = getSearchParams();
  const url = entityDataUrl.view({ formEntityDataId });

  return restApi.get(url, { params: searchParams }).then((res) => {
    const {
      formLayoutId,
      formMobileLayoutId,
      formEntityToken,
      formEntityLayoutToken,
      formEntityMobileLayoutToken,
      notAllowedFields,
      data,
    } = res || {};

    let layoutId = isMobile() ? formMobileLayoutId : formLayoutId;
    let layoutToken = isMobile() ? formEntityMobileLayoutToken : formEntityLayoutToken;

    if (forceLayoutEnv === 'MOBILE') {
      layoutId = formMobileLayoutId;
      layoutToken = formEntityMobileLayoutToken;
    }
    if (forceLayoutEnv === 'PC') {
      layoutId = formLayoutId;
      layoutToken = formEntityLayoutToken;
    }

    return {
      layoutId,
      formEntityToken,
      layoutToken,
      data,
      notAllowedFields,
    };
  });
}

export function createFormEntityData({
  pbcToken,
  formEntityToken,
  formEntityLayoutToken,
  formEntityId,
  formEntityLayoutId,
  data,
}) {
  const url = entityDataUrl.create({
    pbcToken,
    formEntityToken,
    formEntityLayoutToken,
    formEntityId,
    formEntityLayoutId,
  });
  return restApi.post(url, data);
}

export function updateFormEntityData({
  pbcToken,
  formEntityToken,
  formEntityLayoutToken,
  formEntityLayoutId,
  formEntityDataId,
  data,
}) {
  const url = entityDataUrl.edit({
    pbcToken,
    formEntityToken,
    formEntityLayoutToken,
    formEntityLayoutId,
    formEntityDataId,
  });
  return restApi.put(url, data);
}
