import { composeAllRoutesConfig, mapRoutes } from '@icp/app-core';
import { RequirePermission } from '@icp/form-renderer';
import PageRenderer from '@icp/page-renderer';
import { DataMining, ProcessMining } from '@icp/mining';
import Redirect from '../components/Redirect';
import TemplateRender, {
  HomePage,
  Page404,
  Login,
  BasicLogin,
  ResetPassword,
} from '../components/TemplateRender';
import PageConnector from '../components/PageConnector';
import FlowFlame from '../components/FlowFlame';
import Effects from '../components/Effects';

const components = {
  DataMining,
  Effects,
  PageConnector,
  PageRenderer,
  ProcessMining,
  FlowFlame,
  Redirect,
  RequirePermission,
  TemplateRender,
  HomePage,
  Page404,
  Login,
  BasicLogin,
  ResetPassword,
};

export default function makeReactRoutes({
  AuthWrapper,
  context,
  appConfig,
  appRoutes,
  otherRoutes,
  pbcList,
  onLocationChanged,
  appChildren,
}) {
  const routesConfig = composeAllRoutesConfig({
    context,
    appConfig,
    appRoutes,
    otherRoutes,
    pbcList,
  });

  const result = mapRoutes(routesConfig, (config) => {
    const {
      element: elementConfig,
      component,
      needAuth,
      props,
      accessPermissionPredicate,
      ...other // all other react-router route config is valid
    } = config;

    let element = elementConfig;

    if (!element && component) {
      const Comp = components[component];
      const compElement = <Comp {...props} />;

      if (component === 'TemplateRender' || needAuth) {
        // 目前的 design TemplateRender 一定需要登录才能进
        element = <AuthWrapper>{compElement}</AuthWrapper>;
      } else if (accessPermissionPredicate) {
        element = (
          <RequirePermission
            accessPermissionPredicate={accessPermissionPredicate}
            context={context}
          >
            {compElement}
          </RequirePermission>
        );
      } else {
        element = compElement;
      }
    }

    return {
      ...other,
      element,
    };
  });

  return [
    {
      element: <Effects appChildren={appChildren} onLocationChanged={onLocationChanged} />,
      children: result,
    },
  ];
}
