export const AG_FILTER_FILTER_TYPES = ['text', 'number', 'date', 'set'];
// https://www.ag-grid.com/react-data-grid/filter-provided-simple/#simple-filter-options
// https://www.ag-grid.com/react-data-grid/filter-conditions/#filter-options

export const AG_FILTER_TYPES_UNARY = [
  'blank',
  'notBlank',
  // 'empty', 暂时不支持 empty，好像无意义
];

const AG_FILTER_TYPES_GENERIC = [
  'equals',
  'notEqual',
  //
];

export const AG_FILTER_TYPES_TEXT = [
  ...AG_FILTER_TYPES_GENERIC,
  'contains',
  'notContains',
  'startsWith',
  'endsWith',
  ...AG_FILTER_TYPES_UNARY,
];

export const AG_FILTER_TYPES_NUMBER = [
  ...AG_FILTER_TYPES_GENERIC,
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'inRange',
  ...AG_FILTER_TYPES_UNARY,
];

export const AG_FILTER_TYPES_DATE = [
  ...AG_FILTER_TYPES_GENERIC,
  'lessThan',
  'greaterThan',
  'inRange',
  ...AG_FILTER_TYPES_UNARY,
];

export const AG_FILTER_TYPES = Array.from(
  new Set([...AG_FILTER_TYPES_TEXT, ...AG_FILTER_TYPES_NUMBER, AG_FILTER_TYPES_DATE]),
);

export const AG_SORT_TYPES = ['asc', 'desc'];

export const DEFAULT_COL_DEF = {
  menuTabs: ['generalMenuTab', 'columnsMenuTab'],
};
