export const makeScatterOption = () => {
  return {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      type: 'scroll',
    },
    xAxis: {},
    yAxis: {},
    series: [],
  };
};

export const makeScatterSeries = () => {
  return {
    type: 'scatter',
  };
};
