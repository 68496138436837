import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';

export async function loadIntlDateTimeFormat(locale) {
  const unsupportedLocale = shouldPolyfill(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import(
    /* webpackChunkName: "polyfill/intl-datetimeformat/polyfill-force" */
    '@formatjs/intl-datetimeformat/polyfill-force'
  );

  // Parallelize CLDR data loading
  const dataPolyfills = [
    // Full: contains ALL Timezone from IANA database
    // import('@formatjs/intl-datetimeformat/add-all-tz'),
    // Golden: contains popular set of timezones from IANA database
    import(
      /* webpackChunkName: "polyfill/intl-datetimeformat/add-golden-tz" */
      '@formatjs/intl-datetimeformat/add-golden-tz'
    ),
    import(
      /* webpackChunkName: "polyfill/intl-datetimeformat/locale-data" */
      `@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}.js`
    ),
  ];
  await Promise.all(dataPolyfills);
}
