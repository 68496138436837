import PropTypes from 'prop-types';
import { ButtonBase, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Icon } from '@icp/components';
import { useValueLanguageOptions } from '../hooks';

function ValueLanguageSelectMaterial(props) {
  const { value, onChange, readonly } = props;

  const languageOptions = useValueLanguageOptions();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    if (readonly) {
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue) => {
    onChange(newValue);
    handleClose();
  };

  return (
    <>
      <ButtonBase disableRipple={true} onClick={handleOpen}>
        <span style={{ marginRight: 4 }}>
          {languageOptions.find((op) => op.value === value)?.label ?? value}
        </span>
        {!readonly ? <Icon name="oct:chevron-down" size={16} /> : null}
        <hr
          style={{
            margin: '0px 8px 0px 4px',
            flexShrink: 0,
            borderWidth: '0px thin 0px 0px',
            borderStyle: 'solid',
            borderColor: 'currentcolor',
            opacity: 0.2,
            height: 'auto',
            alignSelf: 'stretch',
          }}
        />
      </ButtonBase>
      {!readonly ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {languageOptions.map((item) => {
            return (
              <MenuItem key={item.value} onClick={() => handleChange(item.value)}>
                {item.label}
              </MenuItem>
            );
          })}
        </Menu>
      ) : null}
    </>
  );
}

ValueLanguageSelectMaterial.propTypes = {
  value: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ValueLanguageSelectMaterial;
