/* eslint-disable import/no-dynamic-require */
import loadRequire from './loadRequire';

let promise;

export default function loadPrettier() {
  if (promise) {
    return promise;
  }
  promise = loadRequire().then((require) => {
    return new Promise((resolve) => {
      require([
        'prettier/standalone',
        'prettier/plugins/babel',
        'prettier/plugins/estree',
        //
      ], (prettier, ...plugins) => {
        resolve({ prettier, plugins });
      });
    });
  });
  return promise;
}
