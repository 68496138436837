import { useEffect, useMemo, useRef, useState } from 'react';
import { fetchRemoteContext } from '@icp/form-renderer-core';
import { restApi } from '@icp/settings';
import { useParams } from 'react-router-dom';

export default function useRemoteContext({ ElementDecorator, schema, context, loadRemoteContext }) {
  const params = useParams();

  const isInDesign = !!ElementDecorator;
  const loadRemoteCtx = isInDesign ? null : loadRemoteContext;

  const [loadingRemoteCtx, setLoadingRemoteCtx] = useState(!!loadRemoteCtx);
  const remoteCtxResult = useRef({});

  const mixedContext = useMemo(() => {
    return {
      ...context,
      schemaMode: !!schema,
      remoteCtx: remoteCtxResult.current,
    };
    // 当 loadingRemoteCtx 变成 false 的时候更新一下
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, schema, loadingRemoteCtx]);

  useEffect(() => {
    if (!loadRemoteCtx?.length) return () => {};

    const controller = new AbortController();

    fetchRemoteContext({
      requestConfig: loadRemoteCtx,
      resultHolder: remoteCtxResult.current,
      urlPatternResolverParams: {
        context: mixedContext,
        params,
      },
      restApi,
      signal: controller.signal,
    }).finally(() => {
      setLoadingRemoteCtx(false);
    });

    return () => {
      controller.abort();
    };
    // 只初始化加载一次不需要支持响应 context 变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loadingRemoteCtx, mixedContext };
}
