import { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useClassName, useConditionalPropertyForItemOfArray } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator } from '../FormRenderCtx';
import { LinkWrapper } from '../wrappers';
import { ConditionalPropertyPropType } from '../propTypes';

const NavTabsElement = forwardRef(function NavTabsElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, componentProps } = props;

  const ElementDecorator = useElementDecorator();

  const { activeKey, centered, items: itemsProp, showUnderLine = false } = componentProps || {};

  const className = useClassName(classNameProp);

  const items = useConditionalPropertyForItemOfArray(itemsProp, 'hidden');

  useImperativeHandle(
    ref,
    () => ({
      node: null,
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Tabs
        className={clsx(
          'nav-tabs-element form-element',
          { 'show-underline': showUnderLine },
          className,
        )}
        activeKey={activeKey}
        centered={centered}
        items={(items || [])
          .filter((item) => !item.hidden)
          .map((item) => {
            const { href, hrefIsSiteBased, suppressInheritIncludeDeleted } = item;
            return {
              key: item.key,
              label: (
                <LinkWrapper
                  componentProps={{
                    href,
                    hrefIsSiteBased,
                    suppressInheritIncludeDeleted,
                    replace: true,
                  }}
                >
                  {item.label}
                </LinkWrapper>
              ),
            };
          })}
        style={style}
      />
    </ElementDecorator>
  );
});

NavTabsElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    activeKey: PropTypes.string,
    centered: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        // icon: PropTypes.string, TODO
        href: ConditionalPropertyPropType(PropTypes.string),
        hrefIsSiteBased: PropTypes.bool,
        suppressInheritIncludeDeleted: PropTypes.bool,
        hidden: ConditionalPropertyPropType(PropTypes.bool),
      }),
    ),
    showUnderLine: PropTypes.bool,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
NavTabsElement.displayName = 'NavTabs';

export default withFieldWrapper(NavTabsElement);
