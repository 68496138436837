import './AIAgentIcon.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function AIAgentIcon({ size, variant, alive = true }) {
  return (
    <div
      className={clsx('ai-agent-icon', {
        alive,
        error: variant === 'error',
        thinking: variant === 'thinking',
      })}
      style={{
        '--size': size,
      }}
    />
  );
}

AIAgentIcon.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.oneOf(['error', 'thinking']),
  alive: PropTypes.bool,
};

export default AIAgentIcon;
