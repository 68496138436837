import { createSlice } from '@reduxjs/toolkit';
import { contextInitialState } from '../initialState';

export const selectUserPermissionMap = (state) => state.context.userPermissionMap;
export const selectIsAdminUser = (state) => state.context.userProfile?.isAdmin === 1;
export const selectSchemaMode = (state) => state.context.schemaMode;
export const selectContext = (state) => state.context;

const slice = createSlice({
  name: 'context',
  initialState: { ...contextInitialState },
  reducers: {
    setContext: (state, action) => {
      if (!action.payload) {
        return;
      }
      for (const key of Object.keys(action.payload)) {
        state[key] = action.payload[key];
      }
    },
    setContextDataSource: (state, action) => {
      const { alias, data } = action.payload;
      state.dataSource[alias] = data;
    },
  },
});

export const { setContext, setContextDataSource } = slice.actions;

export default slice.reducer;
