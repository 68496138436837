import { Children, cloneElement, isValidElement } from 'react';
import { forEachInputFieldInSchema, isInputField } from '@icp/form-schema';
import { ensureDisplayText, getComponentDisplayName } from '@icp/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { message } from '@icp/settings';
import { getTFunc } from '@icp/i18n';
import FormControl from './FormControl';
import ConditionalHidden from './ConditionalHidden';

export function forEachInputFieldInChildren(children, cb) {
  const dfs = (subChildren) => {
    if (!subChildren) {
      return;
    }
    Children.forEach(subChildren, (child) => {
      if (!isValidElement(child)) return;

      const component = getComponentDisplayName(child.type);

      if (isInputField({ component })) {
        cb(child, component);
      }

      dfs(child.props?.children);
    });
  };

  dfs(children);
}

export function extractDefaultData({ children, schema }) {
  const defaultValues = {};

  if (children) {
    forEachInputFieldInChildren(children, (child) => {
      const { id, defaultValue } = child.props;
      if (id !== null && id !== undefined && defaultValue !== null && defaultValue !== undefined) {
        defaultValues[child.props?.id] = child.props?.defaultValue;
      }
    });
  } else if (schema) {
    forEachInputFieldInSchema(schema, (field) => {
      const { id, defaultValue } = field;
      if (id !== null && id !== undefined && defaultValue !== null && defaultValue !== undefined) {
        defaultValues[field.id] = field.defaultValue;
      }
    });
  }

  return defaultValues;
}

export function extractValueExpressions({ children, schema }) {
  const valueExpressions = [];

  if (children) {
    forEachInputFieldInChildren(children, (child) => {
      const { id, valueExpression } = child.props;
      if (valueExpression) {
        valueExpressions.push({ id, valueExpression });
      }
    });
  } else if (schema) {
    forEachInputFieldInSchema(schema, (field) => {
      const { id, valueExpression } = field;
      if (valueExpression) {
        valueExpressions.push({ id, valueExpression });
      }
    });
  }

  return valueExpressions;
}

export function hideNotAllowedFieldsInChildren(children, notAllowedFields) {
  if (!children || !(Array.isArray(notAllowedFields) && notAllowedFields.length)) {
    return children;
  }

  const filterOut = (childArray) => {
    return Children.map(childArray, (child) => {
      // All input element should be wrapped with FormControl
      if (child.type === FormControl) {
        if (!isValidElement(child.props.children)) {
          return child;
        }

        const { id } = child.props.children.props;

        if (notAllowedFields.includes(id)) {
          return null;
        }

        return child;
      }

      if (!isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        const filtered = filterOut(child.props.children);
        // ConditionalHidden only support one element children
        return cloneElement(child, null, child.type === ConditionalHidden ? filtered[0] : filtered);
      }

      return child;
    });
  };

  return filterOut(children);
}

export function renderReadonlyText({ supportCopy, supportEmail, supportPhone }, value) {
  if (!value) return null;

  const t = getTFunc('icp-form-renderer');

  if (supportCopy)
    return (
      <CopyToClipboard
        text={value}
        onCopy={(_, result) => {
          if (result) {
            message.success(t('success.copy'));
          }
        }}
      >
        <span className="readonly-text">{ensureDisplayText(value)}</span>
      </CopyToClipboard>
    );

  if (supportEmail || supportPhone)
    return (
      <span className="readonly-text">
        <a href={`${supportEmail ? 'mailto' : 'tel'}:${value}`}>{value}</a>
      </span>
    );
  return <span className="readonly-text">{ensureDisplayText(value)}</span>;
}
