/* eslint-disable import/no-dynamic-require */
import loadRequire from './loadRequire';
import loadCss from './loadCSS';

let promise;

export default function loadDHTMLXGantt() {
  if (promise) {
    return promise;
  }
  promise = loadRequire().then((require) => {
    return new Promise((resolve) => {
      const cssUrl = `${process.env.VENDOR_PATHS.dhtmlxgantt}.css`;
      loadCss('dxhtmlgantt-css', cssUrl).then(() => {
        require(['dhtmlxgantt'], resolve);
      });
    });
  });
  return promise;
}
