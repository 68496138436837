import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, Dropdown, Space } from 'antd';
import { ChevronDownIcon } from '@primer/octicons-react';
import { Loading } from '@icp/components';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName, useDataSource } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import RecursionRenderer from '../RecursionRenderer';
import { CurrentDataProvider } from '../currentDataCtx';

// TODO, 应该叫 DropdownElement
const DropDownElement = forwardRef(function DropDownElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    componentProps = {},
    //
  } = props;

  const {
    content,
    itemField,
    ButtonProps,
    LoadingProps,
    MenuProps,
    dataFilters,
    dataUrl,
    dataResponseKeyPath,
    debounceTime,
    transformDataResponse,
    translateDataResponse,
    ...otherComponentProps
    //
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  // TODO, DropDpwn 并没有随着最新的 useDataSource 修改，不清楚用法
  const { loading, dataFetched } = useDataSource({
    skip: isInDesign || !!MenuProps?.items,
    dataUrl,
    dataFilters,
    dataResponseKeyPath,
    debounceTime,
    transformDataResponse,
    translateDataResponse,
  });

  const menuItems = useMemo(() => {
    const items = MenuProps?.items || dataFetched || [];
    if (!itemField) return items;
    const toReactNode = (item, index, kp) => {
      return {
        ...item,
        label: (
          <CurrentDataProvider value={item}>
            <RecursionRenderer keyPath={kp.concat(['fields', index])} fields={[itemField]} />
          </CurrentDataProvider>
        ),
        ...(item.children && {
          children: item.children.map((subItem, i) =>
            toReactNode(subItem, i, kp.concat(['fields', index])),
          ),
        }),
      };
    };
    return items.map((item, i) => toReactNode(item, i, keyPath));
  }, [MenuProps?.items, dataFetched, itemField, keyPath]);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Dropdown
        trigger={['click']}
        {...otherComponentProps}
        className={clsx('form-element', className, classNameComp)}
        menu={{ ...MenuProps, items: menuItems }}
        style={{ ...style, ...componentProps.style }}
        ref={ref}
      >
        <Button ref={nodeRef}>
          <Space>
            {content}
            {loading ? <Loading {...LoadingProps} /> : <ChevronDownIcon />}
          </Space>
        </Button>
      </Dropdown>
    </ElementDecorator>
  );
});

DropDownElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    treeData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
      }),
    ),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
DropDownElement.displayName = 'DropDown';

export default withFieldWrapper(DropDownElement);
