import { useEffect } from 'react';

export default function useInterval(props) {
  const { gridRef, interval, intervalTime } = props;

  useEffect(() => {
    let timer = null;

    if (interval) {
      timer = setInterval(
        () => gridRef.current.api.refreshServerSide({ purge: false }),
        intervalTime * 1000,
      );
    }

    return () => {
      clearInterval(timer);
    };
  }, [gridRef, interval, intervalTime]);
}
