import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName, useVariablePattern } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType } from '../../propTypes';
import NumberPickerUI from './NumberPickerUI';

const NumberPickerElement = forwardRef(function NumberPickerElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    componentProps = {},
    validation,
    helpers,
  } = props;

  const {
    min: minProp,
    max: maxProp,
    step,
    precision,
    formatOptions,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const min = useVariablePattern(minProp ?? validation?.minValue);
  const max = useVariablePattern(maxProp ?? validation?.maxValue);
  const currency = useVariablePattern(formatOptions?.currency);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'number-picker-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <NumberPickerUI
          {...props}
          componentProps={{
            ...otherComponentProps,
            className: classNameComp,
          }}
          min={min}
          max={max}
          precision={precision}
          formatOptions={{
            ...componentProps.formatOptions,
            currency,
          }}
        />
      </div>
    </ElementDecorator>
  );
});

NumberPickerElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 最小值
     */
    min: PropTypes.number,
    /**
     * 最大值
     */
    max: PropTypes.number,
    /**
     * 每次改变步数，可以为小数
     * @default 1
     */
    step: PropTypes.number,
    /**
     * 数值精度
     */
    precision: PropTypes.number,
    /**
     * Intl.NumberFormat的所有属性
     */
    formatOptions: PropTypes.shape({
      // Intl.NumberFormat的属性，支持冒号语法引用表单数据
      currency: PropTypes.string,
    }),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    /**
     * 是否必填
     * @default false
     */
    required: PropTypes.bool,
    /**
     * 最大值
     */
    minValue: PropTypes.number,
    /**
     * 最大值
     */
    maxValue: PropTypes.number,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
NumberPickerElement.displayName = 'NumberPicker';

export default withFieldWrapper(NumberPickerElement);
