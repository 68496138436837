import PropTypes from 'prop-types';

function Loading20(props) {
  const { size = 20 } = props;

  return (
    <svg
      className="icp-loading-20"
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="currentColor"
    >
      <rect x="92" y="10" width="16" height="50" rx="8" ry="8" fillOpacity={0.15} />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(30) translate(-100, -100)"
        fillOpacity={0.15}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(60) translate(-100, -100)"
        fillOpacity={0.15}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(90) translate(-100, -100)"
        fillOpacity={0.2}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(120) translate(-100, -100)"
        fillOpacity={0.2}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(150) translate(-100, -100)"
        fillOpacity={0.2}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(180) translate(-100, -100)"
        fillOpacity={0.3}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(210) translate(-100, -100)"
        fillOpacity={0.4}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(240) translate(-100, -100)"
        fillOpacity={0.5}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(270) translate(-100, -100)"
        fillOpacity={0.6}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(300) translate(-100, -100)"
        fillOpacity={0.7}
      />
      <rect
        x="92"
        y="10"
        width="16"
        height="50"
        rx="8"
        ry="8"
        transform="translate(100, 100) rotate(330) translate(-100, -100)"
        fillOpacity={0.8}
      />
    </svg>
  );
}

Loading20.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Loading20;
