// import { isAnyOf } from '@reduxjs/toolkit';
import { checkAuthState } from './authSlice';

const clearOneTimeAuthCode = {
  type: checkAuthState.toString(),
  effect: () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('code')) {
      searchParams.delete('code');
      window.location.search = searchParams.toString();
    }
  },
};

export default [
  clearOneTimeAuthCode,
  //
];
