import Axios, { isCancel } from 'axios';
import { message } from '@icp/settings';
import { getLanguage, translateEntireObj } from '@icp/i18n';
import Auth from './auth';

const axios = Axios.create({
  // timeout: 60 * 1000,
  // timeoutErrorMessage: '请求超时',
  // xsrfCookieName: 'csrftoken',
  // xsrfHeaderName: 'X-CSRFToken',
});

export const auth = new Auth({
  restClient: axios,
});

axios.interceptors.request.use((config) => {
  // Do something before request is sent
  return {
    ...config,
    headers: {
      ...config?.headers,
      common: {
        'Accept-Language': getLanguage(),
        ...config?.headers?.common,
        Authorization: `Bearer ${auth.getAccessToken()}`,
      },
    },
    params: {
      ...config?.params,
      timestamp: !config?.noTimestamp ? Date.now() : undefined,
    },
  };
});

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response.data;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.config?.skipResponseInterceptors) throw error;

    // 无response为Axios报错，例如超时
    if (!error.response) {
      // abort请求不提示错误信息
      if (isCancel(error)) return Promise.reject(error);
      message.error(error.message);
      return Promise.reject(error);
    }

    translateEntireObj(error.response.data);

    message.error(error.response.data?.message ?? error.message);
    if (error.response.data?.traceId) {
      console.error(error.response.data);
    }

    if (error.response.status === 401) {
      auth.logout({});
    }

    return Promise.reject(error.response.data ?? error);
  },
);

const restApi = {
  get: (url, config) => {
    return axios.get(url, config);
  },
  getStatic: (url, config) => {
    return axios.get(url, { ...config, noTimestamp: true });
  },
  delete: (url, config) => {
    return axios.delete(url, config);
  },
  post: (url, data, config) => {
    return axios.post(url, data, config);
  },
  put: (url, data, config) => {
    return axios.put(url, data, config);
  },
  get auth() {
    return auth;
  },
};

export default restApi;
