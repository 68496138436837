import './DemoChat.css';
import FormRenderer from '@icp/form-renderer';
import { useState } from 'react';
import { Loading } from '@icp/components';
import { restApi } from '@icp/settings';
import { parseJSON } from '@icp/utils';
// import { Helmet } from 'react-helmet';
import DemoChatSearchBox from './DemoChatSearchBox';

function DemoChat() {
  const [schema, setSchema] = useState(null);
  const [fetching, setFetching] = useState(false);

  const fetchSchema = (prompt) => {
    if (!prompt) return;

    setFetching(true);
    restApi.post('/aip/api/gpt/form-generator', { prompt }).then((data) => {
      const { code } = data;
      setFetching(false);
      setSchema(parseJSON(code));
    });
  };

  return (
    <div className="demo-chat">
      {/* <Helmet>
        <title>????</title>
      </Helmet> */}
      <DemoChatSearchBox onSearch={fetchSchema} />
      {fetching ? <Loading delayed={false} style={{ height: 256 }} /> : null}
      {!fetching && schema ? <FormRenderer className="demo-chat-form" schema={schema} /> : null}
    </div>
  );
}

export default DemoChat;
