import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@icp/components';
import { useElementDecorator } from '../FormRenderCtx';
import { useClassName, useDisplayValue } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';

const IconElement = forwardRef(function IconElement(props, ref) {
  const {
    keyPath,
    id,
    valueField,
    className: classNameProp,
    style = {},
    componentProps = {},
  } = props;

  const { name, size, color: colorProp, ...otherIconProps } = componentProps;

  const ElementDecorator = useElementDecorator();

  const value = useDisplayValue(id, valueField);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  // TODO, change to conditional property
  const color = typeof colorProp === 'object' ? colorProp[value] : colorProp || style.color;

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Icon
        {...otherIconProps}
        className={clsx('icon-element form-element', className, classNameComp)}
        name={value || name}
        size={size}
        style={{ ...style, ...componentProps.style, color }}
        ref={nodeRef}
      />
    </ElementDecorator>
  );
});

IconElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * Icon 的名字
     */
    name: PropTypes.string,
    /**
     * Icon 的尺寸
     */
    size: PropTypes.number,
    /**
     * Icon 的颜色
     */
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
IconElement.displayName = 'Icon';

export default withFieldWrapper(IconElement);
