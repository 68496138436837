export default [
  // 失败的action打印日志
  {
    predicate: (action) => {
      return action.type?.endsWith(`/rejected`);
    },
    effect: (action) => {
      if (action.error?.stack) {
        console.error(action.error.stack);
      } else {
        console.error(action.error);
      }
    },
  },
];
