import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import clsx from 'clsx';

function ACLDialogMaterial(props) {
  const { children, className, title, multiple, onOk, onClose, ...other } = props;

  const { t } = useTranslation(['icp-common']);

  return (
    <Dialog
      className={clsx('icp-acl-dialog', { multiple }, className)}
      open={true}
      fullWidth={true}
      maxWidth={multiple ? 'lg' : 'md'}
      PaperProps={{ sx: { height: 800 } }}
      {...other}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>{children}</DialogContent>
      <DialogActions sx={multiple ? { paddingTop: 0 } : undefined}>
        <Button variant="outlined" onClick={onClose}>
          {t('cancel', { ns: 'icp-common' })}
        </Button>
        <Button variant="contained" onClick={onOk}>
          {t('ok', { ns: 'icp-common' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ACLDialogMaterial.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
};

export default ACLDialogMaterial;
