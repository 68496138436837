import { parseJSON } from '@icp/utils';

export function toCompData(connector) {
  const { authConfigJson, ...other } = connector;
  return {
    ...other,
    ...parseJSON(authConfigJson),
  };
}

export function toApiData(formData) {
  const {
    pbcId,
    id,
    uuid,
    name,
    connectorTypeUuid,
    baseUrl,
    authType,
    headerPrefix,
    addTo,
    grantType,
    accessTokenURL,
    clientID,
    clientSecret,
    username,
    password,
    scope,
    clientAuthentication,
    key,
    value,
    testPath,
  } = formData;

  let authConfigJson;
  if (authType === 'OAUTH_2') {
    authConfigJson = {
      headerPrefix,
      addTo,
      grantType,
      accessTokenURL,
      clientID,
      clientSecret,
      username,
      password,
      scope,
      clientAuthentication,
    };
  } else if (authType === 'BASIC_AUTH') {
    authConfigJson = {
      username,
      password,
    };
  } else if (authType === 'API_KEY') {
    authConfigJson = {
      addTo,
      key,
      value,
    };
  }

  return {
    pbcId,
    id,
    uuid,
    name,
    connectorTypeUuid,
    baseUrl,
    authType,
    testPath,
    authConfigJson: JSON.stringify(authConfigJson),
  };
}
