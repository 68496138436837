import { restApi } from '@icp/settings';
import { parseJSON } from '@icp/utils';

// Promise返回格式 { locale, ns, resource }[]
export default function loadCustomI18nResources() {
  return Promise.all([
    restApi.get('/form/api/translation', { skipResponseInterceptors: true }).catch(() => []),
    restApi
      .get('/form/api/translation/standard', { skipResponseInterceptors: true })
      .catch(() => []),
  ])
    .then((aoa) => aoa.flat())
    .then((list) => {
      return list.flatMap(({ locale, translation }) => {
        const i18nResourceGroupedByNs = parseJSON(translation);
        if (!i18nResourceGroupedByNs) return [];
        return Object.entries(i18nResourceGroupedByNs)
          .filter(([, resource]) => resource != null)
          .map(([ns, resource]) => {
            return {
              locale,
              ns,
              resource,
            };
          });
      });
    });
}

// 为每个locale每个ns补上空resource，否则i18n instance会尝试发请求加载。
export function completeMissingResources(i18nResources, localesInI18nConfig) {
  const allNs = new Set(i18nResources.map((x) => x.ns));
  const allLocales = new Set([...i18nResources.map((x) => x.locale), ...localesInI18nConfig]);

  // Map<Locale, Set<Ns>>
  const existingLocaleNsMapSet = i18nResources.reduce((map, resource) => {
    const { locale, ns } = resource;
    if (!map.has(locale)) {
      map.set(locale, new Set());
    }
    map.get(locale).add(ns);
    return map;
  }, new Map());

  const missing = Array.from(allLocales).flatMap((locale) => {
    if (existingLocaleNsMapSet.has(locale)) {
      const nsSet = existingLocaleNsMapSet.get(locale);
      return Array.from(allNs)
        .filter((ns) => !nsSet.has(ns))
        .map((ns) => ({ locale, ns, resource: {} }));
    }
    return Array.from(allNs).map((ns) => ({ locale, ns, resource: {} }));
  });

  return [...i18nResources, ...missing];
}
