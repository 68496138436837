import { formatDate, formatNumber, getTFunc } from '@icp/i18n';
import dayjs from 'dayjs';
import { AG_FILTER_TYPES_UNARY } from '../../constants';
import { getSetFilterOptions } from './utils';

function getUnaryTypeText(model) {
  const { type } = model;
  const t = getTFunc('icp-vendor-aggrid');
  return t(type);
}

function getTextTypeText(model) {
  const { type, filter } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (!filter) {
    return '';
  }
  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{filter}</span>
    </>
  );
}

function getNumberTypeText(model) {
  const { type, filter, filterTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    if (!filter || !filterTo) {
      return '';
    }
    return (
      <span className="icp-filter-value">
        {formatNumber(filter)} → {formatNumber(filterTo)}
      </span>
    );
  }

  if (!filter) {
    return '';
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{formatNumber(filter)}</span>
    </>
  );
}

function getDateTypeText(model) {
  const { type, dateFrom, dateTo } = model;
  const t = getTFunc('icp-vendor-aggrid');

  if (type === 'inRange') {
    if (!dateFrom || !dateTo) {
      return '';
    }
    return (
      <span className="icp-filter-value">
        {formatDate(dayjs(dateFrom).toDate())} → {formatDate(dayjs(dateTo).toDate())}
      </span>
    );
  }

  if (!dateFrom) {
    return '';
  }

  return (
    <>
      <span className="icp-filter-type">{t(type)}</span>
      <span className="icp-filter-value">{formatDate(dayjs(dateFrom).toDate())}</span>
    </>
  );
}

function getSetTypeText(gridApi, model, column) {
  const { values } = model;

  if (!(Array.isArray(values) && values.length)) {
    return Promise.resolve('');
  }
  return getSetFilterOptions(gridApi, column.colDef).then((options) => {
    return (
      <div className="icp-filter-value">
        {values
          .map((v) => options.find((op) => op.value === v)?.label)
          .filter(Boolean)
          .join(', ')}
      </div>
    );
  });
}

export default function getFilterValueText(gridApi, model, column) {
  if (!model || !column) {
    return '';
  }

  if (AG_FILTER_TYPES_UNARY.includes(model.type)) {
    return getUnaryTypeText(model);
  }

  if (model.filterType === 'text') {
    return getTextTypeText(model);
  }

  if (model.filterType === 'number') {
    return getNumberTypeText(model);
  }

  if (model.filterType === 'date') {
    return getDateTypeText(model);
  }

  if (model.filterType === 'set') {
    return getSetTypeText(gridApi, model, column);
  }

  return '';
}
