import './TableToolbar.css';
import { Modal, Tooltip } from 'antd';
import { message } from '@icp/settings';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import TableSetting from './TableSetting';
import SearchBox from '../../SearchBox';
import ColumnSelect from './ColumnSelect';
import Icon from '../../Icon';
import FilterPanel from './FilterPanel';
import FavoriteView from './FavoriteView';
import Button from './Button';

const TableToolbar = forwardRef(function TableToolbar(props, ref) {
  const {
    children,
    isInDesign,
    componentLibrary,
    gridApi,
    columnDefs,
    className,
    title,
    suppressToolbarActions,
    suppressAddButton,
    suppressDeleteButton,
    suppressFuzzySearch,
    suppressFuzzySearchSpeech,
    suppressColumnSelect,
    suppressExcelExport,
    suppressToolbarRefresh,
    suppressTableSetting,
    suppressFullscreen,
    supportShowDeleted,
    suppressFilterPanel,
    suppressFavoriteView,
    addButtonHref,
    addButtonContent,
    fuzzySearchPlaceholder,
    fuzzySearchOpen,
    rowSelection,
    hasDeletable,
    onAddRow,
    onDeleteRow,
    onExportExcel,
    searchText,
    onSearch,
    onRefresh,
    fullscreen,
    onToggleFullscreen,
    tableState,
    onTableStateChange,
    pinnedFilter,
    onResetSetting,
    settingKey,
    onSwitchFavoriteView,
    ...other
  } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);
  const navigate = useNavigate();

  const [deleting, setDeleting] = useState(false);
  const searchRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openSearch: searchRef.current?.open,
  }));

  const [modal, contextHolder] = Modal.useModal();

  const handleAddRow = (event) => {
    if (addButtonHref) {
      navigate(addButtonHref);
    } else if (onAddRow) {
      onAddRow(event);
    }
  };

  const handleDeleteRow = () => {
    // TODO, handle serverSide, rowModelType serverSide 不能调用 getSelectedRows
    // eslint-disable-next-line react/prop-types
    const selectedRows = gridApi.getSelectedRows();
    const selectedIds = selectedRows.filter((row) => row.deletable !== false).map((row) => row.id);

    modal.confirm({
      title: t('table.toolbar-delete-title'),
      content: t('table.toolbar-delete-content'),
      onOk: () => {
        setDeleting(true);
        onDeleteRow(selectedIds)
          .then(() => {
            message.success(t('delete-success', { ns: 'icp-common' }));
          })
          .finally(() => setDeleting(false));
      },
    });
  };

  // const showFilterDirty = isGridReady ? tableState.filterModel.length > 0 : false;

  return (
    <div className={clsx('icp-table-toolbar', className)} {...other}>
      <div className="icp-table-toolbar-main">
        {/* {title ? <div className="icp-table-title">{title}</div> : null} */}
        {!suppressFilterPanel && gridApi ? (
          <FilterPanel
            gridApi={gridApi}
            pinnedFilter={pinnedFilter}
            filterModel={tableState.filterModel}
            onChange={(newFilterModel) => onTableStateChange({ filterModel: newFilterModel })}
          />
        ) : null}
        {!suppressToolbarActions ? (
          <div className="icp-table-actions">
            {children}
            {!suppressAddButton ? (
              <Button
                componentLibrary={componentLibrary}
                className="icp-table-toolbar-add-button"
                type="primary"
                icon={<Icon name="oct:plus-circle" size={16} />}
                onClick={handleAddRow}
              >
                {addButtonContent || t('add', { ns: 'icp-common' })}
              </Button>
            ) : null}
            {!suppressDeleteButton && rowSelection ? (
              <Tooltip title={t('delete', { ns: 'icp-common' })}>
                <Button
                  componentLibrary={componentLibrary}
                  className="icp-table-toolbar-delete-button"
                  type="text"
                  disabled={!hasDeletable}
                  icon={<Icon name="oct:trash" size={16} />}
                  loading={deleting}
                  onClick={handleDeleteRow}
                />
              </Tooltip>
            ) : null}
            {!suppressFuzzySearch ? (
              <SearchBox
                open={fuzzySearchOpen}
                placeholder={fuzzySearchPlaceholder}
                suppressSpeech={suppressFuzzySearchSpeech}
                defaultValue={searchText}
                onSearch={onSearch}
                ref={searchRef}
              />
            ) : null}
            {/* {!suppressFilterPanel ? (
              <div style={{ position: 'relative' }}>
                <Tooltip title={t('table.filter.label')}>
                  <Button
                    type="text"
                    icon={<Icon name="oct:filter" size={16} />}
                    onClick={() => setFilterPanelOpen(!filterPanelOpen)}
                  />
                </Tooltip>
                {showFilterDirty ? <div className="icp-table-dirty-dot" /> : null}
              </div>
            ) : null} */}
            {!suppressColumnSelect ? <ColumnSelect gridApi={gridApi} /> : null}
            {!suppressExcelExport ? (
              <Tooltip title={t('table.excel-export')}>
                <Button
                  className="icp-table-toolbar-export-button"
                  type="text"
                  icon={<Icon name="oct:download" size={16} />}
                  onClick={onExportExcel}
                />
              </Tooltip>
            ) : null}
            {!suppressToolbarRefresh ? (
              <Tooltip title={t('table.refresh')}>
                <Button
                  className="icp-table-toolbar-refresh-button"
                  type="text"
                  icon={<Icon name="oct:sync" size={16} />}
                  onClick={onRefresh}
                />
              </Tooltip>
            ) : null}
            {!suppressTableSetting ? (
              <TableSetting
                gridApi={gridApi}
                supportShowDeleted={supportShowDeleted}
                tableSize={tableState.tableSize}
                onTableSizeChange={(newSize) => onTableStateChange({ tableSize: newSize })}
                onResetSetting={onResetSetting}
              />
            ) : null}
            {!suppressFullscreen && !fullscreen ? (
              <Tooltip title={t('table.fullscreen')}>
                <Button
                  className="icp-table-toolbar-fullscreen-button"
                  type="text"
                  icon={<Icon name="oct:screen-full" size={16} />}
                  onClick={onToggleFullscreen}
                />
              </Tooltip>
            ) : (
              !suppressFullscreen && (
                <Tooltip title={t('table.exit-fullscreen')}>
                  <Button
                    className="icp-table-toolbar-fullscreen-button"
                    type="text"
                    icon={<Icon name="oct:screen-normal" size={16} />}
                    onClick={onToggleFullscreen}
                  />
                </Tooltip>
              )
            )}
            {!suppressFavoriteView && (isInDesign || settingKey) ? (
              <FavoriteView
                settingKey={settingKey}
                tableState={tableState}
                onSwitchFavoriteView={onSwitchFavoriteView}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {contextHolder}
    </div>
  );
});

TableToolbar.propTypes = {
  children: PropTypes.node,
  isInDesign: PropTypes.bool,
  componentLibrary: PropTypes.string,
  gridApi: PropTypes.shape({}),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  title: PropTypes.node,
  suppressToolbarActions: PropTypes.bool,
  suppressAddButton: PropTypes.bool,
  suppressDeleteButton: PropTypes.bool,
  suppressFuzzySearch: PropTypes.bool,
  suppressFuzzySearchSpeech: PropTypes.bool,
  suppressColumnSelect: PropTypes.bool,
  suppressExcelExport: PropTypes.bool,
  suppressToolbarRefresh: PropTypes.bool,
  suppressTableSetting: PropTypes.bool,
  suppressFullscreen: PropTypes.bool,
  supportShowDeleted: PropTypes.bool,
  suppressFilterPanel: PropTypes.bool,
  suppressFavoriteView: PropTypes.bool,
  addButtonHref: PropTypes.string,
  addButtonContent: PropTypes.string,
  fuzzySearchPlaceholder: PropTypes.string,
  fuzzySearchOpen: PropTypes.bool,
  rowSelection: PropTypes.oneOf(['single', 'multiple']),
  hasDeletable: PropTypes.bool,
  onAddRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onExportExcel: PropTypes.func,
  searchText: PropTypes.string,
  onSearch: PropTypes.func,
  onRefresh: PropTypes.func,
  fullscreen: PropTypes.bool,
  onToggleFullscreen: PropTypes.func,
  tableState: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    filterModel: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onTableStateChange: PropTypes.func,
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  // onTableSizeChange: PropTypes.func,
  onResetSetting: PropTypes.func,
  settingKey: PropTypes.string,
  onSwitchFavoriteView: PropTypes.func,
};

export default TableToolbar;
