import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Result } from 'antd';
import { useParams } from 'react-router-dom';
import { isMobile, setRef } from '@icp/utils';
import { Loading } from '@icp/components';
import { useTranslation } from 'react-i18next';
import { fetchFlowForm } from '@icp/page-renderer-core';
import { FORM_API_STAGE } from '@icp/form-renderer-core';
import ApproveNodeFormRenderer from './ApproveNodeFormRenderer';
import FormNodeFormRenderer from './FormNodeFormRenderer';
// import { getDataId } from '../utils';

const FlowFormRenderer = forwardRef(function FlowFormRenderer(props, ref) {
  const params = useParams();
  const { t } = useTranslation(['icp-common']);

  const {
    context,
    formType = 'FLOW-FORM',
    // Button dialog props 属性会传 null 来禁止从 params 读取这些属性
    flowToken = params.flowToken,
    flowDefinitionId = params.flowDefinitionId,
    flowInstanceId = params.flowInstanceId,
    threadId = params.threadId,
    formEntityDataId = params.formEntityDataId,
    forceLayoutEnv,
    dialogProps,
    FormRendererProps,
    ...other
  } = props;

  const { pbcToken } = context;

  const [instanceData, setInstanceData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!instanceData || error) {
      setRef(ref, { stage: FORM_API_STAGE.getForm, error });
    }
  }, [error, instanceData, ref]);

  // Button open 里会传 null 来禁止从 params flowInstanceId
  /* const flowInstanceId = getDataId(
    flowInstanceIdProp,
    FormRendererProps?.defaultData?.flowInstanceId,
    params.flowInstanceId,
  ); */

  useEffect(() => {
    setInstanceData(null);
    setError(null);
    fetchFlowForm({
      pbcToken,
      flowToken,
      flowDefinitionId,
      flowInstanceId,
      threadId,
      formEntityDataId,
      forceLayoutEnv,
    })
      .then(setInstanceData)
      .catch((e) => {
        console.error(e);
        setError(e);
      });
  }, [
    flowDefinitionId,
    flowInstanceId,
    threadId,
    formEntityDataId,
    forceLayoutEnv,
    flowToken,
    pbcToken,
  ]);

  if (error) {
    return (
      <Result
        title={t('error-occurred')}
        subTitle={error.message}
        status={[404, 403, 500].includes(error.status) ? error.status : 'error'}
      />
    );
  }

  if (!instanceData) {
    return <Loading className="form-renderer-block-loading" />;
  }

  const cmpProps = {
    context,
    pbcToken,
    flowToken,
    flowDefinitionId,
    flowInstanceId,
    threadId,
    instanceData,
    FormRendererProps,
    ref,
  };

  if (formType === 'FLOW-APPROVAL') {
    return (
      <ApproveNodeFormRenderer
        {...cmpProps}
        {...other}
        dialogProps={{ ...dialogProps, centered: !isMobile() }}
      />
    );
  }

  if (formType === 'FLOW-FORM') {
    return <FormNodeFormRenderer {...cmpProps} {...other} />;
  }
});

FlowFormRenderer.propTypes = {
  context: PropTypes.shape({
    pbcToken: PropTypes.string,
  }),
  formType: PropTypes.oneOf(['FLOW-FORM', 'FLOW-APPROVAL']),
  flowToken: PropTypes.string,
  flowDefinitionId: PropTypes.number,
  flowInstanceId: PropTypes.number,
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // api 返回的 threadId 是 string，其他 id 都是 number
  formEntityDataId: PropTypes.number,
  forceLayoutEnv: PropTypes.oneOf(['MOBILE', 'PC']),
  dialogProps: PropTypes.shape({ title: PropTypes.string }),
  FormRendererProps: PropTypes.shape({
    defaultData: PropTypes.shape({
      flowInstanceId: PropTypes.number,
    }),
  }),
};

export default FlowFormRenderer;
