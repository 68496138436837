import PropTypes from 'prop-types';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tree } from 'antd';
import { Loading, Icon } from '@icp/components';
import { restApi } from '@icp/settings';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName } from '../hooks';

const fieldNames = { title: 'name', key: 'id', children: 'children' };

const OrgTreeElement = forwardRef(function OrgTreeElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, componentProps = {} } = props;

  const { style: compStyle } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (isInDesign) return () => {};
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);
    restApi
      .get(`/form/api/v2/form-entity-data/get-tree-form-data/ORG`, { signal })
      .then((res) => setData([res]))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [isInDesign]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          'org-tree-element',
          className,
          classNameComp,
        )}
        style={{ ...style, ...compStyle }}
        ref={nodeRef}
      >
        {loading && <Loading />}
        {!loading && (
          <Tree
            showLine={true}
            switcherIcon={
              <div className="icp-center">
                <Icon name="oct:triangle-down" color="gray" />
              </div>
            }
            defaultExpandedKeys={data?.map((item) => item[fieldNames.key])}
            treeData={data}
            fieldNames={fieldNames}
          />
        )}
      </div>
    </ElementDecorator>
  );
});

OrgTreeElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
  }),
};

export default OrgTreeElement;
