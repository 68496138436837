import { useMemo } from 'react';
import { selectValues } from '@icp/form-renderer-core';
import { get } from 'lodash-es';
import { useStore } from '../store';
import useDependencies from './useDependencies';
import { useCurrentData } from '../currentDataCtx';

export default function useDisplayValue(id, valueField) {
  const store = useStore();
  const formData = selectValues(store.getState());
  const currentData = useCurrentData();

  const ids = useMemo(() => {
    if (currentData) {
      return [];
    }

    return valueField ?? id;
  }, [id, valueField, currentData]);

  useDependencies(ids);

  const data = currentData || formData || {};

  // valueField support keyPath
  if (valueField !== undefined && valueField !== null) {
    return get(data, valueField);
  }

  if (id !== undefined && id !== null) {
    return data[id];
  }

  return undefined;
}
