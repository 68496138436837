import loadable from '@loadable/component';

export const ProcessMining = loadable(() =>
  import(
    /* webpackChunkName: "icp-mining" */
    /* webpackPrefetch: true */
    './ProcessMining'
  ),
);

export const DataMining = loadable(() =>
  import(
    /* webpackChunkName: "icp-mining" */
    /* webpackPrefetch: true */
    './DataMining'
  ),
);

export const ProcessMiningResult = loadable(() =>
  import(
    /* webpackChunkName: "icp-mining" */
    /* webpackPrefetch: true */
    './ProcessMining'
  ).then((module) => ({ default: module.ProcessMiningResult })),
);

export const DataMiningResult = loadable(() =>
  import(
    /* webpackChunkName: "icp-mining" */
    /* webpackPrefetch: true */
    './DataMining'
  ).then((module) => ({ default: module.DataMiningResult })),
);
