export default function mapRoutes(routes, callback) {
  const dfs = (items) => {
    for (let i = 0; i < items.length; i++) {
      const config = items[i];

      items[i] = callback(config);

      if (config.children) {
        dfs(config.children);
      }
    }
  };

  dfs(routes);

  return routes;
}
