import { useEffect, useMemo } from 'react';
import {
  createMaterialTheme,
  extendsTheme,
  insertThemeConfigToPage,
  removeThemeConfigFromPage,
} from '@icp/app-core';

// theme 是直接延用了 antd 的 theme 配置 https://ant-design.antgroup.com/theme-editor-cn
// cssVars 是我们自己定义的给全局其余组件用的额外的 css 变量，在 icp-app/src/vars.css 里
// themeConfig.css 是给 theme 额外的机会在在 theme 和 cssVars 不满足需求的时候去修改 css
export default function useTheme(appConfig, scope = ':root') {
  const { themeConfig, css: oldCss, componentLibrary } = appConfig || {};
  const skip = !appConfig;

  // merge with themeConfig.base
  const mergedTheme = useMemo(() => {
    return skip ? {} : extendsTheme(themeConfig, componentLibrary);
  }, [componentLibrary, skip, themeConfig]);

  const antdTheme = useMemo(() => {
    return {
      token: mergedTheme.token,
      components: mergedTheme.components,
    };
  }, [mergedTheme]);

  const materialTheme = useMemo(() => {
    if (skip) {
      return null;
    }
    return createMaterialTheme(mergedTheme);
  }, [skip, mergedTheme]);

  useEffect(() => {
    if (skip) return () => {};

    insertThemeConfigToPage(mergedTheme, oldCss, scope);

    return () => {
      removeThemeConfigFromPage(scope);
    };
  }, [skip, mergedTheme, scope, oldCss]);

  return { mergedTheme, antdTheme, materialTheme };
}
