export function delay(ms, { signal } = {}) {
  let handle;

  const p = new Promise((resolve, reject) => {
    signal?.addEventListener('abort', (e) => {
      clearTimeout(handle);
      reject(e?.target?.reason);
    });

    handle = setTimeout(() => {
      signal?.throwIfAborted();
      resolve();
    }, ms);
  });

  p.abort = () => {
    clearTimeout(handle);
  };

  return p;
}

export function nextAnimationFrame({ signal } = {}) {
  let handle;

  const p = new Promise((resolve, reject) => {
    signal?.addEventListener('abort', (e) => {
      reject(e?.target?.reason);
    });

    handle = requestAnimationFrame((ms) => {
      signal?.throwIfAborted();
      resolve(ms);
    });
  });

  p.abort = () => {
    cancelAnimationFrame(handle);
  };

  return p;
}

export function nextAnimationFrameTimes(n, { signal } = {}) {
  return new Array(n).fill(null).reduce((prev) => {
    let abort;
    const p = prev.then(() => {
      const next = nextAnimationFrame({ signal });
      abort = next.abort;
      return next;
    });

    p.abort = () => {
      prev.abort?.();
      abort?.();
    };

    return p;
  }, Promise.resolve());
}

/**
 * @example
 * for await (const ms of nextAnimationFrameTimes(100, { signal })) {
 *  console.log(ms);
 * }
 */
export async function* nextAnimationFrameTimesIterator(n, { signal } = {}) {
  for (let i = 0; i < n; i++) {
    // eslint-disable-next-line no-await-in-loop
    yield await nextAnimationFrame({ signal });
  }
}
