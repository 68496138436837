import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash-es';
import { translateEntireObj } from '@icp/i18n';
import { restApi } from '@icp/settings';
import { categorizePermissions } from '../../utils';
import { permissionInitialState } from '../initialState';

/// --- export async thunks ---

export const fetchPermissionList = createAsyncThunk(
  'permission/fetchPermissionList',
  async (payload, { signal }) => {
    const { dataUrl = '/user-management/api/permission/list', dataResponseKeyPath = 'results' } =
      payload || {};

    const data = await restApi.get(dataUrl, { signal }).then((res) => {
      if (dataResponseKeyPath) {
        return get(res, dataResponseKeyPath);
      }
      return res;
    });
    translateEntireObj(data);
    return data;
  },
);

/// --- export async thunks end ---

const slice = createSlice({
  name: 'permission',
  initialState: { ...permissionInitialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionList.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchPermissionList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error;
      })
      .addCase(fetchPermissionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.error = null;

        const permissions = action.payload ?? [];
        translateEntireObj(permissions);

        state.permissions = permissions;
        state.permissionsByCategory = categorizePermissions(permissions);
      });
  },
});

/// --- export actions ---

// export const {} = slice.actions;

/// --- export reducer ---

export default slice.reducer;

export const { reducer: permissionReducer } = slice;

/// --- export selectors ---

const selectThis = (state) => state.permission;

export const selectPermissions = createSelector(selectThis, (state) => state.permissions);
export const selectPermissionsByCategory = createSelector(
  selectThis,
  (state) => state.permissionsByCategory,
);
