import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { delay } from '@icp/utils';
import { restApi } from '@icp/settings';
import { fileStorageState } from '../initialState';

const selectThis = (state) => state.fileStorage;

export const selectPermissionLoading = createSelector(
  selectThis,
  (state) => state.permissionLoading,
);
export const selectSessionId = createSelector(selectThis, (state) => state.sessionId);
export const selectAuthentication = createSelector(selectThis, (state) => state.authentication);
export const selectBaseUrl = createSelector(selectThis, (state) => state.baseUrl);

export const fetchUploadPermission = createAsyncThunk(
  'uploadPermission/fetchUploadPermission',
  async () => {
    const data = await restApi.post('/form/api/form-entity-resource/workspace');
    return data;
  },
  {
    condition: (payload, { getState }) => {
      const permissionLoading = selectPermissionLoading(getState());
      return !permissionLoading;
    },
  },
);

export const uploadFile = (files, config) => async (dispatch, getState) => {
  let baseUrl = selectBaseUrl(getState());
  let authentication = selectAuthentication(getState());

  if (!baseUrl) {
    const permissionLoading = selectPermissionLoading(getState());
    // 正在请求permission时，不再发起新请求，等待上个完成
    if (permissionLoading) {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        await delay(100);
        const stillLoading = selectPermissionLoading(getState());
        if (!stillLoading) {
          baseUrl = selectBaseUrl(getState());
          authentication = selectAuthentication(getState());
          break;
        }
      }
    } else {
      const payload = await dispatch(fetchUploadPermission()).unwrap();
      baseUrl = payload.baseUrl;
      authentication = payload.authentication;
    }
  }

  if (process.env.NODE_ENV === 'development') {
    // in order to avoid CORS, convert to relative URL
    try {
      baseUrl = new URL(baseUrl).pathname;
    } catch {
      //
    }
  }

  const uploadUrl = `${baseUrl}/upload`;

  const formData = new FormData();

  for (const file of files) {
    formData.append('files', file);
  }

  return restApi
    .post(uploadUrl, formData, {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${authentication}`,
      },
    })
    .then((resData) => {
      return resData.map((item) => {
        return {
          storageId: item.tempStorageId,
          fileName: item.fileName,
          url: `${baseUrl}/file/${item.tempStorageId}?jwt=${encodeURIComponent(authentication)}`,
        };
      });
    });
};

const slice = createSlice({
  name: 'fileStorage',
  initialState: { ...fileStorageState },
  reducers: {
    resetFileStorage: (state) => {
      state.sessionId = null;
      state.baseUrl = null;
      state.authentication = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUploadPermission.pending, (state) => {
        state.permissionLoading = true;
      })
      .addCase(fetchUploadPermission.fulfilled, (state, action) => {
        state.permissionLoading = false;
        state.sessionId = action.payload.sessionId;
        state.baseUrl = action.payload.baseUrl;
        state.authentication = action.payload.authentication;
      });
  },
});

export const { resetFileStorage } = slice.actions;

export default slice.reducer;
