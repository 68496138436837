import { makeNewField } from '@icp/form-designer';
import { parseJSON } from '@icp/utils';
import { loadFormEntity } from '../formEntityLoader';

function resolveChartSettings(phases, chartType, fields) {
  const phase = phases.find((p) => 'groupByFields' in p && 'aggregationModels' in p);
  if (!phase) return null;
  const { groupByFields, aggregationModels } = phase;
  const [gbf1, gbf2] = groupByFields || [];

  switch (chartType) {
    case 'bar':
    case 'line':
    case 'area': {
      return {
        categoryAxis: fields.find((f) => f.token === gbf1),
        legend: fields.find((f) => f.token === gbf2),
        valueAxis: (aggregationModels || []).map((model) => {
          const field = fields.find((f) => f.token === model.valueField);
          return {
            token: model.valueField,
            ...field,
            aggr: model.aggregationType,
            aggrAlias: model.alias,
          };
        }),
      };
    }
    case 'pie':
    case 'donut': {
      const model = aggregationModels?.[0];
      if (!model) return null;
      const field = fields.find((f) => f.token === model.valueField);
      return {
        legend: fields.find((f) => f.token === gbf1),
        value: {
          token: model.valueField,
          ...field,
          aggr: model.aggregationType,
          aggrAlias: model.alias,
        },
      };
    }
    default:
      return null;
  }
}

export async function transformToEChartElement(input) {
  try {
    input = parseJSON(input, { silent: true, autocomplete: true });
    if (!input) return null;
    if (!input.chartType) return null;
    const query = input.queryPipeline || input.query;
    if (!query) return null;
    if (!input.formEntity && (!input.formEntityToken || !input.pbcToken)) return null;

    let formEntity = input.formEntity;
    if (!formEntity) {
      formEntity = await loadFormEntity(input.pbcToken, input.formEntityToken);
    }

    const queryPipeline = parseJSON(query, { silent: true, autocomplete: true });

    const { chartType } = input;

    const newField = {
      bar: () => makeNewField('BarChart'),
      line: () => makeNewField('LineChart'),
      area: () => makeNewField('AreaChart'),
      pie: () => makeNewField('PieChart'),
      donut: () => makeNewField('DoughnutChart'),
    }[chartType]?.();

    if (!newField) return null;

    newField.componentProps.dataUrl = `/form/api/v2/form-entity-data/${formEntity.pbcToken}/${formEntity.token}/queryPipeline`;
    newField.componentProps.httpMethod = 'post';
    newField.componentProps.httpBody = queryPipeline;
    newField.componentProps.chartSettings = resolveChartSettings(
      queryPipeline?.phases || [],
      chartType,
      formEntity.fields || [],
    );

    return [newField, input.title, formEntity];
  } catch {
    return null;
  }
}
