import { forwardRef, useEffect, useRef } from 'react';
import { LoadI18nResource, Loading } from '@icp/components';
import {
  FORM_API_STAGE,
  hideNotAllowedFieldsInSchema,
  mergeSchemaToProps,
} from '@icp/form-renderer-core';
import { setRef } from '@icp/utils';
import { useInitFormRenderer } from './hooks';
import { StoreProvider } from './store';
import RequirePermission from './RequirePermission';
import { hideNotAllowedFieldsInChildren } from './utils';
import { FormRendererProps, WrapperProps } from './propTypes';
import FormRenderer from './FormRenderer';

const Wrapper = forwardRef(function Wrapper(originalProps, ref) {
  const props = mergeSchemaToProps(originalProps);
  const { children, schema, notAllowedFields, accessPermissionPredicate, disableDirty } = props;

  const isInDesign = !!props.ElementDecorator;

  const { loading, context, yupSchema, defaultValues, valueExpressions, isFetching, events } =
    useInitFormRenderer(props);

  const formApi = useRef({});

  useEffect(() => {
    if (loading) {
      setRef(ref, { stage: FORM_API_STAGE.fetchRemoteContext });
    }
  }, [loading, ref]);

  if (loading) {
    return <Loading className="form-renderer-block-loading" />;
  }

  if (!schema && !children) {
    return null;
  }

  return (
    <LoadI18nResource ns="icp-form-renderer">
      <StoreProvider
        disableDirty={disableDirty}
        isInDesign={isInDesign}
        isFetching={isFetching}
        context={context}
        yupSchema={yupSchema}
        defaultValues={defaultValues}
        valueExpressions={valueExpressions}
        // legacy support
        onFieldValueChange={
          typeof events.fieldValueChange === 'string' ? events.fieldValueChange : undefined
        }
        formApi={formApi.current}
      >
        <RequirePermission accessPermissionPredicate={accessPermissionPredicate} context={context}>
          <FormRenderer
            {...props}
            schema={hideNotAllowedFieldsInSchema(schema, notAllowedFields)}
            formApi={formApi.current}
            events={events}
            ref={ref}
          >
            {hideNotAllowedFieldsInChildren(children, notAllowedFields)}
          </FormRenderer>
        </RequirePermission>
      </StoreProvider>
    </LoadI18nResource>
  );
});

Wrapper.propTypes = { ...WrapperProps, ...FormRendererProps };

export default Wrapper;
