import {
  CROSS_LAYOUT_PROPERTIES_MAPPING,
  findSchemaFieldByToken,
  schemaFieldToDataField,
} from '@icp/form-schema';
import { get } from 'lodash-es';
import { immutableSet } from '@icp/utils';
import {
  assignDataFieldProperties,
  ensureFields,
  extractDataFieldsFromSchema,
  filterFieldsNotInAnyLayouts,
  updateOneFieldProperty,
} from './formEntityHelper';
import { updateFieldOperation } from './formEntityOperations';

function syncPropertiesToFields(formEntity, currentSchemaFields, needEnsureFields) {
  // fields in formEntity.fields and in extractedFields, sync changed properties
  let existingFields = formEntity.fields.map((dataField) => {
    const newDataField = currentSchemaFields.find((item) => item.token === dataField.token);

    // field not in current layout
    if (!newDataField) {
      return dataField;
    }

    return assignDataFieldProperties(dataField, newDataField);
  });

  if (needEnsureFields) {
    // fields not existing in any layout, delete it
    existingFields = filterFieldsNotInAnyLayouts(existingFields, formEntity.layouts);

    // New added fields which not existing in data.fields, insert it to data.fields manually.
    const addedFields = currentSchemaFields.filter((field) => {
      return !existingFields.find((dataField) => field.token === dataField.token);
    });

    return existingFields.concat(addedFields);
  }

  return existingFields;
}

function syncPropertiesToOtherLayouts(currentLayout, formEntity, currentSchemaFields) {
  return formEntity.layouts.map((layout) => {
    if (layout === currentLayout) {
      return layout;
    }

    // Update to another layouts
    let newSchema = layout.schema;

    for (const field of currentSchemaFields) {
      const { field: schemaField } = findSchemaFieldByToken(currentLayout.schema, field.token);
      const { keyPath: fieldKeyPath } = findSchemaFieldByToken(layout.schema, field.token);
      if (fieldKeyPath) {
        for (const keyPathOfLayout of CROSS_LAYOUT_PROPERTIES_MAPPING.KEY_PATHS.OF_LAYOUTS) {
          const newValue = get(schemaField, keyPathOfLayout);
          const oldValue = get(newSchema, fieldKeyPath.concat(keyPathOfLayout));
          // 不等才修改，否则会创建很多空对象导致无意义的 dirty
          if (newValue !== oldValue) {
            newSchema = updateFieldOperation(newSchema, fieldKeyPath, keyPathOfLayout, newValue);
          }
        }
      }
    }

    return { ...layout, schema: newSchema };
  });
}

/**
 * After edit in JSON mode, sync properties to data.fields and all other layouts.
 * @param currentLayout
 * @param formEntity
 * @returns {*&{fields, layouts}}
 */
export function syncSchemaAllInputFields(currentLayout, formEntity) {
  // 此操作是由 currentLayout.schema 批量改动触发的，所以需要先 extract fields from currentLayout.schema，
  // 然后同步其属性到其余的 layouts 里同样 id 的 input field。不能简单调用 ensureFields
  const currentSchemaFields = extractDataFieldsFromSchema(currentLayout.schema);

  const newFields = syncPropertiesToFields(formEntity, currentSchemaFields, true);
  const newLayouts = syncPropertiesToOtherLayouts(currentLayout, formEntity, currentSchemaFields);

  return { ...formEntity, fields: newFields, layouts: newLayouts };
}

export function syncOneInputCrossProperties({
  formEntity,
  currentLayout,
  token,
  needEnsureFields,
}) {
  const { field: schemaField } = findSchemaFieldByToken(currentLayout.schema, token);
  const extractedField = schemaFieldToDataField(schemaField, formEntity.pbcToken);

  const newFields = syncPropertiesToFields(formEntity, [extractedField], needEnsureFields);
  const newLayouts = syncPropertiesToOtherLayouts(currentLayout, formEntity, [extractedField]);

  return { ...formEntity, fields: newFields, layouts: newLayouts };
}

export function syncOneInputOneProperty({
  formEntity,
  currentLayout,
  token,
  propertyKeyPath,
  newValue,
}) {
  // Update to another layouts
  const newLayouts = formEntity.layouts.map((layout) => {
    if (layout === currentLayout) {
      return layout;
    }

    const { keyPath: fieldKeyPath } = findSchemaFieldByToken(layout.schema, token);

    // token not existing in this layout
    if (!fieldKeyPath) {
      return layout;
    }

    const newSchema = updateFieldOperation(layout.schema, fieldKeyPath, propertyKeyPath, newValue);
    return { ...layout, schema: newSchema };
  });

  // Update property in formEntity.fields
  const index = formEntity.fields.findIndex((layout) => layout.token === token);
  let newFields = formEntity.fields;
  if (index >= 0) {
    const newField = updateOneFieldProperty(formEntity.fields[index], propertyKeyPath, newValue);
    newFields = immutableSet(formEntity.fields, [index], newField);
  }

  // id change may cause formEntity.fields loose old one (when two fields has same token)
  if (String(propertyKeyPath) === 'id') {
    newFields = ensureFields(newFields, newLayouts, formEntity.pbcToken);
  }

  return { ...formEntity, fields: newFields, layouts: newLayouts };
}

export function syncOneInputAllPropertiesToOtherLayouts({
  formEntity,
  currentLayout,
  token,
  tokens,
}) {
  const { field: fieldInCurrentLayout } = findSchemaFieldByToken(currentLayout.schema, token);

  const newLayouts = formEntity.layouts.map((layout) => {
    if (layout === currentLayout || !tokens.includes(layout.token)) {
      return layout;
    }

    const { keyPath } = findSchemaFieldByToken(layout.schema, token);

    const newSchema = updateFieldOperation(layout.schema, keyPath, [], fieldInCurrentLayout);

    return { ...layout, schema: newSchema };
  });

  return { ...formEntity, layouts: newLayouts };
}
