// json 只有 8k 直接 import 进来也影响很小
import octHeightsData from '@site/cache/icons/oct/heights.json';

export function isEmoji(name) {
  // 简单判断就好，普通 icon name 我们只支持 ASCII 码
  // 9️⃣codePointAt(0) is 57
  return name.codePointAt(0) > 127 || name.codePointAt(1) > 127;
}

export function isImg(name) {
  return name.startsWith('data:image') || name.startsWith('http') || name.startsWith('/');
}

function closestNaturalHeight(naturalHeights, height) {
  return naturalHeights
    .map((naturalHeight) => {
      return parseInt(naturalHeight, 10);
    })
    .reduce((acc, naturalHeight) => {
      return naturalHeight <= height ? naturalHeight : acc;
    }, naturalHeights[0]);
}

export function getOctHeight(name, height) {
  const heights = octHeightsData[name];
  if (!heights) {
    return null;
  }
  return closestNaturalHeight(heights, height);
}

export function getIconUrl(name, size) {
  if (name.startsWith('oct')) {
    const iconName = name.slice('oct:'.length);
    // octicons 提供了针对有些 icon 提供了不同尺寸版本
    const fileName = `${iconName}-${getOctHeight(iconName, size)}`;
    return `${window.ICP_PUBLIC_PATH}icons/oct/${fileName}.svg#children`;
  }

  if (name.includes(':')) {
    const [library, iconName] = name.split(':');
    return `${window.ICP_PUBLIC_PATH}icons/${library}/${iconName}.svg#children`;
  }

  // default load icp icon
  return `${window.ICP_PUBLIC_PATH}icons/icp/${name}.svg#children`;
}
