import React, { forwardRef, useImperativeHandle } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import RecursionRenderer from '../RecursionRenderer';
import { useElementDecorator } from '../FormRenderCtx';
import { withFieldWrapper } from '../fieldWrapper';

const TooltipElement = forwardRef(function TooltipElement(props, ref) {
  const { keyPath = [], id, fields = [], componentProps = {}, children = null } = props;
  const ElementDecorator = useElementDecorator();

  useImperativeHandle(
    ref,
    () => ({
      node: null,
    }),
    [],
  );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <Tooltip {...componentProps} ref={ref}>
        {children || <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />}
      </Tooltip>
    </ElementDecorator>
  );
});

TooltipElement.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  componentProps: PropTypes.shape({}),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TooltipElement.displayName = 'Tooltip';

export default withFieldWrapper(TooltipElement);
