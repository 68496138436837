export default function loadCss(name, cssUrl) {
  return new Promise((resolve, reject) => {
    if (linkTagExists(name, cssUrl)) {
      resolve();
      return;
    }

    createLinkTag(name, cssUrl, resolve, reject);
  });
}

function linkTagExists(name, cssUrl) {
  const tag = document.querySelector(`[data-name="${name}"]`);

  return tag && tag.getAttribute('href') === cssUrl;
}

function createLinkTag(name, cssUrl, callback, errorback) {
  const linkNode = document.createElement('link');
  linkNode.setAttribute('rel', 'stylesheet');
  linkNode.setAttribute('type', 'text/css');
  linkNode.setAttribute('data-name', name);
  attachListeners(name, linkNode, callback, errorback);
  linkNode.setAttribute('href', cssUrl);
  document.head.appendChild(linkNode);
}

function attachListeners(name, linkNode, callback, errorback) {
  const unbind = () => {
    linkNode.removeEventListener('load', loadEventListener);
    linkNode.removeEventListener('error', errorEventListener);
  };
  const loadEventListener = () => {
    unbind();
    callback();
  };
  const errorEventListener = (e) => {
    unbind();
    errorback(e);
  };
  linkNode.addEventListener('load', loadEventListener);
  linkNode.addEventListener('error', errorEventListener);
}
