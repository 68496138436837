import { isAnyOf } from '@reduxjs/toolkit';
import { debounce, get } from 'lodash-es';
import { FIELD_TYPE_FORM } from '../../constant';
import {
  fetchFormEntity,
  inferDefaultLayoutAsCurrent,
  setCurrentLayoutIndex,
  pushUndoStack,
  addNewLayout,
  deleteLayout,
  setSelectedField,
  deleteFieldFromAllLayout,
  deleteField,
  selectFormEntity,
  selectSelectedField,
  selectLayouts,
  selectCurrentLayout,
  selectCurrentLayoutSchema,
  undo,
  redo,
  selectCurrentLayoutIndex,
} from './formEntitySlice';

const setCurrentLayoutOnFormEntityLoaded = {
  matcher: isAnyOf(fetchFormEntity.fulfilled),
  effect: async (action, { dispatch }) => {
    await dispatch(inferDefaultLayoutAsCurrent());
  },
};

const pushUndoStackOnFormEntityModified = {
  predicate: (action, currentState, originalState) => {
    if (undo.toString() === action.type) return false;
    if (redo.toString() === action.type) return false;
    if (action.payload?.disableUndoStack) return false; // 有一些程序自动发器的 action 不需要
    if (!action.type) return false;
    if (
      action.type.startsWith('formEntity/createFormEntity') ||
      action.type.startsWith('formEntity/updateFormEntity')
    ) {
      return false;
    }

    const current = selectFormEntity(currentState);
    const original = selectFormEntity(originalState);
    return current !== original;
  },
  effect: debounce(
    async (action, { dispatch, getOriginalState }) => {
      const formEntity = selectFormEntity(getOriginalState());
      const selectedField = selectSelectedField(getOriginalState());

      if (!formEntity) return;
      await dispatch(pushUndoStack({ formEntity, selectedField }));
    },
    200,
    { leading: true, trailing: false },
  ),
};

const clearSelectedFieldIdWhen = {
  matcher: isAnyOf(
    setCurrentLayoutIndex,
    addNewLayout,
    deleteLayout,
    deleteFieldFromAllLayout,
    deleteField,
  ),
  effect: async (action, { dispatch, getState }) => {
    const selectedField = selectSelectedField(getState());
    if (!selectedField.keyPath) return;
    await dispatch(setSelectedField({}));
  },
};

const clearSelectedFieldIdWhenUndoRedo = {
  matcher: isAnyOf(undo, redo),
  effect: async (action, { dispatch, getState }) => {
    const selectedField = selectSelectedField(getState());
    if (!selectedField.keyPath) return;
    if (selectedField.type === FIELD_TYPE_FORM) return;
    const schema = selectCurrentLayoutSchema(getState());
    const field = get(schema, selectedField.keyPath);
    if (!field) {
      await dispatch(setSelectedField({}));
    }
  },
};

const currentLayoutIndexOutOfBoundErrorBoundary = {
  predicate: (action, currentState) => {
    const currentLayoutIndex = selectCurrentLayoutIndex(currentState);
    if (currentLayoutIndex == null) return false; // data not loaded yet
    const current = selectCurrentLayout(currentState);
    return !current;
  },
  effect: async (action, { dispatch, getState }) => {
    const layouts = selectLayouts(getState());
    const defaultLayoutIdx = layouts?.findIndex((l) => l.defaultLayout) ?? -1;
    if (defaultLayoutIdx < 0) {
      console.error('Fatal Error: no default layout!');
    } else {
      await dispatch(setCurrentLayoutIndex(defaultLayoutIdx));
    }
  },
};

const logThunkError = {
  predicate: (action) => {
    return action.type?.endsWith(`/rejected`);
  },
  effect: (action) => {
    if (action.error?.stack) {
      console.error(action.error.stack);
    } else {
      console.error(action.error);
    }
  },
};

export default [
  setCurrentLayoutOnFormEntityLoaded,
  pushUndoStackOnFormEntityModified,
  clearSelectedFieldIdWhen,
  clearSelectedFieldIdWhenUndoRedo,
  currentLayoutIndexOutOfBoundErrorBoundary,
  logThunkError,
];
