import { loadIntlGetCanonicalLocales } from './loadIntlGetCanonicalLocales';
import { loadIntlLocale } from './loadIntlLocale';
import { loadIntlDisplayNames } from './loadIntlDisplayNames';
import { loadIntlListFormat } from './loadIntlListFormat';
import { loadIntlPluralRules } from './loadIntlPluralRules';
import { loadIntlNumberFormat } from './loadIntlNumberFormat';
import { loadIntlDateTimeFormat } from './loadIntlDateTimeFormat';
import { loadIntlRelativeTimeFormat } from './loadIntlRelativeTimeFormat';

const loadedMap = {};

export function isAllPolyfillsLoaded(locales) {
  return locales.every((locale) => loadedMap[locale]);
}

export function loadAllPolyfills(locales) {
  return Promise.all([
    loadIntlGetCanonicalLocales(),
    loadIntlLocale(),
    ...locales
      .filter((locale) => !loadedMap[locale])
      .flatMap((locale) => [
        loadIntlDisplayNames(locale),
        loadIntlListFormat(locale),
        loadIntlPluralRules(locale),
        loadIntlNumberFormat(locale),
        loadIntlDateTimeFormat(locale),
        loadIntlRelativeTimeFormat(locale),
      ]),
  ]).then(() => {
    for (const locale of locales) {
      loadedMap[locale] = true;
    }
  });
}
