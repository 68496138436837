import './cellEditors.css';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { runCellValidate, runColumnValidate } from '../../EditableTableElement/validation';
import { CurrentDataProvider } from '../../../currentDataCtx';
import TextareaElement from '../../TextareaElement';

const TextareaCellEditor = forwardRef(function TextareaCellEditor(props, ref) {
  const {
    value: valueProp,
    cellStartedEdit,
    colDef,
    data,
    context,
    rows = 4,
    cols = 50,
    validation,
    componentProps = {},
    node: nodeProp,
  } = props;

  const { simpleMode, ...otherComponentProps } = componentProps;

  const [value, setValue] = useState(valueProp);

  const refInput = useRef(null);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      focusIn() {
        refInput.current.node.querySelector('textarea').focus();
      },

      focusOut() {
        const { yupSchema } = context;
        runCellValidate({ yupSchema, colDef, data, value });
      },
      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        const { yupSchema } = context;
        const passed = runCellValidate({ yupSchema, colDef, data, value });
        const allRowData = [];
        // eslint-disable-next-line react/prop-types
        props.api.forEachNode((node) => {
          if (node !== nodeProp) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: value });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed || !passed2) {
          return true;
        }

        return value === props.value;
      },
    };
  });

  return (
    <CurrentDataProvider value={data}>
      <TextareaElement
        className="table-textarea-editor"
        suppressFormControl={true}
        value={value}
        onChange={setValue}
        componentProps={{
          ...otherComponentProps,
          simpleMode,
          rows,
          cols,
          size: context.tableSize,
          maxLength: validation?.maxLength,
          autoFocus: cellStartedEdit,
        }}
        ref={refInput}
      />
    </CurrentDataProvider>
  );
});

TextareaCellEditor.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  cellStartedEdit: PropTypes.bool,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  rows: PropTypes.number,
  cols: PropTypes.number,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    maxLength: PropTypes.number,
  }),
  componentProps: PropTypes.shape({
    simpleMode: PropTypes.bool,
  }),
};

// 验证unique列时使用
TextareaCellEditor.getUniqueKey = ({ value }) => {
  return JSON.stringify(value);
};

export default TextareaCellEditor;
