import loadable from '@loadable/component';

export function loadReactFlow() {
  return import(
    /* webpackChunkName: "reactflow" */
    /* webpackPrefetch: true */
    './Reactflow'
  ).then((module) => {
    // reactflow 貌似会在 ReactFlow 渲染完立即去遍历 Handle 的 dom node 寻找 edge 的位置，
    // 所以如果 Handle 被用 loadable lazy 的话会画不出线。
    // 因为 Handle 是在 custom node 里调用，一定在 ReactFlow 渲染过后才会调用，所以可以直接在这里赋值。
    AsyncReactFlow.Handle = module.Handle;
    return module;
  });
}

const AsyncReactFlow = {
  ReactFlow: loadable(() => loadReactFlow().then((module) => ({ default: module.ReactFlow }))),
  Controls: loadable(() => loadReactFlow().then((module) => ({ default: module.Controls }))),
  MiniMap: loadable(() => loadReactFlow().then((module) => ({ default: module.MiniMap }))),
  Background: loadable(() => loadReactFlow().then((module) => ({ default: module.Background }))),
  // Handle: loadable(() => loadReactFlow().then((module) => ({ default: module.Handle }))),
};

export default AsyncReactFlow;
