import AutoCellRenderer from './AutoCellRenderer';
import ActionCellRenderer from './ActionCellRenderer';
import AutoIndexCellRenderer from './AutoIndexCellRenderer';
import DateCellRenderer from './DateCellRenderer';
import EnumCellRenderer from './EnumCellRenderer';
import MultiValueCellRenderer from './MultiValueCellRenderer';
import NumberCellRenderer from './NumberCellRenderer';
import ProgressCellRenderer from './ProgressCellRenderer';
import StatusCellRenderer from './StatusCellRenderer';
import TextCellRenderer from './TextCellRenderer';
import UploadCellRenderer from './UploadCellRenderer';

export {
  AutoCellRenderer,
  ActionCellRenderer,
  AutoIndexCellRenderer,
  DateCellRenderer,
  EnumCellRenderer,
  MultiValueCellRenderer,
  NumberCellRenderer,
  ProgressCellRenderer,
  StatusCellRenderer,
  TextCellRenderer,
  UploadCellRenderer,
};

export const cellRenderMapping = {
  AutoCellRenderer,
  ActionCellRenderer,
  AutoIndexCellRenderer,
  DateCellRenderer,
  EnumCellRenderer,
  MultiValueCellRenderer,
  NumberCellRenderer,
  ProgressCellRenderer,
  StatusCellRenderer,
  TextCellRenderer,
  UploadCellRenderer,
};
