// eslint-disable-next-line import/no-extraneous-dependencies
import initAppConfig from 'create-icp-app/template/appConfig/app.config.json';
// eslint-disable-next-line import/no-extraneous-dependencies
import initRouters from 'create-icp-app/template/appConfig/routers.json';

export default function initFrontEndConfig(lang, projectName) {
  const appConfig = JSON.parse(JSON.stringify(initAppConfig));
  const routers = JSON.parse(JSON.stringify(initRouters));
  appConfig.title = projectName || '';
  appConfig.templateConfig.title = projectName || '';
  appConfig.i18n.fallbackLng = lang;
  appConfig.i18n.allowedLanguages = [lang];
  return {
    appConfig,
    routers,
  };
}
