import { createAsyncThunk, /* createSelector,  */ createSlice } from '@reduxjs/toolkit';
import { saveAsJson } from '@icp/utils';
import {
  initFormEntity,
  selectCurrentLayout,
  importFormEntity,
  selectFormEntity,
} from './formEntitySlice';
import { Converter, formEntityToPage, legacySupportEntity, pageToFormEntity } from '../utils';

/// --- export selectors ---

// const selectThis = (state) => state.pageLayout;

/// --- export async thunks ---

export const fetchPageLayout = createAsyncThunk(
  'pageLayout/fetchPageLayout',
  async (payload, { signal, extra, dispatch }) => {
    const pageData = await extra.restApi
      .get(`/form/api/form-entity-page/${payload.id}`, { signal })
      .then(Converter.PageLayout.fromDTO);

    const originalEntity = pageToFormEntity(pageData);
    const entity = legacySupportEntity(JSON.parse(JSON.stringify(originalEntity)), false);
    await dispatch(initFormEntity({ entity, originalEntity }));

    return pageData;
  },
);

export const createPageLayout = createAsyncThunk(
  'pageLayout/createPageLayout',
  async (payload, { extra, getState, dispatch }) => {
    const formEntity = selectFormEntity(getState());
    const copy = legacySupportEntity(JSON.parse(JSON.stringify(formEntity)), false);
    const page = formEntityToPage(copy);
    const dto = Converter.PageLayout.toDTO(page);
    const pageData = await extra.restApi
      .post('/form/api/form-entity-page', dto)
      .then(Converter.PageLayout.fromDTO);

    const entity = pageToFormEntity(pageData);
    await dispatch(initFormEntity({ entity }));

    return pageData;
  },
);

export const updatePageLayout = createAsyncThunk(
  'pageLayout/updatePageLayout',
  async (payload, { extra, getState, dispatch }) => {
    const formEntity = selectFormEntity(getState());
    const copy = legacySupportEntity(JSON.parse(JSON.stringify(formEntity)), false);
    const page = formEntityToPage(copy);
    const dto = Converter.PageLayout.toDTO(page);
    const pageData = await extra.restApi
      .put(`/form/api/form-entity-page/${page.id}`, dto)
      .then(Converter.PageLayout.fromDTO);

    const entity = pageToFormEntity(pageData);
    await dispatch(initFormEntity({ entity }));

    return pageData;
  },
);

export const initNewPageLayout = (payload) => {
  return (dispatch) => {
    return dispatch(initFormEntity({ pbcId: payload.pbcId, initialName: '' }));
  };
};

export const exportPageLayout = () => {
  return (dispatch, getState) => {
    const formEntity = selectFormEntity(getState());
    const currentLayout = selectCurrentLayout(getState());
    const exportData = { ...currentLayout };
    saveAsJson(exportData, formEntity.token);
  };
};

export const importPageLayout = (payload) => {
  return (dispatch, getState) => {
    const currentLayout = selectCurrentLayout(getState());

    const importData = { ...payload };
    delete importData.id;
    delete importData.pbcId;
    delete importData.contentVersion;

    // 判断一下有才赋值，只是为了解决一下在导入内容一样的时候不 dirty
    if ('id' in currentLayout) {
      importData.id = currentLayout.id;
    }

    return dispatch(
      importFormEntity(
        legacySupportEntity(
          {
            fields: [],
            layouts: [importData],
          },
          false,
        ),
      ),
    );
  };
};

/// --- export async thunks end ---

const slice = createSlice({
  name: 'pageLayout',
  initialState: {
    isLoading: false,
    isSubmitting: false,
    isError: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPageLayout.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchPageLayout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error;
      })
      .addCase(fetchPageLayout.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(createPageLayout.pending, (state) => {
        state.isSubmitting = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(updatePageLayout.pending, (state) => {
        state.isSubmitting = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(createPageLayout.rejected, (state, action) => {
        state.isSubmitting = false;
        state.isError = true;
        state.error = action.error;
      })
      .addCase(updatePageLayout.rejected, (state, action) => {
        state.isSubmitting = false;
        state.isError = true;
        state.error = action.error;
      })
      .addCase(createPageLayout.fulfilled, (state) => {
        state.isSubmitting = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(updatePageLayout.fulfilled, (state) => {
        state.isSubmitting = false;
        state.isError = false;
        state.error = null;
      });
  },
});

/// --- export actions ---

/* export const {
  //
} = slice.actions; */

/// --- export reducer ---

export default slice.reducer;
