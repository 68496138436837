import '../AIAgent.css';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FileList from './FileList';
import UploadWidget from './UploadWidget';
import { makeSerializableFiles } from '../utils';
import SpeechButton from '../../SpeechButton/SpeechButtonRTC';
import Icon from '../../Icon';
import KeyboardShortcut from '../../KeyboardShortcut';
import InputBox, { AllowSuggestionPropType } from './InputBox';
import GenerateButton from './GenerateButton';
import {
  fetchModelListOneTime,
  selectCurrentModel,
  selectModelList,
  selectModelListLoaded,
  setCurrentModel,
  useDispatch,
  useSelector,
} from '../store';

const InputArea = forwardRef((props, ref) => {
  const {
    toolbarConfig = {},
    inputBoxTop,
    disabled,
    onSend,
    onPaste,
    onGenerate,
    allowSuggestion,
  } = props;
  const {
    extraActions = [],
    hideSpeech,
    hideUpload,
    hideSend,
    hideGenerate,
    hideModelPicker,
  } = toolbarConfig;

  const dispatch = useDispatch();
  const modelListLoaded = useSelector(selectModelListLoaded);
  const modelList = useSelector(selectModelList);
  const currentModel = useSelector(selectCurrentModel);

  const [uploadFileList, setUploadFileList] = useState([]);
  const uploadWidgetRef = useRef();
  const [prompt, setPrompt] = useState('');
  const inputBoxRef = useRef();
  const { t } = useTranslation(['icp-components', 'icp-common']);

  useImperativeHandle(ref, () => ({
    setPrompt,
    reset,
    get inputBox() {
      return inputBoxRef.current;
    },
  }));

  useEffect(() => {
    const el = inputBoxRef.current?.textarea;
    if (!el) return () => {};
    if (onPaste) {
      el.addEventListener('paste', onPaste);
    }

    return () => {
      if (onPaste) {
        el.removeEventListener('paste', onPaste);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toolbarConfig.hideModelPicker) {
      return;
    }
    dispatch(fetchModelListOneTime());
  }, [dispatch, toolbarConfig.hideModelPicker]);

  const reset = () => {
    setUploadFileList([]);
    uploadWidgetRef.current?.setUploadFiles([]);
    setPrompt('');
    inputBoxRef.current?.focus();
  };

  const handleSend = (text) => {
    const promptText = text || prompt;
    if (promptText.trim() === '') {
      return;
    }
    onSend(promptText, uploadFileList.length > 0 ? makeSerializableFiles(uploadFileList) : []);
    reset();
  };

  const handleRemoveFile = (uid) => {
    if (disabled) {
      return;
    }
    const newFileList = uploadFileList.filter((file) => file.uid !== uid);
    setUploadFileList(newFileList);
    uploadWidgetRef.current?.setUploadFiles(newFileList);
  };

  const handleSelectModel = (model) => {
    dispatch(setCurrentModel(model));
  };

  const inputActions = [
    !hideSend && {
      id: 'ai-agent-action-send',
      icon: 'oct:paper-airplane',
      label: `${t('send', { ns: 'icp-common' })} (Ctrl/⌘+Enter)`,
      trigger: () => handleSend(),
      shouldDisable: (text) => text.trim() === '',
      keyboardShortCut: [
        { ctrlKey: true, key: 'Enter' },
        { metaKey: true, key: 'Enter' },
      ],
    },
  ]
    .filter(Boolean)
    .concat(extraActions);

  const inputActionsButtons = inputActions.map((action) => (
    <Tooltip key={action.id} title={action.label}>
      <KeyboardShortcut
        target={inputBoxRef.current?.textarea}
        keybindings={(action.keyboardShortCut || []).map((when) => ({
          when,
          triggerEventHandler: 'onClick',
        }))}
      >
        <button
          type="button"
          onClick={() => action.trigger(prompt)}
          disabled={disabled || action.shouldDisable?.(prompt)}
        >
          <Icon name={action.icon} size={16} />
        </button>
      </KeyboardShortcut>
    </Tooltip>
  ));

  return (
    <div className="ai-agent-input-area">
      {!hideUpload && (
        <div className="ai-agent-files-container icp-thin-scrollbar">
          <FileList files={uploadFileList} onRemoveFile={handleRemoveFile} />
        </div>
      )}
      <div className={clsx('ai-agent-input-box', { 'input-disabled': disabled })}>
        {inputBoxTop}
        <InputBox
          value={prompt}
          onChange={setPrompt}
          disabled={disabled}
          allowSuggestion={allowSuggestion}
          ref={inputBoxRef}
        />
        <div className="ai-agent-input-toolbar">
          {!hideModelPicker && (
            <Select
              className="ai-agent-input-select"
              size="small"
              disabled={disabled}
              placeholder={t('ai-agent.select-model', { ns: 'icp-components' })}
              popupMatchSelectWidth={false}
              loading={!modelListLoaded}
              options={modelList}
              fieldNames={{ label: 'name', value: 'model' }}
              onSelect={(_, item) => handleSelectModel(item)}
              value={currentModel?.model}
            />
          )}
          <div className="ai-agent-input-actions">
            {inputActionsButtons}
            {!hideUpload && (
              <UploadWidget
                fileList={uploadFileList}
                onChange={setUploadFileList}
                disabled={disabled}
                ref={uploadWidgetRef}
              />
            )}
            {!hideSpeech && (
              <SpeechButton
                tooltip={t('speech', { ns: 'icp-common' })}
                onChange={(v) => setPrompt((prev) => (prev || '') + v)}
                disabled={disabled}
                allowSwitchLanguage={true}
                speechErrorTip={t('search.speech-error', { ns: 'icp-components' })}
                speechListeningTip={t('search.speech-listening', { ns: 'icp-components' })}
              />
            )}
          </div>
          {!hideGenerate && (
            <GenerateButton
              disabled={disabled || prompt.trim() === ''}
              prompt={prompt}
              onGenerate={onGenerate}
            />
          )}
        </div>
      </div>
    </div>
  );
});

InputArea.propTypes = {
  toolbarConfig: PropTypes.shape({
    extraActions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        trigger: PropTypes.func,
        shouldDisable: PropTypes.func,
      }),
    ),
    hideSpeech: PropTypes.bool,
    hideUpload: PropTypes.bool,
    hideGenerate: PropTypes.bool,
    hideSend: PropTypes.bool,
    hideModelPicker: PropTypes.bool,
  }),
  inputBoxTop: PropTypes.node,
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
  onPaste: PropTypes.func,
  onGenerate: PropTypes.func,
  allowSuggestion: AllowSuggestionPropType,
};

export default InputArea;
