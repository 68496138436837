import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';

export async function loadIntlLocale() {
  // This platform already supports Intl.Locale
  if (shouldPolyfill()) {
    await import(
      /* webpackChunkName: "polyfill/intl-locale/polyfill" */
      '@formatjs/intl-locale/polyfill'
    );
  }
}
