import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useElementDecorator } from '../../FormRenderCtx';
import { useClassName, useVariablePattern } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import ProgressUI from './ProgressUI';

const ProgressElement = forwardRef(function ProgressElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, componentProps = {} } = props;

  const {
    percent: percentProp,
    status: statusProp,
    inHundred = false,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  let percent = useVariablePattern(percentProp);
  let status = useVariablePattern(statusProp);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  if (typeof percent !== 'number') {
    percent = 0;
  }
  if (!inHundred) {
    // floor 一下，否则类似 0.58 * 100 会等于 57.99999999999999
    // 并且暂时 progress cell 暂时不提供显示小数点后的功能，如果后续有需求可以增加参数做相应的保留小数点的格式化
    percent = Math.floor(percent * 100);
  }

  // 到 100% 自动显示成绿色
  if (!status && percent >= 100) {
    status = 'success';
  }

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <ProgressUI
        {...otherComponentProps}
        percent={percent}
        status={status}
        className={clsx(className, classNameComp)}
        style={{ ...style, ...componentProps.style }}
        ref={nodeRef}
      />
    </ElementDecorator>
  );
});

ProgressElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf(['default', 'warning', 'success', 'error']),
    ]),
    progressStyle: PropTypes.oneOf(['style1', 'style2', 'style3', 'style4']),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
ProgressElement.displayName = 'Progress';

export default withFieldWrapper(ProgressElement);
