export default function loadJS(jsUrl) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = jsUrl;
    document.body.appendChild(script);
    script.onload = () => {
      resolve();
    };
  });
}
