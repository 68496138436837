import { clamp } from '@icp/utils';

function isNumber(value) {
  return typeof value === 'number' && !Number.isNaN(value);
}

function getInnerSize(el) {
  const style = getComputedStyle(el);
  return {
    width: el.clientWidth - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight),
    height: el.clientHeight - parseFloat(style.paddingTop) - parseFloat(style.paddingBottom),
  };
}

export function normalizeSize(size) {
  const width = typeof size === 'object' ? size.width : size;
  const height = typeof size === 'object' ? size.height : size;
  // keep null and number
  return {
    width: width === null || isNumber(width) ? width : undefined,
    height: height === null || isNumber(height) ? height : undefined,
  };
}

export function setSavedSize(placement, settingKey, newSize) {
  let toSave;
  if (placement === 'all') {
    toSave = JSON.stringify({ width: newSize.width, height: newSize.height });
  }

  if (placement === 'top' || placement === 'bottom') {
    toSave = newSize.height;
  }

  if (placement === 'left' || placement === 'right') {
    toSave = newSize.width;
  }

  localStorage.setItem(settingKey, toSave);
}

export function getSavedSize(placement, settingKey) {
  const saved = localStorage.getItem(settingKey);
  if (!saved) {
    return null;
  }

  const parsed = JSON.parse(saved);

  return normalizeSize(parsed);
}

export function getDefaultSize(placement, defaultSize) {
  if (defaultSize === undefined || defaultSize === null) {
    return {};
  }

  return normalizeSize(defaultSize);
}

export function normalizeMinMax({ minProp, maxProp, maxGap, placement, elRef }) {
  const containerSize = getInnerSize(elRef.current.parentNode);

  const min = normalizeSize(minProp);
  const max = normalizeSize(maxProp);

  const toDefault = (v, defaultV) => {
    // keep null and number
    return v === undefined ? defaultV : v;
  };

  if (placement === 'all') {
    min.width = toDefault(min.width, 24);
    min.height = toDefault(min.height, 24);
    max.width = toDefault(max.width, containerSize.width - maxGap * 2);
    max.height = toDefault(max.height, containerSize.height - maxGap * 2);
  } else {
    min.width = toDefault(min.width, 24);
    min.height = toDefault(min.height, 24);
    max.width = toDefault(max.width, containerSize.width - maxGap);
    max.height = toDefault(max.height, containerSize.height - maxGap);
  }

  return { min, max };
}

export function computeNewValues({
  move,
  elRef,
  movePlacement,
  placement,
  isPercent,
  min,
  max,
  maxGap,
  startValues,
}) {
  const containerSize = getInnerSize(elRef.current.parentNode);

  // move in left and top is negative, right and bottom is positive
  const invertX = movePlacement.current.includes('left');
  const invertY = movePlacement.current.includes('top');
  const moveInverted = {
    x: invertX ? -move.x : move.x,
    y: invertY ? -move.y : move.y,
  };

  const computeEdgeNewSize = () => {
    const sizeKey = ['top', 'bottom'].includes(movePlacement.current) ? 'height' : 'width';
    const moveKey = ['top', 'bottom'].includes(movePlacement.current) ? 'y' : 'x';

    const moveAdd = moveInverted[moveKey];

    if (isPercent) {
      const newRatio = ((startValues.current[sizeKey] + moveAdd) / containerSize[sizeKey]) * 100;
      const minRatio = min[sizeKey] !== null ? (min[sizeKey] / containerSize[sizeKey]) * 100 : null;
      const maxRatio = max[sizeKey] !== null ? (max[sizeKey] / containerSize[sizeKey]) * 100 : null;
      return {
        [sizeKey]: clamp(newRatio, minRatio, maxRatio),
      };
    }

    return {
      [sizeKey]: clamp(startValues.current[sizeKey] + moveAdd, min[sizeKey], max[sizeKey]),
    };
  };

  const computeCornerNewSize = () => {
    return {
      width: clamp(startValues.current.width + moveInverted.x, min.width, max.width),
      height: clamp(startValues.current.height + moveInverted.y, min.height, max.height),
    };
  };

  let newSize;

  // resize on edge
  if (['top', 'right', 'bottom', 'left'].includes(movePlacement.current)) {
    newSize = computeEdgeNewSize();
  }

  // resize on 4 corners
  if (['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(movePlacement.current)) {
    newSize = computeCornerNewSize();
  }

  const {
    width: startWidth,
    height: startHeight,
    translateX: startX,
    translateY: startY,
  } = startValues.current;
  let { width = startWidth, height = startHeight } = newSize;
  let translateX = startX;
  let translateY = startY;

  if (placement === 'all') {
    const elRect = elRef.current.getBoundingClientRect();
    const parentElRect = elRef.current.parentNode.getBoundingClientRect();

    if (maxGap !== null) {
      // top edge
      if (movePlacement.current.includes('top')) {
        height -= Math.max(parentElRect.top + maxGap - (elRect.bottom - height), 0);
      }

      // bottom edge
      if (movePlacement.current.includes('bottom')) {
        height += Math.min(parentElRect.bottom - maxGap - (elRect.top + height), 0);
      }
    }

    if (maxGap !== null) {
      // right edge
      if (movePlacement.current.includes('right')) {
        width += Math.min(parentElRect.right - maxGap - (elRect.left + width), 0);
      }

      // left edge
      if (movePlacement.current.includes('left')) {
        width -= Math.max(parentElRect.left + maxGap - (elRect.right - width), 0);
      }
    }

    // set transform to align direction
    translateX = invertX ? startX - (width - startWidth) : startX;
    translateY = invertY ? startY - (height - startHeight) : startY;
  }

  return {
    width,
    height,
    translateX,
    translateY,
  };
}
