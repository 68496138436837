import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce, set } from 'lodash-es';
import {
  dataSourceToDataUrl,
  GROUPABLE_CHART_DIMENSION,
  AGGREGATABLE_CHART_DIMENSION,
  selectContext,
} from '@icp/form-renderer-core';
import { AG_SORT_TYPES, Loading } from '@icp/components';
import { getResourceBundle } from '@icp/i18n';
import { shouldTranslateByDefault } from '@icp/settings';
import { loadECharts, randomNumber } from '@icp/utils';
import { useClassName, useDataSource, useDisplayValue } from '../hooks';
import {
  ConditionalListOrSinglePropertyPropType,
  DataFilterAfterAggregationType,
  DataFiltersType,
} from '../propTypes';
import { useElementDecorator, useFormApi, useIsInDesign } from '../FormRenderCtx';
import { withFieldWrapper } from '../fieldWrapper';
import { useStore } from '../store';

function normalizeDataset(data, chartSettings) {
  const groupByFields = GROUPABLE_CHART_DIMENSION.flatMap(
    (dim) => chartSettings?.[dim] ?? [],
  ).filter(Boolean);

  function normalizeItem(originalItem) {
    const item = { ...originalItem };
    for (const field of groupByFields) {
      switch (field.type) {
        case 'TEXTAREA':
        case 'RICH_TEXT': {
          const val = item[field.token]?.longText;
          if (val) {
            item[field.token] = val;
          }
          break;
        }
        case 'TREE_SELECT':
        case 'CASCADER':
        case 'SELECT':
        case 'ACL': {
          // 正常只支持单选 ACL / SELECT
          const val =
            item[field.token] === undefined
              ? undefined
              : (Array.isArray(item[field.token]) ? item[field.token] : [item[field.token]])
                  .map((x) => (typeof x === 'object' ? `${x?.label || ''}` : `${x}`))
                  .sort((a, b) => a.localeCompare(b))
                  .join(', ');
          item[field.token] = val;
          break;
        }
        case 'TEXT_BOX':
        case 'DATE':
        case 'TIME':
        case 'SWITCH':
        case 'RATE':
        case 'NUMBER':
        case 'FORM_DESIGNER':
        case 'RADIO':
        case 'CHECKBOX':
        case 'UPLOAD':
        case 'FORMULA':
        case 'EDITABLE_GRID':
        default:
          break;
      }
    }
    return item;
  }

  return data.map(normalizeItem);
}

function applyPivot(data, categoryField, pivotField, valueField) {
  const map = new Map();
  const pivotColumnSet = new Set();

  for (const {
    [categoryField]: category,
    [pivotField]: pivotColumn,
    [valueField]: value,
  } of data) {
    if (!map.has(category)) {
      map.set(category, { [categoryField]: category });
    }
    map.get(category)[pivotColumn] = value;
    pivotColumnSet.add(pivotColumn);
  }

  return [Array.from(map.values()), Array.from(pivotColumnSet)];
}

const EChartElement = forwardRef(function EChartElement(
  {
    keyPath,
    id,
    className: classNameProp,
    style: styleSetByParent,
    valueField,
    componentProps = {},
    //
  },
  ref,
) {
  const {
    style,
    category,
    chartSettings,
    echartOption,
    series,
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    httpBody,
    defaultValues, // deprecated
    dataFilterAfterAggregation,
  } = componentProps;

  const store = useStore();

  const { i18n } = useTranslation('icp-vendor-echarts');
  const lng = i18n.language;
  const localeObj = useMemo(() => getResourceBundle('icp-vendor-echarts'), []);

  const ElementDecorator = useElementDecorator();
  const context = selectContext(store.getState());
  const isInDesign = useIsInDesign();
  const formApi = useFormApi();
  const dataUrl = dataUrlProp || dataSourceToDataUrl(dataSource, context, true, isInDesign);

  const [echartLoaded, setEchartLoaded] = useState(false);

  const nodeRef = useRef(null);
  const eleRef = useRef(null);
  const echartInstance = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      get chart() {
        return echartInstance.current;
      },
    }),
    [],
  );

  const valueResolved = useDisplayValue(id, valueField);

  const aggregation = useMemo(() => {
    if (!chartSettings) return null;
    const groupByFields = GROUPABLE_CHART_DIMENSION.flatMap((dim) => chartSettings?.[dim] ?? [])
      .filter(Boolean)
      .map((f) => [f.token, f.fn].filter(Boolean).join('|'));

    return {
      groupByFields: [...new Set(groupByFields)],
      aggregationModels: AGGREGATABLE_CHART_DIMENSION.flatMap((dim) => chartSettings?.[dim] ?? [])
        .filter(Boolean)
        .map((f) => ({
          valueField: f.token,
          aggregationType: f.aggr,
          alias: f.aggrAlias,
        })),
    };
  }, [chartSettings]);
  const invalidAggregation =
    aggregation && (!aggregation.groupByFields?.length || !aggregation.aggregationModels?.length);

  const { loading, error, dataFetched } = useDataSource({
    skip: valueResolved || invalidAggregation,
    defaultValues, // deprecated
    // single data source config
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod: httpMethod || (!dataUrlProp && dataSource ? 'post' : undefined), // 使用dataSource推断的内部聚合接口是post
    httpBody,
    // aggregation config
    aggregation,
    dataFilterAfterAggregation,
  });
  const datasetSource = dataFetched || valueResolved;

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-chart-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // init echarts
  useEffect(() => {
    loadECharts().then((echarts) => {
      if (localeObj) {
        echarts.registerLocale(lng, localeObj);
        echartInstance.current = echarts.init(eleRef.current, null, {
          locale: lng,
        });
      } else {
        echartInstance.current = echarts.init(eleRef.current);
      }

      setEchartLoaded(true);
      formApi.asyncComponentManager.setReady(idToRegister);
    });

    return () => {
      echartInstance.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lng, localeObj]);

  // resize listener
  useEffect(() => {
    const listener = debounce(
      () => {
        echartInstance.current?.resize();
      },
      250,
      { leading: true, trailing: true },
    );

    const el = eleRef.current;
    const hasResizeObserver = !!window.ResizeObserver;
    let observer;

    if (hasResizeObserver) {
      observer = new ResizeObserver(listener);
      observer.observe(el);
    } else {
      window.addEventListener('resize', listener);
    }

    return () => {
      if (hasResizeObserver) {
        observer.unobserve(el);
      } else {
        window.removeEventListener('resize', listener);
      }
    };
  }, []);

  // set echart option
  useEffect(() => {
    if (!echartLoaded || loading) return;

    const option = { ...echartOption };

    if (datasetSource) {
      set(option, 'dataset.source', datasetSource);
    }

    const { transpose, categoryAxis, valueAxis: valueAxises } = chartSettings || {};
    const valueAxis = valueAxises?.filter(Boolean);

    if (
      category === 'axis' &&
      series &&
      chartSettings &&
      categoryAxis &&
      valueAxis?.length > 0
      //
    ) {
      const source = normalizeDataset(datasetSource || [], chartSettings);
      set(option, 'dataset.source', source);
      set(option, 'dataset.dimensions', [categoryAxis.token, ...valueAxis.map((x) => x.aggrAlias)]);
      let valueAxisFields = valueAxis;

      if (chartSettings.legend) {
        const [pivotedDataset, pivotColumns] = applyPivot(
          source,
          categoryAxis.token,
          chartSettings.legend.token,
          valueAxis[0].aggrAlias,
        );
        valueAxisFields = pivotColumns.map((x) => ({ token: x }));
        set(option, 'dataset.source', pivotedDataset);
        set(option, 'dataset.dimensions', [categoryAxis.token, ...pivotColumns]);
      }

      option.xAxis = {
        ...option.xAxis,
        type: transpose ? 'value' : 'category',
      };

      option.yAxis = {
        ...option.yAxis,
        type: transpose ? 'category' : 'value',
      };

      option.series = [
        ...(option.series || []),
        ...valueAxisFields.map((vField) => ({
          ...series,
          name: vField?.name || vField?.token,
          encode: {
            [transpose ? 'y' : 'x']: categoryAxis?.token,
            [transpose ? 'x' : 'y']: vField?.aggrAlias ?? vField?.token,
            ...(series.type === 'scatter' && { tooltip: [categoryAxis?.token, vField?.token] }),
          },
        })),
      ];
    }
    if (
      category === 'pie' &&
      series &&
      chartSettings &&
      chartSettings.legend &&
      chartSettings.value
    ) {
      const source = normalizeDataset(datasetSource || [], chartSettings);
      set(option, 'dataset.source', source);

      option.series = [
        ...(option.series || []),
        {
          ...series,
          name: chartSettings.legend?.name || chartSettings.legend?.token,
          encode: {
            itemName: chartSettings.legend?.token,
            value: chartSettings.value?.aggrAlias ?? chartSettings.value?.token,
          },
        },
      ];
    }

    echartInstance.current.setOption(option, true);
  }, [echartLoaded, loading, echartOption, datasetSource, category, chartSettings, series]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('e-chart-element form-element', className)}
        style={styleSetByParent}
        ref={nodeRef}
      >
        {loading && <Loading />}
        {error && <div>{error.message ?? `${error.error}`}</div>}
        <div
          ref={eleRef}
          className={classNameComp}
          style={{
            ...style,
            ...(loading || error ? { visibility: 'hidden' } : null),
          }}
        />
      </div>
    </ElementDecorator>
  );
});

EChartElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * axis - 轴坐标系
     * pie - 饼图无坐标系
     */
    category: PropTypes.oneOf(['axis', 'pie']),
    /**
     * category=axis - 配横纵轴字段
     * category=pie - 配legend/value字段
     */
    chartSettings: PropTypes.shape({}),
    /**
     * echart 的 option 设置
     */
    echartOption: PropTypes.shape({}),
    /**
     * echart 的 series 设置
     */
    series: PropTypes.shape({}),
    /**
     * 通过 `dataUrl` 获取数据
     */
    dataUrl: ConditionalListOrSinglePropertyPropType(PropTypes.string),
    /**
     * 数据请求的http方法
     * @default 'get'
     */
    httpMethod: PropTypes.oneOf(['get', 'post', 'put']),
    /**
     * 指定请求的body，将覆盖默认通过 dataFilters sortModel dataFilterAfterAggregation 产生的body
     */
    httpBody: PropTypes.shape({}),
    /**
     * 也可以指定 formEntity 的数据源作为 data source
     */
    dataSource: PropTypes.shape({
      listUrl: PropTypes.string,
      token: PropTypes.string,
    }),
    /**
     * 数据存在于 response data 里的 key path
     * @default 'results'
     */
    dataResponseKeyPath: PropTypes.string,
    /**
     * 转换从 `dataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 是否需要翻译请求结果
     * @default false
     */
    translateDataResponse: PropTypes.bool,
    /**
     * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变
     */
    dataFilters: DataFiltersType,
    /**
     * 数据聚合后的过滤
     */
    dataFilterAfterAggregation: DataFilterAfterAggregationType,
    /**
     * 请求数据的 debounce time
     */
    debounceTime: PropTypes.number,
    /**
     * TODO，
     */
    defaultValues: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * 数据的排序方式，前端排序
     */
    sortModel: PropTypes.shape({
      /**
       * 排序字段 id
       */
      id: PropTypes.string,
      /**
       * 生序或者降序排序
       * @default 'asc'
       */
      sort: PropTypes.oneOf(AG_SORT_TYPES),
      /**
       * 值按照什么类型进行排序
       * @default 'text'
       */
      type: PropTypes.oneOf(['text', 'number', 'date']),
    }),
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
EChartElement.displayName = 'EChart';

export default withFieldWrapper(EChartElement, { ns: 'icp-vendor-echarts' });
