/* eslint-disable react/prop-types */
import './FilterPanel.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { immutableDelete, immutableSet } from '@icp/utils';
import { useEventCallback } from '@icp/hooks';
import AddFilter from './AddFilter';
import { makeNewFilterModel } from './utils';
import FilterItem from './FilterItem';
import { getSelectableColumns } from '../../utils';

function FilterPanel(props) {
  const { gridApi, pinnedFilter, filterModel, onChange } = props;

  const [newOne, setNewOne] = useState(null);

  useEffect(() => {
    setNewOne(null);
  }, [filterModel]);

  const handleAddFilter = (colId) => {
    const newFilterModel = filterModel.concat(makeNewFilterModel(colId, gridApi));
    setNewOne(colId);
    onChange(newFilterModel);
  };

  const handleDeleteFilter = (colId) => {
    const index = filterModel.findIndex((model) => model.colId === colId);
    const newFilterModel = immutableDelete(filterModel, [index]);
    onChange(newFilterModel);
  };

  const handleChangeFilter = useEventCallback((colId, k, v) => {
    let index = filterModel.findIndex((model) => model.colId === colId);

    let newFilterModel = filterModel;

    // -1 意味着修改的是 pinnedFilter 的内容
    if (index === -1) {
      index = newFilterModel.length;
      newFilterModel = newFilterModel.concat(makeNewFilterModel(colId, gridApi));
    }

    newFilterModel = immutableSet(newFilterModel, [index, k], v);

    // inRange 要求两个值，清空一下脏数据
    const old = index !== -1 && newFilterModel[index];
    if ((old && k === 'type' && old.type !== v && old.type === 'inRange') || v === 'inRange') {
      if (old.filterType === 'date') {
        newFilterModel = immutableSet(newFilterModel, [index, 'dateFrom'], null);
        newFilterModel = immutableSet(newFilterModel, [index, 'dateTo'], null);
      }

      if (old.filterType === 'number') {
        newFilterModel = immutableSet(newFilterModel, [index, 'filter'], null);
        newFilterModel = immutableSet(newFilterModel, [index, 'filterTo'], null);
      }
    }

    onChange(newFilterModel);
  });

  if (!gridApi || gridApi.isDestroyed()) {
    return null;
  }

  const allColumns = getSelectableColumns(gridApi);

  return (
    <div className="icp-table-filter-panel">
      {Array.isArray(pinnedFilter) &&
        pinnedFilter.map((colId) => {
          const model = filterModel.find((fm) => fm.colId === colId);
          return (
            <FilterItem
              key={colId}
              gridApi={gridApi}
              model={model || makeNewFilterModel(colId, gridApi)}
              allColumns={allColumns}
              deletable={false}
              onChange={(k, v) => handleChangeFilter(colId, k, v)}
            />
          );
        })}
      {filterModel
        .filter((model) => !Array.isArray(pinnedFilter) || !pinnedFilter.includes(model.colId))
        .map((model) => (
          <FilterItem
            key={model.colId}
            gridApi={gridApi}
            model={model}
            allColumns={allColumns}
            defaultOpen={newOne === model.colId}
            onChange={(k, v) => handleChangeFilter(model.colId, k, v)}
            onDelete={() => handleDeleteFilter(model.colId)}
          />
        ))}
      <AddFilter
        gridApi={gridApi}
        allColumns={allColumns}
        pinnedFilter={pinnedFilter}
        filterModel={filterModel}
        onChange={handleAddFilter}
      />
    </div>
  );
}

FilterPanel.propTypes = {
  gridApi: PropTypes.shape({}),
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  filterModel: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default FilterPanel;
