import './cellRenderers.css';
import PropTypes from 'prop-types';
import { Icon, Loading } from '@icp/components';
import { ensureDisplayText } from '@icp/utils';
import clsx from 'clsx';
import EnumLoader from './EnumLoader';
import { withSuspense } from '../../../fieldWrapper';
import { ENUM_COLORS_MAP } from '../../../constant';

function EnumCellRenderer(props) {
  const {
    value,
    enums: enumsProp,
    remoteEnum,
    defaultValue,
    enumStyle = 'style1',
    rounded = false,
    context,
  } = props;

  if (!Array.isArray(enumsProp) && !remoteEnum) {
    return null;
  }

  const enums = enumsProp || context[Symbol.for('EnumLoader')].load(remoteEnum);
  const enumItem = enums.find((item) => item.value === value) || {};

  const color = ENUM_COLORS_MAP[enumItem.color] || enumItem.color;
  let { icon } = enumItem;

  if (typeof icon === 'string') {
    icon = { name: icon };
  }

  if (context.tableSize === 'small' && icon && !icon.size) {
    icon.size = 14;
  }

  let displayText = EnumCellRenderer.toDisplayText(value, { enums, defaultValue }, context);
  displayText = ensureDisplayText(displayText);

  return (
    <div className="table-enum-cell">
      <div className={clsx(enumStyle, { rounded })} style={{ '--enum-color': color }}>
        {icon && enumStyle !== 'style2' ? (
          <Icon className="table-enum-cell-icon" size={16} {...icon} />
        ) : null}
        {displayText ? (
          <span className="table-enum-cell-text text-ellipsis">{displayText}</span>
        ) : null}
      </div>
    </div>
  );
}

EnumCellRenderer.toDisplayText = (value, cellRendererParams, context) => {
  const { enums: enumsProp, remoteEnum, defaultValue } = cellRendererParams || {};

  const enums = enumsProp || context?.[Symbol.for('EnumLoader')].getCache(remoteEnum);

  const v = value ?? defaultValue;
  return enums?.find((item) => item.value === v)?.label;
};

EnumCellRenderer.valueFormatter = (params) => {
  const context = params.context;
  const { enums: enumsProp, remoteEnum } = params.colDef.cellRendererParams || {};

  const enums = enumsProp || context?.[Symbol.for('EnumLoader')].getCache(remoteEnum);

  return enums?.find((item) => item.value === params.value)?.label;
};

EnumCellRenderer.values = (params) => {
  const context = params.context;
  const { enums: enumsProp, remoteEnum } = params.colDef.cellRendererParams || {};

  // TODO, 如果是异步请求时间比较长，好像会导致请求成功过后 ag-grid 重新刷新一次 table？
  // 加一个 setTimeout 时间长点就能重现
  Promise.resolve(enumsProp)
    .then((x) => x || context?.[Symbol.for('EnumLoader')].load(remoteEnum))
    .catch((p) => p)
    .then((enums) => enums.map((item) => item.value))
    .then(params.success)
    .catch(console.error);
};

EnumCellRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  enums: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      label: PropTypes.string,
      color: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string,
          size: PropTypes.number,
          color: PropTypes.string,
        }),
      ]),
    }),
  ),
  remoteEnum: PropTypes.shape({
    dataUrl: PropTypes.string,
    dataResponseKeyPath: PropTypes.string,
    transformDataResponse: PropTypes.string,
    translateDataResponse: PropTypes.bool,
  }),
  enumStyle: PropTypes.string,
  rounded: PropTypes.bool,
  context: PropTypes.shape({
    [Symbol.for('EnumLoader')]: PropTypes.instanceOf(EnumLoader),
    tableSize: PropTypes.string,
  }),
};

export default withSuspense({ fallback: <Loading size={16} /> })(EnumCellRenderer);
