import { useMemo } from 'react';
import { createSelectDependencyValues } from '@icp/form-renderer-core';
import { useSelector } from '../store';

// useSelector to trigger re-render when form data dependencies changed
// TODO ids 其实应该叫 keyPathArr
export default function useDependencies(ids) {
  const selectDependencyValues = useMemo(createSelectDependencyValues, []);

  return useSelector(selectDependencyValues(ids));
}
