import { selectContext, selectValues } from '@icp/form-renderer-core';
import { resolveVariablePattern } from '@icp/utils';
import dayjs from 'dayjs';

function resolveComparedDate(value, currentData, store) {
  const formData = selectValues(store.getState());
  const context = selectContext(store.getState());

  const theValue = resolveVariablePattern({
    currentData: currentData || formData,
    formData,
    context,
    pattern: value,
  });

  if (!theValue && theValue !== 0) {
    // 0 is today
    return null;
  }

  // In moment, 0 is today, -1 is yesterday, 1 is tomorrow
  // moment().add(0, 'days').endOf('days') returns the end of today.
  if (theValue === 'today') {
    return dayjs().endOf('day');
  }
  if (theValue === 'tomorrow') {
    return dayjs().add(1, 'day').endOf('day');
  }
  if (theValue === 'yesterday') {
    return dayjs().add(-1, 'day').endOf('day');
  }
  if (typeof theValue === 'number') {
    return dayjs().add(theValue, 'day').endOf('day');
  }

  return dayjs(theValue).endOf('day');
}

function requireNumber(v) {
  if (typeof v !== 'number') {
    return 0;
  }
  return v;
}

export function getDisabledDate(disabledDateProp, current, currentData, store) {
  const { type, includeValue, value, valueFrom, valueTo } = disabledDateProp;
  let { valueOffset, valueFromOffset, valueToOffset } = disabledDateProp;

  if (!current) {
    return false;
  }

  valueOffset = requireNumber(valueOffset);
  valueFromOffset = requireNumber(valueFromOffset);
  valueToOffset = requireNumber(valueToOffset);

  switch (type) {
    case 'before': {
      const theDate = resolveComparedDate(value, currentData, store);
      if (!theDate) {
        return false;
      }
      const dayOffset = (includeValue ? 0 : -1) + valueOffset;
      return current < theDate.add(dayOffset, 'day');
    }
    case 'after': {
      const theDate = resolveComparedDate(value, currentData, store);
      if (!theDate) {
        return false;
      }
      const dayOffset = (includeValue ? -1 : 0) + valueOffset;
      return current > theDate.add(dayOffset, 'day');
    }
    case 'inRange': {
      const fromDate = resolveComparedDate(valueFrom, currentData, store);
      const toDate = resolveComparedDate(valueTo, currentData, store);
      if (!fromDate || !toDate) {
        return false;
      }
      const fromOffset = (includeValue ? -1 : 0) + valueFromOffset;
      const toOffset = (includeValue ? 0 : -1) + valueToOffset;
      return current > fromDate.add(fromOffset, 'day') && current < toDate.add(toOffset, 'day');
    }
    case 'outRange': {
      const fromDate = resolveComparedDate(valueFrom, currentData, store);
      const toDate = resolveComparedDate(valueTo, currentData, store);

      const fromOffset = (includeValue ? 0 : -1) + valueFromOffset;
      const toOffset = (includeValue ? -1 : 0) + valueToOffset;

      return (
        (fromDate && current < fromDate.add(fromOffset, 'day')) ||
        (toDate && current > toDate.add(toOffset, 'day'))
      );
    }
    default:
      return false;
  }
}
