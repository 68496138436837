import { Radio, Space } from 'antd';
import PropTypes from 'prop-types';
import { HELPER_TEXT_TYPES } from '@icp/form-renderer-core';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import { ConditionalPropertyPropType } from '../../propTypes';

function RadioAnt(props) {
  const {
    id,
    title,
    value,
    disabled,
    componentProps = {},
    fieldTitleProps,
    validation,
    readonly,
    helpers,
    onChange,

    options,
    direction,
    gap,
  } = props;

  return (
    <>
      <FieldTitle required={validation?.required} {...fieldTitleProps}>
        {title}
      </FieldTitle>
      <div>
        <Radio.Group
          {...componentProps}
          name={id}
          value={value}
          disabled={disabled || readonly}
          onChange={onChange}
        >
          <Space direction={direction} size={gap} wrap={true}>
            {(options || []).map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
        <FormHelperText helpers={helpers} />
      </div>
    </>
  );
}

RadioAnt.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  spaceStyle: PropTypes.shape({}),
  componentProps: PropTypes.shape({}),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  gap: PropTypes.oneOfType([PropTypes.oneOf(['small', 'middle', 'large']), PropTypes.number]),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

export default RadioAnt;
