// import IcpMining from '@icp/mining';
import { withFieldWrapper } from '../fieldWrapper';

function MiningElement() {
  /* const { style } = props;
  return <IcpMining style={style} />; */
  return null;
}

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
MiningElement.displayName = 'Mining';

export default withFieldWrapper(MiningElement);
