import { ACTION_COLUMN } from '@icp/form-renderer';
import { findSchemaFieldInEntityByToken } from '../../../store';

export function getColumnType(field) {
  switch (field.type) {
    case 'ACL':
      return 'ACL_COLUMN';
    case 'CHECKBOX':
      return 'CHECKBOX_COLUMN';
    case 'DATE':
      return 'DATE_COLUMN';
    case 'NUMBER':
      return 'NUMBER_COLUMN';
    case 'RADIO':
      return 'RADIO_COLUMN';
    case 'SELECT':
      return 'SELECT_COLUMN';
    case 'TEXT_BOX':
      return 'TEXT_COLUMN';
    case 'TEXTAREA':
      return 'TEXTAREA_COLUMN';
    case 'TIME':
      return 'TIME_COLUMN';
    case 'UPLOAD':
      return 'UPLOAD_COLUMN';
    default:
      return undefined;
  }
}

function getComponentProps(field, schemaField) {
  const { componentProps } = schemaField || {};

  switch (field.type) {
    case 'UPLOAD':
      return componentProps;
    default:
      return undefined;
  }
}

function getFormatOptions(field, schemaField) {
  const { componentProps } = schemaField;

  switch (field.type) {
    case 'NUMBER':
    case 'DATE':
      return componentProps?.formatOptions;
    default:
      return undefined;
  }
}

export function getFilterProps(field) {
  // const { componentProps } = schemaField;

  switch (field.type) {
    case 'CHECKBOX':
    case 'RADIO':
    case 'SELECT':
      // TODO, aggrid valueFormatter 不支持 string，json 里又不能写函数
      /* if (componentProps.options) {
        return {
          filter: 'agSetColumnFilter',
          filterParams: {
            values: componentProps?.options,
            valueFormatter: (v) => v.label,
          },
        };
      } */
      break;
    default:
      break;
  }
  return undefined;
}

export function getColDef({ field, dataSourceFormEntity }) {
  if (!dataSourceFormEntity) {
    return {
      colId: field.token,
      field: field.token,
      headerName: field.name,
      headerName_i18n_key: field.nameI18nKey ?? undefined,
      headerName_i18n_ns: field.nameI18nNs ?? undefined,
    };
  }

  // 从第一个 layout 里取 field，虽然多个 layout 里的 componentProps 可能会不同，但是也只能这么处理
  const schemaField = findSchemaFieldInEntityByToken(dataSourceFormEntity, field.token);
  const componentProps = schemaField ? getComponentProps(field, schemaField) : null;
  const formatOptions = schemaField ? getFormatOptions(field, schemaField) : null;
  const filterProps = schemaField ? getFilterProps(field, schemaField) : null;

  return {
    type: getColumnType(field),
    colId: field.token,
    field: field.token,
    headerName: field.name,
    headerName_i18n_key: field.nameI18nKey ?? undefined,
    headerName_i18n_ns: field.nameI18nNs ?? undefined,
    cellRendererParams:
      componentProps || formatOptions
        ? {
            componentProps,
            formatOptions,
          }
        : undefined,
    ...filterProps,
  };
}

export function dataSourceToColumnDefs({ fields, dataSourceFormEntity, oldColumnDefs }) {
  const columnDefs = fields
    .filter((field) => field.type !== 'EDITABLE_GRID' && field.type !== 'RICH_TEXT')
    .map((field) => getColDef({ field, dataSourceFormEntity }));

  const oldActionColumn = (oldColumnDefs || []).find((colDef) => colDef.type === ACTION_COLUMN);

  return columnDefs.concat(oldActionColumn).filter(Boolean);
}
