import { useMemo } from 'react';
import { setRef } from '@icp/utils';

export default function useForkRef(refA, refB) {
  return useMemo(() => {
    return (refValue) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}
