import PropTypes from 'prop-types';
import { ensureDisplayText } from '@icp/utils';
import { useTranslation } from 'react-i18next';

function TextareaReadonlyText(props) {
  const { value: valueProp, simpleMode, allowI18nValue } = props;

  const { i18n } = useTranslation();

  const valueI18nKey = `longText_i18n_${i18n.language}`;

  const innerValue = (function getInnerValue() {
    // return simpleMode ? value : value?.longText;
    if (simpleMode) {
      return valueProp;
    }

    if (!valueProp) {
      return '';
    }

    if (!allowI18nValue) {
      return valueProp.longText ?? '';
    }

    // 当 valueI18n 里没有对应语言的值的时候，fallback 取 valueProp 的值 (兼容老数据)
    return valueProp[valueI18nKey] ?? valueProp.longText;
  })();

  return <span className="readonly-text">{ensureDisplayText(innerValue)}</span>;
}

TextareaReadonlyText.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      longText: PropTypes.string,
    }),
  ]),
  simpleMode: PropTypes.bool,
  allowI18nValue: PropTypes.bool,
};

export default TextareaReadonlyText;
