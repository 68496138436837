import { initBrowserI18n, translateEntireObj } from '@icp/i18n';
import { setAppConfig } from '@icp/settings';
import loadPbcList from './loadPbcList';
import loadFrameWorkLocale from './loadFrameWorkLocale';
import loadFrontendConfig from './loadFrontendConfig';
import loadCustomI18nResources, { completeMissingResources } from './loadCustomI18nResources';

export default function initAllConfig() {
  const loadI18n = () => {
    return Promise.all([
      loadFrontendConfig(),
      loadCustomI18nResources(),
      //
    ])
      .then(([frontendConfig, customI18nResources]) => {
        return [
          frontendConfig,
          completeMissingResources(
            customI18nResources,
            frontendConfig?.appConfig?.i18n?.allowedLanguages ?? [],
          ),
        ];
      })
      .then(([frontendConfig, customI18nResources]) => {
        return Promise.all([
          frontendConfig,
          initBrowserI18n(frontendConfig?.appConfig?.i18n, customI18nResources),
        ]);
      })
      .then(([frontendConfig, i18n]) => {
        return Promise.all([
          frontendConfig,
          i18n,
          loadFrameWorkLocale(i18n.language),
          //
        ]);
      });
  };

  return Promise.all([
    loadI18n(),
    loadPbcList(),
    //
  ]).then(([[frontendConfig, i18n, antdLocale], pbcList]) => {
    translateEntireObj(frontendConfig);
    translateEntireObj(pbcList);

    const { appConfig, appRoutes } = frontendConfig;

    setAppConfig(appConfig);

    return {
      i18n,
      antdLocale,
      pbcList,
      appConfig,
      appRoutes,
    };
  });
}
