import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { selectContext, selectValues } from '@icp/form-renderer-core';
import { extractDataDependencyIds, resolveVariablePattern } from '@icp/utils';
import { useStore } from '../store';
import useDependencies from './useDependencies';
import { useCurrentData } from '../currentDataCtx';
import { useIsInDesign } from '../FormRenderCtx';

export default function useVariablePattern(pattern, failWhenNoVariableValue, isInDesignOut) {
  const isInDesignInner = useIsInDesign();
  // 在 FormRenderer.js 里调用 useVariablePattern 的时候 useIsInDesign 没值
  const isInDesign = isInDesignInner === undefined ? isInDesignOut : isInDesignInner;
  const store = useStore();
  const formData = selectValues(store.getState());
  const currentData = useCurrentData();
  const context = selectContext(store.getState());
  const params = useParams();

  const ids = useMemo(() => {
    return extractDataDependencyIds(pattern, currentData);
  }, [pattern, currentData]);

  useDependencies(ids);

  if (isInDesign) {
    return pattern;
  }

  return resolveVariablePattern({
    pattern,
    currentData: currentData || formData,
    formData,
    context,
    params,
    failWhenNoVariableValue,
  });
}
